import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import ConfModal from "../../../../components/common/ConfModal";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePrompt from "../../../../components/common/DeletePrompt";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import ChatIcon from "@mui/icons-material/Chat";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import {
  disconnectWithConnection,
  memberVicAction,
} from "../../../../reducers/clientReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import { loggedInUserProfile } from "../../../../reducers/authReducer";
import { createId } from "../../../../utils";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { StyledMenu } from "../../../../components/common/StyledMenu/StyledMenu";
import { Divider, MenuItem } from "@mui/material";

const Clients = ({ src, userName, next, prev, isVic, id, mobileNo, data }) => {
  const op = useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [anchorEl2, setAnchorEl2] = React.useState<null | HTMLElement>(null);
  const subMenuOpen5 = Boolean(anchorEl2);
  const handleClick2 = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleSubMenuClose = () => {
    setAnchorEl2(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  let navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const loggedInUser = useSelector(loggedInUserProfile);

  const createConversationData = async () => {
    try {
      // xconsole.test("pass");
      navigate(`/chat`, {
        state: {
          mobileNo: data?.id,
          data: {
            finalData: {
              group: createId(true, loggedInUser?.id, data?.id),
              firstName: data?.firstName,
              lastName: data?.lastName,
              message: "",
              messageCreatedAt: new Date(),
              model: "clients",
              profilePicture: data?.profileImage,
              type: "",
              userId: data?.id,
            },

            activeRoomData: {
              id: createId(true, loggedInUser?.id, data?.id),
              recipient: {
                id: createId(true, loggedInUser?.id, data?.id),
                avatarUrl: data?.profileImage,
                userId: data?.id,
                online: false,
                name: data?.firstName + " " + data?.lastName,
                isProvider: false,
                modifiedAt: {
                  date: new Date(),
                  time: moment().format("hh:mm A"),
                },
                createdAt: {
                  seconds: moment().unix(),
                  nanoseconds: moment().milliseconds(),
                },
              },
              messages: [],
            },
          },
        },
      });
    } catch (error) {
      console.log("error ", error);
    }
  };

  const makeMemberVic = () => {
    if (isVic == 1) {
      const data = {
        clientId: id,
        isVic: 0,
      };
      dispatch(memberVicAction(data));
    } else {
      const data = {
        clientId: id,
        isVic: 1,
      };
      dispatch(memberVicAction(data));
    }
  };

  const connectionDelete = {
    disconnectWtih: "member",
    id: Number(id),
  };
  return (
    <div className="col mb-3">
      <div
        className={`d-flex border align-items-center p-3 border-b5 ${
          data?.isMobileVerified ? "bg-white" : "light-bg-disabled"
        }`}
      >
        <label
          onClick={() =>
            data?.isMobileVerified ? navigate(`/client/profile/${id}`) : null
          }
          className="d-flex flex-column pointer position-relative"
        >
          <img
            className="rounded-circle me-2 border border-primary"
            style={{ objectFit: "cover" }}
            height={44}
            width={44}
            src={
              src == null
                ? "/images/avatar2.png"
                : !data?.isMobileVerified
                ? "/images/avatar2.png"
                : src
            }
            alt=""
          />
          {isVic ? (
            <StarRoundedIcon
              className="me-1 mb-1 rounded-circle bg-white position-absolute end-0"
              sx={{
                fontSize: 18,
                color: "#04bac7",
                bottom: "-0.4rem",
                // marginLeft: "1.5rem",
              }}
            />
          ) : null}
        </label>
        <div className="w-100">
          <div className="d-flex justify-content-between">
            <p
              onClick={() =>
                data?.isMobileVerified
                  ? navigate(`/client/profile/${id}`)
                  : null
              }
              className={`mb-1 text-dark pointer`}
              style={{ fontSize: "15px" }}
            >
              {userName}
            </p>
            <div className="d-flex">
              <div className="dropdown pointer">
                <div
                  id="demo-customized-button"
                  aria-controls={menuOpen ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={menuOpen ? "true" : undefined}
                  onClick={handleClick}
                >
                  <IconButton
                    style={{ marginRight: "-0.9rem", marginTop: "-0.6rem" }}
                  >
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </div>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorEl}
                  open={menuOpen}
                  onClose={handleMenuClose}
                >
                  {data?.isMobileVerified ? (
                    <MenuItem
                      onClick={() => {
                        navigate(`/client/profile/${id}`);
                      }}
                      disableRipple
                    >
                      <AccountBoxIcon />
                      Profile
                    </MenuItem>
                  ) : (
                    [null]
                  )}
                  {isVic ? (
                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        makeMemberVic();
                      }}
                      disableRipple
                    >
                      <StarRoundedIcon />
                      Remove from VIC
                    </MenuItem>
                  ) : (
                    <MenuItem
                      onClick={() => {
                        makeMemberVic();
                        handleMenuClose();
                      }}
                      disableRipple
                    >
                      <StarOutlineRoundedIcon />
                      Add to VIC
                    </MenuItem>
                  )}
                  <Divider sx={{ my: 0.5 }} />
                  <MenuItem
                    onClick={() => {
                      handleOpen();
                      handleMenuClose();
                    }}
                    disableRipple
                  >
                    <DeleteIcon />
                    Delete
                  </MenuItem>
                  <MenuItem
                    id="demo-customized-button2"
                    aria-controls={
                      subMenuOpen5 ? "demo-customized-menu2" : undefined
                    }
                    aria-haspopup="true"
                    aria-expanded={true}
                    onClick={handleClick2}
                    disableRipple
                  >
                    <MoreHorizIcon />
                    Contact
                  </MenuItem>
                </StyledMenu>
                <StyledMenu
                  transformOrigin={{
                    vertical: "center",
                    horizontal: "left",
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  id="demo-customized-menu2"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button2",
                  }}
                  anchorEl={anchorEl2}
                  open={subMenuOpen5}
                  onClose={handleSubMenuClose}
                >
                  {data?.isMobileVerified ? (
                    <MenuItem
                      onClick={() => {
                        handleMenuClose();
                        handleSubMenuClose();
                        createConversationData();
                      }}
                      disableRipple
                    >
                      <ChatIcon />
                      Chat
                    </MenuItem>
                  ) : (
                    [null]
                  )}
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleSubMenuClose();
                    }}
                    disableRipple
                  >
                    <a style={{ color: "#212529" }} href={`tel:${mobileNo}`}>
                      <CallIcon />
                      Call
                    </a>
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleMenuClose();
                      handleSubMenuClose();
                    }}
                    disableRipple
                  >
                    <a
                      style={{ color: "#212529" }}
                      href={`mailto:${data?.email}`}
                    >
                      <MailIcon />
                      Email
                    </a>
                  </MenuItem>
                </StyledMenu>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <BootstrapTooltip title="Previous Appointment" placement="top">
              <div className="d-flex align-items-center">
                <UndoIcon
                  className="rotate30"
                  style={{ color: "grey" }}
                  fontSize="small"
                />
                <p
                  style={{ color: "grey", fontSize: "13px" }}
                  className="mb-0 me-3"
                >
                  {next ? next : "None"}
                </p>
              </div>
            </BootstrapTooltip>
            <BootstrapTooltip title="Next Appointment" placement="top">
              <div className="d-flex align-items-center">
                <RedoIcon
                  className="me-1 rotate-30"
                  style={{ color: "grey" }}
                  fontSize="small"
                />
                <p style={{ color: "grey", fontSize: "13px" }} className="mb-0">
                  {prev ? prev : "None"}
                </p>
              </div>
            </BootstrapTooltip>
          </div>
        </div>
      </div>
      <ConfModal handleClose={handleClose} open={open}>
        <DeletePrompt
          handleClose={handleClose}
          deleteFuntion={disconnectWithConnection({
            data: connectionDelete,
            navigate,
          })}
          textToShow={"Deleting Member"}
        />
      </ConfModal>
    </div>
  );
};

export default Clients;

import React from "react";
import ModalC from "../../../../../components/common/Modal";
import { AddSocialModal } from "../AddSocialModal";

const SocialLink = ({
  link,
  src,
  idx,
}: {
  link: any;
  src: string;
  idx: string | number;
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const imgStyle = {
    padding: src == "/images/Media/linkedin.svg" ? "5px 11px" :
      src == "/images/Media/messenger.svg" || src == "/images/Media/whatsapp.svg" ? "6px 9px"
        : "6px 8px",
    opacity: !link?.userName ? "0.5" : "1.0"
  };

  return (
    <>
      <div
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-mdb-toggle="tooltip"
        title={link.prefix + link.userName}
        onClick={handleOpen}
        style={{ ...imgStyle }}
        className={`bg-mask me-lg-3 me-lg-2 me-1 border border-1 rounded-circle avatar-social bg-white pointer`}
      >
        <img height={src == "/images/Media/twitter.svg" || src == "/images/Media/linkedin.svg" ? 18 : 21} src={src} alt="twitter" className="" />
      </div>

      <ModalC handleClose={handleClose} open={open}>
        <AddSocialModal link={link} idx={idx} handleClose={handleClose} />
      </ModalC>
    </>
  );
};

export default SocialLink;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addMobileNumbers, createConversation } from "../../Firebase";
import { loggedInUserProfile } from "../../reducers/authReducer";
import moment from "moment";
import echo from "../../hooks/useWebSockets";
import {
  activateRoom,
  updateActiveMessage,
  updateList,
} from "../../reducers/chatReducer";
import { AppDispatch } from "../../store";
import { createId, greaterId, smallerId } from "../../utils";

const ConnectionUser = ({ data, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const loggedInUser = useSelector(loggedInUserProfile);

  const createConversationData = async (data: any) => {
    try {
      navigate(`/chat`, {
        state: {
          mobileNo: data?.id,
          data: {
            finalData: {
              group: createId(true, loggedInUser?.id, data?.id),
              firstName: data?.firstName,
              lastName: data?.lastName,
              message: "",
              messageCreatedAt: new Date(),
              model: "clients",
              profilePicture: data?.profileImage,
              type: "",
              userId: data?.id,
            },

            activeRoomData: {
              id: createId(true, loggedInUser?.id, data?.id),
              recipient: {
                id: createId(true, loggedInUser?.id, data?.id),
                avatarUrl: data?.profileImage,
                userId: data?.id,
                online: false,
                name: data?.firstName + " " + data?.lastName,
                isProvider: false,
                modifiedAt: {
                  date: new Date(),
                  time: moment().format("hh:mm A"),
                },
                createdAt: {
                  seconds: moment().unix(),
                  nanoseconds: moment().milliseconds(),
                },
              },
              messages: [],
            },
          },
        },
      });

      handleClose();
    } catch (error) {
      console.warn("error ", error);
    }
  };

  const fsFourteen = {
    fontSize: "14px",
  };

  const textLight = {
    color: "#667085",
    fontWeight: "400",
  };
  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };

  return (
    <div className="d-flex justify-content-between align-items-center border border-b5 bg-white p-1 mb-2">
      <div className="d-flex align-items-center p-1">
        <img
          src={data?.profileImage ? data?.profileImage : "/images/avatar2.png"}
          alt="avatar"
          className="rounded-circle me-1 border border-primary"
          height={40}
          width={40}
        />
        <div className="d-flex flex-column px-1 pointer">
          <div className="text-dark"></div>
          <p
            style={{
              ...textLight,
              ...poppinsFont,
              fontSize: "13px",
            }}
            className="mb-1"
          >
            {data?.username}
          </p>
        </div>
      </div>
      <div>
        <button
          onClick={() => {
            createConversationData(data);
            handleClose();
          }}
          style={{
            whiteSpace: "nowrap",
            ...fsFourteen,
          }}
          className="btn btn-primary d-flex flex-row align-items-center ps-1 border-b5 me-2"
        >
          <img
            height={"16.11px"}
            width={"16px"}
            src="/images/clientList/chat.png"
            alt="plus"
            className="mx-2"
          />
          Chat
        </button>
      </div>
    </div>
  );
};

export default ConnectionUser;

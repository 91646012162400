import { useCallback, useEffect, useMemo, useState, useRef } from "react";

import Typography from "@mui/material/Typography";
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useDebounceEffect } from "../../../../hooks/useDebounceEffect";
import { ASPECT_RATIO } from "../../../../config/constant";

async function canvasPreview(
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  crop: PixelCrop,
  scale = 1,
  rotate = 0
) {
  const TO_RADIANS = Math.PI / 180;

  const ctx = canvas.getContext("2d");

  if (!ctx) {
    throw new Error("No 2d context");
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = "high";

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const rotateRads = rotate * TO_RADIANS;
  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 3) Rotate around the origin
  ctx.rotate(rotateRads);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight
  );

  ctx.restore();
}

export const CropImageModal = ({
  handleClose,
  img,
  setOutput,
  output,
}: any) => {
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();

  const imgRef = useRef<HTMLImageElement>(null);

  const previewCanvasRef = useRef<HTMLCanvasElement>(null);

  const blobUrlRef = useRef("");

  function onImageLoad(e: any) {
    const { naturalWidth, naturalHeight } = e.target;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 100,
          height: 100,
        },
        ASPECT_RATIO,
        naturalWidth,
        naturalHeight
      ),
      naturalWidth,
      naturalHeight
    );

    setCrop(crop);
  }

  const cropImageNow = () => {
    // console.log("cropImageNow", crop, completedCrop);
    // console.log("imgRef", image);

    if (!previewCanvasRef.current) {
      throw new Error("Crop canvas does not exist");
    }

    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error("Failed to create blob");
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current);
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      setOutput({
        ...output,
        image: {
          blob: blob,
          src: blobUrlRef.current,
        },
      });
    });

    handleClose();
  };

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          1,
          0
        );
      }
    },
    100,
    [completedCrop]
  );

  return (
    <>
      <Typography
        className="text-dark"
        id="modal-modal-title"
        sx={{
          fontSize: "25px",
          fontWeight: "900",
          textAlign: "center",
        }}
        component="h6"
      >
        Crop Image
      </Typography>
      <div>
        <div className="d-flex align-items-center justify-content-center">
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(c) => setCompletedCrop(c)}
            locked={true}
            aspect={ASPECT_RATIO}
          >
            <img
              ref={imgRef}
              alt="Crop me"
              // width={500}
              // height={500}
              src={img}
              style={{
                objectFit: "contain",
                maxHeight: 500,
                maxWidth: 500,
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>

          {true && (
            <>
              <div>
                <canvas
                  ref={previewCanvasRef}
                  style={{
                    // border: "1px solid black",
                    objectFit: "contain",
                    width: 0,
                    height: 0,
                  }}
                />
              </div>
            </>
          )}
        </div>
        <div className="text-center mt-2">
          <button className="btn btn-primary" onClick={cropImageNow}>
            Crop
          </button>
        </div>
      </div>
    </>
  );
};

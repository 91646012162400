// ######################################################################### AUTH #########################################################################

// ***************************************************************** payment *****************************************************************

// payabli

export type businessInfoType = Pick<
  MerchantOnBoarding,
  | "dbaName"
  | "legalName"
  | "taxIdNo"
  | "taxFillName"
  | "startDate"
  | "phoneNo"
  | "bAddress"
  | "bCity"
  | "bType"
  | "bState"
  | "bZip"
  | "bSummary"
>;

export type payStep2 = Pick<
  MerchantOnBoarding,
  | "mAddress"
  | "mCity"
  | "mState"
  | "mZip"
  | "mcc"
  | "templateId"
  | "contacts"
  | "ownership"
  | "depositAccountBankName"
  | "depositAccountRoutingAccount"
  | "depositAccountAccountNumber"
  | "depositAccountTypeAccount"
  | "isAcceptVisa"
>;

export type payStep3 = Pick<
  MerchantOnBoarding,
  | "templateId"
  | "contacts"
  | "ownership"
  | "depositAccountBankName"
  | "depositAccountRoutingAccount"
  | "depositAccountAccountNumber"
  | "depositAccountTypeAccount"
  | "isAcceptVisa"
>;
export type payStep4 = Pick<
  MerchantOnBoarding,
  | "singerName"
  | "singerSSS"
  | "singerDob"
  | "singerPhone"
  | "singerEmail"
  | "singerAddress"
  | "singerState"
  | "singerCity"
  | "singerZip"
>;

export interface MerchantOnBoarding {
  dbaName: string;
  legalName: string;
  website?: string; //need to hide
  taxIdNo: string;
  taxFillName: string;
  licence?: string; //need to hide
  licState?: string; //need to hide
  startDate: string;
  phoneNo: string;
  faxNo?: string; //need to hide ?no body uses these days
  bAddress: string;
  bAddress1?: string; //need to hide
  bCity: string;
  bType: string;
  bState: string;
  bZip: string;
  bCountry?: string; //need to hide  ?it always be USA

  mAddress: string;
  mAddress1?: string; //need to hide
  mCity: string;
  mState: string;
  mZip: string;
  mCountry: string;
  mcc: string;
  bSummary: string;
  whenCharged: string;
  whenProvided: string;
  whenDelivered: string;
  whenRefunded: string;

  binPerson: string;
  binPhone: string;
  binWeb: string;
  templateId: string;
  avgMonthly: string;
  ticketamt: string;
  highTicketamt: string;
  contacts: Contact[];
  ownership: Ownership[];
  depositAccountBankName: string;
  depositAccountRoutingAccount: string;
  depositAccountAccountNumber: string;
  depositAccountTypeAccount: string;
  withdrawalAccountBankName: string;
  withdrawalAccountRoutingAccount: string;
  withdrawalAccountAccountNumber: string;
  isAcceptVisa: boolean;

  isAcceptMastercard: boolean;
  isAcceptDiscover: boolean;
  isAcceptAmex: boolean;
  isAcceptWeb: boolean;
  isAcceptPPD: boolean;
  isAcceptCCD: boolean;
  singerName: string;
  singerSSS: string;
  singerDob: string;
  singerPhone: string;
  singerEmail: string;
  singerAddress: string;
  singerState: string;
  singerCountry: string;
  singerCity: string;
  singerZip: string;
}

export interface Contact {
  contactName: string;
  contactEmail: string;
  contactTitle: string;
  contactPhone: string;
}

export interface Ownership {
  ownerName: string;
  ownerTitle: string;
  ownerSSN: string;
  ownerDob: string;
  ownerPhone1: string;
  ownerPhone2: string;
  ownerMail: string;
  ownerDriver: string;
  ownerDriverState: string;
  ownerAddress: string;
  ownerState: string;
  ownerCountry: string;
  ownerCity: string;
  ownerZip: string;
  ownerPercentage?: string;
}

// ***************************************************************** login *****************************************************************

export type loginDataType = {
  username?: string;
  password?: string;
  grant_type?: string;
  client_id?: string;
  client_secret?: string;
};

// ***************************************************************** actions *****************************************************************
export type actionType = {
  loading: boolean;
  error: string | unknown;
  message: string;
};

export interface AxiosResponse {
  data: {
    success: boolean;
    data: Array<any>;
    message: string;
  };
}

// ***************************************************************** request *****************************************************************

export type urlParameter = {
  id: string | number;
};

// ***************************************************************** request error response *****************************************************************

export type errorResponseType = {
  code: number;
  success: boolean;
  message: string;
  error?: string;
  data: any;
};

//chat

export interface ChatState {
  room: {
    list: Array<any>;
    filteredList: Array<any>;
    status: string;
    active: {
      id: string | null;
      recipient: unknown;
      messages?: null | Array<any>;
    };
  };
  status: string;
  CreateStatus: string;
  UpdateStatus: string;
  error: string | null;
}

// ***************************************************************** routes  *****************************************************************

export interface JwtPayload {
  aud: string;
  exp: number;
  iat: number;
  jti: string;
  nbf: number;
  scopes: string[];
  sub: string;
}

// ***************************************************************** appointments  *****************************************************************
export interface Appointment {
  provider: Provider;
  services: Array<Service>;
  client?: any;
  id: number;
  appointmentDate: string;
  startTime: string;
  endTime: string;
  status: string;
  statusToShow: string;
  totalPrice: string;
  totalDuration: string;
  fee: number | null;
  media: any[]; // update with actual media type
  discount: number | null;
  tax: number | null;
  bookedBy: string;
  isPaid: number;
  createdAt: string;
  reservationFee: number;
  isReservationFee: number;
  appointmentAddress: string | null;
  address: string | null;
}

// ***************************************************************** common  *****************************************************************
interface Provider {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  mobileNo: string;
  profileImage: string;
  thumbnailUrl: string;
  businessName: string;
  service: {
    id: number;
    providerId: number;
    service: Service;
    serviceId: number;
  };
  address: null;
  street: string;
  zip: string;
  city: string;
  state: string;
  bio: string;
  gender: null;
  loginBy: string;
  lastLogin: string;
  status: boolean;
  isMobileVerified: boolean;
  latitude: string;
  longitude: string;
  referralCode: number;
  iosUrl: string;
  playStoreUrl: string;
  verificationCodeSentAt: string;
  socialMediaLinks: null;
  createdAt: string;
  medias: Array<Media>;
}

interface Media {
  id: number;
  url: string;
  thumbnail: string;
  type: string;
  caption: string;
  dateTime: string | null;
  isFavourite: boolean | null;
  location: string;
  latitude: number | null;
  longitude: number | null;
  isPrivate: number;
}

interface ChildService {
  id: number;
  parent_id: number;
  name: string;
  icon: string;
  description: string | null;
  duration: string | null;
  price: number | null;
  level: number;
  is_leaf_node: number;
  is_default: number;
  is_client_default: number;
  is_active: number;
  is_admin: number;
  created_at: string;
  updated_at: string;
}
interface Service {
  id: number;
  service: {
    id: number;
    name: string;
    childServices: Array<ChildService>;
    description: string;
    parentId: number;
    icon: string;
    duration: string;
    price: number;
    level: number;
    isLeafNode: number;
    isDefault: number;
    providerService: any;
    createdAt: string;
  };
  servicePrice: number;
  peakPrice: any;
  serviceDuration: string;
}

// ***************************************************************** notification  *****************************************************************
export interface NotificationState {
  list: any[];
  status: string;
  error: string | null;
}

// ***************************************************************** connection(member page)  *****************************************************************

export const enum ConnectionStatus {
  APPROVED = "approved",
  DECLINED = "declined",
  WAITLIST = "waitlist",
}

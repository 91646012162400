import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import DescriptionIcon from "@mui/icons-material/Description";
import MovieIcon from "@mui/icons-material/Movie";
import ImageIcon from "@mui/icons-material/Image";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { ALLOW_DOCS } from "../../../../../config/config";

const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));

const Attachments = ({ handleMediaSend }) => {
  return (
    <div className="dropdown">
      <div
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <label
          className="bg-primary me-2 p-1 rounded pointer"
          htmlFor="imageInput"
        >
          <BootstrapTooltip title="Attachments" placement="top">
            <AttachFileIcon
              sx={{ color: "white", transform: "rotate(-20deg)" }}
            />
          </BootstrapTooltip>
        </label>
      </div>
      <ul
        style={{ borderRadius: "10px", width: "200px" }}
        className="dropdown-menu dropdown-menu-light py-3 mb-5 p-absolute"
        aria-labelledby="dropdownMenuButton2"
      >
        <li className="row px-3 gy-3 row-cols-2 m-auto w-100">
          <div className="col text-center">
            <label
              className="bg-primary p-2 rounded ms-1 pointer"
              htmlFor="imageInput"
            >
              <input
                onChange={handleMediaSend}
                hidden
                type="file"
                name="image"
                id="imageInput"
                accept="image/*"
              />
              <ImageIcon sx={{ color: "white" }} />
            </label>
            <small className="mb-0 d-block mt-1">Image</small>
          </div>
          <div className="col text-center">
            <label
              className="bg-primary p-2 rounded ms-1 pointer"
              htmlFor="videoInput"
            >
              <input
                onChange={handleMediaSend}
                hidden
                type="file"
                name="video"
                id="videoInput"
                accept="video/*"
              />

              <MovieIcon sx={{ color: "white" }} />
            </label>
            <small className="mb-0 d-block mt-1">Video</small>
          </div>
          <div className="col text-center">
            <label
              className="bg-primary p-2 rounded ms-1 pointer"
              htmlFor="audioInput"
            >
              <input
                onChange={handleMediaSend}
                hidden
                type="file"
                name="audio"
                id="audioInput"
                accept="audio/*"
              />

              <HeadphonesIcon sx={{ color: "white" }} />
            </label>
            <small className="mb-0 d-block mt-1">Audio</small>
          </div>

          {/* <div className="col text-center">
            <label
              onClick={() => alert("Currently in under development")}
              className="bg-primary p-2 rounded ms-1 pointer"
              htmlFor=""
            >
              <HeadphonesIcon sx={{ color: "white" }} />
            </label>
            <small className="mb-0 d-block mt-1">Audio</small>
          </div> */}

          <div className="col text-center">
            <label
              className="bg-primary p-2 rounded ms-1 pointer"
              htmlFor="fileInput"
            >
              <input
                onChange={handleMediaSend}
                hidden
                type="file"
                name="file"
                id="fileInput"
                accept={ALLOW_DOCS}
              />

              <DescriptionIcon sx={{ color: "white" }} />
            </label>
            <small className="mb-0 d-block mt-1">Document</small>
          </div>

          {/* <div className="col text-center">
                <label
                onClick={() => alert("Currently in under development")}
                className="bg-primary p-2 rounded ms-1 pointer"
                htmlFor=""
                >
                <DescriptionIcon sx={{ color: "white" }} />
                </label>
                <small className="mb-0 d-block mt-1">Document</small>
            </div> */}
        </li>
      </ul>
    </div>
  );
};

export default Attachments;

import React from 'react'
import { Skeleton } from "@mui/material";

const DaysSkeletonLoading = () => {
    return (
        <>
            <div className="d-flex align-items-center mb-4">
                <Skeleton className='me-2' variant="circular" width={42} height={42} />
                <Skeleton className='me-2' variant="circular" width={42} height={42} />
                <Skeleton className='me-2' variant="circular" width={42} height={42} />
                <Skeleton className='me-2' variant="circular" width={42} height={42} />
                <Skeleton className='me-2' variant="circular" width={42} height={42} />
                <Skeleton className='me-2' variant="circular" width={42} height={42} />
                <Skeleton className='me-2' variant="circular" width={42} height={42} />
            </div>
        </>
    )
}

export default DaysSkeletonLoading
import { Avatar, Badge, BadgeOrigin } from "@mui/material";
import { styled } from "@mui/system";
import { SxProps } from "@mui/system";
import { CSSProperties } from "react";

interface UserAvatarProps {
  src: string;
  name?: string;
  online: boolean;
  className?: string;
  sx?: SxProps<CSSProperties>;
  anchorOrigin?: BadgeOrigin;
  variant?: "standard" | "dot";
  color?: "default" | "error" | "primary" | "secondary" | "warning";
}

const RippleClass = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  borderRadius: "50%",
  animation: "ripple 1.2s infinite ease-in-out",
  border: "1px solid currentColor",
  content: '""',
  opacity: 0,
};

const StyledBadge = styled(Badge)<{ online: boolean }>(({ theme, online }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: online ? "#44b700" : "",
    color: online ? "#44b700" : "",
    position: "relative",
    "&::after": online ? RippleClass : {},
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const UserAvatar = ({
  src,
  online,
  className,
  anchorOrigin,
  variant,
  color,
  name,
}: UserAvatarProps) => {
  return (
    <StyledBadge
      overlap="circular"
      anchorOrigin={anchorOrigin || { vertical: "bottom", horizontal: "right" }}
      variant={variant || "dot"}
      online={online}
      className={className}
      color={color || "default"}
    >
      <Avatar alt={name} src={src} />
    </StyledBadge>
  );
};

export default UserAvatar;

import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';
import { Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import { exploreFilterSelector, filterSelector } from '../../../../reducers/mediaReducer';

const Label = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
}));

export default function MasonaryLoading() {
    const filter = useSelector(filterSelector);
    return (
        <div className='mt-4'>
            <Box sx={{ width: '100%', minHeight: 829 }}>
                <Masonry columns={{ xs: 2, sm: 2, md: 4 }} spacing={2}>
                    {
                        filter.isMine ?
                            staticData.map((item, index) => (
                                <div key={index}>
                                    {/* <Label>{index + 1}</Label> */}
                                    <Skeleton
                                        className="rounded-5"
                                        variant="rectangular"
                                        width={"100%"}
                                        height={item.height}
                                    />
                                </div>
                            ))
                            :
                            itemData.map((item, index) => (
                                <div key={index}>
                                    {/* <Label>{index + 1}</Label> */}
                                    <Skeleton
                                        className="rounded-5"
                                        variant="rectangular"
                                        width={"100%"}
                                        height={item.height}
                                    />
                                    {/* <img
                            src={`${item.img}?w=162&auto=format`}
                            srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                            alt={item.title}
                            loading="lazy"
                            style={{
                                borderBottomLeftRadius: 4,
                                borderBottomRightRadius: 4,
                                display: 'block',
                                width: '100%',
                                height: `${item.height}`,
                                borderRadius: '10px'
                            }}
                        /> */}
                                </div>
                            ))
                    }
                </Masonry>
            </Box>
        </div>
    );
}

const itemData = [
    {
        height: '120px'
    },
    {
        height: '170px'
    },
    {
        height: '190px'
    },
    {
        height: '220px'
    },
    {
        height: '200px'
    },
    {
        height: '150px'
    },
    {
        height: '180px'
    },
    {
        height: '220px'
    },
    {
        height: '150px'
    },
    {
        height: '200px'
    },
    {
        height: '180px'
    },
    {
        height: '120px'
    },
    {
        height: '150px'
    },
    {
        height: '220px'
    },
    {
        height: '180px'
    },
    {
        height: '200px'
    },
    {
        height: '170px'
    },
    {
        height: '190px'
    },
    {
        height: '220px'
    },
    {
        height: '200px'
    },
];
const staticData = [
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
    {
        height: '230px'
    },
];
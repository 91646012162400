import React from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import Modal from "../../../../components/common/Modal";
import { addOwnSocialMediaRequest } from "../../../../helper/provider/media";
import { socialLinkSelector } from "../../../../reducers/mediaReducer";
import { AddSocialModal } from "./AddSocialModal";
import SocialLink from "./socialLinks/SocialLink";

const SOCIAL_LINKS = [
  {
    id: 1,
    link: "www.facebook.com",
    src: "/images/Media/facebook.svg",
  },
  { id: 2, link: "www.tweeter.com", src: "/images/Media/tweeter.svg" },
  {
    id: 3,
    link: "www.instagram.com",
    src: "/images/Media/instagram.svg",
  },
  {
    id: 4,
    link: "www.linkedIn.com",
    src: "/images/Media/linkedIn.svg",
  },
  { id: 5, link: "www.tiktok.com", src: "/images/Media/tiktok.svg" },
  {
    id: 6,
    link: "www.telegram.com",
    src: "/images/Media/telegram.svg",
  },
  {
    id: 7,
    link: "www.whatsapp.com",
    src: "/images/Media/whatsapp.svg",
  },
  {
    id: 8,
    link: "www.pinterest.com",
    src: "/images/Media/pinterest.svg",
  },
  {
    id: 9,
    link: "www.snapchat.com",
    src: "/images/Media/snapchat.svg",
  },
];

const SocialMedia = () => {
  const socialLinksData = useSelector(socialLinkSelector);

  return (
    <div
      style={{ backgroundColor: "#f5f8ff", overflowX: "auto" }}
      className="d-flex justify-content-start border border-start-0 border-end-0 py-3 px-md-4 px-2"
    >
      {/* <button onClick={() => onClickHandle()}>save data</button> */}
      {socialLinksData?.map((SDL, idx) => (
        <SocialLink key={idx} src={SDL.icon} idx={idx} link={SDL} />
      ))}
    </div>
  );
};

export default SocialMedia;

// {
//   /* </div>
//       <div
//         style={{ padding: "6px 8px" }}
//         className="bg-mask me-lg-3 me-lg-2 me-1 border boreder-1 rounded-circle avatar-social bg-white"
//       >
//         <img
//           height={18}
//           src="/images/Media/tweeter.svg"
//           alt="avatar"
//           className=""
//         />
//       </div>
//       <div
//         style={{ padding: "6px 8px" }}
//         className="bg-mask me-lg-3 me-lg-2 me-1 border boreder-1 rounded-circle avatar-social bg-white"
//       >
//         <img
//           height={20}
//           src="/images/Media/instagram.svg"
//           alt="avatar"
//           className=""
//         />
//       </div>
//       <div
//         style={{ padding: "6px 10px" }}
//         className="bg-mask me-lg-3 me-lg-2 me-1 border boreder-1 rounded-circle avatar-social bg-white"
//       >
//         <img
//           height={19}
//           src="/images/Media/linkedIn.svg"
//           alt="avatar"
//           className=""
//         />
//       </div>

//       <div className="dropdown">
//         {/* <div
//                             // style={{ padding: "6px 8px" }}
//                             id="dropdownMenuButton2"
//                             data-bs-toggle="dropdown"
//                             aria-expanded="false"
//                             className="pointer"
//                         >
//                             <img height={42} src="/images/media/add-s.svg" alt="avatar" className="" />
//                         </div> */
// }
// //   <div
// //     style={{ padding: "4px 5px" }}
// //     id="dropdownMenuButton2"
// //     data-bs-toggle="dropdown"
// //     aria-expanded="false"
// //     data-mdb-toggle="tooltip"
// //     title="Add Media"
// //     className="bg-mask me-lg-3 me-lg-2 me-1 border boreder-1 rounded-circle avatar-social bg-white pointer"
// //   >
// //     <img
// //       height={30}
// //       src="/images/Media/add.svg"
// //       alt="avatar"
// //       className=""
// //     />
// //   </div>

// //   <ul
// //     style={{ borderRadius: "10px", width: "200px" }}
// //     className="dropdown-menu dropdown-menu-light py-2 px-0"
// //     aria-labelledby="dropdownMenuButton2"
// //   >
// //     <li className="d-flex align-items-center my-2">
// //       <Link onClick={handleOpen} className="dropdown-item" to="#">
// //         <img
// //           src="/images/Media/tiktok.svg"
// //           alt="avatar"
// //           className="me-2"
// //         />
// //         TikTok
// //       </Link>
// //     </li>

// //     <li className="d-flex align-items-center my-2">
// //       <Link onClick={handleOpen} className="dropdown-item" to="#">
// //         <img
// //           src="/images/Media/telegram.svg"
// //           alt="avatar"
// //           className="me-2"
// //         />
// //         Telegram
// //       </Link>
// //     </li>
// //     <li className="d-flex align-items-center my-2">
// //       <Link onClick={handleOpen} className="dropdown-item" to="#">
// //         <img
// //           src="/images/Media/whatsapp.svg"
// //           alt="avatar"
// //           className="me-2"
// //         />
// //         Whatsapp
// //       </Link>
// //     </li>
// //     <li className="d-flex align-items-center my-2">
// //       <Link onClick={handleOpen} className="dropdown-item" to="#">
// //         <img
// //           src="/images/Media/pinterest.svg"
// //           alt="avatar"
// //           className="me-2"
// //         />
// //         Pinterest
// //       </Link>
// //     </li>
// //     <li className="d-flex align-items-center my-2">
// //       <Link onClick={handleOpen} className="dropdown-item" to="#">
// //         <img
// //           src="/images/Media/snapchat.svg"
// //           alt="avatar"
// //           className="me-2"
// //         />
// //         Snapchat
// //       </Link>
// //     </li>
// //   </ul>
// // </div> */}

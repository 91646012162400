import React from "react";
import "./style.css";
import DoneIcon from "@mui/icons-material/Done";

import { CreditCardsProps } from "./type";

const CreditCard = ({
  acceptedCards = [],
  callback,
  cvc,
  expiry,
  focused,
  issuer,
  locale = {
    valid: "valid thru",
  },
  name,
  id,
  cardId,
  setCardId,
  number,
  placeholders = {
    name: "YOUR NAME HERE",
  },
  preview = false,
}: CreditCardsProps) => {

  return (
    <div
      onClick={() => setCardId(id)}
      className={`pay-card pointer col ${
        cardId == id ? "card-border" : "border-hover"
      }`}
    >
      <div className={`pay__card pay__card--${issuer} position-relative`}>
        <div className="pay__card--front">
          <div className="pay__card__background" />
          <div className="pay__issuer" />
          <div className="pay__number">{number}</div>
          <div className={`pay__name ${name ? "pay--filled" : ""}`}>
            {name || placeholders.name}
          </div>
          <div className="pay__expiry">
            <div className="pay__expiry__valid">{locale.valid}</div>
            <div className="pay__expiry__value">{expiry}</div>
          </div>
          <div className="pay__chip" />
        </div>
        {cardId === id ? (
          <DoneIcon
            style={{ zIndex: 100, color: "#04bac7", fontSize: "28px" }}
            className="position-absolute end-0 top-0 m-1"
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CreditCard;

import AddCardIcon from "@mui/icons-material/AddCard";

const NoPaymentCards = ({ PAYMENT_URL, id, accessToken }) => {
  return (
    <div className="col-4 m-auto text-center">
      <label>
        <img
          src="/images/debit-card.png"
          height={50}
          width={50}
          alt="upload"
          className="pointer"
        />
        <h6 className="mb-0 fw-bold mt-3 mb-1">No Card available yet!</h6>
        <button
          onClick={() =>
            window.open(
              `${PAYMENT_URL}${id}/${accessToken}`,
              "Popup",
              "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=700,top=200,width=500,height=730,visible=none"
            )
          }
          className="btn btn-primary "
        >
          <AddCardIcon style={{ color: "white", marginRight: "5px" }} />
          Add Card
        </button>
      </label>
    </div>
  );
};

export default NoPaymentCards;

import * as React from "react";
import Box from "@mui/material/Box";
import Masonry from "@mui/lab/Masonry";
import { useSelector } from "react-redux";
import {
  createStatusSelector,
  exploreMediaSelector,
  filterSelector,
  paginationSelector,
} from "../../../../reducers/mediaReducer";
import ModalC from "../../../../components/common/Modal";
import InfiniteScroll from "react-infinite-scroll-component";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Video from "yet-another-react-lightbox/plugins/video";
import Share from "yet-another-react-lightbox/plugins/share";
import Download from "yet-another-react-lightbox/plugins/download";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import UserProfileHeader from "../../../../components/common/UserProfileHeader";
import UserProfileFooter from "../../../../components/common/UserProfileFooter";
import { AddMediaModal } from "../../media/components/AddMediaModal";
import MasonryLoading from "./MasonaryLoading";
import ImageProgress from "../../../../components/common/ImageProgress";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";

const ImageMasonry = ({ mediaType, getMedia, filters }) => {
  const exploreMediaData = useSelector(exploreMediaSelector);
  const pagination = useSelector(paginationSelector);
  const filter = useSelector(filterSelector);
  const [mediaUploadProgress, setMediaUploadProgress] =
    React.useState<number>();

  const createStatus = useSelector(createStatusSelector);

  const getNextPageData = () => {
    if (pagination?.nextPageUrl) {
      getMedia(pagination?.nextPageUrl);
    } else {
      return;
    }
  };
  const [addMediaOpen, setAddMediaOpen] = React.useState(false);
  const handleMediaOpen = () => setAddMediaOpen(true);
  const handleMediaClose = () => setAddMediaOpen(false);

  const [showText, setShowText] = React.useState(false);

  const handleMouseEnter = (itemId) => {
    setShowText(itemId);
  };

  const handleMouseLeave = () => {
    setShowText(null);
  };

  const [index, setIndex] = React.useState<React.Key>(0);

  const [isOpen, setIsOpen] = React.useState(false);

  // console.log("filteredData", filteredData);

  const negMargin = {
    marginTop: "-1.4rem",
  };
  const boxStyle = {
    border: "2px #D0D5DD dotted",
    height: filter.isMine ? "230px" : "150px",
  };

  return (
    <InfiniteScroll
      dataLength={exploreMediaData?.length}
      next={() => getNextPageData()}
      hasMore={pagination?.nextPageUrl ? true : false}
      loader={
        <h4>
          <MasonryLoading />
        </h4>
      }
      endMessage={<p className="text-secondary text-center fw-bold">...</p>}
    >
      <Box sx={{ width: "100%", minHeight: 829 }}>
        <Masonry columns={{ xs: 2, sm: 2, md: 4 }} spacing={2}>
          {filter.isMine ? (
            <BootstrapTooltip title="Add Media" placement="top">
              <div>
                <div
                  style={boxStyle}
                  onClick={handleMediaOpen}
                  className="wh-140 rounded-5 d-flex align-items-center justify-content-center pointer"
                >
                  <img
                    height={filter.isMine ? 60 : 50}
                    src="/images/Media/plus.png"
                    alt="upload"
                    // className="mt-4"
                  />
                </div>
              </div>
            </BootstrapTooltip>
          ) : null}
          <>
            {mediaUploadProgress ? (
              mediaUploadProgress !== 100 || createStatus === "loading" ? (
                <ImageProgress
                  progress={mediaUploadProgress}
                  appointment={false}
                />
              ) : (
                ""
              )
            ) : null}
          </>

          {exploreMediaData?.map((data, index) => (
            <div
              className="pointer"
              onClick={() => {
                setIsOpen(true);
                setIndex(index);
                // handleOpen();
              }}
              key={index}
            >
              <figure
                onMouseEnter={() => handleMouseEnter(data?.id)}
                onMouseLeave={handleMouseLeave}
                className={`rounded-5 hover-prime border border-light position-relative mb-0`}
                style={{ position: "relative" }}
              >
                <img
                  className={`rounded-5 border border-1 bg-white`}
                  style={{
                    // backgroundImage: `url(${data?.thumbnail}?w=162&auto=format})`,
                    objectFit: "cover",
                    display: "block",
                    width: "100%",
                    height: filter.isMine ? "230px" : "auto",
                  }}
                  src={`${data?.thumbnail}?w=162&auto=format`}
                  alt="video"
                />
                {data?.type.includes("video") && (
                  <img
                    src="/images/Media/video-play.svg"
                    alt="Image2"
                    style={{
                      height: "70px",
                      width: "70px",
                      position: "absolute",
                      top: "60%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  />
                )}
                {/* {data.tags.length > 0 && showText === data?.id && (
                  <div
                    style={{
                      background:
                        "linear-gradient(to top, #dee2e6, transparent)",
                      borderBottomLeftRadius: "8px",
                      borderBottomRightRadius: "8px",
                      height: "70px",
                    }}
                    className="d-flex m-auto gx-1 flex-wrap ps-1 pb-1 position-absolute bottom-0 w-100 align-items-end"
                  >
                    {/* {data.tags?.map(
                      (
                        tag: {
                          name:
                            | string
                            | number
                            | boolean
                            | React.ReactElement<
                                any,
                                string | React.JSXElementConstructor<any>
                              >
                            | React.ReactFragment
                            | React.ReactPortal;
                        },
                        idx: React.Key
                      ) => (
                        <div
                          key={idx}
                          style={{
                            backgroundColor: "#04bac7",
                            fontSize: "11px",
                            marginTop: data?.isFavourite ? "8px" : "4px",
                          }}
                          className={`px-2 py-1 me-1 rounded-pill ${"text-white"}`}
                        >
                          {tag?.name}
                        </div>
                      )
                    )} */}
                {/* </div> */}
                {/* )} */}
              </figure>
              {data?.isFavourite ? (
                <div
                  style={{ ...negMargin }}
                  className="d-flex justify-content-between px-1"
                >
                  <img src="/images/explore/like.png" alt="like" />
                  <img src="/images/explore/star.png" alt="vic" />
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
          <Lightbox
            styles={{
              container: {
                margin: "auto",
                backgroundColor: "rgba(0, 0, 0, .8)",
              },
            }}
            open={isOpen}
            index={Number(index)}
            close={() => setIsOpen(false)}
            slides={[
              ...exploreMediaData?.map((imageData) => {
                if (imageData?.type.includes("image")) {
                  const data = {
                    ...imageData,
                    open: setIsOpen,
                  };
                  return {
                    src: imageData?.url,
                    title: <UserProfileHeader item={imageData} />,
                    description: <UserProfileFooter item={data} />,
                  };
                } else {
                  const data = {
                    ...imageData,
                    open: setIsOpen,
                  };
                  return {
                    type: "video",
                    width: 1050,
                    height: 550,
                    poster: imageData?.thumbnail,
                    title: <UserProfileHeader item={imageData} />,
                    description: <UserProfileFooter item={data} />,
                    sources: [
                      {
                        src: imageData?.url,
                        type: imageData?.type,
                      },
                    ],
                  };
                }
              }),
            ]}
            render={
              {
                // buttonPrev: () => null,
                // buttonNext: () => null,
              }
            }
            video={{
              autoPlay: true,
              controls: true,
            }}
            plugins={[Captions, Video, Download, Share]}
          />
          {/* <ModalC handleClose={handleClose} open={open}>
          <ViewMediaModal
            explore={true}
            mediaType={item?.type}
            mediaUrl={item?.thumbnail}
            handleClose={handleClose}
            services={item?.service}
            client={item?.client}
          />
        </ModalC> */}
        </Masonry>
        <ModalC handleClose={handleMediaClose} open={addMediaOpen}>
          <AddMediaModal
            handleClose={handleMediaClose}
            mediaUploadProgress={mediaUploadProgress}
            setMediaUploadProgress={setMediaUploadProgress}
          />
        </ModalC>
      </Box>
    </InfiniteScroll>
  );
};

export default React.memo(ImageMasonry);

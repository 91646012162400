import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FAIL, IDLE, LOADING, SUCCESS } from "../../config/constant";
import {
  createServiceRequest,
  fetchOwnServiceRequest,
  fetchServiceByParentRequest,
  fetchThirdLevelServiceRequest,
  updateServiceRequest,
  updateServiceStatusRequest,
} from "../../helper/provider/services";
// import { getAllServices } from "../../../services/Service";
import { RootState } from "../../store";
import { providerServiceSorting } from "../../utils";
import { makeResponseError } from "../../utils/errorHandler";
import { SHOW_SUCCESS } from "../../utils/ToastMessage";

// This type describes the error object structure:
export type FetchTodosError = {
  message: string;
};

export interface serviceState {
  levelTwo: any;
  levelThree: any;
  own: any;
  updatePayload: any;
  rawServiceData?: Array<any>;
  status: string;
  createServiceStatus: string;
  CreateStatus: string;
  UpdateStatus: string;
  level3Status: string;
  error: string | null;
  filter: {
    ownServiceFilter: boolean;
    secondlevelId: number;
  };

  // currentUser: CurrentUser
}

export interface servicePayload {
  data: any;
  success: boolean | null;
}

export const initialState: serviceState = {
  levelTwo: [],
  levelThree: [],
  own: [],
  rawServiceData: [],
  filter: { ownServiceFilter: false, secondlevelId: 0 },
  updatePayload: [],
  status: IDLE,
  createServiceStatus: IDLE,
  level3Status: IDLE,
  CreateStatus: IDLE,
  UpdateStatus: IDLE,
  error: null,
};

export const fetchSecondLevelChildServices = createAsyncThunk(
  "fetch/child/lvl2",
  async (parentId: any, thunkApi) => {
    try {
      const res: any = await fetchServiceByParentRequest(parentId);
      const { data, success } = res.data;
      if (data.length > 0 && success) {
        const sortedData = data.sort((a: any, b: any) =>
          a.name.localeCompare(b.name)
        );
        thunkApi.dispatch(fetchThirdLevelChildServices(sortedData[0].id));
        thunkApi.dispatch(updateSecondLevelServiceId({ id: sortedData[0].id }));
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {}
  }
);

export const fetchThirdLevelChildServices = createAsyncThunk(
  "fetch/child/lvl3",
  async (parentId: any) => {
    try {
      const res: any = await fetchThirdLevelServiceRequest(parentId);
      const { data, success } = res.data;

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {}
  }
);

export const createThirdLevelChildServices = createAsyncThunk(
  "create/child/lvl3",
  async (formData: any, thunkApi) => {
    try {
      const res: any = await createServiceRequest(formData.services);
      const { data, success } = res.data;
      // console.log(data);
      if (success) {
        SHOW_SUCCESS(true);
        // thunkApi.dispatch(fetchThirdLevelChildServices(formData.parentId));
        formData.handleClose();
      }

      return {
        data,
        success,
        code: 201,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
      // alert(error.message);
    }
  }
);

export const updateThirdLevelChildServices = createAsyncThunk(
  "update/child/lvl3",
  async (formData: any, thunkApi) => {
    try {
      const finalData = {
        services: formData.services,
      };
      const res: any = await updateServiceRequest(finalData);
      const { data, success } = res.data;
      // console.log(data);
      if (success) {
        if (formData.id) {
          // thunkApi.dispatch(fetchThirdLevelChildServices(formData.id));
          formData.handleClose();
          SHOW_SUCCESS(true);
        } else {
          // thunkApi.dispatch(
          //   fetchThirdLevelChildServices(formData.services[0].parentId)
          // );
          formData.handleClose();
          SHOW_SUCCESS(true);
        }
      }

      return {
        finalData,
        data,
        success,
        code: 201,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const updateThirdLevelChildServicesStatus = createAsyncThunk(
  "update/status/child/lvl3",
  async (formData: any, thunkApi) => {
    try {
      formData.metaData.setIsStatusChanging(true);
      const res: any = await updateServiceStatusRequest(formData.mainData);
      const { success } = res.data;
      // console.log(data);
      if (success) {
        // formData.metaData.setIsStatusChanging(false);
        // console.log("data", data);
        // thunkApi.dispatch(
        //   fetchThirdLevelChildServices(formData.mainData.parenId)
        // );
      }
      return {
        data: formData.mainData,
        set: formData.metaData.setIsStatusChanging,
        success,
        code: 201,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
      // alert(error.message);
    }
  }
);

export const fetchOwnServices = createAsyncThunk("fetch/services", async () => {
  try {
    const res: any = await fetchOwnServiceRequest();
    const { data, success } = res.data;
    // console.log(data);

    return {
      data,
      success,
      code: 201,
    };
  } catch (error) {
    alert(error.message);
  }
});

const serviceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setOwnServiceFilter: (state, { payload }: PayloadAction<any>) => {
      state.filter.ownServiceFilter = payload;
      if (payload) {
        state.levelThree = state.rawServiceData?.filter((SER) =>
          SER?.providerService !== null && SER?.providerService.status === 1
            ? true
            : false
        );
      } else {
        state.levelThree = providerServiceSorting(state.rawServiceData);
      }
    },

    updateStatus: (state, { payload }: PayloadAction<any>) => {
      const { id, status } = payload;
      const index = state.levelThree.findIndex((SER) => SER.id === id);
      if (index !== -1) {
        if (state.levelThree[index].providerService === null) {
          state.levelThree[index].providerService = {
            ...state.levelThree[index].providerService,
            status: status,
            service_name: state.levelThree[index].name,
            service_description: state.levelThree[index].description,
            duration: state.levelThree[index].duration,
            price: state.levelThree[index].price,
          };
        } else {
          state.levelThree[index].providerService.status = status;
        }
      }
    },

    updateSecondLevelServiceId: (state, { payload }: PayloadAction<any>) => {
      const { id } = payload;
      state.filter.secondlevelId = id;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSecondLevelChildServices.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(
      fetchSecondLevelChildServices.fulfilled,
      (state, { payload }) => {
        const { data, success }: any = payload;
        if (success) {
          // sort data by name
          state.levelTwo = data;
          state.status = "idle";
        }
      }
    );
    builder.addCase(
      fetchSecondLevelChildServices.rejected,
      (state, { payload }) => {
        state.error = payload as string;
        state.status = "idle";
      }
    );

    builder.addCase(
      updateThirdLevelChildServicesStatus.pending,
      (state, action) => {
        state.UpdateStatus = "loading";
        state.error = null;
      }
    );
    builder.addCase(
      updateThirdLevelChildServicesStatus.fulfilled,
      (state, { payload }) => {
        const { data, success, set }: any = payload;

        if (success) {
          set(false);
          const index = state.levelThree.findIndex(
            (SER) => SER.id === data.serviceId
          );
          if (index !== -1) {
            if (state.levelThree[index].providerService === null) {
              state.levelThree[index].providerService = {
                ...state.levelThree[index].providerService,
                id: data.id,
                provider_id: data.providerId,
                service_id: data.serviceId ? data.serviceId : data.service.id,
                status: data.status,
                service_name: state.levelThree[index].name,
                service_description: state.levelThree[index].description,
                duration: state.levelThree[index].duration,
                price: state.levelThree[index].price,
              };

              state.rawServiceData[index].providerService = {
                ...state.rawServiceData[index].providerService,
                id: data.id,
                provider_id: data.providerId,
                service_id: data.serviceId ? data.serviceId : data.service.id,
                status: data.status,
                service_name: state.rawServiceData[index].name,
                service_description: state.rawServiceData[index].description,
                duration: state.rawServiceData[index].duration,
                price: state.rawServiceData[index].price,
              };
            } else {
              state.levelThree[index].providerService.status = data.status;
              state.rawServiceData[index].providerService.status = data.status;
            }
            state.levelThree = providerServiceSorting(state.levelThree);
          }
          state.UpdateStatus = "idle";
        } else {
          state.UpdateStatus = "fail";
        }
      }
    );
    builder.addCase(
      updateThirdLevelChildServicesStatus.rejected,
      (state, { payload }) => {
        state.error = payload as string;
        state.UpdateStatus = "fail";
      }
    );

    builder.addCase(fetchOwnServices.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchOwnServices.fulfilled, (state, { payload }) => {
      const { data, success }: { data?: any; success?: boolean } = payload;
      if (success) {
        state.own = data;
        state.status = "idle";
      }
    });
    builder.addCase(fetchOwnServices.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = "idle";
    });

    builder.addCase(fetchThirdLevelChildServices.pending, (state) => {
      state.level3Status = LOADING;
      state.error = null;
    });
    builder.addCase(
      fetchThirdLevelChildServices.fulfilled,
      (state, { payload }) => {
        const { data, success }: any = payload;
        let tempLevelThree: any;

        if (success) {
          tempLevelThree = providerServiceSorting(data);
          if (state.filter.ownServiceFilter) {
            state.levelThree = tempLevelThree?.filter((SER) =>
              SER?.providerService !== null && SER?.providerService.status === 1
                ? true
                : false
            );
            // console.log(
            //   tempLevelThree?.filter(({ SER }) => SER
            // )?.map((RS) =>
            //     RS?.providerService === null ? RS : null
            //   )
            // );
          } else {
            state.levelThree = tempLevelThree;
          }
          state.rawServiceData = tempLevelThree;

          state.level3Status = SUCCESS;
        }
      }
    );
    builder.addCase(
      fetchThirdLevelChildServices.rejected,
      (state, { payload }) => {
        state.error = payload as string;
        state.level3Status = FAIL;
      }
    );

    builder.addCase(createThirdLevelChildServices.pending, (state, action) => {
      state.createServiceStatus = LOADING;
    });
    builder.addCase(
      createThirdLevelChildServices.fulfilled,
      (state, action) => {
        state.createServiceStatus = SUCCESS;
        const { data } = action.payload;
        const finalData = {
          ...data,
          name: data.service.name,
          providerService: {
            id: data.id,
            provider_id: data.providerId,
            service_id: data.service.id,
            service_name: data.service.name,
            service_description: data.service.description,
            duration: data.service.duration,
            price: data.service.price,
            status: 1,
            created_at: data.service.created_at,
            updated_at: data.service.updated_at,
          },
        };
        state.levelThree.push(finalData);
        state.rawServiceData.push(finalData);

        const tempLevelThree = providerServiceSorting(state.levelThree);
        state.levelThree = tempLevelThree;
      }
    );

    builder.addCase(updateThirdLevelChildServices.pending, (state, action) => {
      state.createServiceStatus = LOADING;
    });
    builder.addCase(
      updateThirdLevelChildServices.fulfilled,
      (state, action) => {
        state.createServiceStatus = SUCCESS;
        const { finalData, data }: any = action.payload;
        const serviceData = {
          ...finalData?.services?.[0],
          providerService: {
            id: data?.[0].id,
            provider_id: data?.[0]?.providerId,
            service_id: data?.[0]?.service?.id,
            service_name: finalData?.services?.[0]?.service_name
              ? finalData?.services?.[0]?.service_name
              : finalData?.services?.[0]?.name,
            service_description: finalData?.services?.[0]?.service_description
              ? finalData?.services?.[0]?.service_description
              : finalData?.services?.[0]?.description,
            duration: finalData?.services?.[0]?.duration,
            price: finalData?.services?.[0]?.price,
            status: 1,
            created_at: finalData?.services?.[0]?.created_at,
            updated_at: finalData?.services?.[0]?.updated_at,
          },
        };
        const index = state.levelThree.findIndex((service) => {
          if (service.providerService === null) {
            return service.id === finalData?.services?.[0]?.id;
          } else {
            return (
              service.providerService.id ===
              finalData?.services?.[0]?.providerServiceId
            );
          }
        });
        if (index !== -1) {
          state.levelThree[index] = serviceData;
        }
      }
    );
    builder.addCase(updateThirdLevelChildServices.rejected, (state, action) => {
      state.createServiceStatus = FAIL;
    });
  },
});

export const { setOwnServiceFilter, updateStatus, updateSecondLevelServiceId } =
  serviceSlice.actions;

export default serviceSlice.reducer;

export const serviceLevelThreeSelector = (state: RootState) =>
  state.service.levelThree;
export const serviceLevelTwoSelector = (state: RootState) =>
  state.service.levelTwo;

export const ownServiceSelector = (state: RootState) => state.service.own;
export const updateServicesStatus = (state: RootState) =>
  state.service.UpdateStatus;

export const level3ServicesStatusSelector = (state: RootState) =>
  state.service.level3Status;

export const servicesParentId = (state: RootState) =>
  state?.auth?.currentUser?.service?.service?.id;

export const ownServiceFilterSelector = (state: RootState) =>
  state.service.filter.ownServiceFilter;

export const selectedServiceSelector = (state: RootState) =>
  state.service.rawServiceData;

export const MultiServiceSelector = (state: RootState) =>
  state.service.rootService.map((u: { id: any; name: any }) => {
    return {
      value: `${u.id}`,
      label: `${u.name}`,
    };
  });

export const secondlevelIdSelector = (state: RootState) =>
  state.service.filter.secondlevelId;

// export const getIsLoggedIn = (state: RootState) => state?.auth.isLoggedIn

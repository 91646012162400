import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChatFilter from "./components/ChatFilter";
import Inbox from "./components/inbox";
import ChatSetup from "./components/ChatSetup";
import { RecentMessages as LoadingRecentMessages } from "./components/UI/Loadings";
import RecentMessages from "./components/RecentMessages";
import {
  getUsersAction,
  resetRoom,
  roomSelector,
  roomStatusSelector,
  statusSelector,
  updateActiveMessage,
  updateLastMessage,
} from "../../reducers/chatReducer";
import { loggedInUserProfile } from "../../reducers/authReducer";
import { AppDispatch } from "../../store";
import { Splitter, SplitterPanel } from "primereact/splitter";
import useWindowSize from "../../hooks/useWindowSize";
import { useLocation } from "react-router-dom";
import echo from "../../hooks/useWebSockets";

const Chat = () => {
  const dispatch = useDispatch<AppDispatch>();

  // selectors
  const roomData = useSelector(roomSelector);
  const authUser = useSelector(loggedInUserProfile);
  const roomStatus = useSelector(roomStatusSelector);

  // hooks
  // const conversations = useConversations(authUser?.mobileNo);
  const [chatInbox, setChatInbox] = useState(false);
  const { width } = useWindowSize();
  const location = useLocation();

  const data = location.state?.data;

  useEffect(() => {
    dispatch(getUsersAction({ data, authUser }));
    if (data) {
      setChatInbox(true);
    }
    return () => {};
  }, [data]);

  useEffect(() => {
    return () => {
      dispatch(resetRoom(null));
      echo
        .private(data?.finalData?.group)
        .stopListening(".private-chat-message");
    };
  }, []);

  useEffect(() => {
    if (data) {
      echo
        .private(data?.finalData?.group)
        .stopListening(".private-chat-message");
      echo
        .private(`notification-${authUser?.id}`)
        .stopListening(".notification-chat");
      echo
        .private(data?.finalData?.group)
        .listen(".private-chat-message", (data: any) => {
          if (data.message.from_user_id !== authUser?.id) {
            // console.log("message data : ", data);
            dispatch(updateActiveMessage({ message: data.message, authUser }));
          }
        });
      echo
        .private(`notification-${authUser?.id}`)
        .listen(".notification-chat", (data: any) => {
          // console.log("notification data : ", data);
          if (data.message.group !== roomData?.active?.id) {
            dispatch(
              updateLastMessage({
                message: data.message,
              })
            );
          }
        });
    }
    return () => {
      // dispatch(resetRoom(null));
    };
  }, [roomData]);

  const memoizedRoomData = useMemo(() => roomData, [roomData]);
  const status = useSelector(statusSelector);

  return (
    <ChatSetup>
      <Splitter>
        <SplitterPanel
          className={`flex align-items-center justify-content-center ${
            width < 600 && chatInbox
              ? "d-none"
              : width > 600
              ? "d-block"
              : "d-block"
          }`}
          size={30}
          minSize={20}
        >
          <ChatFilter />
          <p style={{ fontFamily: "roboto" }} className="fs-6 mt-3 mb-2 m-4">
            All Message
            <span className="text-secondary px-2">
              {memoizedRoomData?.filteredList?.length}
            </span>
          </p>
          <div
            style={{ height: "50vh" }}
            className="px-4 overflow-auto slim-scroll"
          >
            {roomStatus == "loading" ? (
              <LoadingRecentMessages />
            ) : (
              memoizedRoomData?.filteredList?.map((room) => (
                <RecentMessages
                  key={room.id}
                  data={room}
                  setChatInbox={setChatInbox}
                  chatInbox={chatInbox}
                />
              ))
            )}
          </div>
        </SplitterPanel>
        <SplitterPanel
          className={`flex align-items-center justify-content-center ${
            width < 600 && chatInbox
              ? "d-block"
              : width > 600
              ? "d-block"
              : "d-none"
          }`}
          size={70}
          minSize={30}
        >
          <Inbox
            inboxDetails={roomData.active}
            setChatInbox={setChatInbox}
            chatInbox={chatInbox}
          />
        </SplitterPanel>
      </Splitter>
    </ChatSetup>
  );
};
export default Chat;

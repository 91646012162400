import React, { useEffect, useState } from "react";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchLoggedInUserData as providerProfile } from "../../../helper/provider/Auth";
import { fetchFullRootServiceRequest } from "../../../helper/provider/services";
import {
  updateProfile,
  updateProfilePicture,
  updateStatus,
} from "../../../reducers/authReducer";
import { AppDispatch } from "../../../store";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { ErrorMessage, Form, Formik } from "formik";
import { initialProfileState } from "./initialState";
import * as Yup from "yup";
import { TOKEN } from "../../../config/constant";
import useCoordinates from "../../../hooks/useRandomCordinates";
import { localStorageObject } from "../../../utils";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";
import TextArea from "../../../components/common/Textarea";

// type profileDataType = {
//   firstName?: string;
//   lastName?: string;
//   username?: string;
//   category?: any;
//   businessName?: string;
//   email?: string;
//   mobileNo?: string;
// };

interface Options {
  label: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

const UserProfile = () => {
  const [profileData, setProfileData] = useState<any>(initialProfileState);
  const [categories, setCategories] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [Image, setImage] = useState<any>({
    image: { blob: "", src: null },
  });

  const [coordinates, generateCoordinates] = useCoordinates();
  const [mobileError, setMobileError] = useState(true);

  // const { access_token } = useSelector((state: any) => state.auth);

  useEffect(() => {
    getProfile();
    getCategories();
    generateCoordinates();
    return () => {};
  }, []);

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      // console.log("img", img);
      setImage({
        ...Image,
        image: {
          blob: img,
          src: URL.createObjectURL(img),
        },
      });
      let data = new FormData();
      data.append("file", img);
      dispatch(updateProfilePicture({ data: data, token: token }));
    }
  };

  var token = localStorageObject.get(TOKEN);
  // console.log("token", token);

  const getProfile = async () => {
    const profile = await providerProfile(token);
    const data = profile.data.data;
    setProfileData(data);
    // console.log("profile data", data);
  };

  const getCategories = async () => {
    const res = await fetchFullRootServiceRequest(token);
    const categoires = res.data.data;
    setCategories(categoires);

    // console.log("categories", categoires);
  };

  const validationSchema = Yup.object().shape({
    serviceId: Yup.string().nullable().required("Category is required"),
    businessName: Yup.string().nullable().required("Business Name is required"),
    address: Yup.string().nullable().required("Address is required"),
    email: Yup.string()
      .nullable()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Email is required"),
    bio: Yup.string().nullable().max(255, "Max 255 characters allowed"),
  });

  const status = useSelector(updateStatus);

  const [options, setOptions] = useState<Options[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAddressChange = async (e) => {
    setLoading(true);
    if (e.target.value.length > 3) {
      const res: any = await axios.post(
        "https://api.postgrid.com/v1/addver/completions",
        {
          partialStreet: e.target.value,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_POSTGRID_API_KEY,
          },
        }
      );
      const data = res.data.data;
      if (data?.length !== 0) {
        setOptions(
          data.map((m) => {
            return {
              label:
                m?.address?.address +
                " " +
                m?.address?.city +
                " " +
                m?.address?.prov +
                ", " +
                m?.address?.pc,
              street: m?.address?.address,
              city: m?.address?.city,
              state: m?.address?.prov,
              zip: m?.address?.pc,
            };
          })
        );
      } else {
        setLoading(false);
        setOptions([]);
      }
    }
  };

  useEffect(() => {
    if (options?.length !== 0) {
      setOptions(options);
    }
    return () => {};
  }, [options]);

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();

  //   console.log("profileData => ", profileData);
  //   // alert("under development");
  // };

  return (
    <div
      style={{ height: "100%", position: "relative" }}
      className="container-fluid px-0 h-100-hidden bg-white"
    >
      <div className="row w-100 justify-content-center">
        {/* <div className="col-lg-1 d-lg-block d-none">
          <img
            src="/images/custom/undraw_complete_design_re_h75h.svg"
            height={250}
            width={250}
            className="mt-1"
            alt=""
            style={{
              opacity: "0.5",
            }}
          />
        </div> */}
        <div className="col-lg-7">
          <div style={{ height: "auto" }}>
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                className="me-2"
                height={35}
                width={35}
                src="/images/KZH-PRO.jpeg"
                alt=""
              />
              <img src="/images/KZH-Written.svg" alt="" />
            </div>
            <div className="m-5 py-md-0 px-md-5 p-0 rounded">
              <h4
                style={{ fontFamily: "Poppins, sans-serif" }}
                className="fw-bolder text-center me-1"
              >
                Profile
              </h4>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center fw-bold mb-4"
              >
                {/* Hi, This is your bio! What do you want people to know? */}
              </p>
              <label className="d-flex flex-column justify-content-center align-items-center mb-4 pointer">
                <div className="row bg-border justify-content-center">
                  <div className="col-4 m-auto pe-8">
                    <img
                      className="rounded-circle"
                      style={{ objectFit: "cover" }}
                      height={70}
                      width={70}
                      src={
                        Image?.image?.src
                          ? Image?.image?.src
                          : "/no-photo-available.png"
                      }
                      alt=""
                    />
                  </div>
                </div>
                {/* <label
                  style={{
                    marginTop: "-2.3rem",
                    marginRight: "-4rem",
                  }}
                > */}
                <AddCircleIcon
                  fontSize="large"
                  sx={{
                    color: "#04bac7",
                    marginTop: "-2.3rem",
                    marginRight: "-4rem",
                  }}
                />
                <input
                  onChange={onImageChange}
                  type="file"
                  name="image"
                  id="image"
                  hidden
                />
                {/* </label> */}
              </label>
              <div className="form">
                <Formik
                  enableReinitialize={true}
                  initialValues={profileData}
                  onSubmit={(values: any) => {
                    let finalData = {};
                    if (coordinates) {
                      finalData = {
                        ...values,
                        latitude: coordinates?.lat,
                        longitude: coordinates?.lng,
                      };
                    } else {
                      finalData = {
                        ...values,
                        latitude: 0,
                        longitude: 0,
                      };
                    }
                    // console.log("finalData", finalData);

                    dispatch(
                      updateProfile({ data: finalData, navigate, token: token })
                    );
                    // console.log("values", values);
                  }}
                  validationSchema={validationSchema}
                >
                  {({ errors, touched, values, handleBlur, handleChange }) => (
                    <Form>
                      <div className="row">
                        <div className="col form-group">
                          <label htmlFor="firstName" className="form-label">
                            Full name
                          </label>
                          <div className="d-flex">
                            <input
                              name=""
                              type="text"
                              // onChange={handleChange}
                              className="form-control py-2 me-1"
                              id="firstName"
                              disabled
                              value={profileData?.firstName}
                              placeholder="First Name"
                            />
                            <input
                              name=""
                              type="text"
                              // onChange={handleChange}
                              className="form-control py-2"
                              id="firstName"
                              disabled
                              value={profileData?.lastName}
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                        {/* <div className="form-group col-md-6">
                          <label htmlFor="username" className="form-label">
                            Username
                          </label>
                          <Field
                            name="username"
                            type="text"
                            // onChange={(e: any) => {
                            //   handleChange(e);
                            //   setProfileData({
                            //     ...profileData,
                            //     username: e.target.value,
                            //   });
                            // }}
                            className={`form-control py-2 ${
                              errors.username && touched.username
                                ? "is-invalid"
                                : ""
                            }`}
                            value={profileData?.username}
                            readOnly
                            id="username"
                            placeholder="Enter your username"
                          />
                          <ErrorMessage
                            name="username"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div> */}
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="customerType" className="form-label">
                          Category
                        </label>
                        <select
                          id="customerType"
                          name="serviceId"
                          className={`form-select ${
                            errors.serviceId && touched.serviceId
                              ? "is-invalid"
                              : ""
                          }`}
                          onChange={handleChange}
                        >
                          <option value="">Select Category</option>
                          {categories?.map((u: any) => (
                            <option key={u.id} value={u.id}>
                              {u.name}
                            </option>
                          ))}
                        </select>
                        <ErrorMessage
                          name="serviceId"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mt-3 form-group">
                        <label htmlFor="firstName" className="form-label">
                          Business name
                        </label>
                        <input
                          name="businessName"
                          type="text"
                          onChange={handleChange}
                          className={`form-control py-2 ${
                            errors.businessName && touched.businessName
                              ? "is-invalid"
                              : ""
                          }`}
                          id="firstName"
                          value={profileData?.businessName}
                          placeholder="Enter Your Business Name"
                        />
                        <ErrorMessage
                          name="businessName"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="mt-3 row">
                        <div className="form-group">
                          <label htmlFor="address" className="form-label">
                            Address
                          </label>
                          <Autocomplete
                            id="asynchronous-demo"
                            freeSolo
                            isOptionEqualToValue={(option, value) => {
                              return option.street === value.street;
                            }}
                            getOptionLabel={(option: any) => option.label}
                            options={options}
                            open={open}
                            sx={{ width: "100%", marginBottom: "8px" }}
                            onChange={(e, value) => {
                              const data: any = value;
                              handleChange({
                                target: {
                                  name: "street",
                                  value: data?.street,
                                },
                              });
                              handleChange({
                                target: {
                                  name: "city",
                                  value: data?.city,
                                },
                              });
                              handleChange({
                                target: {
                                  name: "state",
                                  value: data?.state,
                                },
                              });
                              handleChange({
                                target: {
                                  name: "zip",
                                  value: data?.zip,
                                },
                              });
                            }}
                            onError={(e) => {
                              console.log(e);
                              setOptions([]);
                            }}
                            onBlur={(e) => {
                              setOptions([]);
                              setLoading(false);
                            }}
                            onOpen={() => {
                              setOpen(true);
                            }}
                            onClose={() => {
                              setOpen(false);
                            }}
                            filterOptions={(x) => x}
                            loading={loading}
                            noOptionsText="No Address Found"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                placeholder="Enter Your Address"
                                // className="autocomplete"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "no",
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      border: "1px solid #ced4da",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#ced4da",
                                    },
                                    "&.Mui-focused fieldset": {
                                      border: "1px solid #ced4da",
                                      boxShadow: "0 0 0 0.25rem #cdf1f4",
                                    },
                                  },
                                }}
                                size="small"
                                error={
                                  errors.address && touched.address
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleAddressChange(e);
                                  handleChange({
                                    target: {
                                      name: "address",
                                      value: e.target.value,
                                    },
                                  });
                                }}
                              />
                            )}
                          />
                          {errors.address && touched.address ? (
                            <small className="text-danger mt-0">
                              Address is required
                            </small>
                          ) : null}
                          <input
                            name="street"
                            type="text"
                            // onChange={handleChange}
                            value={values?.street}
                            className={`form-control mb-2 py-2 ${
                              errors.street && touched.street
                                ? "is-invalid"
                                : ""
                            }`}
                            id="street"
                            disabled={true}
                            placeholder="Street Address"
                          />
                          <ErrorMessage
                            name="street"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="d-flex gap-1">
                          <input
                            name="city"
                            type="text"
                            // onChange={handleChange}
                            value={values?.city}
                            className={`form-control py-2 ${
                              errors.city && touched.city ? "is-invalid" : ""
                            }`}
                            id="city"
                            disabled={true}
                            placeholder="City"
                          />
                          <ErrorMessage
                            name="city"
                            component="span"
                            className="invalid-feedback"
                          />
                          <input
                            type="text"
                            id="principals"
                            name="state"
                            className={`form-control py-2 ${
                              errors.state && touched.state ? "is-invalid" : ""
                            }`}
                            // onChange={handleChange}
                            disabled={true}
                            value={values?.state}
                            placeholder="State"
                          />
                          <ErrorMessage
                            name="state"
                            component="span"
                            className="invalid-feedback"
                          />
                          <input
                            name="zip"
                            type="string"
                            min={0}
                            // onChange={(e) => {
                            //   if (
                            //     e.target.value.match(/[a-zA-Z]/g) ||
                            //     e.target.value.match(
                            //       /[!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g
                            //     )
                            //   ) {
                            //     e.preventDefault();
                            //   } else {
                            //     handleChange(e);
                            //   }
                            // }}
                            value={values?.zip}
                            disabled={true}
                            className={`form-control py-2 ${
                              errors.zip && touched.zip ? "is-invalid" : ""
                            }`}
                            id="zip"
                            placeholder="Zip"
                          />
                          <ErrorMessage
                            name="zip"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="mt-3 form-group">
                        <label htmlFor="bio" className="form-label">
                          Bio
                        </label>
                        {/* <Field
                          as="textarea"
                          name="bio"
                          onChange={handleChange}
                          className={`form-control py-2 ${
                            errors.bio && touched.bio ? "is-invalid" : ""
                          }`}
                          id="bio"
                          style={{
                            resize: "none",
                          }}
                          rows={6}
                          value={values?.bio}
                          placeholder="Enter Your Bio"
                        /> */}
                        <TextArea
                          handleChange={(e) => {
                            handleChange(e);
                          }}
                          errors={errors?.bio}
                          touched={touched?.bio}
                          name={"bio"}
                          value={values?.bio}
                          toggle={null}
                          placeholder={"Enter Your Bio"}
                        />
                      </div>
                      <div className="mt-3 form-group">
                        <label htmlFor="mobileNo" className="form-label">
                          Phone number
                        </label>
                        <input
                          name=""
                          type="text"
                          // onChange={handleChange}
                          className="form-control py-2"
                          id="firstName"
                          value={profileData?.mobileNo}
                          disabled
                          placeholder="Enter Your Number"
                          required
                        />
                        {/* <PhoneInputWithCountrySelect
                      disabled
                      name="mobileNo"
                      id="mobileNo"
                      // value="+923214249423"
                      className="Cus-form-control py-2"
                      international
                      placeholder="Enter phone number"
                      value={profileData?.mobileNo}
                      onChange={(e) =>
                        setProfileData({
                          ...profileData,
                          mobileNo: e,
                        })
                      }
                    /> */}
                      </div>
                      <div className="mt-3 form-group">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          name="email"
                          type="email"
                          onChange={handleChange}
                          className={`form-control py-2 ${
                            errors.email && touched.email ? "is-invalid" : ""
                          }`}
                          value={profileData?.email}
                          id="email"
                          placeholder="Enter You Email"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                      <button
                        style={{ backgroundColor: "#04BAC7" }}
                        className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                        type="submit"
                      >
                        {status === "loading" ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          <>Save & Next</>
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="col-lg-6 p-0 d-lg-block d-none">
          <div
          style={{ height: "190vh", backgroundColor: "#eff3fc" }}
            className="align-items-center d-flex justify-content-center bg-register"
            ></div>
        </div> */}
      </div>
    </div>
  );
};

export default UserProfile;

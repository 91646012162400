import { useEffect, useState } from "react";
import FilterButton from "../../../components/common/Button/FilterBtn";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ClientsLoading from "./components/ClientsLoading";
import { AppDispatch, RootState } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  clientListSelector,
  fetchMemberList,
} from "../../../reducers/clientReducer";
import Clients from "./components/Clients";
import { ConnectionStatus } from "../../../components/common/types";

const ClientHistory = () => {
  const [isHistory, setIsHistory] = useState<ConnectionStatus>(
    ConnectionStatus.APPROVED
  );
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchMemberList(isHistory));
  }, [isHistory]);

  const filteredClientListData = useSelector(clientListSelector);
  const { status } = useSelector((state: RootState) => state.client);
  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "72vh" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="row mx-auto my-4 px-2 py-3 border-bottom align-items-center">
          <div className="col-3">
            <div className="d-flex flex-sm-row flex-column justify-content-between">
              <h5 className="main-head me-sm-5 mb-xl-0 mb-3">Members</h5>
            </div>
          </div>
          <div className="col-9 ms-auto d-flex flex-sm-row flex-column justify-content-end">
            <div
              className="btn-group ms-sm-3 mt-lg-0 mt-2"
              role="group"
              aria-label="Basic outlined example"
            >
              <FilterButton
                onClick={() => {
                  setIsHistory(ConnectionStatus.APPROVED);
                }}
                active={isHistory === ConnectionStatus.APPROVED}
                tooltip="Approved"
              >
                <CheckCircleOutlineOutlinedIcon
                  className="pointer my-1"
                  sx={{ color: "#383838" }}
                />
              </FilterButton>
              <FilterButton
                onClick={() => {
                  setIsHistory(ConnectionStatus.DECLINED);
                }}
                active={isHistory === ConnectionStatus.DECLINED}
                tooltip="Declined"
              >
                <CancelOutlinedIcon
                  className="pointer my-1"
                  sx={{ color: "#383838" }}
                />
              </FilterButton>
              <FilterButton
                onClick={() => {
                  setIsHistory(ConnectionStatus.WAITLIST);
                }}
                active={isHistory === ConnectionStatus.WAITLIST}
                tooltip="Wait List"
              >
                <AccessTimeIcon
                  className="pointer my-1"
                  sx={{ color: "#383838" }}
                />
              </FilterButton>
            </div>
          </div>
        </div>
        {status === "loading" ? (
          <div className="row mx-auto my-2 px-2 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 align-items-center">
            <ClientsLoading />
          </div>
        ) : (
          <div className="row mx-auto my-2 px-2 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 align-items-center">
            {filteredClientListData.map((data, idx) => (
              <Clients
                key={idx}
                id={data?.id}
                src={data?.profileImage}
                userName={data?.firstName + " " + data?.lastName}
                next={data?.oldestAppointment?.appointment_date}
                prev={data?.lastAppointment?.appointment_date}
                isVic={data?.isVic}
                mobileNo={data?.mobileNo}
                data={data}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClientHistory;

import React from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import { AppDispatch } from "../../../../store";

const SlotChip = (time: {
  startTime: string;
  endTime: string;
  scheduleData;
  setScheduleData;
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleTimeChange = (e) => {
    time.setScheduleData({
      ...time.scheduleData,
      startTime: e.startTime,
      endTime: e.endTime,
    });
  };

  return (
    <div
      style={{
        fontSize: "14px",
        backgroundColor: "rgb(243, 244, 246)",
        borderRadius: "6px",
      }}
      className="form-check p-2 mb-2"
    >
      <input
        className="form-check-input ms-1 me-2"
        type="radio"
        onChange={() => {
          //format time
          const startTime = moment(time.startTime, "hh:mm A").format(
            "HH:mm:ss"
          );
          const endTime = moment(time.endTime, "hh:mm A").format("HH:mm:ss");
          handleTimeChange({ startTime, endTime });
        }}
        name="selectedTimeSlot"
        id={time.startTime.trim()}
      />
      <label className="form-check-label me-1" htmlFor={time.startTime.trim()}>
        {moment(time.startTime, "HH:mm:ss").format("hh:mm A")}
      </label>
    </div>
  );
};

export default SlotChip;

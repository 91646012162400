import React from "react";
import { Skeleton } from "@mui/material";
import { COLORS } from "../styles";

const MediaSkeleton = () => {
  return (
    <div
      style={{
        borderRadius: "10px",
        ...COLORS.bgLightGrey,
      }}
      className="d-flex hover-light py-2 mt-2 px-2 align-items-center"
    >
      <Skeleton className="me-3" variant="circular" width={40} height={40} />
      <div style={{ height: "40px", overflow: "hidden" }} className="">
        <p className="mb-0" style={{ fontSize: "13px", fontWeight: 600 }}>
          <Skeleton className="mb-1" width={100} animation="pulse" />
        </p>
        <p style={{ color: "grey", fontSize: "12px" }} className="mb-0">
          <Skeleton
            className="mb-2"
            height={15}
            width={150}
            animation="pulse"
          />
        </p>
      </div>
    </div>
  );
};

const RecentMessages = () => {
  return (
    <>
      <MediaSkeleton />
      <MediaSkeleton />
      <MediaSkeleton />
      <MediaSkeleton />
      <MediaSkeleton />
    </>
  );
};

export { RecentMessages };

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { useDispatch } from "react-redux";
import { deleteSchedule } from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";

const ScheduleDeleteModal = ({ handleClose, id, type }) => {


    const dispatch = useDispatch<AppDispatch>();

    const handleDelete = () => {
        dispatch(deleteSchedule({ id: id, formData: { type: type } }));
    };

    return (
        <>
            <div className="d-flex justify-content-start align-items-start">
                <WarningAmberRoundedIcon
                    className="me-2"
                    sx={{ fontSize: 45, color: '#dc3545' }}
                    fontSize="small"
                />
                <div>
                    <Typography
                        className="text-start mb-1"
                        id="modal-modal-title"
                        variant="h6"
                        component="h6"
                    >
                        Are you sure?
                    </Typography>
                    <p className="mb-2 text-start">
                        Do you really want to delete this? This <br /> Process cannot be undone.
                    </p>
                </div>
            </div>
            <div className="d-flex justify-content-end">
                <div>
                    <button
                        onClick={() => {
                            handleClose();
                        }}
                        type="button"
                        className="btn bg-light-grey mt-4 px-5 mx-2"
                        aria-label="submit data"
                    >
                        No
                    </button>
                </div>
                <div>
                    <button
                        onClick={() => {
                            handleClose();
                            handleDelete()
                        }}
                        type="button"
                        className="btn btn-primary mt-4 px-5 ms-2 me-0"
                        aria-label="submit data"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </>
    );
};

export default ScheduleDeleteModal;

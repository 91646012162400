import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";

export const getAllCredentialsRequest = async () => {
  try {
    return await client.get(provider_URLS.credentials.CREDENTIALS_MAIN);
  } catch (error) {
    return error;
  }
};

export const getOneCredentialsRequest = async (id: number) => {
  try {
    return await client.get(provider_URLS.credentials.CREDENTIALS_SINGLE(id));
  } catch (error) {
    return error;
  }
};

export const createCredentialsRequest = async (data: any) =>
  client.post(provider_URLS.credentials.CREDENTIALS_MAIN, data);

export const updateCredentialsRequest = async (id: number, data: any) =>
  client.put(provider_URLS.credentials.CREDENTIALS_SINGLE(id), data);

export const deleteCredentialsRequest = async (id: number) =>
  client.delete(provider_URLS.credentials.CREDENTIALS_SINGLE(id));

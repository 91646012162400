import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { fetchMemberProfile, memberProfile, profileStatusSelector } from "../../../../reducers/clientReducer";
import { AppDispatch } from "../../../../store";
import { reverseArry } from "../../../../utils";
import MediaCard from "../../media/components/MediaImg";
import MediaSkeletonLoading from "../../media/components/MediaSkeletonLoading";

const MediaView = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { Id }: any = useParams();
    useEffect(() => {
        dispatch(fetchMemberProfile(Id));
        return () => { };
    }, [Id, dispatch]);

    const profile = useSelector(memberProfile);
    const status = useSelector(profileStatusSelector);

    return (
        <>
            <div className="mx-lg-5 my-4" >
                <div
                    style={{ minHeight: "72vh", maxWidth: "800px" }}
                    className="container-fluid bg-white rounded-5 px-4 py-3"
                >
                    <p className={`text-dark text-start`} style={{ fontSize: "18px" }}>
                        Media:
                    </p>
                    {/* <div className="row g-2 row-cols-xl-4 row-cols-md-3 row-cols-2 w-100 m-auto pb-4 justify-content-center mb-1"> */}
                    {status == "loading" ? (
                        <MediaSkeletonLoading />
                    ) : (
                        reverseArry(profile?.media)?.map(({ thumbnail, url, type, tags, isLoading }) => (
                            <MediaCard
                                thumbnail={thumbnail}
                                url={url}
                                mediaType={type}
                                services={tags}
                                isLoading={isLoading}
                            />
                        ))
                    )}
                    {/* </div> */}
                </div>
            </div>
        </>
    );
};

export default MediaView;

import { localStorageObject } from ".";

export interface AuthModel {
  access_token: string;
  refresh_token?: string;
}

// constant
export const AUTH_LOCAL_STORAGE_NAME = "KAZZAH_PRO_AUTH_TOKEN";

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return;
  }

  const lsValue: string | null = localStorageObject.get(
    AUTH_LOCAL_STORAGE_NAME
  );
  if (!lsValue) {
    return;
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel;
    if (auth) {
      return auth;
    }
  } catch (error) {
    console.error("AUTH LOCAL STORAGE PARSE ERROR", error);
  }
};

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return;
  }

  try {
    const lsValue = JSON.stringify({
      access_token: auth?.access_token,
      refresh_token: auth?.refresh_token,
    });
    localStorageObject.set(AUTH_LOCAL_STORAGE_NAME, lsValue);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE SAVE ERROR", error);
  }
};

const removeAuth = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorageObject.remove(AUTH_LOCAL_STORAGE_NAME);
  } catch (error) {
    console.error("AUTH LOCAL STORAGE REMOVE ERROR", error);
  }
};

export { getAuth, setAuth, removeAuth };

import React, { useState } from "react";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { getDayName } from "../../../../../utils";
import { AppDispatch } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { servicePlayPauseAction } from "../../../../../reducers/scheduleReducer";
import { loggedInUserProfile } from "../../../../../reducers/authReducer";

const ActiveServices = ({ data }) => {
  const dispatch = useDispatch<AppDispatch>();
  const authUserProfile = useSelector(loggedInUserProfile);

  const handleClick = (service) => {
    const serviceData = {
      scheduleId: data?.id,
      serviceId: service?.id,
      isPaused: service?.isPaused ? 0 : 1,
    };
    dispatch(servicePlayPauseAction(serviceData));
  };

  return (
    <>
      {data?.services?.map((services) => (
        <div
          className={`rounded my-3 px-md-4 py-3 ${
            !Boolean(Number(services?.isPaused)) ? "bg-primary" : "bg-light"
          }`}
          key={services?.id}
        >
          {/* <h5
                className='pointer text-gray-800 text-hover-primary fw-bolder mb-n4 py-5'
            >
                Services
            </h5> */}
          <div className="row align-items-end">
            <div className="col-4 text-start my-auto">
              <span className="fw-bold d-block">
                {services?.serviceName?.name}
              </span>
              {/* <GroupIcon sx={{ fontSize: 30 }} /> */}
            </div>
            {/* <div className="col-3 text-start px-md-2 pe-0">
              <label
                style={{ fontSize: "0.9rem" }}
                className="text-dark me-1 mb-1"
                htmlFor="NoAte"
              >
                Schedule Days
              </label>
              <div className="d-flex align-items-center">
                {data?.days?.map((data, idx, arr) => (
                  <div
                    key={data}
                    style={{ height: "25px", width: "25px", fontSize: "10px" }}
                    className={`${"bg-light-grey"} rounded-circle p-1 me-1 d-flex align-items-center justify-content-center pointer w-days`}
                  >
                    <p className={`m-0 p-0 ${"text-secondary"}`}>
                      {getDayName(data)}
                    </p>
                  </div>
                  // <span key={id} style={{ display: "inline-block" }}>
                  //   <span style={{ margin: "4px" }}></span>
                  //   {arr?.length > 0 && !(idx + 1 === arr.length) ? ` / ` : ` `}
                  // </span>
                ))}
              </div>
            </div> */}
            <div className="col text-end">
              <div className="text-center">
                <label
                  style={{ fontSize: "0.9rem" }}
                  className="d-block text-dark"
                  htmlFor="NoAte"
                >
                  {data?.servicesLocation
                    ? data?.servicesLocation
                    : authUserProfile?.street + " " + authUserProfile?.city}
                </label>
                <span style={{ fontSize: "14px" }} className="text-center mt-2">
                  {data?.startTime}
                </span>
                -
                <span
                  style={{ fontSize: "14px" }}
                  className="text-center text-dark mt-2"
                >
                  {data?.endTime}
                </span>
              </div>
            </div>
            <div className="col text-end">
              <span
                onClick={() => {
                  handleClick({
                    isPaused: Boolean(Number(services?.isPaused)),
                    id: services?.service?.id,
                  });
                }}
              >
                {!Boolean(Number(services?.isPaused)) ? (
                  <PauseCircleIcon sx={{ fontSize: 30 }} className="pointer" />
                ) : (
                  <PlayCircleIcon sx={{ fontSize: 30 }} className="pointer" />
                )}
              </span>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ActiveServices;

import React, { useState, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { PaymentRecord } from "../../../reducers/paymentReducer";
import BeginPayment from "./components/BeginPayment";
import CompletedPayment from "./components/CompletedPayment";
import PayStep1 from "./components/BusinessInfo";
import PayStep2 from "./components/PayStep2";
import PayStep3 from "./components/PayStep3";
import PayStep4 from "./components/PayStep4";
import { MerchantOnBoarding } from "../../../components/common/types";
import { loggedInUserProfile } from "../../../reducers/authReducer";
import StepIndication from "./components/StepIndication";

const Payment = () => {
  const [changeStep, setChangeStep] = useState();

  const record = useSelector(PaymentRecord);
  const authUser = useSelector(loggedInUserProfile);

  const [data, setData] = useState<MerchantOnBoarding>();
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.checked ? true : false,
    });
  };

  useLayoutEffect(() => {
    setData({
      ...record,
      contacts: record?.contacts ? record?.contacts : [],
      ownership: record?.ownership ? record?.ownership : [],
      id: record?.id ? record?.id : "",
      dbaName: authUser?.businessName,
      legalName: authUser?.firstName + " " + authUser?.lastName,
      taxFillName: authUser?.firstName + " " + authUser?.lastName,
      phoneNo: authUser?.mobileNo,
      bAddress: authUser?.street,
      bCity: authUser?.city,
      bState: authUser?.state,
      bZip: authUser?.zip,
      mAddress: authUser?.street,
      mCity: authUser?.city,
      mState: authUser?.state,
      mZip: authUser?.zip,
      singerName: authUser?.firstName + " " + authUser?.lastName,
      singerPhone: authUser?.mobileNo,
      singerEmail: authUser?.email,
      singerAddress: authUser?.street,
      singerState: authUser?.state,
      singerCity: authUser?.city,
      singerZip: authUser?.zip,
    });
    // console.log("data", data);
  }, [record]);

  return (
    <>
      {changeStep == "begin" ? (
        <StepIndication setChangeStep={setChangeStep} />
      ) : // <PayStep1
      //   data={data}
      //   handleDataChange={handleChange}
      //   setChangeStep={setChangeStep}
      // />
      changeStep == "Step1" ? (
        <PayStep1
          data={data}
          handleDataChange={handleChange}
          setChangeStep={setChangeStep}
        />
      ) : changeStep == "Step2" ? (
        <PayStep2
          data={data}
          handleDataChange={handleChange}
          setChangeStep={setChangeStep}
        />
      ) : changeStep == "Step3" ? (
        <PayStep3
          data={data}
          handleDataChange={handleChange}
          setChangeStep={setChangeStep}
          setData={setData}
          handleCheckChange={handleCheckChange}
        />
      ) : changeStep == "Step4" ? (
        <PayStep4
          data={data}
          handleDataChange={handleChange}
          setChangeStep={setChangeStep}
          handleCheckChange={handleCheckChange}
        />
      ) : changeStep == "CompletedPayment" ? (
        <CompletedPayment setChangeStep={setChangeStep} />
      ) : changeStep == "ToPay1" ? (
        <PayStep1
          data={data}
          handleDataChange={handleChange}
          setChangeStep={setChangeStep}
        />
      ) : changeStep == "ToPay2" ? (
        <PayStep2
          data={data}
          handleDataChange={handleChange}
          setChangeStep={setChangeStep}
        />
      ) : changeStep == "ToPay3" ? (
        <PayStep3
          data={data}
          handleDataChange={handleChange}
          setChangeStep={setChangeStep}
          setData={setData}
          handleCheckChange={handleCheckChange}
        />
      ) : changeStep == "start" ? (
        <BeginPayment setChangeStep={setChangeStep} />
      ) : (
        <BeginPayment setChangeStep={setChangeStep} />
      )}
    </>
  );
};

export default Payment;

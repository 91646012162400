import { useEffect, useMemo, useState, useCallback } from "react";
// import { TagsInput } from "react-tag-input-component";
// import ReactTags from "react-tag-autocomplete";

import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  addAppointmentMedia,
  updateImageModalStatus,
} from "../../../../reducers/appointmentReducer";
import { useDropzone } from "react-dropzone";
import { addPhoto } from "../../../../helper/provider/appointment";
import { LOADING, SUCCESS } from "../../../../config/constant";
import * as yup from "yup";
import useCoordinates from "../../../../hooks/useRandomCordinates";
import { MultiServiceLevelTwoSelector } from "../../../../reducers/mediaReducer";
import TextArea from "../../../../components/common/Textarea";
import { Form, Formik } from "formik";
import Select from "react-select";
import { IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { CS_Styles } from "../../../../utils";
import makeAnimated from "react-select/animated";

export const AddPhotoModel = ({
  handleClose,
  appointmentId,
  index,
  mediaUploadProgress,
  setMediaUploadProgress,
}) => {
  const [imageData, setImageData] = useState<any>({
    image: { blob: "", src: null },
    type: "",
    location: "",
    latitude: "",
    longitude: "",
    caption: "",
    tags: "",
    isPrivate: 1,
    appointmentId: "",
    services: [],
  });
  // console.log(index);

  const [error, setError] = useState(false);
  const [type, setType] = useState(false);
  const [videoError, setVideoError] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const onDrop = useCallback((acceptedFiles) => {
    setError(false);
    setType(false);
    onImageChange(acceptedFiles[0]);
  }, []);

  const rejectedFiles = useCallback((rejectedFiles) => {
    // console.log("rejectedFiles", rejectedFiles);
    setType(true);
  }, []);

  const [coordinates, generateCoordinates]: any = useCoordinates();

  useEffect(() => {
    generateCoordinates();
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected: rejectedFiles,
    accept: {
      "image/png": [".png"],
      "video/mp4": [".mp4"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "70px",
    margin: "0px 5px 0px 0px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: error || type ? "#ff1744" : "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
    padding: "70px",
  };

  const acceptStyle = {
    borderColor: "#00e676",
    padding: "94px",
  };

  const rejectStyle = {
    padding: "94px",
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, type, error]
  );

  const onImageChange = (event) => {
    if (event) {
      let img = event;
      // console.log("img", img);
      setVideoError(false);
      if (img.type.includes("video")) {
        let video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          console.log("video duration", video.duration);
          if (video.duration > 5) {
            setVideoError(true);
            return;
          } else {
            setImageData({
              ...imageData,
              image: {
                blob: img,
                src: URL.createObjectURL(img),
              },
            });
          }
        };
        video.src = URL.createObjectURL(img);
        // setImageData({
        //   ...imageData,
        //   image: {
        //     blob: img,
        //     src: URL.createObjectURL(img),
        //   },
        // });

        // console.log("video", video);
      } else {
        setImageData({
          ...imageData,
          image: {
            blob: img,
            src: URL.createObjectURL(img),
          },
        });
      }
    }
  };

  function onUploadProgress({ loaded, total }) {
    let percentage = (loaded / total) * 100;
    // console.log("MEDIA loading data : ", percentage);

    setMediaUploadProgress((prev) => (prev = percentage));
  }
  //   console.log(imageData);

  const handleSubmit = (e: any) => {
    // console.log("image", imageData?.image?.blob);

    let data = new FormData();
    data.append("file", imageData?.image?.blob);
    data.append("appointmentId", appointmentId);
    data.append("type", imageData.image.blob.type);
    data.append("caption", imageData.caption);
    data.append("isProviderPrivate", imageData.isPrivate);
    // data.append("location", imageData.location);
    if (coordinates) {
      data.append("latitude", coordinates.lat);
      data.append("longitude", coordinates.lng);
    }
    data.append("tags", imageData.tags);
    if (imageData?.image?.blob === "") {
      setError(true);
      setType(false);
    } else {
      // dispatch(addAppointmentPhoto({ data, index: index }));
      setMediaUploadProgress(0);
      dispatch(updateImageModalStatus(LOADING));
      addPhoto(data, onUploadProgress)
        .then((data) => {
          dispatch(updateImageModalStatus(SUCCESS));
          // console.log("MEDIA DATA : ", data);
          dispatch(addAppointmentMedia({ data, index: index }));
        })
        .catch((error) => {
          console.log("MEDIA ERROR : ", error);
        });
      handleClose();
    }
  };

  const maxLength = 255;

  const validationSchema = yup.object().shape({
    // location: yup.string().required("Location is required"),
    caption: yup
      .string()
      .required("Caption is required")
      .max(maxLength, "Must be 255 characters or less"),
    tags: yup.string().required("Tag is required"),
  });

  const serviceOptions = useSelector(MultiServiceLevelTwoSelector);

  const multiSelectOptions = useMemo(() => serviceOptions, [serviceOptions]);

  const handleUserChange = (selectedOption) => {
    let tempSelect = [];
    selectedOption.forEach((select: { value: any }) => {
      tempSelect.push(select.value);
    });
    if (tempSelect.length <= 2) {
      setImageData({
        ...imageData,
        tags: JSON.stringify(tempSelect),
        services: selectedOption,
      });
    }
  };

  const onChangeHandle = (e) => {
    setImageData({ ...imageData, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Typography
        className="text-dark"
        id="modal-modal-title"
        sx={{
          fontSize: "18px",
          fontWeight: "550",
        }}
        component="h6"
      >
        Add Media
      </Typography>
      <div className="row mb-5 pb-3">
        <div className="col-md-6">
          {imageData?.image?.src ? (
            <div
              className="w-100 text-center border rounded mt-3"
              style={{
                height: "260px",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: 0,
                }}
              >
                <IconButton
                  onClick={() => {
                    setImageData({
                      ...imageData,
                      image: { blob: "", src: null },
                    });
                  }}
                  aria-label="delete"
                  size="small"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              </div>
              {imageData?.image?.blob?.type.includes("video") ? (
                <video
                  className="rounded"
                  id="video"
                  width="70%"
                  height="100%"
                  controls
                >
                  <source src={imageData?.image?.src} type="video/mp4" />
                </video>
              ) : (
                <img
                  style={{ objectFit: "contain" }}
                  src={imageData.image.src}
                  className="mt-0"
                  alt="not available"
                  height="100%"
                  width="100%"
                />
              )}
            </div>
          ) : (
            <>
              <div {...getRootProps()}>
                <div
                  className={`d-flex align-items-center justify-content-center py-4 mt-3 w-100 bg-light pointer ${
                    error || type || videoError ? "border-danger" : ""
                  }`}
                  style={{
                    height: "260px",
                    border: isDragActive
                      ? "2px dashed #04bac7"
                      : "2px dashed #D3D3D3",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <div
                        className="d-flex flex-column align-items-center pointer"
                        style={{
                          color: "#bdbdbd",
                        }}
                      >
                        <p className="mb-0">Drop your media here</p>
                      </div>
                    ) : (
                      <div
                        className="d-flex flex-column align-items-center pointer"
                        style={{
                          color: "#bdbdbd",
                        }}
                      >
                        <p className="mb-0">Drag and drop your media here</p>
                        <p className="mb-0">or</p>
                        <p className="mb-0">Browse</p>
                      </div>
                    )}
                  </div>
                </div>
                {/* <p style={{ fontSize: "14px" }} className="mt-0 mb-3">
            PNG, JPG or GIF (max.800 ✗ 400, less than 1MB)
          </p> */}
              </div>
              {videoError ? (
                <div className="text-danger">
                  Video must not be longer than 5 sec.
                </div>
              ) : null}
              {error ? (
                <div className="text-danger">Image is required</div>
              ) : null}
              {type ? (
                <div className="text-danger">Only image/video is allowed</div>
              ) : null}
            </>
          )}
        </div>
        <div className="col-md-6">
          <div className="form">
            <Formik
              initialValues={imageData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ errors, touched, values, handleBlur, handleChange }) => (
                <Form>
                  <label
                    style={{ fontSize: "14px" }}
                    htmlFor="addCaption"
                    className="form-label mt-3"
                  >
                    Add Tag <small>(Maximum 2 allowed)</small>
                  </label>
                  <Select
                    onChange={(e: any) => {
                      handleUserChange(e);
                      handleChange({
                        target: {
                          value: e?.length > 0 ? Math.random() : "",
                          name: "tags",
                        },
                      });
                    }}
                    name="tags"
                    styles={errors.tags && touched.tags ? CS_Styles : {}}
                    value={imageData?.services?.map((u) => {
                      return { label: u?.label, value: u?.value };
                    })}
                    components={makeAnimated()}
                    isMulti
                    options={multiSelectOptions}
                    placeholder="Select Tags"
                    isClearable={true}
                  />
                  {errors.tags && touched.tags ? (
                    <div className="text-danger">
                      <small> Tag is required</small>
                    </div>
                  ) : null}

                  <div className="form-group mt-3 position-relative">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="addCaption"
                      className="form-label"
                    >
                      Add Caption
                    </label>
                    <div className="">
                      <TextArea
                        handleChange={(e) => {
                          onChangeHandle(e);
                          handleChange(e);
                        }}
                        errors={errors?.caption}
                        touched={touched?.caption}
                        name={"caption"}
                        value={values?.caption}
                        toggle={null}
                        placeholder={"Recent Photo took in New York"}
                      />
                    </div>
                  </div>
                  <div className="w-100 position-absolute start-0 bottom-0 mb-2">
                    <div className="row m-auto w-100">
                      <div className="col-6 ps-4">
                        <button
                          type="reset"
                          onClick={handleClose}
                          className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-6 pe-4">
                        <button
                          onClick={() => {
                            if (imageData.image.blob === "") {
                              setError(true);
                            }
                          }}
                          style={{ backgroundColor: "#04BAC7" }}
                          className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

import Skeleton from "@mui/lab/Skeleton";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addAppointmentNotes } from "../../../../reducers/appointmentReducer";
import { loggedInUserProfile } from "../../../../reducers/authReducer";
import { profileStatusSelector } from "../../../../reducers/clientReducer";
import { AppDispatch } from "../../../../store";

const ClientNotes = ({ notes, clientId, clientName, openedTab }) => {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  const providerProfile = useSelector(loggedInUserProfile);
  const [noteChange, setNoteChange] = useState({
    notes: "",
  });

  const addNotes = (e) => {
    dispatch(addAppointmentNotes(noteChange));
  };

  useEffect(() => {
    setNoteChange({
      ...notes?.filter((note) => note?.appointment_id === null)?.[0],
      clientId: clientId,
      providerId: providerProfile?.id,
    });
    // console.log(noteChange);
  }, [notes]);

  const status = useSelector(profileStatusSelector);

  // const poppinsFont = {
  //   fontFamily: "Poppins, sans-serif",
  // };
  // const whiteText = {
  //   fontSize: "13px",
  // };

  // console.log(getLastElement(notes));

  return (
    <>
      <div className="mt-4 margin-start-res" style={{ marginLeft: "4.2rem" }}>
        <div className="d-flex justify-content-between align-items-center">
          <p className={`mb-2 mt-1 text-dark`} style={{ fontSize: "18px" }}>
            Notes
          </p>
          <div
            onClick={(e) => {
              setToggle(!toggle);
              if (toggle) {
                addNotes(e);
              }
            }}
            className="d-flex align-items-center pointer"
          >
            {toggle ? (
              <img
                src="/images/clientList/success.png"
                alt="arrow"
                className="me-2"
              />
            ) : (
              <img
                src="/images/clientList/edit.png"
                alt="arrow"
                className="me-2"
              />
            )}
            <p className={`mb-0 text-primary`} style={{ fontSize: "15px" }}>
              {toggle ? "Save" : "Edit"}
            </p>
          </div>
        </div>
        <div className="row gy-4">
          <div className="col-12">
            {status == "loading" ? (
              <Skeleton
                className="rounded-5"
                variant="rectangular"
                width={"100%"}
                height={90}
              />
            ) : (
              <textarea
                style={{
                  fontSize: "14px",
                  backgroundColor: toggle ? null : "#F1F5F9",
                  border: toggle ? null : "none",
                  resize: toggle ? "vertical" : "none",
                }}
                className={`form-control ${
                  toggle ? null : "bgLight"
                } rounded-b5`}
                name="businessDescription"
                id="description"
                onChange={(e) =>
                  setNoteChange({
                    ...noteChange,
                    notes: e.target.value,
                  })
                }
                value={toggle ? noteChange?.notes : noteChange?.notes}
                placeholder={`Write something...`}
                rows={3}
                disabled={!toggle}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientNotes;

import React from "react";
import ForgetPass from "../../../components/common/auth/ForgetPass";

const ForgotPass = () => {
  return (
    <>
      <ForgetPass link="/auth/login" />
    </>
  );
};

export default ForgotPass;

import { height } from "@mui/system";
import React from "react";
import ModalC from "../../../../components/common/Modal";

const ViewMediaContainer: React.FC<{ isVideo: boolean; mediaUrl: string }> = ({
  isVideo,
  mediaUrl,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      {isVideo ? (
        <video
          className="rounded-5 d-flex align-items-center justify-content-center pointer"
          style={{
            height: "450px",
            width: "100%",
            // backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          src={mediaUrl}
          controls
          autoPlay
        />
      ) : (
        <img
          width={"100%"}
          height={450}
          className="d-block m-auto"
          src={mediaUrl}
          style={{ objectFit: "contain" }}
          alt="img"
        />
      )}
    </>
  );
};

export default ViewMediaContainer;

// <img
//   height={120}
//   src="/images/Media/video-play.svg"
//   alt="avatar"
//   className="mt-4"
// />

import moment from "moment";
import { HOUR, MINUTE, REGION, TIME_OPTIONS } from "../config/constant";

const UTILS_TEST = "Util testing ";
const PAGE_PATHNAME = "pageUrl";

const utilsTest = (value: String): String => {
  // console.log(`value => ${value} `);

  return value;
};

function array_to_CSV(arry: Array<any>) {
  return arry.join();
}

export const localStorageObject = {
  set: (key: string, value: any) => {
    if (!localStorage) {
      return;
    }
    try {
      const lsValue = JSON.stringify(value);
      localStorage.setItem(key, lsValue);

      return true;
    } catch (error) {
      console.error("LOCAL STORAGE SAVE ERROR", error);
      return false;
    }
  },
  get: (key: string) => {
    if (!localStorage) {
      return;
    }
    const lsValue: string | null = localStorage.getItem(key);
    if (!lsValue) {
      return;
    }
    try {
      const value: any = JSON.parse(lsValue);
      if (value) {
        return value;
      }
    } catch (error) {
      console.error("LOCAL STORAGE PARSE ERROR", error);
    }
  },
  remove: (key: string) => {
    if (!localStorage) {
      return;
    }
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error("LOCAL STORAGE REMOVE ERROR", error);
    }
  },
};

const convertTime12to24 = (time12h: any) => {
  const [time, modifier] = time12h?.split(" ");

  // console.log(time, modifier);

  let [hours, minutes] = time?.split(":");

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "PM") {
    hours = (parseInt(hours, 10) + 12) as any;
  }

  return `${hours}:${minutes}`;
};

const convert24To12 = (time: string) => {
  return moment(time, "hh:mm").format("LT");
};

export const formateTheDate = (date: string) => {
  return moment(date).format("M-D-Y");
};

const removePageLocation = () => {
  if (!localStorage) {
    return;
  }

  try {
    localStorage.removeItem(PAGE_PATHNAME);
  } catch (error) {
    console.error("PAGE PATHNAME LOCAL STORAGE REMOVE ERROR", error);
  }
};

const changeTheMeridiem = (time: string, value: any) => {
  let tempTime = time.split(" ")[0];
  return `${tempTime} ${value}`;
};

const convertMinIntoHoursAndMin = (time: string): string => {
  var num = time;
  var hours = Number(num) / 60;
  // console.log(
  //   "🚀 ~ file: index.ts:92 ~ convertMinIntoHoursAndMin ~ hours:",
  //   hours
  // );
  var rHours = Math.floor(hours);
  // console.log(
  //   "🚀 ~ file: index.ts:94 ~ convertMinIntoHoursAndMin ~ rHours:",
  //   rHours
  // );
  var minutes = (hours - rHours) * 60;
  var rMinutes = Math.round(minutes);

  if (rHours === 0) {
    return `${rMinutes}min`;
  } else if (rMinutes === 0) {
    return `${rHours}${HOUR}`;
  } else {
    return `${rHours}${HOUR} ${rMinutes}${MINUTE}`;
  }
};
convertMinIntoHoursAndMin("300");

const titleCase = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map(function (word) {
      return word.replace(word[0], word[0].toUpperCase());
    })
    .join(" ");
};

const dayNameToPrefix = (dayName: string) => {
  switch (dayName) {
    case "Monday":
      return "M";
    case "Tuesday":
      return "T";
    case "Wednesday":
      return "W";
    case "Thursday":
      return "TR";
    case "Friday":
      return "F";
    case "Saturday":
      return "S";
    case "Sunday":
      return "SU";
    default:
      return "";
  }
};

const getDayName = (dayNumber: number) => {
  switch (dayNumber) {
    case 0:
      return "M";
    case 1:
      return "T";
    case 2:
      return "W";
    case 3:
      return "TR";
    case 4:
      return "F";
    case 5:
      return "S";
    case 6:
      return "SU";
    default:
      return "";
  }
};

const extractTitleFirstWord = (str: string): string => {
  let data = str?.toLowerCase().split(" ");
  let word;
  if (str?.split(" ").length > 1) {
    // console.log(data.length - 1);
    word = `${data?.[0]?.[0]?.toUpperCase()}${data[
      data.length - 1
    ]?.[0]?.toUpperCase()}`;
  } else {
    word = data?.[0]?.[0]?.toUpperCase();
  }
  return word;
};

export const providerServiceSorting = (data) => {
  let sorting;
  const sort1 = data.sort((a: { name: string }, b: { name: string }) => {
    return a.name.localeCompare(b.name);
  });
  sorting = sort1.sort(
    (
      a: { name: string; providerService: any },
      b: { name: string; providerService: any }
    ) => {
      const aHasProviderService =
        a.providerService && a.providerService.status === 1;
      const bHasProviderService =
        b.providerService && b.providerService.status === 1;

      if (aHasProviderService && !bHasProviderService) {
        return -1;
      } else if (!aHasProviderService && bHasProviderService) {
        return 1;
      } else {
        return 0;
      }
    }
  );
  return sorting;
};

const CS_Styles = {
  control: (base) => ({
    ...base,
    borderColor: "red",
  }),
};

const percentage = (total: Array<number>, percent: number) => {
  const temp = total.reduce((sum: number, record: number) => sum + record);
  return (temp / percent) * 100;
};

function reverseArry(array: any[]) {
  return array?.map((item, idx) => array[array.length - 1 - idx]);
}

const generateTimeStamp = () => Date.now();

function generateRandomString(length: number) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

// console.log(makeid(5));

function generateRandomIntegerInRange(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateAlphabet() {
  return [...Array(26)].map((_, i) => String.fromCharCode(i + 97));
}

function showTime(time: Date) {
  return new Intl.DateTimeFormat(REGION, TIME_OPTIONS as unknown).format(time);
}

/**
 * Calculates the sum of elements in the given array based on a key or by index.
 *
 * @param {Array} Arr - An array of elements to be summed up.
 * @param {string|undefined} [key] - The key to access the value of an element in the array.
 *
 * @returns {number} - The sum of the elements in the array.
 */
function getTotal(Arr: Array<any>, key: string | undefined): number {
  if (!key) {
    return Arr?.reduce((sum, item) => sum + item, 0);
  } else {
    return Arr?.reduce((sum, item) => {
      if (typeof item[key] === "number") {
        return sum + item[key];
      } else if (!isNaN(parseInt(item[key]))) {
        return sum + parseInt(item[key]);
      } else {
        return sum;
      }
    }, 0);
  }
}

/**
 * Checks if at least one element in one array is included in another array.
 *
 * @param {Array} array1 - The first array to be compared.
 * @param {Array} array2 - The second array to be compared.
 *
 * @returns {boolean} - Returns `true` if at least one element from `array1` is included in `array2`, and `false` otherwise.
 */
function isIncluded(array1: Array<any>, array2: Array<any>): boolean {
  return array1.some(function (element) {
    return array2.includes(element);
  });
}

function formatFirebaseTimestamp(timestamp: {
  seconds: number;
  nanoseconds: number;
}): string {
  if (
    !timestamp ||
    typeof timestamp.seconds !== "number" ||
    typeof timestamp.nanoseconds !== "number"
  ) {
    return "";
  }
  const now = Date.now();
  const diff =
    now - (timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000);
  const formatter = new Intl.DateTimeFormat(undefined, {
    timeStyle: "short",
    dateStyle: "short",
  });
  const formattedDate = formatter.format(
    timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
  );
  const parts = formattedDate.split(", ");
  return diff < 86400000 ? parts[1] : parts[0];
}

function getTimeAgo(timestamp: string): string {
  const date = new Date(timestamp);
  const now = new Date(); // Current time
  // console.log("timestamp ", timestamp);

  const diff = now.getTime() - date.getTime(); // Difference in milliseconds

  const minute = 60 * 1000; // Number of milliseconds in a minute
  const hour = 60 * minute; // Number of milliseconds in an hour
  const day = 24 * hour; // Number of milliseconds in a day
  const week = 7 * day; // Number of milliseconds in a week
  const month = 30 * day; // Number of milliseconds in a month
  const year = 365 * day; // Number of milliseconds in a year

  if (diff < minute) {
    return "just now";
  } else if (diff < hour) {
    const minutesAgo = Math.floor(diff / minute);
    return `${minutesAgo} ${minutesAgo === 1 ? "minute" : "minutes"} ago`;
  } else if (diff < day) {
    const hoursAgo = Math.floor(diff / hour);
    return `${hoursAgo} ${hoursAgo === 1 ? "hour" : "hours"} ago`;
  } else if (diff < week) {
    const daysAgo = Math.floor(diff / day);
    return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
  } else if (diff < month) {
    const weeksAgo = Math.floor(diff / week);
    return `${weeksAgo} ${weeksAgo === 1 ? "week" : "weeks"} ago`;
  } else if (diff < year) {
    const monthsAgo = Math.floor(diff / month);
    return `${monthsAgo} ${monthsAgo === 1 ? "month" : "months"} ago`;
  } else {
    const yearsAgo = Math.floor(diff / year);
    return `${yearsAgo} ${yearsAgo === 1 ? "year" : "years"} ago`;
  }
}

interface ModifiedAt {
  date: string;
  time: string;
}
function getLastOnlineStatus(modifiedAt: ModifiedAt): string {
  const date = new Date(modifiedAt.date);
  const now = new Date();
  const diff = now.getTime() - date.getTime();

  // Calculate the number of hours since the user was last online
  const hoursAgo = Math.floor(diff / 1000 / 60 / 60);

  if (hoursAgo < 24) {
    // If the user was last online less than 24 hours ago, return the time they were last online
    return modifiedAt.time;
  } else {
    // If the user was last online more than 24 hours ago, return the date they were last online
    return date.toDateString();
  }
}

const copyToClipboard = (e) => {
  navigator.clipboard.writeText(e);
  // alert("Copied to clipboard");
};

function openInNewTab(url: string | URL) {
  var win = window.open(url, "_blank");
  win.focus();
}

const RegEx = {
  phone:
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
};

// function to check whick id is greater
const greaterId = (id1: any, id2: any) => {
  if (id1 > id2) {
    return id1;
  } else {
    return id2;
  }
};

const smallerId = (id1: any, id2: any) => {
  if (id1 < id2) {
    return id1;
  } else {
    return id2;
  }
};

const createId = (provider: boolean, id1: number, id2: number) => {
  const greater = greaterId(id1, id2);
  const smaller = smallerId(id1, id2);
  if (provider) {
    if (greater === id1) {
      return `CP-${smaller}-${greater}`;
    } else {
      return `PC-${smaller}-${greater}`;
    }
  } else {
    return `CC-${smaller}-${greater}`;
  }
};

function timeFormat(time) {
  const date = new Date(time);
  var formattedTime = date.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return formattedTime;
}

function getSeconds(timestamp) {
  // get the seconds and nanoseconds from the timestamp
  const date = new Date(timestamp);
  const seconds = date.getSeconds();
  const nanoseconds = date.getMilliseconds() * 1000000;
  // return the seconds and nanoseconds
  return { seconds, nanoseconds };
}

const blockInvalidChar = (e) =>
  ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

function addMinutesToTime(time, minutesToAdd) {
  const formattedTime = moment(time, "hh:mm A")
    .add(minutesToAdd, "m")
    .format("hh:mm A");

  return formattedTime;
}

const handleKeyDown = (event) => {
  // Allow numeric values, decimal point, and specific keys like Backspace, Enter, and arrow keys
  if (
    (!/^\d$/.test(event.key) &&
      event.key !== "Backspace" &&
      event.key !== "Enter" &&
      event.key !== "ArrowLeft" &&
      event.key !== "ArrowRight" &&
      event.key !== ".") ||
    (event.key === "." && event.target.value.includes("."))
  ) {
    event.preventDefault();
  }
};

export {
  generateRandomIntegerInRange,
  generateRandomString,
  reverseArry,
  generateAlphabet,
  UTILS_TEST,
  utilsTest,
  convertTime12to24,
  convert24To12,
  removePageLocation,
  changeTheMeridiem,
  convertMinIntoHoursAndMin,
  titleCase,
  extractTitleFirstWord,
  percentage,
  generateTimeStamp,
  CS_Styles,
  showTime,
  getTotal,
  isIncluded,
  dayNameToPrefix,
  formatFirebaseTimestamp,
  getTimeAgo,
  getLastOnlineStatus,
  copyToClipboard,
  openInNewTab,
  RegEx,
  array_to_CSV,
  greaterId,
  smallerId,
  timeFormat,
  getSeconds,
  createId,
  getDayName,
  blockInvalidChar,
  addMinutesToTime,
  handleKeyDown,
};

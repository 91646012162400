import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { TemporaryDrawer } from "../../../../components/common/TemporaryDrawer";
import { getAppointmentNotes } from "../../../../reducers/appointmentReducer";
import { AppDispatch } from "../../../../store";
import AppointmentCompDrawer from "./AppointmentCompDrawer";
import { ID } from "../../../../components/common/Chips";

const RescheduleDrawer = ({
  handleClose,
  isEmpty,
  isComplete = "approve",
  data,
}) => {
  // console.log("data", data);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  useEffect(() => {
    dispatch(getAppointmentNotes(data?.id));
  }, []);

  return (
    <div className="mt-5 pt-4">
      <div className="d-flex justify-content-between align-items-start pb-1 pt-3 ps-3 pe-4">
        <div
          className="d-flex align-items-start pointer"
          onClick={() => navigate(`/client/profile/${data?.clientId}`)}
        >
          <img
            src={data?.clientImg ? data?.clientImg : "/images/avatar2.png"}
            height={35}
            width={35}
            alt="arrow"
            className="me-2 rounded-circle"
          />
          <div>
            <p className={`mb-0`} style={{ fontSize: "20px" }}>
              {data?.name ? data?.name : "NA"}
            </p>
            <ID color="text.disabled" tooltip="Appointment ID">
              <span>
                {/* {"KZH23-"} */}
                {Number(data?.id)}
              </span>
            </ID>
          </div>
        </div>
        <img
          onClick={handleClose}
          src="/images/clientList/close.png"
          alt="arrow"
          className="pointer me-1"
        />
      </div>
      <hr />
      {isEmpty ? (
        <div className="row mt-5">
          <div className="col-8 m-auto py-5 text-center">
            <label>
              <img
                src="/images/Media/UploadImg.svg"
                alt="Upload"
                className="pointer"
              />
              <h6 className="mb-0 fw-bold mt-3 mb-1">
                Not notes Added for Molly B.
              </h6>
              <p
                style={{ fontSize: "14px", color: "#667085" }}
                className="mb-0 text-grey"
              >
                You can add images from you favorite social media platforms e.g.
                Facebook, Instagram, Twitter etc.
              </p>
            </label>
          </div>
        </div>
      ) : (
        <div className="mt-3 ps-3 pe-4 mb-1">
          <div className="row g-3">
            <div className="col-6">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p
                  className={`mb-1 mt-1 text-dark`}
                  style={{ fontSize: "16px" }}
                >
                  {data?.totalPrice ? `$ ${data?.totalPrice}` : "$0.00"}
                </p>
                <p
                  className={`mb-0 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  Amount
                </p>
              </div>
            </div>
            <div className="col-6">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                  $ 0.00
                </p>
                <p
                  className={`mb-0 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  Tip
                </p>
              </div>
            </div>
            {data?.isReservationFee === 1 ? (
              <div className="col-12">
                {/* border-bottom border-3 border-primary */}
                <div
                  style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                  className={`form-control rounded-b5 px-4 py-2 border-0`}
                >
                  <p
                    className={`mb-1 mt-1 text-dark`}
                    style={{ fontSize: "16px" }}
                  >
                    {data?.reservationFee
                      ? `$ ${data?.reservationFee}`
                      : "$0.00"}
                  </p>
                  <p
                    className={`mb-0 mt-1 fw-light`}
                    style={{ fontSize: "14px", color: "#667085" }}
                  >
                    Reservation Fee
                  </p>
                </div>
              </div>
            ) : null}
            <div className="col-12">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                  Date
                </p>
                <p
                  className={`mb-0 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  {data?.Date}
                </p>
              </div>
            </div>
            <div className="col-12">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                  Time
                </p>
                <p
                  className={`mb-0 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  Start: {data?.Time.split("-")?.[0]} &nbsp; | &nbsp; End:{" "}
                  {data?.Time.split("-")?.[1]}
                </p>
              </div>
            </div>
            <div className="col-12">
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-2 mt-1`} style={{ fontSize: "16px" }}>
                  Services
                </p>
                <ul style={{ fontSize: "14px", color: "#667085" }}>
                  {data?.services?.map((m) => (
                    <li>{m?.props?.children}</li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-12">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                  Location
                </p>
                {data?.location ? (
                  <p
                    className={`mb-2 mt-1 fw-light`}
                    style={{ fontSize: "14px", color: "#667085" }}
                  >
                    {data?.location}
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end mt-3">
            {isComplete == "complete" ? (
              <button
                onClick={handleOpen2}
                style={{ fontSize: "14px", borderRadius: "6px" }}
                className="btn btn-primary me-2"
              >
                Next
              </button>
            ) : null}
            {/* <button
              style={{ fontSize: "14px", borderRadius: "6px" }}
              className="btn btn-outline-danger"
            >
              Decline
            </button> */}
          </div>
        </div>
      )}
      <TemporaryDrawer handleClose={handleClose2} open={open2}>
        <AppointmentCompDrawer
          handleClose={handleClose2}
          drawerClose={handleClose}
          id={data?.id}
          payment={false}
          providerId={data?.providerId}
          clientId={data?.clientId}
        />
      </TemporaryDrawer>
    </div>
  );
};

export default RescheduleDrawer;

import React, { ReactNode } from 'react';
import { BootstrapTooltip } from '../BootstrapTooltip';

interface FilterButtonProps {
    active?: boolean | number;
    onClick?: () => void;
    tooltip?: string;
    style?: React.CSSProperties
    className?: string
    id?: string
    children: ReactNode;
}

const FilterButton: React.FC<FilterButtonProps> = ({ active, onClick, tooltip, id, children, style, className }) => {
    return (
        <BootstrapTooltip title={tooltip} placement="top">
            <button
                style={style}
                key={id}
                data-bs-toggle={id && "dropdown"}
                type="button"
                className={`btn py-1 ${active ? 'bg-light-grey' : ''} ${className} btn-outline-light border border-b5`}
                onClick={onClick}
            >
                {children}
            </button>
        </BootstrapTooltip>
    );
};

export default FilterButton;

import React, { useState } from "react";
import {
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";

const styles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "60vh",
    // backgroundColor: "#f5f5f5",
  },
  paper: {
    padding: "2rem",
    maxWidth: 400,
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
  },
  title: {
    marginBottom: "1.5rem",
    fontWeight: "bold",
    color: "#333",
  },
  formControl: {
    marginBottom: "1.5rem",
  },
  buttonContainer: {
    marginTop: "1.5rem",
    // textAlign: "center",
  },
  icon: {
    marginRight: "0.5rem",
    color: "#888",
  },
};

const CashReceivedPage = () => {
  const [cashReceived, setCashReceived] = useState("");

  const handleCashReceivedChange = (event) => {
    setCashReceived(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission or further processing
    console.log("Cash Received:", cashReceived);
  };

  return (
    <div style={styles.root}>
      <Paper elevation={3} style={styles.paper}>
        <Typography variant="h5" component="h2" style={styles.title}>
          Did you receive cash?
        </Typography>
        <form onSubmit={handleSubmit}>
          <FormControl component="fieldset" style={styles.formControl}>
            <RadioGroup
              aria-label="cashReceived"
              name="cashReceived"
              value={cashReceived}
              onChange={handleCashReceivedChange}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label={
                  <>
                    <span style={styles.icon}>✔</span>
                    Yes
                  </>
                }
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label={
                  <>
                    <span style={styles.icon}>✘</span>
                    No
                  </>
                }
              />
            </RadioGroup>
          </FormControl>
          <div style={styles.buttonContainer} className="text-center">
            <Button
              variant="contained"
              color="primary"
              style={{
                margin: "0.5rem",
                borderRadius: "50px",
                textTransform: "none",
                boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
              type="submit"
              disabled={!cashReceived}
            >
              Submit
            </Button>
          </div>
        </form>
      </Paper>
    </div>
  );
};

export default CashReceivedPage;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { NotificationState, urlParameter } from "../../components/common/types";
import { FAIL, IDLE, LOADING, SUCCESS } from "../../config/constant";
import {
  deleteNotification,
  getAllNotificationRequest,
  markAsReadRequest,
} from "../../helper/provider/notification";
import { Notification, makeNotification } from "../../utils/factory";
import { approveConnection } from "../../helper/provider/member";

export const initialState: NotificationState = {
  list: [],
  status: IDLE,
  error: null,
};

export const getAllNotificationAction = createAsyncThunk(
  "get/notifications",
  async (id: number) => {
    try {
      const res: any = await getAllNotificationRequest(id);
      const { data, success } = res.data;
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const deleteNotificationAction = createAsyncThunk(
  "delete/notifications",
  async (id: urlParameter) => {
    try {
      const res: any = await deleteNotification(id);
      const { data, success } = res.data;
      return {
        id,
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const approveConnectionAction = createAsyncThunk(
  "approve/notifications",
  async (Data: any) => {
    try {
      const res: any = await approveConnection(Data);
      const { data, success } = res.data;
      return {
        Data,
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const markAsReadAction = createAsyncThunk(
  "markAsRead/notifications",
  async (formData: any) => {
    try {
      const res: any = await markAsReadRequest(formData);
      const { data, success } = res.data;
      return {
        data,
        success,
      };
    } catch (error) {
      return error;
    }
  }
);

// action
const notificationSlice = createSlice({
  name: "Appointments",
  initialState,
  reducers: {
    resetNotification: (state) => {
      state.list = [];
      state.error = null;
      state.status = IDLE;
    },
    markNotiAsRead(state, action) {
      const index = state.list.findIndex(
        (item: Notification) => item.id === action.payload
      );
      if (index !== -1) {
        state.list[index].isRead = true;
      }
    },

    markAllNotiAsRead(state) {
      state.list = state.list.map((item: Notification) => {
        return {
          ...item,
          isRead: 1,
        };
      });
    },

    addNotification(state, action) {
      state.list.unshift(action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase(getAllNotificationAction.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(getAllNotificationAction.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      if (success && code === 200) {
        state.list = makeNotification(data);
        state.status = SUCCESS;
      }
      state.status = FAIL;
    });
    builder.addCase(getAllNotificationAction.rejected, (state, action) => {
      state.status = FAIL;
    });
    builder.addCase(deleteNotificationAction.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(deleteNotificationAction.fulfilled, (state, action) => {
      const { id, success, code } = action.payload;
      if (success && code === 200) {
        const index = state.list.findIndex((item) => item.id === id);
        if (index !== -1) {
          state.list.splice(index, 1);
        }
        state.status = SUCCESS;
      }
      state.status = FAIL;
    });
    builder.addCase(deleteNotificationAction.rejected, (state, action) => {
      state.status = FAIL;
    });
    builder.addCase(approveConnectionAction.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(approveConnectionAction.fulfilled, (state, action) => {
      const { Data, success, code } = action.payload;
      if (success && code === 200) {
        const index = state.list.findIndex(
          (item: Notification) => item.id === Data.id
        );
        if (index !== -1) {
          state.list[index].isRead = true;
          if (state.list[index].data) {
            if (Data.status === "approved") {
              state.list[index].data = {
                ...state.list[index].data,
                event: Data.status,
              };
            } else {
              state.list[index].data = {
                ...state.list[index].data,
                event: Data.status,
              };
            }
          }
        }
        state.status = SUCCESS;
      }
      state.status = FAIL;
    });
    builder.addCase(approveConnectionAction.rejected, (state, action) => {
      state.status = FAIL;
    });

    builder.addCase(markAsReadAction.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(markAsReadAction.fulfilled, (state, action) => {
      state.status = SUCCESS;
    });
    builder.addCase(markAsReadAction.rejected, (state, action) => {
      state.status = FAIL;
    });
  },
});

export const {
  resetNotification,
  markNotiAsRead,
  markAllNotiAsRead,
  addNotification,
} = notificationSlice.actions;

export default notificationSlice.reducer;

// export const statusSelector = (state: RootState) => state.appointment.status;

export const messageNotificationSelector = (state: RootState) =>
  state.notification.list.filter(({ event }) => event === "CHAT_MESSAGE");

export const appointmentNotificationSelector = (state: RootState) =>
  state.notification.list.filter(({ event }) => event.includes("APPOINTMENT"));

export const connectionNotificationSelector = (state: RootState) =>
  state.notification.list.filter(({ event }) => event.includes("CONNECTION"));

export const allNotificationSelector = (state: RootState) =>
  state.notification.list;

import { useState } from "react";
import Typography from "@mui/material/Typography";
// import MediaImg from "./MediaImg";
import { saveAs } from "file-saver";

import ViewMediaContainer from "./ViewMediaContainer";
import { VIDEO } from "../../../../config/constant";
import { generateTimeStamp } from "../../../../utils";

export const ViewMediaModal = ({
  handleClose,
  mediaUrl,
  mediaType,
  services,
  userImg,
  userName,
  address,
  explore,
  client,
  singleTitle,
}: {
  handleClose(): void;
  mediaUrl: string;
  mediaType: string;
  services?: Array<any>;
  userImg?: string;
  userName?: string;
  address?: string;
  client?: any;
  explore?: boolean;
  singleTitle?: any;
}) => {
  const downloadImage = (imageUrl: string) => {
    saveAs(imageUrl, generateTimeStamp()); // Put your image url here.
  };

  return (
    <div onClick={handleClose}>
      {explore ? (
        <div className="d-flex">
          <img
            height={40}
            className="me-2 rounded-circle"
            src={
              client?.profile_image
                ? client?.profile_image
                : "/images/avatar2.png"
            }
            alt="no icon"
            loading="lazy"
          />
          <div className="">
            <p className={`mb-0 text-dark`} style={{ fontSize: "14px" }}>
              {client?.first_name ? client?.first_name : "NA"}
            </p>
            <p
              style={{ color: "grey", fontSize: "13px", minHeight: "30px" }}
              className="mb-0"
            >
              {client?.address ? client?.address : "n/a"}
            </p>
          </div>
        </div>
      ) : (
        <></>
      )}
      <ViewMediaContainer mediaUrl={mediaUrl} isVideo={mediaType === VIDEO} />

      {singleTitle
        ? null
        : // <div className="text-center">
          //   <label
          //     style={{ fontSize: "16px" }}
          //     htmlFor="servicename"
          //     className="text-light fw-bold border rounded-pill px-3 py-1 bg-primary mt-2"
          //   >
          //     <span className="mx-1">{singleTitle}</span>
          //   </label>
          // </div>
          null}

      {services?.map(({ service: { name } }, idx, arr) => (
        <label
          key={idx}
          style={{ fontSize: "16px" }}
          htmlFor="servicename"
          className="text-dark fw-bold mt-3"
        >
          <span className="mx-1">{name}</span>
          {arr?.length > 0 && !(idx + 1 === arr.length) ? `|` : ` `}
        </label>
      ))}

      {explore ? (
        <div className="row w-100 m-auto mt-2 mb-0">
          <div className="col hover-light pt-2">
            <div className="d-flex align-items-center justify-content-center">
              <img
                height={20}
                className="me-2"
                src="/images/Media/like.svg"
                alt="no icon"
              />
              <p style={{ fontSize: "13px" }} className="my-0">
                {client?.mediaLikeCounts ? client?.mediaLikeCounts : "0"} Likes
              </p>
            </div>
          </div>
          <div className="col hover-light pt-2">
            <div className="d-flex align-items-center justify-content-center">
              <img
                height={20}
                className="me-2"
                src="/images/Media/download.svg"
                alt="no icon"
              />
              <span
                onClick={() => downloadImage(mediaUrl)}
                style={{ fontSize: "13px" }}
                className="my-0"
              >
                Download
              </span>
            </div>
          </div>
          <div className="col hover-light pt-2">
            <div className="d-flex align-items-center justify-content-center">
              <img
                height={20}
                className="me-2"
                src="/images/Media/share.svg"
                alt="no icon"
              />
              <p style={{ fontSize: "13px" }} className="my-0">
                Share
              </p>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

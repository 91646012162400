import React from 'react'
import { Skeleton } from "@mui/material";

const ServiceSkeletonCard = () => {
    return (
        <div
            style={{
                borderRadius: "6px",
            }}
            className={`d-flex p-3 mt-2 justify-content-between border
                }`}
        >
            <div className="d-flex">
                <Skeleton variant="circular" width={50} height={50} />
                <div className="">
                    <p
                        className={`mb-0 fw-bold mb-1 text-primary mx-2`}
                        style={{ fontSize: "15px" }}
                    >
                        <Skeleton width={90} animation="pulse" />
                    </p>
                    <p style={{ color: "grey", fontSize: "13px" }} className="mb-0 mt-1 mx-2">
                        <Skeleton width={120} animation="pulse" />
                    </p>
                    <p
                        style={{ color: "grey", fontSize: "15px" }}
                        className="mb-0 mt-1 text-primary pointer mx-2"
                    >
                        <Skeleton width={30} animation="pulse" />
                    </p>
                </div>
            </div>
            <div className="justify-content-end d-sm-block d-none">
                <div className="d-flex flex-column text-end">
                    <p
                        className="mb-0 fw-bold text-primary float-end ms-auto"
                        style={{ fontSize: "15px" }}
                    >
                        <Skeleton width={25} animation="pulse" />
                    </p>
                    <div className="d-flex align-items-center mt-1">
                        <span className='me-1 text-end'>
                            <Skeleton variant="circular" width={15} height={15} />
                        </span>
                        <span style={{ fontSize: "14px", color: "grey" }}>
                            <Skeleton width={35} animation="pulse" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

const ServiceSkeletonLoading = () => {
    return (
        <>
            <div className="mx-lg-5 my-4">
                <div className="container-fluid bg-white rounded-5 px-4 pt-1 pb-3">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <h5 className="mb-0"><Skeleton width={90} animation="pulse" /></h5>
                            <div
                                style={{ fontSize: "12px" }}
                                className="border border-1 rounded py-1 px-2 me-2 rounded-pill text-center ms-2 border border-1 text-primary"
                            >
                                <Skeleton width={30} animation="pulse" />
                            </div>
                        </div>
                        <button className="btn p-0 me-0">
                            <Skeleton width={70} height={60} animation="pulse" />
                        </button>
                    </div>

                    <div style={{ height: "80px" }} className="">
                        <div className=" row m-auto">
                            <div
                                className={`col border border-1`}
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center py-1">
                                    <Skeleton variant="circular" width={60} height={60} />
                                    {/* <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
                                        <Skeleton width={50} height={30} animation="pulse" />
                                    </p> */}
                                </div>
                            </div>
                            <div
                                className={`col border border-1`}
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center py-1">
                                    <Skeleton variant="circular" width={60} height={60} />
                                    {/* <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
                                        <Skeleton width={50} height={30} animation="pulse" />
                                    </p> */}
                                </div>
                            </div>
                            <div
                                className={`col border border-1 d-sm-block d-none`}
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center py-1">
                                    <Skeleton variant="circular" width={60} height={60} />
                                    {/* <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
                                        <Skeleton width={50} height={30} animation="pulse" />
                                    </p> */}
                                </div>
                            </div>
                            <div
                                className={`col border border-1 d-md-block d-none`}
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center py-1">
                                    <Skeleton variant="circular" width={60} height={60} />
                                    {/* <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
                                        <Skeleton width={50} height={30} animation="pulse" />
                                    </p> */}
                                </div>
                            </div>
                            <div
                                className={`col border border-1 d-md-block d-none`}
                            >
                                <div className="d-flex flex-column justify-content-center align-items-center py-1">
                                    <Skeleton variant="circular" width={60} height={60} />
                                    {/* <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
                                        <Skeleton width={50} height={30} animation="pulse" />
                                    </p> */}
                                </div>
                            </div>
                        </div>
                    </div>


                    <ServiceSkeletonCard />
                    <ServiceSkeletonCard />
                    <ServiceSkeletonCard />
                    <ServiceSkeletonCard />
                    <ServiceSkeletonCard />
                </div>
            </div>
        </>
    )
}

export default ServiceSkeletonLoading
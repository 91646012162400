import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ModalC from "../../../../components/common/Modal";
import {
  addAppointmentNotes,
  appointmentPendingListSelector,
  availableSlotsSelector,
  changeAppointmentStatus,
  createNewAppointment,
  deleteMediaPhoto,
  getCurrentSlotsAction,
  resetAvailableSlots,
  scheduleSelector,
  uploadImageStatusSelector,
} from "../../../../reducers/appointmentReducer";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AppDispatch } from "../../../../store";
import { AddPhotoModel } from "./AddPhotoModel";
import SlotChip from "./SlotChip";
import { sendNotification } from "../../../../helper/provider/notification";
import EventIcon from "@mui/icons-material/Event";
import { array_to_CSV, blockInvalidChar } from "../../../../utils";
import { loggedInUserProfile } from "../../../../reducers/authReducer";
import ConfModal from "../../../../components/common/ConfModal";
import ActionPrompt from "./ActionPrompt";
import ImageProgress from "../../../../components/common/ImageProgress";

const AppointmentCompDrawer = ({
  handleClose,
  id,
  providerId,
  clientId,
  drawerClose,
  payment,
}) => {
  // console.log(clientId);
  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );

  const { notes } = useSelector((state: any) => state.appointment);
  const [scheduleData, setScheduleData] = useState<any>();
  const [note, setNote] = useState<any>({
    ...notes,
    clientId: clientId,
    appointmentId: id,
    providerId: providerId,
  });
  const [appointmentStatusData, setAppointmentStatusData] = useState<any>({});
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const loggedInUser = useSelector(loggedInUserProfile);

  useEffect(() => {
    const filteredList = pendingAppointmentListData?.filter((f) => f?.id == id);
    const index = pendingAppointmentListData?.findIndex((f) => f?.id == id);
    setScheduleData({
      ...filteredList,
      appointmentId: filteredList?.[0]?.id,
      clientId: filteredList?.[0]?.client?.id
        ? filteredList?.[0]?.client?.id
        : "",
      services: filteredList?.[0]?.services?.map((m) => m?.service),
      name: `${filteredList?.[0]?.client?.firstName} ${filteredList?.[0]?.client?.lastName}`,
      date: filteredList?.[0]?.appointmentDate,
      src: filteredList?.[0]?.client?.profileImage,
      tax: filteredList?.[0]?.tax,
      scheduleId: filteredList?.[0]?.scheduleId,
      startTime:
        filteredList?.[0]?.scheduleType === "multi-to-multi"
          ? filteredList?.[0]?.startTime
          : null,
      endTime:
        filteredList?.[0]?.scheduleType === "multi-to-multi"
          ? filteredList?.[0]?.endTime
          : null,
      fee: parseInt(filteredList?.[0]?.totalPrice),
      discount: filteredList?.[0]?.discount,
      location: filteredList?.[0]?.client?.address,
      media: filteredList?.[0]?.media,
      index: index,
      totalDuration: filteredList?.[0]?.totalDuration,
      totalPrice: filteredList?.[0]?.totalPrice,
      isPaid: filteredList?.[0]?.isPaid,
      scheduleType: filteredList?.[0]?.scheduleType,
    });
    // dispatch(
    //   fetchBookingByDateAction({
    //     date: filteredList?.[0]?.appointmentDate,
    //     services: filteredList?.[0]?.services?.map((m) => m?.service),
    //   })
    // );
    return () => {
      setScheduleData({});
      dispatch(resetAvailableSlots());
    };
    // console.log(filteredList);
  }, [id, pendingAppointmentListData]);

  // const convert12To24 = (time) => {
  //   const time24 = moment(time, "hh:mm A").format("HH:mm");
  //   return time24;
  // };

  //   useEffect(() => {
  //   }, [dispatch, id]);

  // console.log(convert12To24("12:01 PM"));

  const datePicker = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    // border: "1px solid #ccc",
    // borderRadius: "4px",
    padding: "8px",
  };

  const addNotes = (e) => {
    e.preventDefault();
    dispatch(addAppointmentNotes(note));
  };

  const handleDelete = (id: any) => {
    dispatch(deleteMediaPhoto({ id: id.id, index: scheduleData?.index }));
  };

  const availableSlotsDate = useSelector(availableSlotsSelector);
  const providerSchedule = useSelector(scheduleSelector);

  let availableSlotsMemoDate = React.useMemo(
    () => availableSlotsDate,
    [availableSlotsDate]
  );

  const [total, setTotal] = React.useState(0);
  const [miscFee, setMiscFee] = React.useState(0);

  useEffect(() => {
    setTotal(
      scheduleData?.services?.reduce((a, b) => a + b?.price, 0) + miscFee
    );
  }, [miscFee, scheduleData?.services]);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(scheduleData);

    const notificationData = {
      trigger: "PRO_REQUEST",
      triggerType: "client-triggers",
      modelType: "client",
      modelId: scheduleData.clientId,
      channel: "KC",
      title: "Payment Pending",
      messageParams: [{ name: scheduleData?.name }],
    };
    sendNotification(notificationData);
    setAppointmentStatusData({
      appointmentId: scheduleData.appointmentId,
      status: "completed",
      isPaid: scheduleData?.isPaid,
      statusToShow: "Completed",
      miscFee: miscFee ? miscFee : 0,
      textToShow: "Complete Appointment",
      handleClose,
      drawerClose,
      navigate,
    });
    handleActionModalOpen();
    // dispatch(
    //   changeAppointmentStatus({
    //     appointmentId: scheduleData.appointmentId,
    //     status: "completed",
    //     isPaid: scheduleData?.isPaid,
    //     statusToShow: "Completed",
    //     miscFee: miscFee ? miscFee : 0,
    //     handleClose,
    //     drawerClose,
    //     navigate,
    //   })
    // );
  };

  const createAppointment = (e) => {
    e.preventDefault();
    dispatch(
      createNewAppointment({ data: scheduleData, drawerClose, handleClose })
    );
  };

  const [toggle, setToggle] = React.useState(false);

  const imageStatus = useSelector(uploadImageStatusSelector);

  const [mediaUploadProgress, setMediaUploadProgress] =
    React.useState<number>();

  const imgStyle = {
    border: "2px #D0D5DD dotted",
    width: "100%",
    height: "65px",
    borderRadius: "8px",
  };
  const imgStyle2 = {
    width: "100%",
    height: "65px",
    zIndex: -1,
    marginTop: "-1.3rem",
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose2 = () => setOpen(false);

  const [actionModalOpen, setActionModalOpen] = useState(false);
  const handleActionModalOpen = () => setActionModalOpen(true);
  const handleActionModalClose = () => setActionModalOpen(false);

  //get the current date in the format of YYYY-MM-DD
  const currentDate = providerSchedule?.one_to_one?.isAllowLastMinuteBooking
    ? moment().format("YYYY-MM-DD")
    : moment().add(1, "days").format("YYYY-MM-DD");

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center pb-1 pt-3 ps-3 pe-4">
        <div
          className="d-flex align-items-center pointer"
          onClick={() => navigate(`/client/profile/${scheduleData?.clientId}`)}
        >
          <img
            src={scheduleData?.src ? scheduleData?.src : "/images/avatar2.png"}
            height={35}
            width={35}
            alt="arrow"
            className="me-2 rounded-circle"
          />
          <p className={`mb-0 text-capitalize`} style={{ fontSize: "20px" }}>
            {scheduleData?.name ? scheduleData?.name : "N/A"}
          </p>
        </div>
        <img
          onClick={handleClose}
          src="/images/clientList/close.png"
          alt="arrow"
          className="pointer me-1"
        />
      </div>
      <hr />
      <div className="mt-3 ps-3 pe-4 mb-1">
        <div className="row g-3">
          <div className="col-12">
            <div
              style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
              className={`rounded-b5 px-3 py-2 rounded`}
            >
              <div className="d-flex justify-content-between align-items-center">
                <label style={{ fontSize: "14px" }} className="form-label mb-2">
                  Appointment Note
                </label>
                <div
                  onClick={(e) => {
                    setToggle(!toggle);
                    if (toggle) {
                      addNotes(e);
                    }
                  }}
                  className="d-flex align-items-center pointer mb-2"
                >
                  {toggle ? (
                    <img
                      src="/images/clientList/success.png"
                      alt="arrow"
                      className="me-2"
                    />
                  ) : (
                    <img
                      src="/images/clientList/edit.png"
                      alt="arrow"
                      className="me-2"
                    />
                  )}
                  <p
                    className={`mb-0 text-primary`}
                    style={{ fontSize: "15px" }}
                  >
                    {toggle ? "Save" : "Edit"}
                  </p>
                </div>
              </div>
              <textarea
                style={{
                  fontSize: "14px",
                  backgroundColor: toggle ? null : "#F1F5F9",
                  resize: toggle ? "vertical" : "none",
                }}
                className={`form-control ${
                  toggle ? null : "bgLight"
                } rounded-b5`}
                name="businessDescription"
                id="description"
                value={toggle ? note?.notes : note?.notes}
                placeholder=""
                onChange={(e) =>
                  setNote({
                    ...note,
                    notes: e.target.value,
                  })
                }
                cols={8}
                rows={2}
                disabled={!toggle}
              />
            </div>
          </div>
          <div className="col-12">
            <div
              style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
              className={`rounded-b5 p-2 rounded`}
            >
              <div className="row gy-2 gx-2 row-cols-xl-5 row-cols-md-4 row-cols-2 w-100 m-auto pb-2">
                {scheduleData?.media?.map((m) => (
                  <div className="col">
                    <div className="rounded-5 text-end">
                      <img
                        height={15}
                        width={15}
                        className="pointer bg-white rounded-circle"
                        style={{
                          zIndex: 1,
                          position: "relative",
                          bottom: "8px",
                          left: "4px",
                        }}
                        onClick={() => {
                          // console.log(scheduleData?.index);
                          handleDelete({ id: m?.id });
                        }}
                        src="/images/cross.png"
                        alt="thumbnail"
                      />
                      {/* {m?.media_type?.includes("image") ? ( */}
                      <img
                        style={imgStyle2}
                        src={m?.thumbnail_url}
                        alt="avatar"
                        // className="mt-4"
                      />
                      {/* // ) : (
                      //   <video style={imgStyle2} id="video" controls>
                      //     <source src={m?.thumbnail_url} type="video/mp4" />
                      //   </video>
                      // )} */}
                    </div>
                  </div>
                ))}
                {scheduleData?.media?.length >= 5 ? null : (
                  <>
                    {mediaUploadProgress ? (
                      mediaUploadProgress !== 100 ||
                      imageStatus === "loading" ? (
                        <div className="col">
                          <ImageProgress
                            progress={mediaUploadProgress}
                            appointment={true}
                          />
                        </div>
                      ) : (
                        ""
                      )
                    ) : null}
                    <div className="col">
                      <div
                        onClick={handleOpen}
                        style={imgStyle}
                        className="d-flex align-items-center justify-content-center pointer"
                      >
                        <img
                          height={30}
                          src="/images/Media/plus.png"
                          alt="Add"
                          // className="mt-4"
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <ModalC handleClose={handleClose2} open={open}>
              <AddPhotoModel
                index={scheduleData?.index}
                appointmentId={id}
                mediaUploadProgress={mediaUploadProgress}
                setMediaUploadProgress={setMediaUploadProgress}
                handleClose={handleClose2}
              />
            </ModalC>
          </div>
          <div className="col-12">
            <form onSubmit={createAppointment}>
              <div
                style={{ backgroundColor: "#F1F5F9" }}
                className="accordion-item rounded-b5 border border-white"
              >
                <h2 className="accordion-header" id="headingOne">
                  <button
                    style={{ backgroundColor: "#F1F5F9" }}
                    className="accordion-button d-flex justify-content-between pointer px-0 border border-0"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <div className="row w-100 align-items-center m-auto pointer">
                      <div className="col ps-3">Booking</div>
                      <div className="col text-end">
                        <img
                          className="down-arrow"
                          src="/images/schedule/arrow-down.svg"
                          alt=""
                        />
                      </div>
                    </div>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse collapsed"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div style={datePicker}>
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <MobileDatePicker
                          openTo="day"
                          minDate={currentDate}
                          disablePast={true}
                          views={["year", "month", "day"]}
                          value={scheduleData?.date ? scheduleData?.date : null}
                          onChange={(e) => {
                            setScheduleData({
                              ...scheduleData,
                              date: e,
                            });
                            const serviceIdsArray = scheduleData?.services?.map(
                              ({ id }) => id
                            );
                            if (
                              scheduleData?.scheduleType !== "multi-to-multi"
                            ) {
                              dispatch(
                                getCurrentSlotsAction({
                                  date: e,
                                  providerId: loggedInUser?.id,
                                  servicesId: array_to_CSV(serviceIdsArray),
                                })
                              );
                            }
                          }}
                          renderInput={(params) => (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid #ccc",
                                borderRadius: "4px",
                              }}
                            >
                              <EventIcon style={{ margin: "0 8px" }} />
                              <TextField
                                variant="standard"
                                {...params}
                                error={false}
                              />
                            </div>
                          )}
                          renderDay={(day) => {
                            const dayOfWeek = moment(day).format("dddd");
                            let index;
                            if (
                              scheduleData?.scheduleType === "multi-to-multi"
                            ) {
                              index =
                                providerSchedule?.multi_to_multi?.findIndex(
                                  (data) => data.id === scheduleData?.scheduleId
                                );
                            }
                            const isBooked =
                              scheduleData?.scheduleType === "multi-to-multi"
                                ? providerSchedule?.multi_to_multi[
                                    index
                                  ]?.days?.find(
                                    (item) => item.scheduleDays == dayOfWeek
                                  )
                                : providerSchedule?.one_to_one?.days?.find(
                                    (item) => item.scheduleDays == dayOfWeek
                                  );
                            // show these only for 6 months
                            const isWithin6Months = moment(day).isBefore(
                              moment().add(6, "months")
                            );
                            // disable past date
                            const isPastDate =
                              moment(day).isBefore(currentDate);

                            return (
                              <div
                                style={{
                                  display: "inline-flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  position: "relative",
                                  boxSizing: "border-box",
                                  outline: "0px",
                                  border: "0px",
                                  userSelect: "none",
                                  verticalAlign: "middle",
                                  appearance: "none",
                                  textDecoration: "none",
                                  fontFamily:
                                    "Roboto, Helvetica, Arial, sans-serif",
                                  fontWeight: "400",
                                  fontSize: "0.75rem",
                                  lineHeight: "1.66",
                                  letterSpacing: "0.03333em",
                                  width: "36px",
                                  height: "36px",
                                  borderRadius: "50%",
                                  padding: "0px",
                                  color: "black",
                                  margin: "0px 2px",
                                  cursor:
                                    isPastDate || !isBooked || !isWithin6Months
                                      ? "not-allowed"
                                      : "pointer",
                                }}
                                onClick={() => {
                                  if (
                                    isPastDate ||
                                    !isBooked ||
                                    !isWithin6Months
                                  ) {
                                    return;
                                  } else {
                                    setScheduleData({
                                      ...scheduleData,
                                      date: moment(day).format("YYYY-MM-DD"),
                                    });
                                    const serviceIdsArray =
                                      scheduleData?.services?.map(
                                        ({ id }) => id
                                      );
                                    if (
                                      scheduleData?.scheduleType !==
                                      "multi-to-multi"
                                    ) {
                                      dispatch(
                                        getCurrentSlotsAction({
                                          date: moment(day).format(
                                            "YYYY-MM-DD"
                                          ),
                                          providerId: loggedInUser?.id,
                                          servicesId:
                                            array_to_CSV(serviceIdsArray),
                                        })
                                      );
                                    }
                                  }
                                }}
                              >
                                {isWithin6Months ? (
                                  scheduleData?.date ==
                                  moment(day).format("YYYY-MM-DD") ? (
                                    <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                        boxSizing: "border-box",
                                        outline: "0px",
                                        border: "0px",
                                        userSelect: "none",
                                        verticalAlign: "middle",
                                        appearance: "none",
                                        textDecoration: "none",
                                        fontFamily:
                                          "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: "400",
                                        fontSize: "0.75rem",
                                        lineHeight: "1.66",
                                        letterSpacing: "0.03333em",
                                        width: "36px",
                                        height: "36px",
                                        borderRadius: "50%",
                                        color: "white",
                                        backgroundColor: "#1565c0",
                                      }}
                                    >
                                      {moment(day).format("D")}
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        display: "inline-flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        position: "relative",
                                        boxSizing: "border-box",
                                        outline: "0px",
                                        border: "0px",
                                        userSelect: "none",
                                        verticalAlign: "middle",
                                        appearance: "none",
                                        textDecoration: "none",
                                        fontFamily:
                                          "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: "400",
                                        fontSize: "0.75rem",
                                        lineHeight: "1.66",
                                        letterSpacing: "0.03333em",
                                        width: "36px",
                                        height: "36px",
                                        borderRadius: "8px",
                                        padding: "0px",
                                        margin: "0px 2px",
                                        backgroundColor: isBooked
                                          ? !isPastDate
                                            ? "#04bac7"
                                            : ""
                                          : "",
                                        color: isBooked
                                          ? !isPastDate
                                            ? "black"
                                            : "#d6cfcf"
                                          : "#d6cfcf",
                                      }}
                                    >
                                      {moment(day).format("D")}
                                    </span>
                                  )
                                ) : (
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      outline: "0px",
                                      border: "0px",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      textDecoration: "none",
                                      fontFamily:
                                        "Roboto, Helvetica, Arial, sans-serif",
                                      fontWeight: "400",
                                      fontSize: "0.75rem",
                                      lineHeight: "1.66",
                                      letterSpacing: "0.03333em",
                                      width: "36px",
                                      height: "36px",
                                      borderRadius: "8px",
                                      padding: "0px",
                                      margin: "0px 2px",
                                      backgroundColor: "",
                                      color: "#d6cfcf",
                                    }}
                                  >
                                    {moment(day).format("D")}
                                  </span>
                                )}
                              </div>
                            );
                          }}
                        />
                      </LocalizationProvider>
                    </div>
                    {scheduleData?.scheduleType !== "multi-to-multi" ? (
                      <label
                        style={{ fontSize: "14px" }}
                        className="form-label mb-1 mt-3"
                      >
                        Time Slots:
                      </label>
                    ) : null}
                    <div className="row h-100">
                      {scheduleData?.scheduleType !== "multi-to-multi" ? (
                        <div className="col-8">
                          {availableSlotsMemoDate?.length === 0 ? (
                            <p className="text-danger">No slots available</p>
                          ) : (
                            availableSlotsMemoDate?.map(
                              (slot: {
                                startTime: string;
                                endTime: string;
                              }) => (
                                <SlotChip
                                  startTime={slot.startTime}
                                  endTime={slot.endTime}
                                  setScheduleData={setScheduleData}
                                  scheduleData={scheduleData}
                                />
                              )
                            )
                          )}
                        </div>
                      ) : null}
                      <div className="col-4 text-end align-self-end">
                        <button
                          style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                          className="btn bg-white btn-outline-secondary border-b5  border hover-primary2"
                          type="submit"
                          disabled={!scheduleData?.startTime}
                        >
                          Book Next
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {!payment ? (
            <div className="col-12">
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                  Misc Fee
                </p>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Misc Fee"
                  onKeyDown={blockInvalidChar}
                  min={1}
                  value={miscFee === 0 ? "" : miscFee}
                  onChange={(e) => {
                    if (e.target.value === "") {
                      setMiscFee(0);
                    } else {
                      if (e.target.value.length < 4) {
                        // console.log();

                        setMiscFee(parseInt(e.target.value));
                      }
                    }
                  }}
                />
              </div>
            </div>
          ) : null}
          <div className="col-12">
            {/* border-bottom border-3 border-primary */}
            <div
              style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
              className={`form-control rounded-b5 px-4 py-2 border-0`}
            >
              <p className={`mb-1 mt-1 text-dark`} style={{ fontSize: "16px" }}>
                {total ? `$ ${total}` : "$0.00"}
              </p>
              <p
                className={`mb-0 mt-1 fw-light`}
                style={{ fontSize: "14px", color: "#667085" }}
              >
                Total Amount
              </p>
            </div>
          </div>
        </div>

        <div className="d-flex gap-2 justify-content-end mt-3">
          {!payment ? (
            <button
              style={{ fontSize: "14px", borderRadius: "6px" }}
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              Complete/Request Payment
            </button>
          ) : null}
        </div>
      </div>
      <ConfModal handleClose={handleActionModalClose} open={actionModalOpen}>
        <ActionPrompt
          handleClose={handleActionModalClose}
          data={appointmentStatusData}
          textToShow={appointmentStatusData?.textToShow}
        />
      </ConfModal>
    </div>
  );
};

export default AppointmentCompDrawer;

const Empty = () => {
    return (
        <div className="d-flex h-100 align-items-center">
            <div className="col-8 m-auto py-5 text-center">
                <label>
                    <img
                        src="/images/theme/empty_list.svg"
                        alt="avatar"
                        className="pointer"
                        height="200px"
                    />
                    {/* <h6 className="mb-0 fw-bold mt-3 mb-1"> */}
                    <h6 className="mb-0 text-grey my-3">
                        Notification list is empty
                    </h6>
                    {/* <p
                        style={{ fontSize: "14px", color: "#667085" }}
                        className="mb-0 text-grey"
                    >
                        You can add images from you favorite social media platforms e.g.
                        Facebook, Instagram, Twitter etc.
                    </p> */}
                </label>
            </div>
        </div>
    )
}

export default Empty
import React from 'react'
import ClientHistory from '../components/ClientHistory'
import ClientMedia from '../components/ClientMedia'

const ProfileOverview = ({profile, openedTab}) => {
    return (
        <>
            <ClientHistory profile={profile} openedTab={openedTab} />
            <ClientMedia media={profile} openedTab={openedTab} />
        </>
    )
}

export default ProfileOverview
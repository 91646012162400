import React from "react";
import { useSelector } from "react-redux";
import Bar from "../Bar";
import Empty from "../Empty";
import { connectionNotificationSelector } from "../../../../../reducers/notificationReducer";

const Connections = () => {
  const data = useSelector(connectionNotificationSelector);

  const isQueEmpty = data.length == 0;

  if (isQueEmpty) {
    return <Empty />;
  } else {
    return (
      <>
        {data.map((data, idx) => (
          <Bar key={idx} data={data} />
        ))}
      </>
    );
  }
};

export default Connections;

import React from 'react'
import ClientHistory from '../components/ClientHistory'
import ClientNotes from '../components/ClientNotes'

const ProfileHistory = ({ profile, openedTab }) => {
    return (
        <>
            <ClientHistory profile={profile} openedTab={openedTab} />
            <ClientNotes notes={profile?.notes} clientId={profile?.id} clientName={profile?.firstName + ' ' + profile?.lastName} openedTab={openedTab} />
        </>
    )
}

export default ProfileHistory
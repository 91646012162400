import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  scheduleOnToOneReadOnlySelector,
  updateOneToOne,
  updateOneToOneDoubleBooking,
  updateOneToOneServices,
  updateOneToOneServicesToAll,
} from "../../../../../reducers/scheduleReducer";
import { ownServiceSelector } from "../../../../../reducers/serviceReducer";
import { AppDispatch } from "../../../../../store";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const ServiceAccordion = ({ selectedService, change, setChange }: any) => {
  // let selectedIds: Array<any> = selectedService?.map(({ service }) => service);

  const dispatch = useDispatch<AppDispatch>();

  const { onToOne } = useSelector((state: any) => state.schedule);

  const handleServiceChange = (data: any) => {
    const finalData = {
      id: data?.id,
      service: data.Service,
      serviceName: data.Service,
      isAvailable: 1,
      isDoubleBooking: 1,
    };
    // console.log("finalData =>", data);

    dispatch(updateOneToOneServices({ id: data.id, service: finalData }));
    dispatch(updateOneToOneDoubleBooking({ id: data.id }));
    // setTime({
    //   ...time,
    // [e.target.name]: e.target.value,
    // });
  };

  const handleChange = (e: any) => {
    dispatch(updateOneToOne({ key: e.target.name, value: e.target.value }));
    dispatch(updateOneToOne({ key: "serviceLocation", value: e.target.value }));
  };

  const servicesData = useSelector(ownServiceSelector);
  const readOnlyData = useSelector(scheduleOnToOneReadOnlySelector);

  // const serviceAvailableInfo = () => ("Some Operators prefer to \n limit some services to certain \n parts of their schedule. Use \n this tool to select from your \n services which of those \n services on your list are \n available during specific \n schedule conditions you \n custom build")

  // console.log("services =>", servicesData);
  // console.log("selectec services =>", selectedIds);

  return (
    <div className="accordion mb-3" id="accordion">
      <div
        className={`accordion-item ${
          onToOne?.servicesIds?.length === 0
            ? "border-danger"
            : change.services
            ? "border-start-primary"
            : ""
        }`}
      >
        <h2 className="accordion-header" id="heading">
          <button
            style={{ backgroundColor: "#f9fafb", minHeight: "4.4rem" }}
            className="accordion-button px-md-4 px-2 collapsed"
            type="button"
          >
            <div className="row w-100 align-items-center">
              <div className="col-md-6 col-8">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3 pointer"
                    height={22}
                    width={22}
                    src="/images/schedule/serviceAvailable.svg"
                    alt=""
                  />
                  <p className="fw-light mb-0 text-dark">Service Available</p>
                  {readOnlyData?.services?.filter(
                    ({ isAvailable }) => isAvailable === 1
                  )?.length !== 0 ? (
                    readOnlyData?.services ? (
                      <div
                        style={{
                          height: "24px",
                          width: "25px",
                          fontSize: "13px",
                          backgroundColor: "#26ab5e",
                        }}
                        className={`rounded-circle p-1 ms-sm-2 d-flex align-items-center justify-content-center pointer`}
                      >
                        <p className="m-0 p-0 text-white">
                          {
                            readOnlyData?.services?.filter(
                              ({ isAvailable }) => isAvailable === 1
                            )?.length
                          }
                        </p>
                      </div>
                    ) : null
                  ) : null}
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="d-flex align-items-center justify-content-end actions">
                  <BootstrapTooltip
                    placement="top"
                    title="Some Operators prefer to limit some services to certain parts of their schedule. Use this tool to select from your services which of those services on your list are available during specific schedule conditions you custom build"
                  >
                    <img
                      id="serviceOne"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  {/* <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={onToOne?.}
                      id="flexSwitchCheckChecked"
                    />
                  </div> */}
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseS"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapseS"
          className={`accordion-collapse collapse ${
            onToOne?.servicesIds?.length === 0 ? "show" : "show"
          }`}
          aria-labelledby="heading"
          data-bs-parent="#accordion"
        >
          <div className="accordion-body px-2 py-4">
            <div className="row w-100 m-auto rounded-5 g-2">
              <div className="col-12">
                <div className="form-check">
                  <input
                    onChange={(e) => {
                      const newServiceData = servicesData.map((S) => {
                        // console.log("S =>", S);
                        return {
                          id: S?.providerService?.id,
                          service: S,
                          serviceName: S,
                          isDoubleBooking: 1,
                          isAvailable: 1,
                          noOfParticipants: null,
                        };
                      });
                      // console.log("newServiceData =>", service);
                      const object = {
                        isDoubleBooking: 1,
                      };
                      if (onToOne?.isActive !== 1) {
                        newServiceData.unshift(object);
                      }

                      dispatch(
                        updateOneToOneServicesToAll({
                          select: e.target.checked ? 1 : 0,
                          serviceArr: newServiceData,
                        })
                      );
                      dispatch(
                        updateOneToOneDoubleBooking({
                          id: newServiceData,
                        })
                      );
                      setChange({
                        ...change,
                        services: true,
                      });
                    }}
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="CheckSelectAll"
                    checked={
                      onToOne?.services?.[0]?.serviceName?.id
                        ? servicesData?.length ===
                          onToOne?.services?.filter(
                            ({ isAvailable }) => isAvailable === 1
                          )?.length
                        : servicesData?.length + 1 ===
                          onToOne?.services?.filter(
                            ({ isAvailable }) => isAvailable === 1
                          )?.length
                    }
                  />
                  <label
                    style={{ fontSize: "14px" }}
                    className="form-check-label fw-bold"
                    htmlFor="CheckSelectAll"
                  >
                    Select All ({servicesData?.length})
                  </label>
                </div>
              </div>
              {servicesData.map((data, idx: React.Key) => (
                <div key={idx} className="col-md-4 col-sm-6 col-12">
                  <div className="bg-light-sky p-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="services"
                        onChange={() => {
                          // console.log("providerService =>", data);
                          handleServiceChange({
                            id: data?.providerService?.id,
                            Service: data,
                          });

                          setChange({
                            ...change,
                            services: true,
                          });
                        }}
                        checked={
                          onToOne?.services
                            ?.filter(({ isAvailable }) => isAvailable === 1)
                            .find((m) => m?.id === data?.providerService.id)
                            ? true
                            : false
                        }
                        id={`serviceCheckbox-${data?.providerService.id}-${idx}`}
                      />
                      <label
                        style={{ fontSize: "14px" }}
                        className="form-check-label"
                        htmlFor={`serviceCheckbox-${data?.providerService.id}-${idx}`}
                      >
                        {data?.providerService.service_name}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="row w-100 m-auto mt-4 px-0 rounded-5">
              <div className="col-md-6">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Set Service Location
                </p>
                <input
                  style={{ fontSize: "15px" }}
                  type="text"
                  name="servicesLocation"
                  onChange={(e) => {
                    handleChange(e);
                    setChange({
                      ...change,
                      services: true,
                    });
                  }}
                  value={onToOne?.servicesLocation || ""}
                  className="border-1 form-control rounded-4 align-items-center justify-content-center px-2 mt-1 form-control"
                  placeholder="Location"
                  id="Text1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAccordion;

import React from "react";
import { levelTwoCardType } from "../../services/type";
// import { exploreFilterSelector } from "../../../../reducers/mediaReducer";
// import { useSelector } from "react-redux";

const LevelTwo = ({ icon, name, id, filters }: levelTwoCardType) => {
  // const exploreFilterMediaData = useSelector(exploreFilterSelector);
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center py-1 m-auto`}
    >
      {/* <span>
        {exploreFilterMediaData} - {id}
      </span> */}
      {/* <img
        className="rounded-circle mb-1"
        style={{ objectFit: "contain" }}
        height={35}
        width={35}
        src={icon}
        alt=""
      /> */}
      <img
        style={{
          objectFit: "cover",
          borderTopRightRadius: "10px",
          borderTopLeftRadius: "10px",
        }}
        height={140}
        width={"100%"}
        src={icon}
        alt="service icon"
        className="pointer border border-bottom-0"
      />
      <div
        style={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
          cursor: "pointer",
        }}
        className={`border  ${
          filters?.serviceId == id ? "bg-primary" : " "
        }  border-top-0 w-100 text-start ps-3 py-3`}
      >
        <p style={{ fontSize: "15px" }} className="mb-0">
          {name.length > 20 ? name.substring(0, 20) + "..." : name}
        </p>
      </div>
    </div>
  );
};

export default LevelTwo;

/**
 * Project: Kazzah
 * For: Appointment
 * Type: Custom Button
 *
 * This component is a custom button designed specifically for the Appointment feature of the Kazzah project.
 *
 * Note: Do not make any changes to this file without permission from the original author.
 *
 * Written by: [Umair Abbas] (https://github.com/umairabbasDev)
 */

// MUI
import { styled, createTheme, ThemeProvider } from "@mui/system";
// tooltip
import { Tooltip as ReactTooltip } from "react-tooltip";

// icons
import { RestartAlt as RescheduleIcon } from "@mui/icons-material";
import { Restore as RebookIcon } from "@mui/icons-material";
import { Close as CloseIcon } from "@mui/icons-material";
import { Done as approveIcon } from "@mui/icons-material";
import { Receipt as PaymentRequestIcon } from "@mui/icons-material";
import { AppointmentButtons, btn_COLORS } from "../../../../config/constant";
import { EventAvailable as checkoutIcon } from "@mui/icons-material";
import { Paid as PaidIcon } from "@mui/icons-material";

// types
type colorParameter = "PR" | "PD" | "RS" | "AP" | "CA" | "CO" | "DULL";
type nameParameter =
  | "approve"
  | "reschedule"
  | "cancel"
  | "checkout"
  | "paymentRequest"
  | "paid";

interface MyAppointmentButtonProps {
  color?: colorParameter;
  variant?: "normal" | "dashed";
}

// theme
const customTheme = createTheme({
  components: {
    MyAppointmentButton: {
      styleOverrides: {
        root: {
          color: "#fff",
          backgroundColor: "#04bac7",
        },
        RS: {
          color: btn_COLORS.reschedule.color,
          backgroundColor: btn_COLORS.reschedule.backgroundColor,
        },
        AP: {
          color: btn_COLORS.approve.color,
          backgroundColor: btn_COLORS.approve.backgroundColor,
        },
        CA: {
          color: btn_COLORS.cancel.color,
          backgroundColor: btn_COLORS.cancel.backgroundColor,
        },
        CO: {
          color: btn_COLORS.checkout.color,
          backgroundColor: btn_COLORS.checkout.backgroundColor,
        },
        PR: {
          color: btn_COLORS.paymentRequest.color,
          backgroundColor: btn_COLORS.paymentRequest.backgroundColor,
        },
        PD: {
          color: btn_COLORS.paid.color,
          backgroundColor: btn_COLORS.paid.backgroundColor,
        },
        DULL: {
          color: "#9e9e9e",
          backgroundColor: "#bdbdbd",
        },
      },
      variants: [
        {
          props: { variant: "dashed", color: "root" },
          style: {
            border: "1px dashed darkblue",
          },
        },
        {
          props: { variant: "dashed", color: "root" },
          style: {
            border: "1px dashed darkred",
          },
        },
      ],
    },
  },
});

// custom styling
const MyAppointmentButton = styled("button", {
  // Configure which props should be forwarded on DOM
  shouldForwardProp: (prop) =>
    prop !== "color" && prop !== "variant" && prop !== "sx",
  name: "MyAppointmentButton",
  slot: "Root",
  // We are specifying here how the styleOverrides are being applied based on props
  overridesResolver: (props, styles) => [
    styles.root,
    props.color === "RS" && styles.RS,
    props.color === "AP" && styles.RB,
    props.color === "CA" && styles.CA,
    props.color === "CO" && styles.CO,
    props.color === "PR" && styles.PR,
    props.color === "PD" && styles.PD,
    props.color === "DULL" && styles.DULL,
  ],
})<MyAppointmentButtonProps>(({ theme, color }) => ({
  border: "1.5px solid rgb(232, 234, 238)",
  borderRadius: "9px",
  padding: "5.5px 6px",
  marginBottom: "3px",
}));

// util functions
function ChoseIcon({ btnName }: { btnName: nameParameter }) {
  for (const key in btn_COLORS) {
    if (key === btnName) {
      const Icon = getIcon(key);
      return <Icon />;
    }
  }
}

function getIcon(key: keyof typeof btn_COLORS) {
  switch (key) {
    case "approve":
      return approveIcon;
    case "reschedule":
      return RescheduleIcon;
    case "cancel":
      return CloseIcon;
    case "checkout":
      return checkoutIcon;
    case "paid":
      return PaidIcon;
    case "paymentRequest":
      return PaymentRequestIcon;
  }
}

// main component
export default function FourAppointmentButton({
  btnData,
  onClick,
}: {
  btnData: typeof AppointmentButtons[0];
  onClick?: any;
}) {
  return (
    <ThemeProvider theme={customTheme}>
      <MyAppointmentButton
        id={btnData?.id as unknown as string}
        sx={{ m: 1 }}
        color={btnData?.color}
        onClick={onClick}
      >
        <ChoseIcon btnName={btnData?.name} />
      </MyAppointmentButton>
      <ReactTooltip
        style={{ backgroundColor: "#101828", position: "absolute" }}
        anchorId={btnData?.id as unknown as string}
        place="top"
        content={btnData?.toolTip}
      />
    </ThemeProvider>
  );
}

import React from "react";
import ChatIcon from "@mui/icons-material/Chat";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import ProfileLoading from "./ProfileLoading";
import { useDispatch, useSelector } from "react-redux";
import {
  disconnectWithConnection,
  memberStatusSelector,
  memberVicAction,
  profileStatusSelector,
  vicStatusSelector,
} from "../../../../reducers/clientReducer";
import { addMobileNumbers, createConversation } from "../../../../Firebase";
import { loggedInUserProfile } from "../../../../reducers/authReducer";
import { xconsole } from "../../../../utils/console";
import { useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../../store";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import DeleteIcon from "@mui/icons-material/Delete";
import StarOutlineRoundedIcon from "@mui/icons-material/StarOutlineRounded";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";
import ConfModal from "../../../../components/common/ConfModal";
import DeletePrompt from "../../../../components/common/DeletePrompt";
import moment from "moment";
import { createId, greaterId, smallerId } from "../../../../utils";
import FilterButton from "../../../../components/common/Button/FilterBtn";

const ClientDetailChild = ({
  src,
  userName,
  date,
  time,
  address,
  clientSince,
  phoneNo,
  mail,
  profile,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let navigate = useNavigate();
  const profileStatus = useSelector(profileStatusSelector);
  const loggedInUser = useSelector(loggedInUserProfile);
  const dispatch = useDispatch<AppDispatch>();
  const status = useSelector(memberStatusSelector);
  const vicStatus = useSelector(vicStatusSelector);

  const createConversationData = async () => {
    try {
      // xconsole.test("pass");
      navigate(`/chat`, {
        state: {
          mobileNo: profile?.id,
          data: {
            finalData: {
              group: createId(true, loggedInUser?.id, profile?.id),
              firstName: profile?.firstName,
              lastName: profile?.lastName,
              message: "",
              messageCreatedAt: new Date(),
              model: "clients",
              profilePicture: profile?.profileImage,
              type: "",
              userId: profile?.id,
            },

            activeRoomData: {
              id: createId(true, loggedInUser?.id, profile?.id),
              recipient: {
                id: createId(true, loggedInUser?.id, profile?.id),
                avatarUrl: profile?.profileImage,
                userId: profile?.id,
                online: false,
                name: profile?.firstName + " " + profile?.lastName,
                isProvider: false,
                modifiedAt: {
                  date: new Date(),
                  time: moment().format("hh:mm A"),
                },
                createdAt: {
                  seconds: moment().unix(),
                  nanoseconds: moment().milliseconds(),
                },
              },
              messages: [],
            },
          },
        },
      });
    } catch (error) {
      console.log("error ", error);
    }
  };

  const makeMemberVic = () => {
    if (profile?.isVic == 1) {
      const data = {
        clientId: profile?.id,
        isVic: 0,
      };
      dispatch(memberVicAction(data));
    } else {
      const data = {
        clientId: profile?.id,
        isVic: 1,
      };
      dispatch(memberVicAction(data));
    }
  };

  const connectionDelete = {
    disconnectWtih: "member",
    id: Number(profile?.id),
  };

  const fsFourteen = {
    fontSize: "14px",
  };
  const textLight = {
    color: "#667085",
    fontWeight: "300",
  };
  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };
  return (
    <div className="col">
      {profileStatus == "loading" ? (
        <ProfileLoading clienProfile={true} />
      ) : (
        <div className="d-flex">
          <img
            className="rounded-circle me-3 border border-primary"
            style={{ objectFit: "cover" }}
            height={54}
            width={54}
            src={src == null ? "/images/avatar2.png" : src}
            alt=""
          />
          <div className="w-100">
            <p
              className={`mb-0 mt-1 text-dark text-capitalize`}
              style={{ fontSize: "18px" }}
            >
              {profile?.firstName} {profile?.lastName}
            </p>
            <p
              style={{
                ...textLight,
                ...poppinsFont,
                fontSize: "13px",
                marginTop: "-0.4rem",
              }}
              className="mb-0"
            >
              {userName}
            </p>
            <div className="d-flex d-sm-block d-sm-none d-block mt-2">
              <BootstrapTooltip
                placement="top"
                title="VIC designation (Very Important Client) will allow you to extend special benefits to those customers that have special value to you. More tools around this feature and designation are developing"
              >
                <img
                  id="serviceOne"
                  className="me-3 pointer mt-2"
                  height={18}
                  src="/images/schedule/infosvg.svg"
                  alt=""
                />
              </BootstrapTooltip>
              <div className="d-flex align-items-start">
                <div
                  className="d-flex pb-1 gap-2 align-items-center"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <FilterButton
                    tooltip="VIC"
                    onClick={() => makeMemberVic()}
                    style={{
                      width: "42px",
                      height: "40px",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    {vicStatus == "loading" ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm text-primary"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Loading...</span>
                      </>
                    ) : profile?.isVic ? (
                      <StarRoundedIcon
                        sx={{ fontSize: 20, color: "#04bac7" }}
                      />
                    ) : (
                      <StarOutlineRoundedIcon
                        sx={{ fontSize: 20, color: "#505050" }}
                      />
                    )}
                  </FilterButton>
                  <FilterButton
                    tooltip="Delete Connection"
                    onClick={() => handleOpen()}
                    style={{
                      width: "42px",
                      height: "40px",
                    }}
                    className="d-flex align-items-center justify-content-center"
                  >
                    <DeleteIcon
                      className=""
                      sx={{ fontSize: 20, color: "#505050" }}
                    />
                  </FilterButton>
                </div>
              </div>
            </div>
            {/* <p
              style={{
                ...textLight,
                fontSize: "13px",
              }}
              className="mb-2 text-primary"
            >
              Client Since
              <span style={{ ...textLight }} className="ms-1">
                {clientSince}
              </span>
            </p> */}
            <div className="row mt-2">
              <div className="col-md-4 col-6">
                <BootstrapTooltip
                  title="Last Appointment"
                  placement="top-start"
                >
                  <div className="d-flex align-items-center pointer">
                    <UndoIcon
                      className="me-1 mb-1 rotate30"
                      style={{ color: "#505050" }}
                      fontSize="small"
                    />
                    <p
                      style={{
                        ...textLight,
                        ...poppinsFont,
                        ...fsFourteen,
                      }}
                      className="mb-0 me-3"
                    >
                      {profile?.lastAppointment
                        ? profile?.lastAppointment?.appointment_date
                        : "None"}
                    </p>
                  </div>
                </BootstrapTooltip>
              </div>
              <div className="col-md-6 col-6">
                <BootstrapTooltip
                  title="Next Appointment"
                  placement="top-start"
                >
                  <div className="d-flex align-items-center pointer">
                    <RedoIcon
                      className="me-1 rotate-30"
                      style={{ color: "#505050" }}
                      fontSize="small"
                    />
                    <p
                      style={{
                        ...textLight,
                        ...poppinsFont,
                        ...fsFourteen,
                      }}
                      className="mb-0 pointer"
                    >
                      {profile?.nextAppointment?.appointment_date
                        ? profile?.nextAppointment?.appointment_date
                        : "None"}
                    </p>
                  </div>
                </BootstrapTooltip>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-md-4 col-6">
                <div className="d-flex">
                  <DateRangeIcon
                    className="me-1 mb-2 pointer"
                    style={{ color: "#505050" }}
                    fontSize="small"
                  />
                  <p
                    style={{
                      ...textLight,
                      ...poppinsFont,
                      fontSize: "13px",
                    }}
                    className="mb-2 align-self-center"
                  >
                    Client Since {clientSince}
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-6">
                <div className="d-flex">
                  <LocationOnIcon
                    className="mb-2 pointer"
                    style={{ color: "#505050" }}
                    fontSize="small"
                  />
                  <p
                    style={{
                      ...textLight,
                      ...poppinsFont,
                      ...fsFourteen,
                      fontSize: "13px",
                    }}
                    className="mb-2 text-capitalize align-self-center"
                  >
                    {address}
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex flex-sm-row flex-column mt-3 mb-4">
              <button
                style={{ whiteSpace: "nowrap", ...fsFourteen }}
                className="btn btn-outline-secondary d-flex flex-row align-items-center ps-1 border-b5 border me-sm-2 hover-primary2 hover-invert my-sm-0 my-1 py-sm-1 ps-sm-1 pe-sm-2 p-2 justify-content-sm-start justify-content-center"
                onClick={() => createConversationData()}
              >
                <ChatIcon sx={{ fontSize: 18 }} className="mx-2" />
                Chat
              </button>
              <a href={`tel:${phoneNo}`} className="me-sm-2">
                <button
                  style={{ whiteSpace: "nowrap", ...fsFourteen }}
                  className="btn btn-outline-secondary d-flex flex-row align-items-center ps-1 hover-invert my-sm-0 my-1 py-sm-1 ps-sm-1 pe-sm-2 p-2 border-b5  border me-2 hover-primary2 w-100 justify-content-sm-start justify-content-center"
                >
                  <CallIcon sx={{ fontSize: 18 }} className="mx-2" />
                  Call
                </button>
              </a>
              <a href={`mailto:${mail}`} className="me-sm-2">
                <button
                  style={{ whiteSpace: "nowrap", ...fsFourteen }}
                  className="btn btn-outline-secondary d-flex flex-row align-items-center ps-1 hover-invert my-sm-0 my-1 py-sm-1 ps-sm-1 pe-sm-2 p-2 border-b5  border hover-primary2 w-100 justify-content-sm-start justify-content-center"
                >
                  <MailIcon sx={{ fontSize: 18 }} className="mx-2" />
                  Email
                </button>
              </a>
            </div>
          </div>
          <div className="d-flex">
            <BootstrapTooltip
              placement="top"
              title="VIC designation (Very Important Client) will allow you to extend special benefits to those customers that have special value to you. More tools around this feature and designation are developing"
            >
              <img
                id="serviceOne"
                className="me-3 pointer mt-2 d-sm-block d-none"
                height={18}
                src="/images/schedule/infosvg.svg"
                alt=""
              />
            </BootstrapTooltip>
            <div className="d-flex align-items-start d-sm-block d-none">
              <div
                className="d-flex pb-1 gap-2 align-items-center"
                role="group"
                aria-label="Basic outlined example"
              >
                <FilterButton
                  tooltip="VIC"
                  onClick={() => makeMemberVic()}
                  style={{
                    width: "42px",
                    height: "40px",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  {vicStatus == "loading" ? (
                    <>
                      <span
                        className="spinner-border spinner-border-sm text-primary"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      <span className="visually-hidden">Loading...</span>
                    </>
                  ) : profile?.isVic ? (
                    <StarRoundedIcon sx={{ fontSize: 20, color: "#04bac7" }} />
                  ) : (
                    <StarOutlineRoundedIcon
                      sx={{ fontSize: 20, color: "#505050" }}
                    />
                  )}
                </FilterButton>
                <FilterButton
                  tooltip="Delete Connection"
                  onClick={() => handleOpen()}
                  style={{
                    width: "42px",
                    height: "40px",
                  }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <DeleteIcon
                    className=""
                    sx={{ fontSize: 20, color: "#505050" }}
                  />
                </FilterButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <ConfModal handleClose={handleClose} open={open}>
        <DeletePrompt
          handleClose={handleClose}
          deleteFuntion={disconnectWithConnection({
            data: connectionDelete,
            navigate,
          })}
          textToShow={"Deleting Member"}
        />
      </ConfModal>
    </div>
  );
};

export default ClientDetailChild;

import React, { useState } from "react";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../../reducers/authReducer";
import { AppDispatch } from "../../../store";

type registrationDataType = {
  mobileNo?: string;
  password?: string;
};

const ForgetPass = ({ link }) => {
  const [registrationData, setRegistrationData] =
    useState<registrationDataType>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [mobileError, setMobileError] = React.useState(true);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (mobileError) {
      dispatch(forgotPassword({ data: registrationData, navigate }));
    }
    // navigate("/auth/verifyResetPass");
  };

  return (
    <div className="container-fluid px-0 h-100-hidden">
      <div className="row w-100 m-0 p-0">
        <div className="col-lg-6 p-0 bg-white">
          <div style={{ height: "auto" }}>
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                className="me-2"
                height={35}
                width={35}
                src="/images/KZH-PRO.jpeg"
                alt=""
              />
              <img src="/images/KZH-Written.svg" alt="" />
            </div>
            <div className="m-5 py-md-0 px-md-5 p-0 rounded">
              <h4
                style={{ fontFamily: "Poppins, sans-serif" }}
                className="fw-bolder text-center"
              >
                Forgot Password?
              </h4>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center fw-bold mb-4"
              >
                To reset your password, please provide your email.
              </p>
              <div className="form">
                <form onSubmit={handleSubmit} className="php-email-form">
                  <div className="mt-3 form-group">
                    <label htmlFor="mobileNo" className="form-label">
                      Phone number
                    </label>
                    <PhoneInputWithCountrySelect
                      defaultCountry="US"
                      name="mobileNo"
                      id="mobileNo"
                      onBlur={() => {
                        if (registrationData.mobileNo) {
                          setMobileError(
                            isValidPhoneNumber(
                              registrationData.mobileNo.toString()
                            )
                          );
                        }
                      }}
                      className={`Cus-form-control py-2 ${
                        mobileError ? "" : "border-danger"
                      }`}
                      international
                      placeholder="Enter phone number"
                      value={registrationData?.mobileNo}
                      onChange={(e) =>
                        setRegistrationData({
                          ...registrationData,
                          mobileNo: e,
                        })
                      }
                    />
                    {!mobileError && (
                      <p className="text-danger">Invalid phone number</p>
                    )}
                  </div>
                  <button
                    style={{ backgroundColor: "#04BAC7" }}
                    className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                    type="submit"
                  >
                    Send Code
                  </button>
                </form>
              </div>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center fw-bold mt-3"
              >
                Already have an account?{" "}
                <Link to={link} style={{ color: "#04BAC7" }}>
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0 d-lg-block d-none">
          <div
            style={{ height: "110vh", backgroundColor: "#eff3fc" }}
            className="align-items-center d-flex justify-content-center bg-register"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ForgetPass;

import React from "react";
import ReactDOM from "react-dom/client";
import "./assets/styles/bootstrap.css";
import "./assets/styles/index.css";
import "../node_modules/bootstrap/dist/js/bootstrap.js";
import reportWebVitals from "./reportWebVitals";
//primeReact
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import { Provider } from "react-redux";
import store from "./store";
import { AppRoutes } from "./routes/AppRoutes";
import { client } from "./api/client";
import { getAuth } from "./utils/AuthHelpers";
import { BrowserRouter } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { makeResponseError } from "./utils/errorHandler";

client.interceptors.request.use(
  (config: any) => {
    const auth = getAuth();
    if (auth && auth.access_token) {
      config.headers.Authorization = `Bearer ${auth.access_token}`;
    }

    return config;
  },
  (err: any) => Promise.reject("Interceptor : " + err)
);

client.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err.config.url ===
      "https://dev-redesign.kazzah.co.uk/api/provider/v1/boarding"
    ) {
      if (err.response.data.data.error.includes("Record not found")) {
        return;
      } else {
        makeResponseError(err.response.data);
      }
    } else {
      makeResponseError(err.response.data);
    }

    // throw new Error(err.response.data);
  }
);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <>
    <Provider store={store}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Provider>
    <Toaster
      toastOptions={{
        className: "",
        style: {
          minWidth: "300px",
          minHeight: "50px",
          fontSize: "18px",
        },
      }}
    />
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

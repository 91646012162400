import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { ChatState } from "../../components/common/types";
import { FAIL, IDLE, LOADING, SUCCESS } from "../../config/constant";
// import { fetchLastMessagesFromFirebase } from "../../firebase";
// import { getAllServices } from "../../../services/Service";
import { RootState } from "../../store";
import {
  formatFirebaseTimestamp,
  getSeconds,
  getTimeAgo,
  timeFormat,
} from "../../utils";
import { xconsole } from "../../utils/console";
import { makeResponseError } from "../../utils/errorHandler";
import {
  getSearchUsersRequest,
  getUsersMessagesRequest,
  getUsersRequest,
  sendChatMessage,
  updateGroupStatusRequest,
} from "../../helper/provider/chat";
import echo from "../../hooks/useWebSockets";

export const initialState: ChatState = {
  room: {
    list: Array(0),
    filteredList: Array(0),
    status: IDLE,
    active: { id: null, recipient: {}, messages: Array(0) },
  },

  status: IDLE,
  CreateStatus: IDLE,
  UpdateStatus: IDLE,
  error: null,
};

export const getUsersAction = createAsyncThunk(
  "get/chat/users",
  async (localData: any, thunkApi) => {
    try {
      const res: any = await getUsersRequest();
      const { data, success, message } = res.data;
      if (success) {
        // add a key value pair to each object

        let users = data.map((user: any) => {
          return { ...user, newUser: false };
        });

        if (localData.data) {
          const roomDataNew = localData.data.activeRoomData;
          const listData = localData.data.finalData;

          thunkApi.dispatch(checkRoom({ listData, roomDataNew }));
          const index = users.findIndex((f) => {
            return f?.id == listData?.userId;
          });

          if (index !== -1) {
            thunkApi.dispatch(
              getUsersMessagesAction({
                data: listData?.group,
                authUser: localData?.authUser,
              })
            );
          } else {
            users = [
              ...users,
              {
                ...listData,
                newUser: true,
              },
            ];
            echo
              .private(listData?.group)
              .listen(".private-chat-message", (data: any) => {
                // console.log("private-chat-message data : ", data);
              });
            echo
              .private(`notification-${localData?.authUser?.id}`)
              .listen(".notification-chat", (data: any) => {
                // console.log("notification data : ", data);
              });
          }
        }

        return {
          data: users,
          success,
          code: 200,
        };
      }
    } catch (error) {
      console.log("error", error);
      return makeResponseError(error);
    }
  }
);

export const getUsersMessagesAction = createAsyncThunk(
  "get/chat/users/messages",
  async (group: any) => {
    try {
      const res: any = await getUsersMessagesRequest(group.data);
      const { data, success, message } = res.data;
      return {
        data: data,
        formData: group,
        success,
        code: 200,
      };
    } catch (error) {
      console.log("error", error);
      return makeResponseError(error);
    }
  }
);

export const sendChatMessageAction = createAsyncThunk(
  "send/chat/message",
  async (formData: any) => {
    try {
      formData.setSendMsgStatus("Loading");
      // console.log("formData", formData.msg);

      const res: any = await sendChatMessage(formData.msg);
      const { data, success, message } = res.data;
      if (success) {
        formData.setMessage({
          message: "",
          type: "",
        });
        formData.setSendMsgStatus("Sent");
      }
      return {
        data: data,
        success,
        code: 200,
      };
    } catch (error) {
      console.log("error", error);
      return makeResponseError(error);
    }
  }
);

export const updateGroupStatusAction = createAsyncThunk(
  "update/groupStatus",
  async (status: { group: string; isRead: number }) => {
    try {
      const res: any = await updateGroupStatusRequest(status);
      const { data, success } = res.data;
      return {
        data: status,
        success,
        code: 200,
      };
    } catch (error) {
      console.log("error", error);

      return error;
    }
  }
);

export const getSearchUsersAction = createAsyncThunk(
  "get/search/users",
  async (query: { data: string; setLoading: any }) => {
    try {
      const res: any = await getSearchUsersRequest(query.data);
      const { data, success } = res.data;
      if (success) {
        query.setLoading(false);
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      console.log("error", error);
      return error;
    }
  }
);

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    temReducer: (state, { payload }: PayloadAction<any>) => {
      xconsole.success(
        "test No " + Math.floor(Math.random() * 999) + " passed !"
      );
    },

    updateRoom: (state, { payload }: PayloadAction<any>) => {
      // console.log("payload : ", payload);

      const updatedList = payload?.map((conversation) => {
        const { lastMessage } = conversation;
        return {
          ...conversation,
          lastMessage: {
            ...lastMessage,
            originalTime: lastMessage.createdAt,

            createdAt: formatFirebaseTimestamp(lastMessage.createdAt),
            // createdAt: getTimeAgo(lastMessage.createdAt),
          },
          conversationId: conversation.id,
        };
      });
      // console.log(updatedList);

      state.room.list = updatedList;
    },

    resetRoom: (state, { payload }: PayloadAction<any>) => {
      if (payload === null) {
        state.room = initialState.room;
      }
    },

    activateRoom: (state, { payload }: PayloadAction<any>) => {
      // console.log(payload);
      state.room.active = payload;
    },

    disActivateRoom: (state) => {
      state.room.active = initialState.room.active;
    },
    updateActiveMessageList: (state, { payload }: PayloadAction<any>) => {
      state.room.active.messages = payload;
    },

    updateActiveMessage: (state, { payload }: PayloadAction<any>) => {
      // console.log("payload : ", payload);

      const messageData = {
        id: payload.message.id,
        senderUid:
          payload.authUser?.id !== payload.message.from_user_id ? true : false,
        recipientUid:
          payload.authUser?.id === payload.message.from_user_id ? true : false,
        conversationId: payload.message.group,
        messageData: payload.message.message,
        messageType: payload.message.type,
        createdAt: {
          date: payload.message.created_at,
          time: moment(payload.message.created_at).format("hh:mm A"),
        },
      };
      const listData = {
        id: payload.message.id,
        messageType: payload.message.type,
        messageData: payload.message.message,
        createdAt: moment(payload.message.created_at).format("hh:mm A"),
        originalTime: payload.message.created_at,
      };
      const index = state.room.list.findIndex(
        (item) => item.id === payload.message.group
      );
      if (state.room.active.id === payload.message.group) {
        state.room.active.messages.push(messageData);
      }
      if (index !== -1) {
        state.room.list[index].lastMessage = listData;
        state.room.list[index].isRead = 0;
        state.room.filteredList[index].lastMessage = listData;
        state.room.filteredList[index].isRead = 0;
        // sort by time
        state.room.list.sort((a, b) => {
          return (
            moment(b.lastMessage.originalTime).unix() -
            moment(a.lastMessage.originalTime).unix()
          );
        });
        state.room.filteredList.sort((a, b) => {
          return (
            moment(b.lastMessage.originalTime).unix() -
            moment(a.lastMessage.originalTime).unix()
          );
        });
      }
    },

    updateList: (state, { payload }: PayloadAction<any>) => {
      state.room.list.push(payload);
      state.room.filteredList.push(payload);
    },

    resetList: (state) => {
      state.room.filteredList = state.room.list;
    },

    updateMessageOnSend: (state, { payload }: PayloadAction<any>) => {
      const index = state.room.list.findIndex(
        (item) => item.id === payload.conversationId
      );
      if (index !== -1) {
        const lastMessageData = {
          id: "",
          messageType: payload.messageType,
          messageData: payload.messageData,
          createdAt: payload.createdAt.time,
          originalTime: payload.createdAt.date,
        };
        state.room.list[index].lastMessage = lastMessageData;
        state.room.filteredList[index].lastMessage = lastMessageData;
      }
      state.room.active.messages.push(payload);
    },

    updateLastMessage: (state, { payload }: PayloadAction<any>) => {
      const listData = {
        id: payload.message.id,
        messageType: payload.message.type,
        messageData: payload.message.message,
        createdAt: moment(payload.message.created_at).format("hh:mm A"),
        originalTime: payload.message.created_at,
      };

      const index = state.room.list.findIndex(
        (item) => item.id === payload.message.group
      );

      if (index !== -1) {
        state.room.list[index].lastMessage = listData;
        state.room.filteredList[index].lastMessage = listData;
        // sort by time
        state.room.list.sort((a, b) => {
          return (
            moment(b.lastMessage.originalTime).unix() -
            moment(a.lastMessage.originalTime).unix()
          );
        });
        state.room.filteredList.sort((a, b) => {
          return (
            moment(b.lastMessage.originalTime).unix() -
            moment(a.lastMessage.originalTime).unix()
          );
        });
      }
    },

    checkRoom: (state, { payload }: PayloadAction<any>) => {
      const index = state.room.list.findIndex(
        (item) => item.participant.id === payload.userId
      );
      if (index === -1) {
        const newListData = {
          ...payload.listData,
          newUser: true,
        };
        state.room.active = payload.roomDataNew;
        if (newListData.group) {
          return;
        } else {
          state.room.list.push(newListData);
          state.room.filteredList.push(newListData);
        }
      } else {
        state.room.active = {
          id: state.room.list[index].id,
          recipient: state.room.list[index].participant,
          messages: [],
        };
      }
    },
  },
  extraReducers: (builder) => {
    // get users
    builder.addCase(getUsersAction.pending, (state) => {
      state.room.status = LOADING;
      state.error = null;
    });
    builder.addCase(getUsersAction.fulfilled, (state, { payload }) => {
      const { data, success, code }: any = payload;
      if (success && code === 200) {
        if (data.length !== 0) {
          data.map((user) => {
            const index = state.room.list.findIndex(
              (item) => item.id === user.group
            );
            if (index === -1) {
              const data = {
                id: user.group,
                lastMessage: {
                  id: "",
                  messageType: user.type,
                  messageData: user.message,
                  createdAt: timeFormat(user.messageCreatedAt),
                  originalTime: user.messageCreatedAt,
                },
                participant: {
                  id: user.id ? user.id : user.userId,
                  name: user.firstName + " " + user.lastName,
                  online: false,
                  avatarUrl: user.profilePicture,
                  userId: user.id,
                  email: user.email,
                  isProvider: user.model === "providers" ? true : false,
                  mobileNo: user.mobileNo,
                  modifiedAt: {
                    date: user.messageCreatedAt,
                    time: timeFormat(user.messageCreatedAt),
                  },
                  createdAt: {
                    seconds: getSeconds(user.messageCreatedAt).seconds,
                    nanoseconds: getSeconds(user.messageCreatedAt).nanoseconds,
                  },
                },
                newUser: user.newUser,
                isRead: user.isRead,
              };
              state.room.list.push(data);
            } else {
              const data = {
                id: user.group,
                lastMessage: {
                  id: "",
                  messageType: user.type,
                  messageData: user.message,
                  createdAt: timeFormat(user.messageCreatedAt),
                  originalTime: user.messageCreatedAt,
                },
                participant: {
                  id: user.id ? user.id : user.userId,
                  name: user.firstName + " " + user.lastName,
                  online: false,
                  avatarUrl: user.profilePicture,
                  userId: user.id,
                  email: user.email,
                  isProvider: user.model === "providers" ? true : false,
                  mobileNo: user.mobileNo,
                  modifiedAt: {
                    date: user.messageCreatedAt,
                    time: timeFormat(user.messageCreatedAt),
                  },
                  createdAt: {
                    seconds: getSeconds(user.messageCreatedAt).seconds,
                    nanoseconds: getSeconds(user.messageCreatedAt).nanoseconds,
                  },
                },
                newUser: user.newUser,
                isRead: user.isRead,
              };
              state.room.list[index] = data;
            }
          });
          state.room.filteredList = state.room.list;
        }
        state.room.status = SUCCESS;
      } else {
        state.status = FAIL;
      }
    });
    builder.addCase(getUsersAction.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.room.status = FAIL;
    });

    // get users messages
    builder.addCase(getUsersMessagesAction.pending, (state) => {
      state.status = LOADING;
      state.error = null;
    });
    builder.addCase(getUsersMessagesAction.fulfilled, (state, { payload }) => {
      const { data, success, code, formData }: any = payload;

      const index = state.room.list.findIndex(
        (user) => user.id === formData.data
      );
      const user = state.room.list[index];

      if (success && code === 200) {
        // if (data.length !== 0) {
        state.room.active.id = formData.data;
        state.room.active.recipient = {
          id: formData.data,
          avatarUrl: user.participant.avatarUrl,
          userId: user.participant.id,
          online: false,
          name: user.participant.name,
          email: user.participant.email,
          isProvider: user.participant.isProvider,
          mobileNo: user.participant.mobileNo,
          modifiedAt: user.participant.modifiedAt,
          createdAt: user.participant.createdAt,
        };
        if (data.length !== 0) {
          state.room.active.messages = data.map((message) => {
            return {
              id: message.id,
              createdAt: {
                date: message.createdAt,
                time: moment(message.createdAt).format("hh:mm A"),
              },
              senderUid:
                formData.authUser.id !== message.fromUser.id ? true : false,
              recipientUid:
                formData.authUser.id === message.fromUser.id ? true : false,
              conversationId: message.group,
              messageData: message.message,
              messageType: message.type,
            };
          });
        } else {
          state.room.active.messages = [];
        }
        // }
        state.status = SUCCESS;
      } else {
        state.status = FAIL;
      }
    });
    builder.addCase(getUsersMessagesAction.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = FAIL;
    });

    // send message
    builder.addCase(sendChatMessageAction.pending, (state) => {
      state.CreateStatus = LOADING;
      state.error = null;
    });
    builder.addCase(sendChatMessageAction.fulfilled, (state, { payload }) => {
      const { success, code }: any = payload;
      if (success && code === 200) {
        state.room.list = state.room.list.sort((a, b) => {
          return (
            moment(b.lastMessage.originalTime).unix() -
            moment(a.lastMessage.originalTime).unix()
          );
        });
        state.room.filteredList = state.room.filteredList.sort((a, b) => {
          return (
            moment(b.lastMessage.originalTime).unix() -
            moment(a.lastMessage.originalTime).unix()
          );
        });
        state.CreateStatus = SUCCESS;
      } else {
        state.CreateStatus = FAIL;
      }
    });
    builder.addCase(sendChatMessageAction.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.CreateStatus = FAIL;
    });

    builder.addCase(updateGroupStatusAction.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(updateGroupStatusAction.fulfilled, (state, action) => {
      state.status = SUCCESS;
      const { data } = action.payload;
      const index = state.room.list.findIndex((d) => d.id === data.group);

      if (index !== -1) {
        state.room.list[index] = {
          ...state.room.list[index],
          isRead: 1,
        };
        state.room.filteredList = state.room.list;
      }
    });
    builder.addCase(updateGroupStatusAction.rejected, (state, action) => {
      state.status = FAIL;
    });

    builder.addCase(getSearchUsersAction.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(getSearchUsersAction.fulfilled, (state, action) => {
      state.status = SUCCESS;
      const { data } = action.payload;
      state.room.filteredList = data.map((list) => {
        return {
          id: list.group,
          participant: {
            id: list.id,
            name: list.firstName + list.lastName,
            avatarUrl: list.profilePicture,
            online: false,
            userId: list.id,
            isProvider: list.model === "clients" ? false : true,
            modifiedAt: {
              date: list.messageCreatedAt,
              time: timeFormat(list.messageCreatedAt),
            },
            createdAt: {
              seconds: getSeconds(list.messageCreatedAt).seconds,
              nanoseconds: getSeconds(list.messageCreatedAt).nanoseconds,
            },
          },
          lastMessage: {
            id: "",
            messageType: list.messageType,
            messageData: list.message,
            createdAt: timeFormat(list.messageCreatedAt),
            originalTime: list.messageCreatedAt,
          },
        };
      });
    });
    builder.addCase(getSearchUsersAction.rejected, (state, action) => {
      state.status = FAIL;
    });
  },
});

export const {
  temReducer,
  updateRoom,
  activateRoom,
  disActivateRoom,
  updateActiveMessageList,
  resetRoom,
  updateActiveMessage,
  updateList,
  resetList,
  updateMessageOnSend,
  updateLastMessage,
  checkRoom,
} = chatSlice.actions;

export default chatSlice.reducer;

export const roomSelector = (state: RootState) => state.chat.room;
export const roomStatusSelector = (state: RootState) => state.chat.room.status;
export const messageSendStatusSelector = (state: RootState) =>
  state.chat.CreateStatus;

export const messagesListSelector = (state: RootState) =>
  state.chat.room.active.messages;

export const statusSelector = (state: RootState) => state.chat.status;

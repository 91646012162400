import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FAIL, IDLE, LOADING, SUCCESS } from "../../config/constant";
import {
  addOwnSocialMediaRequest,
  appointmentMediaPrivacyRequest,
  deleteMediaRequest,
  fetchAllMediaRequest,
  fetchAllSocialLinksRequest,
  fetchOwnMediaRequest,
  fetchOwnSocialMediaRequest,
  fetchPaginatedMediaRequest,
  mediaLikeRequest,
  providerMedia,
  uploadOwnMediaRequest,
} from "../../helper/provider/media";
import { RootState } from "../../store";
import { xconsole } from "../../utils/console";
import { makeResponseError } from "../../utils/errorHandler";
import { makeExploreData } from "../../utils/factory";
import { SHOW_SUCCESS } from "../../utils/ToastMessage";

type mediaType = {
  id: number;
  provider: {};
  url: string;
  thumbnail: string;
  type: string;
  caption: string;
  service: {};
  dateTime: string;
  isFavourite: number | null;
  location: string;
  tags: Array<{ id: number; service: {} }>;
  isPrivate: number;
  isFav: number;
  isLoading: boolean;
};

export interface mediaState {
  CreateStatus: string;
  defaultSocialLinks: Array<any>;
  error: string | null;
  explore: Array<any>;
  media?: Array<any>;
  pagination: any;
  filter: {
    exploreId: number;
    serviceName: string;
    isFav: boolean;
    isStar: boolean;
    isMine: boolean;
  };
  socialLinks: Array<any>;
  status: string;
  privacyStatus: string;
  UpdateStatus: string;
}

export const initialState: mediaState = {
  CreateStatus: "idle",
  defaultSocialLinks: [],
  error: null,
  explore: [],
  pagination: {
    currentPage: 1,
    lastPage: 1,
    perPage: 10,
    total: 0,
    previousPageUrl: null,
    nextPageUrl: null,
    firstPageUrl: null,
    lastPageUrl: null,
  },
  filter: {
    exploreId: 0,
    serviceName: "",
    isFav: false,
    isStar: false,
    isMine: true,
  },
  media: [],
  socialLinks: [],
  status: "idle",
  privacyStatus: "idle",
  UpdateStatus: "idle",
};

export const fetchAllSocialLinks = createAsyncThunk(
  "fetch/congif/media",
  async () => {
    try {
      const res: any = await fetchAllSocialLinksRequest();
      const { data, success, message } = res.data;
      // console.log(data);

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
    }
  }
);

export const fetchOwnMedia = createAsyncThunk("fetch/media", async () => {
  try {
    const res: any = await fetchOwnMediaRequest();
    const { data, success, message } = res.data;
    // console.log(data);

    return {
      data,
      success,
      code: 200,
    };
  } catch (error) {
    xconsole.fail(error.message);
  }
});

export const getProviderMedia = createAsyncThunk(
  "get/provider/media",
  async (id: any) => {
    try {
      const res: any = await providerMedia(id);
      const { data, success, message } = res.data;
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {}
  }
);

export const fetchAllMedia = createAsyncThunk(
  "fetch/media/explore",
  async () => {
    try {
      const res: any = await fetchAllMediaRequest();
      const { data, success, message } = res.data;
      // console.log(data);

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
    }
  }
);

export const fetchPaginatedMedia = createAsyncThunk(
  "fetch/paginated-media/explore",
  async (formData: any) => {
    try {
      const res: any = await fetchPaginatedMediaRequest(formData);
      const { data, success, message } = res.data;

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
    }
  }
);

export const fetchOwnSocialMedia = createAsyncThunk(
  "fetch/media/social",
  async () => {
    try {
      const res: any = await fetchOwnSocialMediaRequest();
      const SocialRes: any = await fetchAllSocialLinksRequest();
      const { data, success, message } = res.data;
      // console.log(data);

      return {
        data,
        socialLinks: SocialRes?.data?.data?.value?.socials,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
    }
  }
);

export const uploadOwnSocialMedia = createAsyncThunk(
  "post/media/social",
  async (formData: any) => {
    try {
      const res: any = await addOwnSocialMediaRequest(formData);
      const { data, success, message } = res.data;
      // console.log(data);

      return {
        data,
        success,
        code: 201,
      };
    } catch (error) {
      // xconsole.fail(error.message);
      return makeResponseError(error.response.data);
    }
  }
);

// export const UploadOwnMedia = createAsyncThunk(
//   "post/media",
//   async (formData: any) => {
//     try {
//       const res: any = await uploadOwnMediaRequest(formData.data);
//       const { data, success, message } = res.data;
//       // if (success) {
//       //   formData.handleClose();
//       // }
//       // console.log(data);
//       if (success) {
//         SHOW_SUCCESS(true, "Media added successfully");
//       }

//       return {
//         data,
//         success,
//         code: 201,
//       };
//     } catch (error) {
//       // xconsole.fail(error.message);
//       return makeResponseError(error.response.data);
//     }
//   }
// );

export const mediaLikeAction = createAsyncThunk(
  "post/media/like",
  async (id: any) => {
    try {
      const res: any = await mediaLikeRequest(id);
      const { data, success, message } = res.data;
      // console.log(data);

      return {
        data,
        formData: id,
        success,
        code: 201,
      };
    } catch (error) {
      // xconsole.fail(error.message);
      return makeResponseError(error.response.data);
    }
  }
);

export const deleteMediaAction = createAsyncThunk(
  "delete/media",
  async (id: any) => {
    try {
      const res: any = await deleteMediaRequest(id);
      const { data, success, message } = res.data;
      // console.log(data);

      return {
        data,
        formData: id,
        success,
        code: 201,
      };
    } catch (error) {
      // xconsole.fail(error.message);
      return makeResponseError(error.response.data);
    }
  }
);

export const appointmentMediaPrivacyAction = createAsyncThunk(
  "put/appointmentMediaPrivacy",
  async (formData: any) => {
    try {
      const res: any = await appointmentMediaPrivacyRequest(formData);
      const { data, success } = res.data;
      return {
        formData,
        data,
        success,
        code: 200,
      };
    } catch (error) {
      console.log(error);
      return error;
    }
  }
);

const mediaSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    updateSocialLinkArray: (state, { payload }: PayloadAction<any>) => {
      const { id, userName } = payload;
      state.defaultSocialLinks[id].userName = payload.userName;
      // xconsole.test(`userName ${payload.userName}`);
      // xconsole.test(`id ${payload.id}`);
    },

    resetFilters: (state, { payload }: { payload?: any }) => {
      state.filter = initialState.filter;
    },

    aggregateFilter: (state, { payload }: PayloadAction<any>) => {
      const { key, value } = payload;
      state.filter[key] = value;
    },

    filterByService: (state, { payload }: PayloadAction<any>) => {
      const { serviceId } = payload;
      state.filter.exploreId = serviceId;
    },
    searchByServiceName: (state, { payload }: PayloadAction<any>) => {
      const { serviceName } = payload;
      state.filter.serviceName = serviceName.toUpperCase();
    },
    getMediaData: (state, { payload }) => {
      const { data } = payload;
      const exploreData = makeExploreData(data.medias);
      const paginationKeys = [
        "currentPage",
        "perPage",
        "total",
        "lastPage",
        "firstPageUrl",
        "lastPageUrl",
        "nextPageUrl",
        "previousPageUrl",
      ];
      const pagination = Object.keys(data)
        .filter((key) => paginationKeys.includes(key))
        .reduce((obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {});
      state.pagination = pagination;
      exploreData.forEach((media: any) => {
        state.explore.push(media);
      });
    },

    UploadOwnMedia: (state, { payload }) => {
      const { data, success }: any = payload;
      if (success) {
        const mediaData = {
          caption: data.caption,
          client: data.client,
          dateTime: data.dateTime,
          id: data.id,
          isFavourite: data.isFavourite,
          isPrivate: data.isPrivate,
          latitude: data.latitude,
          longitude: data.longitude,
          provider: data.provider,
          service: data.service,
          tags: data.tags,
          thumbnail: data.thumbnail,
          type: data.type,
          mediaLike: data?.mediaLike,
          mediaLikeCounts: data?.mediaLikeCounts,
          isFav: data?.isFav,
          url: data.url,
        };
        const organizedData = makeExploreData([mediaData]);
        state.explore.unshift(organizedData?.[0]);
        state.media.unshift(organizedData?.[0]);
      }
    },

    UpdateStatus: (state, { payload }) => {
      state.CreateStatus = payload;
    },
  },
  extraReducers: (builder) => {
    // explore

    builder.addCase(fetchAllMedia.pending, (state) => {
      state.status = LOADING;
      state.error = null;
    });
    builder.addCase(fetchAllMedia.fulfilled, (state, { payload }) => {
      const { data, success }: any = payload;
      if (success) {
        state.explore = makeExploreData(data);
        state.status = SUCCESS;
      }
    });
    builder.addCase(fetchAllMedia.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = IDLE;
    });

    builder.addCase(fetchPaginatedMedia.pending, (state) => {
      state.status = LOADING;
      state.error = null;
    });
    builder.addCase(fetchPaginatedMedia.fulfilled, (state, { payload }) => {
      const { data, success }: any = payload;
      if (success) {
        const paginationKeys = [
          "currentPage",
          "perPage",
          "total",
          "lastPage",
          "firstPageUrl",
          "lastPageUrl",
          "nextPageUrl",
          "previousPageUrl",
        ];
        // get the pagination object from the response data
        const pagination = Object.keys(data)
          .filter((key) => paginationKeys.includes(key))
          .reduce((obj, key) => {
            obj[key] = data[key];
            return obj;
          }, {});
        // set the pagination object in the state
        state.pagination = pagination;
        state.explore = makeExploreData(data.medias);
        state.status = SUCCESS;
      }
    });
    builder.addCase(fetchPaginatedMedia.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = IDLE;
    });

    // logged in used's media
    builder.addCase(fetchOwnMedia.pending, (state) => {
      state.status = LOADING;
      state.error = null;
    });
    builder.addCase(fetchOwnMedia.fulfilled, (state, { payload }) => {
      const { data, success }: any = payload;
      if (success) {
        state.media = data;
        state.status = SUCCESS;
      }
    });
    builder.addCase(fetchOwnMedia.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = IDLE;
    });

    // upload media
    // builder.addCase(UploadOwnMedia.pending, (state) => {
    //   state.UpdateStatus = LOADING;
    //   state.media.push({
    //     id: 0,
    //     provider: {},
    //     url: "",
    //     thumbnail: "",
    //     type: "",
    //     caption: "",
    //     service: {},
    //     dateTime: "",
    //     isFavourite: null,
    //     location: "",
    //     tags: [{ id: 0, service: {} }],
    //     isPrivate: 0,
    //     isFav: 0,
    //     isLoading: true,
    //   });

    //   state.error = null;
    // });
    // builder.addCase();
    // builder.addCase(UploadOwnMedia.rejected, (state, { payload }) => {
    //   state.error = payload as string;
    //   state.UpdateStatus = FAIL;
    // });

    // social media
    builder.addCase(fetchOwnSocialMedia.pending, (state) => {
      state.status = LOADING;
      state.error = null;
    });
    builder.addCase(fetchOwnSocialMedia.fulfilled, (state, { payload }) => {
      const { data, success, socialLinks }: any = payload;
      console.log("socialLinks ", socialLinks);
      console.log("data", data);

      if (success) {
        const SOCIAL_LINKS_ICONS = [
          "/images/Media/facebook.svg",
          "/images/Media/twitter.svg",
          "/images/Media/instagram.svg",
          "/images/Media/linkedin.svg",
          "/images/Media/tiktok.svg",
          "/images/Media/messenger.svg",
          "/images/Media/telegram.svg",
          "/images/Media/whatsapp.svg",
          "/images/Media/pinterest.svg",
          "/images/Media/snapchat.svg",
        ];
        state.defaultSocialLinks = socialLinks.map(
          (SLD: { userName: any; name: string }) => {
            let tempLink = SOCIAL_LINKS_ICONS.find((lk) =>
              lk.includes(SLD.name)
            );
            let tempUserName = data?.find(
              (lk: { name: string }) => lk.name === SLD.name
            ).userName;

            return {
              ...SLD,
              icon: tempLink ? tempLink : "",
              userName: tempUserName ? tempUserName : "",
            };
          }
        );
        // state.socialLinks = SOCIAL_LINKS;
        state.status = SUCCESS;
      }
    });
    builder.addCase(fetchOwnSocialMedia.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = IDLE;
    });

    builder.addCase(getProviderMedia.pending, (state, action) => {
      state.status = "loading";
      state.media = [];
    });
    builder.addCase(getProviderMedia.fulfilled, (state, action) => {
      state.status = "succeeded";
      // console.log("media data",action.payload);
      state.media = action.payload.data;
    });
    builder.addCase(getProviderMedia.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(mediaLikeAction.pending, (state, action) => {
      // state.status = "loading";
    });
    builder.addCase(mediaLikeAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data, success, formData }: any = action.payload;

      if (success) {
        const { id, isFav, mediaLikeCounts } = data;
        const mediaIndex = state.explore.findIndex(
          (u) => u.id === formData.mediaId
        );
        // console.log(mediaIndex);
        if (mediaIndex !== -1) {
          state.explore[mediaIndex].isFav = isFav;
          state.explore[mediaIndex].mediaLikeCounts = mediaLikeCounts;
        }
      }
    });
    builder.addCase(mediaLikeAction.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(deleteMediaAction.pending, (state, action) => {
      // state.status = "loading";
    });
    builder.addCase(deleteMediaAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data, success, formData }: any = action.payload;
      const mediaIndex = state.explore.findIndex((u) => u.id === formData);
      // console.log(mediaIndex);
      if (mediaIndex !== -1) {
        state.explore.splice(mediaIndex, 1);
      }
    });
    builder.addCase(deleteMediaAction.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(appointmentMediaPrivacyAction.pending, (state, action) => {
      state.privacyStatus = LOADING;
    });
    builder.addCase(
      appointmentMediaPrivacyAction.fulfilled,
      (state, action) => {
        state.privacyStatus = SUCCESS;
        const { formData } = action.payload;
        const index = state.explore.findIndex(
          (media) => formData.mediaId === media.id
        );
        if (index !== -1) {
          state.explore[index].isPrivate = formData.isPrivate;
        }
      }
    );
    builder.addCase(appointmentMediaPrivacyAction.rejected, (state, action) => {
      state.privacyStatus = FAIL;
    });

    // configuration
  },
});

export const {
  updateSocialLinkArray,
  filterByService,
  searchByServiceName,
  resetFilters,
  aggregateFilter,
  getMediaData,
  UploadOwnMedia,
  UpdateStatus,
} = mediaSlice.actions;

export default mediaSlice.reducer;

export const mediaSelector = (state: RootState) => state.media.media;
export const uploadMediaStatusSelector = (state: RootState) =>
  state.media.UpdateStatus;
export const socialLinkSelector = (state: RootState) =>
  state.media.defaultSocialLinks;
export const MultiServiceLevelTwoSelector = (state: RootState) =>
  state.service.levelTwo.map((u: { id: any; name: any }) => {
    return {
      value: `${u.id}`,
      label: `${u.name}`,
    };
  });

// explore selectors
export const exploreMediaSelector = (state: RootState) => state.media.explore;

export const exploreFilterSelector = (state: RootState) =>
  state.media.filter.exploreId;
export const statusSelector = (state: RootState) => state.media.status;

export const serviceNameFilterSelector = (state: RootState) =>
  state.media.filter.serviceName;

export const filterSelector = (state: RootState) => state.media.filter;
export const paginationSelector = (state: RootState) => state.media.pagination;

export const createStatusSelector = (state: RootState) =>
  state.media.CreateStatus;

export const privacyStatusSelector = (state: RootState) =>
  state.media.privacyStatus;

import toast, { Toaster } from "react-hot-toast";

const notify = () => toast("Here is your toast.");

const customId = "custom-id-yes";

const SHOW_ERROR = (error = true, msg = "Some thing went wrong") => {
  if (error) {
    toast.error(msg, {
      position: "top-right",
      duration: 4000,
      style: {
        backgroundColor: "#f05252",
        color: "#fff",
        fontSize: "18px",
      },
    });
  }
};

const SHOW_SUCCESS = (success = true, msg = "Successful !") => {
  if (success) {
    toast.success(msg, {
      position: "top-right",
      duration: 4000,
      style: {
        backgroundColor: "#04bac7",
        color: "#fff",
        fontSize: "18px",
      },
    });
  }
};

const SHOW_INFO = (success = true, msg = "information !") => {
  if (success) {
    toast.success(msg, {
      position: "top-right",
      duration: 4000,
    });
  }
};

export { SHOW_ERROR, SHOW_SUCCESS, SHOW_INFO };

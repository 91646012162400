import { getNotificationTriggers } from "../../helper/provider/notification";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IDLE } from "../../config/constant";

export interface appInterface {
  status: string;
  drawer: boolean;
  notificationTriggers: any;
}

const initialState: appInterface = {
  status: IDLE,
  drawer: null,
  notificationTriggers: null,
};

export const fetchNotificationTriggers = createAsyncThunk(
  "get/notifTriggers",
  async () => {
    try {
      const res: any = await getNotificationTriggers();
      const { data, success } = res.data;
      if (success) {
        // SHOW_SUCCESS(true, "Success");
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const sendNotification = createAsyncThunk(
  "post/sendNotification",
  async (formData: any) => {
    try {
      const res: any = await sendNotification(formData);
      const { data, success } = res.data;
      if (success) {
        // SHOW_SUCCESS(true, "Success");
      }

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

const applicationSlice = createSlice({
  name: "App",
  initialState,
  reducers: {
    isDrawerOpen(state, action) {
      state.drawer = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchNotificationTriggers.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchNotificationTriggers.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      if (success && code === 200) {
        state.notificationTriggers = data;
        state.status = "succeeded";
      }
    });
    builder.addCase(fetchNotificationTriggers.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(sendNotification.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(sendNotification.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      if (success && code === 200) {
        state.status = "succeeded";
      }
    });
    builder.addCase(sendNotification.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { isDrawerOpen } = applicationSlice.actions;
export default applicationSlice.reducer;

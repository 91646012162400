import { Navigate, Route, Routes } from "react-router-dom";
import PublicProfile from "../pages/profile/PublicProfile";

const PublicRoutes = () => {
  // useMemo(() => setRedirectUrl(getPageLocation()), [location])

  return (
    <Routes>
      <Route>
        <Route path="profile/:Id" element={<PublicProfile />} />

        {/* <Route path="/profile" element={<ProviderProfile />} /> */}

        {/* <Route path="/profile" element={<About />} /> */}
        {/* Lazy Modules */}
      </Route>
      {/* Page Not Found */}
      <Route path="*" element={<Navigate to="/error/404" />} />
      {/* </Route> */}
    </Routes>
  );
};

export default PublicRoutes;

import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";
import { urlParameter } from "../../../components/common/types";

export const sendNotification = async (data: unknown) => {
  try {
    return await client.post(
      provider_URLS.NOTIFICATION.SEND_NOTIFICATION,
      data
    );
  } catch (error) {
    console.log("error", error);

    return error;
  }
};

export const signUpNotification = async (data: unknown) => {
  try {
    return await client.post(
      provider_URLS.NOTIFICATION.SIGNUP_NOTIFICATION,
      data
    );
  } catch (error) {
    return error;
  }
};

export const getNotificationTriggers = () =>
  client.get(provider_URLS.NOTIFICATION.GET_NOTIFICATION_TRIGERS);

// }?modelId=${id}&modelType=client
// export const getAllNotificationRequest = (modelId:string ) => client.get(URLS.NOTIFICATION.ALL_NOTIFICATION, { params: { modelId, modelType:"client" } });
export const getAllNotificationRequest = (id: number) =>
  client.get(provider_URLS.NOTIFICATION.ALL_NOTIFICATION, {
    params: { modelId: id, modelType: "provider" },
  });

export const deleteNotification = async (id: urlParameter) => {
  try {
    return await client.delete(
      provider_URLS.NOTIFICATION.DELETE_NOTIFICATION(id)
    );
  } catch (error) {
    return error;
  }
};

export const markAsReadRequest = async (data: any) => {
  try {
    return await client.post(provider_URLS.NOTIFICATION.MARK_AS_READ, data);
  } catch (error) {
    return error;
  }
};

import React from "react";
import { useNavigate } from "react-router-dom";

const StepInfoCardChild: React.FC<{
  isCompleted?: any;
}> = ({ isCompleted }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex align-items-center">
      <div
        onClick={() => {
          if (!isCompleted.services) navigate("/services");
        }}
        style={{ cursor: !isCompleted.services ? "pointer" : "" }}
        className="d-flex align-items-center me-2"
      >
        <img
          height={40}
          className="me-2"
          src={
            isCompleted.services
              ? "images/dashboard/step1.svg"
              : "images/dashboard/step1-unActive.svg"
          }
          alt=""
        />
        <p className="mb-0 fw-bold" style={{ fontSize: "14px" }}>
          Services
        </p>
        {isCompleted.services ? (
          <img
            height={14}
            className="ms-1"
            src="images/dashboard/tick-vector.png"
            alt=""
          />
        ) : null}
      </div>
      <div
        onClick={() => {
          if (!isCompleted.schedules) navigate("/schedule");
        }}
        style={{ cursor: !isCompleted.schedules ? "pointer" : "" }}
        className="d-flex align-items-center me-2"
      >
        <img
          height={40}
          className="me-2"
          src={
            isCompleted.schedules
              ? "images/dashboard/step2.svg"
              : "images/dashboard/step2-unActive.svg"
          }
          alt=""
        />
        <p className="mb-0 fw-bold" style={{ fontSize: "14px" }}>
          Schedule
        </p>
        {isCompleted.schedules ? (
          <img
            height={14}
            className="ms-1"
            src="images/dashboard/tick-vector.png"
            alt=""
          />
        ) : null}
      </div>
      <div
        onClick={() => {
          if (!isCompleted.payments) navigate("/payment");
        }}
        style={{ cursor: !isCompleted.payments ? "pointer" : "" }}
        className="d-flex align-items-center me-2"
      >
        <img
          height={40}
          className="me-2"
          src={
            isCompleted.payments
              ? "images/dashboard/step3.svg"
              : "images/dashboard/step3-unActive.svg"
          }
          alt=""
        />
        <p className="mb-0 fw-bold" style={{ fontSize: "14px" }}>
          Payments
        </p>
        {isCompleted.payments ? (
          <img
            height={14}
            className="ms-1"
            src="images/dashboard/tick-vector.png"
            alt=""
          />
        ) : null}
      </div>
      {/* <div
        onClick={() => {
          if (!isCompleted.medias) navigate("/explore");
        }}
        style={{ cursor: !isCompleted.medias ? "pointer" : "" }}
        className="d-flex align-items-center me-2"
      >
        <img
          height={40}
          className="me-2"
          src={
            isCompleted.medias
              ? "images/dashboard/step4.svg"
              : "images/dashboard/step4-unActive.svg"
          }
          alt=""
        />
        <p className="mb-0 fw-bold" style={{ fontSize: "14px" }}>
          Media
        </p>
        {isCompleted.medias ? (
          <img
            height={14}
            className="ms-1"
            src="images/dashboard/tick-vector.png"
            alt=""
          />
        ) : null}
      </div> */}
      <div
        onClick={() => {
          if (!isCompleted.credentials) navigate("/credential");
        }}
        style={{ cursor: !isCompleted.credentials ? "pointer" : "" }}
        className="d-flex align-items-center me-2"
      >
        <img
          height={40}
          className="me-2"
          src={
            isCompleted.credentials
              ? "images/dashboard/step5.svg"
              : "images/dashboard/step5-unActive.svg"
          }
          alt=""
        />
        <p className="mb-0 fw-bold" style={{ fontSize: "14px" }}>
          Credentials
        </p>
        {isCompleted.credentials ? (
          <img
            height={14}
            className="ms-1"
            src="images/dashboard/tick-vector.png"
            alt=""
          />
        ) : null}
      </div>
    </div>
  );
};

export default StepInfoCardChild;

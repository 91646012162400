// import { fsFourteen, poppinsFont } from "@comp/common/style";
import React, { HtmlHTMLAttributes } from "react";
import { COLORS, radiusEight } from "../../styles";
import DescriptionIcon from "@mui/icons-material/Description";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
// import { copyToClipboard, openInNewTab } from "../";
import { deleteMessage } from "../../../../../Firebase";
import { copyToClipboard, openInNewTab } from "../../../../../utils";
import {
  fsFourteen,
  poppinsFont,
} from "../../../../../components/common/style";
import ModalC from "../../../../../components/common/Modal";
import { MediaViewModal } from "./MediaViewModal";
import { MenuItem, Snackbar } from "@mui/material";
import { StyledMenu } from "../../../../../components/common/StyledMenu/StyledMenu";
// import { Divider } from 'primereact/divider';
// import AudioPlayer from "@comp/common/AudioPlayer";
// import VideoPlayer from "@comp/common/VideoPlayer";
// import Spinner from "@comp/common/Spinner";

const Messages = ({ message, type, array, idx, sendMsgStatus }) => {
  const formattedText =
    message.messageData?.length > 20 && !message.messageData?.includes(" ")
      ? message.messageData?.replace(/(.{20})/g, "$1<wbr>")
      : message.messageData.replace(/\n/g, "<br>");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [open, setOpen] = React.useState(false);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [media, setMedia] = React.useState<any>({
    url: "",
    type: "",
  });

  const handleCopyClick = () => {
    copyToClipboard(message.messageData);
    setSnackOpen(true);
    handleMenuClose();
  };
  const handleSnackClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackOpen(false);
  };

  return (
    <>
      <div className="d-flex">
        <div className="row my-1 ml-2 w-100">
          <div
            className={`${type == "sender" ? "ms-auto" : "me-auto"}
             ${
               String(message.messageType) === "image" ||
               String(message.messageType) === "video" ||
               String(message.messageType) === "document"
                 ? "col-auto"
                 : "col-6"
             }`}
          >
            <div
              style={{
                ...COLORS.bgLight,
                ...fsFourteen,
                backgroundColor: type == "receiver" ? "#CDF1F4" : "#F3F4F6",
                borderRadius:
                  type == "sender" ? "8px 8px 0px 8px" : "8px 8px 8px 0px",
              }}
              className={`mb-0 py-2 ps-2 pe-1`}
            >
              {String(message.messageType) === "image" ? (
                <div
                  className={`d-flex pointer ${
                    type == "receiver"
                      ? "justify-content-start"
                      : "justify-content-end"
                  }`}
                  onClick={() => {
                    setMedia({
                      url: message.messageData,
                      type: "image",
                    });
                    handleOpen();
                  }}
                >
                  <img
                    className="rounded"
                    style={{
                      objectFit: "cover",
                    }}
                    src={message.messageData}
                    alt=""
                    width="200"
                    height="200"
                  />
                </div>
              ) : String(message.messageType) === "video" ? (
                <div
                  className={`d-flex ${
                    type == "receiver"
                      ? "justify-content-start"
                      : "justify-content-end"
                  }`}
                  // onClick={() => {
                  //   setMedia({
                  //     url: message.messageData,
                  //     type: "video",
                  //   });
                  //   handleOpen();
                  // }}
                >
                  {/* <VideoPlayer url={message.messageData} /> */}
                  <video
                    className="rounded"
                    id="video"
                    width="200"
                    height="auto"
                    controls
                  >
                    <source src={message.messageData} type="video/mp4" />
                  </video>
                </div>
              ) : String(message.messageType) === "audio" ? (
                <div
                  className={`d-flex ${
                    type == "receiver"
                      ? "justify-content-start"
                      : "justify-content-end"
                  }`}
                >
                  {/* <AudioPlayer audioSrc={message.messageData} /> */}
                  <audio controls controlsList="nodownload noplaybackrate">
                    <source src={message.messageData} type="audio/mpeg" />
                    <p>Sorry, your browser doesn't support embedded audio.</p>
                  </audio>
                </div>
              ) : String(message.messageType) === "document" ? (
                <div
                  className={`d-flex ${
                    type == "receiver"
                      ? "justify-content-start"
                      : "justify-content-end"
                  }`}
                >
                  <DescriptionIcon
                    sx={{ fontSize: "150px", color: "#04bac7" }}
                  />
                </div>
              ) : (
                <div className="pt-1">
                  <div dangerouslySetInnerHTML={{ __html: formattedText }} />
                </div>
              )}
              <div
                className={`mb-0 text-muted small mx-1 ${
                  String(message.messageType) === "image" && type == "receiver"
                    ? "text-end mt-1"
                    : String(message.messageType) === "video" &&
                      type == "receiver"
                    ? "text-end mt-1"
                    : String(message.messageType) === "image" &&
                      type == "sender"
                    ? "text-end mt-1"
                    : "text-end"
                }`}
                style={{
                  userSelect: "none",
                  fontSize: "10px",
                  fontWeight: 400,
                  fontStyle: "italic",
                  // marginTop: String(message.messageType) === "image" ? '-15px' : '',
                }}
              >
                {message.createdAt?.time}
                <p className="mb-0">
                  {sendMsgStatus == "Loading" && array.length - 1 == idx ? (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  ) : (
                    ""
                  )}
                </p>
              </div>
            </div>
          </div>
          {/* {array.length - 1 !== idx ?
          <Divider align="center">
            <span style={{ fontSize: '10px' }} className="p-tag bg-primary py-1">{message.createdAt?.time}</span>
          </Divider>
          : null
          } */}
        </div>

        <div>
          <div
            id="dropdownMenuButton2"
            aria-controls={menuOpen ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? "true" : undefined}
            onClick={handleClick}
          >
            <MoreVertSharpIcon
              className="pointer p-absolute px-0"
              sx={{ fontSize: "1rem", color: "grey" }}
            />
          </div>
          <StyledMenu
            id="demo-customized-menu"
            MenuListProps={{
              "aria-labelledby": "demo-customized-button",
            }}
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            {String(message.messageType) === "text" ? (
              <MenuItem onClick={() => handleCopyClick()} disableRipple>
                <ContentCopyIcon />
                Copy
              </MenuItem>
            ) : String(message.messageType) !== "card" ? (
              <MenuItem
                onClick={() => {
                  openInNewTab(message.messageData);
                  handleMenuClose();
                }}
                disableRipple
              >
                <FileDownloadSharpIcon />
                Download
              </MenuItem>
            ) : null}
            {type == "sender" ? (
              <MenuItem
                onClick={() => {
                  deleteMessage(message.id, message.conversationId);
                  handleMenuClose();
                }}
                disableRipple
              >
                <DeleteIcon />
                Delete
              </MenuItem>
            ) : null}
          </StyledMenu>
        </div>
      </div>
      <ModalC handleClose={handleClose} open={open}>
        <MediaViewModal media={media} />
      </ModalC>
      <Snackbar
        open={snackOpen}
        autoHideDuration={2000}
        message="Copied to clipboard"
        onClose={handleSnackClose}
      />
    </>
  );
};

export default Messages;

// old audio code
//

// {/* <audio controls>
//     <source src={message.messageData} type="audio/mpeg" />
//     <p>Sorry, your browser doesn't support embedded audio.</p>
// </audio>  */}

// old code
// false
//                 //   String(message.messageType) === "image" ||
//                 //   String(message.messageType) === "video" ||
//                 //   String(message.messageType) === "audio" ||
//                 //   String(message.messageType) === "document"
//                     ? "white"
//                     :

import {
  SettingsApplications as ServiceIcon,
  Explore as ExploreIcon,
  EventNote as ScheduleIcon,
  Dashboard as DashboardIcon,
  Person as MemberIcon,
  Payment as PaymentIcon,
  HourglassTop as AppointmentIcon,
  Chat as ChatIcon,
  ViewListSharp as NAIcon,
  WorkspacePremiumSharp as CredentialsIcon,
} from "@mui/icons-material";

const SidebarRoutes = [
  {
    name: "Dashboard",
    link: `/`,
    Icon: ({ sx }) => <DashboardIcon sx={sx} />,
  },
  {
    name: "Services",
    link: `/services`,
    Icon: ({ sx }) => <ServiceIcon sx={sx} />,
  },
  {
    name: "Schedule",
    link: `/schedule`,
    Icon: ({ sx }) => <ScheduleIcon sx={sx} />,
  },
  {
    name: "Credentials",
    link: `/credential`,
    Icon: ({ sx }) => <CredentialsIcon sx={sx} />,
  },
  {
    name: "Explore",
    link: `/explore`,
    Icon: ({ sx }) => <ExploreIcon sx={sx} />,
  },
  {
    name: "Client",
    link: `/client`,
    Icon: ({ sx }) => <MemberIcon sx={sx} />,
  },
  {
    name: "Payment Gateway",
    link: `/payment`,
    Icon: ({ sx }) => <PaymentIcon sx={sx} />,
  },
  {
    name: "Payment History",
    link: `/payment-status`,
    Icon: ({ sx }) => <PaymentIcon sx={sx} />,
  },
  {
    name: "Appointments",
    link: `/appointment`,
    Icon: ({ sx }) => <AppointmentIcon sx={sx} />,
  },
  {
    name: "Chat",
    link: `/chat`,
    Icon: ({ sx }) => <ChatIcon sx={sx} />,
  },
  // { name: "Media", link: `/media` },
  // { name: "Profile", link: `$/{PROVIDER_URL_BASE}/profile` },
  // { name: "Profile", link: `/profile` },
  // { name: "User Settings", link: `/settings` },
];

export { SidebarRoutes };

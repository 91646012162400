import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../store";
import {
  handleOnrToOneTimeChange,
  vacationSelector,
} from "../../../../../reducers/scheduleReducer";
import MainCard from "./components/MainCard";
import { vacationTypes } from "../../type";
import CreateVacation from "./components/CreateVacation";
import VacationTable from "./components/VacationTable";

const Vacation = ({updateForm, setForm}) => {
  return (
    <>
      <h6 style={{ fontSize: "16px" }} className="mb-0 fw-bold">
        Vacation /Time off
      </h6>
      <CreateVacation updateForm={updateForm} setForm={setForm} />
      {/* <div className="accordion mb-3" id="accordionExample">
        {vacationData?.map((VD, idx) => (
          <MainCard key={idx} data={VD} />
        ))}
      </div> */}
      <VacationTable />
    </>
  );
};

export default Vacation;

import { errorResponseType } from "../components/common/types";
import { ERROR_MESS } from "../config/constant";
import { SHOW_ERROR } from "./ToastMessage";

export const makeResponseError = (response: errorResponseType) => {
  const { code, success, message, data } = response;
  console.log(
    `%cFrom error Handler =: 
                  code    → ${code} 
                  success → ${success} 
                  message → ${message}
                                      `,
    "color:HotPink"
  );
  if (!success && code === 400) {
    if (data) {
      if (data.username) {
        SHOW_ERROR(true, Object.entries(data)[0][1][0]);
      } else if (data.error) {
        SHOW_ERROR(true, data.error);
      } else if (data.message) {
        SHOW_ERROR(true, Object.entries(data)[0][1][0]);
      } else {
        SHOW_ERROR(true, message);
      }
    } else {
      if (response.error) {
        SHOW_ERROR(true, "Login credentials is incorrect");
      } else {
        SHOW_ERROR(true, message);
      }
    }
  } else if (!success && code === 422) {
    SHOW_ERROR(true, Object.entries(data)[0][1][0]);
  } else {
    SHOW_ERROR(true, ERROR_MESS);
  }
  return {
    success,
    data: {},
    code,
  };
};

import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentRecord,
  businessTypes,
  createPayment,
  fetchAllBusinessTypes,
  paymentPrincipals,
  providerBoardingAction,
} from "../../../../reducers/paymentReducer";
import { AppDispatch } from "../../../../store";
import * as yup from "yup";
import FormField from "../../../../components/UI/FormField";
import { payStep2 } from "../../../../components/common/types";
import { countries, mccCodes, states } from "../../../../config/constant";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";
import AddIcon from "@mui/icons-material/Add";
import { SHOW_ERROR } from "../../../../utils/ToastMessage";
import ModalC from "../../../../components/common/Modal";
import AddOwnerShipTable from "./AddOwnerShipTable";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";

interface Options {
  label: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

const PayStep2 = ({
  setChangeStep,
  handleDataChange,
  data,
}: {
  setChangeStep(string): void;
  handleDataChange: any;
  data: any;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [request, setRequest] = useState(false);
  const record = useSelector(PaymentRecord);
  const payPrincipals = useSelector(paymentPrincipals);
  // console.log("data", record);

  const initialState: payStep2 = {
    mAddress: data?.mAddress,
    mCity: data?.mCity,
    mState: data?.mState,
    mZip: data?.mZip,
    mcc: data?.mcc,
    depositAccountBankName: data?.depositAccountBankName,
    contacts: data?.contacts,
    ownership: data?.ownership,
    depositAccountRoutingAccount: data?.depositAccountRoutingAccount,
    depositAccountAccountNumber: data?.depositAccountAccountNumber,
    depositAccountTypeAccount: data?.depositAccountTypeAccount,
    templateId: "",
    isAcceptVisa: false,
  };

  const validationSchema = yup.object().shape({
    mAddress: yup.string().nullable().required("Required"),
    mCity: yup.string().nullable().required("Required"),
    mState: yup.string().nullable().required("Required"),
    mZip: yup.string().nullable().required("Required"),
    mcc: yup.string().nullable().required("Required"),
    depositAccountBankName: yup.string().required("Required").nullable(),
    depositAccountRoutingAccount: yup.string().required("Required").nullable(),
    depositAccountAccountNumber: yup.string().required("Required").nullable(),
    depositAccountTypeAccount: yup.string().required("Required").nullable(),
  });

  const [openOwnershipModal, setOpenOwnershipModal] = useState(false);
  const handleOpenOwnershipModal = () => setOpenOwnershipModal(true);
  const handleCloseOwnershipModal = () => setOpenOwnershipModal(false);

  const [change, setChange] = useState(false);

  const [options, setOptions] = useState<Options[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAddressChange = async (e) => {
    setLoading(true);
    if (e.target.value.length > 3) {
      const res: any = await axios.post(
        "https://api.postgrid.com/v1/addver/completions",
        {
          partialStreet: e.target.value,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_POSTGRID_API_KEY,
          },
        }
      );
      const data = res.data.data;
      if (data?.length !== 0) {
        setOptions(
          data.map((m) => {
            return {
              label:
                m?.address?.address +
                " " +
                m?.address?.city +
                " " +
                m?.address?.prov +
                ", " +
                m?.address?.pc,
              street: m?.address?.address,
              city: m?.address?.city,
              state: m?.address?.prov,
              zip: m?.address?.pc,
            };
          })
        );
      } else {
        setLoading(false);
        setOptions([]);
      }
    }
  };

  // useEffect(() => {
  //   dispatch(fetchAllBusinessTypes());
  // }, [dispatch]);

  // const businesstypes = useSelector(businessTypes);
  //remove underscores from business types object
  // const types = Object.values(businesstypes).map((type) => {
  //   return type?.replace(/_/g, " ");
  // });
  // console.log("data", data);

  function onchange(e) {
    setRequest(true);
    handleDataChange(e);
  }

  return (
    <div style={{ maxWidth: "700px" }} className="my-4 mx-auto">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 px-0 text-center"
      >
        <div className="d-flex justify-content-between px-4 py-3 border-bottom">
          <div>
            <h5 className="mb-0">Payments</h5>
          </div>
          <p className="text-primary py-1 px-3 mb-0">Step 2/3</p>
        </div>
        <div className="form text-start pb-3 px-4">
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              // console.log("value", values);
              if (request) {
                const finalData = {
                  ...values,
                  submitForApproval: 0,
                  id: record?.id,
                };
                if (data?.ownership?.length === 0) {
                  SHOW_ERROR(true, "Please add ownership");
                } else {
                  dispatch(
                    providerBoardingAction({ data: finalData, setChangeStep })
                  );
                  if (change) {
                    setChangeStep("Step4");
                  }
                }
              }
              resetForm();
            }}
          >
            {(formikHandler) => (
              <Form>
                <div className="form-group mt-3">
                  <div className="row border rounded mt-4 pb-3">
                    <h6
                      style={{ fontSize: "1rem" }}
                      className="bg-light w-100 mt-0 py-3 text-dark fw-light"
                    >
                      Mail
                    </h6>
                    <div className="col">
                      <Autocomplete
                        id="asynchronous-demo"
                        freeSolo
                        isOptionEqualToValue={(option, value) => {
                          return option.street === value.street;
                        }}
                        getOptionLabel={(option: any) => option.label}
                        options={options}
                        open={open}
                        sx={{ width: "100%" }}
                        onChange={(e, value) => {
                          const data: any = value;
                          formikHandler.handleChange({
                            target: {
                              name: "mAddress",
                              value: data?.street,
                            },
                          });
                          formikHandler.handleChange({
                            target: {
                              name: "mCity",
                              value: data?.city,
                            },
                          });
                          formikHandler.handleChange({
                            target: {
                              name: "mState",
                              value: data?.state,
                            },
                          });
                          formikHandler.handleChange({
                            target: {
                              name: "mZip",
                              value: data?.zip,
                            },
                          });
                        }}
                        onError={(e) => {
                          console.log(e);
                          setOptions([]);
                        }}
                        onBlur={(e) => {
                          setOptions([]);
                          setLoading(false);
                        }}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        filterOptions={(x) => x}
                        loading={loading}
                        noOptionsText="No Address Found"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Your Address"
                            // className="autocomplete"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "no",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "1px solid #ced4da",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#ced4da",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "1px solid #ced4da",
                                  boxShadow: "0 0 0 0.25rem #cdf1f4",
                                },
                              },
                            }}
                            size="small"
                            onChange={(e) => {
                              handleAddressChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                    <FormField
                      className="col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "Address",
                        prop: "mAddress",
                        placeHolder: "Address here",
                      }}
                    />

                    <FormField
                      className="col-sm-4 col-12 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "City",
                        prop: "mCity",
                        placeHolder: "City here",
                      }}
                    />
                    <FormField
                      className="col-sm-4 col-12 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "State",
                        prop: "mState",
                        placeHolder: "State here",
                      }}
                    />
                    {/* Mail zip */}
                    <FormField
                      className="col-sm-4 col-12 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "Zip",
                        prop: "mZip",
                        placeHolder: "Zip here",
                      }}
                    />
                    {/* mail cc */}
                    <div className="col-12 mt-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <label htmlFor="mcc" className="form-label">
                          MCC
                        </label>
                        <BootstrapTooltip
                          title="Merchant Category Code"
                          placement="top"
                        >
                          <img
                            className="pointer mb-2"
                            height={16}
                            src="/images/schedule/infosvg.svg"
                            alt=""
                          />
                        </BootstrapTooltip>
                      </div>
                      <select
                        name="mcc"
                        className={`form-select ${
                          formikHandler.errors.mcc && formikHandler.errors.mcc
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formikHandler.values.mcc}
                        onChange={(e) => {
                          formikHandler.handleChange(e);
                          onchange(e);
                        }}
                        id=""
                      >
                        <option value="">Select CC</option>
                        {mccCodes.map((cc) => (
                          <option key={cc.mcc} value={cc.mcc}>
                            {cc.combined_description}
                          </option>
                        ))}
                      </select>
                      {formikHandler.errors.mcc && (
                        <div className="invalid-feedback">
                          {formikHandler.errors.mcc}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row border rounded mt-4 pb-3">
                    <h6
                      style={{ fontSize: "1rem" }}
                      className="bg-light w-100 mt-0 py-3 text-dark fw-light"
                    >
                      Deposit Account
                    </h6>
                    <FormField
                      className="col-sm-6 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "bank name",
                        prop: "depositAccountBankName",
                        placeHolder: "Deposit account bank name here",
                      }}
                    />

                    {/* phoneNo */}
                    <FormField
                      className="col-sm-6 mt-sm-0 mt-3 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "routing account",
                        prop: "depositAccountRoutingAccount",
                        placeHolder: "Deposit account routing account here",
                      }}
                    />

                    {/* "faxNo": "121321", */}
                    <FormField
                      className="col-sm-6 col-12 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "account number",
                        prop: "depositAccountAccountNumber",
                        placeHolder: "Deposit account account number here",
                      }}
                    />

                    {/* "bAddress": "address", */}
                    <FormField
                      className="col-sm-6 col-12 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "account type",
                        prop: "depositAccountTypeAccount",
                        placeHolder: "Deposit account account type here",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-stretch align-items-start">
                  <div
                    className="btn rounded-3 mt-4 ps-0 text-primary d-flex"
                    onClick={handleOpenOwnershipModal}
                  >
                    <AddIcon
                      fontSize="small"
                      sx={{ color: "#04bac7", marginRight: 1 }}
                    />
                    Add Ownership
                  </div>
                  <div className="d-flex">
                    <button
                      onClick={() => setChangeStep("ToPay1")}
                      className="btn btn-outline-secondary border rounded rounded-3 mt-4 px-5 mx-2"
                    >
                      Back
                    </button>
                    {payPrincipals?.length !== 0 && !request ? (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-primary rounded rounded-3 mt-4 px-5"
                          // type="submit"
                          onClick={() => {
                            if (data?.ownership?.length === 0) {
                              SHOW_ERROR(true, "Please add ownership");
                            } else {
                              setChangeStep("Step4");
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-primary rounded rounded-3 mt-4 px-5"
                          type="submit"
                          onClick={() => setChange(true)}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ModalC handleClose={handleCloseOwnershipModal} open={openOwnershipModal}>
        <AddOwnerShipTable
          ownerShip={data}
          onChange={onchange}
          handleClose={handleCloseOwnershipModal}
        />
      </ModalC>
    </div>
  );
};

export default PayStep2;

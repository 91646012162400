import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOneToOneBreakTime,
  scheduleOnToOneReadOnlySelector,
  scheduleOnToOneSelector,
  updateOneToOne,
} from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import DisplayTime from "../common/DisplayTime";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const DailyBrAccordion = ({ breakTimes, breaks, change, setChange }) => {
  const [breakData, setBreakData] = useState(breakTimes);
  const dispatch = useDispatch<AppDispatch>();
  const { onToOne } = useSelector((state: any) => state.schedule);

  const readOnlyData = useSelector(scheduleOnToOneReadOnlySelector);
  const handleToggleChange = (e: any) => {
    dispatch(
      updateOneToOne({ key: e.target.name, value: e.target.checked ? 1 : 0 })
    );
  };

  const addBrTime = () => {
    dispatch(addOneToOneBreakTime());
  };

  // const removeBrTime = (index) => {
  //   dispatch(removeOneToOneBreakTime(index));
  // };

  const scheduleOnToOne = useSelector(scheduleOnToOneSelector);

  // const serviceAvailableInfo = () =>
  //   "Some providers like to build \n in a time block in their day \n where clients can not \n access your schedule.";

  useEffect(() => setBreakData(readOnlyData.breakTimes), [readOnlyData]);
  return (
    <div className="accordion mb-3 " id="accordionExample1">
      <div
        className={`accordion-item ${
          onToOne?.isBreak === 1
            ? onToOne?.breakTimes?.length === 0
              ? "border-danger"
              : change.break
              ? "border-start-primary"
              : ""
            : ""
        }`}
      >
        <h2 className="accordion-header" id="headingOne">
          <button
            style={{ backgroundColor: "#f9fafb", minHeight: "4.4rem" }}
            className="accordion-button px-md-4 px-2 collapsed"
            type="button"
          >
            <div className="row w-100 align-items-center">
              <div className="col-md-6 col-8">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3 pointer"
                    height={22}
                    width={22}
                    src="/images/schedule/DailySchedule.svg"
                    alt=""
                  />
                  <div>
                    <p className="fw-light mb-0 text-dark">Daily Break</p>

                    {readOnlyData?.isBreak === 1 ? (
                      <p
                        style={{ fontSize: "12px" }}
                        className="fw-light my-0 text-secondary"
                      >
                        {breakData?.map(
                          (
                            { id, breakStartTime, breakEndTime }: any,
                            idx: number,
                            arr: string | any[]
                          ): JSX.Element => (
                            <span key={idx} style={{ display: "inline-block" }}>
                              <span key={idx} style={{ margin: "4px" }}>
                                {breakStartTime} - {breakEndTime}
                              </span>
                              {arr?.length > 0 && !(idx + 1 === arr.length)
                                ? ` | `
                                : ` `}
                            </span>
                          )
                        )}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="d-flex align-items-center justify-content-end actions">
                  {scheduleOnToOne.isBreak === 1 ? (
                    <AddCircleIcon
                      className="pointer me-2 pe-1"
                      onClick={() => {
                        addBrTime();
                        setChange({
                          ...change,
                          break: true,
                        });
                      }}
                      sx={{ color: "grey" }}
                    />
                  ) : null}
                  <BootstrapTooltip
                    placement="top"
                    title="Some providers like to build in a time block in their day where clients can not access your schedule."
                  >
                    <img
                      id="dailyBreak"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isBreak"
                      onChange={handleToggleChange}
                      checked={breaks}
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse1"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapse1"
          className={`accordion-collapse collapse ${
            onToOne?.breakTimes?.length === 0
              ? onToOne?.isBreak === 1
                ? "show"
                : "collapse"
              : "show"
          }`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample1"
        >
          <div className="accordion-body px-2 pt-xl-4 pt-2 text-end pb-0">
            {/* <button
              className="btn btn-secondary me-2"
            >
              +
              ADD
            </button> */}
            <div className="row w-100 m-auto px-0">
              {breakTimes?.length !== 0 ? (
                <>
                  <div className="col-6 text-center ps-md-5 ps-0">
                    <p
                      style={{ fontSize: "16px" }}
                      className="mb-2 text-dark ps-xl-5 ps-0"
                    >
                      Break Begin
                    </p>
                  </div>
                  <div className="col-6 text-center pe-md-5 pe-0">
                    <p
                      style={{ fontSize: "16px" }}
                      className="mb-2 text-dark pe-xl-5 pe-0"
                    >
                      Break End
                    </p>
                  </div>{" "}
                </>
              ) : null}
            </div>
            {breakTimes?.map(
              (
                { id, breakStartTime, breakEndTime }: any,
                idx: React.Key
              ): JSX.Element => (
                <>
                  <DisplayTime
                    key={id}
                    id={idx}
                    fullStartTime={breakStartTime}
                    fullEndTime={breakEndTime}
                    startTime={breakStartTime?.split(" ")[0]}
                    startMeridiem={breakStartTime?.split(" ")[1]}
                    endTime={breakEndTime?.split(" ")[0]}
                    endMeridiem={breakEndTime?.split(" ")[1]}
                    type="single"
                    setChange={setChange}
                    change={change}
                    // startTime={breakStartTime}
                    // endTime={breakEndTime}
                  />
                  {/* <button
                    style={{}}
                    className="btn btn-danger me-2"
                  >
                    X
                  </button> */}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyBrAccordion;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addAppointmentNote,
  addPhoto,
  changePendingAppointmentStatusRequest,
  createAppointment,
  deletePhoto,
  getAppointmentNote,
  getBookedAppointmentListRequest,
  getCurrentSlotsRequest,
  getPaginatedAppointmentListRequest,
  rescheduleAppointment,
  UpdateNotes,
} from "../../helper/provider/appointment";
import { fetchFullScheduleRequest } from "../../helper/provider/schedule";
import { RootState } from "../../store";
import { makeResponseError } from "../../utils/errorHandler";
import { SHOW_SUCCESS } from "../../utils/ToastMessage";
import { FAIL, LOADING, scheduleType, SUCCESS } from "../../config/constant";
import { AxiosResponse } from "../../components/common/types";
import { sendNotification } from "../../helper/provider/notification";
import {
  attachedPaginatedPayload,
  makeAppointmentList,
} from "../../utils/factory";

export interface scheduleState {
  pendingList: Array<any>;
  schedule: {
    one_to_one?: {
      bookingData?: {
        startTime: string;
        endTime: string;
      }[];
      workHours: {
        startTime: string;
        endTime: string;
      };
      breakTime: Array<{
        startTime: string;
        endTime: string;
      }>;
      doubleBookingServices: [];
      overlappingTime: string;
    };
    multi_to_multi: any;
  };
  availableSlots: Array<{ startTime: string; endTime: string }>;
  status: string;
  pendingAppStatus: string;
  notes: any;
  uploadImageStatus: string;
  pagination: any;
  // createStatus: string;
  error: string | null;
}

export const initialState: scheduleState = {
  pendingList: [],
  notes: {},
  schedule: {
    one_to_one: {
      workHours: { startTime: "", endTime: "" },
      breakTime: [],
      doubleBookingServices: [],
      overlappingTime: "",
    },
    multi_to_multi: [],
  },
  availableSlots: [],
  status: "idle",
  pendingAppStatus: "idle",
  uploadImageStatus: "idle",
  pagination: {
    currentPage: 0,
    perPage: 0,
    total: 0,
    lastPage: 0,
    firstPageUrl: "",
    lastPageUrl: "",
    nextPageUrl: "",
    previousPageUrl: "",
  },
  // createStatus: "idle",
  error: null,
};

export const getPaginatedAppointmentList = createAsyncThunk(
  "get/paginatedList",
  async (formData?: any) => {
    try {
      const res: any = await getPaginatedAppointmentListRequest(formData);
      const { data, success } = res.data;

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const fetchSchedule = createAsyncThunk("fetch/schedule", async () => {
  try {
    const res: any = await fetchFullScheduleRequest();
    const { data, success } = res.data;
    // console.log("schedule", data);

    // if (data.length > 0 && success) {
    //   thunkApi.dispatch(fetchThirdLevelChildServices(data[0].id));
    // }
    return {
      data,
      success,
      code: 200,
    };
  } catch (error) {}
});

export const getPendingAppointmentList = createAsyncThunk(
  "get/pendingList",
  async (params: any) => {
    try {
      const res: any = await getBookedAppointmentListRequest(params);
      const { data, success } = res.data;

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const changeAppointmentStatus = createAsyncThunk(
  "get/status",
  async (statusData: any) => {
    try {
      const res: any = await changePendingAppointmentStatusRequest(statusData);
      const { success } = res.data;
      // console.log("statusData : ", statusData);
      if (success) {
        SHOW_SUCCESS(success, "status changed Successfully ! ");
        if (statusData.handleClose) {
          statusData.handleClose();
          statusData.drawerClose();
          statusData.navigate("/payment-status");
        }
      }

      return {
        data: statusData,
        success,
        code: 200,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const appointmentReschedule = createAsyncThunk(
  "post/reschedule/appointment",
  async (formData: any, thubkApi) => {
    try {
      const res: any = await rescheduleAppointment(formData.data);
      const { data, success } = res.data;
      if (success) {
        SHOW_SUCCESS(success, "Appointment rescheduled successfully");
        thubkApi.dispatch(
          changeAppointmentStatus({
            appointmentId: data.id,
            status: "rescheduleByProvider",
            isPaid: "2",
            statusToShow: "Reschedule By Provider",
          })
        );
        formData.handleClose();
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const getAppointmentNotes = createAsyncThunk(
  "get/appointment-notes",
  async (id: any) => {
    try {
      const res: any = await getAppointmentNote(id);
      // console.log(res);
      const { data, success } = res.data;

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const addAppointmentNotes = createAsyncThunk(
  "add/appointment-notes",
  async (formData: any) => {
    try {
      const res: any = await addAppointmentNote(formData);
      const { data, success } = res.data;
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const updateAppointmentNotes = createAsyncThunk(
  "update/appointment-notes",
  async (formData: any) => {
    try {
      const res: any = await UpdateNotes(formData.id, formData.note);
      const { data, success } = res.data;
      return {
        data,
        success,
      };
    } catch (error) {
      return error;
    }
  }
);

// export const addAppointmentPhoto = createAsyncThunk(
//   "add/appointment-photo",
//   async (formData: any) => {
//     try {
//       const res: any = await addPhoto(formData.data);
//       // console.log(res);

//       const { data, success } = res.data;
//       return {
//         data: data,
//         index: formData.index,
//         success,
//         code: 200,
//       };
//     } catch (error) {
//       return error;
//     }
//   }
// );

export const createNewAppointment = createAsyncThunk(
  "add/appointment",
  async (formData: any) => {
    try {
      const res: any = await createAppointment(formData.data);
      const { data, success } = res.data;
      if (success) {
        SHOW_SUCCESS(success, "Appointment created successfully");
        const notificationData = {
          trigger: "PROVIDER_SEND_REBOOK_REMINDER",
          triggerType: "client-triggers",
          modelType: "client",
          modelId: data.client.id,
          channel: "KC",
          title: "Appointment Rebook Reminder",
          messageParams: [{ name: "Mobeen" }],
        };
        sendNotification(notificationData);
        formData.drawerClose();
        formData.handleClose();
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {}
  }
);

export const deleteMediaPhoto = createAsyncThunk(
  "delete/appointment-media/photo",
  async (id: any) => {
    try {
      const res: any = await deletePhoto(id.id);
      const { data, success } = res.data;
      return {
        data,
        success,
        id: id.id,
        index: id.index,
      };
    } catch (error) {
      return error;
    }
  }
);

export const getCurrentSlotsAction = createAsyncThunk(
  "get/time/slots",
  async (
    dateData: { date: string; providerId: string; servicesId: string },
    thunkAPI
  ) => {
    try {
      const { providerId, date, servicesId } = dateData;
      const res: AxiosResponse = await getCurrentSlotsRequest({
        servicesId,
        date,
        providerId,
      });
      const { data, success } = res.data;
      // console.log("bookedSlots", data);
      if (success) {
        thunkAPI.dispatch(calculateAvailableSlots({ slots: data }));
        console.log("time slots request : ", success);
      } else {
        thunkAPI.dispatch(calculateAvailableSlots({ slots: [] }));
        console.log("time slots request : ", success);
      }
      return {
        slots: data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

const appointmentSlice = createSlice({
  name: "credentials",
  initialState,
  reducers: {
    calculateAvailableSlots: (state, action) => {
      console.log("slots coming... ");

      const { slots } = action.payload;
      state.availableSlots = slots;

      // const { bookedSlots, userServices } = action.payload;
      // const { workHours, breakTime, doubleBookingServices, overlappingTime } =
      //   state.schedule.one_to_one;

      // const totalServiceTime = getTotal(userServices, "duration");
      // // console.log("totalServiceTime : ", totalServiceTime);

      // let refinedBooking = [...bookedSlots];
      // // console.log("refinedBooking : ", refinedBooking);

      // let found = refinedBooking.map(({ services }) =>
      //   isIncluded(services, doubleBookingServices)
      // );
      // // console.log("found : ", found);

      // if (found.includes(true)) {
      //   refinedBooking = bookedSlots.map(
      //     (times: { startTime: string; endTime: string }) => {
      //       return {
      //         startTime: times.startTime,
      //         endTime: Time.removeMin(times.endTime, Number(overlappingTime)),
      //       };
      //     }
      //   );
      // }

      // const finalRemovableTime = [
      //   ...breakTime,
      //   ...(refinedBooking.length > 0 ? refinedBooking : []),
      // ];

      // // console.log("finalRemovableTime : ", finalRemovableTime);

      // const removedBreak = getAvailableTimeSlotsWithSingle(
      //   workHours,
      //   finalRemovableTime
      // );
      // // console.log("removedBreak : ", removedBreak);

      // const slots = findSlots(removedBreak, totalServiceTime);
      // if (action.payload.date === moment().format("YYYY-MM-DD")) {
      //   // const currentTime = moment().format("HH:mm");
      //   const filteredSlots = slots.filter(
      //     (slot) =>
      //       moment(slot.startTime, "hh:mm A").format("HH:mm") >=
      //       moment().format("HH:mm")
      //   );
      //   state.availableSlots = filteredSlots;
      // } else {
      //   state.availableSlots = slots;
      // }
    },

    resetAvailableSlots: (state) => {
      state.availableSlots = [];
    },

    updateImageModalStatus: (state, action) => {
      state.uploadImageStatus = action.payload;
    },

    addAppointmentMedia: (state, action) => {
      const { index, data } = action.payload;
      // console.log(data.data.data);
      const finalData = {
        id: data.data.data.id,
        appointment_id: "",
        client_id: "",
        url: data.data.data.url,
        thumbnail_url: data.data.data.thumbnail,
        media_type: data.data.data.type,
      };
      state.pendingList[index].media.push(finalData);
    },
  },
  extraReducers(builder) {
    builder.addCase(getPendingAppointmentList.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPendingAppointmentList.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      // console.log("data : ", data);

      if (success && code === 200) {
        state.status = "succeeded";
        const { appointmentList } = makeAppointmentList(data);

        state.pendingList = appointmentList;
        // state.pendingList = data.sort(
        //   (a, b) => b.appointmentDate - a.appointmentDate
        // );
      }
    });
    builder.addCase(getPendingAppointmentList.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(getPaginatedAppointmentList.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPaginatedAppointmentList.fulfilled, (state, action) => {
      const { data, success } = action.payload;
      if (success) {
        state.status = "succeeded";

        const { appointmentList } = makeAppointmentList(data.appointments);

        state.pagination = attachedPaginatedPayload(data);

        state.pendingList = appointmentList;
        // state.pendingList = data.sort(
        //   (a, b) => b.appointmentDate - a.appointmentDate
        // );
      }
    });
    builder.addCase(getPaginatedAppointmentList.rejected, (state, action) => {
      state.status = "failed";
    });

    // status changed

    builder.addCase(changeAppointmentStatus.pending, (state, action) => {
      state.pendingAppStatus = "loading";
    });
    builder.addCase(changeAppointmentStatus.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      if (success && code === 200) {
        state.pendingAppStatus = "succeeded";
        const index = state.pendingList.findIndex(
          (item: any) => item.id === data.appointmentId
        );
        if (index !== -1) {
          state.pendingList[index].status = data.status;
          state.pendingList[index].statusToShow = data.statusToShow;
          state.pendingList = makeAppointmentList(
            state.pendingList
          ).appointmentList;
        }
      }
    });
    builder.addCase(changeAppointmentStatus.rejected, (state, action) => {
      state.pendingAppStatus = "failed";
    });

    builder.addCase(appointmentReschedule.pending, (state, action) => {
      // state.status = "loading";
    });
    builder.addCase(appointmentReschedule.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data, success, code } = action.payload;
      if (success && code === 200) {
        const index = state.pendingList.findIndex(
          (item: any) => item.id === data.id
        );
        if (index !== -1) {
          state.pendingList[index].startTime = data.startTime;
          state.pendingList[index].endTime = data.endTime;
          state.pendingList[index].appointmentDate = data.appointmentDate;
        }
      }
    });
    builder.addCase(appointmentReschedule.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(getAppointmentNotes.pending, (state, action) => {
      // state.status = "loading";
      state.notes = {};
    });
    builder.addCase(getAppointmentNotes.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.notes = action.payload.data?.[0];
    });
    builder.addCase(getAppointmentNotes.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(addAppointmentNotes.pending, (state, action) => {
      // state.status = "loading";
    });
    builder.addCase(addAppointmentNotes.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(addAppointmentNotes.rejected, (state, action) => {
      state.status = "failed";
    });

    // builder.addCase(addAppointmentPhoto.pending, (state, action) => {
    //   state.uploadImageStatus = "loading";
    // });
    // builder.addCase(addAppointmentPhoto.fulfilled, (state, action) => {
    //   state.uploadImageStatus = "succeeded";
    //   const { index, data } = action.payload;
    //   // console.log(data);
    //   const finalData = {
    //     id: data?.id,
    //     appointment_id: "",
    //     client_id: "",
    //     url: data?.url,
    //   };
    //   state.pendingList[index].media.push(finalData);
    // });
    // builder.addCase(addAppointmentPhoto.rejected, (state, action) => {
    //   state.uploadImageStatus = "failed";
    // });

    builder.addCase(updateAppointmentNotes.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(updateAppointmentNotes.fulfilled, (state, action) => {
      state.status = "succeeded";
    });
    builder.addCase(updateAppointmentNotes.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(deleteMediaPhoto.pending, (state, action) => {
      // state.status = "loading";
    });
    builder.addCase(deleteMediaPhoto.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.pendingList[action.payload.index].media = state.pendingList?.[
        action.payload.index
      ]?.media?.filter((f) => f?.id !== action.payload.id);
    });
    builder.addCase(deleteMediaPhoto.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(fetchSchedule.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(fetchSchedule.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;

      if (success && code == 200) {
        for (let index = 0; index < data.length; index++) {
          if (data[index].type === scheduleType.SINGLE_TO_SINGLE) {
            const ONE_TO_ONE = data[index];
            const timing = {
              workHours: {
                startTime: ONE_TO_ONE.startTime,
                endTime: ONE_TO_ONE.endTime,
              },
              breakTime: ONE_TO_ONE.isBreak
                ? ONE_TO_ONE.breakTimes.map(({ startTime, endTime }) => {
                    return {
                      startTime: startTime,
                      endTime: endTime,
                    };
                  })
                : [],
              overlappingTime: ONE_TO_ONE.isAllowDoubleBooking
                ? ONE_TO_ONE.overlapping_time
                : "",
              doubleBookingServices: ONE_TO_ONE?.services
                ?.filter(
                  ({ isDoubleBooking, isAvailable }) =>
                    isDoubleBooking === 1 && isAvailable === 1
                )
                .map(({ service }: any) => service.id),

              allowBookingBefore: ONE_TO_ONE.isAllowLastMinuteBooking
                ? ONE_TO_ONE.allowBookingBefore
                : "",
              isAllowLastMinuteBooking: ONE_TO_ONE.isAllowLastMinuteBooking,
              days: ONE_TO_ONE.days,
            };
            // console.log(timing);
            state.schedule.one_to_one = timing;
          } else if (data[index].type === scheduleType.MULTI_TO_MULTI) {
            state.schedule.multi_to_multi.push(data[index]);
          }
        }

        state.status = SUCCESS;
      } else {
        state.status = FAIL;
      }
    });
    builder.addCase(fetchSchedule.rejected, (state, action) => {
      state.status = FAIL;
    });

    builder.addCase(createNewAppointment.pending, (state, action) => {
      state.status = LOADING;
    });
    builder.addCase(createNewAppointment.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      if (success && code === 200) {
        state.pendingList.push(data);
        state.pendingList = makeAppointmentList(
          state.pendingList
        ).appointmentList;
        state.status = SUCCESS;
      } else {
        state.status = FAIL;
      }
    });
    builder.addCase(createNewAppointment.rejected, (state, action) => {
      state.status = FAIL;
    });
  },
});

export const {
  calculateAvailableSlots,
  resetAvailableSlots,
  updateImageModalStatus,
  addAppointmentMedia,
} = appointmentSlice.actions;

export default appointmentSlice.reducer;

// export const credentialsSelector = (state: RootState) =>
//   state.credential.credentials;

export const statusSelector = (state: RootState) => state.appointment.status;
export const pendingAppStatus = (state: RootState) =>
  state.appointment.pendingAppStatus;
export const appointmentPendingListSelector = (state: RootState) =>
  state.appointment.pendingList;

export const scheduleSelector = (state: RootState) =>
  state.appointment.schedule;

export const availableSlotsSelector = (state: RootState) =>
  state.appointment.availableSlots;
export const appointmentPaginationSelector = (state: RootState) =>
  state.appointment.pagination;

export const uploadImageStatusSelector = (state: RootState) =>
  state.appointment.uploadImageStatus;

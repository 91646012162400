import { useEffect } from "react";

// Updates the height of a <textarea> when the value changes.
const useAutosizeTextArea = (
  ref: {
    textAreaRef: HTMLTextAreaElement | null;
    buttonRef: HTMLButtonElement | null;
  },
  value: string
) => {
  useEffect(() => {
    if (ref.textAreaRef && ref.buttonRef) {
      // We need to reset the height momentarily to get the correct scrollHeight for the textarea
      ref.textAreaRef.style.height = "0px";
      const scrollHeight = ref.textAreaRef.scrollHeight;

      // We then set the height directly, outside of the render loop
      // Trying to set this with state or a ref will product an incorrect value.
      ref.textAreaRef.style.height = scrollHeight + "px";
      ref.buttonRef.style.height = scrollHeight + "px";
    }
  }, [ref.textAreaRef, value]);
};

export default useAutosizeTextArea;

import { combineReducers } from "redux";
import authReducer from "../reducers/authReducer";
import serviceReducer from "./serviceReducer";
import scheduleReducer from "./scheduleReducer";
import mediaReducer from "./mediaReducer";
import credentialsReducer from "./credentialsReducer";
import clientReducer from "./clientReducer";
import paymentReducer from "./paymentReducer";
import appointmentReducer from "./appointmentReducer";
import chatReducer from "./chatReducer";
import { DESTROY_SESSION } from "./actions";
import applicationReducer from "./applicationReducer";
import notificationReducer from "./notificationReducer";

const appReducer = combineReducers({
  appointment: appointmentReducer,
  auth: authReducer,
  app: applicationReducer,
  chat: chatReducer,
  client: clientReducer,
  credential: credentialsReducer,
  payment: paymentReducer,
  media: mediaReducer,
  schedule: scheduleReducer,
  service: serviceReducer,
  notification: notificationReducer,
});

const rootReducer = (state, action) => {
  if (action.type === DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};

export default rootReducer;

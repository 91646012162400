const PROVIDER_URL_BASE = "provider";



const ALLOW_DOCS =
  ".pdf,.doc,.docx,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document";

const baseUrl = {
  api: process.env.REACT_APP_SERVER_URL,
};
const PAYMENT_URL = `${baseUrl.api}/payment-collector/provider/`;

export { ALLOW_DOCS, PAYMENT_URL, baseUrl };

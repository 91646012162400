// import AppRoutes from "./routes";
import React, { useEffect } from "react";
// import "./assets/styles/index.css";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import echo from "./hooks/useWebSockets";
import { getAuth } from "./utils/AuthHelpers";
function App() {
  React.useEffect(() => {
    echo.connect();
    return () => {
      echo.disconnect();
    };
  }, []);

  const auth = getAuth();

  useEffect(() => {
    echo.connector.options.auth.headers.Authorization = `Bearer ${auth?.access_token}`;
    echo.options.auth.headers.Authorization = `Bearer ${auth?.access_token}`;
  }, [auth]);

  return (
    <Suspense fallback={<h3>Loading ...</h3>}>
      <Outlet />
    </Suspense>
  );
}

export default App;
// <div>

//   {/* <AppRoutes /> */}
// </div>

import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";

export const getBookedAppointmentListRequest = (data: any) =>
  client.post(provider_URLS.appointment.LIST_BOOKING, data);

export const changePendingAppointmentStatusRequest = async (data: any) =>
  client.post(provider_URLS.appointment.LIST_PENDING_STATUS, data);

export const rescheduleAppointment = async (data: any) =>
  client.post(provider_URLS.appointment.RESCHEDULE_APPOINTMENT, data);

export const getAppointmentNote = async (id: any) => {
  try {
    return await client.get(provider_URLS.appointment.APPOINTMENT_NOTES(id));
  } catch (error) {
    return error;
  }
};

export const addPhoto = (data: any, onUploadProgress: any) =>
  client.post(provider_URLS.appointment.ADD_PHOTO, data, { onUploadProgress });

export const getPaginatedAppointmentListRequest = async (data: {
  data: any;
}) => {
  try {
    return await client.post(provider_URLS.appointment.LIST_PENDINGv2, data);
  } catch (error) {
    return error;
  }
};

export const deletePhoto = async (id: any) => {
  return client.delete(provider_URLS.appointment.DELETE_MEDIA(id));
};

export const addAppointmentNote = async (data: any) => {
  client.post(provider_URLS.appointment.ADD_APPOINTMENT_NOTES, data);
};

export const UpdateNotes = async (id: any, data: any) => {
  client.put(provider_URLS.appointment.UPDATE_APPOINTMENT_NOTES(id), data);
};

export const createAppointment = (data: any) =>
  client.post(provider_URLS.appointment.CREATE_APPOINTMENT, data);

export const getCurrentSlotsRequest = async ({
  servicesId,
  date,
  providerId,
}: {
  date: string;
  providerId: string;
  servicesId: string;
}) => {
  try {
    return await client.get(provider_URLS.appointment.GET_SLOTS, {
      params: {
        servicesId: servicesId,
        date: date,
        providerId: providerId,
      },
    });
  } catch (error) {
    return error;
  }
};

// export const updateCredentialsRequest = async (id: number, data: any) => {
//   try {
//     return await client.put(
//       provider_URLS.credentials.CREDENTIALS_SINGLE(id),
//       data
//     );
//   } catch (error) {
//     return error;
//   }
// };

// export const deleteCredentialsRequest = async (id: number) => {
//   try {
//     return await client.delete(
//       provider_URLS.credentials.CREDENTIALS_SINGLE(id)
//     );
//   } catch (error) {
//     return error;
//   }
// };

import React from "react";
import { useSelector } from "react-redux";
import Modal from "../../../../components/common/Modal";
import { IMAGE, VIDEO } from "../../../../config/constant";
import { uploadMediaStatusSelector } from "../../../../reducers/mediaReducer";
import MediaSkeletonLoading, { MediaSkeleton } from "./MediaSkeletonLoading";
import { ViewMediaModal } from "./ViewImgModal";

const MediaCard: React.FC<{
  mediaType: string;
  thumbnail: string;
  url: string;
  services: Array<any>;
  isLoading: boolean;
  idx?: number
  setItem?: any
  setIsOpen?: any
}> = ({ mediaType, thumbnail, url, services, isLoading, idx, setItem, setIsOpen }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const imgStyle = {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const uploadStatusData = useSelector(uploadMediaStatusSelector);
  return (
    <>
      <>
        {isLoading ? (
          <MediaSkeleton />
        ) : mediaType.includes("video") ? (
          <div
            onClick={() => {
              setIsOpen(true);
              setItem(idx);
            }}
            className="wh-140 rounded-5 d-flex align-items-center justify-content-center pointer border"
            // i'm commenting the style for development turn it back on
            style={{ backgroundImage: `url(${thumbnail})`, ...imgStyle }}
          // style={{ backgroundImage: `url(${url})` }}
          >
            <img
              height={60}
              src="/images/Media/video-play.svg"
              alt="avatar"
              className="mt-4"
            />
          </div>
        ) : (
          <img
            onClick={() => {
              setIsOpen(true);
              setItem(idx);
            }}
            src={thumbnail}
            style={{ objectFit: 'cover' }}
            alt="media"
            className="wh-140 rounded-5 border pointer"
          />
        )}
        {/* <Modal handleClose={handleClose} open={open}>
          <ViewMediaModal
            mediaType={mediaType}
            mediaUrl={url}
            handleClose={handleClose}
            services={services}
          />
        </Modal> */}
      </>
    </>
  );
};

export default MediaCard;

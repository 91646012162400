import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";
import { urlParameter } from "../../../components/common/types";

const fetchServiceByParentRequest = async (id: urlParameter) => {
  try {
    return await client.get(provider_URLS.services.getOneByParent(id));
  } catch (error) {
    return error;
  }
};

const fetchThirdLevelServiceRequest = async (id: urlParameter) => {
  try {
    return await client.get(provider_URLS.services.getThirdLevel(id));
  } catch (error) {
    return error;
  }
};

const fetchFullRootServiceRequest = async (token: any) => {
  try {
    return await client.get(provider_URLS.services.getFullRoot, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return error;
  }
};

const fetchRootServiceRequest = async () => {
  try {
    return await client.get(provider_URLS.services.getRoot);
  } catch (error) {
    return error;
  }
};

const fetchOwnServiceRequest = async () => {
  try {
    return await client.get(provider_URLS.services.getOwnAll);
  } catch (error) {
    return error;
  }
};

const createServiceRequest = async (data: any) =>
  client.post(provider_URLS.services.CREATE, data);

const updateServiceRequest = async (data: any) =>
  client.post(provider_URLS.services.update, data);

const updateServiceStatusRequest = async (data: any) =>
  client.put(provider_URLS.services.updateStatus, data);

export {
  createServiceRequest,
  fetchServiceByParentRequest,
  fetchFullRootServiceRequest,
  fetchRootServiceRequest,
  fetchThirdLevelServiceRequest,
  updateServiceRequest,
  fetchOwnServiceRequest,
  updateServiceStatusRequest,
};

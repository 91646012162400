import React, { useLayoutEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { MINUTE } from "../../../../../config/constant";
import { AppDispatch } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  scheduleMultiToMultiReadOnlySelector,
  updateMultiToMulti,
} from "../../../../../reducers/scheduleReducer";
import { ScheduleAllowLastMintBookingTypes } from "../../type";
import { convertMinIntoHoursAndMin } from "../../../../../utils";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const AllowLastAccordion = ({ booking, before, buffer }) => {
  const dispatch = useDispatch<AppDispatch>();

  const readOnlyData = useSelector(scheduleMultiToMultiReadOnlySelector);

  const handleChange = (e: any) => {
    if (e.target.value.length <= 3) {
      dispatch(
        updateMultiToMulti({ key: e.target.name, value: e.target.value })
      );
    }
  };

  const handleToggleChange = (e: any) => {
    dispatch(
      updateMultiToMulti({
        key: e.target.name,
        value: e.target.checked ? 1 : 0,
      })
    );
    dispatch(
      updateMultiToMulti({
        key: "isLastMinutesBooking",
        value: e.target.checked ? 1 : 0,
      })
    );
  };

  const serviceAvailableInfo = () => ("This will allow last minute \n booking by your clients.  Set \n a time buffer that allows \n bookings up to a time before \n the booking time slot.  IF this \n is not enabled bookings will \n need to have been made 24 \n hours before time slot")

  return (
    <div className="accordion mb-3" id="accordionExample5">
      <div
        className={`accordion-item ${booking === 1
          ? before === "" ||
            before === "0" ||
            before === "00" ||
            before === "000" ||
            before === "0" ||
            before === null
            // ||
            // buffer === "" ||
            // buffer === "0" ||
            // buffer === "00" ||
            // buffer === "000" ||
            // buffer === "0" ||
            // buffer === null
            ? "border-danger"
            : null
          : null
          }`}
      >
        <h2 className="accordion-header" id="headingOne">
          <button
            style={{ backgroundColor: "#f9fafb" }}
            className={`accordion-button`}
            type="button"
          >
            <div className="row w-100 align-items-center">
              <div className="col-8">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3 pointer"
                    height={22}
                    src="/images/schedule/lastMinute.svg"
                    alt=""
                  />
                  <div>
                    <p className="fw-light mb-0 text-dark">
                      Allow Last Minute Booking
                    </p>
                    {booking ? (
                      <p
                        style={{ fontSize: "12px" }}
                        className="fw-light my-0 text-secondary"
                      >
                        Booking Upto:{" "}
                        {convertMinIntoHoursAndMin(
                          readOnlyData.allowBookingBefore
                        )}
                        {/* <FiberManualRecordIcon className="ms-2 me-1" sx={{ fontSize: 9, color: '#94A3B8' }} /> Booking
                        Buffer:{" "}
                        {convertMinIntoHoursAndMin(
                          readOnlyData?.afterAppointmentBuffer
                        )} */}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex align-items-center justify-content-end actions">
                  <BootstrapTooltip placement="top" title="This will allow last minute booking by your clients.  Set a time buffer that allows bookings up to a time before the booking time slot.  IF this is not enabled bookings will need to have been made 24 hours before time slot">
                    <img
                      id="allowLastMulti"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="isLastMinutesBooking"
                      onChange={handleToggleChange}
                      checked={booking}
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse5"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapse5"
          className={`accordion-collapse collapse  ${booking === 1
            ? before === "" ||
              before === null ||
              buffer === null ||
              buffer === ""
              ? "show"
              : "show"
            : "collapsed"
            }`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample5"
        >
          <div className="accordion-body px-2 py-4">
            <div className="row w-100 m-auto px-0 rounded-5">
              <div className="col-md-4">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Allow booking upto:
                </p>

                <div className="input-group">
                  <input
                    style={{ fontSize: "15px" }}
                    type="number"
                    max="999"
                    step="15"
                    min="1"
                    className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control border-end-0 ${before === "0" || before === "00" || before === "000"
                      ? "is-invalid"
                      : ""
                      }`}
                    placeholder="Enter Time"
                    name="allowBookingBefore"
                    onKeyPress={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e" ||
                        e.key === "E"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    value={`${before}`}
                    id="Text1"
                  />
                  <span
                    className="input-group-text px-md-2 px-1"
                    id="basic-addon1"
                  >
                    {MINUTE}
                  </span>
                </div>
                {before === "0" || before === "00" || before === "000" ? (
                  <p style={{ fontSize: "12px" }} className="mb-0 text-danger">
                    Allow Booking Upto can not be 0
                  </p>
                ) : null}
              </div>
              {/* <div className="col-md-4">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  After appointment buffer:
                </p>

                <div className="input-group">
                  <input
                    style={{ fontSize: "15px" }}
                    type="number"
                    max="999"
                    step="15"
                    min="1"
                    className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control border-end-0 ${buffer === "0" || buffer === "00" || buffer === "000"
                      ? "is-invalid"
                      : ""
                      }`}
                    placeholder="Enter Time"
                    name="afterAppointmentBuffer"
                    onKeyPress={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e" ||
                        e.key === "E"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    value={`${buffer}`}
                    id="Text1"
                  />

                  <span
                    className="input-group-text px-md-2 px-1"
                    id="basic-addon1"
                  >
                    {MINUTE}
                  </span>
                </div>
                {buffer === "0" || buffer === "00" || buffer === "000" ? (
                  <p style={{ fontSize: "12px" }} className="mb-0 text-danger">
                    After Appoint Buffer can not be 0
                  </p>
                ) : null}
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowLastAccordion;

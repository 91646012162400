import React from 'react'
import { Skeleton } from "@mui/material";

interface props {
    clienProfile?: boolean
}

const ProfileLoading = ({ clienProfile }: props) => {
    return (
        <>
            <div className="d-flex align-items-start justify-content-between pb-2 mb-5 mx-2">
                <div className="row align-items-start justify-content-start">
                    <div className={`${clienProfile ? "col-2" : "col-3"}`}>
                        <Skeleton variant="circular" height={clienProfile ? 54 : 88} width={clienProfile ? 54 : 89} />
                    </div>
                    <div className="col-9 mt-2">
                        <p
                            className="mb-0 ms-4"
                        >
                            <Skeleton className='mb-2' width={100} animation="pulse" />
                        </p>
                        <p className="mb-0 ms-4">
                            <Skeleton width={100} animation="pulse" />
                        </p>
                        {clienProfile ? <></> :
                            <div className="ms-4 ps-1 d-flex mt-2">
                                {["1", "2", "3", "4", "5"].map((DATA) => (
                                    <Skeleton style={{ marginLeft: "-8px", }} className='me-2' variant="circular" height={32} width={32} />
                                ))}
                            </div>}
                        <div className="d-flex mt-3 ms-4">
                            <Skeleton className='me-2' width={80} height={50} animation="pulse" />
                            <Skeleton width={80} height={50} animation="pulse" />
                        </div>
                    </div>
                </div>
                <div className="d-flex mt-2 flex-column">
                    <Skeleton width={80} height={50} animation="pulse" />
                    {clienProfile ? <></> : <>

                        <p className="mt-2 mb-1">
                            <Skeleton width={80} animation="pulse" />
                        </p>
                        <p>
                            <Skeleton width={80} animation="pulse" />
                        </p>
                    </>
                    }
                </div>
            </div>
        </>
    )
}

export default ProfileLoading
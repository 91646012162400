import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exploreMediaSelector,
  getMediaData,
  resetFilters,
  searchByServiceName,
  statusSelector,
} from "../../../../reducers/mediaReducer";
import { AppDispatch } from "../../../../store";
import MasonaryLoading from "./MasonaryLoading";
import MasonryLayout from "./MasonryLayout";
import useCoordinates from "../../../../hooks/useRandomCordinates";
import { FilterType } from "../types";
import { client } from "../../../../api/client";
import Filters from "./Filters";

const ExploreMedia = () => {
  const exploreMediaData = useSelector(exploreMediaSelector);

  const [filters, setFilters] = React.useState<FilterType>({
    searchByFav: 0,
    isFav: false,
    isStar: false,
    serviceId: 0,
    miles: 0,
    latitude: 0,
    longitude: 0,
    isVic: 0,
    isMine: 1,
    myData: true,
    serviceName: "",
  });

  const getMedia = async (url: string) => {
    const res: any = await client.get(url, {
      params: {
        ...filters,
        perPage: 20,
      },
    });
    if (res.data.success) {
      dispatch(getMediaData(res.data));
    }
  };

  const dispatch = useDispatch<AppDispatch>();

  const [coordinates, generateCoordinates] = useCoordinates();

  useEffect(() => {
    generateCoordinates();
    return () => {
      dispatch(resetFilters());
    };
  }, []);

  const exploreStatus = useSelector(statusSelector);

  return (
    <>
      <div className="row mx-auto mt-2 px-2 py-3 border-bottom align-items-center">
        <div className="col-xl-3 col-12">
          <div className="d-flex flex-sm-row flex-column justify-content-between">
            <h5
              style={{ color: "#3a4758" }}
              className="main-head me-sm-5 mb-xl-0 mb-3"
            >
              My portfolio
            </h5>
            {/* <form onSubmit={clickSearchHandle} className="d-flex">
              <input
                type="search"
                onChange={searchFieldHandle}
                id="form1"
                value={search}
                className="form-control explore-search border-end-0 d-lg-none d-block"
                placeholder="Search here..."
              />
              <button
                type="submit"
                className="btn btn-outline-secondary border border-search d-lg-none d-block"
              >
                Search
              </button>
            </form> */}
          </div>
        </div>
        <Filters
          coordinates={coordinates}
          setFilters={setFilters}
          filters={filters}
        />
        <h6 style={{ fontSize: "16px" }} className="mb-2 mt-3">
          {exploreMediaData?.length} record found
        </h6>

        {exploreStatus === "loading" ? (
          <MasonaryLoading />
        ) : (
          <>
            <MasonryLayout
              mediaType={"VIDEOs"}
              getMedia={getMedia}
              filters={filters}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ExploreMedia;

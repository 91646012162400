import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { deleteCredential } from "../../../../../reducers/credentialsReducer";
import ConfModal from "../../../../../components/common/ConfModal";
import DeletePrompt from "../../../../../components/common/DeletePrompt";
import { useState } from "react";

type MoreDropdownPropTypes = {
  modalHandle?(): any;
  open?: any;
};

const MoreDropdown = ({ modalHandle, open, id }: any) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleOpen = () => setDeleteModalOpen(true);
  const handleClose = () => setDeleteModalOpen(false);

  return (
    <div className="dropdown">
      <div
        id="dropdownMenuButton2"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        data-mdb-toggle="tooltip"
        title="Add Media"
        className="bg-mask me-lg-3 me-lg-2 me-1 bg-white pointer"
      >
        <img
          height={35}
          width={35}
          src="/images/credential/more.svg"
          alt="more"
          className=""
        />
      </div>
      <ul
        style={{ borderRadius: "10px", width: "150px" }}
        className="dropdown-menu dropdown-menu-light py-2 px-0"
        aria-labelledby="dropdownMenuButton2"
      >
        <li className="d-flex align-items-center my-2">
          <span
            className="dropdown-item pointer"
            onClick={() => modalHandle(true)}
          >
            <img
              src="/images/credential/edit.svg"
              alt="Edit"
              className="me-2"
            />
            Edit
          </span>
        </li>

        <li className="d-flex align-items-center my-2">
          <span onClick={handleOpen} className="dropdown-item pointer">
            <img
              src="/images/credential/delete.svg"
              alt="Delete"
              className="me-2"
            />
            Delete
          </span>
        </li>
      </ul>
      <ConfModal handleClose={handleClose} open={deleteModalOpen}>
        <DeletePrompt
          handleClose={handleClose}
          deleteFuntion={deleteCredential(id)}
          textToShow={"Deleting Credential"}
        />
      </ConfModal>
    </div>
  );
};

export default MoreDropdown;

import React from "react";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  appointmentMediaPrivacyAction,
  deleteMediaAction,
  mediaLikeAction,
  privacyStatusSelector,
} from "../../reducers/mediaReducer";
import { AppDispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import ConfModal from "./ConfModal";
import DeletePrompt from "./DeletePrompt";
import { loggedInUserProfile } from "../../reducers/authReducer";

const UserProfileFooter = ({ item }) => {
  const [like, setLike] = React.useState({
    isFav: item?.isFav,
    mediaLikeCounts: item?.mediaLikeCounts,
  });

  const loggedInUser = useSelector(loggedInUserProfile);
  const privacyStatus = useSelector(privacyStatusSelector);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch<AppDispatch>();

  const updatePrivacy = (data) => {
    const finalData = {
      mediaId: item?.id,
      isPrivate: data,
    };
    dispatch(appointmentMediaPrivacyAction(finalData));
  };

  return (
    <div className="mx-4">
      <div className="row justify-content-center">
        <div className="col-6 m-auto justify-content-center d-flex">
          <label
            style={{ fontSize: "16px" }}
            htmlFor="servicename"
            className="mt-3 text-white"
          >
            <span className="mx-1">{item?.caption}</span>
          </label>
        </div>
        <div className="d-flex col-12 my-auto gx-3 flex-wrap ms-1 mt-1 gy-3 justify-content-center mb-2">
          {item?.tags?.map(
            (
              tag: {
                name:
                  | string
                  | number
                  | boolean
                  | React.ReactElement<
                      any,
                      string | React.JSXElementConstructor<any>
                    >
                  | React.ReactFragment
                  | React.ReactPortal;
                service: {
                  name: string;
                };
              },
              idx: React.Key
            ) => (
              <div
                key={idx}
                style={{
                  backgroundColor: "#04bac7",
                  fontSize: "13px",
                  borderRadius: "6px",
                  marginTop: "4px",
                }}
                className={`py-1 px-2 me-1 rounded-pill text-dark`}
              >
                {tag?.service ? tag?.service?.name : tag?.name}
              </div>
            )
          )}
        </div>
      </div>
      <div className={`row w-100 m-auto ${true ? "mt-0" : "mt-2"}`}>
        <div className="col-6 mx-auto py-2 justify-content-center d-flex">
          <div className="d-flex align-items-center justify-content-center mx-4 pointer">
            {like?.isFav ? (
              <FavoriteIcon
                onClick={() => {
                  dispatch(
                    mediaLikeAction({
                      mediaId: item.id,
                      isFav: 0,
                    })
                  );
                  setLike({
                    ...like,
                    mediaLikeCounts: like.mediaLikeCounts - 1,
                    isFav: 0,
                  });
                }}
                sx={{ color: "red" }}
              />
            ) : (
              <FavoriteBorderIcon
                onClick={() => {
                  dispatch(
                    mediaLikeAction({
                      mediaId: item.id,
                      isFav: 1,
                    })
                  );
                  setLike({
                    ...like,
                    isFav: 1,
                    mediaLikeCounts: like.mediaLikeCounts + 1,
                  });
                }}
              />
            )}
            <p style={{ fontSize: "16px" }} className="my-0 ms-2">
              {item?.mediaLikeCounts} Likes
            </p>
          </div>
          {loggedInUser?.id === item?.profile?.id ? (
            <>
              <div
                className="d-flex align-items-center justify-content-center mx-4 pointer"
                onClick={handleOpen}
              >
                <DeleteIcon />
                <p style={{ fontSize: "16px" }} className="my-0 ms-2">
                  Delete
                </p>
              </div>
              {item?.appointment ? (
                privacyStatus !== "loading" ? (
                  <div
                    className="d-flex align-items-center justify-content-center mx-4 pointer"
                    // onClick={handleOpen}
                  >
                    <input
                      type="checkbox"
                      name=""
                      checked={Boolean(Number(item?.isPrivate))}
                      disabled={Boolean(Number(item?.isClientPrivate))}
                      className="form-check-input mt-0"
                      onChange={(e) =>
                        updatePrivacy(Number(Boolean(e.target.checked)))
                      }
                      id=""
                    />
                    <label className="form-label my-0 ms-2" htmlFor="">
                      Private
                    </label>
                  </div>
                ) : (
                  <div
                    className="d-flex justify-content-center align-items-center gap-1"
                    // style={{ padding: "5.5px 0" }}
                  >
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                    <div>
                      <span
                        style={{
                          visibility: "hidden",
                        }}
                      >
                        Loading...
                      </span>
                    </div>
                  </div>
                )
              ) : null}
            </>
          ) : null}
          <ConfModal handleClose={handleClose} open={open}>
            <DeletePrompt
              handleClose={item.open}
              deleteFuntion={deleteMediaAction(item?.id)}
              textToShow={"Deleting Media"}
            />
          </ConfModal>
        </div>
      </div>
    </div>
  );
};

export default UserProfileFooter;

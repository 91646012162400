import React from "react";
import ModalC from "../../../../components/common/Modal";
import { AddCredentialModal } from "./AddCredentialModal";

const UploadCredential = ({ data }: { data?: any }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="row mt-3">
      <div className="col-4 m-auto px-4 py-5 text-center">
        <label>
          <img
            src="/images/Media/UploadImg.svg"
            alt="Upload"
            // className="pointer"
          />

          <h6 className="mb-0 fw-bold mt-3 mb-1">No Credentials Added</h6>
          <p
            style={{ fontSize: "14px", color: "#667085" }}
            className="mb-0 text-grey"
          >
            You can add images from you favorite social media platforms e.g.
            Facebook, Instagram, Twitter etc.
          </p>
        </label>
        <button
          style={{ fontSize: "13px" }}
          className="btn btn-primary w-100 mt-3"
          onClick={handleOpen}
        >
          <img
            height={20}
            src="/images/Media/upload-cloud.svg"
            alt="Upload"
            className="mx-2"
          />
          ADD CREDENTIALS
        </button>
      </div>
      <ModalC handleClose={handleClose} open={open}>
        <AddCredentialModal
          handleClose={handleClose}
          isEdit={false}
          data={{
            title: "",
            image: "",
            type: "",
            issueDate: "",
            expiryDate: "",
            isNotExpiry: 0,
            link: "",
          }}
          index={null}
        />
      </ModalC>
    </div>
  );
};

export default UploadCredential;

import { Route, Routes, Navigate } from "react-router-dom";
// pages

import ProviderRegistration from "../pages/provider/auth/Registration";
import LoginProvider from "../pages/provider/auth/Login";
import ForgotPassProvider from "../pages/provider/auth/ForgotPass";
import VerificationCodeProvider from "../pages/provider/auth/VerificationCode";
import UserProfileProvider from "../pages/provider/auth/UserProfile";
import ResetPassword from "../pages/provider/auth/ResetPassword";
import VerifyResetCode from "../pages/provider/auth/VerifyResetCode";

const AuthRoutes = () => {
  return (
    <Routes>
      {/* Redirect to Dashboard after success login/registartion */}
      <Route path="auth/*" element={<Navigate to="/" />} />
      {/* Pages */}

      {/* provider pages */}
      <Route path="/register" element={<ProviderRegistration />} />
      <Route path="/login" element={<LoginProvider />} />
      <Route path="/forgot" element={<ForgotPassProvider />} />
      <Route path="/verify-code" element={<VerificationCodeProvider />} />
      <Route path="/profile" element={<UserProfileProvider />} />
      <Route path="/resetPass" element={<ResetPassword />} />
      <Route path="/verifyResetPass" element={<VerifyResetCode />} />

      {/* Page Not Found */}
      <Route path="*" element={<h3>No Route found</h3>} />
    </Routes>
  );
};

export { AuthRoutes };

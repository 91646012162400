import React from "react";
import { useSelector } from "react-redux";
import { PaymentRecord } from "../../../../reducers/paymentReducer";

const StepIndication = ({
  setChangeStep,
}: {
  setChangeStep(string): void;
}): JSX.Element => {
  const record = useSelector(PaymentRecord);

  const Steps = [
    {
      id: 1,
      name: "Business Info",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting",
      isComplete: record ? (record?.dbaName !== null ? true : false) : false,
    },
    {
      id: 2,
      name: "Bank Info",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting",
      isComplete: record
        ? record?.depositAccountBankName
          ? true
          : false
        : false,
    },
    {
      id: 3,
      name: "Signer info",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting",
      isComplete: record ? (record?.singerZip !== null ? true : false) : false,
    },
    // {
    //   id: 4,
    //   name: "Banking Info",
    //   description:
    //     "Lorem Ipsum is simply dummy text of the printing and typesetting",
    //   isComplete: record
    //     ? record?.bankAccountName !== null
    //       ? true
    //       : false
    //     : false,
    // },
  ];
  return (
    <div style={{ maxWidth: "700px" }} className="my-4 mx-auto">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 px-0 text-center"
      >
        <div className="px-4 py-3 border-bottom">
          <div>
            <h4 className="mb-0 text-start">Payments</h4>
          </div>
        </div>
        <div className="p-4 text-start">
          {Steps?.map(({ id, name, description, isComplete }) => (
            <>
              <div className="d-flex align-items-center">
                {!isComplete ? (
                  <div
                    style={{
                      height: "45px",
                      width: "45px",
                      fontSize: "17px",
                    }}
                    className={`bg-light-grey rounded-circle p-1 me-md-3 me-2 d-flex align-items-center justify-content-center pointer w-days`}
                  >
                    <p className={`m-0 p-0 text-secondary`}>{id}</p>
                  </div>
                ) : (
                  <img
                    height={45}
                    width={45}
                    className="me-md-3 me-2"
                    src="/images/payment/completedStep.png"
                    alt="no icon"
                  />
                )}
                <p className={`mb-0 text-dark`} style={{ fontSize: "14px" }}>
                  {name}
                </p>
              </div>

              {id !== 3 ? (
                <img
                  className="my-2"
                  style={{ marginLeft: "1.3rem" }}
                  src="/images/payment/seperator.png"
                  alt="no icon"
                />
              ) : null}
            </>
          ))}
        </div>
        <button
          onClick={() => setChangeStep("Step1")}
          style={{ fontSize: "13px" }}
          className="btn btn-primary mb-4"
        >
          Get Started
        </button>
      </div>
    </div>
  );
};

export default StepIndication;

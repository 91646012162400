import { initializeApp } from "firebase/app";
import {
  DocumentData,
  DocumentReference,
  getFirestore,
} from "firebase/firestore";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

import { getStorage } from "firebase/storage";
import { generateTimeStamp } from "../../utils";

const firebaseConfig = {
  // Your Firebase project configuration
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APPID,
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

// Get a messaging instance
const messaging = getMessaging(app);

export const COLLECTION = {
  USER: "Users",
  MESSAGE: "Messages",
  CONVERSATION: "Conversations",
};
const MAIN_DIR = "chat-media";
export const STORAGE = {
  IMAGE: `/image`,
  VIDEO: `/video`,
  AUDIO: `/audio`,
  DOC: `/doc`,
};

// ***************************************************************** user *****************************************************************

export interface UsersFirebase {
  userId: any;
  name: string;
  email: string;
  avatarUrl: string;
  mobileNo: number | string;
  online: boolean;
}

export type singleRef = DocumentReference<DocumentData>;

export interface Conversations {
  id?: string;
  lastMessage: singleRef;
  participants: singleRef[];
}

export enum MessageType {
  "text",
  "image",
  "video",
  "audio",
  "document",
  "emoji",
}
export interface Message {
  id: string;
  conversationId: any;
  senderUid: any;
  recipientUid: any;
  messageType: MessageType;
  messageData: string;
  readBy: [] | null;
  createdAt: any;
  status?: string;
}

const utils = {
  formatDate: (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    const twelveHourFormat = hours % 12 || 12;
    // const month = date.toLocaleString("default", { month: "short" });
    // const day = date.getDate();
    // const year = date.getFullYear();

    const time = `${twelveHourFormat}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    // const formattedDate = `${month} ${day}, ${year}`;

    return { date, time };
  },

  createFilePAth: (conId: string, fileType: any) => {
    let path = "";
    switch (true) {
      case fileType.includes("image"):
        path = `${MAIN_DIR}/${conId}${STORAGE.IMAGE}/${generateTimeStamp()}`;
        break;
      case fileType.includes("video"):
        path = `${MAIN_DIR}/${conId}${STORAGE.VIDEO}/${generateTimeStamp()}`;
        break;
      case fileType.includes("audio"):
        path = `${MAIN_DIR}/${conId}${STORAGE.AUDIO}/${generateTimeStamp()}`;
        break;
      // case fileType.includes(ALLOW_DOCS):
      //   path = `${MAIN_DIR}/${conId}${STORAGE.DOC}/${generateTimeStamp()}`;
      //   break;
      default:
        path = `${MAIN_DIR}/${conId}${STORAGE.DOC}/${generateTimeStamp()}`;
        break;
    }

    return path;
  },
};

export default { storage, db, messaging, MessageType, utils };

import React from 'react'
import { Skeleton } from "@mui/material";

const CredentialLoading = () => {
    return (
        <>
            <div
                style={{
                    borderRadius: "6px",
                }}
                className={`d-flex p-3 mt-2 justify-content-between mb-3
                }`}
            >
                <div className="d-flex justify-content-center">
                    <Skeleton className='ms-2 me-3 border-b5' variant="rectangular" width={270} height={120} />
                    <div className="d-md-block d-none">
                        <p
                            className={`mb-0 fw-bold mb-1 text-primary mx-2`}
                            style={{ fontSize: "15px" }}
                        >
                            <Skeleton width={90} animation="pulse" />
                        </p>
                        <p style={{ color: "grey", fontSize: "13px" }} className="mb-0 mt-2 mx-2">
                            <Skeleton width={60} animation="pulse" />
                        </p>
                        <p
                            style={{ color: "grey", fontSize: "15px" }}
                            className="mb-0 mt-5 text-primary pointer mx-2"
                        >
                            <Skeleton width={400} animation="pulse" />
                        </p>
                    </div>
                </div>
            </div>
            <hr className='mx-4' />
        </>
    )
}

const CredentialCardLoading = () => {
    return (
        <>
            <CredentialLoading />
            <CredentialLoading />
            <CredentialLoading />
        </>
    )
}

export default CredentialCardLoading
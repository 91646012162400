import React from "react";

const ContactBox = () => {
  return (
    <>
      <div className="row mt-3">
        <div className="col-12">
          <div className="bg-white rounded border-3 p-4">
            <h6 style={{ fontSize: "16px" }} className="m-0 fw-bolder">
              Need help with booking your appointment?
            </h6>
            <p className="mt-2" style={{ fontSize: "14px" }}>
              Our experts are here to help you
            </p>
            <div className="row">
              <div className="col-lg-8 col-md-10 col-12 d-flex flex-sm-row flex-column">
                <div
                  style={{ fontSize: "14px" }}
                  className="border border-1 rounded rounded-3 w-50 px-3 py-2 me-2 align-items-center d-flex w-100"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#666666"
                    className="bi bi-telephone-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                    />
                  </svg>
                  +44 xxxx xxx xxx
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="border border-1 rounded rounded-3 w-50 px-3 py-2 me-2 align-items-center d-flex w-100 mt-sm-0 mt-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#666666"
                    className="bi bi-envelope-fill me-2"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                  </svg>
                  help@kazzah.com
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactBox;

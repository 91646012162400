import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  appointmentPendingListSelector,
  appointmentReschedule,
  availableSlotsSelector,
  getCurrentSlotsAction,
  resetAvailableSlots,
  scheduleSelector,
} from "../../../../reducers/appointmentReducer";
import { AppDispatch } from "../../../../store";
import { array_to_CSV, convert24To12 } from "../../../../utils";
import { TextField } from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import EventIcon from "@mui/icons-material/Event";
import SlotChip from "./SlotChip";
import { ID } from "../../../../components/common/Chips";
import { loggedInUserProfile } from "../../../../reducers/authReducer";

const EditRescheduleDrawer = ({ handleClose, id }) => {
  //   console.log(data);
  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );
  const [scheduleData, setScheduleData] = useState<any>();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const loggedInUser = useSelector(loggedInUserProfile);

  useEffect(() => {
    const filteredList = pendingAppointmentListData?.filter((f) => f?.id == id);
    // console.log(filteredList);

    setScheduleData({
      ...filteredList,
      appointmentId: filteredList?.[0]?.id,
      src: filteredList?.[0]?.client?.profileImage,
      clientId: filteredList?.[0]?.client?.id,
      services: filteredList?.[0]?.services?.map((m) => m?.service),
      date: filteredList?.[0]?.appointmentDate,
      tax: filteredList?.[0]?.tax,
      fee: parseInt(filteredList?.[0]?.totalPrice),
      totalPrice: parseInt(filteredList?.[0]?.totalPrice),
      discount: filteredList?.[0]?.discount,
      isReservationFee: filteredList?.[0]?.isReservationFee,
      startTime:
        filteredList?.[0]?.scheduleType === "multi-to-multi"
          ? filteredList?.[0]?.startTime
          : null,
      endTime:
        filteredList?.[0]?.scheduleType === "multi-to-multi"
          ? filteredList?.[0]?.endTime
          : null,
      reservationFee: filteredList?.[0]?.reservationFee,
      location: filteredList?.[0]?.appointmentAddress
        ? filteredList?.[0]?.appointmentAddress
        : loggedInUser?.street,
      name:
        filteredList?.[0]?.client?.firstName +
        " " +
        filteredList?.[0]?.client?.lastName,
      scheduleId: filteredList?.[0]?.scheduleId,
      scheduleType: filteredList?.[0]?.scheduleType,
    });
    // dispatch(
    //   fetchBookingByDateAction({
    //     date: filteredList?.[0]?.appointmentDate,
    //     services: filteredList?.[0]?.services?.map((m) => m?.service),
    //   })
    // );
    // console.log(scheduleData);

    return () => {
      dispatch(resetAvailableSlots());
    };
  }, [id, pendingAppointmentListData]);

  // console.log(scheduleData);

  // const convert12To24 = (time) => {
  //   const time24 = moment(time, "hh:mm A").format("HH:mm");
  //   return time24;
  // };

  const providerSchedule = useSelector(scheduleSelector);

  const datePicker = {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
    // border: "1px solid #ccc",
    // borderRadius: "4px",
    padding: "8px",
  };

  const currentDate = providerSchedule?.one_to_one?.isAllowLastMinuteBooking
    ? moment().format("YYYY-MM-DD")
    : moment().add(1, "days").format("YYYY-MM-DD");

  const availableSlotsDate = useSelector(availableSlotsSelector);

  let availableSlotsMemoDate = React.useMemo(
    () => availableSlotsDate,
    [availableSlotsDate]
  );

  useEffect(() => {
    return () => {
      dispatch(resetAvailableSlots());
    };
  }, []);

  // console.log(convert12To24("12:01 PM"));

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(appointmentReschedule({ data: scheduleData, handleClose }));
  };

  return (
    <div className="mt-5 pt-4">
      <div className="d-flex justify-content-between align-items-start pb-1 pt-3 ps-3 pe-4">
        <div
          className="d-flex align-items-start pointer"
          onClick={() => navigate(`/client/profile/${scheduleData?.clientId}`)}
        >
          <img
            src={scheduleData?.src ? scheduleData?.src : "/images/avatar2.png"}
            height={35}
            width={35}
            alt="arrow"
            className="me-2 rounded-circle"
          />
          <div>
            <p className={`mb-0`} style={{ fontSize: "20px" }}>
              {scheduleData?.name ? scheduleData?.name : "NA"}
            </p>
            <ID color="text.disabled" tooltip="Appointment ID">
              <span>
                {/* {"KZH23-"} */}
                {Number(scheduleData?.clientId)}
              </span>
            </ID>
          </div>
        </div>
        <img
          onClick={handleClose}
          src="/images/clientList/close.png"
          alt="arrow"
          className="pointer me-1"
        />
      </div>
      <hr />
      <div className="mt-3 ps-3 pe-4 mb-1">
        <div className="row g-3">
          <div className="col-6">
            {/* border-bottom border-3 border-primary */}
            <div
              style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
              className={`form-control rounded-b5 px-4 py-2 border-0`}
            >
              <p className={`mb-1 mt-1 text-dark`} style={{ fontSize: "16px" }}>
                $25.29
              </p>
              <p
                className={`mb-0 mt-1 fw-light`}
                style={{ fontSize: "14px", color: "#667085" }}
              >
                Amount
              </p>
            </div>
          </div>
          <div className="col-6">
            {/* border-bottom border-3 border-primary */}
            <div
              style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
              className={`form-control rounded-b5 px-4 py-2 border-0`}
            >
              <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                $0.00
              </p>
              <p
                className={`mb-0 mt-1 fw-light`}
                style={{ fontSize: "14px", color: "#667085" }}
              >
                Tip
              </p>
            </div>
          </div>
          {scheduleData?.isReservationFee === 1 ? (
            <div className="col-12">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p
                  className={`mb-1 mt-1 text-dark`}
                  style={{ fontSize: "16px" }}
                >
                  {scheduleData?.reservationFee
                    ? `$ ${scheduleData?.reservationFee}`
                    : "$0.00"}
                </p>
                <p
                  className={`mb-0 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  Reservation Fee
                </p>
              </div>
            </div>
          ) : null}
          <div className="col-12">
            <div
              style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
              className={`form-control rounded-b5 px-4 py-2 border-0`}
            >
              <p className={`mb-2 mt-1`} style={{ fontSize: "16px" }}>
                Services
              </p>
              <p
                className={`mb-2 mt-1 fw-light`}
                style={{ fontSize: "14px", color: "#667085" }}
              >
                <ul>
                  {scheduleData?.services?.map((m) => (
                    <li>{m?.name}</li>
                  ))}
                </ul>
              </p>
            </div>
          </div>
          <div className="col-12">
            {/* border-bottom border-3 border-primary */}
            <div
              style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
              className={`form-control rounded-b5 px-4 py-2 border-0`}
            >
              <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                Location
              </p>
              {scheduleData?.location ? (
                <p
                  className={`mb-2 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  {scheduleData?.location}
                </p>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="col-12">
            <div
              style={{ backgroundColor: "#F1F5F9" }}
              className="accordion-item rounded-b5 border border-white"
            >
              <h2 className="accordion-header" id="headingOne">
                <button
                  style={{ backgroundColor: "#F1F5F9" }}
                  className="accordion-button d-flex justify-content-between pointer px-0 border border-0"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <div className="row w-100 align-items-center m-auto pointer">
                    <div className="col ps-4">Booking</div>
                    <div className="col text-end">
                      <img
                        className="down-arrow"
                        src="/images/schedule/arrow-down.svg"
                        alt=""
                      />
                    </div>
                  </div>
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse collapsed"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  <div style={datePicker}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <MobileDatePicker
                        openTo="day"
                        minDate={currentDate}
                        disablePast={true}
                        views={["year", "month", "day"]}
                        value={scheduleData?.date ? scheduleData?.date : null}
                        onChange={(e) => {
                          setScheduleData({
                            ...scheduleData,
                            date: e,
                          });
                          const serviceIdsArray = scheduleData?.services?.map(
                            ({ id }) => id
                          );
                          if (scheduleData?.scheduleType !== "multi-to-multi") {
                            dispatch(
                              getCurrentSlotsAction({
                                date: e,
                                providerId: loggedInUser?.id,
                                servicesId: array_to_CSV(serviceIdsArray),
                              })
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #ccc",
                              borderRadius: "4px",
                            }}
                          >
                            <EventIcon style={{ margin: "0 8px" }} />
                            <TextField
                              variant="standard"
                              {...params}
                              error={false}
                            />
                          </div>
                        )}
                        renderDay={(day) => {
                          const dayOfWeek = moment(day).format("dddd");
                          let index;
                          if (scheduleData?.scheduleType === "multi-to-multi") {
                            index = providerSchedule?.multi_to_multi?.findIndex(
                              (data) => data.id === scheduleData?.scheduleId
                            );
                          }
                          const isBooked =
                            scheduleData?.scheduleType === "multi-to-multi"
                              ? providerSchedule?.multi_to_multi[
                                  index
                                ]?.days?.find(
                                  (item) => item.scheduleDays == dayOfWeek
                                )
                              : providerSchedule?.one_to_one?.days?.find(
                                  (item) => item.scheduleDays == dayOfWeek
                                );
                          // show these only for 6 months
                          const isWithin6Months = moment(day).isBefore(
                            moment().add(6, "months")
                          );
                          // disable past date
                          const isPastDate = moment(day).isBefore(currentDate);

                          return (
                            <div
                              style={{
                                display: "inline-flex",
                                alignItems: "center",
                                justifyContent: "center",
                                position: "relative",
                                boxSizing: "border-box",
                                outline: "0px",
                                border: "0px",
                                userSelect: "none",
                                verticalAlign: "middle",
                                appearance: "none",
                                textDecoration: "none",
                                fontFamily:
                                  "Roboto, Helvetica, Arial, sans-serif",
                                fontWeight: "400",
                                fontSize: "0.75rem",
                                lineHeight: "1.66",
                                letterSpacing: "0.03333em",
                                width: "36px",
                                height: "36px",
                                borderRadius: "50%",
                                padding: "0px",
                                color: "black",
                                margin: "0px 2px",
                                cursor:
                                  isPastDate || !isBooked || !isWithin6Months
                                    ? "not-allowed"
                                    : "pointer",
                              }}
                              onClick={() => {
                                if (
                                  isPastDate ||
                                  !isBooked ||
                                  !isWithin6Months
                                ) {
                                  return;
                                } else {
                                  setScheduleData({
                                    ...scheduleData,
                                    date: moment(day).format("YYYY-MM-DD"),
                                  });
                                  const serviceIdsArray =
                                    scheduleData?.services?.map(({ id }) => id);
                                  if (
                                    scheduleData?.scheduleType !==
                                    "multi-to-multi"
                                  ) {
                                    dispatch(
                                      getCurrentSlotsAction({
                                        date: moment(day).format("YYYY-MM-DD"),
                                        providerId: loggedInUser?.id,
                                        servicesId:
                                          array_to_CSV(serviceIdsArray),
                                      })
                                    );
                                  }
                                }
                              }}
                            >
                              {isWithin6Months ? (
                                scheduleData?.date ==
                                moment(day).format("YYYY-MM-DD") ? (
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      outline: "0px",
                                      border: "0px",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      textDecoration: "none",
                                      fontFamily:
                                        "Roboto, Helvetica, Arial, sans-serif",
                                      fontWeight: "400",
                                      fontSize: "0.75rem",
                                      lineHeight: "1.66",
                                      letterSpacing: "0.03333em",
                                      width: "36px",
                                      height: "36px",
                                      borderRadius: "50%",
                                      color: "white",
                                      backgroundColor: "#1565c0",
                                    }}
                                  >
                                    {moment(day).format("D")}
                                  </span>
                                ) : (
                                  <span
                                    style={{
                                      display: "inline-flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      position: "relative",
                                      boxSizing: "border-box",
                                      outline: "0px",
                                      border: "0px",
                                      userSelect: "none",
                                      verticalAlign: "middle",
                                      appearance: "none",
                                      textDecoration: "none",
                                      fontFamily:
                                        "Roboto, Helvetica, Arial, sans-serif",
                                      fontWeight: "400",
                                      fontSize: "0.75rem",
                                      lineHeight: "1.66",
                                      letterSpacing: "0.03333em",
                                      width: "36px",
                                      height: "36px",
                                      borderRadius: "8px",
                                      padding: "0px",
                                      margin: "0px 2px",
                                      backgroundColor: isBooked
                                        ? !isPastDate
                                          ? "#04bac7"
                                          : ""
                                        : "",
                                      color: isBooked
                                        ? !isPastDate
                                          ? "black"
                                          : "#d6cfcf"
                                        : "#d6cfcf",
                                    }}
                                  >
                                    {moment(day).format("D")}
                                  </span>
                                )
                              ) : (
                                <span
                                  style={{
                                    display: "inline-flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    position: "relative",
                                    boxSizing: "border-box",
                                    outline: "0px",
                                    border: "0px",
                                    userSelect: "none",
                                    verticalAlign: "middle",
                                    appearance: "none",
                                    textDecoration: "none",
                                    fontFamily:
                                      "Roboto, Helvetica, Arial, sans-serif",
                                    fontWeight: "400",
                                    fontSize: "0.75rem",
                                    lineHeight: "1.66",
                                    letterSpacing: "0.03333em",
                                    width: "36px",
                                    height: "36px",
                                    borderRadius: "8px",
                                    padding: "0px",
                                    margin: "0px 2px",
                                    backgroundColor: "",
                                    color: "#d6cfcf",
                                  }}
                                >
                                  {moment(day).format("D")}
                                </span>
                              )}
                            </div>
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  {scheduleData?.scheduleType !== "multi-to-multi" ? (
                    <>
                      <label
                        style={{ fontSize: "14px" }}
                        className="form-label mb-1 mt-3"
                      >
                        Time:
                      </label>
                      {availableSlotsMemoDate?.length === 0 ? (
                        <p className="text-danger">No slots available</p>
                      ) : (
                        availableSlotsMemoDate?.map(
                          (slot: { startTime: string; endTime: string }) => (
                            <SlotChip
                              startTime={slot.startTime}
                              endTime={slot.endTime}
                              setScheduleData={setScheduleData}
                              scheduleData={scheduleData}
                            />
                          )
                        )
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end mt-3">
          <button
            style={{ fontSize: "14px", borderRadius: "6px" }}
            className="btn btn-primary me-2"
            onClick={handleSubmit}
            disabled={!scheduleData?.startTime}
          >
            Reschedule
          </button>
          {/* <button
            style={{ fontSize: "14px", borderRadius: "6px" }}
            className="btn btn-outline-danger"
          >
            Decline
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default EditRescheduleDrawer;

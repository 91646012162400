import { useState } from "react";

import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  socialLinkSelector,
  updateSocialLinkArray,
  uploadOwnSocialMedia,
} from "../../../../reducers/mediaReducer";
import { xconsole } from "../../../../utils/console";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";

export const AddSocialModal = ({
  handleClose,
  link,
  idx,
}: {
  handleClose(): void;
  link?: any;
  idx: string | number;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const [error, setError] = useState(false);
  const [mobileError, setMobileError] = useState(true);

  const handleChange = ({
    userName,
    id,
  }: {
    userName: string;
    id: string | number;
  }) => {
    dispatch(updateSocialLinkArray({ id, userName }));
  };

  const submissionData = useSelector(socialLinkSelector);

  const [registrationData, setRegistrationData] = useState<any>({
    mobileNo: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const fData = { socials: submissionData };
    // console.dir({ socials: submissionData });
    dispatch(uploadOwnSocialMedia(fData));
    // console.dir(fData);
    // submissionData
    handleClose();
    // addOwnSocialMediaRequest(SOCIAL_LINKS);
  };

  return (
    <>
      <Typography
        id="modal-modal-title"
        className="text-capitalize mb-2"
        sx={{
          fontSize: "18px",
          fontWeight: "530",
        }}
        component="h6"
      >
        Add {link?.name}
      </Typography>
      <div className="form">
        <form className="php-email-form" onSubmit={handleSubmit}>
          <div className="form-group">
            {/* <label
              style={{ fontSize: "14px" }}
              htmlFor="duration"
              className="form-label"
            >
              {link.name} Profile Link
            </label> */}
            <div style={{ color: "#667085" }} className="input-group">
              {link.type === "http" ? (
                <span
                  style={{ color: "#667085" }}
                  className="input-group-text px-md-2 px-1"
                  id="basic-addon1"
                >
                  {link?.prefix}
                </span>
              ) : (
                <></>
              )}
              {link.type === "http" ? (
                <input
                  name="userName"
                  type="text"
                  className="form-control"
                  id="duration"
                  onChange={(e) =>
                    handleChange({ userName: e.target.value, id: idx })
                  }
                  defaultValue={link?.userName ? link?.userName : ""}
                  placeholder={`Enter your ${
                    link.type === "phone" ? link?.name : "username"
                  } ${link.type === "phone" ? "number" : ""}`}
                />
              ) : (
                <>
                  <PhoneInputWithCountrySelect
                    name="username"
                    onBlur={() => {
                      if (registrationData.mobileNo) {
                        setMobileError(
                          isValidPhoneNumber(
                            registrationData.mobileNo.toString()
                          )
                        );
                      }
                    }}
                    onChange={(e) => {
                      handleChange({ userName: e, id: idx });
                      setRegistrationData({
                        ...registrationData,
                        mobileNo: e,
                      });
                      if (e?.length < 13) {
                        setError(true);
                      } else {
                        setError(false);
                      }
                    }}
                    id="username"
                    className={`form-control ${
                      mobileError ? "" : "border-danger"
                    }`}
                    value={link?.userName ? link?.userName : ""}
                    international
                    placeholder="Enter phone number"
                  />
                </>
              )}
            </div>
            {error ? (
              <div className="text-danger">
                Please enter a valid phone number
              </div>
            ) : (
              <></>
            )}
            {mobileError ? (
              <></>
            ) : (
              <div className="text-danger">
                Please enter a valid phone number
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-6">
              <button
                type="reset"
                onClick={handleClose}
                className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
              >
                Cancel
              </button>
            </div>
            <div className="col-6">
              {error ? (
                <div
                  style={{ backgroundColor: "#04BAC7" }}
                  className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                >
                  Save
                </div>
              ) : (
                <button
                  style={{ backgroundColor: "#04BAC7" }}
                  className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                  type="submit"
                >
                  Save
                </button>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

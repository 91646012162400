import moment from "moment";

const NotesDrawer = ({ handleClose, isEmpty, note }) => {
  const checkDate = (index) => {
    if (index === 0) {
      return true;
    } else {
      return (
        note?.notes[index]?.created_at?.split("T")[0] !==
        note?.notes[index - 1]?.created_at?.split("T")[0]
      );
    }
  };

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center pb-1 pt-3 ps-3 pe-4">
        <div className="d-flex align-items-center">
          <img
            src="/images/clientList/Notes.png"
            alt="arrow"
            className="me-2"
          />
          <p className={`mb-0`} style={{ fontSize: "20px" }}>
            Service notes
          </p>
        </div>
        <img
          onClick={handleClose}
          src="/images/clientList/close.png"
          alt="arrow"
          className="pointer me-1"
        />
      </div>
      <hr />
      {note?.notes?.filter((f) => f.appointment_id)?.length === 0 ? (
        <div className="row mt-5 w-100 m-auto">
          <div className="col-8 m-auto py-5 text-center">
            <label>
              <img
                src="/images/Media/UploadImg.svg"
                alt="Upload"
                className="pointer"
              />
              <h6 className="mb-0 fw-bold mt-3 mb-1">
                {`Not notes Added for ${note?.username}`}
              </h6>
              <p
                style={{ fontSize: "14px", color: "#667085" }}
                className="mb-0 text-grey"
              >
                You can add images from you favorite social media platforms e.g.
                Facebook, Instagram, Twitter etc.
              </p>
            </label>
          </div>
        </div>
      ) : (
        <>
          {note?.notes
            ?.filter((f) => f.appointment_id)
            ?.map((m, i) => (
              <div className="mt-3 ps-3 pe-4 mb-1" key={i}>
                {checkDate(i) ? (
                  <p
                    className={`mb-2 mt-1 text-dark`}
                    style={{ fontSize: "18px" }}
                  >
                    {moment(m?.created_at).format("MMMM Do, YYYY")}
                  </p>
                ) : null}
                <div className="row gy-4">
                  <div className="col-12">
                    {/* border-bottom border-3 border-primary */}
                    <textarea
                      style={{
                        fontSize: "14px",
                        resize: "none",
                        backgroundColor: "#F1F5F9",
                      }}
                      className={`form-control rounded-b5 px-4 py-3 border-0`}
                      name="businessDescription"
                      id="description"
                      value={m?.notes}
                      placeholder=""
                      cols={8}
                      rows={3}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            ))}
        </>
      )}
    </div>
  );
};

export default NotesDrawer;

import React, { useEffect } from "react";
import { FC } from "react";
import { CURRENCY } from "../../../../config/constant";
import Modal from "../../../../components/common/Modal";
import { EditModal } from "./EditModal";
import {
  convertMinIntoHoursAndMin,
  extractTitleFirstWord,
} from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  updateServicesStatus,
  updateThirdLevelChildServicesStatus,
} from "../../../../reducers/serviceReducer";
import ServiceCardSkeletonLoad from "./ServiceCardSkeletonLoad";

const ServiceBox: FC<any> = ({ thirdLevelService }) => {
  // console.log("🚀 ~ file: ServiceBox.tsx:28 ~ data:", data)
  const [open, setOpen] = React.useState(false);
  // const [rootServices, setRootServices] = React.useState<any>({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isStatusChanging, setIsStatusChanging] = React.useState(false);
  const [serviceId, setServiceId] = React.useState(0);
  const updateStatus = useSelector(updateServicesStatus);

  useEffect(() => {
    if (updateStatus === "idle") {
      setServiceId(0);
    }
  }, [serviceId, updateStatus]);

  // const split = name?.split(" ")[0];
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      {/*  */}
      {updateStatus === "loading" && serviceId === thirdLevelService?.id ? (
        <ServiceCardSkeletonLoad services={[1]} />
      ) : (
        <div
          style={{
            borderRadius: "6px",
          }}
          className={`d-flex flex-sm-row flex-column p-md-3 p-2 mt-2 justify-content-between border ${
            thirdLevelService?.providerService &&
            thirdLevelService?.providerService.status
              ? "border-primary bg-selected"
              : null
          }`}
        >
          <div className="d-flex">
            {thirdLevelService?.icon ? (
              <img
                height={50}
                className="me-3"
                src={thirdLevelService?.icon}
                alt="no icon"
              />
            ) : (
              <div
                style={{ height: "54px", width: "54px", fontSize: "17px" }}
                className={`bg-light-grey rounded-circle p-4 me-md-3 me-2 d-flex align-items-center justify-content-center w-days`}
              >
                <p className={`m-0 p-0 text-secondary`}>
                  {extractTitleFirstWord(thirdLevelService?.name)}
                </p>
              </div>
            )}
            <div className="">
              <div className="d-flex justify-content-between">
                <p
                  className={`mb-0 fw-bold mb-1 text-capitalize ${
                    thirdLevelService?.providerService &&
                    thirdLevelService?.providerService.status
                      ? "text-primary"
                      : null
                  }`}
                  style={{ fontSize: "15px" }}
                >
                  {thirdLevelService?.providerService
                    ? thirdLevelService?.providerService.service_name
                    : thirdLevelService?.name}
                </p>
                <span
                  className="mb-0 fw-bold text-primary d-sm-none d-block"
                  style={{ fontSize: "17px" }}
                >
                  {CURRENCY}
                  {thirdLevelService?.providerService
                    ? thirdLevelService?.providerService.price
                    : thirdLevelService?.price}
                </span>
              </div>
              <p
                style={{ color: "grey", fontSize: "13px", minHeight: "30px" }}
                className="mb-0 mt-1"
              >
                {thirdLevelService?.providerService
                  ? thirdLevelService?.providerService.service_description
                  : thirdLevelService?.description}
              </p>
              <div className="d-flex justify-content-between align-items-center">
                <p
                  onClick={handleOpen}
                  style={{ color: "grey", fontSize: "15px", width: "20px" }}
                  className="mb-0 mt-2 text-primary pointer"
                >
                  Edit
                </p>
                {/* <span
              className="mb-0 fw-bold text-primary d-sm-none d-block mt-2"
              style={{ fontSize: "17px" }}
            >
              {CURRENCY}
              {providerService ? providerService.price : price}
            </span> */}
              </div>
            </div>
          </div>
          <div className="justify-content-end">
            <div className="d-flex flex-sm-column flex-row text-end justify-content-end">
              <span
                className="mb-0 fw-bold text-primary d-sm-block d-none"
                style={{ fontSize: "17px" }}
              >
                {CURRENCY}
                {thirdLevelService?.providerService
                  ? thirdLevelService?.providerService.price
                  : thirdLevelService?.price}
              </span>
              <div className="d-flex align-items-center mt-1 justify-content-end me-sm-0 me-2">
                <img
                  height={15}
                  className="me-1"
                  src="/images/services/time.png"
                  alt=""
                />
                <span style={{ fontSize: "14px", color: "grey" }}>
                  {thirdLevelService?.providerService
                    ? convertMinIntoHoursAndMin(
                        thirdLevelService?.providerService.duration
                      )
                    : convertMinIntoHoursAndMin(thirdLevelService?.duration)}
                </span>
              </div>
              <div className="d-flex align-items-center justify-content-end me-0 mt-2">
                {isStatusChanging ? (
                  "Loading..."
                ) : (
                  <div className="form-check form-switch mt-md-1 me-3">
                    <label
                      style={{ fontSize: "15px" }}
                      className="form-check-label"
                    >
                      {thirdLevelService?.providerService?.status
                        ? "Enable"
                        : "Disable"}
                    </label>
                    <input
                      onChange={(e) => {
                        dispatch(
                          updateThirdLevelChildServicesStatus({
                            mainData: {
                              parenId: thirdLevelService?.parentId
                                ? thirdLevelService?.parentId
                                : thirdLevelService?.service?.parent_id,
                              serviceId: thirdLevelService?.providerService
                                ? thirdLevelService?.providerService?.service_id
                                : thirdLevelService?.id,
                              providerServiceId:
                                thirdLevelService?.providerService?.id,
                              status: e.target.checked ? 1 : 0,
                            },
                            metaData: { setIsStatusChanging },
                          })
                        );
                        setServiceId(thirdLevelService?.id);
                        // dispatch(updateStatus({ id: data?.id, status: e.target.checked ? 1 : 0 }))
                      }}
                      className="form-check-input"
                      type="checkbox"
                      checked={
                        thirdLevelService?.providerService
                          ? thirdLevelService?.providerService.status
                          : false
                      }
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                )}

                {/* <h3>{providerService ? providerService.status : false}</h3> */}
                {/* <img
              data-mdb-toggle="tooltip"
              title="Hooray!"
              className="me-0 pointer"
              height={18}
              src="/images/schedule/infosvg.svg"
              alt=""
            /> */}
              </div>
              <div className="form-check-inline m-0">
                {/* style={{ accentColor: "red" }} */}
                {/* <input
              className="form-check-input rounded-circle"
              type="checkbox"
              id="inlineCheckbox1"
              defaultChecked={providerService ? true : false}
            /> */}
              </div>
            </div>
          </div>
          <Modal handleClose={handleClose} open={open}>
            <EditModal
              handleClose={handleClose}
              thirdLevelService={thirdLevelService}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

export default ServiceBox;

import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  appointmentPendingListSelector,
  changeAppointmentStatus,
  pendingAppStatus,
  statusSelector,
} from "../../../reducers/appointmentReducer";
import { convert24To12 } from "../../../utils";
import { AppDispatch } from "../../../store";
import { TemporaryDrawer } from "../../../components/common/TemporaryDrawer";
import RescheduleDrawer from "./components/RescheduleDrawer";
import EditRescheduleDrawer from "./components/EditRescheduleDrawer";
import { Grid } from "@mui/material";
import AppointmentButton from "./components/MainButton";
import { sendNotification } from "../../../helper/provider/notification";
import { useNavigate } from "react-router-dom";
import TableLoading from "../../../components/common/TableLoading";
import OverlayLoading from "../../../components/common/OverlayLoading";
import { SHOW_INFO } from "../../../utils/ToastMessage";
import { loggedInUserProfile } from "../../../reducers/authReducer";
import ConfModal from "../../../components/common/ConfModal";
import ActionPrompt from "./components/ActionPrompt";

interface Data {
  Date: string;
  id: string | number;
  Time: string;
  action: string;
  location: string;
  name: any;
  services: string;
  clientImg: string;
  status: string;
  statusToShow: string;
  totalDuration: any;
  providerId: any;
  clientId: any;
  totalPrice: string | number;
  isReservationFee: number;
  reservationFee: string | number;
  buttons: any;
}

function createData(
  id: string | number,
  name: string,
  Date: string,
  Time: string,
  location: string,
  action: string,
  services: string,
  clientImg: string,
  status: string,
  statusToShow: string,
  totalDuration: any,
  providerId: any,
  clientId: any,
  totalPrice: string | number,
  isReservationFee: number,
  reservationFee: string | number,
  buttons: any
): Data {
  return {
    id,
    name,
    Date,
    Time,
    location,
    action,
    services,
    clientImg,
    status,
    statusToShow,
    totalDuration,
    providerId,
    clientId,
    totalPrice,
    isReservationFee,
    reservationFee,
    buttons,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Client",
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Date & Time",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Services",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            className="light-check"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all clients",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "normal" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}
      {numSelected > 0 ? null : null}
    </Toolbar>
  );
}

export default function PendingRequestTable({
  setRowsPerPage,
  rowsPerPage,
  setPage,
  page,
  pagination,
  handlePagination,
}) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("Date");
  const [selected, setSelected] = React.useState<readonly any[]>([]);
  const [dense, setDense] = React.useState(false);

  const navigate = useNavigate();

  const loginUser = useSelector(loggedInUserProfile);

  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );

  const rows = React.useMemo(
    () =>
      pendingAppointmentListData
        ?.filter(({ client }) => client !== null)
        .map(
          ({
            id,
            client,
            appointmentDate,
            services,
            startTime,
            endTime,
            status,
            statusToShow,
            totalDuration,
            provider,
            totalPrice,
            buttons,
            appointmentAddress,
            isReservationFee,
            reservationFee,
          }) =>
            createData(
              id,
              `${client?.firstName} ${client?.lastName}`,
              appointmentDate,
              `${convert24To12(startTime)}-${convert24To12(endTime)}`,
              appointmentAddress ? appointmentAddress : loginUser?.street,
              client?.id,
              services?.map(
                (
                  { service: { name } }: any,
                  idx: number,
                  arr: string | any[]
                ) => (
                  <div
                    key={name}
                    className="border rounded-pill px-2 py-1 d-inline-block me-2 mb-2 text-nowrap"
                  >
                    {name.length > 25 ? name.substring(0, 25) + "..." : name}
                  </div>
                )
              ),
              client?.profileImage,
              status,
              statusToShow,
              totalDuration,
              provider?.id,
              client?.id,
              totalPrice,
              isReservationFee,
              reservationFee,
              buttons
            )
        ),
    [pendingAppointmentListData]
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
    if (pagination.nextPageUrl) {
      const page = pagination.nextPageUrl.split("?page=");
      handlePagination(newPage + 1);
    } else {
      handlePagination(newPage);
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const dispatch = useDispatch<AppDispatch>();
  const appointmentStatus = useSelector(statusSelector);
  const status = useSelector(pendingAppStatus);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [open3, setOpen3] = React.useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [rowData, setRowData] = React.useState<any>(null);
  const [appointmentStatusData, setAppointmentStatusData] = React.useState<any>(
    {}
  );

  const [appointmentCom, setAppointmentCom] = React.useState("approve");

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 1100 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            {appointmentStatus === "loading" ? (
              <TableLoading />
            ) : (
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
                {stableSort(rows, getComparator(order, orderBy)).map(
                  (row: any, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        className="pointer"
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        {/* <TableCell
                        onClick={(event) => handleClick(event, row.name)}
                        padding="checkbox"
                      >
                        <Checkbox
                          className="light-check"
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                        <TableCell
                          onClick={() => {
                            handleOpen();
                            setRowData(row);
                            setAppointmentCom("approve");
                          }}
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <div className="d-flex align-items-center">
                            <img
                              className="rounded-circle me-2 ms-3 border border-primary"
                              style={{ objectFit: "cover" }}
                              height={40}
                              width={40}
                              src={
                                row?.clientImg == null
                                  ? "/images/avatar2.png"
                                  : row?.clientImg
                              }
                              // srcSet="/images/avatar2.png"
                              alt="No profile"
                            />

                            <div>
                              {row.name}
                              <p
                                style={{ color: "#667085", fontSize: "12px" }}
                                className="m-0"
                              >
                                {row.location}
                              </p>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleOpen();
                            setRowData(row);
                            setAppointmentCom("approve");
                          }}
                          align="left"
                          padding="none"
                        >
                          <div className="d-flex flex-column ms-3">
                            <p className="mb-0">{row.Date}</p>
                            <p
                              style={{ color: "#667085", fontSize: "12px" }}
                              className="mb-0"
                            >
                              {row.Time}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleOpen();
                            setRowData(row);
                            setAppointmentCom("approve");
                          }}
                          align="left"
                          padding="none"
                        >
                          <p
                            style={{ color: "#667085", fontSize: "12px" }}
                            className="mb-0 ms-3 text-nowrap"
                          >
                            {row.services}
                          </p>
                        </TableCell>
                        <TableCell
                          onClick={() => {
                            handleOpen();
                            setRowData(row);
                            setAppointmentCom("approve");
                          }}
                          style={{ width: "220px" }}
                          align="left"
                          padding="none"
                        >
                          {/* <p className="mb-0 ms-3">{row.status}</p> */}
                          <p
                            className={`rounded-pill px-2 py-1 ms-3 d-inline text-nowrap ${
                              row.status == "declineByProvider"
                                ? "tag-brown"
                                : row.status == "pending" ||
                                  row.status == "completed"
                                ? "tag-success"
                                : row.status == "collect"
                                ? "tag-primary"
                                : row.status == "approveByProvider"
                                ? "tag-danger"
                                : "tag-primary"
                            }`}
                          >
                            <span style={{ fontSize: "12px" }} className="mb-0">
                              {row.statusToShow}
                            </span>
                          </p>
                        </TableCell>
                        <TableCell style={{ width: "400px" }} align="center">
                          <div className="d-flex align-items-center ">
                            <Grid container spacing={2}>
                              {/* reschedule index:0 */}
                              <AppointmentButton
                                btnData={row.buttons?.[0]}
                                onClick={() => {
                                  if (row.buttons?.[0].color !== "DULL") {
                                    handleOpen2();
                                    setRowData(row);
                                    setAppointmentCom("approve");
                                  }
                                }}
                              />
                              {/* pay  index:1 */}
                              <AppointmentButton
                                btnData={row.buttons?.[1]}
                                onClick={() => {
                                  if (row.buttons?.[1].color !== "DULL") {
                                    navigate("/payment-status");
                                  }
                                }}
                              />
                              {/* checkout index:2 */}
                              <AppointmentButton
                                btnData={row.buttons?.[2]}
                                onClick={() => {
                                  if (row.buttons?.[2].color !== "DULL") {
                                    handleOpen();
                                    setRowData(row);
                                    setAppointmentCom("complete");
                                  }
                                }}
                              />
                              {/* payment request index:3 */}
                              <AppointmentButton
                                btnData={row.buttons?.[3]}
                                onClick={() => {
                                  if (row.buttons?.[3].color !== "DULL") {
                                    const notificationData = {
                                      trigger: "PAYMENT_REMINDER",
                                      triggerType: "client-triggers",
                                      modelType: "client",
                                      modelId: row.clientId,
                                      channel: "KC",
                                      title: "Payment Pending",
                                      messageParams: [
                                        {
                                          name: row?.name,
                                          date: row?.Date,
                                        },
                                      ],
                                    };
                                    sendNotification(notificationData);
                                    SHOW_INFO(true, "Payment Reminder Sent");
                                  }
                                }}
                              />
                              {/* approve  index:4 */}
                              <AppointmentButton
                                btnData={row.buttons?.[4]}
                                onClick={() => {
                                  if (row.buttons?.[4].color !== "DULL") {
                                    setAppointmentStatusData({
                                      appointmentId: row.id,
                                      status: "approveByProvider",
                                      statusToShow: "Approved By Provider",
                                      textToShow: "Approve Appointment",
                                    });
                                    handleOpen3();
                                  }
                                }}
                              />
                              {/* decline  index:5 */}
                              <AppointmentButton
                                btnData={row.buttons?.[5]}
                                onClick={() => {
                                  if (row.buttons?.[5].color !== "DULL") {
                                    setAppointmentStatusData({
                                      appointmentId: row.id,
                                      status: "declineByProvider",
                                      statusToShow: "Decline By Provider",
                                      textToShow: "Canceling Appointment",
                                    });
                                    handleOpen3();
                                  }
                                }}
                              />
                            </Grid>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  }
                )}
                {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TemporaryDrawer handleClose={handleClose} open={open}>
          <RescheduleDrawer
            handleClose={handleClose}
            isEmpty={false}
            data={rowData}
            isComplete={appointmentCom}
          />
        </TemporaryDrawer>
        <TemporaryDrawer handleClose={handleClose2} open={open2}>
          <EditRescheduleDrawer handleClose={handleClose2} id={rowData?.id} />
        </TemporaryDrawer>
        <ConfModal handleClose={handleClose3} open={open3}>
          <ActionPrompt
            handleClose={handleClose3}
            data={appointmentStatusData}
            textToShow={appointmentStatusData?.textToShow}
          />
        </ConfModal>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={appointmentStatus === "loading" ? 0 : pagination?.total || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <OverlayLoading active={status === "loading" ? true : false} />
      {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
    </Box>
  );
}

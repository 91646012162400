import { useCallback, useEffect, useMemo, useState } from "react";
// import { TagsInput } from "react-tag-input-component";
// import ReactTags from "react-tag-autocomplete";

import Typography from "@mui/material/Typography";
import { xconsole } from "../../../../utils/console";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  MultiServiceLevelTwoSelector,
  UpdateStatus,
  UploadOwnMedia,
} from ".././../../../reducers/mediaReducer";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { ErrorMessage, Form, Formik } from "formik";
import { CS_Styles } from "../../../../utils";
import * as yup from "yup";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { uploadOwnMediaRequest } from "../../../../helper/provider/media";
import { useDropzone } from "react-dropzone";
import ModalC from "../../../../components/common/Modal";
import { CropImageModal } from "./CropImageModal";
import useCoordinates from "../../../../hooks/useRandomCordinates";
import { LOADING, SUCCESS } from "../../../../config/constant";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";
import TextArea from "../../../../components/common/Textarea";

export const AddMediaModal = ({
  handleClose,
  mediaUploadProgress,
  setMediaUploadProgress,
}: any) => {
  const [imageData, setImageData] = useState<any>({
    image: { blob: "", src: null },
    type: "",
    location: "",
    latitude: "",
    longitude: "",
    caption: "",
    tags: "",
    isPrivate: 1,
    services: [],
  });
  // console.log("🚀 ~ file: AddMediaModal.tsx:35 ~ imageData:", imageData)

  const [coordinates, generateCoordinates] = useCoordinates();

  useEffect(() => {
    generateCoordinates();
  }, []);

  const [error, setError] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [type, setType] = useState(false);
  const [output, setOutput] = useState<any>({
    blob: "",
    src: null,
  });

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleMediaClose = () => setOpen(false);

  const dispatch = useDispatch<AppDispatch>();
  const serviceOptions = useSelector(MultiServiceLevelTwoSelector);

  const multiSelectOptions = useMemo(() => serviceOptions, [serviceOptions]);

  const handleUserChange = (selectedOption) => {
    let tempSelect = [];
    selectedOption.forEach((select: { value: any }) => {
      tempSelect.push(select.value);
    });
    if (tempSelect.length <= 2) {
      setImageData({
        ...imageData,
        tags: JSON.stringify(tempSelect),
        services: selectedOption,
      });
    }
  };

  const onChangeHandle = (e) => {
    setImageData({ ...imageData, [e.target.name]: e.target.value });
  };

  const onDrop = useCallback((acceptedFiles) => {
    setError(false);
    setType(false);
    setVideoError(false);
    onImageChange(acceptedFiles[0]);
  }, []);

  const rejectedFiles = useCallback((rejectedFiles) => {
    // console.log("rejectedFiles", rejectedFiles);
    setType(true);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected: rejectedFiles,
    accept: {
      "image/png": [".png"],
      "video/mp4": [".mp4"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  const onImageChange = (event) => {
    if (event) {
      let img = event;
      // console.log("img", img);
      setVideoError(false);
      if (img.type.includes("video")) {
        let video = document.createElement("video");
        video.preload = "metadata";
        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);
          console.log("video duration", video.duration);
          if (video.duration > 5) {
            setVideoError(true);
            return;
          } else {
            setImageData({
              ...imageData,
              image: {
                blob: img,
                src: URL.createObjectURL(img),
              },
            });
          }
        };
        video.src = URL.createObjectURL(img);
        // setImageData({
        //   ...imageData,
        //   image: {
        //     blob: img,
        //     src: URL.createObjectURL(img),
        //   },
        // });

        // console.log("video", video);
      } else {
        setOutput({
          blob: img,
          src: URL.createObjectURL(img),
        });
        handleOpen();
      }
    }
  };

  const maxLength = 255;

  const validationSchema = yup.object().shape({
    // location: yup.string().required("Location is required"),
    caption: yup
      .string()
      .required("Caption is required")
      .max(maxLength, "Must be 255 characters or less"),
    tags: yup.string().required("Tag is required"),
  });

  function onUploadProgress({ loaded, total }) {
    let percentage = (loaded / total) * 100;
    // console.log("MEDIA loading data : ", percentage);

    setMediaUploadProgress((prev) => (prev = percentage));
  }

  const date = new Date();
  const month = date.toLocaleString("default", { month: "short" });
  const today = `${date.getDate()} ${month} ${date.getFullYear()}`;

  const handleSubmit = (e: any) => {
    // e.preventDefault();
    // xconsole.test("pass");
    // console.log("imageData", imageData?.image);
    // convert output to blob and send to server

    let data: any = new FormData();

    data.append("file", imageData.image.blob);
    data.append("type", imageData.image.blob.type);
    data.append("caption", imageData.caption);
    data.append("isProviderPrivate", imageData.isPrivate);
    data.append("DateTime", new Date());
    // data.append("location", imageData.location);
    if (coordinates) {
      data.append("latitude", coordinates.lat);
      data.append("longitude", coordinates.lng);
    }
    data.append("tags", imageData.tags);

    if (imageData?.image?.blob === "") {
      setType(false);
    } else {
      setMediaUploadProgress(0);
      dispatch(UpdateStatus(LOADING));
      uploadOwnMediaRequest(data, onUploadProgress)
        .then((data) => {
          dispatch(UpdateStatus(SUCCESS));
          // console.log("MEDIA DATA : ", data);
          dispatch(UploadOwnMedia(data));
        })
        .catch((error) => {
          console.log("MEDIA ERROR : ", error);
        });
      handleClose();
    }
    // console.log("values", data);
    // setImageData({
    //   image: { blob: "", src: null },
    //   type: "",
    //   location: "",
    //   caption: "",
    //   tags: "",
    // });

    // data.forEach(function (val, key, fD) {
    //   data.delete(key);
    // });
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <Typography
          className=""
          id="modal-modal-title"
          sx={{
            fontSize: "18px",
            fontWeight: "550",
          }}
          component="h6"
        >
          Upload Media
        </Typography>
        <div className="d-flex align-items-center justify-content-center">
          <label
            className="form-check-label pe-1"
            htmlFor="flexSwitchCheckDefault"
          >
            Private
          </label>
          <div className="form-check form-switch">
            <input
              className="form-check-input"
              type="checkbox"
              checked={true}
              role="switch"
              // onChange={(e) =>
              //   setImageData({
              //     ...imageData,
              //     isPrivate: e.target.checked ? 1 : 0,
              //   })
              // }
              id="flexSwitchCheckDefault"
            />
          </div>
          <div>
            <BootstrapTooltip
              // style={{
              //   zIndex: 9999,
              // }}
              title="More Function is coming around personal images. Currently
                    your images are shareable among your personal connections
                    and viewable only with your authorized connections in
                    Kazzah."
              placement="top"
            >
              <IconButton
                // onClick={handleClose}
                aria-label="delete"
                size="small"
                className="text-danger"
              >
                <img src="/images/info.png" alt="" />
              </IconButton>
            </BootstrapTooltip>
          </div>
        </div>
      </div>
      <div className="row mb-5 pb-3">
        <div className="col-md-6">
          {imageData?.image?.src ? (
            <div
              className="w-100 text-center border rounded mt-3"
              style={{
                height: "260px",
                position: "relative",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: 0,
                }}
              >
                <IconButton
                  onClick={() => {
                    setImageData({
                      ...imageData,
                      image: { blob: "", src: null },
                    });
                    setOutput({ blob: "", src: null });
                  }}
                  aria-label="delete"
                  size="small"
                >
                  <CancelIcon fontSize="small" />
                </IconButton>
              </div>
              {imageData?.image?.blob?.type.includes("video") ? (
                <video
                  className="rounded"
                  id="video"
                  width="70%"
                  height="100%"
                  controls
                >
                  <source src={imageData?.image?.src} type="video/mp4" />
                </video>
              ) : (
                <img
                  style={{ objectFit: "contain" }}
                  src={imageData.image.src}
                  className="mt-0"
                  alt="not available"
                  height="100%"
                  width="100%"
                />
              )}
            </div>
          ) : (
            <>
              <div {...getRootProps()}>
                <div
                  className={`d-flex align-items-center justify-content-center py-4 mt-3 w-100 bg-light pointer ${
                    error || type || videoError ? "border-danger" : ""
                  }`}
                  style={{
                    height: "260px",
                    border: isDragActive
                      ? "2px dashed #04bac7"
                      : "2px dashed #D3D3D3",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-center h-100">
                    <input {...getInputProps()} />
                    {isDragActive ? (
                      <div
                        className="d-flex flex-column align-items-center pointer"
                        style={{
                          color: "#bdbdbd",
                        }}
                      >
                        <p className="mb-0">Drop your media here</p>
                      </div>
                    ) : (
                      <div
                        className="d-flex flex-column align-items-center pointer"
                        style={{
                          color: "#bdbdbd",
                        }}
                      >
                        <p className="mb-0">Drag and drop your media here</p>
                        <p className="mb-0">or</p>
                        <p className="mb-0">Browse</p>
                      </div>
                    )}
                  </div>
                </div>
                {/* <p style={{ fontSize: "14px" }} className="mt-0 mb-3">
            PNG, JPG or GIF (max.800 ✗ 400, less than 1MB)
          </p> */}
              </div>
              {videoError ? (
                <div className="text-danger">
                  Video must not be longer than 5 sec.
                </div>
              ) : null}
              {error ? (
                <div className="text-danger">Image is required</div>
              ) : null}
              {type ? (
                <div className="text-danger">Only image/video is allowed</div>
              ) : null}
            </>
          )}
        </div>
        <div className="col-md-6">
          <div className="form">
            <Formik
              initialValues={imageData}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ errors, touched, values, handleBlur, handleChange }) => (
                <Form>
                  {/* <div className="form-group mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="duration"
                      className="form-label"
                    >
                      Location
                    </label>
                    <div className="input-group">
                      <input
                        name="location"
                        type="text"
                        className={`form-control ${
                          errors.location && touched.location
                            ? "is-invalid"
                            : ""
                        }`}
                        id="duration"
                        onChange={(e) => {
                          onChangeHandle(e);
                          handleChange(e);
                        }}
                        placeholder="Eastern Seaboard, New York and Washington"
                      />
                      <ErrorMessage
                        name="location"
                        component="span"
                        className="invalid-feedback"
                      />
                    </div>
                  </div> */}
                  <label
                    style={{ fontSize: "14px" }}
                    htmlFor="addCaption"
                    className="form-label mt-3"
                  >
                    Add Tag <small>(Maximum 2 allowed)</small>
                  </label>
                  <Select
                    onChange={(e: any) => {
                      handleUserChange(e);
                      handleChange({
                        target: {
                          value: e?.length > 0 ? Math.random() : "",
                          name: "tags",
                        },
                      });
                    }}
                    name="tags"
                    styles={errors.tags && touched.tags ? CS_Styles : {}}
                    value={imageData?.services?.map((u) => {
                      return { label: u?.label, value: u?.value };
                    })}
                    components={makeAnimated()}
                    isMulti
                    options={multiSelectOptions}
                    placeholder="Select Tags"
                    isClearable={true}
                  />
                  {errors.tags && touched.tags ? (
                    <div className="text-danger">
                      <small> Tag is required</small>
                    </div>
                  ) : null}

                  <div className="form-group mt-3 position-relative">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="addCaption"
                      className="form-label"
                    >
                      Add Caption
                    </label>
                    <div className="">
                      <TextArea
                        handleChange={(e) => {
                          onChangeHandle(e);
                          handleChange(e);
                        }}
                        errors={errors?.caption}
                        touched={touched?.caption}
                        name={"caption"}
                        value={values?.caption}
                        toggle={null}
                        placeholder={"Recent Photo took in New York"}
                      />
                    </div>
                  </div>
                  <div className="w-100 position-absolute start-0 bottom-0 mb-2">
                    <div className="row m-auto w-100">
                      <div className="col-6 ps-4">
                        <button
                          type="reset"
                          onClick={handleClose}
                          className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="col-6 pe-4">
                        <button
                          onClick={() => {
                            if (output?.blob === "") {
                              setError(true);
                            }
                          }}
                          style={{ backgroundColor: "#04BAC7" }}
                          className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                          type="submit"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <ModalC handleClose={handleMediaClose} open={open}>
        <CropImageModal
          handleClose={handleMediaClose}
          img={output?.src}
          setOutput={setImageData}
          output={imageData}
        />
      </ModalC>
    </>
  );
};

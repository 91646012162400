import React, { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import Drawer from "../layout/Drawer";
// interface layOutProps {
//   children: ReactNode;
// }

const index = () => (
  <div>
    <Drawer>
      <main>
        <Outlet />
      </main>
    </Drawer>
  </div>
);

export default index;

import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import DeleteIcon from "@mui/icons-material/Delete";
import { scheduleDayType } from "../../type";
import ScheduleDeleteModal from "../common/ScheduleDeleteModal";
import ModalC from "../../../../../components/common/Modal";
import { dayNameToPrefix } from "../../../../../utils";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import { servicePlayPauseAction } from "../../../../../reducers/scheduleReducer";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const CompletedSchedule = ({ data }: any) => {
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState<any>();
  const [type, setType] = useState<any>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //     days={multiToMultiData?.days}
  // SelectedDays={multiToMultiData?.newDAY}
  const dispatch = useDispatch<AppDispatch>();
  const handleClick = (service) => {
    const serviceData = {
      scheduleId: data?.id,
      serviceId: service?.id,
      isPaused: service?.isPaused ? 0 : 1,
      multiId: data?.id,
    };
    dispatch(servicePlayPauseAction(serviceData));
  };

  return (
    <div
      className={`rounded my-3 px-md-4 py-3 ${
        !Boolean(Number(data?.services[0]?.isPaused))
          ? "bg-primary"
          : "bg-light"
      }`}
    >
      {/* <h5
                className='pointer text-gray-800 text-hover-primary fw-bolder mb-n4 py-5'
            >
                Services
            </h5> */}
      <div className="row align-items-end">
        <div className="col-4 text-start my-auto">
          <span className="fw-bold d-block">
            {data?.services[0]?.serviceName?.name}
          </span>
          {/* <GroupIcon sx={{ fontSize: 30 }} /> */}
          <div>
            <label
              style={{ fontSize: "0.9rem" }}
              className="text-dark me-1 mb-1"
              htmlFor="NoAte"
            >
              No. of Participants
            </label>
            <span
              style={{ fontSize: "14px" }}
              className="mb-0 d-block"
              id="NoAte"
            >
              {data?.services[0]?.noOfParticipants}
            </span>
          </div>
        </div>
        <div className="col-3 text-start px-md-2 pe-0">
          <label
            style={{ fontSize: "0.9rem" }}
            className="text-dark me-1 mb-1"
            htmlFor="NoAte"
          >
            Schedule Days
          </label>
          <div className="d-flex align-items-center">
            {data?.days?.map(({ id, scheduleDays }, idx, arr) => (
              <div
                key={id}
                style={{ height: "25px", width: "25px", fontSize: "10px" }}
                className={`${"bg-light-grey"} rounded-circle p-1 me-1 d-flex align-items-center justify-content-center pointer w-days`}
              >
                <p className={`m-0 p-0 ${"text-secondary"}`}>
                  {dayNameToPrefix(scheduleDays)}
                </p>
              </div>
              // <span key={id} style={{ display: "inline-block" }}>
              //   <span style={{ margin: "4px" }}></span>
              //   {arr?.length > 0 && !(idx + 1 === arr.length) ? ` / ` : ` `}
              // </span>
            ))}
          </div>
        </div>
        <div className="col-5 d-flex justify-content-between align-items-center">
          <div>
            <label
              style={{ fontSize: "0.9rem" }}
              className="me-1 d-block text-dark mb-1"
              htmlFor="NoAte"
            >
              State St.
            </label>
            <span style={{ fontSize: "14px" }} className="text-center mt-2">
              {data?.startTime}
            </span>
            -
            <span
              style={{ fontSize: "14px" }}
              className="text-center text-dark mt-2"
            >
              {data?.endTime}
            </span>
          </div>
          <div>
            <BootstrapTooltip title={"Delete"} placement="top">
              <span
                onClick={() => {
                  handleOpen();
                  setId(data.id);
                  setType(data.type);
                }}
              >
                <DeleteIcon sx={{ fontSize: 23 }} className="pointer mb-0" />
              </span>
            </BootstrapTooltip>
            <BootstrapTooltip
              title={
                !Boolean(Number(data?.services[0]?.isPaused)) ? "Pause" : "Play"
              }
              placement="top"
            >
              <span
                onClick={() => {
                  handleClick({
                    isPaused: Boolean(Number(data?.services[0]?.isPaused)),
                    id: data?.services[0]?.service?.id,
                  });
                }}
              >
                {!Boolean(Number(data?.services[0]?.isPaused)) ? (
                  <PauseCircleIcon sx={{ fontSize: 30 }} className="pointer" />
                ) : (
                  <PlayCircleIcon sx={{ fontSize: 30 }} className="pointer" />
                )}
              </span>
            </BootstrapTooltip>
          </div>
          <ModalC handleClose={handleClose} open={open}>
            <ScheduleDeleteModal
              type={type}
              id={id}
              handleClose={handleClose}
            />
          </ModalC>
        </div>
      </div>
    </div>
  );
};

export default CompletedSchedule;

import React from "react";
import { useSelector } from "react-redux";
import {
  appointmentPendingListSelector,
  statusSelector,
} from "../../../reducers/appointmentReducer";
import { profileProgressSelector } from "../../../reducers/authReducer";
import RequestCard from "./pendingRequest/RequestCard";
import PendingRequestsLoading from "./skeletonLoading/PendingRequestsLoading";
import useWindowSize from "../../../hooks/useWindowSize";

const PendingRequestSection = () => {
  const { width } = useWindowSize();
  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );

  const Status = useSelector(statusSelector);
  const profileData = useSelector(profileProgressSelector);

  return (
    <>
      <div className="bg-white rounded pb-4">
        {Status === "loading" ? (
          <PendingRequestsLoading requests={[1, 2, 3, 4, 5, 6, 7, 8]} />
        ) : (
          <>
            <h6
              style={{ fontSize: "16px" }}
              className="m-0 fw-bolder px-4 pt-4"
            >
              Requests (
              {
                pendingAppointmentListData
                  ?.filter(({ isReservationFee, isPaid }) =>
                    isReservationFee === 1
                      ? isPaid !== 0 || isPaid !== null
                      : true
                  )
                  ?.filter(({ status }) => status === "pending")?.length
              }
              )
            </h6>
            <div
              style={{
                height:
                  width >= 1200
                    ? profileData?.profilePercentage === 100
                      ? "855px"
                      : "1012px"
                    : "auto",
              }}
              className="overflowYscroll slim-scroll px-4"
            >
              {pendingAppointmentListData
                ?.filter(({ isReservationFee, isPaid }) =>
                  isReservationFee === 1
                    ? isPaid !== 0 || isPaid !== null
                    : true
                )
                ?.filter(({ status }) => status === "pending")
                ?.map((data, idx) => (
                  <RequestCard key={idx} index={idx} reqData={data} />
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PendingRequestSection;

import React, { Fragment, useEffect, useMemo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMessages } from "../../../../Firebase/hooks";
import SendMessage from "./components/SendMessage";
import { Message } from "../../../../Firebase/App";
import { Stack } from "@mui/system";
import {
  FiberManualRecord,
  FiberManualRecordRounded,
} from "@mui/icons-material";
import Messages from "./components/Message";
import { inboxProps } from "../../types";
import {
  disActivateRoom,
  messagesListSelector,
  statusSelector,
} from "../../../../reducers/chatReducer";
import { getLastOnlineStatus } from "../../../../utils";
import moment from "moment";
import { Divider } from "@mui/material";
// import { Divider } from "primereact/divider";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Avatar from "../../../../components/common/Avatar";
import useWindowSize from "../../../../hooks/useWindowSize";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import { LOADING } from "../../../../config/constant";
import Spinner from "../../../../components/common/Spinner";
import InboxLoading from "../UI/InboxLoading";
import { AppDispatch } from "../../../../store";

const Inbox = ({
  setChatInbox,
  chatInbox,
  inboxDetails: { recipient, id },
}: inboxProps) => {
  // const messageList = useMessages(id);
  const dispatch = useDispatch<AppDispatch>();
  let navigate = useNavigate();
  function checkUserType(id: string) {
    if (id.includes("p")) {
      return "provider";
    } else {
      return "member";
    }
  }
  const messageData: any = useSelector(messagesListSelector);
  const messagesEndRef = useRef(null);
  const status = useSelector(statusSelector);
  const [sendMsgStatus, setSendMsgStatus] = React.useState("");

  let memoizedMessageList = useMemo(() => {
    return messageData;
  }, [messageData, id]);
  // console.log("messageList : ", messageList);

  useEffect(() => {
    scrollToBottom();
  }, [memoizedMessageList]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    // dispatch(fetchAllMessagesAction(id));
    return () => {};
  }, []);

  const { width } = useWindowSize();

  return (
    <div className="px-0 pb-2">
      {chatInbox ? (
        <>
          {status === LOADING ? (
            <InboxLoading />
          ) : (
            <>
              <div className="d-flex border-bottom border-1 align-items-center py-3 pe-4 ps-2 justify-content-between">
                <div className="d-flex align-items-center">
                  {width < 600 && (
                    <IconButton
                      onClick={() => {
                        dispatch(disActivateRoom());
                        setChatInbox(false);
                      }}
                      color="primary"
                      aria-label="upload picture"
                      component="label"
                    >
                      <ArrowBackIosIcon fontSize="small" />
                    </IconButton>
                  )}
                  <div
                    className="pointer"
                    onClick={() =>
                      navigate(
                        `/client/profile/${
                          recipient?.userId
                        }?type=${checkUserType(recipient?.id)}`
                      )
                    }
                  >
                    <Avatar
                      className="me-2"
                      online={false}
                      src={recipient?.avatarUrl}
                      name={recipient?.name}
                    />
                  </div>

                  <Stack>
                    <p
                      onClick={() =>
                        navigate(
                          `/client/profile/${
                            recipient?.userId
                          }?type=${checkUserType(recipient?.id)}`
                        )
                      }
                      className="mb-0 pointer"
                      style={{ fontSize: "13px", fontWeight: 600 }}
                    >
                      {recipient?.name}
                    </p>

                    {/* {recipient?.online ? (
                <div
                  className="mb-0  text-muted small"
                  style={{
                    fontSize: "10px",
                    fontWeight: 400,
                  }}
                >
                  <FiberManualRecord
                    sx={{
                      color: "#44b700",
                      fontSize: "13px",
                      marginRight: "1px",
                    }}
                  />
                  online
                </div>
              ) : recipient !== null ? (
                <div
                  className="mb-0  text-muted small"
                  style={{
                    fontSize: "10px",
                    fontWeight: 400,
                    fontStyle: "italic",
                  }}
                >
                  last seen {getLastOnlineStatus(recipient?.modifiedAt)}
                </div>
              ) : (
                <div
                  className="mb-0  text-muted small"
                  style={{
                    fontSize: "10px",
                    fontWeight: 400,
                  }}
                >
                  loading...
                </div>
              )} */}
                  </Stack>
                </div>
              </div>
              <div
                style={{ height: "50vh" }}
                className="p-4 overflow-auto slim-scroll"
              >
                {memoizedMessageList?.map(
                  (message: Message, idx: number, array: Message[]) => {
                    const element = message;
                    const chatTime = moment(
                      new Date(element.createdAt.date)
                    ).format("h:mm a");
                    const messageDate = moment(
                      new Date(element.createdAt.date)
                    );

                    // Determine the date display format based on the message age
                    let dateFormat = "";
                    if (messageDate.isSame(moment(), "day")) {
                      // If the message was sent today, show "Today"
                      dateFormat = "[Today]";
                    } else if (
                      messageDate.isAfter(moment().subtract(7, "weeks"))
                    ) {
                      // If the message was sent within the last 7 weeks, show the day of the week
                      dateFormat = "dddd";
                    } else {
                      // For older messages, show the date, month name, and year
                      dateFormat = "MMMM DD YYYY";
                    }

                    // Determine whether to display the date divider
                    let previousDate =
                      idx > 0
                        ? moment(new Date(array[idx - 1].createdAt.date))
                        : null;
                    let currentDate = moment(new Date(element.createdAt.date));
                    const displayDateDivider =
                      !previousDate || !currentDate.isSame(previousDate, "day");

                    // console.log(
                    //   "PreviousDate : ",
                    //   previousDate,
                    //   currentDate,
                    //   array.length
                    // );

                    return (
                      <Fragment key={idx.toString()}>
                        {displayDateDivider && (
                          <Divider>
                            <span
                              style={{ fontSize: "10px" }}
                              className="  py-1"
                            >
                              {messageDate.format(dateFormat)}
                            </span>
                          </Divider>
                        )}
                        <Messages
                          message={message}
                          type={message.recipientUid ? "sender" : "receiver"}
                          array={array}
                          idx={idx}
                          sendMsgStatus={sendMsgStatus}
                        />
                      </Fragment>
                    );
                  }
                )}
                <div ref={messagesEndRef} />
              </div>
            </>
          )}
          <div className="row">
            <SendMessage
              recipientData={{ recipient, id }}
              setSendMsgStatus={setSendMsgStatus}
            />
          </div>
        </>
      ) : (
        <Blank />
      )}
    </div>
  );
};

export default Inbox;

const Blank = () => {
  return (
    <>
      <div
        className="align-items-center d-flex justify-content-center"
        style={{ height: "64.5vh" }}
      >
        {/* <h4 className="mb-0 fw-bold mt-3 mb-1">No Chat Selected !</h4> */}
        {/* <p style={{ fontSize: '14px', color: '#667085', "textAlign": "center", }} className='mt-5 text-grey '>No Chat Selected !</p> */}
        <div
          // style={{ height: "415px", overflowX: "auto" }}
          className="mt-4 d-flex align-items-center justify-content-center mx-4"
        >
          <img
            src="/images/theme/empty_chat.svg"
            alt="no chat"
            className="pointer w-100"
            style={{ height: "400px" }}
          />
        </div>
      </div>
    </>
  );
};

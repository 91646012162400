import React from "react";
import { useSelector } from "react-redux";
import Modal from "../../../../components/common/Modal";
import { mediaSelector } from "../../../../reducers/mediaReducer";
import { RootState } from "../../../../store";
import { AddMediaModal } from "./AddMediaModal";
import { reverseArry } from "../../../../utils";
import MediaCard from "./MediaImg";
import MediaSkeletonLoading from "./MediaSkeletonLoading";

const MediaAvailableView = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const mediaData = useSelector(mediaSelector);
  const { status } = useSelector((state: RootState) => state.media);
  const imgStyle = {
    border: "2px #D0D5DD dotted",
    width: '100%',
    height: '120px',
  };

  return (
    <div
      style={{ minHeight: "40vh" }}
      className="row gy-4 row-cols-xl-6 row-cols-md-4 row-cols-2 w-100 m-auto px-2 pb-4"
    >
      <div className="col">
        <div
          style={imgStyle}
          onClick={handleOpen}
          className="rounded-5 d-flex align-items-center justify-content-center pointer"
        >
          <img
            height={50}
            src="/images/Media/plus.png"
            alt="upload"
          // className="mt-4"
          />
        </div>
        {/* <img
          onClick={handleOpen}
          height={140}
          width={140}
          src="/images/Media/add-image.svg"
          alt="avatar"
          className="pointer"
        /> */}
      </div>

      {status === "loading" ? (
        <MediaSkeletonLoading />
      ) : (
        reverseArry(mediaData).map(
          ({ thumbnail, url, type, tags, isLoading }) => (
            <div className="col">
              <MediaCard
                thumbnail={thumbnail}
                url={url}
                mediaType={type}
                services={tags}
                isLoading={isLoading}
              />
            </div>
          )
        )
      )}

      <Modal handleClose={handleClose} open={open}>
        <AddMediaModal handleClose={handleClose} />
      </Modal>
    </div>
  );
};

export default MediaAvailableView;

// {/* <div className="col">
//   <MediaCardpath={"/images/Media/media-avatar2.svg"} isVideo={false} />
// </div>
// <div className="col">
//   <MediaCardpath={"/images/Media/media-avatar.svg"} isVideo={true} />
// </div>
// <div className="col">
//   <MediaCardpath={"/images/Media/media-avatar2.svg"} isVideo={false} />
// </div>
// <div className="col">
//   <MediaCardpath={"/images/Media/media-avatar.svg"} isVideo={true} />
// </div> */}

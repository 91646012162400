import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import LoginForm from "../../../components/common/auth/LoginForm";
import { actionType, loginDataType } from "../../../components/common/types";
import { ERROR_MESS, TOKEN } from "../../../config/constant";
import {
  fetchLoggedInUserData,
  LoginRequest,
} from "../../../helper/provider/Auth";
import { logIn } from "../../../reducers/authReducer";
import { localStorageObject } from "../../../utils";
import { setAuth } from "../../../utils/AuthHelpers";
import { makeResponseError } from "../../../utils/errorHandler";
import { SHOW_ERROR, SHOW_INFO } from "../../../utils/ToastMessage";

const ProviderLogin = () => {
  const [action, setAction] = useState<actionType>({
    loading: false,
    error: null,
    message: "",
  });
  const [loginData, setLoginData] = useState<loginDataType>({
    username: "",
    password: "",
    grant_type: process.env.REACT_APP_GRANT_TYPE,
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const loginProvider = async (formData: loginDataType) => {
    setAction({ ...action, loading: true });

    try {
      const res: any = await LoginRequest(formData);
      const resProfile: any = await fetchLoggedInUserData(
        res.data.access_token
      );
      if (res?.data?.access_token) {
        if (resProfile.data?.success) {
          if (!resProfile?.data?.data?.isMobileVerified) {
            // console.log("unverified phone no : ", resProfile.data.data);
            navigate("/auth/verify-code", {
              state: { access_token: res.data.access_token },
              replace: true,
            });
          } else {
            if (resProfile?.data?.data?.service) {
              setAuth({
                access_token: res.data.access_token,
                refresh_token: res.data.access_token,
              });
              navigate(location.state ? location.state.from : "/");
              dispatch(logIn(resProfile.data?.data));
            } else {
              navigate("/auth/profile");
            }
          }
          // console.log(resProfile);
        }
        localStorageObject.set(TOKEN, res.data.access_token);
        // console.log("resProfile : ",resProfile);

        // window.location.replace(redirectUrl);

        // const { code, success, message, errors } = resProfile?.response?.data;

        // if (!success && code === 422) {
        //   SHOW_ERROR(true, Object.entries(errors)[0][1][0]);
        // } else {
        //   SHOW_INFO(true, "Some thing went wrong");
        // }

        // alert(resProfile?.data?.data?.message);
        // return resProfile?.data?.message;
      }
      // setAction({ ...action, loading: false });
    } catch (error) {
      // console.log("error", error);
      // SHOW_ERROR();

      setAction({
        ...action,
        loading: false,
        message: "The login detail is incorrect",
        error,
      });
      const { success, code, message, data } = error.response.data;
      if (!success && code === 400) {
        SHOW_ERROR(true, Object.entries(data)[0][1][0]);
      } else if (!success && code === 422) {
        SHOW_ERROR(true, Object.entries(data)[0][1][0]);
      } else {
        SHOW_ERROR(true, ERROR_MESS);
      }
      // return makeResponseError(error.response.data);
    }
  };

  const handleSubmit = (e: any) => {
    loginProvider(loginData);

    // console.log("loginData => ", loginData);
  };

  return (
    <div className="container-fluid px-0 h-100-hidden">
      <div className="row w-100 m-0 p-0">
        <div className="col-lg-6 p-0 bg-white">
          <div style={{ height: "auto" }}>
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                className="me-2"
                height={35}
                width={35}
                src="/images/KZH-PRO.jpeg"
                alt=""
              />
              <img src="/images/KZH-Written.svg" alt="" />
            </div>
            <div className="m-5 py-md-0 px-md-5 p-0 rounded">
              <h4 className="text-center auth-heading">Login</h4>
              <p className="fs-6 text-center mb-4 auth-tagline">
                Welcome back, Please enter your details
              </p>
              <LoginForm
                setLoginData={setLoginData}
                loginData={loginData}
                action={action}
                handleSubmit={handleSubmit}
              />

              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center mt-3"
              >
                Don't have an account?{" "}
                <Link
                  to="/auth/register"
                  style={{ color: "#04BAC7", fontWeight: "600" }}
                >
                  SignUp
                </Link>
              </p>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "600" }}
                className="fs-6 text-center mt-3"
              >
                <Link to="/auth/forgot" style={{ color: "#04BAC7" }}>
                  Forgot Password
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0 d-lg-block d-none">
          <div
            style={{ height: "110vh", backgroundColor: "#eff3fc" }}
            className="align-items-center d-flex justify-content-center bg-register"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProviderLogin;

import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import PrincipalTable from "./PrincipalTable";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  createPayment,
  deletePrincipal,
  paymentPrincipals,
  providerBoardingAction,
} from "../../../../reducers/paymentReducer";
import { AppDispatch } from "../../../../store";
import ModalC from "../../../../components/common/Modal";
import DeletePrincipalsModal from "./DeletePrincipalsModal";
import FormField from "../../../../components/UI/FormField";
import { AddContactsTable } from "./AddContactsTable";
import { payStep3 } from "../../../../components/common/types";
import AddOwnerShipTable from "./AddOwnerShipTable";
import { SHOW_ERROR } from "../../../../utils/ToastMessage";

const PayStep3 = ({
  setChangeStep,
  handleDataChange,
  setData,
  data,
  handleCheckChange,
}: {
  setChangeStep(string): void;
  handleDataChange: any;
  setData: any;
  data: any;
  handleCheckChange: any;
}): JSX.Element => {
  const initialState: payStep3 = {
    depositAccountBankName: data?.depositAccountBankName,
    contacts: data?.contacts,
    ownership: data?.ownership,
    depositAccountRoutingAccount: data?.depositAccountRoutingAccount,
    depositAccountAccountNumber: data?.depositAccountAccountNumber,
    depositAccountTypeAccount: data?.depositAccountTypeAccount,
    templateId: "",
    isAcceptVisa: false,
  };

  const [request, setRequest] = React.useState(false);
  const [change, setChange] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [id, setId] = useState<any>();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openContactModal, setOpenContactModal] = useState(false);
  const handleOpenContactModal = () => setOpenContactModal(true);
  const handleCloseContactModal = () => setOpenContactModal(false);

  const [openOwnershipModal, setOpenOwnershipModal] = useState(false);
  const handleOpenOwnershipModal = () => setOpenOwnershipModal(true);
  const handleCloseOwnershipModal = () => setOpenOwnershipModal(false);

  const dispatch = useDispatch<AppDispatch>();

  // console.log("data", data);

  const payPrincipals = useSelector(paymentPrincipals);

  const validationSchema = yup.object().shape({
    depositAccountBankName: yup.string().required("Required").nullable(),
    depositAccountRoutingAccount: yup.string().required("Required").nullable(),
    depositAccountAccountNumber: yup.string().required("Required").nullable(),
    depositAccountTypeAccount: yup.string().required("Required").nullable(),
  });

  // useEffect(() => {
  //   setData({
  //     ...data,
  //     principals: [principals],
  //   });
  //   return () => {};
  // }, [principals]);

  // function createData(
  //   principalPercent: string,
  //   firstName: string,
  //   lastName: string
  // ) {
  //   return { principalPercent, firstName, lastName };
  // }

  // console.log("principal", data?.principals);

  const { record } = useSelector((state: any) => state.payment);

  function onchange(e) {
    setRequest(true);
    handleDataChange(e);
  }

  return (
    <div style={{ maxWidth: "700px" }} className="my-4 mx-auto">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 px-0 text-center"
      >
        <div className="d-flex justify-content-between px-4 py-3 border-bottom">
          <div>
            <h5 className="mb-0">Payments</h5>
          </div>
          <p className="text-primary py-1 px-3 mb-0">Step 3/4</p>
        </div>
        <div className="form text-start pb-3 px-4 mb-3">
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              // console.log("value", values);
              // const finalData = {
              //   ...data,
              //   principals: [principals],
              // };
              if (request) {
                const finalData = {
                  ...values,
                  submitForApproval: 0,
                  id: record?.id,
                };
                if (data?.ownership?.length === 0) {
                  SHOW_ERROR(true, "Please add ownership");
                } else {
                  dispatch(
                    providerBoardingAction({ data: finalData, setChangeStep })
                  );
                  if (change) {
                    setChangeStep("Step4");
                  }
                }
              }
              resetForm();
            }}
          >
            {(formikHandler) => (
              <Form>
                <div className="form-group  mt-3">
                  {/* licence */}
                  <div className="row border rounded mt-4 pb-3">
                    <h6
                      style={{ fontSize: "1rem" }}
                      className="bg-light w-100 mt-0 py-3 text-dark fw-light"
                    >
                      Deposit Account
                    </h6>
                    <FormField
                      className="col-sm-6 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "bank name",
                        prop: "depositAccountBankName",
                        placeHolder: "Deposit account bank name here",
                      }}
                    />

                    {/* phoneNo */}
                    <FormField
                      className="col-sm-6 mt-sm-0 mt-3 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "routing account",
                        prop: "depositAccountRoutingAccount",
                        placeHolder: "Deposit account routing account here",
                      }}
                    />

                    {/* "faxNo": "121321", */}
                    <FormField
                      className="col-sm-6 col-12 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "account number",
                        prop: "depositAccountAccountNumber",
                        placeHolder: "Deposit account account number here",
                      }}
                    />

                    {/* "bAddress": "address", */}
                    <FormField
                      className="col-sm-6 col-12 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "account type",
                        prop: "depositAccountTypeAccount",
                        placeHolder: "Deposit account account type here",
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex flex-md-row flex-column justify-content-between align-items-md-stretch align-items-start">
                  <div
                    className="btn rounded-3 mt-4 ps-0 text-primary d-flex"
                    onClick={handleOpenOwnershipModal}
                  >
                    <AddIcon
                      fontSize="small"
                      sx={{ color: "#04bac7", marginRight: 1 }}
                    />
                    Add Ownership
                  </div>
                  <div className="d-flex">
                    <button
                      onClick={() => setChangeStep("ToPay2")}
                      className="btn btn-outline-secondary border rounded rounded-3 mt-4 px-5 mx-2"
                    >
                      Back
                    </button>
                    {payPrincipals?.length !== 0 && !request ? (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-primary rounded rounded-3 mt-4 px-5"
                          // type="submit"
                          onClick={() => {
                            if (data?.ownership?.length === 0) {
                              SHOW_ERROR(true, "Please add ownership");
                            } else {
                              setChangeStep("Step4");
                            }
                          }}
                        >
                          Next
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn btn-primary rounded rounded-3 mt-4 px-5"
                          type="submit"
                          onClick={() => setChange(true)}
                        >
                          Next
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ModalC handleClose={handleClose} open={open}>
        <DeletePrincipalsModal
          id={id}
          handleClose={handleClose}
          data={record}
        />
      </ModalC>
      <ModalC handleClose={handleCloseOwnershipModal} open={openOwnershipModal}>
        <AddOwnerShipTable
          ownerShip={data}
          onChange={onchange}
          handleClose={handleCloseOwnershipModal}
        />
      </ModalC>
    </div>
  );
};

export default PayStep3;

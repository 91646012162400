import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  scheduleStatus,
  updateMultiToMultiDays,
} from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import { initialScheduleDays } from "../../initialState";
import { ScheduleDaysPropTypes, scheduleDayType } from "../../type";
import DaysSkeletonLoading from "../DaysSkeletonLoading";

const ScheduleDays = ({ days, SelectedDays }: ScheduleDaysPropTypes) => {
  const dispatch = useDispatch<AppDispatch>();
  const { status } = useSelector((state: any) => state.schedule);

  // const Loading = useSelector(scheduleStatus);
  const handleDayActive = (id: number) => {
    dispatch(updateMultiToMultiDays(id));
  };

  return (
    <>
      {status === "loading" ? (
        <DaysSkeletonLoading />
      ) : (
        <>
          <div className="d-flex align-items-center mb-4">
            {SelectedDays?.map(({ id, day, isActive }: scheduleDayType) => (
              <div
                key={id}
                onClick={() => handleDayActive(id)}
                style={{ height: "45px", width: "45px", fontSize: "17px" }}
                className={`${
                  isActive ? "bg-primary" : "bg-light-grey"
                } rounded-circle p-1 me-md-3 me-2 d-flex align-items-center justify-content-center pointer w-days`}
              >
                <p
                  className={`m-0 p-0 ${
                    isActive ? "text-white" : "text-secondary"
                  }`}
                >
                  {day}
                </p>
              </div>
            ))}
          </div>
          {days?.length < 1 ? (
            <div className="text-danger">Atleast one day required</div>
          ) : null}
        </>
      )}
    </>
  );
};

export default ScheduleDays;

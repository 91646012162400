import React from "react";
import MediaSkeletonLoading from "../../media/components/MediaSkeletonLoading";
import { useSelector } from "react-redux";
import MediaCard from "../../media/components/MediaImg";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import Video from "yet-another-react-lightbox/plugins/video";
import { profileStatusSelector } from "../../../../reducers/clientReducer";
import UserProfileHeader from "../../../../components/common/UserProfileHeader";
import UserProfileFooter from "../../../../components/common/UserProfileFooter";
import Share from "yet-another-react-lightbox/plugins/share";
import Download from "yet-another-react-lightbox/plugins/download";

const ClientMedia = ({ media, publicProfile = false, openedTab }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [item, setItem] = React.useState<React.Key>(0);
  const status = useSelector(profileStatusSelector);
  const mediaData = media?.media;
  let mediaArray: any = [];
  if (mediaData) {
    // Check if mediaData is an object, convert to an array of objects
    if (typeof mediaData === "object" && !Array.isArray(mediaData)) {
      mediaArray = Object.values(mediaData);
    } else if (Array.isArray(mediaData)) {
      mediaArray = mediaData.slice(0, 8);
    }
  }

  return (
    <>
      <div
        className={`text-center slim-scroll margin-start-res ${
          openedTab === 0 ? "mt-5" : "mt-4"
        }`}
        style={{
          marginLeft: publicProfile ? "0rem" : "4.2rem",
          height: openedTab !== 0 ? "350px" : "",
          overflowY: "auto",
          overflowX: "hidden",
        }}
      >
        <div className="row g-4 row-cols-xl-4 row-cols-md-3 row-cols-2 pb-4 mb-1">
          {status === "loading" ? (
            <MediaSkeletonLoading />
          ) : mediaArray?.length == 0 ? (
            <div
              style={{ height: "210px", overflowX: "auto" }}
              className="mt-4 d-flex align-items-center justify-content-center w-100"
            >
              <label>
                <img
                  src="/images/Media/UploadImg.svg"
                  alt="Upload"
                  className="pointer"
                />
                <h6 className="mb-0 fw-bold mt-3 mb-1">No media here!</h6>
                <p
                  style={{ fontSize: "14px", color: "#667085" }}
                  className="mb-0 text-grey"
                >
                  Media will be show here!
                </p>
              </label>
            </div>
          ) : (
            mediaArray
              ?.slice(0, openedTab === 0 ? 4 : mediaArray?.length)
              ?.map(({ thumbnail, url, type, tags, isLoading }, idx) => (
                <>
                  <div className="col">
                    <MediaCard
                      idx={idx}
                      thumbnail={thumbnail}
                      url={url}
                      mediaType={type}
                      services={tags}
                      isLoading={isLoading}
                      setItem={setItem}
                      setIsOpen={setIsOpen}
                    />
                  </div>
                </>
              ))
          )}
        </div>
        {isOpen ? (
          <Lightbox
            styles={{
              container: {
                margin: "auto",
                backgroundColor: "rgba(0, 0, 0, .8)",
                zIndex: "100",
              },
            }}
            open={isOpen}
            index={Number(item)}
            close={() => setIsOpen(false)}
            slides={[
              ...mediaArray?.map((imageData) => {
                if (imageData?.type.includes("image")) {
                  return {
                    src: imageData?.url,
                    title: (
                      <UserProfileHeader
                        item={{
                          profile: {
                            ...imageData?.client,
                            src: imageData?.client?.profile_image,
                            name:
                              imageData?.client?.first_name +
                              " " +
                              imageData?.client?.last_name,
                          },
                        }}
                      />
                    ),
                    description: <UserProfileFooter item={imageData} />,
                  };
                } else {
                  return {
                    type: "video",
                    width: 1050,
                    height: 550,
                    poster: imageData?.thumbnail,
                    title: (
                      <UserProfileHeader
                        item={{
                          profile: {
                            ...imageData?.provider,
                            src: imageData?.profile_image,
                            name:
                              imageData?.provider?.first_name +
                              " " +
                              imageData?.provider?.last_name,
                          },
                        }}
                      />
                    ),
                    description: (
                      <UserProfileFooter
                        item={{ profile: imageData?.provider }}
                      />
                    ),
                    sources: [
                      {
                        src: imageData?.url,
                        type: imageData?.type,
                      },
                    ],
                  };
                }
              }),
            ]}
            render={
              {
                // buttonPrev: () => null,
                // buttonNext: () => null,
              }
            }
            video={{
              autoPlay: true,
              controls: true,
            }}
            plugins={[Captions, Video, Download, Share]}
          />
        ) : null}
      </div>
    </>
  );
};

export default ClientMedia;

import { Box } from "@mui/material";
import { IDProps } from "./type";
import { BootstrapTooltip } from "../BootstrapTooltip";

const ID = ({ text, bg, color, children, tooltip }: IDProps) => {
  if (!text && !children) {
    throw new Error("ID component requires either 'text' or 'children' prop.");
  }

  if (text && children) {
    throw new Error(
      "ID component requires only one of 'text' or 'children' prop."
    );
  }

  return (
    <BootstrapTooltip title={tooltip} placement="top">
      <Box
        component="div"
        sx={{
          display: "inline",
          p: "5px",
          m: "5px",
          bgcolor: (theme) =>
            theme.palette.mode === "dark" ? "#101010" : "#fff",
          color,
          border: "1px solid",
          borderColor: (theme) =>
            theme.palette.mode === "dark" ? "grey.800" : "grey.300",
          borderRadius: 2,
          fontSize: "0.675rem",
          fontWeight: "700",
        }}
      >
        {children ? children : text}
      </Box>
    </BootstrapTooltip>
  );
};

export { ID };

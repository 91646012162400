const COLORS = {
  bgLight: {
    backgroundColor: "#F3F4F6",
  },
  bgLightGrey: {
    backgroundColor: "#F3F4F6",
  },
};
const radiusEight = {
  borderRadius: "8px 8px 0px 8px",
};

export { COLORS, radiusEight };

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  fetchMemberProfile,
  memberProfile,
} from "../../../reducers/clientReducer";
import { AppDispatch } from "../../../store";
import ClientDetailChild from "./components/ClientDetailChild";
import ClientHistory from "./components/ClientHistory";
import ClientMedia from "./components/ClientMedia";
import ClientNotes from "./components/ClientNotes";
import { TabPanel, a11yProps } from "../../../components/common/Tabs/Config";
import { Box, Tab, Tabs } from "@mui/material";
import ProfileOverview from "./profileTabs/ProfileOverview";
import ProfileHistory from "./profileTabs/ProfileHistory";
import ProfileMedia from "./profileTabs/ProfileMedia";

const ClientDetail = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { Id }: any = useParams();
  const [openedTab, setOpenedTab] = useState<number>(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setOpenedTab(newValue);
  };
  useEffect(() => {
    dispatch(fetchMemberProfile(Id));
    return () => {};
  }, [Id, dispatch]);

  const profile = useSelector(memberProfile);

  const fDate = profile?.createdAt?.split("-");

  const year = fDate?.[2];

  return (
    <div className="mx-lg-5 my-4">
      <div
        style={{ minHeight: "72vh", maxWidth: "800px" }}
        className="container-fluid bg-white rounded-5 px-4 py-3"
      >
        <ClientDetailChild
          clientSince={year}
          src={profile?.profileImage}
          userName={profile?.username}
          date={"03/25/22"}
          phoneNo={profile?.mobileNo}
          mail={profile?.email}
          time={null}
          profile={profile}
          address={
            profile?.street +
            "," +
            " " +
            profile?.city +
            " " +
            profile?.state +
            " " +
            profile?.zip
          }
        />
        <Box sx={{ width: "100%" }}>
          <Box
            className="margin-start-res"
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginLeft: "4.2rem",
            }}
          >
            <Tabs
              textColor="secondary"
              indicatorColor="secondary"
              value={openedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
            >
              <Tab iconPosition="start" label={"Overview"} {...a11yProps(0)} />
              <Tab iconPosition="start" label={"History"} {...a11yProps(1)} />
              <Tab iconPosition="start" label={"Media"} {...a11yProps(2)} />
            </Tabs>
          </Box>
          <TabPanel value={openedTab} index={0}>
            <ProfileOverview profile={profile} openedTab={openedTab} />
          </TabPanel>
          <TabPanel value={openedTab} index={1}>
            <ProfileHistory profile={profile} openedTab={openedTab} />
          </TabPanel>
          <TabPanel value={openedTab} index={2}>
            <ProfileMedia profile={profile} openedTab={openedTab} />
          </TabPanel>
        </Box>
      </div>
    </div>
  );
};

export default ClientDetail;

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { resetPassword } from "../../../reducers/authReducer";
import { AppDispatch } from "../../../store";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { localStorageObject } from "../../../utils";
import { CODE } from "../../../config/constant";

type resetDataType = {
  resetToken?: string;
  password?: string;
  password_confirmation?: string;
};

const ResetPassword = () => {
  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [resetData, setResetData] = useState<resetDataType>({
    resetToken: localStorageObject.get(CODE),
    password: "",
    password_confirmation: "",
  });
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleSubmit = (e: any) => {
    e.preventDefault();
    dispatch(resetPassword({ data: resetData, navigate }));
    // navigate("/auth/verify-code")
    // console.log("registrationData => ", resetData);
  };

  const handleChange = (e) => {
    setResetData({
      ...resetData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <div className="container-fluid px-0 h-100-hidden">
      <div className="row w-100 m-0 p-0">
        <div className="col-lg-6 p-0 bg-white">
          <div style={{ height: "auto" }}>
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                className="me-2"
                height={35}
                width={35}
                src="/images/KZH-PRO.jpeg"
                alt=""
              />
              <img src="/images/KZH-Written.svg" alt="" />
            </div>
            <div className="m-5 py-md-0 px-md-5 p-0 rounded">
              <h4
                style={{ fontFamily: "Poppins, sans-serif" }}
                className="fw-bolder text-center"
              >
                Reset Password
              </h4>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center fw-bold mb-4"
              >
                Enter Your New Password
              </p>
              <div className="form">
                <form onSubmit={handleSubmit} className="php-email-form">
                  <div className="mt-3 form-group">
                    <label htmlFor="mobileNo" className="form-label">
                      New Password
                    </label>
                    <div className="input-group">
                      <input
                        type={toggle2 ? "text" : "password"}
                        className="form-control py-2"
                        placeholder="Enter New Passowrd"
                        name="password"
                        onChange={handleChange}
                      />
                      <span
                        onClick={() => setToggle2(!toggle2)}
                        className="input-group-text px-md-2 px-1 rounded-end pointer"
                        id="basic-addon1"
                      >
                        {toggle2 ? (
                          <RemoveRedEyeIcon fontSize="small" />
                        ) : (
                          <VisibilityOffIcon fontSize="small" />
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="mt-3 form-group">
                    <label htmlFor="mobileNo" className="form-label">
                      Confirm Password
                    </label>
                    <div className="input-group">
                      <input
                        type={toggle ? "text" : "password"}
                        className="form-control py-2"
                        placeholder="Confirm Passowrd"
                        name="password_confirmation"
                        onChange={handleChange}
                      />
                      <span
                        onClick={() => setToggle(!toggle)}
                        className="input-group-text px-md-2 px-1 rounded-end pointer"
                        id="basic-addon1"
                      >
                        {toggle ? (
                          <RemoveRedEyeIcon fontSize="small" />
                        ) : (
                          <VisibilityOffIcon fontSize="small" />
                        )}
                      </span>
                    </div>
                  </div>
                  <button
                    style={{ backgroundColor: "#04BAC7" }}
                    className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                    type="submit"
                  >
                    Reset Password
                  </button>
                </form>
              </div>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center fw-bold mt-3"
              >
                Already have an account?{" "}
                <Link to="/auth/login" style={{ color: "#04BAC7" }}>
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0 d-lg-block d-none">
          <div
            style={{ height: "110vh", backgroundColor: "#eff3fc" }}
            className="align-items-center d-flex justify-content-center bg-register"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;

import React, { useEffect } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SettingsIcon from "@mui/icons-material/Settings";
import SocialMedia from "./components/SocialMedia";
import NoMediaView from "./components/NoMediaView";
import ModalC from "../../../components/common/Modal";
import { AddMediaModal } from "./components/AddMediaModal";
import MediaAvailableView from "./components/MediaAvailableView";
import { AppDispatch } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchOwnMedia,
  fetchOwnSocialMedia,
} from "../../../reducers/mediaReducer";
import {
  fetchSecondLevelChildServices,
  servicesParentId,
} from "../../../reducers/serviceReducer";

const Media = () => {
  const dispatch = useDispatch<AppDispatch>();

  const parentId = useSelector(servicesParentId);

  useEffect(() => {
    dispatch(fetchOwnMedia());
    dispatch(fetchOwnSocialMedia());
    dispatch(fetchSecondLevelChildServices(parentId));
    return () => { };
  }, [parentId]);

  return (
    <div className="mx-lg-1 my-4">
      <div style={{ minHeight: '70vh' }} className="container-fluid bg-white rounded-5 px-0">
        <div className="d-flex justify-content-between px-4 py-3">
          <div>
            <h5 className="mb-0 main-head">Media</h5>
          </div>
          {/* <button className="btn btn-primary py-1 px-3">Save</button> */}
        </div>
        <SocialMedia />
        <div className="d-flex justify-content-start px-4 pt-3">
          <div>
            <h6 className="mb-0">Portfolio</h6>
          </div>
        </div>
        {/* <NoMediaView /> */}
        <MediaAvailableView />
      </div>
    </div>
  );
};

export default Media;

import React from "react";

const NoClientList = ({ handleOpen }) => {
  return (
    <div className="row">
      <div className="col-4 m-auto px-4 py-5 text-center mt-3">
        <label>
          <img src="/images/Media/UploadImg.svg" alt="avatar" className="" />
          <h6 className="mb-0 fw-bold mt-3 mb-1">No Client is in your list</h6>
          <p
            style={{ fontSize: "14px", color: "#667085" }}
            className="mb-0 text-grey"
          >
            You can add images from you favorite social media platforms e.g.
            Facebook, Instagram, Twitter etc.
          </p>
        </label>
        <button
          style={{ fontSize: "16px" }}
          className="btn btn-primary mt-3 border-b5"
          onClick={handleOpen}
        >
          <img
            height={15}
            src="/images/clientList/invite.png"
            alt="plus"
            className="mx-2"
          />
          Client
        </button>
      </div>
    </div>
  );
};

export default NoClientList;

import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { mediaSelector } from "../../../reducers/mediaReducer";
import { RootState } from "../../../store";
import { reverseArry } from "../../../utils";
import MediaCard from "../../provider/media/components/MediaImg";
import MediaSkeletonLoading from "../../provider/media/components/MediaSkeletonLoading";

const ProviderMedia = ({ media, publicProfile = false }) => {
  const { status } = useSelector((state: RootState) => state.media);
  const mediaData = useSelector(mediaSelector);
  const navigate = useNavigate();

  const [toggle, setToggle] = React.useState(false);

  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };
  const whiteText = {
    fontSize: "13px",
  };
  return (
    <>
      <div
        className="mt-4 text-center"
        style={{ marginLeft: publicProfile ? "0rem" : "4.2rem" }}
      >
        <p
          className={`mb-2 mt-1 text-dark text-start`}
          style={{ fontSize: "18px" }}
        >
          Media:
        </p>
        <div className="row gy-3 row-cols-xl-4 row-cols-md-3 row-cols-2 w-100 m-auto pb-4 justify-content-center mb-1">
          {status === "loading" ? (
            <MediaSkeletonLoading />
          ) : (
            reverseArry(media)
              ?.slice(0, 8)
              ?.map(({ thumbnail, url, type, tags, isLoading }) => (
                <div className="col">
                  <MediaCard
                    thumbnail={thumbnail}
                    url={url}
                    mediaType={type}
                    services={tags}
                    isLoading={isLoading}
                  />
                </div>
              ))
          )}
        </div>
        {media?.media?.length < 1 ? (
          <></>
        ) : (
          <u
            onClick={() => navigate("/media")}
            className="text-center text-primary pointer"
          >
            View All
          </u>
        )}
      </div>
    </>
  );
};

export default ProviderMedia;

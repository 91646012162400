import { Skeleton } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN, PROVIDER } from "../../config/constant";
import {
  deleteAccountRequest,
  deleteProviderPic,
} from "../../helper/provider/Auth";
import { fetchFullRootServiceRequest } from "../../helper/provider/services";
import {
  imgStatusSelector,
  loggedInUserProfile,
  profileLoadingSelector,
  setLogOut,
  statusSelector,
  updateProfile,
  updateProfilePicture,
  updateStatus,
} from "../../reducers/authReducer";
import { AppDispatch } from "../../store";
import useCoordinates from "../../hooks/useRandomCordinates";
import { getUserCardsAction } from "../../reducers/paymentReducer";
import PaymentCard from "./components/PaymentCard";
import { removeAuth } from "../../utils/AuthHelpers";
import { localStorageObject, removePageLocation } from "../../utils";
import { DESTROY_SESSION } from "../../reducers/actions";
import OverlayLoading from "../../components/common/OverlayLoading";
import * as Yup from "yup";
import { ErrorMessage, Form, Formik } from "formik";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import axios from "axios";
import TextArea from "../../components/common/Textarea";

interface Options {
  label: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

const UserProfile = () => {
  const avatar = {
    height: "64px",
    width: "64px",
  };

  const profile = useSelector(loggedInUserProfile);

  const [profileData, setProfileData] = useState<any>(profile);
  const [readOnlyData, setReadOnlyData] = useState<any>(profile);
  const [formToggle, setFormToggle] = useState(true);
  // console.log(
  //   "🚀 ~ file: UserProfile.tsx:36 ~ UserProfile ~ profileData:",
  //   profileData
  // );

  useEffect(() => {
    setProfileData(profile);
    setReadOnlyData(profile);

    return () => {};
  }, [profile, formToggle]);

  const [coordinates, generateCoordinates] = useCoordinates();

  // const [profileData, setProfileData] = useState<any>();
  const [categories, setCategories] = useState<any>();
  const [Image, setImage] = useState<any>({
    image: { blob: "", src: null },
  });

  const navigate = useNavigate();

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    getCategories();
    generateCoordinates();
    return () => {};
  }, [Image]);

  var newToken = localStorageObject.get(AUTH_TOKEN);
  var access_token = JSON.parse(newToken);
  var token = access_token.access_token;

  const deleteImage = async () => {
    const res = await deleteProviderPic(profileData?.id);
    const data = res.data.data;
    return data;
  };

  const getCategories = async () => {
    const res = await fetchFullRootServiceRequest(token);
    const categories = res.data.data;
    setCategories(categories);
    // console.log("categories", categoires);
  };

  const [toggle, setToggle] = useState({ edit: false });

  const handleDataChange = (e) => {
    setProfileData({
      ...profileData,
      [e.target.name]: e.target.value,
    });
  };

  const onImageChange = (event) => {
    // console.log("logged ");

    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      // console.log("img from profile ", img);
      setImage({
        ...Image,
        image: {
          blob: img,
          src: URL.createObjectURL(img),
        },
      });
      const formData = new FormData();
      formData.append("file", img);
      dispatch(updateProfilePicture({ data: formData, token: token }));
    }
  };

  useEffect(() => {
    dispatch(getUserCardsAction({ type: PROVIDER, id: profileData?.id }));
  }, [dispatch, profileData?.id]);

  //   const handleImagfeSubmit = (e) => {
  //     e.preventDefault();
  //     const formData = new FormData();
  //     formData.append("file", Image?.image?.blob);
  //     dispatch(updateProfilePicture({ data: formData, token: token }));
  //   };

  const handleDeleteAccount = async (e) => {
    e.preventDefault();
    try {
      const res = await deleteAccountRequest({
        mobileNo: profileData?.mobileNo,
      });
      const { data, success } = res.data;
      if (success) {
        removeAuth();
        removePageLocation();
        dispatch({ type: DESTROY_SESSION });
        dispatch(setLogOut());
        navigate("/auth/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    street: Yup.string().nullable().required("Street Address is required"),
    city: Yup.string().nullable().required("City is required"),
    state: Yup.string().nullable().required("State is required"),
    zip: Yup.string().nullable().required("ZIP is required"),
    email: Yup.string()
      .nullable()
      .matches(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Invalid email address"
      )
      .required("Email is required"),
    businessName: Yup.string().nullable().required("Business name is required"),
    bio: Yup.string().nullable().max(255, "Max 255 characters allowed"),
  });

  const handleSubmit = (e) => {
    // console.log("profile data", profileData);
    const finalData = {
      ...profileData,
      profileImage: profile?.profileImage,
      state: e?.state,
      city: e?.city,
      street: e?.street,
      zip: e?.zip,
    };
    // console.log("profile data", profileData);
    dispatch(
      updateProfile({
        data: finalData,
        token: token,
        update: true,
        setFormToggle,
      })
    );
  };

  const handleImageDelete = () => {
    setProfileData({
      ...profileData,
      profileImage: null,
    });
  };

  const status = useSelector(profileLoadingSelector);
  const updateProfileStatus = useSelector(updateStatus);
  const imgStatus = useSelector(imgStatusSelector);

  // console.log(
  //   "🚀 ~ file: UserProfile.tsx:145 ~ sendUserData ~ profileData?.Id:",
  //   profileData?.id
  // );

  const [options, setOptions] = useState<Options[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAddressChange = async (e) => {
    setLoading(true);
    if (e.target.value.length > 3) {
      const res: any = await axios.post(
        "https://api.postgrid.com/v1/addver/completions",
        {
          partialStreet: e.target.value,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_POSTGRID_API_KEY,
          },
        }
      );
      const data = res.data.data;
      if (data?.length !== 0) {
        setOptions(
          data.map((m) => {
            return {
              label:
                m?.address?.address +
                " " +
                m?.address?.city +
                " " +
                m?.address?.prov +
                ", " +
                m?.address?.pc,
              street: m?.address?.address,
              city: m?.address?.city,
              state: m?.address?.prov,
              zip: m?.address?.pc,
            };
          })
        );
      } else {
        setLoading(false);
        setOptions([]);
      }
    }
  };

  useEffect(() => {
    if (options?.length !== 0) {
      setOptions(options);
    }
    return () => {};
  }, [options]);

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "72vh" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="d-flex align-items-center justify-content-between px-4 py-3 border-bottom">
          <div>
            <h5 className="mb-0 main-head">Profile</h5>
          </div>
          <div>
            {/* <span>{JSON.stringify(coordinates)}</span> */}
            {!formToggle ? (
              <button
                style={{ whiteSpace: "nowrap" }}
                className="btn btn-outline-secondary border-b5  border me-2 hover-primary2"
                onClick={() => setFormToggle(!formToggle)}
              >
                Cancel
              </button>
            ) : null}
            {!formToggle ? (
              <button
                className="btn btn-primary py-1 px-3"
                // onClick={handleSubmit}
                form="profileForm"
                type="submit"
              >
                Save
              </button>
            ) : (
              <div>
                <button
                  className="btn btn-primary py-1 px-3"
                  onClick={() => setFormToggle(!formToggle)}
                >
                  Edit
                </button>
                {process.env.NODE_ENV === "development" ? (
                  <button
                    onClick={handleDeleteAccount}
                    className="btn btn-danger py-1 px-3 ms-2"
                  >
                    Delete account
                  </button>
                ) : null}
              </div>
            )}
          </div>
        </div>
        <div className="container-fluid py-4 px-xxl-5 px-4">
          <div className="row pb-2">
            <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6 mb-md-0 mb-2">
              Your Profile Photo
            </label>
            <div className="col-xl-6 col-md-7 col-12">
              <div
                onMouseLeave={() => setToggle({ ...toggle, edit: false })}
                className="d-flex justify-content-between align-items-center"
              >
                {status === "loading" ? (
                  <Skeleton variant="circular" style={{ ...avatar }} />
                ) : (
                  <div
                    className="pointer"
                    // onMouseEnter={() => setToggle({ ...toggle, edit: true })}
                  >
                    <img
                      style={{ ...avatar }}
                      src={
                        profile?.profileImage !== null || ""
                          ? profile?.profileImage
                          : "/images/avatar2.png"
                      }
                      alt="avatar"
                      className="rounded-circle border border-primary"
                    />
                  </div>
                )}
                <div>
                  {/* <span
                    className="mb-0 me-3 pointer"
                    onClick={() => {
                      handleImageDelete();
                      deleteImage();
                    }}
                  >
                    Delete
                  </span> */}
                  {!formToggle ? (
                    <label
                      // style={{ marginLeft: '-3.5rem' }}
                      className="mb-0 text-primary pointer ms-2"
                      id="image"
                      // onClick={handleImageSubmit}
                    >
                      Update
                      <input
                        onChange={onImageChange}
                        type="file"
                        name="image"
                        id="image"
                        hidden
                      />
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <hr />
          <Formik
            initialValues={profile}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // console.log("values", values);
              setReadOnlyData(values);

              handleSubmit(values);
            }}
            enableReinitialize={true}
          >
            {({ errors, touched, values, handleBlur, handleChange }) => (
              <Form id="profileForm">
                <div className="row pt-3 pb-1">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Username
                  </label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status === "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <input
                        name="username"
                        type="text"
                        // onChange={handleChange}
                        className="form-control py-2"
                        value={values?.username}
                        id="username"
                        disabled
                        placeholder="Username"
                      />
                    )}
                  </div>
                </div>
                <div className="row py-3">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Full Name
                  </label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {[
                      status === "loading" ? (
                        <Skeleton
                          style={{ lineHeight: "3rem" }}
                          animation="pulse"
                        />
                      ) : (
                        <div className="d-flex">
                          <div
                            style={{
                              width: "50%",
                            }}
                            className="me-1"
                          >
                            <input
                              name="firstName"
                              type="text"
                              value={
                                !formToggle
                                  ? profileData?.firstName
                                  : readOnlyData?.firstName
                              }
                              onChange={(e) => {
                                handleDataChange(e);
                                handleChange(e);
                              }}
                              readOnly={formToggle}
                              className={`form-control ${
                                errors.firstName && touched.firstName
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="name"
                              placeholder="First Name"
                            />
                            <ErrorMessage
                              name="firstName"
                              component="span"
                              className="invalid-feedback"
                            />
                          </div>
                          <div
                            style={{
                              width: "50%",
                            }}
                          >
                            <input
                              name="lastName"
                              type="text"
                              value={
                                !formToggle
                                  ? profileData?.lastName
                                  : readOnlyData?.lastName
                              }
                              onChange={(e) => {
                                handleDataChange(e);
                                handleChange(e);
                              }}
                              readOnly={formToggle}
                              className={`form-control ${
                                errors.lastName && touched.lastName
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="name"
                              placeholder="Last Name"
                            />
                            <ErrorMessage
                              name="lastName"
                              component="span"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      ),
                    ]}
                  </div>
                </div>
                <div className="row pb-3">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Email Address
                  </label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status == "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <>
                        <input
                          name="email"
                          type="email"
                          value={
                            !formToggle
                              ? profileData?.email
                              : readOnlyData?.email
                          }
                          onChange={(e) => {
                            handleDataChange(e);
                            handleChange(e);
                          }}
                          readOnly={formToggle}
                          className={`form-control ${
                            errors.email && touched.email ? "is-invalid" : ""
                          }`}
                          id="email"
                          placeholder="abc@abc.com"
                        />
                        <ErrorMessage
                          name="email"
                          component="span"
                          className="invalid-feedback"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="row pb-3">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Phone Number
                  </label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status == "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <input
                        name="email"
                        type="text"
                        value={values?.mobileNo}
                        // onChange={handleChange}
                        className="form-control py-2"
                        id="email"
                        disabled
                        placeholder="Phone No"
                      />
                    )}
                  </div>
                </div>
                <div className="row">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Bio
                  </label>
                  <div className="col-xl-6 col-md-7 col-12 mb-1">
                    {status === "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <>
                        {/* <textarea
                          name="bio"
                          onChange={(e) => {
                            handleDataChange(e);
                            handleChange(e);
                          }}
                          className={`form-control ${
                            errors.bio && touched.bio ? "is-invalid" : ""
                          }`}
                          style={{ resize: "none" }}
                          id="bio"
                          readOnly={formToggle}
                          value={
                            !formToggle ? profileData?.bio : readOnlyData?.bio
                          }
                          placeholder="Enter Your Bio"
                          cols={8}
                          rows={4}
                        /> */}
                        <TextArea
                          handleChange={(e) => {
                            handleDataChange(e);
                            handleChange(e);
                          }}
                          errors={errors?.bio}
                          touched={touched?.bio}
                          name={"bio"}
                          value={
                            !formToggle ? profileData?.bio : readOnlyData?.bio
                          }
                          toggle={formToggle}
                          placeholder={"Enter your bio"}
                        />
                      </>
                    )}
                  </div>
                </div>
                <hr />
                <div className="row py-3">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Channel
                  </label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status === "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <>
                        <input
                          type="text"
                          name="serviceId"
                          className={`form-control`}
                          value={values?.service?.service?.name}
                          disabled
                        />
                        <ErrorMessage
                          name="serviceId"
                          component="span"
                          className="invalid-feedback"
                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="row pb-1">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Business Name
                  </label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status === "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <>
                        <input
                          name="businessName"
                          type="text"
                          value={
                            !formToggle
                              ? profileData?.businessName
                              : readOnlyData?.businessName
                          }
                          onChange={(e) => {
                            handleDataChange(e);
                            handleChange(e);
                          }}
                          readOnly={formToggle}
                          className={`form-control ${
                            errors.businessName && touched.businessName
                              ? "is-invalid"
                              : ""
                          }`}
                          id="businessName"
                          placeholder="Business Name"
                        />
                        <ErrorMessage
                          name="businessName"
                          component="span"
                          className="invalid-feedback"
                        />
                      </>
                    )}
                  </div>
                </div>
                <hr />
                {formToggle ? null : (
                  <div className="row py-3">
                    <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6"></label>
                    <div className="col-xl-6 col-md-7 col-12">
                      <Autocomplete
                        id="asynchronous-demo"
                        freeSolo
                        isOptionEqualToValue={(option, value) => {
                          return option.street === value.street;
                        }}
                        getOptionLabel={(option: any) => option.label}
                        options={options}
                        open={open}
                        sx={{ width: "100%" }}
                        onChange={(e, value) => {
                          const data: any = value;
                          handleChange({
                            target: {
                              name: "street",
                              value: data?.street,
                            },
                          });
                          handleChange({
                            target: {
                              name: "city",
                              value: data?.city,
                            },
                          });
                          handleChange({
                            target: {
                              name: "state",
                              value: data?.state,
                            },
                          });
                          handleDataChange({
                            target: {
                              name: "state",
                              value: data?.state,
                            },
                          });
                          handleChange({
                            target: {
                              name: "zip",
                              value: data?.zip,
                            },
                          });
                        }}
                        onError={(e) => {
                          console.log(e);
                          setOptions([]);
                        }}
                        onBlur={(e) => {
                          setOptions([]);
                          setLoading(false);
                        }}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        filterOptions={(x) => x}
                        loading={loading}
                        noOptionsText="No Address Found"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Your Address"
                            // className="autocomplete"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "no",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "1px solid #ced4da",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#ced4da",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "1px solid #ced4da",
                                  boxShadow: "0 0 0 0.25rem #cdf1f4",
                                },
                              },
                            }}
                            size="small"
                            onChange={(e) => {
                              handleAddressChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                  </div>
                )}
                <div className="row pt-3 pb-1">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6">
                    Address
                  </label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status === "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <>
                        <input
                          name="street"
                          type="text"
                          // onChange={handleChange}
                          readOnly={true}
                          value={
                            !formToggle ? values?.street : readOnlyData?.street
                          }
                          className={`form-control py-2 ${
                            errors.street && touched.street ? "is-invalid" : ""
                          }`}
                          id="street"
                          placeholder="Street"
                        />
                        <ErrorMessage
                          name="street"
                          component="span"
                          className="invalid-feedback"
                        />
                      </>
                    )}
                  </div>
                </div>
                {/* <div className="row py-3">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6"></label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status === "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <>
                        <input
                          name="city"
                          type="text"
                          // onChange={handleChange}
                          readOnly={true}
                          value={values?.city}
                          className={`form-control py-2 ${
                            errors.city && touched.city ? "is-invalid" : ""
                          }`}
                          id="city"
                          placeholder="City"
                        />
                        <ErrorMessage
                          name="city"
                          component="span"
                          className="invalid-feedback"
                        />
                      </>
                    )}
                  </div>
                </div> */}
                <div className="row pb-3 pt-1">
                  <label className="col-xxl-4 col-xl-3 col-md-2 col-12 col-form-label fs-6"></label>
                  <div className="col-xl-6 col-md-7 col-12">
                    {status === "loading" ? (
                      <Skeleton
                        style={{ lineHeight: "3rem" }}
                        animation="pulse"
                      />
                    ) : (
                      <div className="row gx-1">
                        <div className="col-4">
                          <input
                            name="city"
                            type="text"
                            // onChange={handleChange}
                            readOnly={true}
                            value={
                              !formToggle ? values?.city : readOnlyData?.city
                            }
                            className={`form-control py-2 ${
                              errors.city && touched.city ? "is-invalid" : ""
                            }`}
                            id="city"
                            placeholder="City"
                          />
                          <ErrorMessage
                            name="city"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-4">
                          <input
                            id="principals"
                            type="text"
                            name="state"
                            className={`form-control py-2 ${
                              errors.state && touched.state ? "is-invalid" : ""
                            }`}
                            // onChange={(e) => {
                            //   handleDataChange(e);
                            //   handleChange(e);
                            // }}
                            value={
                              !formToggle ? values?.state : readOnlyData?.state
                            }
                            disabled={true}
                            placeholder="State"
                          />
                          <ErrorMessage
                            name="state"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-4">
                          <input
                            name="zip"
                            type="text"
                            min={0}
                            // onChange={(e) => {
                            //   if (
                            //     e.target.value.match(/[a-zA-Z]/g) ||
                            //     e.target.value.match(
                            //       /[!$%^&*()_+|~=`{}[:;<>?,.@#\]]/g
                            //     )
                            //   ) {
                            //     e.preventDefault();
                            //   } else {
                            //     handleChange(e);
                            //   }
                            // }}
                            value={
                              !formToggle ? values?.zip : readOnlyData?.zip
                            }
                            readOnly={true}
                            className={`form-control py-2 ${
                              errors.zip && touched.zip ? "is-invalid" : ""
                            }`}
                            id="zip"
                            placeholder="Zip"
                          />
                          <ErrorMessage
                            name="zip"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
          <hr />
          <PaymentCard id={profileData?.id} />
        </div>
      </div>
      <OverlayLoading
        active={updateProfileStatus === "loading" ? true : false}
      />
    </div>
  );
};

export default UserProfile;

import React from "react";
import { Skeleton } from "@mui/material";

const RequestLoading = () => {
  return (
    <>
      <div
        style={{
          borderRadius: "6px",
        }}
        className={`d-flex p-2 mt-2 justify-content-between mb-4
                }`}
      >
        <div className="d-flex">
          <Skeleton
            className="me-3"
            variant="circular"
            width={35}
            height={35}
          />
          <div className="">
            <p
              className={`mb-0 fw-bold mb-1 text-primary mx-2`}
              style={{ fontSize: "15px" }}
            >
              <Skeleton width={90} animation="pulse" />
            </p>
            <p
              style={{ color: "grey", fontSize: "13px" }}
              className="mb-0 mt-1 mx-2"
            >
              <Skeleton width={60} animation="pulse" />
            </p>
            <p
              style={{ color: "grey", fontSize: "15px" }}
              className="mb-0 mt-1 text-primary pointer mx-2"
            >
              <Skeleton width={120} animation="pulse" />
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export const SingleRequestLoading = () => {
  return (
    <>
      <div
        style={{
          borderRadius: "6px",
        }}
        className={`d-flex mt-4 justify-content-between
                }`}
      >
        <div className="d-flex">
          <Skeleton
            className="me-lg-2 me-4"
            variant="circular"
            width={35}
            height={35}
          />
          <div className="">
            <Skeleton className="mt-0" width={220} animation="pulse" />
            <Skeleton className="my-3" width={220} animation="pulse" />
            <Skeleton className="my-3" width={220} animation="pulse" />
          </div>
        </div>
      </div>
    </>
  );
};

const PendingRequestsLoading = ({ requests }) => {
  return (
    <div className="p-4">
      <p
        style={{ color: "grey", fontSize: "15px" }}
        className="mb-0 mt-1 text-primary pointer mx-2"
      >
        <Skeleton width={90} animation="pulse" />
      </p>
      <div style={{ height: "1030px" }} className="overflowYscroll slim-scroll">
        {requests?.map((p) => (
          <RequestLoading key={p} />
        ))}
      </div>
    </div>
  );
};

export default PendingRequestsLoading;

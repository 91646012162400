import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";

const RegistrationRequest = async (data: any) =>
  client.post(provider_URLS.Auth.REGISTRATION, data);

const LoginRequest = async (data: any) =>
  client.post(provider_URLS.Auth.LOGIN, data);

const VerifyCode = async (data: any, token: any) =>
  client.post(provider_URLS.Auth.OTP, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const ResendCodeRequest = async (token: any) =>
  client.get(provider_URLS.Auth.RESEND_OTP, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const verifyReset = async (data: any) =>
  client.post(provider_URLS.Auth.VERIFY_RESET_CODE, data);

const fetchLoggedInUserData = async (token: any) => {
  try {
    return await client.get(provider_URLS.Auth.USER_PROFILE, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  } catch (error) {
    return error;
  }
};

const deleteProviderPic = async (id: any) => {
  try {
    return await client.get(provider_URLS.Auth.DELETE_PROVICER_PICTURE(id), {});
  } catch (error) {
    return error;
  }
};

const fetchProviderProfileData = async (id: any) => {
  try {
    return await client.get(provider_URLS.Auth.PROVIDER_PROFILE(id), {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
  } catch (error) {
    return error;
  }
};

const fetchProviderPublicProfileData = async (id: any) => {
  try {
    return await client.get(provider_URLS.Auth.PROVIDER_PUBLIC_PROFILE(id), {
      // headers: {
      //   Authorization: `Bearer ${token}`,
      // },
    });
  } catch (error) {
    return error;
  }
};

const updateUserProfile = async (data: any, token: any) =>
  client.put(provider_URLS.Auth.UPDATE_PROFILE, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const updateProfilePhoto = async (data: any, token: any) =>
  client.post(provider_URLS.Auth.PROFILE_PHOTO, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const userProfileProgressRequest = async () => {
  try {
    return await client.get(provider_URLS.Auth.PROFILE_PROGRESS);
  } catch (error) {
    return error;
  }
};

const forgotPass = async (data: any) =>
  client.post(provider_URLS.Auth.FORGOT_PASS, data);

const resetPass = async (data: any) =>
  client.post(provider_URLS.Auth.RESET_PASS, data);

const getDashBoardDataRequest = async () => {
  try {
    return await client.get(provider_URLS.Auth.GET_DASHBOARD_DATA);
  } catch (error) {
    return error;
  }
};

const deleteAccountRequest = async (data) => {
  try {
    return await client.post(provider_URLS.Auth.DELETE_ACCOUNT, data);
  } catch (error) {
    return error;
  }
};

export {
  RegistrationRequest,
  LoginRequest,
  VerifyCode,
  fetchLoggedInUserData,
  updateUserProfile,
  userProfileProgressRequest,
  updateProfilePhoto,
  forgotPass,
  resetPass,
  verifyReset,
  fetchProviderProfileData,
  deleteProviderPic,
  fetchProviderPublicProfileData,
  getDashBoardDataRequest,
  ResendCodeRequest,
  deleteAccountRequest,
};

import React, { Children } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400, lg: 650, xl: 900 },
  bgcolor: "transparent",
  borderRadius: "7px",
  //   boxShadow: 24,
  //   p: 3,
  // border: '2px solid #000',
};

const ImageModal = ({ handleClose, open, children }) => {
  return (
    <Modal
      style={{ overflow: "auto" }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          ...style,
          // focus will not be on the modal
          "&:focus": {
            outline: "none",
          },
        }}
      >
        {children}
      </Box>
    </Modal>
  );
};

export default ImageModal;

import { getTimeAgo, reverseArry } from "..";
import { Appointment } from "../../components/common/types";
import { scheduleDayType } from "../../pages/provider/schedule/type";

function makeExploreUser(profileData: {
  id: string | number;
  address: string;
  profile_image: string;
  first_name: string;
  last_name: string;
  username: string;
  street: string;
  city: string;
  state: string;
}) {
  return {
    id: profileData?.id,
    address: profileData?.address,
    src: profileData?.profile_image,
    name: `${profileData?.first_name ? profileData?.first_name : "User"} ${
      profileData?.last_name ? profileData?.last_name : ""
    }`,
    userName: profileData?.username,
    street: profileData?.street,
    city: profileData?.city,
    state: profileData?.state,
  };
}

export const makeExploreData = (data: any[]) => {
  return data
    ?.filter(({ thumbnail, url }) => thumbnail !== null && url !== null)
    ?.map(
      (media: {
        tags: { service: any }[];
        provider: any;
        client: any;
        caption: string;
        dateTime: string;
        id: number;
        isFav: number;
        isFavourite: any;
        isProviderPrivate: any;
        isClientPrivate: any;
        latitude: string;
        longitude: string;
        location: any;
        mediaLike: number;
        mediaLikeCounts: number;
        service: any;
        thumbnail: any;
        appointment: boolean;
        type: string;
        url: string;
      }) => {
        if (media.provider) {
          return {
            appointment: media.appointment ? true : false,
            caption: media?.caption,
            dateTime: media?.dateTime,
            id: media?.id,
            isFav: media?.isFav,
            isFavourite: media?.isFavourite,
            isPrivate: media?.isProviderPrivate,
            isClientPrivate: media?.isClientPrivate,
            latitude: media?.latitude,
            longitude: media?.longitude,
            location: media?.location,
            mediaLike: media?.mediaLike,
            mediaLikeCounts: media?.mediaLikeCounts,
            service: media?.service,
            thumbnail: media?.thumbnail,
            type: media?.type,
            url: media?.url,
            profile: makeExploreUser(media.provider),
            isProvider: true,
            tags: media?.tags?.map(({ service }) => {
              return { id: service.id, name: service.name };
            }),
          };
        } else {
          return {
            caption: media?.caption,
            dateTime: media?.dateTime,
            id: media?.id,
            isFav: media?.isFav,
            isFavourite: media?.isFavourite,
            isPrivate: media?.isProviderPrivate,
            latitude: media?.latitude,
            longitude: media?.longitude,
            location: media?.location,
            mediaLike: media?.mediaLike,
            mediaLikeCounts: media?.mediaLikeCounts,
            service: media?.service,
            thumbnail: media?.thumbnail,
            type: media?.type,
            url: media?.url,
            profile: makeExploreUser(media.client),
            isProvider: false,
            tags: media?.tags?.map(({ service }) => {
              return { id: service.id, name: service.name };
            }),
          };
        }
      }
    );
};

export const makeCalenderPerchi = (
  data: {
    id: number;
    bookedBy: string;
    appointmentDate: Date;
    startTime: string;
    endTime: string;
    client: any;
    totalDuration: string;
    services: unknown;
    status: string;
    appointmentAddress: string;
    isReservationFee: number;
    reservationFee: number | string;
  }[]
) => {
  return data
    ?.filter((f) => {
      return (
        f.status !== "cancelledByMember" && f.status !== "declineByProvider"
      );
    })
    .map(
      ({
        id,
        bookedBy,
        appointmentDate,
        startTime,
        endTime,
        client,
        totalDuration,
        services,
        appointmentAddress,
        isReservationFee,
        reservationFee,
        status,
      }) => {
        return {
          id: id,
          title: bookedBy,
          fistName: client?.firstName ? client?.firstName : "NA",
          lastName: client?.lastName ? client?.lastName : "NA",
          start: new Date(`${appointmentDate} ${startTime}`),
          end: new Date(`${appointmentDate} ${endTime}`),
          clientImg: client?.profileImage,
          totalDuration: totalDuration,
          Date: appointmentDate,
          services: services,
          location: appointmentAddress ? appointmentAddress : client?.address,
          isReservationFee,
          reservationFee,
          status,
        };
      }
    );
};

export const makeSchedule = (ele: any, state: any) => {
  let newDAY = [
    { id: 0, day: "M", name: "Monday", isActive: false },
    { id: 1, day: "T", name: "Tuesday", isActive: false },
    { id: 2, day: "W", name: "Wednesday", isActive: false },
    { id: 3, day: "TR", name: "Thursday", isActive: false },
    { id: 4, day: "F", name: "Friday", isActive: false },
    { id: 5, day: "S", name: "Saturday", isActive: false },
    { id: 6, day: "SU", name: "Sunday", isActive: false },
  ];

  newDAY.forEach((d: scheduleDayType) => {
    ele?.days?.forEach(({ scheduleDays }: any) => {
      if (scheduleDays === d.name) {
        d.isActive = true;
      }
    });
  });

  const service = ele?.services
    ?.filter(({ isAvailable }) => isAvailable === 1)
    .map((s: any) => s?.service?.id);

  const services = ele?.services.map((s: any) => {
    return {
      id: s?.service.id,
      service: s?.service,
      isAvailable: s?.isAvailable,
      isDoubleBooking: s?.isDoubleBooking,
      isPaused: s?.isPaused,
      serviceName: s?.serviceName,
      noOfParticipants: s?.noOfParticipants,
    };
  });

  const doubleBookingServicesIds = ele?.services
    ?.filter(({ isDoubleBooking }) => isDoubleBooking === 1)
    .map((s: any) => s?.service?.id);

  const days = newDAY.filter(({ isActive }) => isActive).map(({ id }) => id);

  const breakTimes = ele?.breakTimes.map(({ id, startTime, endTime }) => {
    return {
      id,
      breakStartTime: startTime,
      breakEndTime: endTime,
    };
  });

  const isAllowForDoubleBooking = ele?.isAllowDoubleBooking;
  const isLastMinutesBooking = ele?.isAllowLastMinuteBooking;
  const overlapingTime = ele?.overlapping_time;

  const weekly = state?.onToOne?.isWeekly;

  return {
    days,
    servicesIds: service,
    services,
    doubleBookingServicesIds,
    repeatWeekly: weekly,
    isLastMinutesBooking,
    isAllowForDoubleBooking,
    breakTimes,
    newDAY,
    overlapingTime,
  };
};

function checkAppointmentStatus(
  status: any,
  isPaid: number,
  isReservationFee: number
) {
  // console.log("statusData : ", status, "isPaid : ", isPaid);

  let buttons;
  let statusToShow;
  if (status === "pending" || "rescheduleByMember" || "rescheduleByProvider") {
    statusToShow = "Pending";
    buttons = [
      {
        id: Math.random() * 71,
        color: "RS",
        name: "reschedule",
        toolTip: "Reschedule",
      },
      {
        id: Math.random() * 92,
        color: "DULL",
        name: "paid",
        toolTip: "Paid",
      },
      {
        id: Math.random() * 63,
        color: "DULL",
        name: "checkout",
        toolTip: "Checkout",
      },
      {
        id: Math.random() * 44,
        color: "DULL",
        name: "paymentRequest",
        toolTip: "Payment Request",
      },
      {
        id: Math.random() * 55,
        color: "AP",
        name: "approve",
        toolTip: "Approve",
      },
      {
        id: Math.random() * 66,
        color: "CA",
        name: "cancel",
        toolTip: "Cancel",
      },
    ];
  }

  if (status === "approveByProvider") {
    statusToShow = "Approved";
    buttons = [
      {
        id: Math.random() * 2,
        color: "DULL",
        name: "reschedule",
        toolTip: "Reschedule",
      },
      {
        id: Math.random() * 3,
        color: "DULL",
        name: "paid",
        toolTip: "Paid",
      },
      {
        id: Math.random() * 4,
        color: "CO",
        name: "checkout",
        toolTip: "Checkout",
      },
      {
        id: Math.random() * 5,
        color: "DULL",
        name: "paymentRequest",
        toolTip: "Payment Request",
      },
      {
        id: Math.random() * 6,
        color: "DULL",
        name: "approve",
        toolTip: "Approve",
      },
      {
        id: Math.random() * 7,
        color: "DULL",
        name: "cancel",
        toolTip: "Cancel",
      },
    ];
  }
  if (status === "completed" && (isPaid === 2 || isPaid === 0)) {
    statusToShow = "Payment Requested";
    buttons = [
      {
        id: Math.random() * 8,
        color: "DULL",
        name: "reschedule",
        toolTip: "Reschedule",
      },
      {
        id: Math.random() * 9,
        color: "DULL",
        name: "paid",
        toolTip: "Paid",
      },
      {
        id: Math.random() * 10,
        color: "DULL",
        name: "checkout",
        toolTip: "Checkout",
      },
      {
        id: Math.random() * 11,
        color: "PR",
        name: "paymentRequest",
        toolTip: "Payment Request",
      },
      {
        id: Math.random() * 12,
        color: "DULL",
        name: "approve",
        toolTip: "Approve",
      },
      {
        id: Math.random() * 13,
        color: "DULL",
        name: "cancel",
        toolTip: "Cancel",
      },
    ];
  }

  if (status === "completed" && isPaid === 1) {
    statusToShow = "Paid";
    buttons = [
      {
        id: Math.random() * 14,
        color: "DULL",
        name: "reschedule",
        toolTip: "Reschedule",
      },
      {
        id: Math.random() * 15,
        color: "PD",
        name: "paid",
        toolTip: "Paid",
      },
      {
        id: Math.random() * 16,
        color: "DULL",
        name: "checkout",
        toolTip: "Checkout",
      },
      {
        id: Math.random() * 17,
        color: "DULL",
        name: "paymentRequest",
        toolTip: "Payment Request",
      },
      {
        id: Math.random() * 218,
        color: "DULL",
        name: "approve",
        toolTip: "Approve",
      },
      {
        id: Math.random() * 219,
        color: "DULL",
        name: "cancel",
        toolTip: "Cancel",
      },
    ];
  }
  if (status === "declineByProvider") {
    statusToShow = "Decline by Provider";
    buttons = [
      {
        id: Math.random() * 25,
        color: "DULL",
        name: "reschedule",
        toolTip: "Reschedule",
      },
      {
        id: Math.random() * 26,
        color: "DULL",
        name: "paid",
        toolTip: "Paid",
      },
      {
        id: Math.random() * 27,
        color: "DULL",
        name: "checkout",
        toolTip: "Checkout",
      },
      {
        id: Math.random() * 28,
        color: "DULL",
        name: "paymentRequest",
        toolTip: "Payment Request",
      },
      {
        id: Math.random() * 29,
        color: "DULL",
        name: "approve",
        toolTip: "Approve",
      },
      {
        id: Math.random() * 30,
        color: "DULL",
        name: "cancel",
        toolTip: "Cancel",
      },
    ];
  }

  if (status === "rescheduleByProvider") {
    statusToShow = "Reschedule by Provider";
    buttons = [
      {
        id: Math.random() * 31,
        color: "DULL",
        name: "reschedule",
        toolTip: "Reschedule",
      },
      {
        id: Math.random() * 32,
        color: "DULL",
        name: "paid",
        toolTip: "Paid",
      },
      {
        id: Math.random() * 33,
        color: "CO",
        name: "checkout",
        toolTip: "Checkout",
      },
      {
        id: Math.random() * 34,
        color: "DULL",
        name: "paymentRequest",
        toolTip: "Payment Request",
      },
      {
        id: Math.random() * 35,
        color: "DULL",
        name: "approve",
        toolTip: "Approve",
      },
      {
        id: Math.random() * 36,
        color: "DULL",
        name: "cancel",
        toolTip: "Cancel",
      },
    ];
  }
  // if (status === "cancelledByMember") {
  //   statusToShow = "Cancelled by member";
  //   buttons = [
  //     {
  //       id: 1,
  //       color: "DULL",
  //       name: "reschedule",
  //       toolTip: "reschedule",
  //     },
  //     {
  //       id: 2,
  //       color: "DULL",
  //       name: "paid",
  //       toolTip: "paid",
  //     },
  //     {
  //       id: 3,
  //       color: "DULL",
  //       name: "checkout",
  //       toolTip: "checkout",
  //     },
  //     {
  //       id: 4,
  //       color: "DULL",
  //       name: "paymentRequest",
  //       toolTip: "paymentRequest",
  //     },
  //     {
  //       id: 5,
  //       color: "DULL",
  //       name: "approve",
  //       toolTip: "approve",
  //     },
  //     {
  //       id: 6,
  //       color: "DULL",
  //       name: "cancel",
  //       toolTip: "cancel",
  //     },
  //   ];
  // }
  if (
    status !== "declineByProvider" &&
    isReservationFee === 1 &&
    (isPaid === null || isPaid === 0)
  ) {
    statusToShow = "Reservation Fee not paid";
    buttons = [
      {
        id: Math.random() * 8,
        color: "DULL",
        name: "reschedule",
        toolTip: "Reschedule",
      },
      {
        id: Math.random() * 9,
        color: "DULL",
        name: "paid",
        toolTip: "Paid",
      },
      {
        id: Math.random() * 10,
        color: "DULL",
        name: "checkout",
        toolTip: "Checkout",
      },
      {
        id: Math.random() * 11,
        color: "DULL",
        name: "paymentRequest",
        toolTip: "Payment Request",
      },
      {
        id: Math.random() * 12,
        color: "DULL",
        name: "approve",
        toolTip: "Approve",
      },
      {
        id: Math.random() * 13,
        color: "CA",
        name: "cancel",
        toolTip: "Cancel",
      },
    ];
  }

  return { statusToShow, buttons };
}

export function attachedPaginatedPayload(dataList: any) {
  const paginationKeys = [
    "currentPage",
    "perPage",
    "total",
    "lastPage",
    "firstPageUrl",
    "lastPageUrl",
    "nextPageUrl",
    "previousPageUrl",
  ];
  // get the pagination object from the response data
  return Object.keys(dataList)
    .filter((key) => paginationKeys.includes(key))
    .reduce((obj, key) => {
      obj[key] = dataList[key];
      return obj;
    }, {});
}

export function makeAppointmentList(data: Appointment[]) {
  const improvedList = data.map((appointment: Appointment) => {
    delete appointment.provider;
    // console.log(appointment);
    const { buttons, statusToShow } = checkAppointmentStatus(
      appointment.status,
      appointment.isPaid,
      appointment.isReservationFee
    );
    return { ...appointment, statusToShow, buttons };
  });

  // set the pagination object in the state
  const pagination = attachedPaginatedPayload(data);

  // sort appointment List by date
  const appointmentList = improvedList.sort(
    (a: any, b: any) =>
      new Date(b.appointmentDate).getTime() -
      new Date(a.appointmentDate).getTime()
  );
  // console.log("pagination : ", pagination);

  return { pagination, appointmentList };
}

export interface Notification {
  id: number;
  isRead: number;
  message: string;
  data: any;
  createdAt: string;
  notificationClass: string;
  event: string;
}

export const makeNotification = (list: any[]): Notification[] => {
  /**
   * TODO
   * this function needs to move into utils
   * this here only for development only
   */

  const refinedNotifications: Notification[] = list.map(
    ({ id, data, message, createdAt, notificationClass, event, isRead }) => {
      return {
        id,
        data,
        createdAt: getTimeAgo(createdAt),
        isRead,
        message,
        notificationClass,
        event,
      };
    }
  );

  return reverseArry(refinedNotifications);
};

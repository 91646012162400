import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function createData(
    principalPercent: string,
    firstName: string,
    lastName: string,
) {
    return { principalPercent, firstName, lastName };
}

const rows = [
    createData('Push', 'abc', 'xyz'),
    createData('Email', 'abc', 'xyz'),
    createData('SMS', 'abc', 'xyz'),
];

export default function PushNotification() {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead className='border-top'>
                    <TableRow>
                        <TableCell>Notification</TableCell>
                        <TableCell align="left">Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.principalPercent}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                {row.principalPercent}
                            </TableCell>
                            <TableCell align="left">
                                {/* <button style={{ fontSize: '14px' }} className="btn btn-primary me-2">
                                    Edit</button>
                                <button style={{ fontSize: '14px' }} className="btn btn-outline-danger me-2">
                                    Delete</button> */}
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
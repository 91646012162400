import React, { Children } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 300, sm: 400, lg: 650, xl: 900 },
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "7px",
  boxShadow: 24,
  p: 3,
};

const ConfModal = ({ handleClose, open, children }) => {
  return (
    <Modal
      style={{ zIndex: 9999 }}
      open={open}
      onClose={() => handleClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>{children}</Box>
    </Modal>
  );
};

export default ConfModal;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  forgotPass,
  getDashBoardDataRequest,
  RegistrationRequest as providerSignup,
  ResendCodeRequest,
  resetPass,
  updateProfilePhoto,
  updateUserProfile,
  userProfileProgressRequest,
  VerifyCode,
  verifyReset,
} from "../../helper/provider/Auth";
import { RootState } from "../../store";
import { getAuth, setAuth } from "../../utils/AuthHelpers";
import { fetchLoggedInUserData as providerProfile } from "../../helper/provider/Auth";
import { localStorageObject, percentage } from "../../utils";
import { xconsole } from "../../utils/console";
import { makeResponseError } from "../../utils/errorHandler";
import { SHOW_SUCCESS } from "../../utils/ToastMessage";
import { user } from "../../Firebase";
import { getFCMToken } from "../../Firebase/lib";
import { signUpNotification } from "../../helper/provider/notification";
import getDeviceType from "../../utils/device";
import {
  CODE,
  FAIL,
  LOADING,
  MOBILE_NUMBER,
  NUMBER,
  SUCCESS,
  TOKEN,
} from "../../config/constant";

export interface AuthState {
  isLoggedIn: boolean;
  profileProgress: profileProgress;
  currentUser: CurrentUser;
  access_token: string;
  temp_data: any;
  status: string;
  updateStatus: string;
  imgStatus: string;
  error: string | null;
  profileLoading: string;
  dashboardData: any;
}
export interface profileProgress {
  credentials: number;
  medias: number;
  payments: number;
  schedules: number;
  services: number;
  profilePercentage: number;
}

export interface CurrentUser {
  service: any;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  mobileNo: string;
  profileImage: string;
  access_token: string;
  refresh_token: string;
}

export const initialState: AuthState = {
  isLoggedIn: false, // please do not set it to true => ok sir!
  access_token: "",
  status: "idle",
  updateStatus: "idle",
  imgStatus: "idle",
  profileLoading: "idle",
  error: null,
  temp_data: {},
  profileProgress: {
    credentials: 0,
    medias: 0,
    payments: 0,
    schedules: 0,
    services: 0,
    profilePercentage: 0,
  },
  currentUser: {
    id: 0,
    service: {},
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    mobileNo: "",
    profileImage: "",
    access_token: "",
    refresh_token: "",
  },
  dashboardData: {},
};

type UserToken = {
  access_token: string;
};

export const signUpProvider = createAsyncThunk(
  "auth/provider/register",
  async (formData: any) => {
    try {
      const res: any = await providerSignup(formData.data);
      const { data, success } = res.data;
      // console.log("data", formData.data);

      if (success) {
        const res = await providerProfile(data?.accessToken);
        if (res.data.success) {
          if (res.data.data.isMobileVerified) {
            formData.navigate("/auth/profile");
          } else {
            formData.navigate("/auth/verify-code");
            localStorageObject.set(NUMBER, formData.data.mobileNo);
          }
        }
      }
      // console.log("data", data);

      // setAuth("provider_token", {
      //   access_token: data?.accessToken,
      //   refresh_token: null,

      return {
        data,
        success,
      };
    } catch (error) {
      console.error(error);
      return makeResponseError(error.response.data);
    }
  }
);

export const getProfileProgressRequest = createAsyncThunk(
  "auth/progress",
  async () => {
    try {
      const res: any = await userProfileProgressRequest();
      const { data, success, message } = res.data;

      if (success) {
        xconsole.success(message);
        // console.log("%c" + , "color:green");
      }

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      console.error(error);
      return error;
    }
  }
);

export const verifyProviderToken = createAsyncThunk(
  "auth/profile",
  async (formData: any) => {
    try {
      const res: any = await VerifyCode(formData.data, formData.token);
      const { data, success } = res.data;
      // console.log("data", data);

      const profile = await providerProfile(formData.token);
      const pData = profile.data;
      // console.log("profile", profile.data);
      if (success) {
        formData.navigate("/auth/profile");
      }
      // console.log("data", data);

      return {
        profile: pData,
        data,
        success,
      };
    } catch (error) {
      console.error(error);
      // return makeResponseError(error.response.data);
    }
  }
);

export const verifyResendCode = createAsyncThunk(
  "auth/provider/resend-code",
  async (token: any) => {
    try {
      const res: any = await ResendCodeRequest(token);
      const { data, success } = res.data;
      // console.log("data", data);
      return {
        data,
        success,
      };
    } catch (error) {
      console.error(error);
      return makeResponseError(error.response.data);
    }
  }
);

export const verifyResetCode = createAsyncThunk(
  "auth/provider/verify-reset-code",
  async (formData: any) => {
    try {
      const res: any = await verifyReset(formData.data.code);
      const { data, success } = res.data;
      if (success) {
        // console.log(data);
        formData.navigate("/auth/resetPass");
      }
      return data;
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "auth/provider/updateProfile",
  async (formData: any, thunkApi) => {
    try {
      const res = await updateUserProfile(formData.data, formData.token);
      const { success } = res.data;
      const auth: any = getAuth();
      // console.log("update profile data", res.data);

      if (success) {
        SHOW_SUCCESS(
          formData?.update && success,
          "Profile updated successfully"
        );
        setAuth({
          access_token: formData.token,
          refresh_token: null,
        });
        if (formData?.update) {
          formData?.setFormToggle(true);
          thunkApi.dispatch(loginUser(auth));
          return;
        } else {
          // console.log("from userProfile :", res);
          window.location.replace("/");
          localStorageObject.remove(TOKEN);
        }
      }
      return res.data;
    } catch (error) {
      console.log("update profile error =>", error);
      return makeResponseError(error.response.data);
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  "auth/provider/updateProfilePhoto",
  async (formData: any) => {
    try {
      const res: any = await updateProfilePhoto(formData.data, formData.token);
      const { data, success } = res.data;
      // console.log("data", data);
      if (success && data.mobileNo) {
        const mobileNo = data.mobileNo + "p";
        user.update(mobileNo, {
          key: "avatarUrl",
          updatedValue: data.profileImage,
        });
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "auth/provider/forgotPass",
  async (formData: any) => {
    try {
      const res: any = await forgotPass(formData.data);
      const { data, success, message } = res.data;
      if (success) {
        localStorageObject.set(MOBILE_NUMBER, formData.data.mobileNo);
        formData.navigate("/auth/verifyResetPass");
      }

      return {
        data,
        success,
        message,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPass",
  async (formData: any) => {
    try {
      const res: any = await resetPass(formData.data);
      const { success } = res.data;
      if (success) {
        formData.navigate("/auth/login");
      }
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login/user",
  async (auth: any) => {
    try {
      const res = await providerProfile(auth?.access_token);

      const { data, success } = res.data;

      if (success) {
        return {
          data,
          success,
          code: 200,
        };
      }
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const getDashBoardDataAction = createAsyncThunk(
  "auth/getDashBoardDataAction",
  async () => {
    try {
      const res = await getDashBoardDataRequest();
      const { data, success } = res.data;
      if (success) {
        return {
          data,
          success,
          code: 200,
        };
      }
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signUp: (state, { payload }: PayloadAction<UserToken>) => {
      // state.isLoggedIn = true;
      state.temp_data = payload;
    },
    logIn: (state, { payload }: PayloadAction<CurrentUser>) => {
      const onlineFlag = true;
      state.currentUser = payload;
      state.isLoggedIn = onlineFlag;
      user.update(payload.mobileNo, {
        key: "online",
        updatedValue: onlineFlag,
      });

      // notification data

      let notificationData: any = {
        deviceType: getDeviceType(),
        modelId: payload.id,
        modelType: "provider",
      };

      getFCMToken()
        .then((rezToken) => {
          notificationData.deviceToken = rezToken;
          // console.log(" rezToken : ", rezToken);
          signUpNotification(notificationData)
            .then((res) => {
              // console.log("res (log in reducer ) :", res);
            })
            .catch((error) => {
              console.log("error (log in reducer ) :", error);
            });
        })
        .catch((error) => {
          console.log("getFCMToken (log in reducer ) :", error);
        });
    },
    setLogOut: (state) => {
      state.isLoggedIn = false;
      state.access_token = "";
      state.currentUser = initialState.currentUser;
      state.error = null;
      state.status = "idle";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpProvider.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(signUpProvider.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.access_token = action.payload.data.accessToken;
        localStorageObject.set(TOKEN, action.payload.data.accessToken);
      })
      .addCase(signUpProvider.rejected, (state, action) => {
        state.status = "failed";
      })

      // login user start here
      .addCase(loginUser.pending, (state, action) => {
        state.profileLoading = LOADING;
      })
      .addCase(
        loginUser.fulfilled,
        (
          state,
          {
            payload,
          }: PayloadAction<{ data: any; success: boolean; code: number }>
        ) => {
          const { data, success } = payload;
          if (success) {
            state.currentUser = data;
            state.isLoggedIn = true;
          }
          state.profileLoading = SUCCESS;
        }
      )
      .addCase(loginUser.rejected, (state, action) => {
        state.status = FAIL;
      })

      // login user end here

      .addCase(getProfileProgressRequest.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getProfileProgressRequest.fulfilled, (state, action) => {
        const { data, success, code } = action.payload;

        if (success && code === 200) {
          const progressValues: Array<number> = Object.values(data);
          const profilePercentage: any = percentage(
            progressValues,
            progressValues.length
          );
          // console.log(profilePercentage);

          state.profileProgress = data;
          state.profileProgress.profilePercentage = parseInt(profilePercentage);
          state.status = "succeeded";
        }
        state.status = "failed";
      })
      .addCase(getProfileProgressRequest.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(verifyProviderToken.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(verifyProviderToken.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { profile: pData } = action.payload;
        // console.log("profile", action.payload);

        state.currentUser = pData;
      })
      .addCase(verifyProviderToken.rejected, (state, action) => {
        state.status = "failed";
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.updateStatus = "loading";
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        // console.log("action", action.payload);
        state.updateStatus = "succeeded";
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.updateStatus = "failed";
      })

      .addCase(updateProfilePicture.pending, (state, action) => {
        state.imgStatus = "loading";
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.imgStatus = "succeeded";
        if (action.payload.success) {
          state.currentUser = action.payload.data;
        }
      })
      .addCase(updateProfilePicture.rejected, (state, action) => {
        state.imgStatus = "failed";
      })

      .addCase(forgotPassword.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(resetPassword.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(verifyResetCode.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(verifyResetCode.fulfilled, (state, action) => {
        state.status = "succeeded";
        localStorageObject.set(CODE, action.payload.resetToken);
      })
      .addCase(verifyResetCode.rejected, (state, action) => {
        state.status = "failed";
      })

      .addCase(getDashBoardDataAction.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getDashBoardDataAction.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.dashboardData = action.payload.data;
      })
      .addCase(getDashBoardDataAction.rejected, (state, action) => {
        state.status = "failed";
      });
  },

  // extraReducers: (builder) => {
  //   // When we send a request, // `signUp.pending` is being fired:
  //   builder.addCase(signUpMember.pending, (state) => {
  //     // At that moment,
  //     // we change status to `loading`
  //     // and clear all the previous errors:

  //     state.status = "loading";
  //     state.error = null;
  //   });

  //   // When a server responses with the data,
  //   // `signUpMember.fulfilled` is fired:
  //   builder.addCase(signUpMember.fulfilled, (state, { payload }) => {
  //     const { data, success }: any = payload;
  //     if (success) {
  //       state.isLoggedIn = true;
  //       state.status = "idle";
  //     }
  //   });

  //   // When a server responses with an error:
  //   builder.addCase(signUpMember.rejected, (state, { payload }) => {
  //     state.error = payload as string;
  //     state.status = "idle";
  //   });

  //   // provider
  //   builder.addCase(signUpProvider.pending, (state) => {
  //     // At that moment,
  //     // we change status to `loading`
  //     // and clear all the previous errors:

  //     state.status = "loading";
  //     state.error = null;
  //   });

  //   // When a server responses with the data,
  //   // `signUpProvider.fulfilled` is fired:
  //   builder.addCase(signUpProvider.fulfilled, (state, { payload }) => {
  //     const { data, success }: any = payload;
  //     if (success) {
  //       state.isLoggedIn = true;
  //       state.status = "idle";
  //     }
  //   });

  //   // When a server responses with an error:
  //   builder.addCase(signUpProvider.rejected, (state, { payload }) => {
  //     state.error = payload as string;
  //     state.status = "idle";
  //   });
  // },
});

export const { logIn, setLogOut, signUp } = authSlice.actions;
export default authSlice.reducer;

export const authSelector = (state: RootState) => state.auth;
export const loggedInUserProfile = (state: RootState) => state.auth.currentUser;
export const loggedInUserProfileRootService = (state: RootState) =>
  state.auth.currentUser.service;
export const getIsLoggedIn = (state: RootState) => state?.auth.isLoggedIn;
export const profileProgressSelector = (state: RootState) =>
  state?.auth.profileProgress;
export const statusSelector = (state: RootState) => state.auth.status;
export const updateStatus = (state: RootState) => state.auth.updateStatus;
export const imgStatusSelector = (state: RootState) => state.auth.imgStatus;
export const dashboardDataSelector = (state: RootState) =>
  state.auth.dashboardData;

export const profileLoadingSelector = (state: RootState) =>
  state.auth.profileLoading;

import { title } from "process";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import ModalC from "../../../../components/common/Modal";
import { credentialsSelector } from "../../../../reducers/credentialsReducer";
import { formateTheDate } from "../../../../utils";
import { ViewMediaModal } from "../../media/components/ViewImgModal";
import { AddCredentialModal } from "./AddCredentialModal";
import MoreDropdown from "./common/MoreDropdown";
import CredentialDetail from "./CredentialDetail";
import UploadCredential from "./UploadCredential";
import ImageModal from "../../../../components/common/ImageModal";

const CredentialAvailableView = ({ data, index }: any) => {
  const credentialData = useSelector(credentialsSelector);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);

  // console.log("key",k);

  return (
    <>
      <div
        className={`row mx-md-4 mx-3 py-4 ${
          index === credentialData.length - 1 ? "border-0" : "border-bottom"
        }`}
      >
        <div className="col-xl-2 col-lg-3 col-md-4 col-12 px-0 justify-content-center d-flex">
          <img
            onClick={handleOpen2}
            style={{ objectFit: "cover" }}
            className="credential-img rounded-5 border mb-md-0 mb-3 pointer"
            src={data?.thumbnailUrl}
            alt="No Creds."
          />
        </div>
        <div className="col-xl-10 col-md-8 col-12 justify-content-start align-items-center pe-0 ps-md-4 ps-0">
          <div className="d-flex justify-content-between">
            <p className={`mb-md-0 text-dark`} style={{ fontSize: "18px" }}>
              {data?.title?.length > 50
                ? data?.title?.slice(0, 50) + "..."
                : data?.title}
            </p>
            <MoreDropdown open={open} id={data?.id} modalHandle={setOpen} />
          </div>
          {/* <div
            style={{
              width: `${
                data?.type?.length >= 10
                  ? "7"
                  : data?.type?.length <= 8
                  ? "6"
                  : "4"
              }rem`,
              padding: "2px 0px",
              overflow: "hidden",
            }}
            className=" d-flex align-items-center rounded-pill border px-2"
          >
            <img
              className="me-2"
              src="/images/credential/dot.svg"
              alt="no icon"
            />
            <p style={{ fontSize: "14px" }} className=" mb-0 text-secondary">
              {data?.type ? data?.type : ""}
            </p>
          </div> */}
          <CredentialDetail
            issDate={formateTheDate(data?.issueDate)}
            expDate={data?.expiryDate ? formateTheDate(data?.expiryDate) : "--"}
            url={data?.link}
          />
        </div>
      </div>
      <ModalC handleClose={handleClose} open={open}>
        <AddCredentialModal
          index={index}
          isEdit={true}
          handleClose={handleClose}
          data={data}
        />
      </ModalC>
      <ImageModal handleClose={handleClose2} open={open2}>
        <ViewMediaModal
          mediaType={null}
          mediaUrl={data?.imageUrl}
          handleClose={handleClose2}
          singleTitle={data?.title}
        />
      </ImageModal>
    </>
  );
};

export default CredentialAvailableView;

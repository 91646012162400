import React from "react";
import { Toaster, toast } from "react-hot-toast";

interface NotificationToastProps {
  message: string;
  icon: JSX.Element;
}

const NotificationToast = ({ message, icon }: NotificationToastProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "start",
        justifyContent: "around",
      }}
      className="bg-white p-1 rounded-lg"
    >
      <div className="d-flex items-center">
        {icon}
        <span className="ml-2">{message}</span>
      </div>
    </div>
  );
};

export default NotificationToast;

import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
// import IconButton from '@mui/material/IconButton';
// import Tooltip from '@mui/material/Tooltip';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
// import DeleteIcon from '@mui/icons-material/Delete';
// import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  appointmentPendingListSelector,
  changeAppointmentStatus,
} from "../../../../reducers/appointmentReducer";
import { TemporaryDrawer } from "../../../../components/common/TemporaryDrawer";
import RescheduleDrawer from "../../appointment/components/RescheduleDrawer";
import EditRescheduleDrawer from "../../appointment/components/EditRescheduleDrawer";
import { convert24To12 } from "../../../../utils";
import { AppDispatch } from "../../../../store";
import AppointmentCompDrawer from "../../appointment/components/AppointmentCompDrawer";

interface Data {
  Date: string;
  id: string | number;
  Time: string;
  action: string;
  location: string;
  name: any;
  clientImg: string;
  totalDuration: string;
  totalPrice: string;
  isPaid: number;
  providerId: any;
  clientId: any;
}

function createData(
  id: string | number,
  name: string,
  Date: string,
  Time: string,
  location: string,
  action: string,
  clientImg: string,
  totalDuration: string,
  totalPrice: string,
  providerId: any,
  isPaid: number,
  clientId: any
): Data {
  return {
    id,
    name,
    Date,
    Time,
    location,
    action,
    clientImg,
    totalPrice,
    totalDuration,
    isPaid,
    providerId,
    clientId,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Client ",
  },
  {
    id: "Date",
    numeric: true,
    disablePadding: false,
    label: "Duration",
  },
  {
    id: "location",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "isPaid",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            className="light-check"
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all clients",
            }}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : null}
      {numSelected > 0 ? null : null}
    </Toolbar>
  );
}

export default function PendingPaymentTable({ param }) {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("Date");
  const [selected, setSelected] = React.useState<readonly any[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );

  const rows = React.useMemo(
    () =>
      param === null
        ? pendingAppointmentListData?.map(
            ({
              id,
              client,
              appointmentDate,
              startTime,
              endTime,
              totalPrice,
              totalDuration,
              isPaid,
              provider,
            }) =>
              createData(
                id,
                client?.firstName + " " + client?.lastName,
                appointmentDate,
                `${convert24To12(startTime)}-${convert24To12(endTime)}`,
                client?.address,
                client?.id,
                client?.profileImage,
                totalDuration,
                totalPrice,
                provider?.id,
                isPaid,
                client?.id
              )
          )
        : pendingAppointmentListData
            ?.filter((f) => f?.client?.id == param)
            .map(
              ({
                id,
                client,
                appointmentDate,
                startTime,
                endTime,
                totalDuration,
                totalPrice,
                isPaid,
                provider,
              }) =>
                createData(
                  id,
                  client?.firstName + " " + client?.lastName,
                  appointmentDate,
                  `${convert24To12(startTime)}-${convert24To12(endTime)}`,
                  client?.address,
                  client?.id,
                  client?.profileImage,
                  totalDuration,
                  totalPrice,
                  provider?.id,
                  isPaid,
                  client?.id
                )
            ),
    [pendingAppointmentListData]
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const dispatch = useDispatch<AppDispatch>();

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [rowData, setRowData] = React.useState<any>(null);

  const [appointmentCom, setAppointmentCom] = React.useState("approve");

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? "small" : "medium"}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
              rows.sort(getComparator(order, orderBy)).slice() */}
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row: any, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      className="pointer"
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      {/* <TableCell
                        onClick={(event) => handleClick(event, row.name)}
                        padding="checkbox"
                      >
                        <Checkbox
                          className="light-check"
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell> */}
                      <TableCell
                        onClick={() => {
                          //   handleOpen();
                          //   setRowData(row);
                          setAppointmentCom("approve");
                        }}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <div className="d-flex align-items-center my-3">
                          <img
                            className="rounded-circle me-2 ms-3 border border-primary"
                            style={{ objectFit: "contain" }}
                            height={35}
                            width={35}
                            src={
                              row?.clientImg == null
                                ? "/images/avatar2.png"
                                : row?.clientImg
                            }
                            // srcSet="/images/avatar2.png"
                            alt="No profile"
                          />

                          <div>
                            {row.name}
                            <p
                              style={{ color: "#667085", fontSize: "12px" }}
                              className="m-0"
                            >
                              {row.location}
                            </p>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleOpen();
                          setRowData(row);
                          setAppointmentCom("approve");
                        }}
                        align="left"
                      >
                        <div className="d-flex flex-column ms-3">
                          <p
                            style={{ color: "#667085", fontSize: "12px" }}
                            className="mb-0"
                          >
                            {row.totalDuration} min
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleOpen();
                          setRowData(row);
                          setAppointmentCom("approve");
                        }}
                        align="left"
                      >
                        <div className="d-flex flex-column ms-3">
                          <p
                            style={{ color: "#667085", fontSize: "12px" }}
                            className="mb-0"
                          >
                            ${row.totalPrice}
                          </p>
                        </div>
                      </TableCell>
                      <TableCell
                        onClick={() => {
                          handleOpen();
                          setRowData(row);
                          setAppointmentCom("approve");
                        }}
                        style={{ width: "220px" }}
                        align="left"
                      >
                        {/* <p className="mb-0 ms-3">{row.status}</p> */}
                        <p
                          className={`rounded-pill px-3 py-1 ms-3 d-inline ${
                            row.isPaid === 2
                              ? "tag-purple"
                              : row.isPaid === 0
                              ? "tag-brown"
                              : row.isPaid === 1
                              ? "tag-success"
                              : "tag-primary"
                          }`}
                        >
                          <img
                            className="me-1"
                            src={`${
                              row.isPaid === 2
                                ? "/images/purple.png"
                                : row.isPaid === 0
                                ? "/images/brown.png"
                                : row.isPaid === 1
                                ? "/images/success.png"
                                : "/images/primary.png"
                            }`}
                            alt="no icon"
                          />
                          <span style={{ fontSize: "12px" }} className="mb-0">
                            {row.isPaid === 0
                              ? "Unpaid"
                              : row.isPaid === 1
                              ? "Paid"
                              : row.isPaid === 2
                              ? "Reservation Fee Paid"
                              : null}
                          </span>
                        </p>
                      </TableCell>
                      {/* <TableCell style={{ width: "200px" }} align="right">
                        <img
                          data-mdb-toggle="tooltip"
                          title="Reschedule"
                          onClick={() => {
                            handleOpen2();
                            setRowData(row);
                            setAppointmentCom("approve");
                          }}
                          className="pointer me-1"
                          src="/images/reschedule.svg"
                          alt="No profile"
                        />
                        {/* <img
                          data-mdb-toggle="tooltip"
                          title="Collect Payment"
                          className="pointer me-1"
                          src="/images/collect.svg"
                          alt="No profile"
                        /> */}
                      {/* {row.status == "pending" ? (
                          <img
                            data-mdb-toggle="tooltip"
                            title="Approve"
                            onClick={() => {
                              dispatch(
                                changeAppointmentStatus({
                                  appointmentId: row.id,
                                  status: "approveByProvider",
                                  isPaid: "2",
                                })
                              );

                              // xconsole.test(row.id);
                            }}
                            className="pointer me-1"
                            src="/images/approve.svg"
                            alt="No profile"
                          />
                        ) : (
                          <img
                            onClick={() => {
                              handleOpen();
                              setRowData(row);
                              setAppointmentCom("complete");
                            }}
                            data-mdb-toggle="tooltip"
                            title="Mark it Complete"
                            className="pointer me-1"
                            src="/images/appointmentC.svg"
                            alt="No profile"
                          />
                        )}
                        <img
                          data-mdb-toggle="tooltip"
                          title="Decline"
                          onClick={() => {
                            dispatch(
                              changeAppointmentStatus({
                                appointmentId: row.id,
                                status: "declineByProvider",
                                isPaid: "2",
                              })
                            );

                            // xconsole.test(row.id);
                          }}
                          className="pointer"
                          src="/images/decline.svg"
                          alt="No profile"
                        /> */}
                      {/* <button
                          onClick={() => {
                            dispatch(
                              changeAppointmentStatus({
                                appointmentId: row.id,
                                status: "declineByProvider",
                                isPaid: "2",
                              })
                            );

                            // xconsole.test(row.id);
                          }}
                          style={{ fontSize: "14px", borderRadius: "8px" }}
                          className="btn btn-primary me-2"
                        >
                          Approve
                        </button>
                        <button
                          onClick={() => {
                            dispatch(
                              changeAppointmentStatus({
                                appointmentId: row.id,
                                status: "approveByProvider",
                                isPaid: "2",
                              })
                            );

                            // xconsole.test(row.id);
                          }}
                          style={{ fontSize: "14px", borderRadius: "8px" }}
                          className="btn btn-outline-danger me-2"
                        >
                          Decline
                        </button> */}
                      {/* </TableCell> */}
                    </TableRow>
                  );
                })}
              {/* {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )} */}
            </TableBody>
          </Table>
        </TableContainer>
        <TemporaryDrawer handleClose={handleClose} open={open}>
          <AppointmentCompDrawer
            handleClose={handleClose}
            drawerClose={handleClose}
            payment={true}
            id={rowData?.id}
            providerId={rowData?.providerId}
            clientId={rowData?.clientId}
          />
        </TemporaryDrawer>
        <TemporaryDrawer handleClose={handleClose2} open={open2}>
          <EditRescheduleDrawer handleClose={handleClose2} id={rowData?.id} />
        </TemporaryDrawer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
    </Box>
  );
}

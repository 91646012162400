import React from "react";
import { useSelector } from "react-redux";
import {
  profileProgressSelector,
  statusSelector,
} from "../../../reducers/authReducer";
import StepInfoCardChild from "./StepInfoCardChild";
import StepInfoLoading from "./skeletonLoading/StepInfoLoading";

const StepsInfoCard = () => {
  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };
  const profileProgressData = useSelector(profileProgressSelector);
  const status = useSelector(statusSelector);
  return (
    <div
      className={`row ${
        profileProgressData?.profilePercentage == 100 ? "mb-0" : "mb-4"
      } mt-md-2 mt-4`}
    >
      <div
        className={`col-12 ${
          profileProgressData?.profilePercentage == 100 ? "d-none" : "d-block"
        }`}
      >
        <div className="bg-white rounded border-3 p-4 overflow-auto">
          <h6
            style={{ fontSize: "16px", ...poppinsFont }}
            className="m-0 fw-bolder"
          >
            You're{" "}
            {profileProgressData?.profilePercentage
              ? profileProgressData?.profilePercentage
              : 0}
            % there
          </h6>
          <p className="mt-2" style={{ fontSize: "14px" }}>
            What services do you offer and what do they cost?
          </p>
          <div className="">
            {status === "loading" ? (
              <StepInfoLoading />
            ) : (
              <StepInfoCardChild isCompleted={profileProgressData} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepsInfoCard;

import React, { useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CURRENCY } from "../../../../../config/constant";
import {
  scheduleMultiToMultiReadOnlySelector,
  updateMultiToMulti,
} from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const ReservationAccordian = ({ isFee, fee }) => {
  const dispatch = useDispatch<AppDispatch>();
  const readOnlyData = useSelector(scheduleMultiToMultiReadOnlySelector);

  const handleChange = (e: any) => {
    if (e.target.value.length <= 3) {
      dispatch(
        updateMultiToMulti({ key: e.target.name, value: e.target.value })
      );
    }
  };

  const handleToggleChange = (e: any) => {
    if (e.target.checked === true) {
      dispatch(updateMultiToMulti({ key: e.target.name, value: 1 }));
    } else {
      dispatch(updateMultiToMulti({ key: e.target.name, value: 0 }));
    }
  };

  return (
    <div className="accordion mb-3" id="accordionExample4">
      <div
        className={`accordion-item ${
          isFee === 1
            ? fee === "" ||
              fee === "0" ||
              fee === null ||
              fee === "00" ||
              fee === "000" ||
              fee === "0000"
              ? "border-danger"
              : null
            : null
        }`}
      >
        <h2 className="accordion-header" id="headingOne">
          <button
            style={{ backgroundColor: "#f9fafb" }}
            className={`accordion-button collapsed`}
            type="button"
          >
            <div className="row w-100 align-items-center">
              <div className="col-md-6 col-8">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3 pointer"
                    height={22}
                    src="/images/schedule/reservation.svg"
                    alt=""
                  />
                  <div>
                    <p className="fw-light mb-0 text-dark">Reservation Fee</p>
                    {isFee ? (
                      <p
                        style={{ fontSize: "12px" }}
                        className="fw-light my-0 text-secondary"
                      >
                        {CURRENCY}
                        {readOnlyData.reservationFee}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="d-flex align-items-center justify-content-end actions">
                  <BootstrapTooltip
                    placement="top"
                    title="To increase your customer commitment to the reservation you can elect to charge a non refundable booking fee which is applied to service cost at checkout"
                  >
                    <img
                      id="reservationMulti"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="isReservationFee"
                      onChange={handleToggleChange}
                      checked={isFee}
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse4"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapse4"
          className={`accordion-collapse collapse  ${
            isFee === 1
              ? fee === "" || fee === "0"
                ? "show"
                : "show"
              : "collapsed"
          }`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample4"
        >
          <div className="accordion-body px-2 py-4">
            <div className="row w-100 m-auto px-0 rounded-5">
              <div className="col-md-4">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Fee
                </p>

                <div className="input-group">
                  <span
                    className="input-group-text px-md-2 px-1"
                    id="basic-addon1"
                  >
                    {CURRENCY}
                  </span>
                  <input
                    style={{ fontSize: "15px" }}
                    type="number"
                    min={1}
                    max={999}
                    name="reservationFee"
                    onKeyPress={(e) => {
                      if (e.key === "-" || e.key === "e" || e.key === "E") {
                        e.preventDefault();
                      }
                    }}
                    onChange={handleChange}
                    className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control border-start-0 ${
                      fee === "0" ||
                      fee === null ||
                      fee === "00" ||
                      fee === "000"
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter Fee"
                    value={fee}
                    id="Text1"
                  />
                </div>
                {fee === "0" ||
                fee === null ||
                fee === "00" ||
                fee === "000" ||
                fee === "0000" ? (
                  <p style={{ fontSize: "12px" }} className="mb-0 text-danger">
                    Reservation Fee can not be 0
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservationAccordian;

import React, { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { COLORS } from "../../styles";
import { Stack } from "@mui/material";
import Attachments from "./Attachments";
import Picker, { EmojiClickData } from "emoji-picker-react";
import { AppDispatch } from "../../../../../store";
import {
  sendChatMessageAction,
  updateMessageOnSend,
} from "../../../../../reducers/chatReducer";
import getDeviceType from "../../../../../utils/device";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";
import moment from "moment";
import useAutoSizeTextArea from "../../../../../hooks/useAutoSizeTextArea";

const SendMessage = ({ recipientData, setSendMsgStatus }: any) => {
  // console.log("🚀 ~ file: SendMessage.tsx:18 ~ SendMessage ~ recipientData:", recipientData)
  const [message, setMessage] = useState<any>();

  const [emojiTabIsOpen, setEmojiTabIsOpen] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  useAutoSizeTextArea(
    { textAreaRef: textAreaRef.current, buttonRef: buttonRef.current },
    message?.message
  );

  // const { participant } = useSelector(singleRoomSelector);

  const NotificationData = {
    title: "Message Received",
    body: recipientData?.recipient?.name,
    recipientId: recipientData?.recipient?.userId,
    recipientType: "client",
    Event: "chat",
    device_type: getDeviceType(),
  };

  const handleMessageSend = (data: any) => {
    try {
      // let messageType = text.includes("image") ? "image" : "text";
      // setSendMsgStatus("Loading")
      setMessage({
        message: "",
        type: "",
      });

      //function to check data type
      const checkDataType = (data) => {
        if (data.includes("image")) {
          return "image";
        } else if (data.includes("video")) {
          return "video";
        } else if (data.includes("audio")) {
          return "audio";
        } else if (data.includes("application")) {
          return "document";
        } else if (data.includes("text")) {
          return "text";
        }
      };

      const formData = new FormData();
      formData.append("message", data.message);
      formData.append(
        "receiverType",
        recipientData?.recipient?.isProvider ? "providers" : "clients"
      );
      formData.append("receiverId", recipientData?.recipient?.userId);
      formData.append("type", checkDataType(data?.type));

      const messageData = {
        id: data.message.id,
        senderUid: false,
        recipientUid: true,
        conversationId: recipientData?.recipient?.id,
        messageData:
          checkDataType(data?.type) === "text"
            ? data.message
            : URL.createObjectURL(data.message),
        messageType: checkDataType(data?.type),
        createdAt: {
          date: moment(),
          time: moment().format("hh:mm A"),
        },
      };

      dispatch(updateMessageOnSend(messageData));

      dispatch(
        sendChatMessageAction({ msg: formData, setMessage, setSendMsgStatus })
      );
      // sendChatNotification(NotificationData);
      // setSendMsgStatus("Sent")
    } catch (error) {
      throw new Error("Sending Message : ", error);
    }
  };

  const handleOnchange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    const data = {
      message: e.target.value,
      type: "text",
    };
    setMessage(data);
    // const textarea = e.target as HTMLTextAreaElement;
    // textarea.style.height = 'auto';
    // textarea.style.height = `${textarea.scrollHeight}px`;
  };

  const handleSelectEmoji = (data: EmojiClickData, event: MouseEvent) => {
    // console.log(data);
    const emojiCodePoint = parseInt(data.unified, 16);
    const emoji = String.fromCodePoint(emojiCodePoint);
    const messageData = {
      message: message + emoji,
      type: "text",
    };
    setMessage(messageData);
    setEmojiTabIsOpen(false);
  };

  const handleMediaSend = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files[0];
    event.target.value = null; // Clear the input field value
    // console.log("Selected file name:", selectedFile);

    if (selectedFile) {
      // const { url } = await uploadStorageFile(
      //   recipientData.id.trim(),
      //   selectedFile
      // );
      try {
        const data = {
          message: selectedFile,
          type: selectedFile.type,
        };
        // console.log(data);
        handleMessageSend(data);
      } catch (error) {
        throw new Error("File sending Error :", error);
      }
      // console.dir(test);

      // console.log("Selected file name:", selectedFile);
    }

    // Clear message and file input fields
    setMessage({
      message: "",
      type: "",
    });
    // setFile(null);
  };

  function handleEnterKeyDown(event: any) {
    if (
      event.key === "Enter" &&
      !event.getModifierState("Shift") &&
      message.message.length > 0
    ) {
      handleMessageSend(message);
      event.preventDefault();
      setMessage({
        message: "",
        type: "",
      });
    }
    if (event.key === "Enter" && !event.getModifierState("Shift")) {
      event.preventDefault();
    }
  }

  return (
    <div className="col-xl-10 col-12 me-auto w-100">
      <Stack>
        <div className="d-flex align-items-center pt-3 pb-1 px-4">
          <Attachments handleMediaSend={handleMediaSend} />
          {/* <div
            onClick={() => setEmojiTabIsOpen(!emojiTabIsOpen)}
            className="mr-2 d-none"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{
                borderRadius: "8px",
                height: "40px",
                backgroundColor: "#04bac7",
                width: "40px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <EmojiEmotionsIcon className=" pointer" htmlColor="#fff" />
            </span>
          </div> */}
          {/* <img
            className="me-2 pointer"

            height={40}
            src="/images/chat/uploadMedia.png"
            alt=""
          /> */}

          <div className="d-none">
            <img width="100" src="/no-photo-available.png" alt="selected" />
          </div>
          <textarea
            onKeyDown={handleEnterKeyDown}
            style={{
              ...COLORS.bgLight,
              resize: "none",
              maxHeight: "100px",
            }}
            value={message?.message}
            id="text"
            ref={textAreaRef}
            onChange={handleOnchange}
            className="form-control py-2 border border-0 rounded-start-5 px-3 no-focus emoji-input"
            placeholder="Start typing"
            autoComplete="off"
            rows={1}
          />
          {/* <button
            style={{ ...COLORS.bgLight }}
            type="button"
            className="btn border-chat-attachment no-focus"
            // onClick={() =>
            //   message.length > 0 ? handleMessageSend(message) : null
            // }
          >
            <Attachments handleMediaSend={handleMediaSend} />
          </button> */}
          <BootstrapTooltip title="Send" placement="top">
            <button
              style={{ ...COLORS.bgLight, maxHeight: "100px" }}
              type="button"
              ref={buttonRef}
              className="btn border-chat no-focus"
              onClick={() =>
                message.message.length > 0 ? handleMessageSend(message) : null
              }
            >
              <img
                height={27}
                src="/images/chat/snd.png"
                alt="icon"
                className="pointer"
              />
            </button>
          </BootstrapTooltip>

          {/* <ReactTooltip
            style={{
              backgroundColor: "#101828",
              width: "65px",
              position: "absolute",
            }}
            anchorId={"send"}
            place="top"
            content="Send"
          /> */}
        </div>
        {emojiTabIsOpen ? (
          <div className="emoji">
            <span className="strip"></span>
            <Picker
              onEmojiClick={handleSelectEmoji}
              width="100%"
              // pickerStyle={{
              // width: "100%",
              // height: "200px",
              // background: "transparent",
              // border: "none",
              // boxShadow: "none",
              // }}
              // groupVisibility={{
              //   flags: false,
              //   objects: false,
              //   symbols: false,
              // }}
              // groupNames={{
              //   smileys_people: "",
              //   animals_nature: "",
              //   food_drink: "",
              //   travel_places: "",
              //   activities: "",
              //   objects: "",
              //   symbols: "",
              //   flags: "",
              //   recently_used: "",
              // }}
              // disableSearchBar
              // disableAutoFocus
            />
          </div>
        ) : null}
      </Stack>
    </div>
  );
};

export default SendMessage;

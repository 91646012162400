import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allMultiToMultiNamesSelector,
  handleMultiToMultiTimeChange,
  scheduleMultiToMultiSelector,
  scheduleOnToOneReadOnlySelector,
  updateMultiToMulti,
  updateMultiToMultiServices,
} from "../../../../../reducers/scheduleReducer";
import { ownServiceSelector } from "../../../../../reducers/serviceReducer";
import { AppDispatch } from "../../../../../store";
import { convert24To12 } from "../../../../../utils";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const ServiceAccordion = ({
  selectedService,
  startMeridiem,
  endMeridiem,
  startTime,
  endTime,
}: any) => {
  //   const [slectedArry, setslectedArry] = useState<Array<any>>([]);

  // let selectedIds: Array<any> = selectedService?.map(({ service }) => service);

  const dispatch = useDispatch<AppDispatch>();

  const multiToMultiData = useSelector(scheduleMultiToMultiSelector);
  const allMultiToMultiData = useSelector(allMultiToMultiNamesSelector);

  const handleTimeChange = (e: any) => {
    // console.log("time : ", e.target.value);
    // console.log(" converted time : ", convert24To12(e.target.value));

    let tempTime = convert24To12(e.target.value);
    const tempMed = multiToMultiData[e.target.name].split(" ")[1];
    // console.log(tempTime);

    dispatch(
      updateMultiToMulti({
        key: e.target.name,
        value: `${tempTime.split(" ")[0]} ${tempMed}`,
      })
    );
    // setTime({
    //   ...time,
    // [e.target.name]: e.target.value,
    // });
  };

  const handleServiceChange = (data: any) => {
    const finalData = data.target.value.split(" ");
    dispatch(updateMultiToMultiServices(finalData[0]));
    dispatch(
      updateMultiToMulti({ key: "serviceDuration", value: finalData[1] })
    );
    // setTime({
    //   ...time,
    // [e.target.name]: e.target.value,
    // });
  };

  const [error, setError] = useState(false);

  const handleChange = (e: any) => {
    dispatch(updateMultiToMulti({ key: e.target.name, value: e.target.value }));
  };

  const servicesData = useSelector(ownServiceSelector);

  // console.log("services =>", servicesData);
  // console.log("selectec services =>", selectedIds);

  const handleMeridiemClick = (e: any) => {
    dispatch(
      handleMultiToMultiTimeChange({
        key: e.target.name,
        value: e.target.value,
      })
    );
  };

  // console.log("fullStartTime =>", fullStartTime);
  // console.log("fullEndTime =>", fullEndTime);

  return (
    <div
      className={`accordion mb-3 ${
        multiToMultiData?.servicesIds?.length === 0 ||
        multiToMultiData?.noOfParticipants === "" ||
        multiToMultiData?.noOfParticipants === "0" ||
        multiToMultiData?.noOfParticipants === null ||
        multiToMultiData?.serviceLocation === "" ||
        multiToMultiData?.serviceLocation === null
          ? "border border-danger rounded"
          : null
      }`}
      id="accordion"
    >
      <div className={`accordion-item`}>
        <h2 className="accordion-header" id="heading">
          <button
            style={{ backgroundColor: "#f9fafb" }}
            className={`accordion-button  ${
              multiToMultiData?.servicesIds?.length === 0 ||
              multiToMultiData?.noOfParticipants === "" ||
              multiToMultiData?.noOfParticipants === null ||
              multiToMultiData?.serviceLocation === "" ||
              multiToMultiData?.serviceLocation === null
                ? "show"
                : "show"
            }`}
          >
            <div className="row w-100 align-items-center">
              <div className="col-md-6 col-8">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3 pointer"
                    height={22}
                    src="/images/schedule/serviceAvailable.svg"
                    alt=""
                  />
                  <p className="fw-light mb-0 text-dark">Service Available</p>
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="d-flex align-items-center justify-content-end actions">
                  <BootstrapTooltip
                    placement="top"
                    title="Some Operators prefer to limit some services to certain parts of their schedule. Use this tool to select from your services which of those services on your list are available during specific schedule conditions you custom build"
                  >
                    <img
                      id="serviceMulti"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  {/* <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isBreak"
                      // onChange={handleToggleChange}
                      // checked={breaks}
                      id="flexSwitchCheckChecked"
                    />
                  </div> */}
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapse"
          className={`accordion-collapse collapse  ${
            multiToMultiData?.servicesIds?.length === 0 ||
            multiToMultiData?.noOfParticipants === "" ||
            multiToMultiData?.noOfParticipants === null ||
            multiToMultiData?.serviceLocation === "" ||
            multiToMultiData?.serviceLocation === null
              ? "collapse"
              : "collapse"
          }`}
          aria-labelledby="heading"
          data-bs-parent="#accordion"
        >
          <div className="accordion-body px-2 py-4">
            <div className="row w-100 m-auto px-0 justify-content-center">
              <>
                <div className="col-6 text-center">
                  <p style={{ fontSize: "16px" }} className="mb-2 text-dark">
                    Start Time
                  </p>
                </div>
              </>
            </div>
            <div className="row w-100 m-auto px-0 rounded-5 justify-content-center">
              <div className="d-flex flex-md-row flex-column align-items-center col-6 px-md-2 px-0 py-1 justify-content-center">
                {/* <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Start Time:
                </p> */}
                <input
                  style={{ fontSize: "15px", width: 62 }}
                  type="time"
                  className="border-1 border-secondary rounded-6 align-items-center justify-content-center px-2 me-md-3 mb-md-0 mb-2"
                  // placeholder="hh:mm"
                  id="Text1"
                  name="startTime"
                  max="12:00"
                  onChange={handleTimeChange}
                  defaultValue={"00:00"}
                />
                <div
                  className="btn-group align-items-center"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    style={{ fontSize: "15px" }}
                    name="startTime"
                    onClick={handleMeridiemClick}
                    value="AM"
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "AM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>

                  <button
                    style={{ fontSize: "15px" }}
                    name="startTime"
                    onClick={handleMeridiemClick}
                    value="PM"
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "PM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    PM
                  </button>
                </div>
              </div>
            </div>
            <div className="row w-100 m-auto px-0 rounded-5 align-items-sm-stretch mt-4">
              <div className="col-md-4">
                <label
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Service:
                </label>
                <select
                  style={{ fontSize: "15px" }}
                  className="form-select"
                  aria-label="Default select example"
                  onChange={handleServiceChange}
                >
                  <option value="">Select</option>
                  {servicesData
                    ?.filter(
                      ({ providerService }) => providerService.status === 1
                    )
                    .map((services) => (
                      <option
                        value={`${services?.providerService?.id} ${services?.providerService?.duration}`}
                        key={services?.id}
                      >
                        {services?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-4">
                <label
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Number of Participants:
                </label>
                <input
                  style={{ fontSize: "15px" }}
                  type="number"
                  className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control ${
                    error ? "is-invalid" : ""
                  }`}
                  placeholder="Enter Value"
                  min={1}
                  max={99}
                  name="noOfParticipants"
                  onKeyDown={(e) => {
                    if (
                      e.key === "-" ||
                      e.key === "." ||
                      e.key === "e" ||
                      e.key === "E"
                    ) {
                      e.preventDefault();
                    }
                  }}
                  value={
                    multiToMultiData.noOfParticipants === null
                      ? ""
                      : multiToMultiData.noOfParticipants
                  }
                  onChange={(e) => {
                    if (e.target.value.length <= 2) {
                      handleChange(e);
                      if (e.target.value === "0" || e.target.value === "00") {
                        setError(true);
                      } else {
                        setError(false);
                      }
                    }
                  }}
                />
                {error ? (
                  <div className="text-danger small">
                    Number of Participants can not be 0
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <div className="col-md-4">
                <label
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Set Service Location
                </label>
                <input
                  style={{ fontSize: "15px" }}
                  type="text"
                  name="serviceLocation"
                  onChange={handleChange}
                  value={multiToMultiData?.serviceLocation}
                  className="border-1 form-control rounded-4 align-items-center justify-content-center px-2 form-control"
                  placeholder="Location"
                  id="Text1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceAccordion;

import React from "react";
import { Skeleton } from "@mui/material";

const Client = () => {
  return (
    <>
      <div className="col mb-3">
        <div
          style={{
            borderRadius: "6px",
          }}
          className={`d-flex border align-items-center p-3 border-b5`}
        >
          <div className="d-flex">
            <Skeleton
              className="me-2"
              variant="circular"
              height={44}
              width={44}
            />
            <div className="">
              <p
                className={`mb-0 fw-bold mb-1 text-primary mx-2`}
                style={{ fontSize: "15px" }}
              >
                <Skeleton width={90} animation="pulse" />
              </p>
              <p
                style={{ color: "grey", fontSize: "13px" }}
                className="mb-0 mt-1 mx-2"
              >
                <Skeleton width={60} animation="pulse" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const ClientsLoading = () => {
  return (
    <>
      {[1, 2, 3, 4]?.map((p) => (
        <Client key={p} />
      ))}
    </>
  );
};

export default ClientsLoading;

import React, { useLayoutEffect, useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { CURRENCY, MINUTE } from "../../../../../config/constant";
import { AppDispatch } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  scheduleOnToOneReadOnlySelector,
  updateOneToOne,
  updateOneToOneDoubleBooking,
  updateOneToOneDoubleBookingToAll,
  updateOneToOneServices,
} from "../../../../../reducers/scheduleReducer";
import { convertMinIntoHoursAndMin } from "../../../../../utils";
import { xconsole } from "../../../../../utils/console";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const AllowDoubAccordion = ({
  double,
  overTime,
  services,
  selectedService,
  totalServices,
  change,
  setChange,
}) => {
  const readOnlyData = useSelector(scheduleOnToOneReadOnlySelector);

  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (e: any) => {
    if (e.target.value.length <= 3) {
      dispatch(updateOneToOne({ key: e.target.name, value: e.target.value }));
    }
  };

  const handleToggleChange = (e: any) => {
    if (e.target.checked === true) {
      dispatch(updateOneToOne({ key: e.target.name, value: 1 }));
      dispatch(updateOneToOne({ key: "isAllowForDoubleBooking", value: 1 }));
    } else {
      dispatch(updateOneToOne({ key: e.target.name, value: 0 }));
      dispatch(updateOneToOne({ key: "isAllowForDoubleBooking", value: 0 }));
    }
  };

  const handleServiceChange = (id, index) => {
    dispatch(updateOneToOneDoubleBooking({ id, index }));
  };

  const { onToOne } = useSelector((state: any) => state.schedule);

  const checkAllServices = (services) => {
    return services?.every((service) => service?.isDoubleBooking === 1);
  };

  // console.log("services", checkAllServices(onToOne?.services));

  useEffect(() => {
    checkAllServices(onToOne?.services);
  }, [onToOne?.services]);

  return (
    <div className="accordion mb-3" id="accordionExample3">
      <div
        className={`accordion-item ${
          onToOne?.isAllowDoubleBooking === 1
            ? ["", "0", "00", "000", null].includes(overTime) ||
              onToOne.doubleBookingServicesIds.length === 0
              ? "border-danger"
              : change.doubleBooking
              ? "border-start-primary"
              : ""
            : onToOne?.isAllowDoubleBooking === 1
            ? change.doubleBooking
              ? "border-start-primary"
              : ""
            : ""
        }`}
      >
        <h2 className="accordion-header" id="headingOne">
          <button
            style={{ backgroundColor: "#f9fafb", minHeight: "4.5rem" }}
            className="accordion-button px-md-4 px-2 collapsed"
            type="button"
          >
            <div className="row w-100 align-items-center">
              <div className="col-md-6 col-8">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3 pointer"
                    height={22}
                    src="/images/schedule/doubleBooking.svg"
                    alt=""
                  />
                  <div>
                    <p className="fw-light mb-0 text-dark">
                      Allow for Double Booking
                    </p>
                    {readOnlyData?.isAllowDoubleBooking === 1 ? (
                      <p
                        style={{ fontSize: "12px" }}
                        className="fw-light my-0 text-secondary"
                      >
                        <span className="mx-1">Overlapping time</span>
                        {convertMinIntoHoursAndMin(
                          readOnlyData.overlapping_time
                        )}
                        <FiberManualRecordIcon
                          className="ms-2 me-1"
                          sx={{ fontSize: 9, color: "#94A3B8" }}
                        />{" "}
                        {readOnlyData?.allServices === 1
                          ? "All Services"
                          : `Service available:  ${
                              readOnlyData?.services?.filter(
                                ({ isDoubleBooking, isAvailable }) =>
                                  isDoubleBooking === 1 && isAvailable === 1
                              )?.length
                            }`}
                        {/* {services === 1 ? (
                          "All Services"
                        ) : (
                          <div className="d-flex">
                            <p className="fw-light mb-0 text-dark">
                              Service available:
                            </p>
                            <p style={{ fontSize: '14px' }} className="ms-1 p-0 text-success">
                              {selectedService?.length}
                            </p>
                          </div>
                        )} */}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="d-flex align-items-center justify-content-end actions">
                  <BootstrapTooltip
                    placement="top"
                    title="Some operators like to schedule customers in an overlapping way. Set the amount of appointment time you allow for overlapping appointments"
                  >
                    <img
                      id="allowDoubOne"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="isAllowDoubleBooking"
                      onChange={(e) => {
                        setChange({
                          ...change,
                          doubleBooking: true,
                        });
                        handleToggleChange(e);
                      }}
                      checked={double}
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse3"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapse3"
          className={`accordion-collapse collapse ${
            onToOne?.allServices === 0 || overTime === "" || overTime === null
              ? onToOne?.doubleBookingServicesIds?.length === 0 ||
                overTime === "" ||
                overTime === null
                ? onToOne?.isAllowDoubleBooking === 1
                  ? "show"
                  : "collapse"
                : "show"
              : "show"
          }`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample3"
        >
          <div className="accordion-body px-2 py-4">
            <div className="row w-100 m-auto px-0 rounded-5">
              <div className="align-items-center col-md-4">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Overlapping time:
                </p>

                <div className="input-group">
                  <input
                    name="overlapingTime"
                    style={{ fontSize: "15px" }}
                    type="number"
                    max={999}
                    step={15}
                    min={0}
                    onKeyPress={(e) => !/\d/.test(e.key) && e.preventDefault()}
                    className={`border-1 rounded-4 px-2 form-control border-end-0 ${
                      ["0", "00", "000"].includes(overTime) ? "is-invalid" : ""
                    }`}
                    placeholder="Enter time"
                    onChange={(e) => {
                      setChange({
                        ...change,
                        doubleBooking: true,
                      });
                      handleChange(e);
                    }}
                    value={overTime ? overTime : ""}
                    id="Text1"
                  />

                  <span
                    className="input-group-text px-md-2 px-1"
                    id="basic-addon1"
                  >
                    {MINUTE}
                  </span>
                </div>
                {["0", "00", "000"].includes(overTime) ? (
                  <>
                    {checkAllServices(onToOne?.services)}
                    <p
                      style={{ fontSize: "12px" }}
                      className="mb-0 text-danger"
                    >
                      Overlapping time cannot be 0
                    </p>
                  </>
                ) : null}
              </div>
              <div className="d-flex align-items-center col-md-8 pe-4 justify-content-end mt-4">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  All Services:
                </p>
                <div
                  style={{ width: 62 }}
                  className="btn-group align-items-center"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    style={{ fontSize: "15px" }}
                    type="button"
                    name="allServices"
                    onClick={() => {
                      handleChange({
                        target: { name: "allServices", value: 1 },
                      });
                      setChange({
                        ...change,
                        doubleBooking: true,
                      });
                      dispatch(updateOneToOneDoubleBookingToAll({ select: 1 }));
                    }}
                    className={`btn btn-outline-secondary rounded-5 py-0 px-2 ${
                      checkAllServices(onToOne?.services)
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    YES
                  </button>
                  {/* <button
                    style={{ fontSize: "15px" }}
                    type="button"
                    name="allServices"
                    onClick={() => {
                      dispatch(updateOneToOneDoubleBookingToAll({ select: 0 }));
                      setChange({
                        ...change,
                        doubleBooking: true,
                      });
                      handleChange({
                        target: { name: "allServices", value: 0 },
                      });
                    }}
                    className={`btn btn-outline-secondary rounded-5 py-0 px-2 ${
                      !checkAllServices(onToOne?.services)
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    NO
                  </button> */}
                </div>
              </div>
              {/* {!services ? ( */}
              <div className="row mt-4 g-2">
                {onToOne?.services
                  // ?.filter(({ isAvailable }) => isAvailable === 1)
                  ?.map((S: any, idx: React.Key) =>
                    S.isAvailable === 1 ? (
                      <div key={idx} className="col-md-4 col-sm-6 col-12">
                        <div className="bg-light-sky p-2">
                          <div className="form-check">
                            <input
                              onChange={() => {
                                xconsole.test(S?.service.id);
                              }}
                              className="form-check-input"
                              type="checkbox"
                              name="services"
                              checked={S.isDoubleBooking === 1 ? true : false}
                              // onToOne?.services
                              //   ?.filter(
                              //     ({ isDoubleBooking }) => isDoubleBooking === 1
                              //   )
                              //   .find((SS) => SS?.id == S.id)
                              //   ? true
                              //   : false
                              // }
                              id={`doubleCheckbox-${S?.service?.id}-${idx}`}
                            />
                            <label
                              style={{ fontSize: "14px" }}
                              className="form-check-label"
                              id={`checkbox-${S?.service?.id}-${idx}`}
                              htmlFor={`doubleCheckbox-${S?.service?.id}-${idx}`}
                            >
                              {S?.service?.service
                                ? S?.service?.service?.name
                                : S?.service?.name}
                            </label>
                          </div>
                        </div>
                      </div>
                    ) : null
                  )}
              </div>
              {/* ) : null} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllowDoubAccordion;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clientListSelector,
  fetchProviderConnects,
} from "../../reducers/clientReducer";
import { fetchProviderPublicProfileData } from "../../helper/provider/Auth";
import { AppDispatch } from "../../store";
import { getProviderMedia, mediaSelector } from "../../reducers/mediaReducer";
import ProviderMedia from "./components/ProviderMedia";
import { useParams } from "react-router-dom";

const PublicProfile = () => {
  // const profile = useSelector(memberProfile);
  const [profileData, setProfileData] = useState<any>();

  const { Id } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const clientListData = useSelector(clientListSelector);

  const avatar = {
    height: "88px",
    width: "89px",
    border: "4px solid white",
  };

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = async () => {
    const profile = await fetchProviderPublicProfileData(Id);
    const data = profile.data.data;
    setProfileData(data);
    // console.log("profile data", data);
  };

  useEffect(() => {
    dispatch(fetchProviderConnects(Id));
    dispatch(getProviderMedia(Id));
    return () => {};
  }, [Id, dispatch]);

  const media = useSelector(mediaSelector);

  const fsFourteen = {
    fontSize: "14px",
  };
  const connection = {
    height: "32px",
    width: "32px",
    border: "2px solid white",
    marginLeft: "-8px",
  };
  const textGrey = {
    color: "#475467",
  };

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "72vh" }}
        className="container-fluid bg-white rounded-5 py-4 px-xxl-5 px-lg-4 px-1"
      >
        <div className="d-flex flex-md-row flex-column align-items-start justify-content-between pb-2 mx-2">
          <div className="row align-items-start justify-content-start mb-md-0 mb-2">
            <div className="col-md-4 col-2">
              <img
                style={{ ...avatar }}
                src={
                  profileData?.profileImage
                    ? profileData?.profileImage
                    : "/images/avatar2.png"
                }
                alt="avatar"
                className="rounded-circle shadow-sm"
              />
            </div>
            <div className="col-md-8 col-10 mt-2">
              <p
                className="mb-0 ms-4"
                style={{
                  fontSize: "28px",
                  fontWeight: 600,
                  lineHeight: "38px",
                }}
              >
                {`${profileData?.firstName ? profileData?.firstName : "NA"} ${
                  profileData?.lastName ? profileData?.lastName : ""
                }`}
              </p>
              <p style={{ fontSize: "16px" }} className="mb-0 ms-4">
                {profileData?.city ? profileData?.city + "," : ""} 10miles away
              </p>
              <div className="ms-4 ps-1 d-flex mt-2">
                {clientListData?.slice(0, 10).map((data) => (
                  <img
                    style={{ ...connection }}
                    src={
                      data?.provider2
                        ? data?.provider2?.profileImage
                        : data?.client1?.profileImage
                    }
                    alt="avatar"
                    className="rounded-circle"
                  />
                ))}
                {clientListData?.length > 10 && (
                  <div
                    style={{
                      ...connection,
                      fontSize: "13px",
                      backgroundColor: "#F2F4F7",
                    }}
                    className={`rounded-circle p-1 d-flex align-items-center justify-content-center pointer`}
                  >
                    <p style={{ ...textGrey }} className="m-0 p-0">
                      +{clientListData?.length - 10}
                    </p>
                  </div>
                  // <button
                  //   style={{ whiteSpace: "nowrap", ...fsFourteen }}
                  //   className="btn btn-outline-secondary  border hover-primary2 d-flex flex-row align-items-center ps-1 border-b5 me-2"
                  // >
                  //   <img
                  //     height={"16.11px"}
                  //     width={"16px"}
                  //     src="/images/message.png"
                  //     alt="plus"
                  //     className="mx-2"
                  //   />
                  //   {clientListData?.length - 10} more
                  // </button>
                )}
                {/* {clientListData?.map((data) => (
                  <img
                    style={{ ...connection }}
                    src={data?.profileImage}
                    alt="avatar"
                    className="rounded-circle"
                  />
                ))} */}
              </div>
            </div>
          </div>
          <div className="d-flex mt-2">
            <button
              style={{ whiteSpace: "nowrap", ...fsFourteen }}
              className="btn btn-primary d-flex flex-row align-items-center ps-1 border-b5 me-2"
            >
              <img
                height={"16.11px"}
                width={"16px"}
                src="/images/connect.png"
                alt="plus"
                className="mx-2"
              />
              Connect
            </button>
            <button
              style={{ whiteSpace: "nowrap", ...fsFourteen }}
              className="btn btn-outline-secondary  border hover-primary2 d-flex flex-row align-items-center ps-1 border-b5 me-2"
            >
              <img
                height={"16.11px"}
                width={"16px"}
                src="/images/message.png"
                alt="plus"
                className="mx-2"
              />
              Message
            </button>
          </div>
        </div>
        <hr />
        <div className="mt-4 text-center mx-4 mb-4">
          <p
            className={`mb-2 mt-1 text-dark text-start`}
            style={{ fontSize: "18px" }}
          >
            Bio:
          </p>
          <p
            className={`mb-2 mt-1 text-start`}
            style={{ fontSize: "16px", color: "#4B5563" }}
          >
            {profileData?.bio}
          </p>
        </div>
        <div className="mx-4">
          <ProviderMedia media={media} publicProfile={true} />
        </div>
      </div>
    </div>
  );
};

export default PublicProfile;

import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";
import { ConnectionStatus, urlParameter } from "../../../components/common/types";

export const fetchMemberListRequest = async (status: ConnectionStatus) => {
  try {
    return await client.get(provider_URLS.member.MEMBER_LIST, {
      params: { status },
    });
  } catch (error) {
    return error;
  }
};

export const fetchMemberProfileRequest = async (id: urlParameter) => {
  try {
    return await client.get(provider_URLS.member.GET_MEMBER_PROFILE(id));
  } catch (error) {
    return error;
  }
};

export const getProviderConnects = async (id: urlParameter) => {
  try {
    return await client.get(provider_URLS.member.GET_MEMBER_CONNECT(id));
  } catch (error) {
    return error;
  }
};

export const addMemberRequest = async (data: any) => {
  try {
    return await client.post(provider_URLS.member.ADD_MEMBER, data);
  } catch (error) {
    return error;
  }
};

export const memberVicRequest = async (data: any) => {
  try {
    return await client.post(provider_URLS.member.VIC_MEMBER, data);
  } catch (error) {
    return error;
  }
};

export const DisconnectConnection = async (data: any) => {
  try {
    return await client.put(provider_URLS.member.DISCONNECT_MEMBER, data);
  } catch (error) {
    return error;
  }
};

export const approveConnection = async (data: any) => {
  try {
    return await client.put(provider_URLS.member.CONNECTION_APPROVAL, data);
  } catch (error) {
    return error;
  }
};

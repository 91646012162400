import React from "react";

const CredentialDetail = ({ issDate, expDate, url }) => {
  return (
    <div className="row mt-4">
      <div className="col-md-3 col-6">
        <p className={`mb-0 text-dark`} style={{ fontSize: "16px" }}>
          Issue Date
        </p>
        <p className={`mb-0 text-secondary`} style={{ fontSize: "14px" }}>
          {issDate}
        </p>
      </div>
      <div className="col-md-3 col-6 mb-md-0 mb-3">
        <p className={`mb-0 text-dark`} style={{ fontSize: "16px" }}>
          Expiry Date
        </p>
        <p className={`mb-0 text-secondary`} style={{ fontSize: "14px" }}>
          {expDate}
        </p>
      </div>
      {url !== "https://" && (
        <div className="col-md-6 col-12">
          <p className={`mb-0 text-dark`} style={{ fontSize: "16px" }}>
            Certificate URL
          </p>
          <p
            className={`mb-0 text-secondary`}
            style={{
              fontSize: "14px",
              wordBreak: "break-all",
              whiteSpace: "normal",
            }}
          >
            {url}
          </p>
        </div>
      )}
    </div>
  );
};

export default CredentialDetail;

export const poppinsFont = {
  fontFamily: "Poppins, sans-serif",
};

export const whiteText = {
  color: "white",
  fontSize: "13px",
};

export const greyText = {
  color: "#6B7280",
  fontSize: "13px",
};

export const fsFourteen = {
  fontSize: "14px",
};

export const textLight = {
  fontWeight: "400",
};
export const fs19 = {
  fontSize: "19px",
};

export const textLightC = {
  color: "#667085",
  fontWeight: "400",
};

import React from "react";

const ChatSetup = ({ children }) => {
  return (
    <div className="mx-lg-5 my-4 bg-white rounded-5">
      <div className="container-fluid px-0">{children}</div>
    </div>
  );
};

export default ChatSetup;

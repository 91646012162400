import { Box } from "@mui/material";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`kazzah-tabpanel-${index}`}
            aria-labelledby={`kazzah-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}



export const a11yProps = (index: number) => {
    return {
        id: `kazzah-tab-${index}`,
        'aria-controls': `kazzah-tabpanel-${index}`,
    };
}
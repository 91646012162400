import { useState, useRef } from "react";
import gsap from "gsap";
import { useSelector } from "react-redux";
import { serviceLevelThreeSelector } from "../../../../reducers/serviceReducer";
import LevelTwo from "./LevelTwo";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";
import { localStorageObject } from "../../../../utils";
import { EXPLOREFILTERS } from "../../../../config/constant";

const ExploreCarousel = ({ data, filters, setFilters }: any) => {
  const handleServiceClick = (id: number | string): void => {
    if (filters.serviceId !== id) {
      setFilters({ ...filters, serviceId: id });
      localStorageObject.set(EXPLOREFILTERS, {
        ...filters,
        serviceId: id,
      });
    }
  };
  const levelThreeServices = useSelector(serviceLevelThreeSelector);

  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(false);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  const anim = (e) => {
    gsap.from(e.target, { scale: 1 });
    gsap.to(e.target, { scale: 1.5 });
  };
  const anim2 = (e) => {
    gsap.from(e.target, { scale: 1.5 });
    gsap.to(e.target, { scale: 1 });
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(false);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    // <div className="row m-auto">
    //   {data?.map(({ icon, name, id }: levelTwoCardType) => (
    //     <div
    //       key={id}
    //       onClick={() => handleServiceClick(id)}
    //       className={`${levelThreeServices[0]?.parentId === id
    //         ? " bg-primary hover-primary text-white"
    //         : "text-secondary"
    //         } col px-3 border border-1 hover-light`}
    //     >
    //       <LevelTwo icon={icon} name={name} />
    //     </div>
    //   ))}
    // </div>
    <div className="scroll-App w-100">
      {/* {scrollX !== 0 && ( */}
      <BootstrapTooltip title="Backward" placement="top">
        <button
          style={{
            borderRadius: "30px",
            height: "40px",
            width: "40px",
            marginRight: "-28px",
            zIndex: "1",
          }}
          className="prev mb-3 mt-auto mb-auto"
          onClick={() => slide(-150)}
        >
          <img
            className=""
            style={{ objectFit: "contain" }}
            height={16}
            width={20}
            src="/images/services/left.png"
            alt=""
          />
        </button>
      </BootstrapTooltip>
      {/* )} */}
      <ul
        ref={scrl}
        className={`scroll-list col ${
          data?.length >= 6 ? "row-cols-xxl-6" : "row-cols-xl-5"
        } row-cols-md-3 row-cols-sm-2 row-cols-1 ps-0`}
        onScroll={scrollCheck}
      >
        {data?.map(({ id, icon, name }, idx) => (
          <div
            // style={{ maxWidth: "200px" }}
            key={id}
            onClick={() => handleServiceClick(id)}
            className={` ${
              levelThreeServices[0]?.parentId === id ? " " : " "
            } col ${
              idx + 1 === data.length
                ? "pe-1 ps-2"
                : idx === 0
                ? "ps-0 pe-2"
                : "px-2"
            }`}
          >
            <LevelTwo id={id} icon={icon} name={name} filters={filters} />
          </div>
        ))}
      </ul>
      {/* <ul ref={scrl} className="scroll-list" onScroll={scrollCheck}>
          {Fdata?.fruits?.map((idx) => (
            <div
              // style={{ width: "200px" }}
              key={idx}
              // onClick={() => handleServiceClick(id)}
              className={`${levelThreeServices[0]?.parentId === idx
                ? " bg-primary hover-primary"
                : " "
                }  px-5 border border-1 hover-light`}
            >
              <LevelTwo icon={"icon"} name={"name"} />
            </div>
          ))}
        </ul> */}
      {/* {!scrolEnd && ( */}
      <BootstrapTooltip title="Forward" placement="top">
        <button
          style={{
            borderRadius: "30px",
            height: "40px",
            width: "40px",
            marginLeft: "-28px",
            marginRight: "9px",
            zIndex: "1",
          }}
          className="next scroll-button mb-3 px-2 mt-auto mb-auto"
          onClick={() => slide(+150)}
        >
          <img
            className=""
            style={{ objectFit: "contain" }}
            height={16}
            width={20}
            src="/images/services/right.png"
            alt=""
          />
        </button>
      </BootstrapTooltip>
      {/* )} */}
    </div>
  );
};

export default ExploreCarousel;

import React from "react";
import MuiDrawer from "@mui/material/Drawer";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
} from "@mui/material";
import {
  loggedInUserProfile,
  profileProgressSelector,
  statusSelector,
} from "../../../reducers/authReducer";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SidebarRoutes } from "../../../config/sidebar";

const SideBar = ({ open, DrawerHeader }) => {
  const drawerWidth = 280;

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const status = useSelector(statusSelector);

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  const profileProgressData = useSelector(profileProgressSelector);

  const authUserData: any = useSelector(loggedInUserProfile);

  return (
    <Drawer className="h-100vh" variant="permanent" open={open}>
      <DrawerHeader className="justify-content-center">
        <img
          className="me-2"
          height={35}
          width={35}
          src="/images/KZH-PRO.jpeg"
          alt=""
        />
        <img src="/images/KZH-Written.svg" alt="" />
        {/* <IconButton onClick={handleDrawerClose}>
                            {theme.direction === "rtl" ? (
                                <ChevronRightIcon />
                            ) : (
                                <ChevronLeftIcon />
                            )}
                        </IconButton> */}
      </DrawerHeader>
      {/* <Divider /> */}
      <List>
        <div
          className="rounded mx-2 mb-3 mt-1"
          style={{
            minHeight:
              open && profileProgressData.profilePercentage <= 99
                ? 85
                : profileProgressData.profilePercentage == 100
                ? 60
                : 0,
            justifyContent: open ? "initial" : "center",
            backgroundColor: "#F4F3F3",
          }}
        >
          <div
            className={`d-flex align-items-center justify-content-start ${
              open ? "p-2" : "p-1 justify-content-center"
            }`}
          >
            <div className="avatar">
              <img
                style={{
                  height: "2rem",
                  width: "2rem",
                  marginTop: open ? "2px" : "10px",
                  marginLeft: open ? "" : "4px",
                }}
                src={
                  authUserData?.profileImage
                    ? authUserData?.profileImage
                    : "/images/avatar2.png"
                }
                alt="avatar"
                className="rounded-circle w-avatar border border-primary"
              />
            </div>
            {open ? (
              <div>
                <p
                  style={{ fontSize: "14px" }}
                  className="p-0 m-0 pointer"
                  onClick={() => navigate("/profile")}
                >
                  {/* {authUserData?.firstName ? authUserData?.firstName : "User"}{" "} */}
                  {status === "loading" ? (
                    <Skeleton width={50} animation="pulse" />
                  ) : (
                    authUserData?.lastName
                  )}
                </p>
                <p
                  style={{ fontSize: "12px" }}
                  className="p-0 m-0 text-secondary"
                >
                  {/* {authUserData?.email ? authUserData?.email : "usermail@gmail.com"} */}
                  {status === "loading" ? (
                    <Skeleton width={50} animation="pulse" />
                  ) : (
                    authUserData?.username
                  )}
                </p>
              </div>
            ) : null}
          </div>
          {open ? (
            <div
              className={`d-flex align-items-center ${
                open ? "mb-2 mx-2" : "m-0"
              } ${
                profileProgressData.profilePercentage === 100
                  ? "d-none"
                  : "d-block"
              }`}
            >
              <div
                style={{ height: "8px", width: "210px" }}
                className="progress rounded-pill bg-white"
              >
                <div
                  className={`progress-bar ${
                    profileProgressData.profilePercentage >= 50
                      ? "bg-danger"
                      : "bg-danger"
                  }`}
                  role="progressbar"
                  aria-valuenow={40}
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{ width: `${profileProgressData.profilePercentage}%` }}
                ></div>
              </div>
              <p style={{ fontSize: "14px" }} className="ms-2 mb-0">
                {profileProgressData.profilePercentage}%
              </p>
            </div>
          ) : null}
        </div>
        {SidebarRoutes?.map(({ name, link, Icon }, index) => (
          <ListItem key={index} disablePadding sx={{ display: "block" }}>
            <Link style={{ textDecoration: "none" }} to={link}>
              <ListItemButton
                className={`rounded mb-2 ${
                  pathname === link && !pathname.includes(`/client/profile`)
                    ? "hover-primary"
                    : pathname.includes(`/client/profile`) &&
                      name.includes("Client")
                    ? "hover-primary"
                    : "null"
                }`}
                sx={{
                  backgroundColor:
                    pathname === link && !pathname.includes(`/client/profile`)
                      ? "#04BAC7"
                      : pathname.includes(`/client/profile`) &&
                        name.includes("Client")
                      ? "#04BAC7"
                      : null,
                  height: open ? 48 : 40,
                  justifyContent: open ? "initial" : "center",
                  px: 1,
                  mx: 1,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 2 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    sx={{
                      color: pathname === link ? "white" : "grey",
                    }}
                  />
                </ListItemIcon>

                <ListItemText
                  primary={name}
                  sx={{
                    opacity: open ? 1 : 0,
                    color:
                      pathname === link && !pathname.includes(`/client/profile`)
                        ? "white"
                        : pathname.includes(`/client/profile`) &&
                          name.includes("Client")
                        ? "white"
                        : "#4B5563",
                    // color: pathname == link ? "white" : "#4B5563",
                    textDecoration: "none",
                  }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      {/* <Divider /> */}
      {/* <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                       2                     minWidth: 0,
                                            mr: open ? 3 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                    </ListItemIcon>
                                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List> */}
    </Drawer>
  );
};

export default SideBar;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loggedInUserProfileRootService } from "../../../reducers/authReducer";
import { fetchSecondLevelChildServices } from "../../../reducers/serviceReducer";
import { AppDispatch } from "../../../store";
import ExploreMedia from "./components/ExploreMedia";
import NoMediaFound from "./components/NoMediaFound";

const Explore = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch<AppDispatch>();
  //   const credentialData = useSelector(credentialsSelector);
  const rootServices = useSelector(loggedInUserProfileRootService);

  useEffect(() => {
    dispatch(fetchSecondLevelChildServices(rootServices?.service?.id));

    return () => {};
  }, [rootServices?.service?.id]);

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "72vh" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="row w-100 m-auto">
          {false ? (
            <div className="col-md-8 col-12 mx-auto my-4 d-flex align-items-center">
              <input
                type="text"
                id="explore-search"
                // onChange={searchHandle}
                className="form-control explore-search border-b5"
                placeholder="search here"
              />
              <div
                className="btn-group ms-3"
                role="group"
                aria-label="Basic outlined example"
              >
                <button
                  type="button"
                  className="btn btn-outline-light border border-b5"
                >
                  <img
                    src="/images/explore/location.png"
                    alt="icon"
                    className="pointer my-1"
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light border border-b5"
                >
                  <img
                    src="/images/explore/like.png"
                    alt="icon"
                    className="pointer my-1"
                  />
                </button>
                <button
                  type="button"
                  className="btn btn-outline-light border border-b5"
                >
                  <img
                    src="/images/explore/star.png"
                    alt="icon"
                    className="pointer my-1"
                  />
                </button>
              </div>
              <button
                onClick={handleOpen}
                style={{ whiteSpace: "nowrap" }}
                className="btn btn-primary d-flex flex-row align-items-center ms-3 ps-1 border-b5"
              >
                <img
                  height={15}
                  src="/images/explore/plus.png"
                  alt="plus"
                  className="mx-2"
                />
                Add Media
              </button>
            </div>
          ) : (
            <ExploreMedia />
          )}
        </div>
        {false ? <NoMediaFound /> : <></>}
      </div>
    </div>
  );
};

export default Explore;

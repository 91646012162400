import { useState } from "react";

interface Coordinates {
  lat: number;
  lng: number;
}

function useCoordinates(): [Coordinates | null, () => void] {
  const [coordinates, setCoordinates] = useState<Coordinates | null>(null);

  function generateCoordinates(): void {
    //get actual coordinates from the browser
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        // console.log("latitude", latitude);
        // console.log("longitude", longitude);

        const randomLat = latitude;
        const randomLng = longitude;
        setCoordinates({ lat: randomLat, lng: randomLng });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  return [coordinates, generateCoordinates];
}

export default useCoordinates;

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConnectionStatus, urlParameter } from "../../components/common/types";
import { FAIL, IDLE, LOADING, SUCCESS } from "../../config/constant";
import {
  addMemberRequest,
  DisconnectConnection,
  fetchMemberListRequest,
  fetchMemberProfileRequest,
  getProviderConnects,
  memberVicRequest,
} from "../../helper/provider/member";
import { RootState } from "../../store";
import { xconsole } from "../../utils/console";
import { TriangulationUpdate } from "../../helper/provider/TGL";
import { SHOW_SUCCESS } from "../../utils/ToastMessage";

export interface mediaState {
  memberList: Array<any>;
  member: any;
  error: string | null;
  status: string;
  vicStatus: string;
  profileStatus: string;
}

export const initialState: mediaState = {
  memberList: [],
  member: {},
  error: null,
  status: "idle",
  vicStatus: "idle",
  profileStatus: "idle",
};

export const fetchMemberList = createAsyncThunk(
  "fetch/member/list",
  async (status: ConnectionStatus) => {
    try {
      const res: any = await fetchMemberListRequest(status);
      const { data, success, message } = res.data;
      // console.log(data);

      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
    }
  }
);

export const fetchProviderConnects = createAsyncThunk(
  "get/provider/connects",
  async (id: any) => {
    try {
      const res: any = await getProviderConnects(id);
      const { data, success } = res.data;
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const fetchMemberProfile = createAsyncThunk(
  "fetch/member/profile",
  async (id: urlParameter) => {
    try {
      const res: any = await fetchMemberProfileRequest(id);
      // console.log("res", res);

      const { data, success, message } = res.data;
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
      return error;
    }
  }
);

export const addMemberAction = createAsyncThunk(
  "add/member",
  async (formData: any) => {
    try {
      const res: any = await addMemberRequest(formData.formData);
      const { data, success, message } = res.data;
      if (success) {
        SHOW_SUCCESS(true, "Request sent");
        formData.handleClose();
        if (data?.client1.username == null) {
          TriangulationUpdate({
            senderId: formData.currentUser,
            senderType: "provider",
            sharedUserIds: [""],
            sharedUserTypes: [""],
            nkNumbers: [`${formData.sharedUser}`],
            nkTypes: ["member"],
            serviceIds: [""],
            flow: "normal_connection",
          });
        }
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
      return error;
    }
  }
);

export const memberVicAction = createAsyncThunk(
  "member/vic",
  async (formData: any) => {
    try {
      const res: any = await memberVicRequest(formData);
      const { data, success, message } = res.data;
      return {
        data,
        formData,
        success,
        code: 200,
      };
    } catch (error) {
      xconsole.fail(error.message);
      return error;
    }
  }
);

export const disconnectWithConnection = createAsyncThunk(
  "disconnect/connection",
  async (Data: any, thunkApi) => {
    try {
      const res: any = await DisconnectConnection(Data.data);
      const { data, success } = res.data;

      if (success) {
        Data.navigate("/client");
      }

      return {
        Data,
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

const clientSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    // updateSocialLinkArray: (state, { payload }: PayloadAction<any>) => {
    //   const { id, userName } = payload;
    //   state.defaultSocialLinks[id].userName = payload.userName;
    //   // xconsole.test(`userName ${payload.userName}`);
    //   // xconsole.test(`id ${payload.id}`);
    // },
    // resetFilters: (state, { payload }: { payload?: any }) => {
    //   state.filter = initialState.filter;
    // },
    // filterByService: (state, { payload }: PayloadAction<any>) => {
    //   const { serviceId } = payload;
    //   state.filter.exploreId = serviceId;
    // },
    // searchByServiceName: (state, { payload }: PayloadAction<any>) => {
    //   const { serviceName } = payload;
    //   state.filter.serviceName = serviceName.toUpperCase();
    // },
  },
  extraReducers: (builder) => {
    // explore

    builder.addCase(fetchMemberList.pending, (state) => {
      state.status = LOADING;
      state.error = null;
    });
    builder.addCase(fetchMemberList.fulfilled, (state, { payload }) => {
      const { data, success }: any = payload;
      if (success) {
        state.memberList = data.map(({ connection }) => connection);
        state.status = SUCCESS;
      }
    });
    builder.addCase(fetchMemberList.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = IDLE;
    });

    builder.addCase(fetchMemberProfile.pending, (state, action) => {
      state.profileStatus = "loading";
    });
    builder.addCase(fetchMemberProfile.fulfilled, (state, action) => {
      state.profileStatus = "succeeded";
      state.member = action.payload.data;
      // console.log("member profile", action.payload);
    });
    builder.addCase(fetchMemberProfile.rejected, (state, action) => {
      state.profileStatus = "failed";
    });

    builder.addCase(fetchProviderConnects.pending, (state, action) => {
      state.status = "loading";
      state.memberList = [];
    });
    builder.addCase(fetchProviderConnects.fulfilled, (state, action) => {
      state.status = "succeeded";
      state.memberList = action.payload.data;
    });
    builder.addCase(fetchProviderConnects.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(addMemberAction.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(addMemberAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      // state.memberList.push(action.payload.data.client1);
    });
    builder.addCase(addMemberAction.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(memberVicAction.pending, (state, action) => {
      state.vicStatus = "loading";
    });
    builder.addCase(memberVicAction.fulfilled, (state, action) => {
      state.vicStatus = "succeeded";
      const { data, success, formData }: any = action.payload;
      if (success) {
        const { formData } = action.payload;
        state.member.isVic = formData.isVic;
        const index = state.memberList.findIndex(
          (item: any) => item.id === formData.clientId
        );
        if (index !== -1) {
          // Update the "Fav" key of the found object with data.isFav
          state.memberList[index].isVic = formData.isVic;
        }
      }
      // state.memberList.push(action.payload.data.client1);
    });
    builder.addCase(memberVicAction.rejected, (state, action) => {
      state.vicStatus = "failed";
    });
    builder.addCase(disconnectWithConnection.pending, (state, action) => {
      // state.status = LOADING;
    });
    builder.addCase(disconnectWithConnection.fulfilled, (state, action) => {
      const { Data } = action.payload;
      // state.status = SUCCESS;
      const index = state.memberList.findIndex(
        (item) => item.id === Data?.data?.id
      );
      if (index !== -1) {
        state.memberList.splice(index, 1);
      }
    });
    builder.addCase(disconnectWithConnection.rejected, (state, action) => {
      // state.status = FAIL;
    });

    // configuration
  },
});

// export const {
//   updateSocialLinkArray,
//   filterByService,
//   searchByServiceName,
//   resetFilters,
// } = clientSlice.actions;

export default clientSlice.reducer;

export const clientListSelector = (state: RootState) => state.client.memberList;
export const memberProfile = (state: RootState) => state.client.member;
export const profileStatusSelector = (state: RootState) =>
  state.client.profileStatus;
export const memberStatusSelector = (state: RootState) => state.client.status;
export const vicStatusSelector = (state: RootState) => state.client.vicStatus;
// export const uploadMediaStatusSelector = (state: RootState) =>
//   state.media.UpdateStatus;
// export const socialLinkSelector = (state: RootState) =>
//   state.media.defaultSocialLinks;
// export const MultiServiceLevelTwoSelector = (state: RootState) =>
//   state.service.levelTwo.map((u: { id: any; name: any }) => {
//     return {
//       value: `${u.id}`,
//       label: `${u.name}`,
//     };
//   });

// explore selectors
// export const exploreMediaSelector = (state: RootState) => state.media.explore;

// export const exploreFilterSelector = (state: RootState) =>
//   state.media.filter.exploreId;
//   export const statusSelector = (state: RootState) => state.media.status;

// export const serviceNameFilterSelector = (state: RootState) =>
//   state.media.filter.serviceName;

import React, { useLayoutEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMultiToMultiBreakTime,
  removeMultiToMultiBreakTime,
  scheduleMultiToMultiReadOnlySelector,
  scheduleMultiToMultiSelector,
  updateMultiToMulti,
} from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import { convert24To12 } from "../../../../../utils";
import DisplayTime from "../common/DisplayTime";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const DailyBrAccordion = ({ breakTimes, breaks }) => {
  const [breakData, setBreakData] = useState(breakTimes);
  const dispatch = useDispatch<AppDispatch>();
  const { multiToMulti } = useSelector((state: any) => state.schedule);

  const readOnlyData = useSelector(scheduleMultiToMultiReadOnlySelector);
  const handleToggleChange = (e: any) => {
    dispatch(
      updateMultiToMulti({
        key: e.target.name,
        value: e.target.checked ? 1 : 0,
      })
    );
  };

  const addBrTime = () => {
    dispatch(addMultiToMultiBreakTime());
  };

  const removeBrTime = (index) => {
    dispatch(removeMultiToMultiBreakTime(index));
  };

  const scheduleMultiToMulti = useSelector(scheduleMultiToMultiSelector);

  useMemo(() => setBreakData(breakTimes), [readOnlyData.breakTimes]);
  return (
    <div className="accordion mb-3 " id="accordionExample1">
      <div
        className={`accordion-item ${
          multiToMulti?.isBreak === 1
            ? multiToMulti?.breakTimes?.length === 0
              ? "border-danger"
              : null
            : null
        }`}
      >
        <h2 className="accordion-header" id="headingOne">
          <button
            style={{ backgroundColor: "#f9fafb" }}
            className={`accordion-button collapsed`}
            // type="button"
          >
            <div className="row w-100 align-items-center">
              <div className="col-md-6 col-8">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3 pointer"
                    height={22}
                    src="/images/schedule/DailySchedule.svg"
                    alt=""
                  />
                  <div>
                    <p className="fw-light mb-0 text-dark">Daily Break</p>

                    {breaks ? (
                      <p
                        style={{ fontSize: "12px" }}
                        className="fw-light my-0 text-secondary"
                      >
                        {breakData?.map(
                          (
                            { id, breakStartTime, breakEndTime }: any,
                            idx: number,
                            arr: string | any[]
                          ): JSX.Element => (
                            <span key={id} style={{ display: "inline-block" }}>
                              <span style={{ margin: "4px" }}>
                                {breakStartTime} - {breakEndTime}
                              </span>
                              {arr?.length > 0 && !(idx + 1 === arr.length)
                                ? ` , `
                                : ` `}
                            </span>
                          )
                        )}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-4">
                <div className="d-flex align-items-center justify-content-end actions">
                  {multiToMulti?.isBreak === 1 ? (
                    <AddCircleIcon
                      className="pointer me-2 ps-1"
                      onClick={() => {
                        if (scheduleMultiToMulti.isBreak === 1) {
                          addBrTime();
                        }
                      }}
                      sx={{ color: "grey" }}
                    />
                  ) : null}
                  <BootstrapTooltip
                    placement="top"
                    title="Some providers like to build in a time block in their day where clients can not access your schedule."
                  >
                    <img
                      id="dailyBrMulti"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isBreak"
                      onChange={handleToggleChange}
                      checked={breaks}
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse1"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapse1"
          className={`accordion-collapse collapse  ${
            multiToMulti?.isBreak === 1
              ? multiToMulti?.breakTimes?.length === 0
                ? "show"
                : "show"
              : "collapsed"
          }`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample1"
        >
          <div className="accordion-body px-2 pt-xl-4 pt-2 text-end pb-0">
            {/* <button
              style={{ fontSize: "13px" }}
              className="btn btn-sm btn-secondary p-1 me-2"
              onClick={addBrTime}
            >
              <img
                height={25}
                src="/images/Media/add.svg"
                alt="avatar"
                className="mx-2"
              />
              ADD
            </button> */}
            <div className="row w-100 m-auto px-0">
              {breakTimes?.length !== 0 ? (
                <>
                  <div className="col-6 text-center ps-md-5 ps-0">
                    <p
                      style={{ fontSize: "16px" }}
                      className="mb-2 text-dark ps-xl-5 ps-0"
                    >
                      Break Begin
                    </p>
                  </div>
                  <div className="col-6 text-center pe-md-5 pe-0">
                    <p
                      style={{ fontSize: "16px" }}
                      className="mb-2 text-dark pe-xl-5 pe-0"
                    >
                      Break End
                    </p>
                  </div>{" "}
                </>
              ) : null}
            </div>
            {breakTimes?.map(
              (
                { id, breakStartTime, breakEndTime }: any,
                idx: React.Key
              ): JSX.Element => (
                <>
                  <DisplayTime
                    key={id}
                    id={idx}
                    fullStartTime={breakStartTime}
                    fullEndTime={breakEndTime}
                    startTime={breakStartTime?.split(" ")[0]}
                    startMeridiem={breakStartTime?.split(" ")[1]}
                    endTime={breakEndTime?.split(" ")[0]}
                    endMeridiem={breakEndTime?.split(" ")[1]}
                    type="multi"
                    setChange={""}
                    change={""}
                    // startTime={breakStartTime}
                    // endTime={breakEndTime}
                  />
                  {/* <button
                    onClick={() => removeBrTime(idx)}
                    className="btn btn-danger"
                  >
                    X
                  </button> */}
                </>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailyBrAccordion;

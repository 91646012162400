import { Navigate, useLocation } from "react-router-dom";
import { getAuth } from "../../utils/AuthHelpers";

const ProtectedRoute = ({ children }) => {
  const token = getAuth();
  const location = useLocation();

  if (!token) {
    return (
      <Navigate to="auth/login" replace state={{ from: location.pathname }} />
    );
  }
  return children ? children : <Navigate to="/" replace />;
};

export default ProtectedRoute;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import TableLoading from "../../../components/common/TableLoading";
import { APPOINTMENTSTATUS, STATUSES } from "../../../config/constant";
import {
  appointmentPaginationSelector,
  appointmentPendingListSelector,
  getPaginatedAppointmentList,
  statusSelector,
} from "../../../reducers/appointmentReducer";
import { AppDispatch } from "../../../store";
import PendingRequestTable from "./PendingRequestTable";
import { fetchAllSchedule } from "../../../reducers/scheduleReducer";
import { localStorageObject } from "../../../utils";

const Appointment = () => {
  const dispatch = useDispatch<AppDispatch>();

  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("memberId");
  const time = queryParams.get("time");
  const status = queryParams.get("status");
  const [statusFilter, setStatusFilter] = useState(status ? status : "");
  const [timeFilter, setTimeFilter] = useState(time ? time : "");
  const [perpage, setPerpage] = useState(10);
  const [page, setPage] = useState(0);

  useEffect(() => {
    if (timeFilter !== "" || statusFilter !== "") {
      let statusFilterArray = statusFilter.split("&");
      if (statusFilterArray?.[1] === "isPaid=1") {
        dispatch(
          getPaginatedAppointmentList({
            status: "completed",
            timeFrame: timeFilter,
            perPage: perpage,
            isPaid: "1",
          })
        );
      } else if (statusFilterArray?.[1] === "isPaid=2,0") {
        dispatch(
          getPaginatedAppointmentList({
            status: statusFilter,
            timeFrame: timeFilter,
            perPage: perpage,
            isPaid: "0,2",
          })
        );
      } else {
        dispatch(
          getPaginatedAppointmentList({
            status: statusFilter,
            timeFrame: timeFilter,
            perPage: perpage,
          })
        );
      }
    } else if (id) {
      dispatch(
        getPaginatedAppointmentList({
          status: statusFilter,
          timeFrame: "",
          perPage: perpage,
          id: id,
        })
      );
    } else {
      dispatch(
        getPaginatedAppointmentList({
          status:
            "pending,declineByProvider,approveByProvider,completed,rescheduleByProvider",
          timeFrame: "",
          perPage: perpage,
        })
      );
    }
    dispatch(fetchAllSchedule());
    return () => {};
  }, [location.search, timeFilter, statusFilter, perpage]);

  // console.log(params);

  const appointmentStatus = useSelector(statusSelector);
  const pagination = useSelector(appointmentPaginationSelector);

  const handlePagination = (url: string) => {
    dispatch(
      getPaginatedAppointmentList({
        status: statusFilter ? statusFilter : "all",
        timeFrame: "",
        perPage: perpage,
        page: parseInt(url),
      })
    );
  };

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="d-flex justify-content-between px-3 py-3 flex-md-row flex-column">
          <div className="d-flex py-1 align-items-center">
            <h5 className="mb-0  main-head">Appointments</h5>
            {/* <button onClick={() => setdialog(!isDialog)}>open</button> */}
            {/* <dialog open={isDialog}>This is an open dialog window</dialog> */}
            <div className="d-flex align-items-center">
              <div
                style={{
                  height: pagination?.total <= 9 ? "18px" : "24px",
                  width: pagination?.total <= 9 ? "18px" : "24px",
                  fontSize: "12px",
                }}
                className={`bg-primary rounded-circle ms-2 d-flex align-items-center justify-content-center`}
              >
                <p className={`m-0 p-0 text-white`}>
                  {id
                    ? pendingAppointmentListData?.filter(
                        (f) => f.client.id == id
                      ).length
                    : pagination?.total}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center align-items-center gap-2">
            <div className="d-flex justify-content-center align-items-center gap-2">
              <label htmlFor="" className="text-center">
                Status
              </label>
              <select
                className="form-select border-b5"
                value={statusFilter}
                onChange={(e) => {
                  setStatusFilter(e.target.value);
                }}
              >
                {STATUSES.map((m) => (
                  <option key={m.key} value={m.key}>
                    {m.value}
                  </option>
                ))}
              </select>
            </div>
            <div>
              <button
                className="btn bg-transparent border border-1 border-b5 text-danger"
                onClick={() => {
                  setStatusFilter(
                    "pending,declineByProvider,approveByProvider,completed,rescheduleByProvider"
                  );
                  setTimeFilter("");
                }}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
        {false ? (
          <div className="row">
            <div className="col-4 m-auto px-4 py-5 text-center">
              <label>
                <img
                  src="/images/Media/UploadImg.svg"
                  alt="Upload"
                  className="pointer"
                />

                <h6 className="mb-0 fw-bold mt-3 mb-1">No Pending Requests</h6>
                <p
                  style={{ fontSize: "14px", color: "#667085" }}
                  className="mb-0 text-grey"
                >
                  All the requests put by your clients will be listed here You
                  can easily Approve or Decline the requests
                </p>
              </label>
            </div>
          </div>
        ) : (
          <div>
            <PendingRequestTable
              setRowsPerPage={setPerpage}
              rowsPerPage={perpage}
              setPage={setPage}
              page={page}
              pagination={pagination}
              handlePagination={handlePagination}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Appointment;

// params === null ? (
//   <>
//     <div
//       style={{ height: "55vh", overflowX: "auto" }}
//       className="mt-4 d-flex align-items-center justify-content-center w-100"
//     >
//       <small className="text-primary ms-1">
//         No appointment here
//       </small>
//     </div>
//   </>

import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  deleteStatus,
  deleteVacations,
  vacationSelector,
} from "../../../../../../reducers/scheduleReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../../store";
import { formateTheDate } from "../../../../../../utils";
import ConfModal from "../../../../../../components/common/ConfModal";
import DeletePrompt from "../../../../../../components/common/DeletePrompt";
import OverlayLoading from "../../../../../../components/common/OverlayLoading";

function createData(
  dayBegin: string,
  dayEnd: string,
  serviceLocation: string,
  id: number
) {
  return { dayBegin, dayEnd, serviceLocation, id };
}

// today's date and time
const today = new Date();
const dd = String(today.getDate()).padStart(2, "0");
const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
const yyyy = today.getFullYear();
const todayDate = mm + "-" + dd + "-" + yyyy;
// console.log(todayDate);

export default function VacationTable() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [id, setId] = React.useState()
  const vacationData = useSelector(vacationSelector);
  const deleteVacationStatus = useSelector(deleteStatus)

  const rows = vacationData
    .filter(({ endDateTime }) => formateTheDate(endDateTime) >= todayDate)
    .map(({ startDateTime, endDateTime, location, id }) =>
      createData(startDateTime, endDateTime, location, id)
    );

  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = (id: number) => {
    dispatch(deleteVacations(id));
  };

  // if (vacationData.length === 0) {
  //   return <h3>
  //     No data found
  //   </h3>
  // }

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead className="border-top">
            <TableRow>
              <TableCell>Day Begin</TableCell>
              <TableCell align="left">Day End</TableCell>
              <TableCell align="left">Location</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) => (
              <TableRow
                key={idx}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {formateTheDate(row.dayBegin)}
                </TableCell>
                <TableCell align="left">{formateTheDate(row.dayEnd)}</TableCell>
                <TableCell align="left">{row.serviceLocation}</TableCell>
                <TableCell align="left">
                  <button
                    style={{ fontSize: "14px" }}
                    className="btn btn-outline-danger me-2"
                    onClick={() => {
                      setId(row.id);
                      handleOpen()
                      // console.log(formateTheDate(row.dayEnd));
                    }}
                  >
                    Delete
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ConfModal handleClose={handleClose} open={open}>
        <DeletePrompt
          handleClose={handleClose}
          deleteFuntion={deleteVacations(id)}
          textToShow={"Deleting Vacation"}
        />
      </ConfModal>
      <OverlayLoading active={deleteVacationStatus === "loading" ? true : false} />
    </>
  );
}

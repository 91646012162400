import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import PushNotification from "./components/PushNotification";

const Settings = () => {

    const [appointmentStatus, setAppointmentStatus] = useState("false");

    return (
        <div style={{ minHeight: "40vh" }} className="mx-lg-1 my-4">
            <div style={{ minHeight: '70vh' }} className="container-fluid bg-white rounded-5 px-0">
                <div className="d-flex justify-content-start px-3 py-3">
                    <h5 className="mb-0 main-head">What Notifications You Receive</h5>
                </div>
                <div>
                    {appointmentStatus === "true" ? (
                        "Loading..."
                    ) : (
                        <PushNotification />
                    )}
                </div>
            </div>
        </div>
    );


};

export default Settings;

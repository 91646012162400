import React from "react";
import { Skeleton } from "@mui/material";

export const MediaSkeleton = () => {
  return (
    <>
      <div>
        <Skeleton
          className="rounded-5"
          variant="rectangular"
          width={"100%"}
          height={150}
        />
      </div>
    </>
  );
};

const MediaSkeletonLoading = () => {
  const Steps = [
    "1",
    "2",
    "3",
    "3",
  ];
  return (
    <>
      {Steps?.map((DATA) => (
        <MediaSkeleton key={DATA} />
      ))}
    </>
  );
};

export default MediaSkeletonLoading;

import Box from "@mui/material/Box";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store";
import {
  createPayment,
  deletePrincipal,
} from "../../../../reducers/paymentReducer";

const DeletePrincipalsModal = ({ handleClose, id, data }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleDelete = () => {
    sendUpdateRequest(data);
    dispatch(deletePrincipal(id));
  };

  const sendUpdateRequest = (data) => {
    const finalData = {
      ...data,
      principals: data?.principals?.filter((item) => item.id !== id),
    };
    dispatch(createPayment({ data: finalData }));
  };

  return (
    <>
      <Typography
        className="text-center"
        id="modal-modal-title"
        variant="h6"
        component="h2"
      >
        Are you sure to delete it?
      </Typography>
      <div className="d-flex justify-content-between">
        <div>
          <button
            onClick={() => {
              handleClose();
              handleDelete();
            }}
            type="button"
            className="btn btn-success mt-4 px-3"
            aria-label="submit data"
          >
            Yes
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              handleClose();
            }}
            type="button"
            className="btn btn-danger mt-4 px-3"
            aria-label="submit data"
          >
            No
          </button>
        </div>
      </div>
    </>
  );
};

export default DeletePrincipalsModal;

import React, { useState } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import "react-phone-number-input/style.css";
import * as yup from "yup";

const LoginForm = ({ setLoginData, loginData, handleSubmit, action }) => {
  const handleChange = (e: any) => {
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value,
    });
  };

  const [mobileError, setMobileError] = useState(true);
  const [toggle, setToggle] = useState(false);

  return (
    <div className="form">
      <Formik
        initialValues={{
          username: "",
          password: "",
        }}
        validationSchema={yup.object().shape({
          username: yup.string().required("Phone Number is required"),
          password: yup.string().required("Password is required"),
        })}
        onSubmit={(values) => {
          const finalData = {
            ...values,
            username: loginData?.username,
            password: loginData?.password,
          };
          if (mobileError) {
            handleSubmit(finalData);
          }
        }}
      >
        {({ errors, touched, values, handleBlur, handleChange }) => (
          <Form>
            <div className="mt-3 form-group">
              <label htmlFor="username" className="form-label">
                Phone number
              </label>
              <PhoneInputWithCountrySelect
                defaultCountry="US"
                name="username"
                onBlur={() => {
                  if (loginData.username) {
                    setMobileError(
                      isValidPhoneNumber(loginData.username.toString())
                    );
                  }
                }}
                onChange={(e) => {
                  handleChange({
                    target: {
                      value: e?.length > 0 ? Math.random() : "",
                      name: "username",
                    },
                  });
                  setLoginData({
                    ...loginData,
                    username: e,
                  });
                }}
                id="username"
                className={`form-control py-2 ${
                  (errors.username && touched.username) || !mobileError
                    ? "is-invalid"
                    : ""
                }`}
                international
                placeholder="Enter phone number"
              />
              <ErrorMessage
                name="username"
                component="span"
                className="invalid-feedback"
              />
              {!mobileError && (
                <p className="text-danger">Invalid phone number</p>
              )}
            </div>
            <div className="mt-3 form-group">
              <label htmlFor="password_confirmation" className="form-label">
                Password
              </label>
              <div className="input-group">
                <input
                  name="password"
                  onChange={(e) => {
                    handleChange(e);
                    setLoginData({
                      ...loginData,
                      password: e.target.value,
                    });
                  }}
                  type={toggle ? "text" : "password"}
                  className={`form-control py-2 ${
                    errors.password && touched.password ? "is-invalid" : ""
                  }`}
                  id="password_confirmation"
                  placeholder="Enter your password"
                />
                <span
                  onClick={() => setToggle(!toggle)}
                  className="input-group-text px-md-2 px-1 rounded-end pointer"
                  id="basic-addon1"
                >
                  {toggle ? (
                    <RemoveRedEyeIcon fontSize="small" />
                  ) : (
                    <VisibilityOffIcon fontSize="small" />
                  )}
                </span>
                <ErrorMessage
                  name="password"
                  component="span"
                  className="invalid-feedback"
                />
              </div>
            </div>
            <div className="row mt-2">
              {/* <div className="col-6">
            <div className="form-group form-check">
              <input type="checkbox" className="form-check-input" id="Check1" />
              <label className="form-check-label" htmlFor="Check1">
                Remember me
              </label>
            </div>
          </div>
          <div className="col-6 text-end">
            <Link to={""} style={{ color: "#04BAC7" }}>
              Forgot password?
            </Link>
          </div> */}
            </div>
            <button
              style={{ backgroundColor: "#04BAC7", fontSize: "18px" }}
              className="btn text-white rounded rounded-3 w-100 mt-4 py-2"
              type="submit"
            >
              {action?.loading ? (
                <div
                  className="d-flex justify-content-center align-items-center gap-1"
                  // style={{ padding: "5.5px 0" }}
                >
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></div>
                  <div>
                    <span
                      style={{
                        fontSize: "14px",
                      }}
                    >
                      Loading...
                    </span>
                  </div>
                </div>
              ) : (
                "Sign In"
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;

import React from "react";
import { Skeleton } from "@mui/material";

const ServiceSkeletonLoading = () => {
  return (
    <div
      style={{
        borderRadius: "6px",
      }}
      className={`d-flex p-3 mt-2 justify-content-between border
                }`}
    >
      <div className="d-flex">
        <Skeleton variant="circular" width={50} height={50} />
        <div className="">
          <p
            className={`mb-0 fw-bold mb-1 text-primary mx-2`}
            style={{ fontSize: "15px" }}
          >
            <Skeleton width={90} animation="pulse" />
          </p>
          <p
            style={{ color: "grey", fontSize: "13px" }}
            className="mb-0 mt-1 mx-2"
          >
            <Skeleton width={120} animation="pulse" />
          </p>
          <p
            style={{ color: "grey", fontSize: "15px" }}
            className="mb-0 mt-1 text-primary pointer mx-2"
          >
            <Skeleton width={30} animation="pulse" />
          </p>
        </div>
      </div>
      <div className="justify-content-end d-sm-block d-none">
        <div className="d-flex flex-column text-end">
          <p
            className="mb-0 fw-bold text-primary float-end ms-auto"
            style={{ fontSize: "15px" }}
          >
            <Skeleton width={25} animation="pulse" />
          </p>
          <div className="d-flex align-items-center mt-1">
            <span className="me-1 text-end">
              <Skeleton variant="circular" width={15} height={15} />
            </span>
            <span style={{ fontSize: "14px", color: "grey" }}>
              <Skeleton width={35} animation="pulse" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServiceCardSkeletonLoad = ({ services }) => {
  return (
    <>
      {services?.map((p) => (
        <ServiceSkeletonLoading key={p} />
      ))}
    </>
  );
};

export default ServiceCardSkeletonLoad;

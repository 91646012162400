import { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { recentMessagesProps } from "../types";
import ImageIcon from "@mui/icons-material/Image";
import MovieIcon from "@mui/icons-material/Movie";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import DescriptionIcon from "@mui/icons-material/Description";
import {
  activateRoom,
  getUsersMessagesAction,
  roomSelector,
  updateActiveMessage,
  updateGroupStatusAction,
  updateLastMessage,
} from "../../../reducers/chatReducer";
import { getSeconds, timeFormat } from "../../../utils";
import Avatar from "../../../components/common/Avatar";
import { loggedInUserProfile } from "../../../reducers/authReducer";
import echo from "../../../hooks/useWebSockets";
import { AppDispatch } from "../../../store";

const RecentMessages: FC<recentMessagesProps> = ({ data, setChatInbox }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { active }: any = useSelector(roomSelector);
  const roomData = useSelector(roomSelector);
  const authUser = useSelector(loggedInUserProfile);

  const MAX_LIMIT = 15;
  // const [isOnline, documentData] = useUser(data?.participant.id);
  // console.log(documentData);

  // useEffect(() => {
  //   console.log("it's working ", roomData?.active?.recipient);

  //   if (roomData.active.id) {
  //     const roomData = {
  //       id: data.id,
  //       recipient: documentData,
  //     };
  //     console.log("it's not working : ", documentData);

  //     dispatch(activateRoom(roomData));
  //   }
  // }, [isOnline]);

  const handleClick = (data) => {
    // const roomData = {
    //   id: data.id,
    //   recipient: data,
    // };
    // console.log("🚀 ~ file: RecentMessages.tsx:46 ~ handleClick ~ id:", roomData.id)
    // echo.private(`chat.${authUser.id}`).listen(".user-message", (data: any) => {
    //   console.log("message data : ", data);
    // });

    if (roomData?.active?.id !== data.id) {
      dispatch(updateGroupStatusAction({ group: data.id, isRead: 1 }));
      echo.private(data.id).stopListening(".private-chat-message");
      echo
        .private(`notification-${authUser?.id}`)
        .stopListening(".notification-chat");
      setChatInbox(true);
      echo.private(data.id).listen(".private-chat-message", (data: any) => {
        // console.log("recent data : ", data);
        if (data.message.from_user_id !== authUser?.id) {
          dispatch(updateActiveMessage({ message: data.message, authUser }));
        }
      });
      echo
        .private(`notification-${authUser?.id}-providers`)
        .listen(".notification-chat", (data: any) => {
          if (data.message.group !== roomData?.active?.id) {
            dispatch(
              updateLastMessage({
                message: data.message,
              })
            );
          }
        });

      const index = roomData?.list?.findIndex(
        (f) => f?.participant?.id == data?.participant?.id
      );
      if (index !== -1) {
        if (!roomData.list[index].newUser) {
          dispatch(getUsersMessagesAction({ data: data?.id, authUser }));
        } else {
          const roomData = {
            id: data?.id,
            recipient: {
              id: data?.id,
              avatarUrl: data?.participant?.avatarUrl,
              userId: data?.participant?.id,
              online: false,
              name: data?.participant?.name,
              modifiedAt: {
                date: new Date(),
                time: timeFormat(new Date()),
              },
              createdAt: {
                seconds: getSeconds(new Date()).seconds,
                nanoseconds: getSeconds(new Date()).nanoseconds,
              },
            },
            messages: [],
          };
          dispatch(activateRoom(roomData));
        }
      }
    }
  };

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setLastMsgTime(getTimeAgo(data?.lastMessage?.originalTime));
  //     // console.log("time changed ", getTimeAgo(data.lastMessage.originalTime));
  //   }, 60000); // update every minute
  //   return () => clearInterval(intervalId); // cleanup the interval when the component unmounts
  // }, []);

  return (
    <div
      onClick={() => handleClick(data)}
      style={{
        borderRadius: "10px",
        backgroundColor: active.id === data.id ? "#d9f5f7" : "#f3f4f6",
      }}
      className="d-flex hover-light py-2 mt-2 px-2 align-items-center"
    >
      <Avatar
        className="me-2"
        online={false}
        src={data?.participant?.avatarUrl}
        name={data?.participant?.name}
      />
      {/* <div className="">
        <img
          height={50}
          className="me-3 rounded-circle"
          src={data?.participant?.avatarUrl}
          alt=""
        />
      </div> */}
      <div style={{ height: "40px", overflow: "hidden" }} className="">
        <p className="mb-0" style={{ fontSize: "13px", fontWeight: 600 }}>
          {data?.participant?.name}
        </p>
        <p style={{ color: "grey", fontSize: "12px" }} className="mb-0">
          {String(data?.lastMessage?.messageType) === "image" ? (
            <ImageIcon sx={{ fontSize: "medium" }} />
          ) : String(data?.lastMessage?.messageType) === "video" ? (
            <MovieIcon sx={{ fontSize: "medium" }} />
          ) : String(data?.lastMessage?.messageType) === "audio" ? (
            <HeadphonesIcon sx={{ fontSize: "medium" }} />
          ) : String(data?.lastMessage?.messageType) === "document" ? (
            <DescriptionIcon sx={{ fontSize: "medium" }} />
          ) : data?.lastMessage?.messageData?.length > MAX_LIMIT ? (
            `${data?.lastMessage?.messageData?.substring(0, MAX_LIMIT)}...`
          ) : (
            data?.lastMessage?.messageData
          )}
        </p>
      </div>
      <div className="mb-auto ms-auto">
        <span className="mb-0" style={{ fontSize: "10px", fontWeight: 600 }}>
          {data?.lastMessage?.createdAt}
        </span>
        {data.isRead === 0 ? (
          <div className="mb-0 text-end">
            <div
              style={{
                height: "12px",
                width: "12px",
                backgroundColor: "#04bac7",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></div>
          </div>
        ) : null}
        <p
          style={{
            fontSize: "10px",
            // backgroundColor: "#04bac7",
            width: "15px",
          }}
          className="mb-0 rounded-circle text-white text-center ms-auto"
        >
          {/* {received} */}
          {/* length of  unread messages  */}
        </p>
      </div>
    </div>
  );
};

export default RecentMessages;

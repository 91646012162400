import React from "react";
import { useSelector } from "react-redux";
import { paymentCards } from "../../../reducers/paymentReducer";
import { PAYMENT_URL } from "../../../config/config";
import { getAuth } from "../../../utils/AuthHelpers";
import { BootstrapTooltip } from "../../../components/common/BootstrapTooltip";
import AddCardIcon from "@mui/icons-material/AddCard";
import CreditCard from "../../../components/common/CreditCard/CreditCard";
import { Card } from "../types";
import NoPaymentCards from "./NoPaymentCards";

const PaymentCard = ({ id }) => {
  const [cardId, setCardId] = React.useState(null);
  const cards = useSelector(paymentCards);
  const auth = getAuth();

  return (
    <>
      <div className="row">
        <div className="d-flex justify-content-between">
          <label
            // style={{ color: "#3A4758" }}
            className="col-form-label fs-6"
          >
            Cards
          </label>
          {cards.length !== 0 ? (
            <BootstrapTooltip title="Payment Method" placement="top">
              <button
                id="payment-btn"
                style={{ whiteSpace: "nowrap", fontSize: "14px" }}
                className="btn btn-primary btn-outline-secondary border-b5 border hover-primary2 d-block"
                onClick={() =>
                  window.open(
                    `${PAYMENT_URL}${id}/${auth?.access_token}`,
                    "Popup",
                    "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=700,top=200,width=500,height=730,visible=none"
                  )
                }
              >
                <div className="d-flex gap-1 text-light justify-content-center align-items-center">
                  <AddCardIcon style={{ color: "white" }} />
                  Add
                </div>
              </button>
            </BootstrapTooltip>
          ) : null}
        </div>
      </div>
      <div className="row row-cols-2 mt-3 justify-content-center">
        {cards.length === 0 ? (
          <NoPaymentCards
            PAYMENT_URL={PAYMENT_URL}
            id={id}
            accessToken={auth?.access_token}
          />
        ) : (
          cards.map((card: Card) => (
            <CreditCard
              key={card?.id}
              id={card?.id}
              name="Arif Rustam"
              issuer={card.cardBrand}
              number={`**** **** **** ${card.lastFourDigits}`}
              expiry={`${card.expirationMonth}/${card.expirationYear}`}
              cardId={cardId}
              setCardId={setCardId}
            />
          ))
        )}
      </div>
    </>
  );
};

export default PaymentCard;

{
  /* <div
  onClick={() => setToggle(card?.id)}
  style={{
    borderRadius: "12px",
  }}
  className={`d-flex p-2 mt-3 justify-content-between border ${toggle === card?.id ? "border-primary border-2" : null
    }`}
>
  <div className="d-flex align-items-center justify-content-start">
    <div className="border rounded px-2 py-1 me-3">
      <img
        height={12}
        width={28}
        className=""
        src="/images/visa.png"
        alt="no icon"
      />
    </div>
    <div className="">
      <p style={{ fontSize: "14px" }} className="mb-0">
        **********{card.lastFourDigits}
      </p>
      <p
        className={`mb-0 ${toggle === card?.id ? "text-primary" : null
          }`}
        style={{ color: "grey", fontSize: "14px" }}
      >
        Expiry {card.expirationMonth}/{card.expirationYear}
      </p>
    </div>
  </div>
  <div className="justify-content-end">
    <div className="d-flex flex-column text-end">
      <div className="form-check-inline m-0">
        <input
          style={{ accentColor: "red" }}
          className="form-check-input rounded-circle"
          type="radio"
          id="inlineCheckbox1"
          checked={toggle === card?.id ? true : false}
        />
      </div>
    </div>
  </div>
</div> */
}

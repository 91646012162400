import * as React from "react";
import { Drawer, Box, Typography, Button } from "@mui/material";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { isDrawerOpen } from "../../reducers/applicationReducer";

export const TemporaryDrawer = ({ handleClose, open, children }) => {
  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(isDrawerOpen(open));
  }, [open]);

  return (
    <>
      <Drawer anchor="right" open={open} onClose={handleClose}>
        <Box width="400px" height="100%" role="presentation">
          {children}
        </Box>
      </Drawer>
    </>
  );
};

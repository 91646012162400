import { useEffect, useMemo, useState, useCallback } from "react";

import Typography from "@mui/material/Typography";
import { xconsole } from "../../../../utils/console";
import {
  createCredential,
  createStatusSelector,
  updateCredential,
} from "../../../../reducers/credentialsReducer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import { PREFIX } from "../../../../config/constant";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import { useDropzone } from "react-dropzone";
import OverlayLoading from "../../../../components/common/OverlayLoading";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";

export const AddCredentialModal = ({
  handleClose,
  data,
  isEdit,
  index,
}: {
  handleClose(): any;
  data?: any;
  isEdit?: boolean;
  index: number;
}) => {
  const [newCredentials, setNewCredentials] = useState<any>({
    title: "",
    image: { blob: "", src: null },
    type: "",
    issueDate: "",
    expiryDate: "",
    isNotExpiry: 0,
    link: "",
  });

  const [error, setError] = useState(false);
  const [type, setType] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const status = useSelector(createStatusSelector);

  useMemo(() => {
    setNewCredentials({
      ...data,
      image: { blob: "", src: data?.imageUrl ? data?.imageUrl : null },
    });
    // console.log("data", newCredentials);
  }, [data]);

  const handleCredentialChange = (e: any) => {
    setNewCredentials({
      ...newCredentials,
      [e.target.name]:
        e.target.name === "isNotExpiry" ? e.target.checked : e.target.value,
    });
  };

  // get date in yyyy-mm-dd format
  const getDate = (date: any) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const dt = d.getDate();

    return `${year}-${month < 10 ? `0${month}` : `${month}`}-${
      dt < 10 ? `0${dt}` : `${dt}`
    }`;
  };
  const today = new Date();
  // console.log("getDate", getDate(today));

  const validationSchema = yup.object().shape({
    // type: yup.string().required("Credential Type is required"),
    title: yup.string().required("Title is required"),
    issueDate: yup
      .date()
      .max(getDate(today), "Issue Date must be less than today's date")
      .required("Issue Date is required"),
    expiryDate: yup
      .date()
      .nullable()
      .when("isNotExpiry", {
        is: 0,
        then: yup
          .date()
          .when("issueDate", (startDate, schema) => {
            if (startDate) {
              const dayAfter = new Date(startDate.getTime() + 86400000);

              return schema.min(
                dayAfter,
                "Expiry date has to be greater than Issue date"
              );
            }

            return schema;
          })
          .required("Expiry Date is required"),
      }),
  });

  const onDrop = useCallback((acceptedFiles) => {
    setError(false);
    setType(false);
    onImageChange(acceptedFiles[0]);
  }, []);

  const rejectedFiles = useCallback((rejectedFiles) => {
    // console.log("rejectedFiles", rejectedFiles);
    setType(true);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    onDropRejected: rejectedFiles,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpeg", ".jpg"],
    },
  });

  const baseStyle = {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "70px",
    margin: "0px 5px 0px 0px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: error || type ? "#ff1744" : "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };

  const focusedStyle = {
    borderColor: "#2196f3",
    padding: "70px",
  };

  const acceptStyle = {
    borderColor: "#00e676",
    padding: "94px",
  };

  const rejectStyle = {
    padding: "94px",
    borderColor: "#ff1744",
  };

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, type, error]
  );

  const onImageChange = (event) => {
    if (event) {
      let img = event;
      setNewCredentials({
        ...newCredentials,
        image: {
          blob: img,
          src: URL.createObjectURL(img),
        },
      });
    }
    setError(false);
  };

  // console.log("key", k);

  const handleSubmit = (e: any) => {
    // e.preventDefault();
    // xconsole.test("pass");
    // console.log(newCredentials);
    let formData = new FormData();

    formData.append("title", e.title);
    formData.append(
      "image",
      isEdit ? newCredentials?.image?.src : newCredentials?.image?.blob
    );
    formData.append("type", e.type);
    formData.append("issueDate", e.issueDate);
    formData.append("expiryDate", e.isNotExpiry == 1 ? "" : e.expiryDate);
    formData.append("isNotExpiry", e.isNotExpiry == 1 ? "1" : "0");
    formData.append("link", `${PREFIX.WEB}${e.link}`);

    if (newCredentials?.image?.src === null) {
      setError(true);
    } else if (isEdit) {
      // xconsole.test("test pass");
      // xconsole.test("update");
      dispatch(
        updateCredential({
          id: data.id,
          formData: { ...newCredentials, image: newCredentials?.image?.src },
          index,
          handleClose,
        })
      );
    } else {
      dispatch(createCredential({ data: formData, handleClose }));
    }
    // setNewCredentials({});
  };

  useMemo(() => {
    if (newCredentials.isNotExpiry == 1) {
      setNewCredentials({ ...newCredentials, expiryDate: "" });
    }
  }, [newCredentials.isNotExpiry]);

  return (
    <div>
      <Typography
        className="text-dark"
        id="modal-modal-title"
        sx={{
          fontSize: "18px",
          fontWeight: "550",
        }}
        component="h6"
      >
        Add Credentials{" "}
        <BootstrapTooltip
          title={
            "Professional certificates, Licenses, Insurance, Certificates, Accreditation tell us about your Accomplishments"
          }
          placement="top"
        >
          <img
            className="pointer"
            height={13}
            src="/images/schedule/infosvg.svg"
            alt=""
          />
        </BootstrapTooltip>
      </Typography>
      <div className="form">
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleSubmit(values);
            // console.log("values", values);
          }}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <div className="row mt-3">
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label style={{ fontSize: "14px" }} className="form-label">
                      Credential Type
                    </label>
                    <Field
                      as="select"
                      name="type"
                      value={values.type}
                      onChange={(e) => {
                        handleCredentialChange(e);
                        handleChange(e);
                      }}
                      style={{ fontSize: "15px" }}
                      className={`form-select ${
                        errors.type && touched.type ? "is-invalid" : ""
                      }`}
                      aria-label="Default select example"
                    >
                      <option value="">Select</option>
                      <option value="School">School</option>
                      <option value="College">College</option>
                      <option value="University">University</option>
                    </Field>
                    <ErrorMessage
                      name="type"
                      component="span"
                      className="invalid-feedback"
                    />
                  </div>
                </div> */}
                <div className="col">
                  <div className="form-group">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="name"
                      className="form-label"
                    >
                      Title
                    </label>
                    <div className="input-group">
                      <input
                        value={values.title}
                        onChange={(e) => {
                          handleChange(e);
                          handleCredentialChange(e);
                        }}
                        name="title"
                        type="text"
                        className={`form-control ${
                          errors.title && touched.title ? "is-invalid" : ""
                        }`}
                        id="name"
                        placeholder="Add title"
                      />
                      <ErrorMessage
                        name="title"
                        component="span"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="issueDate"
                      className="form-label"
                    >
                      Issue Date
                    </label>
                    <div className="input-group">
                      <input
                        value={values.issueDate}
                        onChange={(e) => {
                          handleChange(e);
                          handleCredentialChange(e);
                        }}
                        name="issueDate"
                        max={getDate(today)}
                        type="date"
                        className={`form-control ${
                          errors.issueDate && touched.issueDate
                            ? "is-invalid"
                            : ""
                        }`}
                        id="issueDate"
                        placeholder="Recent Photo took in London"
                      />
                      <ErrorMessage
                        name="issueDate"
                        component="span"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="issueDate"
                      className="form-label"
                    >
                      Expiry Date
                    </label>
                    <div className="input-group">
                      <input
                        disabled={values.isNotExpiry == 0 ? false : true}
                        value={values.isNotExpiry == 0 ? values.expiryDate : ""}
                        onChange={(e) => {
                          handleChange(e);
                          handleCredentialChange(e);
                        }}
                        name="expiryDate"
                        type="date"
                        className={`form-control ${
                          errors.expiryDate && touched.expiryDate
                            ? "is-invalid"
                            : ""
                        }`}
                        id="issueDate"
                        placeholder="Recent Photo took in London"
                      />
                      <ErrorMessage
                        name="expiryDate"
                        component="span"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="row">
                <div className="col-md-6 ms-auto">
                  <div className="form-check d-flex align-items-center">
                    <input
                      style={{ fontSize: "13px" }}
                      name="isNotExpiry"
                      className="form-check-input"
                      type="checkbox"
                      checked={values.isNotExpiry == 0 ? false : true}
                      onChange={(e) => {
                        handleCredentialChange(e);
                        handleChange({
                          target: {
                            name: "isNotExpiry",
                            value: e.target.checked ? 1 : 0,
                          },
                        });
                      }}
                      id="isNotExpiry"
                    />
                    <label
                      style={{ fontSize: "12px" }}
                      className="form-check-label mt-1 ms-1"
                      htmlFor="isNotExpiry"
                    >
                      This Credentials does not expire
                    </label>
                  </div>
                </div>
              </div> */}
              {/* <div className="form-group mt-3">
                <label
                  style={{ fontSize: "14px" }}
                  htmlFor="duration"
                  className="form-label"
                >
                  Credentials Certificate URL
                </label>
                <div style={{ color: "#667085" }} className="input-group">
                  <span
                    style={{ color: "#667085" }}
                    className="input-group-text px-md-2 px-1"
                    id="basic-addon1"
                  >
                    {PREFIX.WEB}
                  </span>
                  <input
                    value={values?.link?.split("https://")[1]}
                    onChange={(e) => {
                      handleChange(e);
                      handleCredentialChange(e);
                    }}
                    name="link"
                    type="text"
                    className={`form-control ${
                      errors.link && touched.link ? "is-invalid" : ""
                    }
                    `}
                    id="link"
                    placeholder="Enter the Related link"
                  />
                  <ErrorMessage
                    name="link"
                    component="span"
                    className="invalid-feedback"
                  />
                </div>
              </div> */}

              {newCredentials?.image?.src ? (
                <div className="w-100 text-center border rounded py-4 mt-3 ">
                  <div className="d-flex justify-content-end">
                    <button
                      className="bg-danger mx-3 my-1 px-2 rounded"
                      onClick={() =>
                        setNewCredentials({
                          ...newCredentials,
                          image: { blob: "", src: null },
                        })
                      }
                    >
                      X
                    </button>
                  </div>
                  <img
                    style={{ objectFit: "contain" }}
                    src={newCredentials?.image?.src}
                    alt="not available "
                    height="170px"
                    width="250px"
                  />
                </div>
              ) : (
                <>
                  <div className={`text-center mt-2 w-100 pointer`}>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <div className="d-flex flex-column align-items-center pointer">
                          <p className="mb-0">Drop your media here</p>
                        </div>
                      ) : (
                        <div className="d-flex flex-column align-items-center pointer">
                          <p className="mb-0">Drag and drop your media here</p>
                          <p className="mb-0">or</p>
                          <p className="mb-0">Browse</p>
                        </div>
                      )}
                    </div>
                  </div>
                  {error ? (
                    <div className="text-danger">Image is required</div>
                  ) : null}
                  {type ? (
                    <div className="text-danger">Only image is allowed</div>
                  ) : null}
                </>
              )}

              <div className="row">
                <div className="col-6">
                  <button
                    type="reset"
                    onClick={handleClose}
                    className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  <button
                    // onClick={handleClose}
                    style={{ backgroundColor: "#04BAC7" }}
                    className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                    type="submit"
                  >
                    {isEdit ? "Update" : "Confirm"}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <OverlayLoading active={status === "loading" ? true : false} />
    </div>
  );
};

import React from 'react'
import { Skeleton } from "@mui/material";

const StepInfoLoading = () => {
    return (
        <div className="d-flex align-items-center">
            <div
                className="d-flex align-items-center me-2"
            >
                <Skeleton className='me-3' variant="circular" height={40} width={40} />
                <Skeleton width={70} animation="pulse" />
            </div>
            <div
                className="d-flex align-items-center me-2"
            >
                <Skeleton className='me-3' variant="circular" height={40} width={40} />
                <Skeleton width={70} animation="pulse" />
            </div>
            <div
                className="d-flex align-items-center me-2"
            >
                <Skeleton className='me-3' variant="circular" height={40} width={40} />
                <Skeleton width={70} animation="pulse" />
            </div>
            <div
                className="d-flex align-items-center me-2"
            >
                <Skeleton className='me-3' variant="circular" height={40} width={40} />
                <Skeleton width={70} animation="pulse" />
            </div>
            <div
                className="d-flex align-items-center me-2"
            >
                <Skeleton className='me-3' variant="circular" height={40} width={40} />
                <Skeleton width={70} animation="pulse" />
            </div>
        </div>
    )
}

export default StepInfoLoading
import React from "react";
import Empty from "../Empty";
import { useSelector } from "react-redux";
import Bar from "../Bar";
import { messageNotificationSelector } from "../../../../../reducers/notificationReducer";

const Messages = () => {
  const data = useSelector(messageNotificationSelector);

  const isQueEmpty = data.length == 0;
  if (isQueEmpty) {
    return <Empty />;
  } else {
    return (
      <>
        {/* <span>{data.length}</span> */}
        {data.map((data, idx) => (
          <Bar key={idx} data={data} />
        ))}
      </>
    );
  }
};

export default Messages;

import React, { useState } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import {
  TabPanel,
  a11yProps,
  Messages,
  Appointments,
  AllNotification,
} from "./components/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import { TemporaryDrawer } from "../../common/TemporaryDrawer";
import { AppDispatch } from "../../../store";
import {
  allNotificationSelector,
  appointmentNotificationSelector,
  connectionNotificationSelector,
  markAllNotiAsRead,
  markAsReadAction,
  messageNotificationSelector,
} from "../../../reducers/notificationReducer";
import { ID } from "../../common/Chips";
import { loggedInUserProfile } from "../../../reducers/authReducer";
import Connections from "./components/Tabs/Connections";

/**
 * TODO :TYPES
 * assigning any for now it needs to changes into proper types
 * props types
 */

const NotificationDrawer = ({ toggle, handleToggler }: any) => {
  const allNotifications = useSelector(allNotificationSelector);
  const appointmentNotifications = useSelector(appointmentNotificationSelector);
  const messageNotification = useSelector(messageNotificationSelector);
  const connectionNotification = useSelector(connectionNotificationSelector);
  const [openedTab, setOpenedTab] = useState<number>(0);
  const dispatch = useDispatch<AppDispatch>();

  const authUser = useSelector(loggedInUserProfile);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setOpenedTab(newValue);
  };

  const markAllAsRead = () => {
    dispatch(markAllNotiAsRead());
    const data = {
      modelId: authUser?.id,
      modelType: "provider",
      isRead: 1,
    };
    dispatch(markAsReadAction(data));
  };

  return (
    <TemporaryDrawer handleClose={handleToggler} open={toggle}>
      <div className="pt-2">
        <IconButton className="float-end" onClick={handleToggler}>
          <CloseIcon className="pointer" />
        </IconButton>
        <div className="d-flex justify-content-between align-items-center pe-4">
          <h4 className="mb-0 ms-3 mt-2 fw-bold">Notifications</h4>
          <u
            style={{ fontSize: "12px" }}
            onClick={markAllAsRead}
            className="pointer pt-2 text-primary"
          >
            Mark all as read
          </u>
        </div>
        {/* <hr /> */}

        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              width: "100%",
              overflow: "auto",
            }}
          >
            <Tabs
              textColor="secondary"
              indicatorColor="secondary"
              value={openedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons={false}
              aria-label="basic tabs example"
            >
              <Tab
                iconPosition="start"
                label={
                  <>
                    <span className="">All</span>
                    {allNotifications?.length > 0 && (
                      <ID text={allNotifications?.length} />
                    )}
                  </>
                }
                {...a11yProps(0)}
              />
              <Tab
                iconPosition="start"
                label={
                  <>
                    <span className="">Message</span>
                    {messageNotification?.length > 0 && (
                      <ID text={messageNotification?.length} />
                    )}
                  </>
                }
                {...a11yProps(1)}
              />
              <Tab
                iconPosition="start"
                label={
                  <>
                    <span className="">Appointments</span>
                    {appointmentNotifications?.length > 0 && (
                      <ID text={appointmentNotifications?.length} />
                    )}
                  </>
                }
                {...a11yProps(2)}
              />
              <Tab
                iconPosition="start"
                label={
                  <>
                    <span className="">Connections</span>
                    {connectionNotification?.length > 0 && (
                      <ID text={connectionNotification?.length} />
                    )}
                  </>
                }
                {...a11yProps(3)}
              />
            </Tabs>
          </Box>

          <TabPanel value={openedTab} index={0}>
            <AllNotification />
          </TabPanel>
          <TabPanel value={openedTab} index={1}>
            <Messages />
          </TabPanel>
          <TabPanel value={openedTab} index={2}>
            <Appointments />
          </TabPanel>
          <TabPanel value={openedTab} index={3}>
            <Connections />
          </TabPanel>
        </Box>
      </div>
    </TemporaryDrawer>
  );
};

export default NotificationDrawer;

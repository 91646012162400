import { AxiosRequestConfig } from "axios";
import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";
import { urlParameter } from "../../../components/common/types";

const fetchFullScheduleRequest = async () => {
  try {
    return await client.get(provider_URLS.schedule.PROVIDER_SCHEDULE);
  } catch (error) {
    return error;
  }
};

const updateScheduleRequest = (data: AxiosRequestConfig<any>) =>
  client.put(provider_URLS.schedule.PROVIDER_SCHEDULE, data);
// provider_URLS.schedule.PROVIDER_SCHEDULE_UPDATE(data?.id as any),
// } catch (error) {
//   console.info("error from helper function :", error);

//   return error;
// }
// };

export const createScheduleRequest = async (data: AxiosRequestConfig<any>) =>
  client.post(provider_URLS.schedule.PROVIDER_SCHEDULE, data);

export const fetchFullScheduleVacationRequest = async () => {
  try {
    return await client.get(provider_URLS.schedule.PROVIDER_VACATIONS);
  } catch (error) {
    return error;
  }
};

export const updateScheduleVacationRequest = async (
  data: AxiosRequestConfig<any>
) => client.post(provider_URLS.schedule.PROVIDER_VACATIONS, data);

export const deleteScheduleVacationRequest = async (id: number) =>
  client.delete(provider_URLS.schedule.DELETE_PROVIDER_VACATIONS(id));

export const deleteScheduleRequest = async (id: urlParameter, data: any) =>
  client.delete(provider_URLS.schedule.PROVIDER_SCHEDULE_UPDATE(id), data);

export const activeStatusScheduleRequest = async (type: string) => {
  client.put(provider_URLS.schedule.SCHEDULE_ACTIVE_STATUS, {
    type,
  });
};

export const fetchBookingByDateRequest = (date) => {
  const datePattern = /^\d{4}-\d{2}-\d{2}$/;
  if (!datePattern.test(date)) {
    throw new Error(
      "Invalid date format. Date should be in the format of 2023-02-09."
    );
  }
  return client.get(provider_URLS.schedule.BY_DATE, {
    params: { date },
  });
};

export const scheduleStatusUpdateRequest = async (data: string) =>
  client.put(provider_URLS.schedule.SCHEDULE_STATUS_UPDATE, data);

export const servicePlayPauseRequest = async (data: string) =>
  client.post(provider_URLS.schedule.SERVICE_PLAY_PAUSE, data);

export { fetchFullScheduleRequest, updateScheduleRequest };

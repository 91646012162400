import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  scheduleOnToOneReadOnlySelector,
  scheduleOnToOneSelector,
} from "../../../../../reducers/scheduleReducer";
import { fetchOwnServices } from "../../../../../reducers/serviceReducer";
import { AppDispatch } from "../../../../../store";
import ScheduleDays from "./ScheduleDays";
import AllowDoubAccordion from "./AllowDoubAccordian";
import AllowLastAccordion from "./AllowLastAccordian";
import DailyBrAccordion from "./DailyBrAccordian";
import DailySchAccordion from "./DailySchAccordian";
import PeakPriceAccordian from "./PeakPriceAccordian";
import RepeatWeekAccordian from "./RepeatWeekAccordian";
import ReservationAccordian from "./ReservationAccordian";
import ServiceAccordion from "./ServiceAccordian";
import ActiveServices from "./ActiveServices";

const OneToOneSchedule = ({
  change,
  setChange,
  // errors,
  // values,
  // handleChange,
  // handleBlur,
  // touched,
}) => {
  const oneToOneData = useSelector(scheduleOnToOneSelector);
  const oneToOneReadOnly = useSelector(scheduleOnToOneReadOnlySelector);

  const dispatch = useDispatch<AppDispatch>();

  // console.log("time =>", time);
  // console.log("meridiam =>", meridiem);

  useEffect(() => {
    dispatch(fetchOwnServices());
    return () => {};
  }, []);

  return (
    <>
      <ActiveServices data={oneToOneReadOnly} />
      <h6 style={{ fontSize: "16px" }} className="mb-4 fw-bold">
        Select Days to Apply
      </h6>
      {/* <p style={{ fontSize: "12px" }} className="text-secondary">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed distinctio
        eum rerum ullam.
      </p> */}
      <ScheduleDays
        change={change}
        setChange={setChange}
        days={oneToOneData?.days}
        SelectedDays={oneToOneData?.newDAY}
      />
      <DailySchAccordion
        startTime={oneToOneData?.startTime?.split(" ")[0]}
        startMeridiem={oneToOneData?.startTime?.split(" ")[1]}
        endTime={oneToOneData?.endTime?.split(" ")[0]}
        endMeridiem={oneToOneData?.endTime?.split(" ")[1]}
      />
      <ServiceAccordion
        selectedService={oneToOneData?.services}
        change={change}
        setChange={setChange}
      />
      <div className="row w-100 align-items-center px-1 my-4">
        <div className="col-12">
          <div className="d-flex align-items-center">
            <img
              className="me-3 pointer"
              height={52}
              src="/images/schedule/dailyRules.svg"
              alt=""
            />
            <div>
              <p className="fw-light mb-1 fw-bold">Daily Rules</p>
              <p
                style={{ fontSize: "12px" }}
                className="fw-light my-0 text-secondary"
              >
                All options below apply to all services that occurs on the days
                selected above.
              </p>
            </div>
          </div>
        </div>
      </div>
      <DailyBrAccordion
        breaks={oneToOneData?.isBreak}
        breakTimes={oneToOneData?.breakTimes}
        change={change}
        setChange={setChange}
      />
      <RepeatWeekAccordian weekly={oneToOneData?.isWeekly} />
      <ReservationAccordian
        fee={oneToOneData?.reservationFee}
        isFee={oneToOneData?.isReservationFee}
        change={change}
        setChange={setChange}
      />
      <AllowLastAccordion
        before={oneToOneData?.allowBookingBefore}
        buffer={oneToOneData?.afterAppointmentBuffer}
        booking={oneToOneData?.isLastMinutesBooking}
        change={change}
        setChange={setChange}
      />
      <AllowDoubAccordion
        selectedService={oneToOneData?.services}
        totalServices={oneToOneData?.services?.length}
        overTime={oneToOneData?.overlapingTime}
        services={oneToOneData?.allServices}
        double={oneToOneData?.isAllowForDoubleBooking}
        change={change}
        setChange={setChange}
      />
      <PeakPriceAccordian
        monthly={oneToOneData?.monthlySchedulePercentage}
        increase={oneToOneData?.increaseRateBy}
        vic={oneToOneData?.isVicExempt}
        pricing={oneToOneData?.isPeakPricing}
        change={change}
        setChange={setChange}
      />
    </>
  );
};

export default OneToOneSchedule;

import React from 'react'
import { Link, useNavigate } from 'react-router-dom'

const CompletedPayment = ({ setChangeStep }: { setChangeStep(string): void }): JSX.Element => {
    const navigate = useNavigate()
    const mtFive = {
        marginTop: '2.2rem'
    }
    return (
        <div style={{ maxWidth: '700px' }} className="my-4 m-auto">
            <div style={{ minHeight: '70vh' }} className="container-fluid bg-white rounded-5 p-5">
                <div className="d-flex flex-column justify-content-center align-items-center">
                    <img
                        // height={100}
                        className=''
                        src="/images/payment/CompletedPay.svg"
                        alt="no icon"
                    />
                    <h4 style={{ ...mtFive }} className="mb-0 mb-1">Successfully Saved</h4>
                    {/* <p className={`text-secondary`} style={{ fontSize: "16px" }}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                    </p> */}
                    <button onClick={() => setChangeStep("start")} style={{ fontSize: '13px' }} className='btn btn-primary mt-4'>
                        Back to Payments
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CompletedPayment
import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";
import { urlParameter } from "../../../components/common/types";

export const createPayments = async (data: any) =>
  client.post(provider_URLS.payments.CREATE_STORE, data);

export const getTypes = async () => {
  try {
    return await client.get(provider_URLS.payments.GET_TYPES);
  } catch (error) {
    return error;
  }
};

export const getRecord = async () => {
  try {
    return await client.get(provider_URLS.payments.GET_RECORD);
  } catch (error) {
    return error;
  }
};

export const getUserApplicationId = async (id: any, userType: any) => {
  try {
    return await client.get(
      provider_URLS.payments.GET_USER_APP_ID(id, userType)
    );
  } catch (error) {
    return error;
  }
};

export const providerBoardingRequest = async (data: any) => {
  try {
    return await client.post(provider_URLS.payments.PROVIDER_BOARDING, data);
  } catch (error) {
    return error;
  }
};

export const getProviderBoardingRequest = async () => {
  try {
    return await client.get(provider_URLS.payments.GET_PROVIDER_BOARDING);
  } catch (error) {
    return error;
  }
};

export const getUserCardsRequest = async (
  userType: string,
  id: urlParameter
) => {
  try {
    return await client.get(provider_URLS.payments.GET_USER_CARDS, {
      params: {
        userType,
        id,
      },
    });
  } catch (error) {
    return error;
  }
};

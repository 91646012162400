import STATES from "./data/states.json"
import COUNTRIES from "./data/countries.json"
import MCC_CODES from "./data/MCC.json"
// ######################################################################### common #########################################################################

export const PROVIDER = "provider";

export const APPOINTMENTSTATUS = "appointmentStatus";

export const CLIENTFILTER = "clientFilters";

export const EXPLOREFILTERS = "exploreFilterData";

export const AUTH_TOKEN = "KAZZAH_PRO_AUTH_TOKEN";

export const TOKEN = "token";

export const CODE = "code";

export const MOBILE_NUMBER = "mobile";

export const NUMBER = "number";

export const WIDGETS = "widgets";

export const ASPECT_RATIO = 9 / 16;


// ***************************************************************** SERVICES *****************************************************************
export const ADD_SERVICES = "add services";
export const DELETE_SERVICES = "delete services";
export const EDIT_SERVICES = "edit services";

export const MINUTE = "min";
export const HOUR = "hr";

export const CURRENCY = "$";
export const PERCENT = "%";

// ***************************************************************** REDUCER/SLICE *****************************************************************
export const SUCCESS = "success";
export const LOADING = "loading";
export const FAIL = "fail";
export const IDLE = "idle";

export const STATUSES = [
  {
    key: "pending,declineByProvider,approveByProvider,completed,rescheduleByProvider",
    value: "All",
  },
  { key: "pending", value: "Pending" },
  { key: "declineByProvider", value: "Decline" },
  {
    key: "approveByProvider,completed,rescheduleByProvider&isPaid=2,0",
    value: "Active",
  },
  { key: "completed&isPaid=1", value: "Past" },
];

type colorParameter = "PR" | "PD" | "RS" | "AP" | "CA" | "CO" | "DULL";
type nameParameter =
  | "approve"
  | "reschedule"
  | "cancel"
  | "checkout"
  | "paymentRequest"
  | "paid";
export interface AppointmentButtons {
  id: number;
  color: colorParameter;
  name: nameParameter;
  toolTip: nameParameter;
}

export const AppointmentButtons = [
  {
    id: Math.random() * 12,
    color: "RS",
    name: "reschedule",
    toolTip: "reschedule",
  },
  {
    id: Math.random() * 4,
    color: "DULL",
    name: "pay",
    toolTip: "pay",
  },
  {
    id: Math.random() * 9,
    color: "RB",
    name: "rebook",
    toolTip: "rebook",
  },
  {
    id: Math.random() * 14,
    color: "CA",
    name: "cancel",
    toolTip: "cancel",
  },
] as Array<AppointmentButtons>;

export const btn_COLORS = {
  rebook: { color: "#6941C6", backgroundColor: "#F9F5FF" },
  approve: { color: "#00ff00", backgroundColor: "#FEF3F2" },
  reschedule: { color: "#B54708", backgroundColor: "#FFFAEB" },
  cancel: { color: "#B42318", backgroundColor: "#FEF3F2" },
  paid: { color: "#042AC7", backgroundColor: "#8e99c7" },
  checkout: { color: "#f07333", backgroundColor: "#fef3b6" },
  paymentRequest: { color: "#009751", backgroundColor: "#95c4ae" },
} as const;

// ***************************************************************** MEDIA *****************************************************************

export const IMAGE = "image";
export const VIDEO = "video/mp4";
export const ERROR_MESS = "Something went wrong";

export const PREFIX = {
  WEB: "https://",
};

// ***************************************************************** time config(not default) *****************************************************************

export const REGION = undefined; // it will get region of current location that's why it set to undefined , it can be hard code
// export const REGION = "en-US";

export const TIME_OPTIONS = {
  hour: "numeric",
  minute: "2-digit",
  hour12: true,

  // second: "2-digit",
  // timeZone: 'Australia/Sydney',
  // timeZoneName: "short",
};

// ***************************************************************** STATES *****************************************************************
export const states = STATES

// ***************************************************************** COUNTRIES *****************************************************************
export const countries = COUNTRIES;

// ***************************************************************** MCC CODES *****************************************************************
export const mccCodes = MCC_CODES;

export enum scheduleType {
  SINGLE_TO_SINGLE = "single-to-single",
  MULTI_TO_MULTI = "multi-to-multi",
}


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  allMultiToMultiSelector,
  deleteSchStatus,
  scheduleMultiToMultiSelector,
} from "../../../../../reducers/scheduleReducer";
import ScheduleDays from "./ScheduleDays";
import AllowLastAccordion from "./AllowLastAccordian";
import DailyBrAccordion from "./DailyBrAccordian";
import PeakPriceAccordian from "./PeakPriceAccordian";
import RepeatWeekAccordian from "./RepeatWeekAccordian";
import ReservationAccordian from "./ReservationAccordian";
import ServiceAccordion from "./ServiceAccordian";
import CompletedSchedule from "./CompletedSchedule";
import OverlayLoading from "../../../../../components/common/OverlayLoading";

const MultiSchedule = () => {
  const multiToMultiData = useSelector(scheduleMultiToMultiSelector);
  const allMultiToMultiData = useSelector(allMultiToMultiSelector);
  const status = useSelector(deleteSchStatus);

  return (
    <>
      {allMultiToMultiData.map((OMTMTD) => (
        <CompletedSchedule data={OMTMTD} />
      ))}
      <>
        <h6 style={{ fontSize: "16px" }} className="mb-4 mt-4 fw-bold">
          Select Days to Apply
        </h6>
        {/* <p style={{ fontSize: "12px" }} className="text-secondary">
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Sed distinctio
        eum rerum ullam.
      </p> */}
        <ScheduleDays
          days={multiToMultiData?.days}
          SelectedDays={multiToMultiData?.newDAY}
        />
        <ServiceAccordion
          selectedService={multiToMultiData?.services}
          startTime={multiToMultiData?.startTime?.split(" ")[0]}
          startMeridiem={multiToMultiData?.startTime?.split(" ")[1]}
          endTime={multiToMultiData?.endTime?.split(" ")[0]}
          endMeridiem={multiToMultiData?.endTime?.split(" ")[1]}
        />
        <div className="row w-100 align-items-center px-1 my-4">
          <div className="col-12">
            <div className="d-flex align-items-center">
              <img
                className="me-3 pointer"
                height={52}
                src="/images/schedule/dailyRules.svg"
                alt=""
              />
              <div>
                <p className="fw-light mb-1 fw-bold">Daily Rules</p>
                <p
                  style={{ fontSize: "12px" }}
                  className="fw-light my-0 text-secondary"
                >
                  All options below apply to all services that occurs on the
                  days selected above.
                </p>
              </div>
            </div>
          </div>
        </div>
        <DailyBrAccordion
          breaks={multiToMultiData?.isBreak}
          breakTimes={multiToMultiData?.breakTimes}
        />
        <RepeatWeekAccordian weekly={multiToMultiData?.isWeekly} />
        <ReservationAccordian
          fee={multiToMultiData?.reservationFee}
          isFee={multiToMultiData?.isReservationFee}
        />
        <AllowLastAccordion
          before={multiToMultiData?.allowBookingBefore}
          buffer={multiToMultiData?.afterAppointmentBuffer}
          booking={multiToMultiData?.isLastMinutesBooking}
        />
        {/* <PeakPriceAccordian
          monthly={multiToMultiData?.monthlySchedulePercentage}
          increase={multiToMultiData?.increaseRateBy}
          vic={multiToMultiData?.isVicExempt}
          pricing={multiToMultiData?.isPeakPricing}
        /> */}
      </>
      <OverlayLoading active={status === "loading" ? true : false} />
    </>
  );
};

export default MultiSchedule;

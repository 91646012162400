import Typography from "@mui/material/Typography";

export const MediaViewModal = ({ media }: any) => {
  return (
    <div className="d-flex justify-content-center">
      <img
        className="rounded"
        src={media.url}
        alt=""
        style={{
          objectFit: "contain",
          maxHeight: 500,
          maxWidth: 500,
        }}
      />
    </div>
  );
};

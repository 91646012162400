// registration states
export const initialRegistrationState = {
  firstName: "",
  lastName: "",
  mobileNo: "",
  password: "",
  password_confirmation: "",
};

//profile update state

export const initialProfileState = {
  firstName: "",
  lastName: "",
  username: "",
  bio: "",
  serviceId: "",
  businessName: "",
  email: "",
  latitude: "",
  longitude: "",
  address: "",
  street: "",
  city: "",
  state: "",
  zip: "",
};

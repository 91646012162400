import React from "react";
import { useSelector } from "react-redux";
import { dashboardDataSelector } from "../../../reducers/authReducer";

const InfoCardds = () => {
  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };
  const whiteText = {
    color: "white",
    fontSize: "13px",
  };

  const dashboardData = useSelector(dashboardDataSelector);

  return (
    <>
      <div className="row gy-4">
        <div className="col-md-4 col-sm-6 mb-sm-0">
          {/* border-bottom border-3 border-primary */}
          <div className="rounded px-4 py-3 bg-payment">
            <img
              height={40}
              className="me-3"
              src="images/dashboard/pending-pay.svg"
              alt=""
            />
            <div style={{ marginTop: "1.5rem" }} className="d-flex">
              <h5 className="m-0 fw-bolder text-white">
                ${dashboardData?.pendingPayments ? dashboardData?.pendingPayments : 0}
              </h5>
              {/* <div
                style={{
                  fontSize: "12px",
                  backgroundColor: "#ebe1ff",
                  color: "#5850EC",
                  width: "50px",
                }}
                className="rounded my-auto ms-2 rounded-pill text-center fw-bold"
              >
                2.5%
              </div> */}
            </div>
            <p style={{ ...poppinsFont, ...whiteText }} className="mt-2 mb-0">
              Pending Payment
            </p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-sm-0">
          {/* border-bottom border-3 border-danger */}
          <div className="rounded px-4 py-3 bg-pending">
            <img
              height={40}
              className="me-3"
              src="images/dashboard/pending-re.svg"
              alt=""
            />
            <div style={{ marginTop: "1.5rem" }} className="d-flex">
              <h5 className="m-0 fw-bolder text-white">
                {dashboardData?.pendingRequests ? dashboardData?.pendingRequests : 0}
              </h5>
              {/* <div
                style={{
                  fontSize: "12px",
                  backgroundColor: "#fde8e8",
                  color: "#E02424",
                  width: "50px",
                }}
                className="rounded my-auto ms-2 rounded-pill text-center fw-bold"
              >
                2.5 %
              </div> */}
            </div>
            <p style={{ ...poppinsFont, ...whiteText }} className="mt-2 mb-0">
              Pending Requests
            </p>
          </div>
        </div>
        <div className="col-md-4 col-sm-6 mb-sm-0">
          {/* border-bottom border-3 border-success */}
          <div className="rounded px-4 py-3 bg-client">
            <img
              height={40}
              className="me-3"
              src="images/dashboard/total-client.svg"
              alt=""
            />
            <div style={{ marginTop: "1.5rem" }} className="d-flex">
              <h5 className="m-0 fw-bolder text-white">
                {dashboardData?.totalClientServed ? dashboardData?.totalClientServed : 0}
              </h5>
              {/* <div
                style={{
                  fontSize: "12px",
                  backgroundColor: "#def7ec",
                  color: "#31C48D",
                  width: "50px",
                }}
                className="rounded my-auto ms-2 rounded-pill text-center fw-bold"
              >
                2.5%
              </div> */}
            </div>
            <p style={{ ...poppinsFont, ...whiteText }} className="mt-2 mb-0">
              Total Client Served
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoCardds;

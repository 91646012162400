import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { urlParameter } from "../../components/common/types";
import { ERROR_MESS } from "../../config/constant";
import {
  activeStatusScheduleRequest,
  createScheduleRequest,
  deleteScheduleRequest,
  deleteScheduleVacationRequest,
  fetchFullScheduleRequest,
  fetchFullScheduleVacationRequest,
  scheduleStatusUpdateRequest,
  servicePlayPauseRequest,
  updateScheduleRequest,
  updateScheduleVacationRequest,
} from "../../helper/provider/schedule";
import { initialScheduleDays } from "../../pages/provider/schedule/initialState";
import { scheduleDayType } from "../../pages/provider/schedule/type";

// import { getAllServices } from "../../../services/Service";
import { RootState } from "../../store";
import { changeTheMeridiem } from "../../utils";
import { xconsole } from "../../utils/console";
import { makeResponseError } from "../../utils/errorHandler";
import { makeSchedule } from "../../utils/factory";
import { SHOW_ERROR, SHOW_SUCCESS } from "../../utils/ToastMessage";

export interface scheduleState {
  onToOne: any;
  onToOneReadOnly: any;
  multiToMulti: any;
  AllMultiToMulti: Array<any>;
  multiToMultiReadOnly: any;
  vacation: Array<any>;
  status: string;
  deleteStatus: string;
  deleteSchStatus: string;
  updateStatus: string;
  error: string | null;
}

export const initialState: scheduleState = {
  onToOne: {
    newDAY: [
      { id: 0, day: "M", name: "Monday", isActive: false },
      { id: 1, day: "T", name: "Tuesday", isActive: false },
      { id: 2, day: "W", name: "Wednesday", isActive: false },
      { id: 3, day: "TR", name: "Thursday", isActive: false },
      { id: 4, day: "F", name: "Friday", isActive: false },
      { id: 5, day: "S", name: "Saturday", isActive: false },
      { id: 6, day: "SU", name: "Sunday", isActive: false },
    ],
    days: [],
    startTime: "12:00 AM",
    endTime: "12:00 PM",
    type: "single-to-single",
    isWeekly: "",
    isBreak: "",
    breakTimes: [],
    isAllowDoubleBooking: "",
    overlapping_time: "",
    services: [
      {
        isDoubleBooking: 1,
      },
    ],
    isReservationFee: "",
    reservationFee: "",
    isAllowLastMinuteBooking: "",
    allowBookingBefore: "",
    afterAppointmentBuffer: null,
    isPeakPricing: "",
    monthlySchedulePercentage: null,
    increaseRateBy: null,
    isVicExempt: null,
    isActive: null,
    servicesLocation: "",
    createdAt: "",
    servicesIds: [],
    doubleBookingServicesIds: [],
    repeatWeekly: "",
    isAllowForDoubleBooking: "",
    overlapingTime: null,
    allServices: 1,
    isLastMinutesBooking: "",
    noOfParticipants: null,
    serviceLocation: "",
  },
  onToOneReadOnly: {
    newDAY: [
      { id: 0, day: "M", name: "Monday", isActive: false },
      { id: 1, day: "T", name: "Tuesday", isActive: false },
      { id: 2, day: "W", name: "Wednesday", isActive: false },
      { id: 3, day: "TR", name: "Thursday", isActive: false },
      { id: 4, day: "F", name: "Friday", isActive: false },
      { id: 5, day: "S", name: "Saturday", isActive: false },
      { id: 6, day: "SU", name: "Sunday", isActive: false },
    ],
    days: [],
    startTime: "12:00 AM",
    endTime: "12:00 PM",
    isWeekly: "",
    isBreak: "",
    breakTimes: [],
    isAllowDoubleBooking: "",
    overlapping_time: "",
    allServices: "",
    isReservationFee: "",
    reservationFee: "",
    isAllowLastMinuteBooking: "",
    allowBookingBefore: "",
    afterAppointmentBuffer: null,
    isPeakPricing: "",
    monthlySchedulePercentage: null,
    increaseRateBy: null,
    isVicExempt: null,
    isActive: null,
    servicesLocation: "",
    createdAt: "",
    servicesIds: [],
    doubleBookingServicesIds: [],
    repeatWeekly: "",
    isAllowForDoubleBooking: "",
    overlapingTime: null,
    isLastMinutesBooking: "",
    noOfParticipants: null,
    serviceLocation: "",
  },
  multiToMulti: {
    newDAY: [
      { id: 0, day: "M", name: "Monday", isActive: false },
      { id: 1, day: "T", name: "Tuesday", isActive: false },
      { id: 2, day: "W", name: "Wednesday", isActive: false },
      { id: 3, day: "TR", name: "Thursday", isActive: false },
      { id: 4, day: "F", name: "Friday", isActive: false },
      { id: 5, day: "S", name: "Saturday", isActive: false },
      { id: 6, day: "SU", name: "Sunday", isActive: false },
    ],
    days: [],
    startTime: "12:00 AM",
    endTime: "12:00 PM",
    type: "multi-to-multi",
    isWeekly: "",
    isBreak: "",
    breakTimes: [],
    isAllowDoubleBooking: "",
    overlapping_time: "",
    allServices: "",
    isReservationFee: "",
    reservationFee: "",
    isAllowLastMinuteBooking: "",
    allowBookingBefore: "",
    afterAppointmentBuffer: null,
    isPeakPricing: "",
    monthlySchedulePercentage: null,
    increaseRateBy: null,
    isVicExempt: null,
    isActive: null,
    servicesLocation: "",
    createdAt: "",
    servicesIds: [],
    doubleBookingServicesIds: [],
    repeatWeekly: "",
    isAllowForDoubleBooking: "",
    overlapingTime: null,
    isLastMinutesBooking: "",
    noOfParticipants: null,
    serviceLocation: "",
  },
  multiToMultiReadOnly: {
    newDAY: [
      { id: 0, day: "M", name: "Monday", isActive: false },
      { id: 1, day: "T", name: "Tuesday", isActive: false },
      { id: 2, day: "W", name: "Wednesday", isActive: false },
      { id: 3, day: "TR", name: "Thursday", isActive: false },
      { id: 4, day: "F", name: "Friday", isActive: false },
      { id: 5, day: "S", name: "Saturday", isActive: false },
      { id: 6, day: "SU", name: "Sunday", isActive: false },
    ],
    days: [],
    startTime: "12:00 AM",
    endTime: "12:00 PM",
    type: "multi-to-multi",
    isWeekly: "",
    isBreak: "",
    breakTimes: [],
    isAllowDoubleBooking: "",
    overlapping_time: "",
    allServices: "",
    isReservationFee: "",
    reservationFee: "",
    isAllowLastMinuteBooking: "",
    allowBookingBefore: "",
    afterAppointmentBuffer: null,
    isPeakPricing: "",
    monthlySchedulePercentage: null,
    increaseRateBy: null,
    isVicExempt: null,
    isActive: null,
    servicesLocation: "",
    createdAt: "",
    servicesIds: [],
    doubleBookingServicesIds: [],
    repeatWeekly: "",
    isAllowForDoubleBooking: "",
    overlapingTime: null,
    isLastMinutesBooking: "",
  },
  AllMultiToMulti: [],
  vacation: [],
  status: "idle",
  deleteStatus: "idle",
  deleteSchStatus: "idle",
  updateStatus: "idle",
  error: null,
};

export const fetchAllSchedule = createAsyncThunk("fetch/schedule", async () => {
  try {
    const res: any = await fetchFullScheduleRequest();
    const { data, success, message } = res.data;
    // console.log("schedule", data);

    // if (data.length > 0 && success) {
    //   thunkApi.dispatch(fetchThirdLevelChildServices(data[0].id));
    // }
    return {
      data,
      success,
      code: 200,
    };
  } catch (error) {}
});

export const fetchVacationSchedule = createAsyncThunk(
  "fetch/vacation/schedule",
  async () => {
    try {
      const res: any = await fetchFullScheduleVacationRequest();
      const { data, success, message } = res?.data;
      // console.log("schedule", data);

      // if (data.length > 0 && success) {
      //   thunkApi.dispatch(fetchThirdLevelChildServices(data[0].id));
      // }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {}
  }
);

export const updateSchedule = createAsyncThunk(
  "put/schedule",
  async (schedule: any, thunkApi) => {
    try {
      const res: any = await updateScheduleRequest(schedule.data);
      const { data, success, message } = res?.data;
      // console.log("schedule", data);
      if (success) {
        if (schedule.setChange) {
          schedule.setChange({
            days: [],
            services: false,
            break: false,
            doubleBooking: false,
            fee: false,
            buffer: false,
            peak: false,
          });
        }
        SHOW_SUCCESS(true, "Schedule updated successfully");
      }

      // if (data.length > 0 && success) {
      //   thunkApi.dispatch(fetchThirdLevelChildServices(data[0].id));
      // }
      if (success) {
        return {
          data: [data],
          success,
          code: 200,
        };
      }
    } catch (error) {
      // console.error({ error });
      // console.log("data ", error.response.data);
      // const { code, success, message, data } = error.response.data;
      return makeResponseError(error.response.data);
    }
  }
);

export const activeStatusSchedule = createAsyncThunk(
  "put/status/schedule",
  async (type: any) => {
    try {
      const res: any = await activeStatusScheduleRequest(type);
      const { data, success, message } = res.data;
      // console.log("schedule", data);

      // if (data.length > 0 && success) {
      //   thunkApi.dispatch(fetchThirdLevelChildServices(data[0].id));
      // }
      if (success) {
        return {
          data: {},
          success,
          code: 200,
        };
      }
    } catch (error) {
      // console.error(error);
      return makeResponseError(error.response.data);
    }
  }
);

export const createSchedule = createAsyncThunk(
  "post/schedule",
  async (schedule: any) => {
    try {
      const res: any = await createScheduleRequest(schedule.data);
      const { data, success, message } = res.data;
      // console.log("schedule", data);
      if (success) {
        if (schedule.setChange) {
          schedule.setChange({
            days: [],
            services: false,
            break: false,
            doubleBooking: false,
            fee: false,
            buffer: false,
            peak: false,
          });
        }
        SHOW_SUCCESS(true, "Schedule created successfully");
      }
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      // console.error(error);
      return makeResponseError(error.response.data);
    }
  }
);

export const updateVacations = createAsyncThunk(
  "put/vacations",
  async (vacations: any) => {
    try {
      const res: any = await updateScheduleVacationRequest(vacations.data);
      const { data, success, message } = res.data;
      // console.log("vacations", data);
      if (success) {
        SHOW_SUCCESS(true, "Vacation created successfully");
        vacations.setNewVacation({
          startDateTime: "",
          endDateTime: "",
          location: "",
        });
        return {
          data,
          success,
          code: 200,
        };
      }
    } catch (error) {
      // console.error(error);
      return makeResponseError(error.response.data);
    }
  }
);

export const deleteVacations = createAsyncThunk(
  "delete/vacations",
  async (id: number) => {
    try {
      const res: any = await deleteScheduleVacationRequest(id);
      // console.log(res);

      return id;
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const deleteSchedule = createAsyncThunk(
  "delete/schedule",
  async ({ id, formData }: { id: urlParameter; formData: any }) => {
    try {
      const res: any = await deleteScheduleRequest(id, formData);
      SHOW_SUCCESS(true, "Schedule deleted successfully");
      return id;
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const scheduleStatusUpdateAction = createAsyncThunk(
  "put/schedule/status",
  async (formData: any) => {
    try {
      const res: any = await scheduleStatusUpdateRequest(formData);
      const { data, success, message } = res.data;
      // console.log("schedule", data);
      if (success) {
        if (formData.type === "single-to-single") {
          SHOW_SUCCESS(true, "One-to-One schedule activated");
        } else {
          SHOW_SUCCESS(true, "Multi-Attendee schedule activated");
        }
      }
      return {
        formData,
        data,
        success,
        code: 200,
      };
    } catch (error) {
      // console.error(error);
      return makeResponseError(error.response.data);
    }
  }
);

export const servicePlayPauseAction = createAsyncThunk(
  "post/service/status",
  async (formData: any) => {
    try {
      const res: any = await servicePlayPauseRequest(formData);
      const { data, success, message } = res.data;
      return {
        formData,
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {
    updateOneToOneDays: (state, { payload }: PayloadAction<any>) => {
      let tempArray = [...state.onToOne.newDAY];
      // console.log("reducer", payload);

      tempArray.forEach((ele) => {
        if (ele.id === payload) {
          ele.isActive = !ele.isActive;
        }
      });

      state.onToOne.newDAY = tempArray;

      if (state.onToOne?.days?.includes(payload)) {
        state.onToOne.days = state.onToOne.days.filter(
          (item: any) => item !== payload
        );
      } else {
        state.onToOne.days.push(payload);
      }
    },

    updateOneToOneServices: (state, { payload }: PayloadAction<any>) => {
      if (state.onToOne.servicesIds.includes(payload.id)) {
        const serviceIndex = state.onToOne.services.findIndex(
          (m) => m?.id === payload.id
        );
        if (serviceIndex !== -1) {
          state.onToOne.services[serviceIndex].isAvailable = 0;
          state.onToOne.servicesIds = state.onToOne.servicesIds.filter(
            (f: number) => f !== payload.id
          );
        }
      } else {
        if (state.onToOne.services.includes("id")) {
          const service = state.onToOne.services.filter(
            (m: { service: { id: number } }) => m.service.id === payload.id
          );

          state.onToOne.servicesIds.push(payload.id);

          state.onToOne.services = [{ ...service[0], isAvailable: 1 }];
        } else {
          state.onToOne.servicesIds.push(payload.id);
          const index = state.onToOne.services.findIndex(
            (m) => m?.id === payload.id
          );
          if (index !== -1) {
            state.onToOne.services[index].isAvailable = 1;
          } else {
            state.onToOne.services.push({
              ...payload,
              isAvailable: 1,
              isDoubleBooking: 1,
            });
          }
        }
      }
    },

    updateOneToOneDoubleBooking: (state, { payload }: PayloadAction<any>) => {
      // xconsole.test(`id ${id}`);
      // xconsole.test(`index ${index}`);
      // state.onToOne.services[payload.index].isDoubleBooking = 0;
      const index = state.onToOne.services.findIndex(
        (m) => m?.service.id === payload.id
      );
      state.onToOne.doubleBookingServicesIds = state.onToOne.servicesIds;
      // state.onToOne.services.push({
      //   ...payload,
      //   isDoubleBooking: 1,
      // });

      // state.onToOne.doubleBookingServicesIds =
      //   state.onToOne.doubleBookingServicesIds.filter(
      //     (f: number) =>
      //       f !== state.onToOne.services[payload.index].service.id
      //   );
    },

    addOneToOneBreakTime: (state) => {
      const times = {
        breakStartTime: "12:00 AM",
        breakEndTime: "12:00 PM",
      };
      // console.log("br time");
      state.onToOne.breakTimes.push(times);
    },

    removeOneToOneBreakTime: (state, { payload }: PayloadAction<any>) => {
      state.onToOne.breakTimes = state.onToOne.breakTimes.filter(
        (item: any, index: number) => index !== payload
      );
    },

    updateOneToOneServicesToAll: (state, { payload }: PayloadAction<any>) => {
      state.onToOne.services = payload.serviceArr;
      state.onToOne.services.forEach((SD: { isAvailable: number }) => {
        // console.log(SD.isAvailable);
        SD.isAvailable = payload.select;
      });
      const ids = state.onToOne.services
        .filter(({ isAvailable }) => isAvailable === 1)
        .map(({ id }) => id);
      // console.log(
      //   "ids",
      //   ids.filter(function (el) {
      //     return el != null;
      //   })
      // );

      //remove undefined from ids array
      const filtered = ids.filter(function (el) {
        return el != null;
      });
      state.onToOne.servicesIds = filtered;
    },

    updateOneToOneDoubleBookingToAll: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      // updateOneToOneDoubleBookingToAll;
      // state.onToOne.services = state.onToOne.services.map(({ service }) => {
      //   return { ...service, isDoubleBooking: payload.select };
      // });

      if (payload.select === 1) {
        state.onToOne.allServices = 1;
      } else {
        state.onToOne.allServices = 0;
      }

      state.onToOne.services.forEach((SD: { isDoubleBooking: number }) => {
        // console.log(SD.isDoubleBooking);
        SD.isDoubleBooking = payload.select;
      });
      const ids = state.onToOne.services
        .filter(({ isDoubleBooking }) => isDoubleBooking === 1)
        .map(({ service }) => service.id);
      // console.info("double booking ", ids);

      state.onToOne.doubleBookingServicesIds = ids;
    },

    updateOneToOne: (state, { payload }: PayloadAction<any>) => {
      const { key, value } = payload;
      state.onToOne[key] = value;
      // console.log(state.onToOne[key]);
    },

    updateOneToOneBreakTime: (state, { payload }: PayloadAction<any>) => {
      const { key, value, idx } = payload;
      const temTimeMed = state.onToOne.breakTimes[idx][key].split(" ")[1];
      console.info(temTimeMed);
      const temTime = value.split(" ")[0];

      state.onToOne.breakTimes[idx][key] = `${temTime} ${temTimeMed}`;

      // console.log(state.onToOne[key]);
    },

    handleOnrToOneTimeChange: (state, { payload }: PayloadAction<any>) => {
      const { key, value } = payload;

      state.onToOne[key] = changeTheMeridiem(state.onToOne[key], value);

      state.onToOneReadOnly[key] = changeTheMeridiem(state.onToOne[key], value);
      // console.log(`${tempTime} ${value}`);
    },

    handleOnrToOneBreakTimeMeridiemChange: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      const { key, value, idx } = payload;

      state.onToOne.breakTimes[idx][key] = changeTheMeridiem(
        state.onToOne.breakTimes[idx][key],
        value
      );
    },
    // multi to multi

    updateMultiToMultiDays: (state, { payload }: PayloadAction<any>) => {
      let tempArray = [...state.multiToMulti.newDAY];
      // console.log("reducer", payload);

      tempArray.forEach((ele) => {
        if (ele.id === payload) {
          ele.isActive = !ele.isActive;
        }
      });

      state.multiToMulti.newDAY = tempArray;

      if (state.multiToMulti.days.includes(payload)) {
        state.multiToMulti.days = state.multiToMulti.days.filter(
          (item: any) => item !== payload
        );
      } else {
        state.multiToMulti.days.push(payload);
      }
    },

    updateMultiToMultiServices: (state, { payload }: PayloadAction<any>) => {
      state.multiToMulti.servicesIds = [payload];
    },

    updateMultiToMultiDoubleBooking: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      const { id, index } = payload;
      if (state.multiToMulti.doubleBookingServicesIds.includes(payload.id)) {
        xconsole.test(`id ${id}`);
        xconsole.test(`index ${index}`);
        state.multiToMulti.services[payload.index].isDoubleBooking = 0;

        state.multiToMulti.doubleBookingServicesIds =
          state.multiToMulti.doubleBookingServicesIds.filter(
            (f: number) =>
              f !== state.multiToMulti.services[payload.index].service.id
          );
      } else {
        state.multiToMulti.doubleBookingServicesIds.push(
          state.multiToMulti.services[payload.index].service.id
        );
        state.multiToMulti.services[payload.index].isDoubleBooking = 1;

        // state.multiToMulti.services.forEach(SERVICE => {
        //   SERVICE.isDoubleBooking = 1
        // });

        // push(payload.processedData);
      }
    },

    updateMultiToMultiServicesToAll: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.multiToMulti.services = payload.serviceArr;
      state.multiToMulti.services.forEach((SD: { isAvailable: number }) => {
        // console.log(SD.isAvailable);
        SD.isAvailable = payload.select;
      });
      const ids = state.multiToMulti.services
        .filter(({ isAvailable }) => isAvailable === 1)
        .map(({ service }) => service.id);
      state.multiToMulti.servicesIds = ids;
    },

    updateMultiToMultiDoubleBookingToAll: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      // updateOneToOneDoubleBookingToAll;
      // state.multiToMulti.services = state.multiToMulti.services.map(({ service }) => {
      //   return { ...service, isDoubleBooking: payload.select };
      // });

      state.multiToMulti.services.forEach((SD: { isDoubleBooking: number }) => {
        // console.log(SD.isDoubleBooking);
        SD.isDoubleBooking = payload.select;
      });
      const ids = state.multiToMulti.services
        .filter(({ isDoubleBooking }) => isDoubleBooking === 1)
        .map(({ service }) => service.id);
      console.info("double booking ", ids);

      state.multiToMulti.doubleBookingServicesIds = ids;
    },

    updateMultiToMulti: (state, { payload }: PayloadAction<any>) => {
      const { key, value } = payload;
      state.multiToMulti[key] = value;
      // console.log(state.multiToMulti[key]);
    },

    addMultiToMultiBreakTime: (state) => {
      const times = {
        breakStartTime: "12:00 AM",
        breakEndTime: "12:00 PM",
      };
      // console.log("br time");
      state.multiToMulti.breakTimes.push(times);
    },

    removeMultiToMultiBreakTime: (state, { payload }: PayloadAction<any>) => {
      state.multiToMulti.breakTimes = state.multiToMulti.breakTimes.filter(
        (item: any, index: number) => index !== payload
      );
    },

    updateMultiToMultiBreakTime: (state, { payload }: PayloadAction<any>) => {
      const { key, value, idx } = payload;
      const temTimeMed = state.multiToMulti.breakTimes[idx][key].split(" ")[1];
      console.info(temTimeMed);
      const temTime = value.split(" ")[0];

      state.multiToMulti.breakTimes[idx][key] = `${temTime} ${temTimeMed}`;

      // console.log(state.multiToMulti[key]);
    },

    handleMultiToMultiTimeChange: (state, { payload }: PayloadAction<any>) => {
      const { key, value } = payload;

      state.multiToMulti[key] = changeTheMeridiem(
        state.multiToMulti[key],
        value
      );

      state.multiToMultiReadOnly[key] = changeTheMeridiem(
        state.multiToMulti[key],
        value
      );
      // console.log(`${tempTime} ${value}`);
    },

    handleMultiToMultiBreakTimeMeridiemChange: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      const { key, value, idx } = payload;

      state.multiToMulti.breakTimes[idx][key] = changeTheMeridiem(
        state.multiToMulti.breakTimes[idx][key],
        value
      );
    },

    resetState: (state) => {
      state.onToOne = initialState.onToOne;
      state.onToOneReadOnly = initialState.onToOneReadOnly;
      state.multiToMulti = initialState.multiToMulti;
      state.multiToMultiReadOnly = initialState.multiToMultiReadOnly;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAllSchedule.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchAllSchedule.fulfilled, (state, { payload }) => {
      const { data, success }: any = payload;
      if (success) {
        data.forEach((ele: any) => {
          if (ele.type === "single-to-single") {
            state.onToOneReadOnly = {
              ...ele,
              ...makeSchedule(ele, state),
            };
            state.onToOne = {
              ...ele,
              ...makeSchedule(ele, state),
            };
          } else if (ele.type === "multi-to-multi") {
            state.multiToMultiReadOnly = {
              ...ele,
              ...makeSchedule(ele, state),
            };
          }
        });
        // let tempMTMData = data.filter(
        //   (MTM: { type: string }) => MTM.type === "multi-to-multi"
        // );

        // tempMTMData.forEach((ele) => {
        //   let newDAY = [
        //     { id: 0, day: "M", name: "Monday", isActive: false },
        //     { id: 1, day: "T", name: "Tuesday", isActive: false },
        //     { id: 2, day: "W", name: "Wednesday", isActive: false },
        //     { id: 3, day: "TR", name: "Thursday", isActive: false },
        //     { id: 4, day: "F", name: "Friday", isActive: false },
        //     { id: 5, day: "S", name: "Saturday", isActive: false },
        //     { id: 6, day: "SN", name: "Sunday", isActive: false },
        //   ];
        //   newDAY.forEach((d: scheduleDayType) => {
        //     ele?.days?.forEach(({ scheduleDays }: any) => {
        //       if (scheduleDays === d.name) {
        //         d.isActive = true;
        //       }
        //     });
        //   });

        //   ele.newDAY = newDAY;
        // });
        const multiToMultiData = data.filter(
          (MTM: { type: string }) => MTM.type === "multi-to-multi"
        );

        const newData = multiToMultiData.map((obj) => {
          const scheduleDays = [
            { id: 0, day: "M", name: "Monday", isActive: false },
            { id: 1, day: "T", name: "Tuesday", isActive: false },
            { id: 2, day: "W", name: "Wednesday", isActive: false },
            { id: 3, day: "TR", name: "Thursday", isActive: false },
            { id: 4, day: "F", name: "Friday", isActive: false },
            { id: 5, day: "S", name: "Saturday", isActive: false },
            { id: 6, day: "SU", name: "Sunday", isActive: false },
          ];

          const newObj = { ...obj, scheduleDays };

          scheduleDays.forEach((day) => {
            obj?.days?.forEach(({ scheduleDays }: any) => {
              if (scheduleDays === day.name) {
                day.isActive = true;
              }
            });
          });

          return newObj;
        });

        state.AllMultiToMulti = newData;
        state.status = "success";
      }
    });
    builder.addCase(fetchAllSchedule.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = "idle";
    });

    builder.addCase(fetchVacationSchedule.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchVacationSchedule.fulfilled, (state, { payload }) => {
      state.status = "succeeded";
      const { data, success }: any = payload;
      if (success) {
        // const date = new Date(utcDate);
        data.forEach((ele) => {
          ele.startDateTime = ele.startDateTime?.includes(".")
            ? ele.startDateTime?.split(".")[0]
            : ele.startDateTime;
          ele.endDateTime = ele.endDateTime?.includes(".")
            ? ele.endDateTime?.split(".")[0]
            : ele.endDateTime;
        });
      }

      state.vacation = data;
    });
    builder.addCase(fetchVacationSchedule.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.status = "idle";
    });

    builder.addCase(updateSchedule.pending, (state) => {
      state.updateStatus = "loading";
      state.error = null;
    });
    builder.addCase(updateSchedule.fulfilled, (state, { payload }) => {
      const { data, success, code }: any = payload;

      if (success) {
        data.forEach((ele: any) => {
          if (ele.type === "single-to-single") {
            state.onToOneReadOnly = {
              ...ele,
              ...makeSchedule(ele, state),
            };
            state.onToOne = {
              ...ele,
              ...makeSchedule(ele, state),
            };
          }
        });

        state.updateStatus = "success";
      } else {
        state.updateStatus = "fail";
      }
    });
    builder.addCase(updateSchedule.rejected, (state, { payload }) => {
      state.error = payload as string;
      state.updateStatus = "not working";
    });

    builder.addCase(updateVacations.pending, (state, action) => {
      state.updateStatus = "loading";
    });
    builder.addCase(updateVacations.fulfilled, (state, action) => {
      state.updateStatus = "succeeded";
      const { data } = action.payload;
      const finalData = {
        ...data,
        startDateTime: data.startDateTime?.includes(".")
          ? data.startDateTime?.split(".")[0]
          : data.startDateTime,
        endDateTime: data.endDateTime?.includes(".")
          ? data.endDateTime?.split(".")[0]
          : data.endDateTime,
      };
      // console.log("data", finalData);
      state.vacation.push(finalData);
    });
    builder.addCase(updateVacations.rejected, (state, action) => {
      state.updateStatus = "rejected";
    });

    builder.addCase(deleteVacations.pending, (state, action) => {
      state.deleteStatus = "loading";
    });
    builder.addCase(deleteVacations.fulfilled, (state, action) => {
      state.deleteStatus = "succeeded";
      // console.log("id", action.payload);

      state.vacation = state.vacation.filter(
        (vacation) => vacation.id !== action.payload
      );
    });
    builder.addCase(deleteVacations.rejected, (state, action) => {
      state.deleteStatus = "failed";
    });

    builder.addCase(deleteSchedule.pending, (state, action) => {
      state.deleteSchStatus = "loading";
    });
    builder.addCase(deleteSchedule.fulfilled, (state, action) => {
      state.deleteSchStatus = "succeeded";
      state.AllMultiToMulti = state.AllMultiToMulti.filter(
        (schedule) => schedule?.id !== action.payload
      );
    });
    builder.addCase(deleteSchedule.rejected, (state, action) => {
      state.deleteSchStatus = "failed";
    });

    builder.addCase(createSchedule.pending, (state, action) => {
      // state.status = "loading";
      state.updateStatus = "loading";
    });
    builder.addCase(createSchedule.fulfilled, (state, action) => {
      // state.status = "succeeded";
      state.updateStatus = "succeeded";
      const { data } = action.payload;
      if (data.type === "single-to-single") {
        state.onToOneReadOnly = {
          ...data,
          ...makeSchedule(data, state),
        };

        state.onToOne = {
          ...data,
          ...makeSchedule(data, state),
        };
      } else {
        state.AllMultiToMulti.push(action.payload.data);
        state.multiToMulti = initialState.multiToMulti;
      }
    });
    builder.addCase(createSchedule.rejected, (state, action) => {
      // state.status = "failed";
      state.updateStatus = "failed";
    });
    builder.addCase(scheduleStatusUpdateAction.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(scheduleStatusUpdateAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { formData }: any = action.payload;
      if (formData.type === "single-to-single") {
        state.onToOne.isActive = 1;
      } else {
        state.onToOne.isActive = 0;
      }
    });
    builder.addCase(scheduleStatusUpdateAction.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(servicePlayPauseAction.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(servicePlayPauseAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { formData }: any = action.payload;
      if (formData.multiId) {
        const index = state.AllMultiToMulti.findIndex(
          (data) => data.id === formData.multiId
        );

        state.AllMultiToMulti[index].services[0].isPaused = formData.isPaused;
      } else {
        const index = state.onToOne.services.findIndex(
          (service) => service.service.id === formData.serviceId
        );

        state.onToOne.services[index].isPaused = formData.isPaused;
        state.onToOneReadOnly.services[index].isPaused = formData.isPaused;
      }
    });
    builder.addCase(servicePlayPauseAction.rejected, (state, action) => {
      state.status = "failed";
    });

    //   builder.addCase(fetchThirdLevelChildServices.pending, (state) => {
    //     state.status = "loading";
    //     state.error = null;
    //   });
    //   builder.addCase(
    //     fetchThirdLevelChildServices.fulfilled,
    //     (state, { payload }) => {
    //       const { data, success }: any = payload;
    //       if (success) {
    //         state.levelThree = data.sort(
    //           (a: { providerService: any }, b: { providerService: any }) => {
    //             if (a.providerService === null) {
    //               return 1;
    //             }

    //             if (b.providerService === null) {
    //               return -1;
    //             }

    //             if (a.providerService === b.providerService) {
    //               return 0;
    //             }

    //             return a.providerService < b.providerService ? -1 : 1;
    //           }
    //         );

    //         state.status = "idle";
    //       }
    //     }
    //   );
    //   builder.addCase(
    //     fetchThirdLevelChildServices.rejected,
    //     (state, { payload }) => {
    //       state.error = payload as string;
    //       state.status = "idle";
    //     }
    //   );
    // },
  },
});

export const {
  // one To one
  updateOneToOneDays,
  updateOneToOne,
  updateOneToOneServices,
  updateOneToOneBreakTime,
  handleOnrToOneTimeChange,
  handleOnrToOneBreakTimeMeridiemChange,
  updateOneToOneDoubleBooking,
  updateOneToOneDoubleBookingToAll,
  updateOneToOneServicesToAll,
  addOneToOneBreakTime,
  removeOneToOneBreakTime,

  // multi To multi
  updateMultiToMultiDays,
  updateMultiToMulti,
  updateMultiToMultiServices,
  updateMultiToMultiBreakTime,
  handleMultiToMultiTimeChange,
  handleMultiToMultiBreakTimeMeridiemChange,
  updateMultiToMultiDoubleBooking,
  updateMultiToMultiDoubleBookingToAll,
  updateMultiToMultiServicesToAll,
  addMultiToMultiBreakTime,
  removeMultiToMultiBreakTime,

  resetState,
} = scheduleSlice.actions;

export default scheduleSlice.reducer;

export const scheduleOnToOneSelector = (state: RootState) =>
  state.schedule.onToOne;
export const scheduleMultiToMultiSelector = (state: RootState) =>
  state.schedule.multiToMulti;

export const scheduleStatus = (state: RootState) => state.schedule.status;
export const updateStatus = (state: RootState) => state.schedule.updateStatus;
export const deleteStatus = (state: RootState) => state.schedule.deleteStatus;
export const deleteSchStatus = (state: RootState) =>
  state.schedule.deleteSchStatus;

export const scheduleOnToOneReadOnlySelector = (state: RootState) =>
  state.schedule.onToOneReadOnly;

export const scheduleMultiToMultiReadOnlySelector = (state: RootState) =>
  state.schedule.multiToMultiReadOnly;

export const vacationSelector = (state: RootState) => state.schedule.vacation;
export const allMultiToMultiSelector = (state: RootState) =>
  state.schedule.AllMultiToMulti;

export const allMultiToMultiNamesSelector = (state: RootState) =>
  state.schedule.AllMultiToMulti.map((S) => S?.services[0]?.serviceName?.name);

import React from 'react'
import { Backdrop } from "@mui/material";

const OverlayLoading = ({ active }) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={active ? true : false}
        >
            <img
                style={{ borderRadius: '10px' }}
                className="me-2 animate"
                height={100}
                width={100}
                src="/images/KZH-PRO.jpeg"
                alt=""
            />
        </Backdrop>
    )
}

export default OverlayLoading
import React from "react";
import { Skeleton } from "@mui/material";
import { COLORS } from "../styles";

const ChatHeaderSkeleteon = () => {
  return (
    <div className="pe-4 ps-2 d-flex justify-content-between align-items-center mb-3 border-bottom py-3">
      <div className="d-flex align-items-center">
        <Skeleton variant="circular" width={40} height={40} />
        <div className="ms-2">
          <Skeleton width={100} animation="pulse" />
        </div>
      </div>
    </div>
  );
};

const SendSkeleton = () => {
  return (
    <div
      style={{
        borderRadius: "10px",
        ...COLORS.bgLightGrey,
      }}
      className="d-flex hover-light py-2 mx-2 mt-2 px-2 align-items-center"
    >
      {/* <Skeleton className="me-2" variant="circular" width={45} height={45} /> */}
      <div style={{ height: "30px", overflow: "hidden" }} className="w-100">
        {/* <p style={{ color: "grey", fontSize: "12px" }} className="mb-0"> */}
        <div
          style={{ color: "grey", fontSize: "12px" }}
          className="d-flex justify-content-between"
        >
          <div className="text-center">
            <Skeleton className="mb-2" width={200} animation="pulse" />
          </div>
          <div className="d-flex align-items-center gap-2">
            <Skeleton
              className="mb-2"
              variant="circular"
              width={30}
              height={30}
            />
            <Skeleton
              className="mb-2"
              variant="circular"
              width={30}
              height={30}
            />
          </div>
        </div>
        {/* </p> */}
      </div>
    </div>
  );
};

const MessageSkeleton = ({ className, radius }) => {
  return (
    <div className="row my-1 ml-2 w-100">
      <div className={`col-6 ${className}`}>
        <div
          style={{
            borderRadius: radius,
            ...COLORS.bgLightGrey,
          }}
          className="mb-0 pt-2 ps-2 pe-1"
        >
          {/* <Skeleton className="me-2" variant="circular" width={45} height={45} /> */}
          <div className="pt-1">
            <Skeleton className="mb-2" width={100} animation="pulse" />
          </div>
          <div
            className={`mb-0 text-muted small mx-1 d-flex justify-content-end`}
            style={
              {
                // marginTop: String(message.messageType) === "image" ? '-15px' : '',
              }
            }
          >
            <Skeleton
              className="mb-2"
              width={50}
              height={15}
              animation="pulse"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const InboxLoading = () => {
  // create skeleton for inbox
  return (
    <div>
      <div>
        <ChatHeaderSkeleteon />
      </div>
      <div
        style={{ height: "48.3vh" }}
        className="d-flex flex-column px-4 overflow-auto slim-scroll"
      >
        <MessageSkeleton className={"me-auto"} radius={"8px 8px 8px 0px"} />
        <MessageSkeleton className={"me-auto"} radius={"8px 8px 8px 0px"} />
        <MessageSkeleton className={"ms-auto"} radius={"8px 8px 0px 8px"} />
        <MessageSkeleton className={"ms-auto"} radius={"8px 8px 0px 8px"} />
        <MessageSkeleton className={"ms-auto"} radius={"8px 8px 0px 8px"} />
        <MessageSkeleton className={"ms-auto"} radius={"8px 8px 0px 8px"} />
      </div>
      {/* <div className="mt-3">
        <SendSkeleton />
      </div> */}
    </div>
  );
};

export default InboxLoading;

import { useEffect, useState } from "react";
import {
  changeAppointmentStatus,
  pendingAppStatus,
} from "../../../../reducers/appointmentReducer";
import { AppDispatch } from "../../../../store";
import { convert24To12 } from "../../../../utils";
import { useDispatch, useSelector } from "react-redux";
import PendingRequestsLoading, {
  SingleRequestLoading,
} from "../skeletonLoading/PendingRequestsLoading";
import ConfModal from "../../../../components/common/ConfModal";
import DeletePrompt from "../../../provider/appointment/components/ActionPrompt";
import { useNavigate } from "react-router-dom";
import { loggedInUserProfile } from "../../../../reducers/authReducer";

const RequestCard = ({ index, reqData }: { index: number; reqData: any }) => {
  const dispatch = useDispatch<AppDispatch>();
  const status = useSelector(pendingAppStatus);
  const navigate = useNavigate();
  const [button, setButton] = useState("");
  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);
  const [appointmentStatus, setAppointmentStatus] = useState({});
  const authUser = useSelector(loggedInUserProfile);

  useEffect(() => {
    if (status === "succeeded") {
      setButton("");
    }
  }, [button, status]);

  return (
    <>
      {status === "loading" && button === reqData.id ? (
        <SingleRequestLoading />
      ) : (
        <div className="border p-2 my-2 border border-gray rounded">
          <div
            className="row mt-4 pointer"
            onClick={() => navigate(`/client/profile/${reqData?.client?.id}`)}
          >
            <div className="col-1">
              <img
                height={35}
                width={35}
                className="me-3 rounded-circle border"
                src={
                  reqData?.client?.profileImage
                    ? reqData?.client?.profileImage
                    : "/images/avatar2.png"
                }
                alt=""
              />
            </div>
            <div className="col-11">
              <p
                className="mb-0 ms-3"
                style={{ fontSize: "14px", fontWeight: 600 }}
              >
                {reqData?.client?.firstName} {reqData?.client?.lastName}
              </p>
              <div className="d-flex ms-3 align-items-center my-1">
                <img
                  height={15}
                  className="me-2"
                  src="images/locate.png"
                  alt=""
                />
                <p style={{ color: "grey", fontSize: "13px" }} className="mb-0">
                  {reqData?.appointmentAddress
                    ? reqData?.appointmentAddress
                    : authUser?.street +
                      " " +
                      authUser?.city +
                      "" +
                      authUser?.state}
                </p>
              </div>
              <div className="d-flex ms-3 align-items-center my-1">
                <img
                  height={13}
                  className="me-2"
                  src="images/time.png"
                  alt=""
                />
                <p style={{ color: "grey", fontSize: "13px" }} className="mb-0">
                  {/* {reqData?.appointmentDate} */}
                  <span className="">
                    {`${convert24To12(reqData?.startTime)} - ${convert24To12(
                      reqData?.endTime
                    )}`}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-12 text-end">
              <button
                style={{ fontSize: "14px" }}
                className="btn btn-outline-danger me-3"
                onClick={() => {
                  // dispatch(
                  //   changeAppointmentStatus({
                  //     appointmentId: reqData.id,
                  //     status: "declineByProvider",
                  //     isPaid: "0",
                  //   })
                  // );
                  setButton(reqData.id);
                  handleOpen3();
                  setAppointmentStatus({
                    ...appointmentStatus,
                    appointmentId: reqData.id,
                    status: "declineByProvider",
                    isPaid: reqData?.isPaid,
                  });
                }}
              >
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-x me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                  Decline
                </>
              </button>
              <button
                style={{ fontSize: "14px" }}
                className="btn btn-success"
                onClick={() => {
                  dispatch(
                    changeAppointmentStatus({
                      appointmentId: reqData.id,
                      status: "approveByProvider",
                      isPaid: reqData?.isPaid,
                    })
                  );
                  setButton(reqData.id);
                }}
              >
                <>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-check2 me-1"
                    viewBox="0 0 16 16"
                  >
                    <path d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z" />
                  </svg>
                  Approve
                </>
              </button>
            </div>
          </div>
          <ConfModal handleClose={handleClose3} open={open3}>
            <DeletePrompt
              handleClose={handleClose3}
              data={appointmentStatus}
              textToShow={"Canceling Appointment"}
            />
          </ConfModal>
        </div>
      )}
    </>
  );
};

export default RequestCard;

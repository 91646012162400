import React from "react";

const UserProfileHeader = ({ item }) => {
  const streetAddress =
    item?.profile?.street +
    " " +
    item?.profile?.city +
    " " +
    item?.profile?.state;
  console.log("data", item);

  return (
    <div className="mt-3">
      <div className="d-flex justify-content-center">
        <img
          height={40}
          width={40}
          className="me-2 rounded-circle"
          src={item?.profile?.src ? item?.profile?.src : "/images/avatar2.png"}
          alt=""
          loading="lazy"
        />
        <div className="">
          <p className={`mb-0 text-white`} style={{ fontSize: "14px" }}>
            {item?.profile?.name}
          </p>
          <p
            style={{ fontSize: "13px", minHeight: "30px" }}
            className="mb-0 text-white"
          >
            {item?.location ? item?.location : streetAddress}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserProfileHeader;

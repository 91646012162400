import React from "react";
import Typography from "@mui/material/Typography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { addMemberAction } from "../../../../reducers/clientReducer";
import { AppDispatch, RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSecondLevelChildServices,
  serviceLevelTwoSelector,
} from "../../../../reducers/serviceReducer";
import {
  loggedInUserProfile,
  loggedInUserProfileRootService,
} from "../../../../reducers/authReducer";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";

const InviteModal = ({ handleClose }: { handleClose(): void }) => {
  const [data, setData] = React.useState({
    rootServiceId: "",
    serviceId: "",
    first_name: "",
    last_name: "",
    mobileNo: "",
    image: "",
  });

  const loggedInUser = useSelector(loggedInUserProfile);
  const { status } = useSelector((state: RootState) => state.client);

  const [image, setImage] = React.useState<File>();
  const [mobileError, setMobileError] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();
  const rootServices = useSelector(loggedInUserProfileRootService);
  const levelTwoServices = useSelector(serviceLevelTwoSelector);

  React.useEffect(() => {
    dispatch(fetchSecondLevelChildServices(rootServices?.service?.id));
    return () => {};
  }, []);

  const handleImage = (e: any) => {
    setImage(e.target.files[0]);
    console.log("Image", image?.name);
  };

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("First name is required"),
    last_name: yup.string().required("Last name is required"),
    mobileNo: yup.string().required("Number is required"),
  });

  const handleSubmit = (submittedData) => {
    // console.log("data", submittedData);

    const formData = new FormData();

    formData.append("firstName", submittedData.first_name);
    formData.append("lastName", submittedData.last_name);
    formData.append("mobileNo", submittedData.mobileNo);
    formData.append("image", image);
    formData.append("latitude", "");
    formData.append("longitude", "");

    if (!mobileError) {
      dispatch(
        addMemberAction({
          formData: formData,
          currentUser: loggedInUser.id,
          sharedUser: submittedData.mobileNo,
          handleClose,
        })
      );
    }
  };

  // }

  return (
    <>
      <Typography
        className="text-dark text-start mb-0"
        id="modal-modal-title"
        sx={{
          fontSize: "18px",
          fontWeight: "550",
        }}
        component="h6"
      >
        Add Client
      </Typography>
      <div>
        <Formik
          initialValues={data}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            // console.log("values", values);
            handleSubmit(data);
          }}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <div className="row gx-2">
                <div className="col-md-6 form-group">
                  <label
                    style={{ fontSize: "14px" }}
                    className="form-label me-4 text-secondary"
                  >
                    First Name
                  </label>
                  <div className="input-group">
                    <input
                      name="first_name"
                      type="text"
                      className={`form-control ${
                        errors.first_name && touched.first_name
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="First Name"
                      onChange={(e) => {
                        handleChange(e);
                        setData({
                          ...data,
                          first_name: e.target.value,
                        });
                      }}
                    />
                    <ErrorMessage
                      name="first_name"
                      component="span"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-6 form-group mt-md-0">
                  <label
                    style={{ fontSize: "14px" }}
                    className="form-label me-4 text-secondary"
                  >
                    Last Name
                  </label>
                  <div className="input-group">
                    <input
                      name="last_name"
                      type="text"
                      className={`form-control ${
                        errors.last_name && touched.last_name
                          ? "is-invalid"
                          : ""
                      }`}
                      placeholder="Last Name"
                      onChange={(e) => {
                        handleChange(e);
                        setData({
                          ...data,
                          last_name: e.target.value,
                        });
                      }}
                    />
                    <ErrorMessage
                      name="last_name"
                      component="span"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <label
                  style={{ fontSize: "14px" }}
                  className="form-label me-4 text-secondary"
                >
                  Phone Number
                </label>
                <>
                  <PhoneInputWithCountrySelect
                    defaultCountry="US"
                    name="mobileNo"
                    onBlur={() => {
                      if (data.mobileNo) {
                        setMobileError(
                          !isValidPhoneNumber(data.mobileNo.toString())
                        );
                      }
                    }}
                    // value={data?.mobileNo}
                    onChange={(e) => {
                      handleChange({
                        target: {
                          value: e?.length > 0 ? Math.random() : "",
                          name: "mobileNo",
                        },
                      });
                      setMobileError(false);
                      setData({
                        ...data,
                        mobileNo: e,
                      });
                    }}
                    id="phoneNumber"
                    className={`form-control ${
                      (errors.mobileNo && touched.mobileNo) || mobileError
                        ? "border-danger"
                        : ""
                    }`}
                    international
                    placeholder="Enter phone number"
                  />
                  {errors.mobileNo && touched.mobileNo ? (
                    <div className="text-danger">
                      <small>Phone Number is required</small>
                    </div>
                  ) : null}
                </>
                {mobileError && (
                  <p className="text-danger">Invalid phone number</p>
                )}
              </div>
              <div className="row">
                <div className="col-6">
                  <button
                    type="reset"
                    onClick={handleClose}
                    className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  {status !== "loading" ? (
                    <button
                      style={{ backgroundColor: "#04BAC7" }}
                      className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                      type="submit"
                      // onClick={() => {
                      //   handleSubmit(data);
                      //   handleClose();
                      // }}
                    >
                      Add
                    </button>
                  ) : (
                    <div
                      style={{ backgroundColor: "#04BAC7" }}
                      className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                    >
                      <div
                        className="d-flex justify-content-center align-items-center gap-1"
                        // style={{ padding: "5.5px 0" }}
                      >
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                        Add
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default InviteModal;

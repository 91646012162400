import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createPayments,
  getProviderBoardingRequest,
  getRecord,
  getTypes,
  getUserApplicationId,
  getUserCardsRequest,
  providerBoardingRequest,
} from "../../helper/provider/payments";
import { RootState } from "../../store";
import { makeResponseError } from "../../utils/errorHandler";

export interface paymentState {
  businessTypes: Array<any>;
  token: string;
  status: string;
  error: string | null;
  record: any;
  cards: any;
}

export const initialState: paymentState = {
  businessTypes: [],
  token: "",
  record: {},
  status: "idle",
  error: null,
  cards: [],
};

export const fetchUserApplicationId = createAsyncThunk(
  "get/applicationId",
  async (id: any) => {
    try {
      const res: any = await getUserApplicationId(id.id, id.type);
      const { data, success, message } = res.data;
      //   console.log("types", data);
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {}
  }
);

export const fetchAllBusinessTypes = createAsyncThunk(
  "get/businessTypes",
  async () => {
    try {
      const res: any = await getTypes();
      const { data, success, message } = res.data;
      //   console.log("types", data);
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {}
  }
);

export const createPayment = createAsyncThunk(
  "create/Payment",
  async (formData: any) => {
    try {
      const res: any = await createPayments(formData.data);
      const { data, success, message } = res.data;
      // console.log("types", data);

      if (success) {
        if (formData.step === 4) {
          formData.setChangeStep("CompletedPayment");
        }
      }
      return {
        data,
        success,
        message,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const providerBoardingAction = createAsyncThunk(
  "create/providerBoarding",
  async (formData: any) => {
    try {
      const res: any = await providerBoardingRequest(formData.data);
      const { data, success, message } = res.data;
      if (success) {
        if (formData.step === 4) {
          formData.setChangeStep("CompletedPayment");
        }
        if (formData.step === 3) {
          formData.resetForm();
        }
      }
      // console.log("types", res.data);
      return {
        data,
        success,
        message,
      };
    } catch (error) {
      makeResponseError(error.response.data);
      console.log("error", error);

      return error;
    }
  }
);

export const getPaymentRecord = createAsyncThunk("get/Record", async () => {
  try {
    const res: any = await getRecord();
    const { data, success, message } = res.data;
    return {
      data,
      success,
      message,
    };
  } catch (error) {
    return error;
  }
});

export const getProviderBoardingAction = createAsyncThunk(
  "get/providerBoarding",
  async () => {
    try {
      const res: any = await getProviderBoardingRequest();
      const { data, success, message } = res.data;
      return {
        data,
        success,
        message,
      };
    } catch (error) {
      return error;
    }
  }
);

export const getUserCardsAction = createAsyncThunk(
  "get/userCards",
  async (formData: any) => {
    try {
      const res: any = await getUserCardsRequest(formData.type, formData.id);
      const { data, success, message } = res.data;
      return {
        data,
        success,
        message,
      };
    } catch (error) {
      return error;
    }
  }
);

const paymentSlice = createSlice({
  name: "Payment",
  initialState,
  reducers: {
    deletePrincipal: (state: any, { payload }) => {
      // filter principals and return new array
      const newPrincipals = state.record.principals.filter(
        (principal) => principal.id !== payload
      );
      state.record.principals = newPrincipals;
      //dispatch
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchUserApplicationId.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchUserApplicationId.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data } = action.payload;
      state.applicationId = data;
    });
    builder.addCase(fetchUserApplicationId.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(fetchAllBusinessTypes.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllBusinessTypes.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data } = action.payload;
      state.businessTypes = data;
    });
    builder.addCase(fetchAllBusinessTypes.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(createPayment.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createPayment.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data, success } = action.payload;
      if (success) {
        state.record = data;
      }
    });
    builder.addCase(createPayment.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(getPaymentRecord.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getPaymentRecord.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data, success } = action.payload;
      if (success) {
        state.record = data;
      }
    });
    builder.addCase(getPaymentRecord.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(providerBoardingAction.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(providerBoardingAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      // console.log("data", action.payload);
      const { data, success } = action.payload;

      if (success) {
        state.record = data;
      }
    });
    builder.addCase(providerBoardingAction.rejected, (state, action) => {
      state.status = "failed";
      console.error("error", action.error);
    });

    builder.addCase(getProviderBoardingAction.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getProviderBoardingAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data, success } = action.payload;
      if (success) {
        state.record = data;
      }
    });
    builder.addCase(getProviderBoardingAction.rejected, (state, action) => {
      state.status = "failed";
    });

    builder.addCase(getUserCardsAction.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getUserCardsAction.fulfilled, (state, action) => {
      state.status = "succeeded";
      const { data, success } = action.payload;
      if (success) {
        state.cards = data;
      }
    });
    builder.addCase(getUserCardsAction.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export const { deletePrincipal } = paymentSlice.actions;

export default paymentSlice.reducer;

export const businessTypes = (state: RootState) => state.payment.businessTypes;

export const applicationId = (state: RootState) => state.payment.applicationId;

export const status = (state: RootState) => state.payment.status;

export const PaymentRecord = (state: RootState) => state.payment.record;

export const paymentPrincipals = (state: RootState) =>
  state.payment.record?.principals;

export const paymentCards = (state: RootState) => state.payment.cards;

import React from "react";
import { useDispatch } from "react-redux";
import { updateOneToOne } from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const RepeatWeekAccordian = ({ weekly }) => {
  const [change, setChange] = React.useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const handleToggleChange = (e: any) => {
    setChange(true);
    if (e.target.checked === true) {
      dispatch(updateOneToOne({ key: e.target.name, value: 1 }));
      dispatch(updateOneToOne({ key: "repeatWeekly", value: 1 }));
    } else {
      dispatch(updateOneToOne({ key: e.target.name, value: 0 }));
      dispatch(updateOneToOne({ key: "repeatWeekly", value: 0 }));
    }
  };

  return (
    <div className="accordion mb-3" id="accordionExample2">
      <div className={`accordion-item ${change ? "border-start-primary" : ""}`}>
        <h2 className="accordion-header" id="headingOne">
          <button
            style={{
              backgroundColor: "#f9fafb",
              cursor: "default",
              minHeight: "4.5rem",
            }}
            className="accordion-button px-md-4 px-2 no-icon collapsed"
            type="button"
            // data-bs-toggle="collapse"
            // data-bs-target="#collapse2"
            // aria-expanded="true"
            // aria-controls="collapseOne"
          >
            <div className="row w-100 align-items-center">
              <div className="col-6">
                <div className="d-flex align-items-center me-n5">
                  <img
                    className="me-3 pointer"
                    height={22}
                    src="/images/schedule/repeat.svg"
                    alt=""
                  />
                  <div>
                    <p className="fw-light mb-0 text-dark">Repeat Weekly</p>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-flex align-items-center justify-content-end">
                  <BootstrapTooltip placement="top" title="Enabling this feature will repeat your custom schedule weekly in the forward schedule">
                  <img
                    id="app-title"
                    className="me-3 pointer"
                    height={18}
                    src="/images/schedule/infosvg.svg"
                    alt=""
                  />
                  </BootstrapTooltip>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      onChange={handleToggleChange}
                      name="isWeekly"
                      checked={weekly}
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                </div>
              </div>
            </div>
          </button>
        </h2>
        {/* <div
          id="collapse2"
          className="accordion-collapse collapse collapsed"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample2"
        >
          <div className="accordion-body px-2 py-4">
            <div className="row w-100 m-auto px-0 rounded-5">
              <div className="d-flex align-items-center col-md-6">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Day Begin:
                </p>
                <input
                  style={{ height: 24, width: 62, fontSize: "15px" }}
                  type="time"
                  className="border-1 border-secondary rounded-5 align-items-center justify-content-center px-2 me-3"
                  placeholder="Select Time"
                  id="Text1"
                />
                <div
                  className="btn-group align-items-center"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    onClick={() => setMeridiem(true)}
                    style={{ fontSize: "15px" }}
                    type="button"
                    className={`btn btn-outline-secondary rounded-5 py-0 px-2 ${
                      meridiem
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>

                                    <button onClick={() => setMeridiem(false)} style={{ fontSize: '15px' }} type="button" className={`btn btn-outline-secondary rounded-5 py-0 px-2 ${!meridiem ? 'bg-secondary text-white' : 'bg-white text-secondary'}`}>PM</button>
                                </div>
                            </div>
                            <div className='d-flex align-items-center col-md-6 pe-4 justify-content-end'>
                                <p style={{ fontSize: '14px' }} className='mb-0 me-4 text-secondary'>Day Begin:</p>
                                <input style={{ height: 24, width: 62, fontSize: '15px' }}
                                    type="time"
                                    className="border-1 border-secondary rounded-5 align-items-center justify-content-center px-2 me-3"
                                    placeholder="Select Time"
                                    id="Text1"
                                />
                                <div style={{ width: 62 }} className="btn-group align-items-center" role="group" aria-label="Basic outlined example">
                                    <button style={{ fontSize: '15px' }} type="button" className="btn btn-outline-secondary rounded-5 py-0 px-2">AM</button>
                                    <button style={{ fontSize: '15px' }} type="button" className="btn btn-outline-secondary rounded-5 py-0 px-2">PM</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>*/}
      </div>
    </div>
  );
};

export default RepeatWeekAccordian;

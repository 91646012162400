import { Skeleton } from '@mui/material'
import React from 'react'

const TableLoading = () => {

    const borderStyle = { borderStyle: 'hidden' }

    return (
        <>
            <tbody className='table-border-bottom-0'>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]?.map((p) => (
                    <tr key={p}>
                        <th style={{ ...borderStyle, width: '20%' }} scope='row'>
                            <div className="d-flex m-2 align-items-center">
                                <Skeleton className='me-1' variant="circular" height={40} width={50} />
                                <div className='w-100'>
                                    <Skeleton animation='wave' height={15} />
                                    <Skeleton animation='wave' height={15} />
                                </div>
                            </div>
                        </th>
                        <td style={{ ...borderStyle }}>
                            <Skeleton className='m-2' animation='wave' height={20} />
                        </td>
                        <td style={{ ...borderStyle }}>
                            <Skeleton className='m-2' animation='wave' height={20} />
                        </td>
                        <td style={{ ...borderStyle }}>
                            <Skeleton className='m-2' animation='wave' height={20} />
                        </td>

                        <td className='w-25' style={{ ...borderStyle }}>
                            {' '}
                            {/* <span className='d-flex align-items-center justify-content-center ml-n1'> */}
                            <div className="d-flex align-items-center">
                                <Skeleton className='m-2' animation='wave' height={40} width={40} />
                                <Skeleton className='m-2' animation='wave' height={40} width={40} />
                                <Skeleton className='m-2' animation='wave' height={40} width={40} />
                                <Skeleton className='m-2' animation='wave' height={40} width={40} />
                                <Skeleton className='m-2' animation='wave' height={40} width={40} />
                                <Skeleton className='m-2' animation='wave' height={40} width={40} />
                            </div>
                            {/* <Skeleton animation='wave' />
                  <Skeleton animation='wave' /> */}
                            {/* </span> */}
                        </td>
                    </tr>
                ))}
            </tbody>
        </>
    )
}

export default TableLoading

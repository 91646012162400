import { IconButton } from "@mui/material";
import { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import MarkChatReadIcon from "@mui/icons-material/MarkChatRead";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../store";
import { Notification } from "../../../../utils/factory";
import { BootstrapTooltip } from "../../../common/BootstrapTooltip";
import {
  approveConnectionAction,
  deleteNotificationAction,
  markAsReadAction,
  markNotiAsRead,
} from "../../../../reducers/notificationReducer";
import ConfModal from "../../../common/ConfModal";
import DeletePrompt from "../../../common/DeletePrompt";
import { urlParameter } from "../../../common/types";
import { loggedInUserProfile } from "../../../../reducers/authReducer";

const Bar = ({ data }: any) => {
  // console.log("🚀 ~ file: Bar.tsx:28 ~ Bar ~ data:", data);
  const dispatch = useDispatch<AppDispatch>();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const authUser = useSelector(loggedInUserProfile);

  const noti: Notification = data;
  // const [expanded, setExpanded] = useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  const handleStatusUpdate = ({ status }) => {
    markAsRead();
    dispatch(
      approveConnectionAction({
        id: noti?.id,
        connectionId: noti?.data?.connectionId,
        status: status,
        notificationId: noti?.id,
      })
    );
  };

  const markAsRead = () => {
    dispatch(markNotiAsRead(noti?.id));
    const notiData = {
      modelId: authUser?.id,
      modelType: "provider",
      notificationId: noti?.id,
      isRead: 1,
    };
    dispatch(markAsReadAction(notiData));
  };

  return (
    <div
      style={{
        backgroundColor: noti.isRead
          ? noti?.data?.event === "approved"
            ? "#CBFCFF"
            : noti?.data?.event === "declined"
            ? "#E8AAA5"
            : noti?.data?.event === "waitlist"
            ? "#E3E8E8"
            : "white"
          : "#cdf1f4",
      }}
    >
      <div
        className={`notification pt-3 pb-4 mx-4 border-bottom ${
          !noti.isRead && "border-light"
        }`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="notification-title text-dark">
            {noti.notificationClass}
          </h3>
          {noti?.data?.event === "approved" ? (
            <button
              className="btn btn-secondary btn-sm"
              style={{
                background: "#04bac7",
                border: "1px solid #04bac7",
              }}
              disabled={true}
            >
              Approved
            </button>
          ) : // <BootstrapTooltip title={"Approved"} placement="top">
          //   <CheckCircleIcon
          //     fontSize="small"
          //     sx={{
          //       color: "#04bac7",
          //     }}
          //   />
          // </BootstrapTooltip>
          noti?.data?.event === "declined" ? (
            <button
              className="btn btn-secondary btn-sm"
              style={{
                background: "#f44336",
                border: "1px solid #f44336",
              }}
              disabled={true}
            >
              Declined
            </button>
          ) : // <BootstrapTooltip title={"Declined"} placement="top">
          //   <CancelIcon
          //     fontSize="small"
          //     sx={{
          //       color: "#f44336",
          //     }}
          //   />
          // </BootstrapTooltip>
          noti?.data?.event === "waitlist" ? (
            <button className="btn btn-secondary btn-sm" disabled={true}>
              On wait
            </button>
          ) : // <BootstrapTooltip title={"On wait"} placement="top">
          //   <WatchLaterIcon
          //     fontSize="small"
          //     sx={{
          //       color: "#3a4758",
          //     }}
          //   />
          // </BootstrapTooltip>
          null}
        </div>
        <div className="notification-body text-dark">
          <small className="mb-1">{noti.message}</small>
        </div>
        <span
          style={{ color: noti.isRead ? "#000" : "#3a4758" }}
          className="notification-time float-end d-block"
        >
          {noti.createdAt}
        </span>
        <div className="notification-buttons">
          {noti?.event !== "CONNECTION_APPROVAL" ? (
            <>
              <BootstrapTooltip title={"Delete Notification"} placement="top">
                <IconButton
                  onClick={() => handleOpen()}
                  aria-label="delete"
                  sx={{ color: "#f44336" }}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </BootstrapTooltip>
              <BootstrapTooltip
                title={noti?.isRead === 0 && "Mark as Read"}
                placement="top"
              >
                <IconButton
                  onClick={() => (noti?.isRead === 0 ? markAsRead() : null)}
                  aria-label="delete"
                  sx={{ color: noti?.isRead ? "rgb(0 0 0 / 40%)" : "#2196f3" }}
                >
                  <MarkChatReadIcon fontSize="small" />
                </IconButton>
              </BootstrapTooltip>
            </>
          ) : (
            <div className="d-flex ms-2">
              {!noti?.data?.event ? (
                <>
                  <button
                    onClick={() => handleStatusUpdate({ status: "declined" })}
                    className="btn btn-danger me-2 btn-sm"
                  >
                    Decline
                  </button>
                  <button
                    onClick={() => handleStatusUpdate({ status: "approved" })}
                    className="btn btn-primary me-2 btn-sm"
                  >
                    Approve
                  </button>
                  <button
                    onClick={() => handleStatusUpdate({ status: "waitlist" })}
                    className="btn btn-secondary btn-sm"
                  >
                    Waitlist
                  </button>
                </>
              ) : null}
            </div>
          )}
        </div>
      </div>
      <ConfModal handleClose={handleClose} open={open}>
        <DeletePrompt
          handleClose={handleClose}
          deleteFuntion={deleteNotificationAction(
            noti?.id as unknown as urlParameter
          )}
          textToShow="Deleting Notification"
        />
      </ConfModal>
    </div>
  );
};

export default Bar;

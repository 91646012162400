import { useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode, { JwtPayload } from "jwt-decode";

import App from "../App";
import { getAuth, removeAuth } from "../utils/AuthHelpers";

import { authSelector, loginUser } from "../reducers/authReducer";

import { AppDispatch } from "../store";
import NotFound from "../pages/error/NotFound";

// routes
import { PrivateRoutes } from "./PrivateRoutes";
import { AuthRoutes } from "./AuthRoutes";
import PublicRoutes from "./PublicRoutes";
import ProtectedRoute from "./components/ProtectedRoute";

const AppRoutes = () => {
  const authState = useSelector(authSelector);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const location = useLocation();

  function isJwtExpire(TOKEN: string) {
    const decoded: JwtPayload = jwt_decode(TOKEN);
    const tokenExpiration = decoded.exp * 1000; // convert seconds to milliseconds
    let currentDate = new Date();

    // JWT exp is in seconds
    if (tokenExpiration < currentDate.getTime()) {
      // if (tokenExpiration <= Date.now()) {
      removeAuth();
      navigate("auth/login");
      return;
    } else {
      // console.log("not expire ");
    }
  }

  useEffect(() => {
    const auth: any = getAuth();
    if (auth) {
      isJwtExpire(auth?.access_token);
      if (!authState.isLoggedIn) {
        if (location.pathname.includes("auth")) {
          navigate("/", { replace: true });
        }
        dispatch(loginUser(auth));
      }
    } else {
      navigate("auth/login", {
        replace: true,
        state:
          location.pathname === "/auth/login"
            ? null
            : {
                from: location.pathname,
              },
      });
    }
    return () => {};
  }, [authState.isLoggedIn]);

  return (
    <Routes>
      <Route element={<App />}>
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <PrivateRoutes />
            </ProtectedRoute>
          }
        />
        <Route path="public/*" element={<PublicRoutes />} />
        <Route path="auth/*" element={<AuthRoutes />} />
        {/* {!redirect && !authState.isLoggedIn ? (
          <Route path="*" element={<Navigate to="/auth/login" />} />
        ) : (
          <Route path="*" element={<Navigate to={redirect} />} />
        )} */}
        <Route path="error/*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};

export { AppRoutes };

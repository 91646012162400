import Typography from "@mui/material/Typography";
import { newServerType } from "../type";
import { useDispatch, useSelector } from "react-redux";
import {
  createThirdLevelChildServices,
  secondlevelIdSelector,
} from "../../../../reducers/serviceReducer";
import { AppDispatch, RootState } from "../../../../store";
import { serverInitialState } from "../initialStates";
import { CURRENCY, MINUTE } from "../../../../config/constant";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";
import OverlayLoading from "../../../../components/common/OverlayLoading";
import TextArea from "../../../../components/common/Textarea";
import { handleKeyDown } from "../../../../utils";

export const CreateModal = ({ handleClose }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { createServiceStatus } = useSelector(
    (state: RootState) => state.service
  );
  const secondLevelId = useSelector(secondlevelIdSelector);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Service Name is required"),
    duration: yup
      .number()
      .min(1, "Value must be greater than 0")
      .max(999, "Value must be less than 1000")
      .required("Duration is required"),
    price: yup
      .number()
      .positive("Value must be greater than 0")
      .max(9999, "Value must be less than 10,000")
      .required("Price is required"),
    description: yup
      .string()
      .max(255, "Max 255 characters allowed")
      .required("Description is required"),
  });

  return (
    <>
      <Typography
        id="modal-modal-title"
        sx={{
          fontSize: "18px",
          fontWeight: "530",
        }}
        component="h6"
      >
        Create Service
      </Typography>
      <div className="form">
        <Formik
          initialValues={serverInitialState}
          validationSchema={validationSchema}
          onSubmit={(values: newServerType) => {
            const newFinalData = {
              ...values,
              level: "3",
              parentId: secondLevelId,
            };

            dispatch(
              createThirdLevelChildServices({
                services: newFinalData,
                parentId: secondLevelId,
                handleClose,
              })
            );
            // console.log("values", newFinalData);
          }}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              <div className="form-group mt-3">
                <label
                  style={{ fontSize: "14px" }}
                  htmlFor="servicename"
                  className="form-label"
                >
                  Service Name
                </label>
                <Field
                  type="text"
                  className={`form-control ${
                    errors.name && touched.name ? "is-invalid" : ""
                  }`}
                  id="servicename"
                  placeholder="Service name"
                  name="name"
                  onChange={handleChange}
                />
                <ErrorMessage
                  name="name"
                  component="span"
                  className="invalid-feedback"
                />
              </div>
              <div className="row mt-2">
                <div className="col-md-6 form-group">
                  <label
                    style={{ fontSize: "14px" }}
                    htmlFor="duration"
                    className="form-label"
                  >
                    Duration
                  </label>
                  <div className="input-group">
                    <Field
                      name="duration"
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        if (e.target.value.length > 3) {
                          return;
                        }
                        handleChange(e);
                      }}
                      type="number"
                      className={`form-control border-end-0 ${
                        errors.duration && touched.duration
                          ? "is-invalid border-end-dangerC"
                          : ""
                      }`}
                      id="duration"
                      placeholder="Duration"
                    />
                    <ErrorMessage
                      name="duration"
                      component="span"
                      className="invalid-feedback"
                    />
                    {errors.duration && touched.duration ? null : (
                      <span
                        className="input-group-text px-md-2 px-1"
                        id="basic-addon1"
                      >
                        {MINUTE}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-md-6 form-group mt-3 mt-md-0">
                  <label
                    style={{ fontSize: "14px" }}
                    htmlFor="price"
                    className="form-label"
                  >
                    Price
                  </label>
                  <div className="input-group">
                    <span
                      className="input-group-text px-md-2 px-1"
                      id="basic-addon1"
                    >
                      {CURRENCY}
                    </span>
                    <Field
                      name="price"
                      onKeyDown={handleKeyDown}
                      onChange={(e) => {
                        if (e.target.value.length > 4) {
                          return;
                        }
                        handleChange(e);
                      }}
                      type="number"
                      className={`form-control border-start-0 ${
                        errors.price && touched.price ? "is-invalid" : ""
                      }`}
                      id="price"
                      placeholder="Price"
                    />
                    <ErrorMessage
                      name="price"
                      component="span"
                      className="invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group mt-3">
                <label
                  style={{ fontSize: "14px" }}
                  htmlFor="description"
                  className="form-label"
                >
                  Description
                </label>
                {/* <Field
                  as="textarea"
                  className={`form-control ${
                    errors.description && touched.description
                      ? "is-invalid"
                      : ""
                  }`}
                  name="description"
                  style={{ resize: "none" }}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  id="description"
                  placeholder="Description"
                  rows={2}
                /> */}
                <TextArea
                  handleChange={(e) => {
                    handleChange(e);
                  }}
                  errors={errors?.description}
                  touched={touched?.description}
                  name={"description"}
                  value={values?.description}
                  toggle={null}
                  placeholder={"Description"}
                />
              </div>
              <div className="row mt-2">
                <div className="col-6">
                  <button
                    type="reset"
                    onClick={handleClose}
                    className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  <button
                    style={{ backgroundColor: "#04BAC7" }}
                    className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <OverlayLoading
          active={createServiceStatus == "loading" ? true : false}
        />
      </div>
    </>
  );
};

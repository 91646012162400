import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { updateVacations } from "../../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../../store";
import { vacationTypes } from "../../../type";

const CreateVacation = ({ updateForm, setForm }) => {
  var date = new Date();
  var day: any = date.getDate();
  var month: any = date.getMonth() + 1;
  var year: any = date.getFullYear();
  var today = year + "-" + month + "-" + day;

  // console.log(today);

  const handleOnChange = (e: any) => {
    setForm({ ...updateForm, [e.target.name]: e.target.value });
  };

  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);
  const [locError, setLocError] = useState(false);

  const check = () => {
    if (updateForm.startDateTime == "") {
      document.getElementById("startError").style.color = "red";
      document.getElementById("startError").innerHTML = "Day Begin is required";
      setStartError(true);
    } else if (updateForm.startDateTime >= today) {
      document.getElementById("dateError").style.color = "red";
      document.getElementById("dateError").innerHTML =
        "Day Begin must be equal to or greater than today's date ";
      setStartError(true);
    } else if (updateForm.endDateTime == "") {
      document.getElementById("endError").style.color = "red";
      document.getElementById("endError").innerHTML = "Day End is required";
      setEndError(true);
    } else if (updateForm.location == "") {
      document.getElementById("locationError").style.color = "red";
      document.getElementById("locationError").innerHTML =
        "Location is required";
      setLocError(true);
    }
  };

  const hide = () => {
    if (updateForm.startDateTime == "") {
      document.getElementById("startError").innerHTML = "";
      setStartError(false);
    } else if (updateForm.startDateTime <= today) {
      document.getElementById("dateError").innerHTML = "";
      setStartError(false);
    } else if (updateForm.endDateTime == "") {
      document.getElementById("endError").innerHTML = "";
      setEndError(false);
    }
    document.getElementById("locationError").innerHTML = "";
    setLocError(false);
  };

  const disablePastDt = () => {
    var date = new Date();
    var day: any = date.getDate();
    var month: any = date.getMonth() + 1;
    var year: any = date.getFullYear();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var today = year + "-" + month + "-" + day;
    document.getElementById("startDateTime").setAttribute("min", today);
  };

  useEffect(() => {
    disablePastDt();
    return () => {};
  }, []);

  return (
    <div className="px-0 pb-5 pt-4">
      {/* start time */}
      <div className="row w-100 m-auto px-0 mx-0 rounded-5 align-items-end">
        <div className="col-md-4 ps-0">
          <label
            style={{ fontSize: "14px" }}
            className="mb-0 me-2 text-secondary"
          >
            Day Begin:
          </label>
          <input
            onChange={(e) => {
              handleOnChange(e);
            }}
            style={{ fontSize: "15px" }}
            type="date"
            value={updateForm?.startDateTime}
            onBlur={check}
            onFocus={(e) => {
              hide();
            }}
            className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control ${
              startError ? "border-danger" : ""
            }`}
            placeholder="Enter day begin Data/Time"
            id="startDateTime"
            name="startDateTime"
          />
          <small className="mb-0 position-absolute" id="startError"></small>
          <small className="mb-0 position-absolute" id="dateError"></small>
        </div>
        <div className="col-md-4 ps-0 my-xl-0 my-lg-1 my-1 mx-xl-0 mx-lg-1">
          <label
            style={{ fontSize: "14px" }}
            className="mb-0 me-2 text-secondary"
          >
            Day End:
          </label>
          <input
            style={{ fontSize: "15px" }}
            type="date"
            onChange={(e) => {
              if (e.target.value <= updateForm.startDateTime) {
                setEndError(true);
                document.getElementById("endError").style.color = "red";
                document.getElementById("endError").innerHTML =
                  "Day End must be greater than Day Begin";
              } else {
                setEndError(false);
                document.getElementById("endError").innerHTML = "";
              }
              handleOnChange(e);
            }}
            onBlur={(e) => {
              check();
            }}
            onFocus={(e) => {
              hide();
            }}
            min={updateForm.startDateTime}
            className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control ${
              endError ? "border-danger" : ""
            }`}
            placeholder="Enter day end Data/Time"
            value={updateForm?.endDateTime}
            id="endDateTime"
            name="endDateTime"
          />
          <small className="mb-0 position-absolute" id="endError"></small>
          <small className="mb-0 position-absolute" id="date"></small>
        </div>
        <div className="col-md-4 px-0 my-xl-0 my-lg-1 my-1 mx-xl-0 mx-lg-1">
          <label
            htmlFor="location"
            style={{ fontSize: "14px" }}
            className="mb-0 me-4 text-secondary"
          >
            Location
          </label>
          <input
            style={{ fontSize: "15px" }}
            type="text"
            value={updateForm?.location}
            onChange={handleOnChange}
            name="location"
            onBlur={(e) => {
              check();
            }}
            onFocus={(e) => {
              hide();
            }}
            className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control ${
              locError ? "is-invalid" : ""
            }`}
            placeholder="Enter location"
            id="location"
          />
          <small className="mb-0 position-absolute" id="locationError"></small>
        </div>
      </div>
    </div>
  );
};

export default CreateVacation;

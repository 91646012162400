import { ErrorMessage, Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  PaymentRecord,
  providerBoardingAction,
} from "../../../../reducers/paymentReducer";
import { AppDispatch } from "../../../../store";
import * as yup from "yup";
import FormField from "../../../../components/UI/FormField";
import { countries, states } from "../../../../config/constant";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";
import { payStep4 } from "../../../../components/common/types";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";

interface Options {
  label: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

const PayStep4 = ({
  setChangeStep,
  data,
  handleDataChange,
  handleCheckChange,
}: {
  setChangeStep(string): void;
  data;
  handleDataChange;
  handleCheckChange;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const [request, setRequest] = React.useState(false);
  const record = useSelector(PaymentRecord);

  const initialState: payStep4 = {
    singerName: data?.singerName,
    singerSSS: data?.singerSSS,
    singerDob: data?.singerDob,
    singerPhone: data?.singerPhone,
    singerEmail: data?.singerEmail,
    singerAddress: data?.singerAddress,
    singerState: data?.singerState,
    singerCity: data?.singerCity,
    singerZip: data?.singerZip,
  };

  const validationSchema = yup.object().shape({
    singerName: yup.string().required("Required").nullable(),
    singerSSS: yup.string().required("Required").nullable(),
    singerDob: yup.string().required("Required").nullable(),
    singerPhone: yup.string().required("Required").nullable(),
    singerEmail: yup.string().required("Required").nullable(),
    singerAddress: yup.string().required("Required").nullable(),
    singerState: yup.string().required("Required").nullable(),
    singerCity: yup.string().required("Required").nullable(),
    singerZip: yup.string().required("Required").nullable(),
  });

  const [options, setOptions] = React.useState<Options[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAddressChange = async (e) => {
    setLoading(true);
    if (e.target.value.length > 3) {
      const res: any = await axios.post(
        "https://api.postgrid.com/v1/addver/completions",
        {
          partialStreet: e.target.value,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_POSTGRID_API_KEY,
          },
        }
      );
      const data = res.data.data;
      if (data?.length !== 0) {
        setOptions(
          data.map((m) => {
            return {
              label:
                m?.address?.address +
                " " +
                m?.address?.city +
                " " +
                m?.address?.prov +
                ", " +
                m?.address?.pc,
              street: m?.address?.address,
              city: m?.address?.city,
              state: m?.address?.prov,
              zip: m?.address?.pc,
            };
          })
        );
      } else {
        setLoading(false);
        setOptions([]);
      }
    }
  };

  function onchange(e) {
    setRequest(true);
    handleDataChange(e);
  }

  return (
    <div style={{ maxWidth: "700px" }} className="my-4 mx-auto">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 px-0 text-center"
      >
        <div className="d-flex justify-content-between px-4 py-3 border-bottom">
          <div>
            <h5 className="mb-0">Payments</h5>
          </div>
          <p className="text-primary py-1 px-3 mb-0">Step 3/3</p>
        </div>
        <div className="form text-start pb-3 px-4">
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const finalData = {
                ...data,
                submitForApproval: 1,
                id: record?.id,
              };
              dispatch(
                providerBoardingAction({
                  data: finalData,
                  setChangeStep,
                  step: 4,
                })
              );
            }}
          >
            {(formikHandler) => (
              <Form>
                <div className="row border rounded mt-4 pb-3">
                  <h6
                    style={{ fontSize: "1rem" }}
                    className="bg-light w-100 mt-0 py-3 text-dark fw-light"
                  >
                    Signer
                  </h6>
                  <FormField
                    className="col-12"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="text"
                    fieldName={{
                      label: "Name Accepted",
                      prop: "singerName",
                      placeHolder: "Name Accepted",
                    }}
                  />
                  <FormField
                    className="col-sm-6 col-12 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="text"
                    fieldName={{
                      label: "SSN",
                      prop: "singerSSS",
                      placeHolder: "Signature SSN",
                    }}
                    tooltip="Social Security Number"
                  />
                  <FormField
                    className="col-sm-6 col-12 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="date"
                    fieldName={{
                      label: "DOB",
                      prop: "singerDob",
                      placeHolder: "Signature Dob",
                    }}
                    tooltip="Date of birth"
                  />
                  <FormField
                    className="col-sm-6 col-12 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="tel"
                    fieldName={{
                      label: "Phone",
                      prop: "singerPhone",
                      placeHolder: "Signature Phone",
                    }}
                  />
                  <FormField
                    className="col-sm-6 col-12 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="email"
                    fieldName={{
                      label: "Email",
                      prop: "singerEmail",
                      placeHolder: "Signature Email",
                    }}
                  />
                  <div className="col mt-3">
                    <Autocomplete
                      id="asynchronous-demo"
                      freeSolo
                      isOptionEqualToValue={(option, value) => {
                        return option.street === value.street;
                      }}
                      getOptionLabel={(option: any) => option.label}
                      options={options}
                      open={open}
                      sx={{ width: "100%" }}
                      onChange={(e, value) => {
                        const data: any = value;
                        formikHandler.handleChange({
                          target: {
                            name: "singerAddress",
                            value: data?.street,
                          },
                        });
                        formikHandler.handleChange({
                          target: {
                            name: "singerCity",
                            value: data?.city,
                          },
                        });
                        formikHandler.handleChange({
                          target: {
                            name: "singerState",
                            value: data?.state,
                          },
                        });
                        formikHandler.handleChange({
                          target: {
                            name: "singerZip",
                            value: data?.zip,
                          },
                        });
                      }}
                      onError={(e) => {
                        console.log(e);
                        setOptions([]);
                      }}
                      onBlur={(e) => {
                        setOptions([]);
                        setLoading(false);
                      }}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      filterOptions={(x) => x}
                      loading={loading}
                      noOptionsText="No Address Found"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter Your Address"
                          // className="autocomplete"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "no",
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "1px solid #ced4da",
                              },
                              "&:hover fieldset": {
                                borderColor: "#ced4da",
                              },
                              "&.Mui-focused fieldset": {
                                border: "1px solid #ced4da",
                                boxShadow: "0 0 0 0.25rem #cdf1f4",
                              },
                            },
                          }}
                          size="small"
                          onChange={(e) => {
                            handleAddressChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                  <FormField
                    className="col-12 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="textarea"
                    fieldName={{
                      label: "Address",
                      prop: "singerAddress",
                      placeHolder: "Signature Address",
                    }}
                  />
                  <FormField
                    className="col-sm-4 col-12 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="text"
                    fieldName={{
                      label: "State",
                      prop: "singerState",
                      placeHolder: "State here",
                    }}
                  />
                  <FormField
                    className="col-sm-4 col-6 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="text"
                    fieldName={{
                      label: "City",
                      prop: "singerCity",
                      placeHolder: "Signature City",
                    }}
                  />
                  <FormField
                    className="col-sm-4 col-6 mt-3"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="text"
                    fieldName={{
                      label: "Zip",
                      prop: "singerZip",
                      placeHolder: "Signature Zip",
                    }}
                  />
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => setChangeStep("ToPay2")}
                    className="btn btn-outline-secondary border rounded rounded-3 mt-4 px-5 mx-2"
                  >
                    Back
                  </button>
                  <div className="d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary rounded rounded-3 mt-4 px-5"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default PayStep4;

import React, { useState } from "react";
import ModalC from "../../../../components/common/Modal";
import { AddOwnership } from "./AddOwnerShip";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Tooltip as ReactTooltip } from "react-tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";

const AddOwnerShipTable = ({ handleClose, ownerShip, onChange }) => {
  const [openOwnershipModal, setOpenOwnershipModal] = useState(false);
  const handleOpenOwnershipModal = () => setOpenOwnershipModal(true);
  const handleCloseOwnershipModal = () => setOpenOwnershipModal(false);
  return (
    <>
      <div className="d-flex justify-content-between">
        <Typography
          className="text-dark fw-bold mb-3"
          id="modal-modal-title"
          sx={{
            fontSize: "18px",
            fontWeight: "550",
          }}
          component="h6"
        >
          Ownership
        </Typography>
        <Typography
          className="text-dark pointer"
          id="modal-modal-title"
          sx={{
            fontSize: "18px",
            fontWeight: "550",
          }}
          component="h6"
        >
          <BootstrapTooltip title={"Add new ownership"} placement="top">
            <AddCircleIcon
              sx={{ color: "grey" }}
              onClick={handleOpenOwnershipModal}
            />
          </BootstrapTooltip>
        </Typography>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead className="border-top">
            <TableRow>
              <TableCell>Owner Name</TableCell>
              <TableCell align="left">Owner Title</TableCell>
              <TableCell align="left">Owner SSN</TableCell>
              <TableCell align="left">Owner DOB</TableCell>
              <TableCell align="left">Owner Phone 1</TableCell>
              <TableCell align="left">Owner Mail</TableCell>
              <TableCell align="left">Owner Address</TableCell>
              <TableCell align="left">Owner State</TableCell>
              <TableCell align="left">Owner Country</TableCell>
              <TableCell align="left">Owner City</TableCell>
              <TableCell align="left">Owner Zip</TableCell>
              <TableCell align="left">Owner Percentage</TableCell>
              {/* <TableCell align="left">Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {ownerShip?.ownership?.map((row, i) => (
              <TableRow
                key={i}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {row?.ownerName}
                </TableCell>
                <TableCell align="left">{row?.ownerTitle}</TableCell>
                <TableCell align="left">{row?.ownerSSN}</TableCell>
                <TableCell align="left">{row?.ownerDob}</TableCell>
                <TableCell align="left">{row?.ownerPhone1}</TableCell>
                <TableCell align="left">{row?.ownerMail}</TableCell>
                <TableCell align="left">{row?.ownerAddress}</TableCell>
                <TableCell align="left">{row?.ownerState}</TableCell>
                <TableCell align="left">{row?.ownerCountry}</TableCell>
                <TableCell align="left">{row?.ownerCity}</TableCell>
                <TableCell align="left">{row?.ownerZip}</TableCell>
                <TableCell align="left">{row?.ownerPercentage}</TableCell>
                {/* <TableCell align="left">
                  <button style={{ fontSize: '14px' }} className="btn btn-primary me-2">
                                    Edit</button>
                  <BootstrapTooltip title={"Delete Ownership"} placement="top">
                    <DeleteIcon
                      // onClick={() => {
                      //   handleOpen();
                      //   setId(row?.id);
                      // }}
                      className="pointer res-margin mb-md-0 mb-2"
                      sx={{
                        color: "grey",
                      }}
                    />
                  </BootstrapTooltip>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <ModalC handleClose={handleCloseOwnershipModal} open={openOwnershipModal}>
        <AddOwnership
          ownerShip={ownerShip}
          onChange={onChange}
          handleClose={handleCloseOwnershipModal}
        />
      </ModalC>
    </>
  );
};

export default AddOwnerShipTable;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllSchedule,
  scheduleOnToOneSelector,
  scheduleMultiToMultiSelector,
  updateSchedule,
  updateStatus,
  fetchVacationSchedule,
  createSchedule,
  updateVacations,
  scheduleOnToOneReadOnlySelector,
  scheduleStatusUpdateAction,
  allMultiToMultiSelector,
  resetState,
  scheduleMultiToMultiReadOnlySelector,
} from "../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../store";
import OneToOneSchedule from "./components/oneTooneSchedule/OneToOneSchedule";
import MultiSchedule from "./components/multiSchedule/Index";
import Vacation from "./components/vacationSchedule";
// import { xconsole } from "../../../utils/console";
import { vacationTypes } from "./type";
import moment from "moment";
import OverlayLoading from "../../../components/common/OverlayLoading";
import useWindowSize from "../../../hooks/useWindowSize";
import { addMinutesToTime } from "../../../utils";

// OTO -  one to one
// MTM -  multi to multi
// TF  -  time off

const Schedule = () => {
  const { width } = useWindowSize();
  const [tab, setTab] = useState("OTO");
  const handleTabChange = (t: string) => setTab(t);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchAllSchedule());
    return () => {
      dispatch(resetState());
    };
  }, []);

  const [change, setChange] = useState({
    days: [],
    services: false,
    break: false,
    doubleBooking: true,
    fee: false,
    buffer: false,
    peak: false,
  });

  const error = [];

  const multiErrors = [];

  const oneToOneData: any = useSelector(scheduleOnToOneSelector);
  const oneToOneReadOnly = useSelector(scheduleOnToOneReadOnlySelector);
  const multiToMultiData = useSelector(scheduleMultiToMultiSelector);
  const multiToMultiReadOnly = useSelector(
    scheduleMultiToMultiReadOnlySelector
  );
  const allMultiToMultiData = useSelector(allMultiToMultiSelector);
  const status = useSelector(updateStatus);

  const fullStartTime = moment(oneToOneData?.startTime, "hh:mm A")
    .add(60, "minutes")
    .format("HH:mm");
  const fullEndTime = moment(oneToOneData?.endTime, "hh:mm A").format("HH:mm");

  useEffect(() => {
    if (fullStartTime > fullEndTime) {
      error.push(false);
    } else {
      error.push(true);
    }

    if (oneToOneData?.servicesIds?.length < 1) {
      error.push(false);
    } else {
      error.push(true);
    }

    if (oneToOneData?.isBreak === 1) {
      if (oneToOneData?.breakTimes?.length === 0) {
        error.push(false);
      } else if (oneToOneData?.breakTimes?.length !== 0) {
        oneToOneData?.breakTimes?.map(({ breakStartTime, breakEndTime }) => {
          if (breakStartTime && breakEndTime) {
            const fullTime = moment(breakStartTime, "hh:mm A")
              .add(15, "minutes")
              .format("HH:mm");
            const fullETime = moment(breakEndTime, "hh:mm A").format("HH:mm");
            if (fullTime <= fullETime) {
              error.push(true);
            } else {
              error.push(false);
            }
            // console.log(error);
          }
        });
      } else {
        error.push(true);
      }
    } else {
      error.push(true);
    }

    if (oneToOneData?.isAllowDoubleBooking === 1) {
      if (
        oneToOneData?.allServices === 0 ||
        oneToOneData?.overlapingTime === "" ||
        oneToOneData?.overlapingTime === "0" ||
        oneToOneData?.overlapingTime === "00" ||
        oneToOneData?.overlapingTime === "000" ||
        oneToOneData?.overlapingTime === null
      ) {
        if (
          oneToOneData?.doubleBookingServicesIds?.length === 0 ||
          oneToOneData?.overlapingTime === "" ||
          oneToOneData?.overlapingTime === "0" ||
          oneToOneData?.overlapingTime === "00" ||
          oneToOneData?.overlapingTime === "000" ||
          oneToOneData?.overlapingTime === null
        ) {
          error.push(false);
        } else {
          error.push(true);
        }
      }
    } else {
      error.push(true);
    }

    if (oneToOneData?.isReservationFee === 1) {
      if (
        oneToOneData?.reservationFee === "" ||
        oneToOneData?.reservationFee === "0" ||
        oneToOneData?.reservationFee === "00" ||
        oneToOneData?.reservationFee === "000" ||
        oneToOneData?.reservationFee === "0000" ||
        oneToOneData?.reservationFee === null
      ) {
        error.push(false);
      } else {
        error.push(true);
      }
    } else {
      error.push(true);
    }

    if (oneToOneData?.isLastMinutesBooking === 1) {
      if (
        oneToOneData?.allowBookingBefore === "" ||
        oneToOneData?.allowBookingBefore === "0" ||
        oneToOneData?.allowBookingBefore === "00" ||
        oneToOneData?.allowBookingBefore === "000" ||
        oneToOneData?.allowBookingBefore === null
        // ||
        // oneToOneData?.afterAppointmentBuffer === null ||
        // oneToOneData?.afterAppointmentBuffer === "" ||
        // oneToOneData?.afterAppointmentBuffer === "0" ||
        // oneToOneData?.afterAppointmentBuffer === "00" ||
        // oneToOneData?.afterAppointmentBuffer === "000"
      ) {
        error.push(false);
      } else {
        error.push(true);
      }
    } else {
      error.push(true);
    }

    if (oneToOneData?.isPeakPricing) {
      if (
        oneToOneData?.monthlySchedulePercentage === "" ||
        oneToOneData?.monthlySchedulePercentage === "0" ||
        oneToOneData?.monthlySchedulePercentage === "00" ||
        oneToOneData?.monthlySchedulePercentage === "000" ||
        oneToOneData?.monthlySchedulePercentage === null ||
        oneToOneData?.increaseRateBy === "" ||
        oneToOneData?.increaseRateBy === "0" ||
        oneToOneData?.increaseRateBy === "00" ||
        oneToOneData?.increaseRateBy === "000" ||
        oneToOneData?.increaseRateBy === null
      ) {
        error.push(false);
      } else {
        error.push(true);
      }
    } else {
      error.push(true);
    }
    // console.log("error =>", error);
  }, [error, oneToOneData?.servicesIds?.length]);

  useEffect(() => {
    if (multiToMultiData?.days?.length === 0) {
      multiErrors.push(false);
    } else {
      multiErrors.push(true);
    }

    if (
      multiToMultiData?.servicesIds?.length === 0 ||
      multiToMultiData?.noOfParticipants === "" ||
      multiToMultiData?.noOfParticipants === "0" ||
      multiToMultiData?.noOfParticipants === null ||
      multiToMultiData?.serviceLocation === "" ||
      multiToMultiData?.serviceLocation === null
    ) {
      multiErrors.push(false);
    } else {
      multiErrors.push(true);
    }

    if (multiToMultiData?.isBreak === 1) {
      if (multiToMultiData?.breakTimes?.length === 0) {
        multiErrors.push(false);
      }
    } else if (multiToMultiData?.breakTimes?.length !== 0) {
      multiToMultiData?.breakTimes?.map(({ breakStartTime, breakEndTime }) => {
        if (breakStartTime && breakEndTime) {
          const fullTime = moment(breakStartTime, "hh:mm A")
            .add(15, "minutes")
            .format("HH:mm");
          const fullETime = moment(breakEndTime, "hh:mm A").format("HH:mm");
          if (fullTime <= fullETime) {
            error.push(true);
          } else {
            error.push(false);
          }
          // console.log(error);
        }
      });
    } else {
      error.push(true);
    }

    if (multiToMultiData?.isReservationFee === 1) {
      if (
        multiToMultiData?.reservationFee === "" ||
        multiToMultiData?.reservationFee === "0" ||
        multiToMultiData?.reservationFee === "00" ||
        multiToMultiData?.reservationFee === "000" ||
        multiToMultiData?.reservationFee === "0000" ||
        multiToMultiData?.reservationFee === null
      ) {
        multiErrors.push(false);
      } else {
        multiErrors.push(true);
      }
    }

    if (multiToMultiData?.isLastMinutesBooking === 1) {
      if (
        multiToMultiData?.allowBookingBefore === "" ||
        multiToMultiData?.allowBookingBefore === "0" ||
        multiToMultiData?.allowBookingBefore === "00" ||
        multiToMultiData?.allowBookingBefore === "000" ||
        multiToMultiData?.allowBookingBefore === null
        // ||
        // multiToMultiData?.afterAppointmentBuffer === null ||
        // multiToMultiData?.afterAppointmentBuffer === "" ||
        // multiToMultiData?.afterAppointmentBuffer === "0" ||
        // multiToMultiData?.afterAppointmentBuffer === "00" ||
        // multiToMultiData?.afterAppointmentBuffer === "000"
      ) {
        multiErrors.push(false);
      } else {
        multiErrors.push(true);
      }
    }

    if (multiToMultiData?.isPeakPricing === 1) {
      if (
        multiToMultiData?.monthlySchedulePercentage === "" ||
        multiToMultiData?.monthlySchedulePercentage === "0" ||
        multiToMultiData?.monthlySchedulePercentage === "00" ||
        multiToMultiData?.monthlySchedulePercentage === "000" ||
        multiToMultiData?.monthlySchedulePercentage === null ||
        multiToMultiData?.increaseRateBy === "" ||
        multiToMultiData?.increaseRateBy === "0" ||
        multiToMultiData?.increaseRateBy === "00" ||
        multiToMultiData?.increaseRateBy === "000" ||
        multiToMultiData?.increaseRateBy === null
      ) {
        multiErrors.push(false);
      } else {
        multiErrors.push(true);
      }
    }

    return () => {};
  }, [multiErrors, multiToMultiData?.days?.length]);

  useEffect(() => {
    if (multiToMultiReadOnly?.isActive === 1) {
      handleTabChange("MTM");
    }
  }, [multiToMultiReadOnly?.isActive]);

  const handleSubmit = () => {
    if (tab === "OTO") {
      if (oneToOneReadOnly.days.length === 0) {
        if (!error.includes(false)) {
          dispatch(createSchedule({ data: oneToOneData, setChange }));
        }
      } else {
        if (!error.includes(false)) {
          const data = {
            ...oneToOneData,
            serviceLocation: oneToOneData?.servicesLocation,
          };

          dispatch(updateSchedule({ data: data, setChange }));
        }
      }
    }
    // console.log("progress =>", error);
    if (tab === "MTM") {
      if (!multiErrors?.includes(false)) {
        const finalData = {
          ...multiToMultiData,
          endTime: addMinutesToTime(
            multiToMultiData.startTime,
            parseInt(multiToMultiData.serviceDuration)
          ),
        };
        // console.log("data", finalData);

        dispatch(createSchedule({ data: finalData }));
      }
    }

    if (tab === "TF") {
      // dispatch(updateSchedule(oneToOneData));
    }

    // console.log("update data =>", updateData);
  };

  const [newVacation, setNewVacation] = useState<vacationTypes>({
    startDateTime: "",
    endDateTime: "",
    location: "",
  });

  var today = new Date().toISOString().slice(0, 16);
  var date = today.split("T")[0];

  const handleVacationSubmit = () => {
    if (
      newVacation.startDateTime === "" ||
      newVacation.endDateTime === "" ||
      newVacation.startDateTime < date ||
      newVacation.endDateTime === newVacation.startDateTime ||
      newVacation.endDateTime < newVacation.startDateTime ||
      newVacation.location === ""
    ) {
      return;
    } else {
      dispatch(updateVacations({ data: newVacation, setNewVacation }));
    }
  };

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: width >= 992 ? "70vh" : "auto" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="d-flex justify-content-between ps-2 pe-3 py-3">
          <div>
            <h5 className="mb-0 main-head ms-3">Schedule</h5>
          </div>

          {tab === "TF" ? (
            <button
              onClick={handleVacationSubmit}
              className="btn btn-primary py-1 px-3"
            >
              Save
            </button>
          ) : (
            <button
              onClick={handleSubmit}
              className="btn btn-primary py-1 px-3"
            >
              Save
            </button>
          )}
        </div>
        {status === "success" ? (
          <div className="row w-100 m-auto">
            {/* <div className="col-lg-8 col-xxl-9 offset-xxl-3 offset-lg-4">
              <Expire delay="3000">
                <Alert
                  className="d-flex justify-content-center ms-4 me-5 mb-2"
                  severity="success"
                >
                  {/* <AlertTitle>Success</AlertTitle> */}
            {/* <h6 className="text-center my-0"> */}
            {/* Updated <strong>Successfully !</strong> */}
            {/* </h6> */}
            {/* </Alert> */}
            {/* </Expire> */}
            {/* </div> */}
          </div>
        ) : (
          <></>
        )}
        <div className="row w-100 m-auto">
          <div
            style={{ minHeight: width >= 992 ? "70vh" : "auto" }}
            className="col-lg-4 col-xxl-3 border-end px-0"
          >
            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className={`nav-link p-0 ${
                  oneToOneData?.isActive === 1 ? "active" : ""
                }`}
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
                onClick={() => {
                  handleTabChange("OTO");
                  dispatch(
                    scheduleStatusUpdateAction({ type: "single-to-single" })
                  );
                  // xconsole.test("active status single-to-single");
                  // dispatch(activeStatusSchedule("single-to-single"));
                }}
              >
                <div className="d-flex border-top border-bottom ps-3 pe-2 py-3">
                  <img
                    className="me-2 pointer"
                    height={17}
                    src="/images/schedule/oneToOne.png"
                    alt=""
                  />
                  <div className="text-start">
                    <h6 style={{ fontSize: "14px" }} className="mb-1 text-dark">
                      One-to-One Service Schedule
                    </h6>
                    <p
                      style={{ fontSize: "12px", opacity: 0.75 }}
                      className="my-0 text-secondary"
                    >
                      Choose this schedule build if you provide 1 to 1 client
                      services. ie: Consultations, House Keeping, Nails, etc.
                    </p>
                  </div>

                  <div className="align-self-center">
                    <img
                      className="ms-2 pointer"
                      height={14}
                      src="/images/schedule/dis-forward.png"
                      alt=""
                    />
                  </div>
                </div>
              </button>
              <button
                className={`nav-link p-0 ${
                  multiToMultiReadOnly?.isActive === 1 ? "active" : ""
                }`}
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
                onClick={() => {
                  handleTabChange("MTM");
                  if (allMultiToMultiData?.length > 0) {
                    dispatch(
                      scheduleStatusUpdateAction({ type: "multi-to-multi" })
                    );
                  }
                  // xconsole.test("active status multi-to-multi");
                  // dispatch(activeStatusSchedule("multi-to-multi"));
                }}
              >
                <div className="d-flex border-top border-bottom ps-3 pe-2 py-3">
                  <img
                    className="me-2 pointer"
                    height={17}
                    src="/images/schedule/multi.png"
                    alt=""
                  />
                  <div className="text-start">
                    <h6 style={{ fontSize: "14px" }} className="mb-1 text-dark">
                      Multi-Attendee Service Schedule
                    </h6>
                    <p
                      style={{ fontSize: "12px", opacity: 0.75 }}
                      className="my-0 text-secondary"
                    >
                      Choose this schedule build if you provide services to
                      multiple clients at once. ie: Group Classes, Events,
                      Training, etc.
                    </p>
                  </div>
                  <div className="align-self-center">
                    <img
                      className="ms-2 pointer"
                      height={14}
                      src="/images/schedule/dis-forward.png"
                      alt=""
                    />
                  </div>
                </div>
              </button>
              <button
                className="nav-link p-0"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
                onClick={() => {
                  handleTabChange("TF");
                  dispatch(fetchVacationSchedule());
                }}
              >
                <div className="d-flex border-top border-bottom ps-3 pe-2 py-3">
                  <img
                    className="me-2 pointer"
                    height={17}
                    src="/images/schedule/vacation.png"
                    alt=""
                  />
                  <div className="text-start">
                    <h6 style={{ fontSize: "14px" }} className="mb-1 text-dark">
                      Vacation /Time off
                    </h6>
                    <p
                      style={{ fontSize: "12px", opacity: 0.75 }}
                      className="my-0 text-secondary"
                    >
                      Choose this schedule build if you would like portions of
                      your schedule to show as unavailable to clients.
                    </p>
                  </div>
                  <div className="align-self-center">
                    <img
                      className="ms-2 pointer"
                      height={14}
                      src="/images/schedule/dis-forward.png"
                      alt=""
                    />
                  </div>
                </div>
              </button>
            </div>
          </div>
          <div className="col-lg-8 col-xxl-9 p-0 border-top">
            <div className="tab-content" id="v-pills-tabContent">
              {/* <Formik
                initialValues={oneToOneData}
                validationSchema={validationSchema}
                onSubmit={(values) => console.log("values", values)}
              > */}
              {/* {({ errors, touched, values, handleBlur, handleChange }) => ( */}
              {/* <Form> */}
              <div
                className={`tab-pane fade p-3  ${
                  oneToOneData?.isActive === 1 ? "show active" : ""
                }`}
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <OneToOneSchedule
                  // errors={errors}
                  // touched={touched}
                  // values={values}
                  // handleBlur={handleBlur}
                  // handleChange={handleChange}
                  change={change}
                  setChange={setChange}
                />
              </div>
              {/* </Form> */}
              {/* )} */}
              {/* </Formik> */}
              <div
                className={`tab-pane fade p-3  ${
                  multiToMultiReadOnly?.isActive === 1 ? "show active" : ""
                }`}
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <MultiSchedule />
              </div>
              <div
                className="tab-pane fade p-3 show"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <Vacation updateForm={newVacation} setForm={setNewVacation} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <OverlayLoading active={status === "loading" ? true : false} />
    </div>
  );
};

export default Schedule;

import React, { useEffect, useMemo } from "react";
import ServiceBox from "./components/ServiceBox";
import { loggedInUserProfileRootService } from "../../../reducers/authReducer";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSecondLevelChildServices,
  level3ServicesStatusSelector,
  ownServiceFilterSelector,
  serviceLevelThreeSelector,
  serviceLevelTwoSelector,
  setOwnServiceFilter,
} from "../../../reducers/serviceReducer";
import { AppDispatch, RootState } from "../../../store";
import ServicesCarousel from "./components/ServicesCarousel";
import { serviceBoxPropsType } from "./type";
import Modal from "../../../components/common/Modal";
import { CreateModal } from "./components/CreateModal";
import ServiceCardSkeletonLoad from "./components/ServiceCardSkeletonLoad";
import ServiceSkeletonLoading from "./components/ServiceSkeletonLoading";
import { LOADING } from "../../../config/constant";

const Services = () => {
  const [open, setOpen] = React.useState(false);
  // const [rootServices, setRootServices] = React.useState<any>({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch<AppDispatch>();
  const rootServices = useSelector(loggedInUserProfileRootService);
  const levelTwoServices = useSelector(serviceLevelTwoSelector);
  const levelThreeServices = useSelector(serviceLevelThreeSelector);
  const level3ServicesStatus = useSelector(level3ServicesStatusSelector);
  const ownServiceFilter = useSelector(ownServiceFilterSelector);

  const { status } = useSelector((state: RootState) => state.service);

  useEffect(() => {
    // dispatch(serviceLevelThreeSelector())
    dispatch(fetchSecondLevelChildServices(rootServices?.service?.id));

    return () => {};
  }, [rootServices?.service?.id]);

  function handleFilterChange(e) {
    dispatch(setOwnServiceFilter(e.target.checked));
    // console.log(e.target.name, e.target.checked);
  }

  return (
    <>
      {status === "loading" ? (
        <ServiceSkeletonLoading />
      ) : (
        <div className="mx-lg-5 my-4">
          <div
            style={{ minHeight: "70vh" }}
            className="container-fluid bg-white rounded-5 px-4 py-3"
          >
            <div className="d-flex flex-md-row flex-column justify-content-between">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 main-head">Services</h5>
                <div
                  style={{ fontSize: "12px" }}
                  className="border border-1 rounded py-1 px-2 me-2 rounded-pill text-center ms-2 border border-1 border-primary text-primary"
                >
                  {rootServices?.service?.name}
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-md-end justify-content-between mt-md-0 mt-2">
                <div className="form-check me-3">
                  <input
                    className="form-check-input"
                    name="ownServiceFilter"
                    type="checkbox"
                    checked={ownServiceFilter}
                    onChange={handleFilterChange}
                    value=""
                    id="ownServices"
                  />
                  <label
                    style={{ fontSize: "14px", color: "grey" }}
                    className="form-check-label fs-small"
                    htmlFor="ownServices"
                  >
                    My Services
                  </label>
                </div>
                <button
                  onClick={handleOpen}
                  className="btn btn-primary py-1 px-3"
                >
                  Create
                </button>
                {/* <button className="btn btn-primary py-1 px-3">
                                Save
                            </button> */}
              </div>
            </div>

            <div
              style={{ height: "90px" }}
              className="mt-3 mb-xl-0 mb-sm-4 mb-2"
            >
              <ServicesCarousel secondLevelServices={levelTwoServices} />
            </div>

            {level3ServicesStatus === LOADING ? (
              <ServiceCardSkeletonLoad services={[1, 2, 3, 4]} />
            ) : (
              levelThreeServices?.map(
                (TLS: serviceBoxPropsType, idx: React.Key) => (
                  <ServiceBox key={idx} thirdLevelService={TLS} />
                )
              )
            )}
          </div>
        </div>
      )}
      <Modal handleClose={handleClose} open={open}>
        <CreateModal handleClose={handleClose} />
      </Modal>
    </>
  );
};

export default Services;

//  {/* <div className="row w-100 m-auto">
//                       <div style={{ width: '20px' }} className="col-1 px-3 border border-1 pointer rounded-start">
//                           <div className='h-100 d-flex justify-content-center align-items-center py-1'>
//                               <img
//                                   className=""
//                                   style={{ objectFit: "contain" }}
//                                   height={15}
//                                   width={15}
//                                   src="/images/services/left.png"
//                                   alt=""
//                               />
//                           </div>
//                       </div>
//                       <div className="col px-3 border border-1 hover-light bg-primary">
//                           <div className='d-flex flex-column justify-content-center align-items-center py-1'>
//                               <img
//                                   className="rounded-circle"
//                                   style={{ objectFit: "contain" }}
//                                   height={25}
//                                   width={25}
//                                   src="/images/Frame.png"
//                                   alt=""
//                               />
//                               <p style={{ fontSize: '14px' }} className='mb-0 text-white'>
//                                   Hair
//                               </p>
//                           </div>
//                       </div>
//                       <div className="col px-3 border border-1 hover-light">
//                           <div className='d-flex flex-column justify-content-center align-items-center py-1'>
//                               <img
//                                   className="rounded-circle"
//                                   style={{ objectFit: "contain" }}
//                                   height={25}
//                                   width={25}
//                                   src="/images/Frame.png"
//                                   alt=""
//                               />
//                               <p style={{ fontSize: '14px' }} className='mb-0 text-secondary'>
//                                   Hair
//                               </p>
//                           </div>
//                       </div>
//                       <div className="col px-3 border border-1 hover-light">
//                           <div className='d-flex flex-column justify-content-center align-items-center py-1'>
//                               <img
//                                   className="rounded-circle"
//                                   style={{ objectFit: "contain" }}
//                                   height={25}
//                                   width={25}
//                                   src="/images/Frame.png"
//                                   alt=""
//                               />
//                               <p style={{ fontSize: '14px' }} className='mb-0 text-secondary'>
//                                   Hair
//                               </p>
//                           </div>
//                       </div>
//                       <div className="col px-3 border border-1 hover-light">
//                           <div className='d-flex flex-column justify-content-center align-items-center py-1'>
//                               <img
//                                   className="rounded-circle"
//                                   style={{ objectFit: "contain" }}
//                                   height={25}
//                                   width={25}
//                                   src="/images/Frame.png"
//                                   alt=""
//                               />
//                               <p style={{ fontSize: '14px' }} className='mb-0 text-secondary'>
//                                   Hair
//                               </p>
//                           </div>
//                       </div>
//                       <div className="col px-3 border border-1 hover-light">
//                           <div className='d-flex flex-column justify-content-center align-items-center py-1'>
//                               <img
//                                   className="rounded-circle"
//                                   style={{ objectFit: "contain" }}
//                                   height={25}
//                                   width={25}
//                                   src="/images/Frame.png"
//                                   alt=""
//                               />
//                               <p style={{ fontSize: '14px' }} className='mb-0 text-secondary'>
//                                   Hair
//                               </p>
//                           </div>
//                       </div>
//                       <div className="col px-3 border border-1 hover-light">
//                           <div className='d-flex flex-column justify-content-center align-items-center py-1'>
//                               <img
//                                   className="rounded-circle"
//                                   style={{ objectFit: "contain" }}
//                                   height={25}
//                                   width={25}
//                                   src="/images/Frame.png"
//                                   alt=""
//                               />
//                               <p style={{ fontSize: '14px' }} className='mb-0 text-secondary'>
//                                   Hair
//                               </p>
//                           </div>
//                       </div>
//                       <div className="col px-3 border border-1 hover-light">
//                           <div className='d-flex flex-column justify-content-center align-items-center py-1'>
//                               <img
//                                   className="rounded-circle"
//                                   style={{ objectFit: "contain" }}
//                                   height={25}
//                                   width={25}
//                                   src="/images/Frame.png"
//                                   alt=""
//                               />
//                               <p style={{ fontSize: '14px' }} className='mb-0 text-secondary'>
//                                   Hair
//                               </p>
//                           </div>
//                       </div>
//                       <div style={{ width: '20px' }} className="col-1 px-3 border border-1 rounded-end">
//                           <div className='h-100 d-flex justify-content-center align-items-center py-1 pointer'>
//                               <img
//                                   className=""
//                                   style={{ objectFit: "contain" }}
//                                   height={15}
//                                   width={15}
//                                   src="/images/services/right.png"
//                                   alt=""
//                               />
//                           </div>
//                       </div>
//                   </div> */}

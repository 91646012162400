import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalC from "../../../components/common/Modal";
import {
  clientListSelector,
  fetchMemberList,
} from "../../../reducers/clientReducer";
import { AppDispatch, RootState } from "../../../store";
import Clients from "./components/Clients";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import ClientsLoading from "./components/ClientsLoading";
import InviteModal from "./components/InviteModal";
import NoClientList from "./components/NoClientList";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import FilterButton from "../../../components/common/Button/FilterBtn";
import { useNavigate } from "react-router-dom";
import { ConnectionStatus } from "../../../components/common/types";

const ClientList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const clientListData = useSelector(clientListSelector);

  const strAscending = [...clientListData]?.sort((a, b) =>
    a?.username?.localeCompare(b?.username)
  );
  const strDescending = [...clientListData]?.sort((a, b) =>
    b?.username?.localeCompare(a?.username)
  );

  const favUser = [...clientListData].filter((f) => f.isFav === 1);
  const vicUser = [...clientListData].filter((f) => f.isVic === 1);
  const oldUser = [...clientListData].sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  const recentUser = [...clientListData].sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });

  useEffect(() => {
    dispatch(fetchMemberList(ConnectionStatus.APPROVED));
    return () => {};
  }, [dispatch]);

  const [filters, setFilters] = useState({
    alphabetSort: 0,
    isFav: false,
    isVic: false,
    isRecent: false,
    isOld: false,
  });
  const [search, setsearch] = useState();
  const [searching, setSearching] = useState(false);
  const [finalSearch, setFinalSearch] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { status } = useSelector((state: RootState) => state.client);

  const sorting = () => {
    if (filters.alphabetSort === 0) {
      setFilters({
        ...filters,
        alphabetSort: 1,
      });
    } else if (filters.alphabetSort === 1) {
      setFilters({
        ...filters,
        alphabetSort: 2,
      });
    } else if (filters.alphabetSort === 2) {
      setFilters({
        ...filters,
        alphabetSort: 0,
      });
    }
  };

  const handleSearching = (e) => {
    if (e.target.value === "") {
      setSearching(false);
      setsearch(null);
    } else {
      // setSearching(true);
      setsearch(e.target.value.toUpperCase());
    }
    // console.log(search);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (finalSearch === null) {
      setSearching(false);
    } else {
      setFinalSearch(search);
      // console.log(search);
      setSearching(true);
    }
  };

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "72vh" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="row mx-auto my-4 px-2 py-3 border-bottom align-items-center">
          <div className="col-xl-3 col-12">
            <div className="d-flex flex-sm-row flex-column justify-content-between">
              <h5 className="main-head me-sm-5 mb-xl-0 mb-3">Clients</h5>
              <form onSubmit={handleSearchSubmit} className="d-flex">
                <input
                  type="search"
                  id="form1"
                  onChange={handleSearching}
                  className="form-control explore-search border-end-0 d-lg-none d-block"
                  placeholder="Search Client"
                />
                <button
                  onClick={handleSearchSubmit}
                  type="button"
                  className="btn btn-outline-secondary border border-search d-lg-none d-block"
                >
                  Search
                </button>
              </form>
            </div>
          </div>
          <div className="col-xl-9 ms-auto col-12 d-flex flex-sm-row flex-column justify-content-end">
            <form onSubmit={handleSearchSubmit} className="d-flex">
              <input
                type="search"
                id="form1"
                onChange={handleSearching}
                className="form-control explore-search border-end-0 d-lg-block d-none"
                placeholder="Search Client"
              />
              <button
                onClick={handleSearchSubmit}
                type="button"
                className="btn btn-outline-secondary border border-search d-lg-block d-none"
              >
                Search
              </button>
            </form>
            <div
              className="btn-group ms-sm-3 mt-lg-0 mt-2"
              role="group"
              aria-label="Basic outlined example"
            >
              <FilterButton
                onClick={() => sorting()}
                active={filters.alphabetSort}
                tooltip="Alpha"
              >
                <img
                  src={`${
                    filters.alphabetSort === 2
                      ? "/images/clientList/ztoa.png"
                      : "/images/clientList/atoz.png"
                  }`}
                  alt="icon"
                  className="pointer my-1"
                />
              </FilterButton>
              <FilterButton
                onClick={() => {
                  setFilters({
                    ...filters,
                    isOld: !filters.isOld,
                    isRecent: false,
                  });
                }}
                active={filters.isOld}
                tooltip="Old Members"
              >
                <img
                  src="/images/clientList/time.png"
                  alt="icon"
                  className="pointer my-1"
                />
              </FilterButton>
              <FilterButton
                onClick={() => {
                  setFilters({
                    ...filters,
                    isVic: !filters.isVic,
                  });
                }}
                active={filters.isVic}
                tooltip="VIC Members"
              >
                <img
                  src="/images/explore/star.png"
                  alt="icon"
                  className="pointer my-1"
                />
              </FilterButton>
              <FilterButton
                onClick={() => {
                  setFilters({
                    ...filters,
                    isRecent: !filters.isRecent,
                    isOld: false,
                  });
                }}
                active={filters.isRecent}
                tooltip="Recent Members"
              >
                <RecentActorsIcon
                  className="pointer my-1"
                  sx={{ color: "#383838" }}
                />
              </FilterButton>
              <FilterButton
                onClick={() => {
                  navigate("/client/history");
                }}
                tooltip="Members History"
              >
                <HistoryToggleOffIcon
                  className="pointer my-1"
                  sx={{ color: "#383838" }}
                />
              </FilterButton>
            </div>
            <button
              style={{ whiteSpace: "nowrap" }}
              className="btn btn-primary d-flex flex-row align-items-center ps-1 border-b5 ms-sm-3 mt-md-0 mt-3"
              onClick={handleOpen}
            >
              <img
                height={15}
                src="/images/clientList/invite.png"
                alt="plus"
                className="mx-2"
              />
              Client
            </button>
          </div>
        </div>
        {status === "loading" ? (
          <div className="row mx-auto my-2 px-2 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 align-items-center">
            <ClientsLoading />
          </div>
        ) : (
          <>
            {clientListData?.length === 0 ? (
              <NoClientList handleOpen={handleOpen} />
            ) : searching ? (
              <div className="row mx-auto my-2 px-2 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 align-items-center">
                {clientListData
                  ?.filter((f) =>
                    f?.username?.toUpperCase().includes(finalSearch)
                  )
                  ?.map((data, idx) => (
                    <Clients
                      key={idx}
                      id={data.id}
                      src={data?.profileImage}
                      userName={data?.firstName + " " + data?.lastName}
                      next={data?.oldestAppointment?.appointment_date}
                      prev={data?.lastAppointment?.appointment_date}
                      isVic={data?.isVic}
                      mobileNo={data?.mobileNo}
                      data={data}
                    />
                  ))}
              </div>
            ) : (
              <div className="row mx-auto my-2 px-2 row-cols-xxl-4 row-cols-xl-3 row-cols-lg-2 row-cols-md-2 row-cols-1 align-items-center">
                {(filters.alphabetSort === 1
                  ? strAscending
                  : filters.alphabetSort === 2
                  ? strDescending
                  : filters.isVic
                  ? vicUser
                  : filters.isFav
                  ? favUser
                  : filters.isRecent
                  ? recentUser
                  : filters.isOld
                  ? oldUser
                  : clientListData
                ).map((data, idx) => (
                  <Clients
                    key={idx}
                    id={data.id}
                    src={data?.profileImage}
                    userName={data?.firstName + " " + data?.lastName}
                    next={data?.oldestAppointment?.appointment_date}
                    prev={data?.lastAppointment?.appointment_date}
                    isVic={data?.isVic}
                    mobileNo={data?.mobileNo}
                    data={data}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <ModalC handleClose={handleClose} open={open}>
        <InviteModal handleClose={handleClose} />
      </ModalC>
    </div>
  );
};

export default ClientList;

import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { appointmentPendingListSelector } from "../../../../reducers/appointmentReducer";
import { makeCalenderPerchi } from "../../../../utils/factory";
import { useCallback, useMemo, useState } from "react";
import { TemporaryDrawer } from "../../../../components/common/TemporaryDrawer";
import DrawerChild from "./DrawerChild";
import { showTime } from "../../../../utils";
// import "./style.css"; testing  for classes

const localizer = momentLocalizer(moment);

const fontFamily = {
  fontFamily: "roboto",
};

const ProCalendar = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState<unknown>({});

  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );

  const refinedData = useMemo(
    () => makeCalenderPerchi(pendingAppointmentListData),
    [pendingAppointmentListData]
  );

  const onSelectEvent = useCallback((data) => {
    // console.log({ data });
    setData(data);
  }, []);

  const MonthEvent = ({ event }): JSX.Element => (
    <div>
      {event?.lastName} - {showTime(event.start)}
    </div>
  );

  const WeekEvent = ({ event }) => <div>{event?.lastName}</div>;

  // commented on purpose
  const components = useMemo(
    () => ({
      // event: MyEvent, // used by each view (Month, Day, Week)
      // eventWrapper: MyEventWrapper,
      // eventContainerWrapper: MyEventContainerWrapper,
      // dateCellWrapper: MyDateCellWrapper,
      // timeSlotWrapper: MyTimeSlotWrapper,
      // timeGutterHeader: MyTimeGutterWrapper,
      // resourceHeader: MyResourceHeader,
      // toolbar: MyToolbar,

      week: {
        // header:MyWeekHeader,
        event: WeekEvent,
      },
      month: {
        //   header: MyMonthHeader,
        //   dateHeader: MyMonthDateHeader,
        event: MonthEvent,
      },
    }),
    []
  );

  // const msg = { showMore: (total) => `+ ${total}` };

  return (
    <>
      <div
        style={{ ...fontFamily }}
        className="calendars mt-4 bg-white rounded p-3 overflow-auto"
      >
        <div>
          <Calendar
            events={refinedData}
            localizer={localizer}
            defaultView="month"
            onSelectEvent={(e) => {
              handleOpen();
              onSelectEvent(e);
            }}
            views={["month", "week", "day"]}
            defaultDate={new Date()}
            popup={true}
            style={{ height: 700, minWidth: '550px', overflow: 'auto' }}
            // onDoubleClickEvent={(e) => {
            //   handleOpen();
            //   onSelectEvent(e);
            // }}
            // messages={msg}
            components={components}
          />
        </div>
      </div>
      <TemporaryDrawer handleClose={handleClose} open={open}>
        <DrawerChild
          handleClose={handleClose}
          isEmpty={false}
          appointmentData={data}
        />
      </TemporaryDrawer>
    </>
  );
};

export default ProCalendar;

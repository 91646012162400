import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";
import { urlParameter } from "../../../components/common/types";
import { makeResponseError } from "../../../utils/errorHandler";
import { SHOW_SUCCESS } from "../../../utils/ToastMessage";

// const fetchServiceByParentRequest = async (id: urlParameter) => {
//   try {
//     return await client.get(provider_URLS.services.getOneByParent(id));
//   } catch (error) {
//     return error;
//   }
// };

// const fetchThirdLevelServiceRequest = async (id: urlParameter) => {
//   try {
//     return await client.get(provider_URLS.services.getThirdLevel(id));
//   } catch (error) {
//     return error;
//   }
// };

// const fetchFullRootServiceRequest = async (token: any) => {
//   try {
//     return await client.get(provider_URLS.services.getFullRoot, {
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     });
//   } catch (error) {
//     return error;
//   }
// };

// const fetchRootServiceRequest = async () => {
//   try {
//     return await client.get(provider_URLS.services.getRoot);
//   } catch (error) {
//     return error;
//   }
// };

export const fetchAllSocialLinksRequest = async () => {
  try {
    return await client.get(provider_URLS.media.MEDIA_CONFIG);
  } catch (error) {
    return error;
  }
};
const fetchOwnMediaRequest = async () => {
  try {
    return await client.get(provider_URLS.media.MEDIA_MAIN);
  } catch (error) {
    return error;
  }
};

export const providerMedia = async (id: any) => {
  try {
    return await client.get(provider_URLS.media.PROVIDER_MEDIA(id));
  } catch (error) {
    return error;
  }
};

export const fetchAllMediaRequest = async () => {
  try {
    return await client.get(provider_URLS.media.MEDIA_EXPLORE);
  } catch (error) {
    return error;
  }
};

export const fetchPaginatedMediaRequest = async (data) => {
  try {
    return await client.get(provider_URLS.media.PAGINATED_MEDIA_EXPLORE, {
      params: data,
    });
  } catch (error) {
    return error;
  }
};

const fetchOwnSocialMediaRequest = async () => {
  try {
    return await client.get(provider_URLS.media.MEDIA_ALL_LINK);
  } catch (error) {
    return error;
  }
};

export const addOwnSocialMediaRequest = async (data: any) =>
  client.post(provider_URLS.media.MEDIA_ALL_LINK, data);

export const uploadOwnMediaRequest = async (
  fromData,
  onUploadProgress: any
) => {
  try {
    const res: any = await client.post(
      provider_URLS.media.MEDIA_MAIN,
      fromData,
      { onUploadProgress }
    );
    const { data, success, message } = res.data;
    // if (success) {
    //   formData.handleClose();
    // }
    // console.log(data);

    SHOW_SUCCESS(success, "Media added successfully");

    return {
      data,
      success,
      code: 201,
    };
  } catch (error) {
    // xconsole.fail(error.message);
    return makeResponseError(error.response.data);
  }
};

export const mediaLikeRequest = async (data: any) =>
  client.post(provider_URLS.media.MEDIA_LIKE, data);

export const deleteMediaRequest = async (id: any) =>
  client.delete(provider_URLS.media.DELETE_MEDIA(id));

export const appointmentMediaPrivacyRequest = async (data: any) =>
  client.put(provider_URLS.media.APPOINTMENT_MEDIA_PRIVACY, data);

export { fetchOwnMediaRequest, fetchOwnSocialMediaRequest };

import { useState, useRef } from "react";
import LevelTwo from "./LevelTwo";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchThirdLevelChildServices,
  secondlevelIdSelector,
  updateSecondLevelServiceId,
} from "../../../../reducers/serviceReducer";
import { AppDispatch } from "../../../../store";

import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";

const ServicesCarousel = ({ secondLevelServices }: any) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleServiceClick = (id: number | string): void => {
    dispatch(fetchThirdLevelChildServices(id));
    dispatch(updateSecondLevelServiceId({ id: id }));
  };
  const secondLevelServiceId = useSelector(secondlevelIdSelector);

  let scrl = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);

  //Slide click
  const slide = (shift) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(false);
    } else {
      setscrolEnd(false);
    }
  };

  //Anim
  // const anim = (e) => {
  //   gsap.from(e.target, { scale: 1 });
  //   gsap.to(e.target, { scale: 1.5 });
  // };
  // const anim2 = (e) => {
  //   gsap.from(e.target, { scale: 1.5 });
  //   gsap.to(e.target, { scale: 1 });
  // };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(false);
    } else {
      setscrolEnd(false);
    }
  };

  return (
    // <div className="row m-auto">
    //   {data?.map(({ icon, name, id }: levelTwoCardType) => (
    //     <div
    //       key={id}
    //       onClick={() => handleServiceClick(id)}
    //       className={`${levelThreeServices[0]?.parentId === id
    //         ? " bg-primary hover-primary text-white"
    //         : "text-secondary"
    //         } col px-3 border border-1 hover-light`}
    //     >
    //       <LevelTwo icon={icon} name={name} />
    //     </div>
    //   ))}
    // </div>
    <div className="scroll-App w-100">
      {/* {scrollX !== 0 && ( */}
      <BootstrapTooltip title="Backward" placement="top">
        <button
          className="prev scroll-button mb-3 px-2 border-end-0"
          onClick={() => slide(-205)}
          // onMouseEnter={(e) => anim(e)}
          // onMouseLeave={(e) => anim2(e)}
        >
          <img
            className=""
            style={{ objectFit: "contain" }}
            height={16}
            width={20}
            src="/images/services/left.png"
            alt=""
          />
          {/* <i className="fa fa-angle-left"></i> */}
        </button>
      </BootstrapTooltip>
      {/* )} */}
      <ul
        ref={scrl}
        className={`scroll-list col ${
          secondLevelServices?.length >= 4
            ? "row-cols-md-4"
            : secondLevelServices?.length >= 3
            ? "row-cols-md-3"
            : secondLevelServices?.length >= 2
            ? "row-cols-md-2"
            : secondLevelServices?.length >= 1
            ? "row-cols-md-1"
            : "row-cols-md-4"
        } row-cols-sm-3 row-cols-1 ps-0`}
        onScroll={scrollCheck}
      >
        {secondLevelServices?.map(({ id, icon, name }) => (
          <div
            // style={{ maxWidth: "200px" }}
            key={id}
            onClick={() => {
              if (secondLevelServiceId !== id) {
                handleServiceClick(id);
              }
            }}
            className={`${
              secondLevelServiceId === id
                ? " bg-primary hover-primary text-white"
                : " "
            } col border border-1 border-end-0 hover-light`}
          >
            <LevelTwo icon={icon} name={name} />
          </div>
        ))}
      </ul>
      {/* <ul ref={scrl} className="scroll-list" onScroll={scrollCheck}>
          {Fdata?.fruits?.map((idx) => (
            <div
              // style={{ width: "200px" }}
              key={idx}
              // onClick={() => handleServiceClick(id)}
              className={`${levelThreeServices[0]?.parentId === idx
                ? " bg-primary hover-primary"
                : " "
                }  px-5 border border-1 hover-light`}
            >
              <LevelTwo icon={"icon"} name={"name"} />
            </div>
          ))}
        </ul> */}
      {/* {!scrolEnd && ( */}
      <BootstrapTooltip title="Forward" placement="top">
        <button
          className="next scroll-button mb-3 px-2"
          onClick={() => slide(+205)}
          // onMouseEnter={(e) => anim(e)}
          // onMouseLeave={(e) => anim2(e)}
        >
          <img
            className=""
            style={{ objectFit: "contain" }}
            height={16}
            width={20}
            src="/images/services/right.png"
            alt=""
          />
          {/* <i className="fa fa-angle-right"></i> */}
        </button>
      </BootstrapTooltip>
      {/* )} */}
    </div>
  );
};

export default ServicesCarousel;

// const ServicesCarouselTemp = () => {
//   return (
//     <div className="row">
//       {/* {scrollX !== 0 && ( */}
//       <div className="col-12 d-flex w50">
//         <button
//           className="prev scroll-button mb-3 px-2"
//           onClick={() => slide(-50)}
//         // onMouseEnter={(e) => anim(e)}
//         // onMouseLeave={(e) => anim2(e)}
//         >
//           <img
//             className="rounded-circle"
//             style={{ objectFit: "contain" }}
//             height={20}
//             width={20}
//             src="/images/services/left.png"
//             alt=""
//           />
//           {/* <i className="fa fa-angle-left"></i> */}
//         </button>
//         {/* )} */}
//         <ul ref={scrl} className="d-flex w-100" onScroll={scrollCheck}>
//           {data?.map(({ id, icon, name }) => (
//             <div
//               // style={{ width: "158px" }}
//               key={id}
//               onClick={() => handleServiceClick(id)}
//               className={`${levelThreeServices[0]?.parentId === id
//                 ? " bg-primary hover-primary"
//                 : " "
//                 }border hover-light`}
//             >
//               <LevelTwo icon={icon} name={name} />
//             </div>
//           ))}
//         </ul>
//         {/* {!scrolEnd && ( */}
//         <button
//           className="next scroll-button mb-3 px-2"
//           onClick={() => slide(+50)}
//         // onMouseEnter={(e) => anim(e)}
//         // onMouseLeave={(e) => anim2(e)}
//         >
//           <img
//             className="rounded-circle"
//             style={{ objectFit: "contain" }}
//             height={20}
//             width={20}
//             src="/images/services/right.png"
//             alt=""
//           />
//           {/* <i className="fa fa-angle-right"></i> */}
//         </button>
//       </div>
//       {/* )} */}
//     </div>
//   )
// }

//   <div className="row m-auto">
//     <div className="col px-3 border border-1 hover-light bg-primary">
//       <div className="d-flex flex-column justify-content-center align-items-center py-1">
//         <img
//           className="rounded-circle"
//           style={{ objectFit: "contain" }}
//           height={25}
//           width={25}
//           src="/images/Frame.png"
//           alt=""
//         />
//         <p style={{ fontSize: "14px" }} className="mb-0 text-white">
//           Hair
//         </p>
//       </div>
//     </div>
//     <div className="col px-3 border border-1 hover-light">
//       <div className="d-flex flex-column justify-content-center align-items-center py-1">
//         <img
//           className="rounded-circle"
//           style={{ objectFit: "contain" }}
//           height={25}
//           width={25}
//           src="/images/Frame.png"
//           alt=""
//         />
//         <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
//           Hair
//         </p>
//       </div>
//     </div>
//     <div className="col px-3 border border-1 hover-light">
//       <div className="d-flex flex-column justify-content-center align-items-center py-1">
//         <img
//           className="rounded-circle"
//           style={{ objectFit: "contain" }}
//           height={25}
//           width={25}
//           src="/images/Frame.png"
//           alt=""
//         />
//         <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
//           Hair
//         </p>
//       </div>
//     </div>
//     <div className="col px-3 border border-1 hover-light">
//       <div className="d-flex flex-column justify-content-center align-items-center py-1">
//         <img
//           className="rounded-circle"
//           style={{ objectFit: "contain" }}
//           height={25}
//           width={25}
//           src="/images/Frame.png"
//           alt=""
//         />
//         <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
//           Hair
//         </p>
//       </div>
//     </div>
//     <div className="col px-3 border border-1 hover-light">
//       <div className="d-flex flex-column justify-content-center align-items-center py-1">
//         <img
//           className="rounded-circle"
//           style={{ objectFit: "contain" }}
//           height={25}
//           width={25}
//           src="/images/Frame.png"
//           alt=""
//         />
//         <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
//           Hair
//         </p>
//       </div>
//     </div>
//     <div className="col px-3 border border-1 hover-light">
//       <div className="d-flex flex-column justify-content-center align-items-center py-1">
//         <img
//           className="rounded-circle"
//           style={{ objectFit: "contain" }}
//           height={25}
//           width={25}
//           src="/images/Frame.png"
//           alt=""
//         />
//         <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
//           Hair
//         </p>
//       </div>
//     </div>
//     <div className="col px-3 border border-1 hover-light">
//       <div className="d-flex flex-column justify-content-center align-items-center py-1">
//         <img
//           className="rounded-circle"
//           style={{ objectFit: "contain" }}
//           height={25}
//           width={25}
//           src="/images/Frame.png"
//           alt=""
//         />
//         <p style={{ fontSize: "14px" }} className="mb-0 text-secondary">
//           Hair
//         </p>
//       </div>
//     </div>
//   </div>

import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { removeAuth } from "../../../utils/AuthHelpers";
import { removePageLocation } from "../../../utils";
import Greeting from "../../common/Greeting";
import { Toolbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../store";
import {
  loggedInUserProfile,
  profileLoadingSelector,
  setLogOut,
} from "../../../reducers/authReducer";
import { DESTROY_SESSION } from "../../../reducers/actions";
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Notification from "../Notification";
import {
  allNotificationSelector,
  getAllNotificationAction,
} from "../../../reducers/notificationReducer";
import GreetingLoading from "../../common/GreetingLoading";

const Header = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isOnline, setIsOnline] = React.useState(navigator.onLine);
  const authUserData = useSelector(loggedInUserProfile);
  const allNotifications = useSelector(allNotificationSelector);
  const profileLoading = useSelector(profileLoadingSelector);

  React.useEffect(() => {
    // Update network status
    const handleStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener("online", handleStatusChange);

    // Listen to the offline status
    window.addEventListener("offline", handleStatusChange);

    // Specify how to clean up after this effect for performance improvement
    return () => {
      window.removeEventListener("online", handleStatusChange);
      window.removeEventListener("offline", handleStatusChange);
    };
  }, [isOnline]);

  React.useEffect(() => {
    dispatch(getAllNotificationAction(authUserData?.id));
  }, [authUserData?.id]);

  const [notificationToggle, setNotificationToggle] = React.useState(false);
  const handleNotificationToggle = () =>
    setNotificationToggle((prev) => (prev = !notificationToggle));

  const navigate = useNavigate();

  return (
    <Toolbar className="d-flex">
      {/* <IconButton
                        className="sm-d-none"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: "none" }),
                        }}
                    >
                        <MenuIcon style={{ color: "grey" }} />
                    </IconButton> */}
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className="order-md-0 d-md-block d-none">
          {profileLoading === "loading" ? (
            <GreetingLoading />
          ) : (
            <Greeting userData={authUserData} />
          )}
        </div>
        <div className="d-md-none d-block">
          <img
            className="me-2"
            height={35}
            width={35}
            src="/images/KZH-PRO.jpeg"
            alt=""
          />
          <img src="/images/KZH-Written.svg" alt="" />
        </div>
        <div className="order-md-1 order-end d-sm-block d-none">
          {/* <input
                style={{ backgroundColor: "#F3F4F6" }}
                type="search"
                id="form1"
                className="form-control rounded-pill py-2 border border-0"
                placeholder="search"
              /> */}
          {/* <label className="form-label" htmlFor="form1">Search</label> */}
        </div>
        <div className="order-md-2 px-0">
          <div className="d-flex align-items-center justify-content-end my-md-0 my-3">
            {/* <div
                  style={{ padding: "5px 8px" }}
                  className="bg-mask me-lg-3 me-md-2 me-1 border boreder-1 rounded-circle avatar"
                >
                  <img src="/images\info.png" alt="avatar" className="" />
                </div> */}
            <div
              style={{ padding: "5px 8px" }}
              className={`bg-mask me-lg-3 me-md-2 me-1 border border-1 rounded-circle avatar ${
                allNotifications?.find(({ isRead }) => isRead === 0)
                  ? "avatar-busy"
                  : ""
              }`}
              onClick={handleNotificationToggle}
            >
              <img src="/images\notification.png" alt="avatar" className="" />
            </div>
            {/* notification side starts from here */}
            <Notification
              toggle={notificationToggle}
              handleToggler={handleNotificationToggle}
            />
            {/* <div
                  style={{ padding: "5px 8px" }}
                  className="bg-mask me-lg-3 me-md-2 me-1 border boreder-1 rounded-circle avatar avatar-busy"
                >
                  <img src="/images\calendar.png" alt="avatar" className="" />
                </div> */}
            {/* <div className="dropdown">
                  <div
                    style={{ padding: "5px 8px" }}
                    className="bg-mask me-lg-3 me-md-2 me-1 border boreder-1 rounded-circle avatar avatar-busy"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="/images\notification.png"
                      alt="avatar"
                      className=""
                    />
                  </div>

                  <ul
                    style={{ borderRadius: "10px", width: "300px" }}
                    className="dropdown-menu dropdown-menu-light px-3 pt-2 pb-3"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="align-items-center">
                      <h6 className="mb-0 fw-bold mt-3 mb-1">
                        Introducing Kazzah v1.0.0
                      </h6>
                      <p
                        style={{ fontSize: "14px", color: "#667085" }}
                        className="mb-0 text-grey"
                      >
                        Explore what's new and what's next in the new stable version of the advanced features.
                      </p>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li className="align-items-center">
                      <h6 className="mb-0 fw-bold mt-3 mb-1">
                        Date and Time Pickers revamped
                      </h6>
                      <p
                        style={{ fontSize: "14px", color: "#667085" }}
                        className="mb-0 text-grey"
                      >
                        Discover the exciting new features landing now with v6 beta.
                      </p>
                    </li>
                  </ul>
                </div> */}
            {/* left side dropdown  on header start here  */}
            <div className="dropdown">
              <div
                className={`ropdown-toggle avatar ${
                  isOnline ? "avatar-online" : "avatar-offline"
                }`}
                id="dropdownMenuButton2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <img
                  src={
                    authUserData?.profileImage
                      ? authUserData?.profileImage
                      : "/images/avatar2.png"
                  }
                  alt="avatar"
                  className="rounded-circle w-avatar border border-primary"
                />
              </div>
              <ul
                style={{ borderRadius: "10px", width: "200px" }}
                className="dropdown-menu dropdown-menu-light py-3"
                aria-labelledby="dropdownMenuButton2"
              >
                <li className="d-flex align-items-center">
                  <Link className="dropdown-item" to="/profile">
                    <PersonOutlineIcon
                      className="me-2 mb-1"
                      sx={{ fontSize: 30 }}
                    />
                    Account
                  </Link>
                </li>

                <li className="d-flex align-items-center">
                  <Link className="dropdown-item" to="/settings">
                    <SettingsIcon
                      className="me-2 mb-1"
                      sx={{
                        fontSize: 30,
                      }}
                    />
                    Setting
                  </Link>
                </li>

                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li className="d-flex align-items-center">
                  <span
                    className="dropdown-item pointer text-danger fw-bold"
                    onClick={() => {
                      removeAuth();
                      removePageLocation();
                      dispatch({ type: DESTROY_SESSION });
                      dispatch(setLogOut());
                      navigate("/auth/login");
                      // window.location.reload();
                    }}
                  >
                    <LogoutIcon
                      className="me-2 mb-1"
                      sx={{ fontSize: 30, color: "red" }}
                    />
                    Logout
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <Typography variant="h6" noWrap component="div">
                        Mini variant drawer
                    </Typography> */}
    </Toolbar>
  );
};

export default Header;

import * as React from "react";
import CircularProgress, {
  CircularProgressProps,
} from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const ImageProgress = ({ progress, appointment }) => {
  return (
    <figure
      className="rounded-5 text-center"
      style={{
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.11)",
        height: appointment ? 60 : 150,
        // width: "100%",
      }}
    >
      {/* <img
        style={{
          height: 150,
          objectFit: "contain",
          width: "100%",
          filter: "blur(1px)",
        }}
        src={src}
        alt="Loading"
      /> */}
      <CircularProgressWithLabel variant="determinate" value={progress} />
    </figure>
  );
};

function CircularProgressWithLabel(
  props: CircularProgressProps & { value: number }
) {
  return (
    <Box
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#04bac7",
      }}
    >
      <CircularProgress
        sx={{ color: "#04bac7" }}
        variant="determinate"
        {...props}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{ color: "#04bac7" }}
          className="mb-2"
          variant="caption"
          component="div"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

export default ImageProgress;

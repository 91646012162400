import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { countries, states } from "../../../../config/constant";
import { createPayment } from "../../../../reducers/paymentReducer";
import { AppDispatch } from "../../../../store";
import FormField from "../../../../components/UI";
import { businessInfoType } from "../../../../components/common/types";
import { providerBoardingAction } from "../../../../reducers/paymentReducer";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";

interface Options {
  label: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

const BusinessInfo = ({
  setChangeStep,
  handleDataChange,
  data,
}: {
  setChangeStep(string): void;
  handleDataChange: any;
  data: any;
}): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();

  const [request, setRequest] = React.useState(false);
  const initialState: businessInfoType = {
    dbaName: data?.dbaName,
    legalName: data?.legalName,
    taxIdNo: data?.taxIdNo,
    taxFillName: data?.taxFillName,
    startDate: data?.startDate,
    phoneNo: data?.phoneNo,
    bAddress: data?.bAddress,
    bSummary: data?.bSummary,
    bCity: data?.bCity,
    bType: data?.bType,
    bState: data?.bState,
    bZip: data?.bZip,
  };

  const [mobileError, setMobileError] = useState(true);

  const [options, setOptions] = useState<Options[]>([]);
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const handleAddressChange = async (e) => {
    setLoading(true);
    if (e.target.value.length > 3) {
      const res: any = await axios.post(
        "https://api.postgrid.com/v1/addver/completions",
        {
          partialStreet: e.target.value,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_POSTGRID_API_KEY,
          },
        }
      );
      const data = res.data.data;
      if (data?.length !== 0) {
        setOptions(
          data.map((m) => {
            return {
              label:
                m?.address?.address +
                " " +
                m?.address?.city +
                " " +
                m?.address?.prov +
                ", " +
                m?.address?.pc,
              street: m?.address?.address,
              city: m?.address?.city,
              state: m?.address?.prov,
              zip: m?.address?.pc,
            };
          })
        );
      } else {
        setLoading(false);
        setOptions([]);
      }
    }
  };

  const validationSchema = yup.object().shape({
    dbaName: yup.string().nullable().required("Business Name is required"),
    legalName: yup.string().nullable().required("Legal Name is required"),
    taxIdNo: yup.number().nullable().required("Tax Id is required"),
    taxFillName: yup.string().nullable().required("Tax Fill Name is required"),
    startDate: yup.string().nullable().required("Start Date is required"),
    phoneNo: yup.string().required("Phone No. is required").nullable(),
    bSummary: yup.string().nullable().required("Required"),
    bAddress: yup.string().nullable().required("Address is required"),
    bCity: yup.string().nullable().required("City is required"),
    bType: yup.string().nullable().required("Type is required"),
    bState: yup.string().nullable().required("State is required"),
    bZip: yup.string().nullable().required("Zip is required"),
  });

  function onchange(e) {
    setRequest(true);
    // console.log("e", { [e.target.name]: e.target.value });

    handleDataChange(e);
  }

  return (
    <div style={{ maxWidth: "700px" }} className="my-4 mx-auto">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 px-0 text-center"
      >
        <div className="d-flex justify-content-between px-4 py-3 border-bottom">
          <div>
            <h5 className="mb-0">Payments</h5>
          </div>
          <p className="text-primary py-1 px-3 mb-0">Step 1/3</p>
        </div>
        <div className="form text-start pb-3 px-4">
          <Formik
            initialValues={initialState}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              // console.log("value", data);
              if (request) {
                //remove id from data
                const finalData = {
                  ...data,
                  submitForApproval: 0,
                };

                if (mobileError) {
                  dispatch(
                    providerBoardingAction({ data: finalData, setChangeStep })
                  );
                }
              }
              setChangeStep("Step2");
            }}
          >
            {(formikHandler) => (
              <Form>
                <div className="form-group  mt-3">
                  <div className="row border rounded mt-4 py-3">
                    <FormField
                      className="col-sm-6 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "DBA Name",
                        prop: "dbaName",
                        placeHolder: "name here",
                      }}
                      tooltip="Doing Business As"
                    />

                    <FormField
                      className="col-sm-6 col-12 mt-sm-0 mt-3"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "Legal Name",
                        prop: "legalName",
                        placeHolder: "name here",
                      }}
                    />
                    <FormField
                      className="mt-3 col-sm-6 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="date"
                      fieldName={{
                        label: "start date",
                        prop: "startDate",
                        placeHolder: "Date here",
                      }}
                    />
                    {/* startDate */}
                    {/* phoneNo */}
                    <div className="col-sm-6 col-12">
                      <label htmlFor="" className="form-label mt-3">
                        Phone No.
                      </label>
                      <PhoneInputWithCountrySelect
                        defaultCountry="US"
                        onBlur={() => {
                          if (data?.phoneNo) {
                            setMobileError(
                              isValidPhoneNumber(data?.phoneNo.toString())
                            );
                          }
                        }}
                        name="phoneNo"
                        id="phoneNo"
                        className={`form-control ${
                          (formikHandler.errors.phoneNo &&
                            formikHandler.touched.phoneNo) ||
                          !mobileError
                            ? "is-invalid"
                            : ""
                        }`}
                        international
                        placeholder="Enter phone number"
                        value={data?.phoneNo}
                        onChange={(e) => {
                          formikHandler.handleChange({
                            target: {
                              value: e?.length > 0 ? Math.random() : "",
                              name: "phoneNo",
                            },
                          });
                          onchange({
                            target: {
                              value: e,
                              name: "phoneNo",
                            },
                          });
                          // console.log("e=>", e);
                        }}
                      />
                      <ErrorMessage
                        name="phoneNo"
                        component="span"
                        className="invalid-feedback"
                      />
                      {!mobileError && (
                        <span className="invalid-feedback">
                          Invalid phone number
                        </span>
                      )}
                    </div>
                  </div>

                  {/* "bAddress": "address", */}

                  <div className="row border rounded mt-4 pb-3">
                    <h5
                      style={{ fontSize: "1rem" }}
                      className="bg-light w-100 mt-0 py-3 text-dark fw-light"
                    >
                      Tax
                    </h5>
                    <FormField
                      className="col-sm-6 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="number"
                      fieldName={{
                        label: "Id here",
                        prop: "taxIdNo",
                        placeHolder: "tax id number here ",
                      }}
                    />
                    {/* taxFillName */}
                    <FormField
                      className="col-sm-6 col-12"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "Name",
                        prop: "taxFillName",
                        placeHolder: "tax name here",
                      }}
                    />
                  </div>
                  <div className="row border rounded mt-4 pb-3">
                    <h5
                      style={{ fontSize: "1rem" }}
                      className="bg-light w-100 mt-0 py-3 text-dark fw-light"
                    >
                      Business
                    </h5>
                    <div className="col">
                      <Autocomplete
                        id="asynchronous-demo"
                        freeSolo
                        isOptionEqualToValue={(option, value) => {
                          return option.street === value.street;
                        }}
                        getOptionLabel={(option: any) => option.label}
                        options={options}
                        open={open}
                        sx={{ width: "100%" }}
                        onChange={(e, value) => {
                          const data: any = value;
                          formikHandler.handleChange({
                            target: {
                              name: "bAddress",
                              value: data?.street,
                            },
                          });
                          formikHandler.handleChange({
                            target: {
                              name: "bCity",
                              value: data?.city,
                            },
                          });
                          formikHandler.handleChange({
                            target: {
                              name: "bState",
                              value: data?.state,
                            },
                          });
                          formikHandler.handleChange({
                            target: {
                              name: "bZip",
                              value: data?.zip,
                            },
                          });
                        }}
                        onError={(e) => {
                          console.log(e);
                          setOptions([]);
                        }}
                        onBlur={(e) => {
                          setOptions([]);
                          setLoading(false);
                        }}
                        onOpen={() => {
                          setOpen(true);
                        }}
                        onClose={() => {
                          setOpen(false);
                        }}
                        filterOptions={(x) => x}
                        loading={loading}
                        noOptionsText="No Address Found"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Enter Your Address"
                            // className="autocomplete"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "no",
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  border: "1px solid #ced4da",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#ced4da",
                                },
                                "&.Mui-focused fieldset": {
                                  border: "1px solid #ced4da",
                                  boxShadow: "0 0 0 0.25rem #cdf1f4",
                                },
                              },
                            }}
                            size="small"
                            onChange={(e) => {
                              handleAddressChange(e);
                            }}
                          />
                        )}
                      />
                    </div>
                    <FormField
                      className="col-12 py-2"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "Address ",
                        prop: "bAddress",
                        placeHolder: "address here",
                      }}
                    />
                    {/* "bCity": "lahore2", */}
                    <FormField
                      className="col-sm-4 col-12 py-2"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "City",
                        prop: "bCity",
                        placeHolder: "city here",
                      }}
                    />
                    <FormField
                      className="col-sm-4 col-12 py-2"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "State",
                        prop: "bState",
                        placeHolder: "State here",
                      }}
                    />
                    {/* "bZip": "58000", */}
                    <FormField
                      className="col-sm-4 col-12 py-2"
                      formikHandler={formikHandler}
                      onChange={onchange}
                      type="text"
                      fieldName={{
                        label: "Zip",
                        prop: "bZip",
                        placeHolder: "zip here",
                      }}
                    />
                    {/* "bType": "llc", */}
                    <div className="col py-2">
                      <label className="form-label">Type</label>
                      <select
                        name="bType"
                        className={`form-select ${
                          formikHandler.touched.bType &&
                          formikHandler.errors.bType
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formikHandler.values.bType}
                        onChange={(e) => {
                          formikHandler.handleChange(e);
                          onchange(e);
                        }}
                        id=""
                      >
                        <option value="">Select Type</option>
                        <option value="Limited Liability Company">
                          Limited Liability Company
                        </option>
                        <option value="Non-Profit Org">Non-Profit Org</option>
                        <option value="Partnership">Partnership</option>
                        <option value="Private Corp">Private Corp</option>
                        <option value="Public Corp">Public Corp</option>
                        <option value="Tax Exempt">Tax Exempt</option>
                        <option value="Government">Government</option>
                      </select>
                      {formikHandler.touched.bType &&
                        formikHandler.errors.bType && (
                          <div className="invalid-feedback">
                            {formikHandler.errors.bType}
                          </div>
                        )}
                    </div>
                    {/* "bState": "CA", */}

                    {/* business summary */}
                    <div className="col-12 py-2">
                      <label className="form-label">Summary</label>
                      <textarea
                        name="bSummary"
                        className={`form-control ${
                          formikHandler.touched.bSummary &&
                          formikHandler.errors.bSummary
                            ? "is-invalid"
                            : ""
                        }`}
                        value={formikHandler.values.bSummary}
                        onChange={(e) => {
                          formikHandler.handleChange(e);
                          onchange(e);
                        }}
                        style={{
                          resize: "none",
                        }}
                        rows={6}
                        id=""
                      />
                      {formikHandler.touched.bSummary &&
                        formikHandler.errors.bSummary && (
                          <div className="invalid-feedback">
                            {formikHandler.errors.bSummary}
                          </div>
                        )}
                    </div>
                  </div>
                </div>

                {/* button */}
                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary rounded rounded-3 mt-4 px-5"
                    type="submit"
                  >
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default BusinessInfo;

import React, { useEffect, useState } from "react";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { RegistrationRequest } from "../../../helper/provider/Auth";
import {
  logIn,
  signUpProvider,
  statusSelector,
} from "../../../reducers/authReducer";
import { AppDispatch } from "../../../store";
import { setAuth } from "../../../utils/AuthHelpers";
import { registrationTypes } from "./type";
import { initialRegistrationState } from "./initialState";
import { ErrorMessage, Field, Form, Formik } from "formik";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import * as Yup from "yup";
import Spinner from "../../../components/common/Spinner";
import useCoordinates from "../../../hooks/useRandomCordinates";

type actionType = {
  loading: boolean;
  error: string | null | unknown;
};

const Registration = () => {
  const [registrationData, setRegistrationData] = useState<registrationTypes>();
  const [coordinates, generateCoordinates] = useCoordinates();

  useEffect(() => {
    generateCoordinates();
  }, []);

  const [action, setAction] = useState<actionType>({
    loading: false,
    error: null,
  });

  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const [toggle, setToggle] = useState(false);
  const [toggle2, setToggle2] = useState(false);
  const [mobileError, setMobileError] = useState(true);

  // const handleChange = (e: any) => {
  //   setRegistrationData({
  //     ...registrationData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    mobileNo: Yup.string().required("Phone Number is required"),
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])[a-zA-Z0-9!@#%&]{8,}$/,
        "Password must contain at least 8 characters, one uppercase, one lowercase, one number and one special case character"
      )
      .required("Password is required"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords don't match")
      .required("Password Confirmation is required"),
  });

  const status = useSelector(statusSelector);

  return (
    <div className="container-fluid px-0 h-110-hidden h-100-hidden">
      <div className="row w-100 m-0 p-0">
        <div className="col-lg-6 p-0 bg-white">
          <div style={{ height: "auto" }}>
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                className="me-2"
                height={35}
                width={35}
                src="/images/KZH-PRO.jpeg"
                alt=""
              />
              <img src="/images/KZH-Written.svg" alt="" />
            </div>
            <div className="m-5 py-md-0 px-md-5 p-0 rounded">
              <h4 className="auth-heading text-center mb-4">
                Create an Account
              </h4>
              <div className="form">
                <Formik
                  initialValues={initialRegistrationState}
                  validationSchema={validationSchema}
                  onSubmit={(values: any) => {
                    const finalData = {
                      ...values,
                      mobileNo: registrationData?.mobileNo,
                      latitude: coordinates?.lat ? coordinates?.lat : 0,
                      longitude: coordinates?.lng ? coordinates?.lng : 0,
                    };
                    if (mobileError) {
                      dispatch(signUpProvider({ data: finalData, navigate }));
                    }
                    // console.log("values", values);
                  }}
                >
                  {({ errors, touched, values, handleBlur, handleChange }) => (
                    <Form>
                      <div className="row">
                        <div className="col-md-6 form-group">
                          <label htmlFor="firstName" className="form-label">
                            First Name
                          </label>
                          <Field
                            name="firstName"
                            type="text"
                            onChange={handleChange}
                            className={`form-control py-2 ${
                              errors.firstName && touched.firstName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="firstName"
                            placeholder="Enter first name"
                          />
                          <ErrorMessage
                            name="firstName"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <label htmlFor="lastName" className="form-label">
                            Last Name
                          </label>
                          <Field
                            name="lastName"
                            type="text"
                            onChange={handleChange}
                            className={`form-control py-2 ${
                              errors.lastName && touched.lastName
                                ? "is-invalid"
                                : ""
                            }`}
                            id="lastName"
                            placeholder="Enter last name"
                          />
                          <ErrorMessage
                            name="lastName"
                            component="span"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col form-group">
                          <label htmlFor="mobileNo" className="form-label">
                            Phone number
                          </label>
                          <PhoneInputWithCountrySelect
                            defaultCountry="US"
                            onBlur={() => {
                              if (registrationData.mobileNo) {
                                setMobileError(
                                  isValidPhoneNumber(
                                    registrationData.mobileNo.toString()
                                  )
                                );
                              }
                            }}
                            name="mobileNo"
                            id="mobileNo"
                            className={`form-control py-2 ${
                              (errors.mobileNo && touched.mobileNo) ||
                              !mobileError
                                ? "is-invalid"
                                : ""
                            }`}
                            international
                            placeholder="Enter phone number"
                            value={registrationData?.mobileNo}
                            onChange={(e) => {
                              handleChange({
                                target: {
                                  value: e?.length > 0 ? Math.random() : "",
                                  name: "mobileNo",
                                },
                              });
                              setRegistrationData({
                                ...registrationData,
                                mobileNo: e,
                              });
                              // console.log("e=>", e);
                            }}
                          />
                          <ErrorMessage
                            name="mobileNo"
                            component="span"
                            className="invalid-feedback"
                          />
                          {!mobileError && (
                            <span className="text-danger">
                              Invalid phone number
                            </span>
                          )}
                        </div>
                        {/* <div className="col-md-6 form-group mt-3 mt-md-0">
                      <label htmlFor="customerType" className="form-label">
                        Customer Type
                      </label>
                      <input
                        id="customerType"
                        name="customerType"
                        className="form-control py-2"
                        aria-label="Default select example"
                        defaultValue={"Provider"}
                        readOnly={true}
                      // onChange={handleChange}
                      />
                    </div> */}
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-6 form-group">
                          <label htmlFor="password" className="form-label">
                            Password
                          </label>
                          <div className="input-group">
                            <Field
                              name="password"
                              onChange={handleChange}
                              type={toggle ? "text" : "password"}
                              className={`form-control py-2 ${
                                errors.password && touched.password
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="password"
                              placeholder="Enter password"
                            />
                            <span
                              onClick={() => setToggle(!toggle)}
                              className="input-group-text px-md-2 px-1"
                              id="basic-addon1"
                            >
                              {toggle ? (
                                <RemoveRedEyeIcon fontSize="small" />
                              ) : (
                                <VisibilityOffIcon fontSize="small" />
                              )}
                            </span>
                            <ErrorMessage
                              name="password"
                              component="span"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                          <label
                            htmlFor="password_confirmation"
                            className="form-label"
                          >
                            Confirm Password
                          </label>
                          <div className="input-group">
                            <Field
                              name="password_confirmation"
                              onChange={handleChange}
                              type={toggle2 ? "text" : "password"}
                              className={`form-control py-2 ${
                                errors.password_confirmation &&
                                touched.password_confirmation
                                  ? "is-invalid"
                                  : ""
                              }`}
                              id="password_confirmation"
                              placeholder="Enter password again"
                            />
                            <span
                              onClick={() => setToggle2(!toggle2)}
                              className="input-group-text px-md-2 px-1"
                              id="basic-addon1"
                            >
                              {toggle2 ? (
                                <RemoveRedEyeIcon fontSize="small" />
                              ) : (
                                <VisibilityOffIcon fontSize="small" />
                              )}
                            </span>
                            <ErrorMessage
                              name="password_confirmation"
                              component="span"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      <p className="fs-6 text-center auth-tagline mt-4 mb-3">
                        By creating an account you agree to our{" "}
                        <a
                          rel="noreferrer"
                          className="d-inline-block"
                          target="_blank"
                          href="https://www.kazzah.com/user-agreement"
                          style={{ color: "#04BAC7", fontWeight: "600" }}
                        >
                          Terms of Service
                        </a>{" "}
                        and{" "}
                        <a
                          rel="noreferrer"
                          className="d-inline-block"
                          target="_blank"
                          href="https://www.kazzah.com/privacy-agreement"
                          style={{ color: "#04BAC7", fontWeight: "600" }}
                        >
                          Privacy Policy
                        </a>
                      </p>
                      <button
                        style={{ backgroundColor: "#04BAC7" }}
                        className="btn text-white rounded rounded-3 w-100 mt-1 py-2"
                        type="submit"
                      >
                        {status === "loading" ? (
                          <>
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            <span className="sr-only">Loading...</span>
                          </>
                        ) : (
                          <>Save & Next</>
                        )}
                      </button>
                    </Form>
                  )}
                </Formik>
              </div>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center mt-3"
              >
                Already have an account?{" "}
                <Link
                  to="/auth/login"
                  style={{ color: "#04BAC7", fontWeight: "600" }}
                >
                  Log in
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0 d-lg-block d-none">
          <div
            style={{ height: "110vh", backgroundColor: "#eff3fc" }}
            className="align-items-center d-flex justify-content-center bg-register"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Registration;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createCredentialsRequest,
  deleteCredentialsRequest,
  getAllCredentialsRequest,
  getOneCredentialsRequest,
  updateCredentialsRequest,
} from "../../helper/provider/credentials";
import { RootState } from "../../store";
import { makeResponseError } from "../../utils/errorHandler";

export interface scheduleState {
  credentials: Array<any>;
  status: string;
  createStatus: string;
  error: string | null;
}

export const initialState: scheduleState = {
  credentials: [],
  status: "idle",
  createStatus: "idle",
  error: null,
};

export const fetchAllCredentials = createAsyncThunk(
  "get/credentials",
  async () => {
    try {
      const res: any = await getAllCredentialsRequest();
      const { data, success } = res.data;
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const fetchOneCredential = createAsyncThunk(
  "get/credential",
  async (id: number) => {
    try {
      const res: any = await getOneCredentialsRequest(id);
      const { data, success } = res.data;
      return {
        data,
        success,
        code: 200,
      };
    } catch (error) {
      return error;
    }
  }
);

export const createCredential = createAsyncThunk(
  "create/credential",
  async (formData: any) => {
    try {
      const res: any = await createCredentialsRequest(formData.data);
      const { data, success, message } = res.data;
      if (success) {
        formData.handleClose();
      }
      // console.log(data);

      return {
        data,
        success,
        code: 201,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

export const updateCredential = createAsyncThunk(
  "update/credential",
  async ({
    id,
    formData,
    index,
    handleClose,
  }: {
    id: number;
    formData: any;
    index: number;
    handleClose: any;
  }) => {
    try {
      const res: any = await updateCredentialsRequest(id, formData);
      const { data, success } = res.data;
      if (success) {
        handleClose();
      }
      // console.log("data", data);

      return {
        index,
        data,
        success,
        code: 200,
      };
    } catch (error) {
      // return makeResponseError(error.response.data);
      return error;
    }
  }
);

export const deleteCredential = createAsyncThunk(
  "delete/credential",
  async (id: number) => {
    try {
      const res: any = await deleteCredentialsRequest(id);
      const { success } = res.data;
      return {
        id,
        success,
        code: 200,
      };
    } catch (error) {
      return makeResponseError(error.response.data);
    }
  }
);

const credentialSlice = createSlice({
  name: "credentials",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAllCredentials.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(fetchAllCredentials.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      if (success && code === 200) {
        state.status = "succeeded";
        state.credentials = data;
      }
    });
    builder.addCase(fetchAllCredentials.rejected, (state, action) => {
      state.status = "failed";
    });
    builder.addCase(createCredential.pending, (state, action) => {
      state.createStatus = "loading";
    });
    builder.addCase(createCredential.fulfilled, (state, action) => {
      const { data, success, code } = action.payload;
      if (success && code === 201) {
        state.credentials.push(data);
        state.createStatus = "succeeded";
      }
    });
    builder.addCase(createCredential.rejected, (state, action) => {
      state.createStatus = "failed";
    });

    builder.addCase(updateCredential.pending, (state, action) => {
      state.createStatus = "loading";
    });
    builder.addCase(updateCredential.fulfilled, (state, action) => {
      const { index, success, code } = action.payload;
      if (success && code === 200) {
        state.createStatus = "succeeded";
        // console.log("key", k);
        // console.log("data", state.credentials[k]);

        state.credentials[index] = action.payload.data;
      }
    });
    builder.addCase(updateCredential.rejected, (state, action) => {
      state.createStatus = "failed";
    });

    builder.addCase(deleteCredential.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(deleteCredential.fulfilled, (state, action) => {
      const { success, code, id } = action.payload as {
        success: boolean;
        code: number;
        id: number;
      };
      if (success && code === 200) {
        state.status = "succeeded";
        state.credentials = state.credentials.filter((cred) => cred.id !== id);
      }
    });
    builder.addCase(deleteCredential.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default credentialSlice.reducer;

export const credentialsSelector = (state: RootState) =>
  state.credential.credentials;

export const statusSelector = (state: RootState) => state.credential.status;
export const updateStatusSelector = (state: RootState) =>
  state.credential.createStatus;

export const createStatusSelector = (state: RootState) =>
  state.credential.createStatus;

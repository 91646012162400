import React from "react";
import { levelTwoCardType } from "../type";

const LevelTwo = ({ icon, name }: levelTwoCardType) => {
  return (
    <div className="d-flex flex-column  justify-content-center align-items-center py-2">
      <img
        className="rounded-circle mb-1"
        style={{ objectFit: "contain" }}
        height={35}
        width={35}
        src={icon}
        alt=""
      />
      <p style={{ fontSize: "14px" }} className="mb-0 text-capitalize">
        {name.substring(0, 18) + (name.length > 18 ? "..." : "")}
      </p>
    </div>
  );
};

export default LevelTwo;

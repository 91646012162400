import { urlParameter } from "../components/common/types";
import { baseUrl } from "../config/config";

// todo: this will be replace with .env variable https://sit.kazzah.co.uk
const BASE_URL = baseUrl.api;

const PROVIDER_PREFIX = "/api/provider/v1";
const MEMBER_PREFIX = "/api/member/v1";
const CONFIGURE_PREFIX = "/api/v1/configuration";

const provider_URLS = {
  Auth: {
    LOGIN: `${BASE_URL}/oauth/token`,
    REGISTRATION: `${BASE_URL}${PROVIDER_PREFIX}/registration`,
    OTP: `${BASE_URL}${PROVIDER_PREFIX}/verify-code`,
    RESEND_OTP: `${BASE_URL}${PROVIDER_PREFIX}/resend-code`,
    USER_PROFILE: `${BASE_URL}${PROVIDER_PREFIX}/auth-user`,
    PROVIDER_PROFILE: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/auth-user/${id}`,
    DELETE_PROVICER_PICTURE: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/delete-profile-pic/${id}`,
    UPDATE_PROFILE: `${BASE_URL}${PROVIDER_PREFIX}/update-profile`,
    PROFILE_PHOTO: `${BASE_URL}${PROVIDER_PREFIX}/profile-photo`,
    PROFILE_PROGRESS: `${BASE_URL}${PROVIDER_PREFIX}/progress`,
    FORGOT_PASS: `${BASE_URL}${PROVIDER_PREFIX}/forgot-password`,
    RESET_PASS: `${BASE_URL}${PROVIDER_PREFIX}/reset-password`,
    VERIFY_RESET_CODE: `${BASE_URL}${PROVIDER_PREFIX}/verify-reset-code`,
    PROVIDER_PUBLIC_PROFILE: (id: urlParameter) =>
      `${BASE_URL}/api/v1/provider/${id}`,
    GET_DASHBOARD_DATA: `${BASE_URL}${PROVIDER_PREFIX}/dashboard`,
    DELETE_ACCOUNT: `${BASE_URL}${PROVIDER_PREFIX}/delete`,
  },
  services: {
    getOneByParent: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/services/${id}`,
    getThirdLevel: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/provider-services/services/${id}`,
    getFullRoot: `${BASE_URL}${PROVIDER_PREFIX}/root-services/fullResponse`,
    getRoot: `${BASE_URL}${PROVIDER_PREFIX}/root-service`,
    ALL_UPDATE: `${BASE_URL}${PROVIDER_PREFIX}/service-update`,
    CREATE: `${BASE_URL}${PROVIDER_PREFIX}/service`,
    update: `${BASE_URL}${PROVIDER_PREFIX}/individual-service-update`,
    updateStatus: `${BASE_URL}${PROVIDER_PREFIX}/update-service-status`,
    getOwnAll: `${BASE_URL}${PROVIDER_PREFIX}/provider-services`,
  },

  schedule: {
    PROVIDER_SCHEDULE: `${BASE_URL}${PROVIDER_PREFIX}/schedule`,
    SCHEDULE_ACTIVE_STATUS: `${BASE_URL}${PROVIDER_PREFIX}/schedule-status`,
    PROVIDER_VACATIONS: `${BASE_URL}${PROVIDER_PREFIX}/vacations`,
    DELETE_PROVIDER_VACATIONS: (id: number) =>
      `${BASE_URL}${PROVIDER_PREFIX}/vacations/${id}`,
    DELETE_PROVIDER_SCHEDULE: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/schedule/${id}`,
    PROVIDER_SCHEDULE_UPDATE: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/schedule/${id}`,
    BY_DATE: `${BASE_URL}${PROVIDER_PREFIX}/appointments-by-date`,
    SCHEDULE_STATUS_UPDATE: `${BASE_URL}${PROVIDER_PREFIX}/schedule-status`,
    SERVICE_PLAY_PAUSE: `${BASE_URL}${PROVIDER_PREFIX}/service-pause`,
  },

  credentials: {
    CREDENTIALS_MAIN: `${BASE_URL}${PROVIDER_PREFIX}/credential`,
    CREDENTIALS_SINGLE: (id: number) =>
      `${BASE_URL}${PROVIDER_PREFIX}/credential/${id}`,
  },

  media: {
    MEDIA_MAIN: `${BASE_URL}${PROVIDER_PREFIX}/media`,
    MEDIA_ALL_LINK: `${BASE_URL}${PROVIDER_PREFIX}/social-media-links`,
    MEDIA_CONFIG: `${BASE_URL}${CONFIGURE_PREFIX}/socialLinks`,
    MEDIA_EXPLORE: `${BASE_URL}${PROVIDER_PREFIX}/media-all`,
    PROVIDER_MEDIA: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/get-media/${id}`,
    PAGINATED_MEDIA_EXPLORE: `${BASE_URL}/api/v2/provider/media-all`,
    MEDIA_LIKE: `${BASE_URL}${PROVIDER_PREFIX}/media-like`,
    DELETE_MEDIA: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/media/${id}`,
    APPOINTMENT_MEDIA_PRIVACY: `${BASE_URL}${PROVIDER_PREFIX}/appointment/media/privacy`,
    // MEDIA_VACATIONS: `${BASE_URL}${MEDIA_PREFIX}/vacations`,
    // MEDIA_SCHEDULE_UPDATE: (id: urlParameter) =>
    //   `${BASE_URL}${PROVIDER_PREFIX}/schedule/${id}`,
  },

  payments: {
    CREATE_STORE: `${BASE_URL}${PROVIDER_PREFIX}/finix-business`,
    GET_TYPES: `${BASE_URL}${PROVIDER_PREFIX}/finix-business-types`,
    GET_RECORD: `${BASE_URL}${PROVIDER_PREFIX}/get-payment-details`,
    GET_USER_APP_ID: (id: any, userType: any) =>
      `${BASE_URL}/api/v1/user-application-id?userType=${userType}&id=${id}`,
    PROVIDER_BOARDING: `${BASE_URL}${PROVIDER_PREFIX}/boarding`,
    GET_PROVIDER_BOARDING: `${BASE_URL}${PROVIDER_PREFIX}/boarding`,
    GET_USER_CARDS: `${BASE_URL}/api/v1/user-cards`,
  },

  appointment: {
    LIST_PENDINGv2: `${BASE_URL}/api/v2/provider/appointment-bookings`,
    LIST_BOOKING: `${BASE_URL}${PROVIDER_PREFIX}/appointment-bookings`,
    LIST_PENDING_STATUS: `${BASE_URL}${PROVIDER_PREFIX}/appointment-status`,
    RESCHEDULE_APPOINTMENT: `${BASE_URL}${PROVIDER_PREFIX}/appointment`,
    APPOINTMENT_NOTES: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/appointment-notes?appointmentId=${id}`,
    ADD_APPOINTMENT_NOTES: `${BASE_URL}${PROVIDER_PREFIX}/appointment-notes`,
    UPDATE_APPOINTMENT_NOTES: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/appointment-notes/${id}`,
    ADD_PHOTO: `${BASE_URL}${PROVIDER_PREFIX}/appointment/add-photo`,
    DELETE_MEDIA: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/media/${id}`,
    CREATE_APPOINTMENT: `${BASE_URL}${PROVIDER_PREFIX}/appointment-create`,
    GET_SLOTS: `${BASE_URL}${PROVIDER_PREFIX}/time-slots`,
  },

  chat: {
    CHAT_GROUP: `${BASE_URL}${PROVIDER_PREFIX}/chat-thread`,
    CHAT_GROUPS: `${BASE_URL}${PROVIDER_PREFIX}/chat-threads`,
    SEND_MSG: `${BASE_URL}${PROVIDER_PREFIX}/send`,
    GET_USERS: `${BASE_URL}${PROVIDER_PREFIX}/chat/users`,
    GET_USERS_MESSAGES: `${BASE_URL}${PROVIDER_PREFIX}/chat/messages`,
    UPDATE_GROUP_STATUS: `${BASE_URL}${PROVIDER_PREFIX}/read-status`,
    CHAT_SEARCH: `${BASE_URL}${PROVIDER_PREFIX}/chat/search`,
  },

  member: {
    MEMBER_LIST: `${BASE_URL}${PROVIDER_PREFIX}/connections`,
    GET_MEMBER_PROFILE: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/client/${id}`,
    GET_MEMBER_CONNECT: (id: urlParameter) =>
      `${BASE_URL}${PROVIDER_PREFIX}/connections/${id}`,
    ADD_MEMBER: `${BASE_URL}${PROVIDER_PREFIX}/create-member-connection`,
    VIC_MEMBER: `${BASE_URL}${PROVIDER_PREFIX}/isVic`,
    DISCONNECT_MEMBER: `${BASE_URL}${PROVIDER_PREFIX}/disconnect`,
    CONNECTION_APPROVAL: `${BASE_URL}${PROVIDER_PREFIX}/connection-update`,
  },

  NOTIFICATION: {
    SEND_NOTIFICATION: `${BASE_URL}/api/v1/send-notification`,
    SIGNUP_NOTIFICATION: `${BASE_URL}/api/v1/user-devices`,
    GET_NOTIFICATION_TRIGERS: `${BASE_URL}/api/v1/notification-triggers`,
    CHAT_NOTIFICATION: `${BASE_URL}/api/v1/chat-notification`,
    ALL_NOTIFICATION: `${BASE_URL}/api/v1/notifications-list`,
    DELETE_NOTIFICATION: (id: urlParameter) =>
      `${BASE_URL}/api/v1/notifications/delete/${id}`,
    MARK_AS_READ: `${BASE_URL}/api/v1/notifications-status`,
  },

  TRIANGULATION: {
    TGL_UPDATE: `${BASE_URL}/api/v1/shared-connections`,
  },

  // end providers urls
};

const member_URLS = {
  Auth: {
    LOGIN: `${BASE_URL}/oauth/token`,
    REGISTRATION: `${BASE_URL}${MEMBER_PREFIX}/registration`,
    OTP: `${BASE_URL}${MEMBER_PREFIX}/verify-code`,
    USER_PROFILE: `${BASE_URL}${MEMBER_PREFIX}/`,
  },
};

export { provider_URLS, member_URLS };

import { ErrorMessage, Form, Formik } from "formik";
import React, { useState } from "react";
import OtpInput from "react-otp-input";
// import PhoneInputWithCountrySelect from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// import { VerifyCode } from "../../../helper/provider/Auth";
import {
  statusSelector,
  verifyResendCode,
  // verifyProviderToken,
  verifyResetCode,
} from "../../../reducers/authReducer";
import { AppDispatch } from "../../../store";
import * as yup from "yup";
import Spinner from "../../../components/common/Spinner";
import { localStorageObject } from "../../../utils";
import { MOBILE_NUMBER, TOKEN } from "../../../config/constant";
// import { VerifyCode } from "../../../helper/member/Auth";
// import OtpInput from 'react-otp-input';

// type DataType = {
//   otp?: any;
// };

const VerifyResetCode = () => {
  // const [registrationData, setRegistrationData] =
  //     useState<registrationDataType>();

  const navigate = useNavigate();

  const [otp, setOtp] = useState<any>({
    mobileNo: localStorageObject.get(MOBILE_NUMBER),
    code: "",
  });

  const token = localStorageObject.get(TOKEN);

  const handleClick = () => {
    dispatch(verifyResendCode(token));
  };

  // const handleChange = (e: any) => {
  //   setOtp(e.target.value);
  //   console.log("code", otp);
  // };

  // const { access_token } = useSelector((state: any) => state.auth);

  // const handleSubmit = (e: any) => {
  //   e.preventDefault();
  //   const finalData = {
  //     code: otp,
  //   };

  //   const token = localStorage.getItem("token");
  //   dispatch(verifyProviderToken({ data: finalData, token: token, navigate }));
  //   console.log("otp => ", otp);
  // };

  const status = useSelector(statusSelector);
  const mobileNo = localStorageObject.get(MOBILE_NUMBER);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="container-fluid px-0 h-100-hidden">
      <div className="row w-100 m-0 p-0">
        <div className="col-lg-6 p-0 bg-white">
          <div style={{ height: "auto" }}>
            <div className="d-flex justify-content-center mt-5 mb-4">
              <img
                className="me-2"
                height={35}
                width={35}
                src="/images/KZH-PRO.jpeg"
                alt=""
              />
              <img src="/images/KZH-Written.svg" alt="" />
            </div>
            <div className="m-5 py-md-0 px-md-5 p-0 rounded">
              <h3
                style={{ fontFamily: "Poppins, sans-serif" }}
                className="fw-bolder text-center"
              >
                Check your Phone
              </h3>
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center fw-bold mb-4"
              >
                We sent a verification code to your {mobileNo}
              </p>
              <div className="form">
                <Formik
                  initialValues={{ code: "" }}
                  validationSchema={yup.object().shape({
                    code: yup
                      .string()
                      .min(1, "Fill all the fields")
                      .required("Verification Code is required"),
                  })}
                  onSubmit={(values) => {
                    // handleSubmit(values);
                    const finalData = {
                      ...values,
                      code: otp,
                    };
                    dispatch(
                      verifyResetCode({
                        data: finalData,
                        navigate,
                      })
                    );
                    // console.log("values =>", finalData);
                  }}
                >
                  {({ errors, touched, values, handleBlur, handleChange }) => (
                    <Form>
                      <div className="mt-3 form-group">
                        <div style={{ width: "55%" }} className="m-auto">
                          <label htmlFor="" className="form-label ms-1">
                            6 Digit Verification Code
                          </label>
                          <OtpInput
                            value={otp.code}
                            onChange={(e: any) => {
                              handleChange({
                                target: {
                                  value: e?.length > 0 ? Math.random() : "",
                                  name: "code",
                                },
                              });
                              setOtp({
                                ...otp,
                                code: e,
                              });
                              // console.log("otp", otp);
                            }}
                            focusStyle="text-black"
                            containerStyle="text-black"
                            numInputs={6}
                            isInputNum={true}
                            separator={""}
                            inputStyle={`form-control py-2 verify-height fs-1 text-dark p-0 mx-1 ${
                              errors.code && touched.code ? "border-danger" : ""
                            }`}
                          />
                          <ErrorMessage
                            name="code"
                            component="span"
                            className="invalid-feedback"
                          />
                          <button
                            style={{ backgroundColor: "#04BAC7" }}
                            className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                            type="submit"
                          >
                            {status === "loading" ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Loading...</span>
                              </>
                            ) : (
                              <>Verify</>
                            )}
                          </button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              {status === "loading" ? (
                ""
              ) : (
                <p
                  style={{
                    fontFamily: "Poppins, sans-serif",
                    fontWeight: "500",
                  }}
                  className="fs-6 text-center fw-bold mt-4"
                >
                  Didn't receive the code?{" "}
                  {/* <div
                    onClick={handleClick}
                    style={{ color: "#04BAC7" }}
                    className="pointer"
                  >
                    Click to resend
                  </div> */}
                </p>
              )}
              <p
                style={{ fontFamily: "Poppins, sans-serif", fontWeight: "500" }}
                className="fs-6 text-center fw-bold mt-5 align-items-center d-flex justify-content-center"
              >
                <Link style={{ color: "black" }} to="/auth/login">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    fill="currentColor"
                    className="bi bi-arrow-left-short me-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z"
                    />
                  </svg>
                  Back to log in
                </Link>
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-0 d-lg-block d-none">
          <div
            style={{ height: "110vh", backgroundColor: "#eff3fc" }}
            className="align-items-center d-flex justify-content-center bg-register"
          ></div>
        </div>
      </div>
    </div>
  );
};

export default VerifyResetCode;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getPendingAppointmentList } from "../../reducers/appointmentReducer";
import { getDashBoardDataAction } from "../../reducers/authReducer";
import { AppDispatch } from "../../store";
import ContactBox from "./components/ContactBox";
import InfoCardds from "./components/InfoCards";
import PendingRequestSection from "./components/PendingRequestSection";
import StepsInfoCard from "./components/StepsInfoCard";
import ProCalendar from "./components/Calendar/ProCalendar";
import { onForegroundMessage } from "../../Firebase/lib";
import { toast } from "react-hot-toast";

export default function Dashboard() {
  const dispatch = useDispatch<AppDispatch>();

  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };

  useEffect(() => {
    dispatch(
      getPendingAppointmentList({
        status:
          "pending,declineByProvider,approveByProvider,completed,rescheduleByProvider",
      })
    );
    dispatch(getDashBoardDataAction());
    return () => {};
  }, []);

  useEffect(() => {
    onForegroundMessage()
      .then((payload) => {
        // console.log("Received foreground message: ", payload);
        const {
          notification: { title, body },
        }: any = payload;
        // toast(<ToastifyNotification title={title} body={body} />);
      })
      .catch((err) =>
        console.log(
          "An error occured while retrieving foreground message. ",
          err
        )
      );
  }, []);

  // const ToastifyNotification = ({ title, body }) => (
  //   <div className="push-notification">
  //     <h2 className="push-notification-title">{title}</h2>
  //     <p className="push-notification-text">{body}</p>
  //   </div>
  // );

  return (
    <>
      <div style={poppinsFont} className="row">
        <div className="col-xl-8">
          <StepsInfoCard />
          <InfoCardds />
          {/* <h5
            className="fw-bold mb-3"
            style={{ fontSize: "19px", marginTop: "2rem" }}
          >
            Team Whole Categories
          </h5> */}
          <ProCalendar />
          {/* <TeamCategories /> */}
        </div>
        {/* <button
          onClick={() => {
            getFCMToken().then((rezToken) => {
              console.log(" rezToken : ", rezToken);
            });
          }}
        >
          get token{" "}
        </button> */}
        <div className="col-xl-4 mt-xl-2 mt-4">
          {/* <PendingAppointments /> */}
          <PendingRequestSection />
          {/* <ChatBox /> */}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ContactBox />
        </div>
      </div>
    </>
  );
}

import { client } from "../../../api/client";
import { provider_URLS } from "../../../api/Urls";

export const addChatGroupRequest = (data: any) =>
  client.post(provider_URLS.chat.CHAT_GROUPS, data);

export const fetchChatGroupRequest = () =>
  client.get(provider_URLS.chat.CHAT_GROUP);

export const sendChatNotification = async (data: unknown) => {
  try {
    client.post(provider_URLS.NOTIFICATION.CHAT_NOTIFICATION, data);
  } catch (error) {
    return error;
  }
};

export const getUsersRequest = () =>
  client.get(provider_URLS.chat.GET_USERS, {
    headers: {
      Accept: "application/json",
    },
  });

export const getUsersMessagesRequest = (data: unknown) =>
  client.get(provider_URLS.chat.GET_USERS_MESSAGES, {
    params: {
      group: data,
    },
  });

export const sendChatMessage = (data: unknown) =>
  client.post(provider_URLS.chat.SEND_MSG, data);

export const updateGroupStatusRequest = (data: {
  group: string;
  isRead: number;
}) =>
  client.post(
    provider_URLS.chat.UPDATE_GROUP_STATUS,
    {},
    {
      params: {
        group: data.group,
        isRead: data.isRead,
      },
    }
  );

export const getSearchUsersRequest = (data: string) =>
  client.get(provider_URLS.chat.CHAT_SEARCH, {
    params: {
      query: data,
    },
  });

import React from 'react'

const Greeting = ({ userData }) => {
    var today = new Date()
    var curHr = today.getHours()
    return (
        <div className="d-flex align-items-center justify-content-md-start justify-content-center my-md-0 my-3">
            <div>
                <img height={25} src={curHr < 12 ? '/images/morninig.png' : curHr < 18 ? '/images/afternoon.png' : '/images/night.png'} alt="" />
            </div>
            <div>
                <div className="d-flex align-items-end">
                    <h5 className="text-dark pb-0 mb-0 ms-2 text-capitalize">
                        {
                            curHr < 12 ? 'good morning,'
                                : curHr < 18 ? 'good afternoon,'
                                    : 'good evening,'
                        }
                    </h5>
                    <h5 className="text-dark pb-0 mb-0 ms-2 text-capitalize">
                        <>
                            {userData?.firstName ? userData?.firstName + " " + userData?.lastName : "User"}
                        </>
                    </h5>
                </div>
            </div>
        </div>
    )
}

export default Greeting
import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileProgressRequest,
  loggedInUserProfile,
} from "../../reducers/authReducer";
import { AppDispatch, RootState } from "../../store";
import { fetchNotificationTriggers } from "../../reducers/applicationReducer";
import Header from "./components/Header";
import SideBar from "./components/SideBar";
import echo from "../../hooks/useWebSockets";
import { makeNotification } from "../../utils/factory";
import { addNotification } from "../../reducers/notificationReducer";
import NotificationToast from "./components/NotificationToast";
import ChatIcon from "@mui/icons-material/Chat";
import { toast } from "react-hot-toast";
import HourglassTop from "@mui/icons-material/HourglassTop";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";

const drawerWidth = 280;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

type MiniDrawerProps = {
  children: React.ReactNode;
};
export default function MiniDrawer({ children }: MiniDrawerProps) {
  const [open, setOpen] = React.useState(false);
  const authUser = useSelector(loggedInUserProfile);

  const isDrawerOpen = useSelector((state: RootState) => state.app.drawer);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    if (authUser?.id !== 0) {
      echo
        .private(`notification-${authUser?.id}-providers`)
        .listen(".notification-chat", (data: any) => {
          if (!data?.message?.group) {
            // console.log("data", data);
            if (data?.message?.event?.includes("CHAT")) {
              toast(
                <NotificationToast
                  icon={<ChatIcon className="text-primary me-2" />}
                  message={data?.message?.message}
                />,
                {
                  position: "top-right",
                }
              );
            } else if (data?.message?.event?.includes("APPOINTMENT")) {
              toast(
                <NotificationToast
                  icon={<HourglassTop className="text-primary me-2" />}
                  message={data?.message?.message}
                />,
                {
                  position: "top-right",
                }
              );
            } else {
              toast(
                <NotificationToast
                  icon={
                    <NotificationsActiveIcon
                      className="me-2"
                      sx={{
                        color: "#f6c428ff",
                      }}
                    />
                  }
                  message={data?.message?.message}
                />,
                {
                  position: "top-right",
                }
              );
            }
            const finalData = {
              ...data?.message,
              isRead: 0,
            };
            const notification = makeNotification([finalData]);
            dispatch(addNotification(notification?.[0]));
          }
        });
    }
    return () => {};
  }, [authUser?.id]);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;

    if (width > 860) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
    return {
      width,
      height,
    };
  }

  React.useMemo(() => {
    if (window.screen.width > 860) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [window.screen.width]);

  React.useEffect(() => {
    window.addEventListener("resize", (e) => {
      getWindowDimensions();
    });
    // return unSubscribe();
    if (window.screen.width > 800) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  React.useEffect(() => {
    dispatch(getProfileProgressRequest());

    return () => {};
  }, []);

  React.useEffect(() => {
    // dispatch(getProfileProgressRequest());
    dispatch(fetchNotificationTriggers());
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        style={{
          boxShadow:
            isDrawerOpen == true
              ? "2px 0px 1px 1px white"
              : "20px 0px 30px 4px #dcdcdc",
        }}
        className={`bg-white ${isDrawerOpen ? "d-none" : "d-block"}`}
        position="fixed"
        open={open}
      >
        <Header />
      </AppBar>
      <div
        style={{ boxShadow: "0px 0px 30px 20px #dcdcdc" }}
        className="h-auto bg-white"
      >
        <SideBar open={open} DrawerHeader={DrawerHeader} />
      </div>
      <Box
        style={{ backgroundColor: "#eff3fc" }}
        component="main"
        sx={{ flexGrow: 1, p: open ? 3 : 1 }}
        className="row w-100 mx-auto mb-auto overflow-auto"
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}

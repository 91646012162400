import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleMultiToMultiBreakTimeMeridiemChange,
  updateMultiToMultiBreakTime,
  updateOneToOneBreakTime,
  handleOnrToOneBreakTimeMeridiemChange,
  scheduleOnToOneSelector,
  removeOneToOneBreakTime,
  removeMultiToMultiBreakTime,
} from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import { convert24To12 } from "../../../../../utils";
import DeleteIcon from "@mui/icons-material/Delete";

const DisplayTime = ({
  id,
  startTime,
  fullStartTime,
  fullEndTime,
  startMeridiem,
  endTime,
  endMeridiem,
  type,
  setChange,
  change,
}) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleMultiChange = (e: any) => {
    let tempTime = convert24To12(e.target.value);
    dispatch(
      updateMultiToMultiBreakTime({
        key: e.target.name,
        value: tempTime,
        idx: id,
      })
    );
  };

  const removeBrTime = (index) => {
    dispatch(removeOneToOneBreakTime(index));
  };

  const [error, setError] = useState({
    timeDiff: false,
    sBreak: false,
    sDay: false,
  });

  const [errorTime, setErrorTime] = useState(false);

  const fullTime = moment(fullStartTime, "hh:mm A").format("HH:mm");
  const fullETime = moment(fullEndTime, "hh:mm A").format("HH:mm");

  // console.log("fullTime =>", fullTime);
  // console.log("fullETime =>", fullETime);

  const oneToOne = useSelector(scheduleOnToOneSelector);

  useEffect(() => {
    const timeDiff = moment(fullETime, "HH:mm").diff(
      moment(fullTime, "HH:mm"),
      "minutes"
    );
    if (timeDiff < 15) {
      setErrorTime(true);
    } else {
      setErrorTime(false);
    }
    if (
      moment(oneToOne?.startTime, "hh:mm A").format("HH:mm") ===
      moment(fullStartTime, "hh:mm A").format("HH:mm")
    ) {
      setError({
        ...error,
        sDay: true,
      });
    } else {
      // console.log("else", oneToOne?.startTime, fullStartTime);

      setError({
        ...error,
        sDay: false,
      });
    }
    console.log("error =>", error);
  }, [fullTime, fullETime]);

  const handleSingleChange = (e: any) => {
    let tempTime = convert24To12(e.target.value);
    setChange({
      ...change,
      break: true,
    });
    dispatch(
      updateOneToOneBreakTime({
        key: e.target.name,
        value: tempTime,
        idx: id,
      })
    );
  };

  const handleMultiMeridiemClick = (e: any, id: any, value: string) => {
    dispatch(
      handleMultiToMultiBreakTimeMeridiemChange({
        key: e.target.name,
        value,
        idx: id,
      })
    );
  };

  const handleSingleMeridiemClick = (e: any, id: any, value: string) => {
    setChange(true);
    dispatch(
      handleOnrToOneBreakTimeMeridiemChange({
        key: e.target.name,
        value,
        idx: id,
      })
    );
  };

  return (
    <>
      {type === "multi" ? (
        <>
          <div className="row w-100 m-auto px-0 rounded-5">
            <div className="d-flex flex-md-row flex-column align-items-center col-6 ps-md-5 ps-0 py-1 justify-content-center">
              {/* <p style={{ fontSize: "14px" }} className="mb-0 me-4 text-secondary">
          Day Begin:
        </p> */}
              <div
                className="ps-xl-5 ps-0 text-center
              "
              >
                <input
                  style={{ height: 24, width: 70, fontSize: "15px" }}
                  type="time"
                  className="border-1 border-secondary rounded-6 align-items-center justify-content-center px-2 me-md-3"
                  placeholder="Select Time"
                  name="breakStartTime"
                  max="12:00"
                  onChange={handleMultiChange}
                  value={
                    startTime?.split(":")[0] < 10
                      ? "0" +
                        startTime?.split(":")[0] +
                        ":" +
                        startTime?.split(":")[1]
                      : startTime
                  }
                  id="Text1"
                />
                <div
                  className="btn-group align-items-center me-md-0 mt-md-0 mt-2"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    onClick={(e) => handleMultiMeridiemClick(e, id, "AM")}
                    style={{ fontSize: "15px" }}
                    name="breakStartTime"
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "AM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>

                  <button
                    name="breakStartTime"
                    onClick={(e) => handleMultiMeridiemClick(e, id, "PM")}
                    style={{ fontSize: "15px" }}
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "PM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    PM
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-md-row flex-column align-items-center col-6 pe-md-5 pe-0 py-1 justify-content-center">
              {/* <p style={{ fontSize: "14px" }} className="mb-0 me-4 text-secondary">
          Day End:
        </p> */}
              <div className="pe-xl-5 pe-0 text-center">
                <input
                  style={{ height: 24, width: 70, fontSize: "15px" }}
                  type="time"
                  className="border-1 border-secondary rounded-6 align-items-center justify-content-center px-2 me-md-3"
                  placeholder="Select Time"
                  name="breakEndTime"
                  max="12:00"
                  onChange={handleMultiChange}
                  id="Text1"
                  value={
                    endTime?.split(":")[0] < 10
                      ? "0" +
                        endTime?.split(":")[0] +
                        ":" +
                        endTime?.split(":")[1]
                      : endTime
                  }
                />
                <div
                  style={{ width: 62 }}
                  className="btn-group align-items-center me-md-0 me-3 mt-md-0 mt-2"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    name="breakEndTime"
                    onClick={(e) => handleMultiMeridiemClick(e, id, "AM")}
                    style={{ fontSize: "15px" }}
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      endMeridiem === "AM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>
                  <button
                    name="breakEndTime"
                    data-meridiem="PM"
                    onClick={(e) => handleMultiMeridiemClick(e, id, "PM")}
                    style={{ fontSize: "15px" }}
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      endMeridiem === "PM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    PM
                  </button>
                </div>
              </div>
            </div>
          </div>
          {errorTime ? (
            <div
              className="alert alert-danger d-flex align-items-center border border-danger danger text-danger mx-3 mt-3"
              role="alert"
            >
              <img className="me-2" src="/images/schedule/info.svg" alt="" />
              <div style={{ fontSize: "14px" }}>
                Break must be at least 15 minutes long
              </div>
            </div>
          ) : null}
          {error.sDay ? (
            <div
              className="alert alert-danger d-flex align-items-center border border-danger danger text-danger mx-3 mt-3"
              role="alert"
            >
              <img className="me-2" src="/images/schedule/info.svg" alt="" />
              <div style={{ fontSize: "14px" }}>
                Break must not be equal to start time
              </div>
            </div>
          ) : null}

          <div className="row w-100 m-auto px-0">
            <div className="col-5 ms-auto text-end offset-1">
              <DeleteIcon
                className="pointer res-margin mb-md-0 mb-2"
                onClick={() => {
                  dispatch(removeMultiToMultiBreakTime(id));
                  setChange({
                    ...change,
                    break: true,
                  });
                }}
                sx={{
                  color: "grey",
                  marginTop:
                    error.timeDiff || error.sDay ? "-13.7rem" : "-3.5rem",
                }}
              />
            </div>
            <div className="col-1"></div>
          </div>
        </>
      ) : (
        <>
          <div className="row w-100 m-auto px-0 rounded-5">
            <div className="d-flex flex-md-row flex-column align-items-center col-6 ps-md-5 ps-0 py-1 justify-content-center">
              {/* <p style={{ fontSize: "14px" }} className="mb-0 me-4 text-secondary">
          Day Begin:
        </p> */}
              <div className="ps-xl-5 ps-0 text-center">
                <input
                  style={{ fontSize: "15px", width: 70, height: 24 }}
                  type="time"
                  className="border-1 border-secondary rounded-6 align-items-center justify-content-center px-2 me-md-3"
                  placeholder="Select Time"
                  name="breakStartTime"
                  max="12:00"
                  onChange={handleSingleChange}
                  defaultValue={
                    startTime?.split(":")[0] < 10
                      ? "0" +
                        startTime?.split(":")[0] +
                        ":" +
                        startTime?.split(":")[1]
                      : startTime
                  }
                  id="Text1"
                />
                <div
                  className="btn-group mt-md-0 mt-2"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    onClick={(e) => handleSingleMeridiemClick(e, id, "AM")}
                    style={{ fontSize: "15px" }}
                    name="breakStartTime"
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "AM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>

                  <button
                    name="breakStartTime"
                    onClick={(e) => handleSingleMeridiemClick(e, id, "PM")}
                    style={{ fontSize: "15px" }}
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "PM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    PM
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-md-row flex-column align-items-center col-6 pe-md-5 pe-0 py-1 justify-content-center">
              {/* <p style={{ fontSize: "14px" }} className="mb-0 me-4 text-secondary">
          Day End:
        </p> */}
              <div className="pe-xl-5 pe-0 text-center">
                <input
                  style={{ fontSize: "15px", width: 70, height: 24 }}
                  type="time"
                  className="border-1 border-secondary rounded-6 align-items-center justify-content-center px-2 me-md-3"
                  placeholder="Select Time"
                  name="breakEndTime"
                  max="12:00"
                  onChange={handleSingleChange}
                  id="Text1"
                  defaultValue={
                    endTime?.split(":")[0] < 10
                      ? "0" +
                        endTime?.split(":")[0] +
                        ":" +
                        endTime?.split(":")[1]
                      : endTime
                  }
                />
                <div
                  style={{ width: 62 }}
                  className="btn-group align-items-center me-md-0 me-3 mt-md-0 mt-2"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    name="breakEndTime"
                    onClick={(e) => handleSingleMeridiemClick(e, id, "AM")}
                    style={{ fontSize: "15px" }}
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      endMeridiem === "AM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>
                  <button
                    name="breakEndTime"
                    data-meridiem="PM"
                    onClick={(e) => handleSingleMeridiemClick(e, id, "PM")}
                    style={{ fontSize: "15px" }}
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      endMeridiem === "PM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    PM
                  </button>
                </div>
              </div>
            </div>
          </div>
          {errorTime ? (
            <div
              className="alert alert-danger d-flex align-items-center border border-danger danger text-danger mx-3 mt-3"
              role="alert"
            >
              <img className="me-2" src="/images/schedule/info.svg" alt="" />
              <div style={{ fontSize: "14px" }}>
                Break must be at least 15 minutes long
              </div>
            </div>
          ) : null}
          {error.sDay ? (
            <div
              className="alert alert-danger d-flex align-items-center border border-danger danger text-danger mx-3 mt-3"
              role="alert"
            >
              <img className="me-2" src="/images/schedule/info.svg" alt="" />
              <div style={{ fontSize: "14px" }}>
                Break must not be equal to day begin
              </div>
            </div>
          ) : null}

          <div className="row w-100 m-auto px-0">
            <div className="col-5 ms-auto text-end offset-1">
              <DeleteIcon
                className="pointer res-margin mb-md-0 mb-2"
                onClick={() => {
                  setChange({
                    ...change,
                    break: true,
                  });
                  removeBrTime(id);
                }}
                sx={{
                  color: "grey",
                  marginTop:
                    error.timeDiff || error.sDay ? "-13.7rem" : "-3.5rem",
                }}
              />
            </div>
            <div className="col-1"></div>
          </div>
        </>
      )}
    </>
  );
};

export default DisplayTime;

import { useEffect, useState } from "react";
// import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import UploadCredential from "./components/UploadCredential";
import CredentialAvailableView from "./components/CredentialAvailableView";
import {
  credentialsSelector,
  fetchAllCredentials,
  statusSelector,
} from "../../../reducers/credentialsReducer";
import ModalC from "../../../components/common/Modal";
import { AddCredentialModal } from "./components/AddCredentialModal";
import CredentialCardLoading from "./components/common/CredentialCardLoading";

const Credential = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch<AppDispatch>();
  const credentialData = useSelector(credentialsSelector);
  const credentialStatus = useSelector(statusSelector);

  useEffect(() => {
    dispatch(fetchAllCredentials());
    return () => {};
  }, []);

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "72vh" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="d-flex align-items-center justify-content-between px-4 py-3 border-bottom">
          <div>
            <h5 className="mb-0 main-head">Credentials</h5>
          </div>
          {credentialData?.length === 0 ? (
            <></>
          ) : (
            <button onClick={handleOpen} className="btn btn-primary py-1 px-3">
              Add
            </button>
          )}
        </div>
        {credentialStatus === "loading" ? (
          <CredentialCardLoading />
        ) : (
          <>
            {credentialData?.length === 0 ? (
              <UploadCredential />
            ) : (
              <>
                {credentialData?.map((CD, idx) => (
                  <CredentialAvailableView key={idx} index={idx} data={CD} />
                ))}
              </>
            )}
          </>
        )}
      </div>

      <ModalC handleClose={handleClose} open={open}>
        <AddCredentialModal
          handleClose={handleClose}
          isEdit={false}
          data={{
            title: "",
            image: { blob: "", src: null },
            type: "",
            issueDate: "",
            expiryDate: "",
            isNotExpiry: 0,
            link: "",
          }}
          index={null}
        />
      </ModalC>
    </div>
  );
};

export default Credential;

import { useEffect, useMemo, useState } from "react";
// import { TagsInput } from "react-tag-input-component";
// import ReactTags from "react-tag-autocomplete";

import Typography from "@mui/material/Typography";
import { ErrorMessage, Form, Formik } from "formik";
import * as yup from "yup";
import FormField from "../../../../components/UI/FormField";
import {
  PaymentRecord,
  providerBoardingAction,
} from "../../../../reducers/paymentReducer";
import { AppDispatch } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import PhoneInputWithCountrySelect, {
  isValidPhoneNumber,
} from "react-phone-number-input";
import { countries, states } from "../../../../config/constant";
import { loggedInUserProfile } from "../../../../reducers/authReducer";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";

interface Options {
  label: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

export const AddOwnership = ({ handleClose, ownerShip, onChange }) => {
  const authUser = useSelector(loggedInUserProfile);

  const initialValues = {
    ownerName: authUser?.firstName + " " + authUser?.lastName,
    ownerTitle: authUser?.firstName + " " + authUser?.lastName,
    ownerSSN: "",
    ownerDob: "",
    ownerPercentage: "100",
    ownerPhone1: authUser?.mobileNo,
    ownerMail: authUser?.email,
    ownerAddress: authUser?.street,
    ownerState: authUser?.state,
    ownerCountry: "US",
    ownerCity: authUser?.city,
    ownerZip: authUser?.zip,
  };

  const dispatch = useDispatch<AppDispatch>();
  const record = useSelector(PaymentRecord);

  const [options, setOptions] = useState<Options[]>([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleAddressChange = async (e) => {
    setLoading(true);
    if (e.target.value.length > 3) {
      const res: any = await axios.post(
        "https://api.postgrid.com/v1/addver/completions",
        {
          partialStreet: e.target.value,
        },
        {
          headers: {
            "x-api-key": process.env.REACT_APP_POSTGRID_API_KEY,
          },
        }
      );
      const data = res.data.data;
      if (data?.length !== 0) {
        setOptions(
          data.map((m) => {
            return {
              label:
                m?.address?.address +
                " " +
                m?.address?.city +
                " " +
                m?.address?.prov +
                ", " +
                m?.address?.pc,
              street: m?.address?.address,
              city: m?.address?.city,
              state: m?.address?.prov,
              zip: m?.address?.pc,
            };
          })
        );
      } else {
        setLoading(false);
        setOptions([]);
      }
    }
  };

  const validationSchema = yup.object().shape({
    ownerName: yup.string().required("Required"),
    ownerTitle: yup.string().required("Required"),
    ownerSSN: yup.string().required("Required"),
    ownerDob: yup.string().required("Required"),
    ownerPhone1: yup.string().required("Required"),
    ownerMail: yup.string().required("Required"),
    // ownerDriver: yup.string().required("Required"),
    // ownerDriverState: yup.string().required("Required"),
    ownerAddress: yup.string().required("Required"),
    ownerState: yup.string().required("Required"),
    ownerCity: yup.string().required("Required"),
    ownerZip: yup.string().required("Required"),
    ownerPercentage: yup.string().required("Required"),
  });

  const [mobileError, setMobileError] = useState(true);

  return (
    <>
      <Typography
        className="text-dark mb-2 fw-bold"
        id="modal-modal-title"
        sx={{
          fontSize: "18px",
          fontWeight: "550",
        }}
        component="h6"
      >
        Add OwnerShip
      </Typography>
      <div className="form">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { resetForm }) => {
            // console.log(values);
            const data = [...ownerShip.ownership, values];
            const finalData = { ...record, ownership: data, id: record?.id };

            // console.log(finalData);
            dispatch(
              providerBoardingAction({ data: finalData, resetForm, step: 3 })
            );
            handleClose();
          }}
        >
          {(formikHandler) => (
            <Form>
              <div className="form-group">
                <div className="row">
                  <FormField
                    className="col-md-3 col-6"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="text"
                    fieldName={{
                      label: "Name",
                      prop: "ownerName",
                      placeHolder: "Owner Name",
                    }}
                  />
                  <FormField
                    className="col-md-3 col-6"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="text"
                    fieldName={{
                      label: "Title",
                      prop: "ownerTitle",
                      placeHolder: "Owner Title",
                    }}
                  />
                  <FormField
                    className="col-md-3 col-6 mt-md-0 mt-3"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="text"
                    fieldName={{
                      label: "SSN",
                      prop: "ownerSSN",
                      placeHolder: "Owner SSN",
                    }}
                    tooltip="Social Security Number"
                  />
                  <FormField
                    className="col-md-3 col-6 mt-md-0 mt-3"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="date"
                    fieldName={{
                      label: "DOB",
                      prop: "ownerDob",
                      placeHolder: "Owner DOB",
                    }}
                    tooltip="Date of Birth"
                  />
                </div>
                <div className="row">
                  <div className="col mt-3">
                    <label htmlFor="" className="form-label">
                      Phone
                    </label>
                    <PhoneInputWithCountrySelect
                      defaultCountry="US"
                      onBlur={() => {
                        if (formikHandler.values?.ownerPhone1) {
                          setMobileError(
                            isValidPhoneNumber(
                              formikHandler.values?.ownerPhone1.toString()
                            )
                          );
                        }
                      }}
                      name="ownerPhone1"
                      id="ownerPhone1"
                      className={`form-control py-2 ${
                        (formikHandler.errors.ownerPhone1 &&
                          formikHandler.touched.ownerPhone1) ||
                        !mobileError
                          ? "is-invalid"
                          : ""
                      }`}
                      international
                      placeholder="Enter phone number"
                      value={formikHandler.values?.ownerPhone1}
                      onChange={(e) => {
                        formikHandler.handleChange({
                          target: {
                            value: e,
                            name: "ownerPhone1",
                          },
                        });
                        onChange({
                          target: {
                            value: e,
                            name: "ownerPhone1",
                          },
                        });
                        // console.log("e=>", e);
                      }}
                    />
                    <ErrorMessage
                      name="ownerPhone1"
                      component="span"
                      className="invalid-feedback"
                    />
                    {!mobileError && (
                      <span className="text-danger">Invalid phone number</span>
                    )}
                  </div>
                </div>
                <div className="row">
                  <FormField
                    className="col mt-3"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="text"
                    fieldName={{
                      label: "Mail",
                      prop: "ownerMail",
                      placeHolder: "Owner Mail",
                    }}
                  />
                  <FormField
                    className="col mt-3"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="number"
                    fieldName={{
                      label: "Percentage",
                      prop: "ownerPercentage",
                      placeHolder: "Owner Percentage",
                    }}
                  />
                </div>
                <div className="row">
                  <div className="col-12 mt-3">
                    <Autocomplete
                      id="asynchronous-demo"
                      freeSolo
                      isOptionEqualToValue={(option, value) => {
                        return option.street === value.street;
                      }}
                      getOptionLabel={(option: any) => option.label}
                      options={options}
                      open={open}
                      sx={{ width: "100%" }}
                      onChange={(e, value) => {
                        const data: any = value;
                        formikHandler.handleChange({
                          target: {
                            name: "ownerAddress",
                            value: data?.street,
                          },
                        });
                        formikHandler.handleChange({
                          target: {
                            name: "ownerCity",
                            value: data?.city,
                          },
                        });
                        formikHandler.handleChange({
                          target: {
                            name: "ownerState",
                            value: data?.state,
                          },
                        });
                        formikHandler.handleChange({
                          target: {
                            name: "ownerZip",
                            value: data?.zip,
                          },
                        });
                      }}
                      onError={(e) => {
                        console.log(e);
                        setOptions([]);
                      }}
                      onBlur={(e) => {
                        setOptions([]);
                        setLoading(false);
                      }}
                      onOpen={() => {
                        setOpen(true);
                      }}
                      onClose={() => {
                        setOpen(false);
                      }}
                      filterOptions={(x) => x}
                      loading={loading}
                      noOptionsText="No Address Found"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Enter Your Address"
                          // className="autocomplete"
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "no",
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "1px solid #ced4da",
                              },
                              "&:hover fieldset": {
                                borderColor: "#ced4da",
                              },
                              "&.Mui-focused fieldset": {
                                border: "1px solid #ced4da",
                                boxShadow: "0 0 0 0.25rem #cdf1f4",
                              },
                            },
                          }}
                          size="small"
                          onChange={(e) => {
                            handleAddressChange(e);
                          }}
                        />
                      )}
                    />
                  </div>
                  <FormField
                    className="col mt-3"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="text"
                    fieldName={{
                      label: "Address",
                      prop: "ownerAddress",
                      placeHolder: "Owner Address",
                    }}
                  />
                </div>
                <div className="row">
                  <FormField
                    className="col mt-3 py-1"
                    formikHandler={formikHandler}
                    onChange={onchange}
                    type="text"
                    fieldName={{
                      label: "State",
                      prop: "ownerState",
                      placeHolder: "State here",
                    }}
                  />
                  <FormField
                    className="col mt-3"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="text"
                    fieldName={{
                      label: "City",
                      prop: "ownerCity",
                      placeHolder: "Owner City",
                    }}
                  />
                  <FormField
                    className="col mt-3"
                    formikHandler={formikHandler}
                    onChange={onChange}
                    type="text"
                    fieldName={{
                      label: "Zip",
                      prop: "ownerZip",
                      placeHolder: "Owner Zip",
                    }}
                  />
                </div>

                <div className="row">
                  <div className="col-6">
                    <button
                      type="reset"
                      onClick={handleClose}
                      className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
                    >
                      Cancel
                    </button>
                  </div>
                  <div className="col-6">
                    <button
                      style={{ backgroundColor: "#04BAC7" }}
                      className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                      type="submit"
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};

import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  handleOnrToOneTimeChange,
  scheduleOnToOneSelector,
  updateOneToOne,
} from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import { convert24To12 } from "../../../../../utils";
import { xconsole } from "../../../../../utils/console";

const DailySchAccordion = ({
  startTime,
  endTime,
  startMeridiem,
  endMeridiem,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const oneToOneData = useSelector(scheduleOnToOneSelector);

  const handleTimeChange = (e: any) => {
    // console.log("time : ", e?.target?.value);
    // console.log(" converted time : ", convert24To12(e.target.value));
    //check e.target.value is empty or not
    if (e.target.value !== "") {
      const tempTime = convert24To12(e.target.value);
      const tempMed = oneToOneData[e.target.name].split(" ")[1];
      // xconsole.test(`tempTime ${tempTime}`);
      dispatch(
        updateOneToOne({
          key: e.target.name,
          value: `${tempTime.split(" ")[0]} ${tempMed}`,
        })
      );
    }

    // setTime({
    //   ...time,
    // [e.target.name]: e.target.value,
    // });
  };

  const [error, setError] = useState<boolean>(true);

  // console.log("fullStartTime =>", fullStartTime);
  // console.log("fullEndTime =>", fullEndTime);

  const isHourApart = (oneToOneData) => {
    const startTime = moment(oneToOneData?.startTime, "hh:mm A");
    const endTime = moment(oneToOneData?.endTime, "hh:mm A");
    const diff = endTime.diff(startTime, "hours");
    // console.log("diff =>", diff);
    if (diff >= 1) {
      return true;
    } else {
      return false;
    }
  };

  // console.log(isHourApart(oneToOneData));

  useEffect(() => {
    setError(isHourApart(oneToOneData));
  }, [oneToOneData]);

  const handleMeridiemClick = (e: any) => {
    dispatch(
      handleOnrToOneTimeChange({ key: e.target.name, value: e.target.value })
    );
  };

  return (
    <div className={`accordion mb-3`} id="accordionExample">
      <div
        className={`accordion-item ${!error ? "border-start-primary" : null}`}
      >
        <div
          id="collapseOne"
          className="accordion-collapse collapse show"
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample"
        >
          <div className="accordion-body px-2 py-xl-4 py-2">
            {!error ? (
              <>
                <div
                  className="alert alert-danger d-flex align-items-center border border-danger danger text-danger mx-3"
                  role="alert"
                >
                  <img
                    className="me-2"
                    src="/images/schedule/info.svg"
                    alt=""
                  />
                  <div style={{ fontSize: "14px" }}>
                    Day Begin must be at least an hour apart from the Day End
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="row w-100 m-auto px-0">
              <>
                <div className="col-6 text-center">
                  <p style={{ fontSize: "16px" }} className="mb-2 text-dark">
                    Day Begin
                  </p>
                </div>
                <div className="col-6 text-center">
                  <p style={{ fontSize: "16px" }} className="mb-2 text-dark">
                    Day End
                  </p>
                </div>{" "}
              </>
            </div>
            <div className="row w-100 m-auto px-0 rounded-6">
              <div className="d-flex flex-md-row flex-column align-items-center col-6 px-md-2 px-0 py-1 justify-content-center">
                {/* <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Day Begin:
                </p> */}
                <input
                  style={{ fontSize: "15px", width: 62 }}
                  type="time"
                  className="border-1 border-secondary rounded-6 align-items-center justify-content-center px-2 me-md-3 mb-md-0 mb-2"
                  // placeholder="hh:mm"
                  id="Text1"
                  name="startTime"
                  max="12:00"
                  onChange={handleTimeChange}
                  value={
                    startTime?.split(":")[0] < 10
                      ? "0" +
                        startTime?.split(":")[0] +
                        ":" +
                        startTime?.split(":")[1]
                      : startTime
                  }
                />
                <div
                  className="btn-group align-items-center"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    style={{ fontSize: "15px" }}
                    name="startTime"
                    onClick={handleMeridiemClick}
                    value="AM"
                    type="button"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "AM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>

                  <button
                    style={{ fontSize: "15px" }}
                    type="button"
                    name="startTime"
                    onClick={handleMeridiemClick}
                    value="PM"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      startMeridiem === "PM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    PM
                  </button>
                </div>
              </div>

              {/* end time */}
              <div className="d-flex flex-md-row flex-column align-items-center col-6 px-md-2 px-0 py-1 justify-content-center">
                {/* <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Day End:
                </p> */}
                <input
                  style={{ fontSize: "15px", width: 62 }}
                  type="time"
                  className="border-1  border-secondary rounded-6 align-items-center justify-content-center px-2 me-md-3 mb-md-0 mb-2"
                  placeholder="Select Time"
                  id="Text1"
                  name="endTime"
                  max="12:00"
                  onChange={handleTimeChange}
                  value={
                    endTime?.split(":")[0] < 10
                      ? "0" +
                        endTime?.split(":")[0] +
                        ":" +
                        endTime?.split(":")[1]
                      : endTime
                  }
                />
                <div
                  className="btn-group align-items-center"
                  role="group"
                  aria-label="Basic outlined example"
                >
                  <button
                    style={{ fontSize: "15px" }}
                    type="button"
                    name="endTime"
                    onClick={handleMeridiemClick}
                    value="AM"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      endMeridiem === "AM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    AM
                  </button>
                  <button
                    style={{ fontSize: "15px" }}
                    type="button"
                    name="endTime"
                    onClick={handleMeridiemClick}
                    value="PM"
                    className={`btn btn-outline-secondary rounded-6 py-0 px-2 ${
                      endMeridiem === "PM"
                        ? "bg-secondary text-white"
                        : "bg-white text-secondary"
                    }`}
                  >
                    PM
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DailySchAccordion;

// {
//   /* <h2 className="accordion-header" id="headingOne">
//           <button
//             style={{ backgroundColor: "#f9fafb" }}
//             className="accordion-button px-md-4 px-2 collapsed"
//             type="button"
//             data-bs-toggle="collapse"
//             data-bs-target="#collapseOne"
//             aria-expanded="true"
//             aria-controls="collapseOne"
//           >
//             <div className="row w-100 align-items-center">
//               <div className="col-6">
//                 <div className="d-flex align-items-center">
//                   <img
//                     className="me-3 pointer"
//                     height={22}
//                     src="/images/schedule/DailySchedule.svg"
//                     alt=""
//                   />
//                   <div>
//                     <p className="fw-light mb-1 text-dark">Daily Schedule</p>
//                     <p
//                       style={{ fontSize: "12px" }}
//                       className="fw-light my-0 text-secondary"
//                     >
//                       {startTime} - {endTime}
//                     </p>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-6">
//                 <div className="d-flex align-items-center justify-content-end">
//                   <img
//                     data-mdb-toggle="tooltip"
//                     title="Hooray!"
//                     className="me-3 pointer"
//                     height={18}
//                     src="/images/schedule/infosvg.svg"
//                     alt=""
//                   />
//                   <div className="form-check form-switch">
//                     <input
//                       className="form-check-input"
//                       type="checkbox"
//                       id="flexSwitchCheckChecked"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </button>
//         </h2> */
// }

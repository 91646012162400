import moment from "moment";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { TemporaryDrawer } from "../../../../components/common/TemporaryDrawer";
import { getAppointmentNotes } from "../../../../reducers/appointmentReducer";
import { AppDispatch } from "../../../../store";
import { convert24To12 } from "../../../../utils";

const DrawerChild = ({
  handleClose,
  isEmpty,
  isComplete = "approve",
  appointmentData,
}) => {
  // console.log("appointmentData", appointmentData);

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center pb-1 pt-3 ps-3 pe-4">
        <div className="d-flex align-items-center pointer">
          <img
            src={
              appointmentData?.clientImg
                ? appointmentData?.clientImg
                : "/images/avatar2.png"
            }
            height={35}
            width={35}
            alt="arrow"
            className="me-2 rounded-circle"
          />
          <p className={`mb-0`} style={{ fontSize: "20px" }}>
            {appointmentData?.title ? appointmentData?.title : "NA"}
          </p>
        </div>
        <img
          onClick={handleClose}
          src="/images/clientList/close.png"
          alt="arrow"
          className="pointer me-1"
        />
      </div>
      <hr />
      {isEmpty ? (
        <div className="row mt-5">
          <div className="col-8 m-auto py-5 text-center">
            <label>
              <img
                src="/images/Media/UploadImg.svg"
                alt="Upload"
                className="pointer"
              />
              <h6 className="mb-0 fw-bold mt-3 mb-1">
                Not notes Added for Molly B.
              </h6>
              <p
                style={{ fontSize: "14px", color: "#667085" }}
                className="mb-0 text-grey"
              >
                You can add images from you favorite social media platforms e.g.
                Facebook, Instagram, Twitter etc.
              </p>
            </label>
          </div>
        </div>
      ) : (
        <div className="mt-3 ps-3 pe-4 mb-1">
          <div className="row g-3">
            <div className="col-6">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p
                  className={`mb-2 mt-1 text-dark`}
                  style={{ fontSize: "16px" }}
                >
                  {appointmentData?.totalDuration
                    ? `$ ${appointmentData?.totalDuration}`
                    : "$0.00"}
                </p>
                <p
                  className={`mb-2 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  Amount
                </p>
              </div>
            </div>
            <div className="col-6">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-2 mt-1`} style={{ fontSize: "16px" }}>
                  $ 0.00
                </p>
                <p
                  className={`mb-2 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  Tip
                </p>
              </div>
            </div>
            {appointmentData?.isReservationFee === 1 ? (
              <div className="col-12">
                {/* border-bottom border-3 border-primary */}
                <div
                  style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                  className={`form-control rounded-b5 px-4 py-2 border-0`}
                >
                  <p
                    className={`mb-2 mt-1 text-dark`}
                    style={{ fontSize: "16px" }}
                  >
                    {appointmentData?.reservationFee
                      ? `$ ${appointmentData?.reservationFee}`
                      : "$0.00"}
                  </p>
                  <p
                    className={`mb-2 mt-1 fw-light`}
                    style={{ fontSize: "14px", color: "#667085" }}
                  >
                    Reservation Fee
                  </p>
                </div>
              </div>
            ) : null}
            <div className="col-12">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-2 mt-1`} style={{ fontSize: "16px" }}>
                  Date
                </p>
                <p
                  className={`mb-2 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  {appointmentData?.Date}
                </p>
              </div>
            </div>
            <div className="col-12">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-1 mt-1`} style={{ fontSize: "16px" }}>
                  Time
                </p>
                <p
                  className={`mb-0 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  Start: {convert24To12(appointmentData?.start)} &nbsp; | &nbsp;
                  End: {convert24To12(appointmentData?.end)}
                </p>
              </div>
            </div>
            <div className="col-12">
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-2 mt-1`} style={{ fontSize: "16px" }}>
                  Services
                </p>
                <p
                  className={`mb-2 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  <ul>
                    {appointmentData?.services?.map((m) => (
                      <li>{m?.service?.name}</li>
                    ))}
                  </ul>
                </p>
              </div>
            </div>
            <div className="col-12">
              {/* border-bottom border-3 border-primary */}
              <div
                style={{ fontSize: "14px", backgroundColor: "#F1F5F9" }}
                className={`form-control rounded-b5 px-4 py-2 border-0`}
              >
                <p className={`mb-2 mt-1`} style={{ fontSize: "16px" }}>
                  Location
                </p>
                <p
                  className={`mb-2 mt-1 fw-light`}
                  style={{ fontSize: "14px", color: "#667085" }}
                >
                  {appointmentData?.location}
                </p>
              </div>
            </div>
          </div>
          {/* <div className="d-flex justify-content-end mt-3">
            {isComplete == "complete" ? (
              <button
                onClick={handleOpen2}
                style={{ fontSize: "14px", borderRadius: "6px" }}
                className="btn btn-primary me-2"
              >
                Complete
              </button>
            ) : (
              <button
                style={{ fontSize: "14px", borderRadius: "6px" }}
                className="btn btn-primary me-2"
              >
                Approve
              </button>
            )}
            <button
              style={{ fontSize: "14px", borderRadius: "6px" }}
              className="btn btn-outline-danger"
            >
              Decline
            </button>
          </div> */}
        </div>
      )}
      {/* <TemporaryDrawer handleClose={handleClose2} open={open2}>
        <AppointmentCompDrawer
          handleClose={handleClose2}
          id={appointmentData?.id}
          providerId={appointmentData?.providerId}
          clientId={appointmentData?.clientId}
        />
      </TemporaryDrawer> */}
    </div>
  );
};

export default DrawerChild;

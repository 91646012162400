import React from "react";
import { formFieldPropType } from "./type";
import { ErrorMessage } from "formik";
import { BootstrapTooltip } from "../common/BootstrapTooltip";

const FormField = ({
  type,
  formikHandler,
  onChange,
  fieldName,
  className,
  tooltip,
}: formFieldPropType) => {
  const { errors, touched, values, handleBlur, handleChange } = formikHandler;
  const property = fieldName.prop as string;

  {
    /* {({ errors, touched, values, handleBlur, handleChange }) => ( */
  }

  return (
    <div className={className}>
      {type === "checkbox" ? (
        <div
          style={{ height: "33px" }}
          className="d-flex align-items-center bg-light p-1"
        >
          <input
            type={type}
            className={`${"form-check-input mt-0"} ${
              errors[property] && touched[property] ? "is-invalid" : ""
            }`}
            name={property}
            // min={1}
            checked={values[property]}
            onChange={(e) => {
              handleChange(e);
              onChange(e);
            }}
            id={property}
            placeholder={fieldName.placeHolder}
          />
          {fieldName?.label && (
            <label htmlFor={property} className="form-label my-0 ms-2">
              {fieldName?.label}
            </label>
          )}
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center justify-content-between">
            {fieldName?.label && (
              <label htmlFor={property} className="form-label">
                {fieldName?.label}
              </label>
            )}
            {tooltip && (
              <BootstrapTooltip title={tooltip} placement="top">
                <img
                  className="pointer mb-2"
                  height={16}
                  src="/images/schedule/infosvg.svg"
                  alt=""
                />
              </BootstrapTooltip>
            )}
          </div>
          <input
            type={type}
            className={`${"form-control"} ${
              errors[property] && touched[property] ? "is-invalid" : ""
            }`}
            name={property}
            // min={1}
            value={values[property]}
            onChange={(e) => {
              handleChange(e);
              onChange(e);
            }}
            id={property}
            placeholder={fieldName.placeHolder}
          />
        </>
      )}
      <ErrorMessage
        name={property}
        component="span"
        className="invalid-feedback"
      />
    </div>
  );
};

export default FormField;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { TemporaryDrawer } from "../../../../components/common/TemporaryDrawer";
import NotesDrawer from "./NotesDrawer";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import UndoIcon from "@mui/icons-material/Undo";
import RedoIcon from "@mui/icons-material/Redo";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { Skeleton } from "@mui/lab";
import { useSelector } from "react-redux";
import { profileStatusSelector } from "../../../../reducers/clientReducer";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";

const ClientHistory = ({ profile, openedTab }) => {
  const [hover, setHover] = React.useState({
    one: false,
    two: false,
    three: false,
  });
  const [open, setOpen] = React.useState(false);
  const [total, setTotal] = useState<any>(0);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const poppinsFont = {
    fontFamily: "Poppins, sans-serif",
  };
  const whiteText = {
    fontSize: "13px",
  };
  const bgLight = {
    backgroundColor: "#F1F5F9",
  };

  const fsFourteen = {
    fontSize: "14px",
  };
  const textLight = {
    color: "#667085",
    fontWeight: "300",
  };

  const naviagate = useNavigate();

  const status = useSelector(profileStatusSelector);

  const fDate = profile?.createdAt?.split("-");

  const year = fDate?.[2];

  const address =
    profile?.street +
    "," +
    " " +
    profile?.city +
    " " +
    profile?.state +
    " " +
    profile?.zip;

  useEffect(() => {
    let sum = 0;
    profile?.appointments?.forEach((i) => {
      sum = Number(sum) + Number(i.totalPrice);
    });
    setTotal(sum);
  }, [profile?.appointments]);

  return (
    <>
      <div className="mt-4 margin-start-res" style={{ marginLeft: "4.2rem" }}>
        <div
          className={`row gy-4 align-items-center ${
            openedTab !== 0 ? "me-xl-5 pe-xl-5" : ""
          }`}
        >
          {status == "loading" ? (
            [1, 2, 3]?.map(() => (
              <div className="col-md-4 col-sm-6 mb-sm-0">
                <Skeleton
                  className="rounded-5"
                  variant="rectangular"
                  width={"100%"}
                  height={90}
                />
              </div>
            ))
          ) : (
            <>
              {openedTab === 0 && (
                <div className="col me-auto">
                  <div className="w-100">
                    <p
                      className={`mb-0 mt-1 text-dark text-capitalize`}
                      style={{ fontSize: "18px" }}
                    >
                      {profile?.firstName} {profile?.lastName}
                    </p>
                    <p
                      style={{
                        ...textLight,
                        ...poppinsFont,
                        fontSize: "13px",
                        marginTop: "-0.4rem",
                      }}
                      className="mb-0"
                    >
                      {profile?.username}
                    </p>
                    <div className="row mt-2">
                      <div className="col-md-4 col-6">
                        <BootstrapTooltip
                          title="Last Appointment"
                          placement="top-start"
                        >
                          <div className="d-flex align-items-center pointer">
                            <UndoIcon
                              className="me-1 mb-1 rotate30"
                              style={{ color: "#505050" }}
                              fontSize="small"
                            />
                            <p
                              style={{
                                ...textLight,
                                ...poppinsFont,
                                ...fsFourteen,
                              }}
                              className="mb-0 me-3"
                            >
                              {profile?.lastAppointment
                                ? profile?.lastAppointment?.appointment_date
                                : "None"}
                            </p>
                          </div>
                        </BootstrapTooltip>
                      </div>
                      <div className="col-md-6 col-6">
                        <BootstrapTooltip
                          title="Next Appointment"
                          placement="top-start"
                        >
                          <div className="d-flex align-items-center pointer">
                            <RedoIcon
                              className="me-1 rotate-30"
                              style={{ color: "#505050" }}
                              fontSize="small"
                            />
                            <p
                              style={{
                                ...textLight,
                                ...poppinsFont,
                                ...fsFourteen,
                              }}
                              className="mb-0 pointer"
                            >
                              {profile?.nextAppointment?.appointment_date
                                ? profile?.nextAppointment?.appointment_date
                                : "None"}
                            </p>
                          </div>
                        </BootstrapTooltip>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4 col-6">
                        <div className="d-flex align-items-center">
                          <DateRangeIcon
                            className="me-1 mb-2 pointer"
                            style={{ color: "#505050" }}
                            fontSize="small"
                          />
                          <p
                            style={{
                              ...textLight,
                              ...poppinsFont,
                              fontSize: "13px",
                            }}
                            className="mb-2"
                          >
                            Member Since {year}
                          </p>
                        </div>
                      </div>
                      <div className="col-md-6 col-6">
                        <div className="d-flex align-items-center">
                          <LocationOnIcon
                            className="mb-2 pointer"
                            style={{ color: "#505050" }}
                            fontSize="small"
                          />
                          <p
                            style={{
                              ...textLight,
                              ...poppinsFont,
                              ...fsFourteen,
                              fontSize: "13px",
                            }}
                            className="mb-2 text-capitalize"
                          >
                            {address}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="col-md-4 col-sm-6 mb-sm-0 ms-auto">
                {/* border-bottom border-3 border-primary */}
                <div
                  style={{ ...bgLight }}
                  className="rounded-5 px-3 pt-2 pb-1"
                >
                  <div style={{ marginTop: "1rem" }} className="d-flex">
                    <h4 style={{ fontWeight: "600" }} className="m-0">
                      {profile?.appointments?.length}
                    </h4>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <p
                      style={{ ...poppinsFont, ...whiteText }}
                      className="mt-2 mb-2 text-uppercase"
                    >
                      Appointments
                    </p>

                    <div
                      onMouseEnter={() => setHover({ ...hover, one: true })}
                      onMouseLeave={() => setHover({ ...hover, one: false })}
                      onClick={() =>
                        naviagate(`/appointment?memberId=${profile?.id}`)
                      }
                      style={{
                        height: "25px",
                        width: "25px",
                        fontSize: "13px",
                        backgroundColor: hover.one ? "#039BA6" : "white",
                      }}
                      className={`rounded-circle p-1 ms-sm-2 d-flex align-items-center justify-content-center pointer shadow`}
                    >
                      <ArrowForwardIcon
                        sx={{
                          fontSize: 15,
                          color: hover.one ? "white" : "#039BA6",
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {openedTab !== 0 && (
                <>
                  <div className="col-md-4 col-sm-6 mb-sm-0">
                    {/* border-bottom border-3 border-primary */}
                    <div
                      style={{ ...bgLight }}
                      className="rounded-5 px-3 pt-2 pb-1"
                    >
                      <div style={{ marginTop: "1rem" }} className="d-flex">
                        <h4 style={{ fontWeight: "600" }} className="m-0">
                          {total}
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          style={{ ...poppinsFont, ...whiteText }}
                          className="mt-2 mb-2 text-uppercase"
                        >
                          Payment
                        </p>
                        <div
                          onMouseEnter={() => setHover({ ...hover, two: true })}
                          onMouseLeave={() =>
                            setHover({ ...hover, two: false })
                          }
                          style={{
                            height: "25px",
                            width: "25px",
                            fontSize: "13px",
                            backgroundColor: "white",
                          }}
                          className={`rounded-circle p-1 ms-sm-2 d-flex align-items-center justify-content-center shadow`}
                        >
                          <ArrowForwardIcon
                            sx={{ fontSize: 15, color: "grey" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-6 mb-sm-0">
                    {/* border-bottom border-3 border-primary */}
                    <div
                      style={{ ...bgLight }}
                      className="rounded-5 px-3 pt-2 pb-1"
                    >
                      <div style={{ marginTop: "1rem" }} className="d-flex">
                        <h4 style={{ fontWeight: "600" }} className="m-0">
                          {
                            profile?.notes?.filter((f) => f.appointment_id)
                              ?.length
                          }
                        </h4>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <p
                          style={{ ...poppinsFont, ...whiteText }}
                          className="mt-2 mb-2 text-uppercase"
                        >
                          Service notes
                        </p>
                        <div
                          onMouseEnter={() =>
                            setHover({ ...hover, three: true })
                          }
                          onMouseLeave={() =>
                            setHover({ ...hover, three: false })
                          }
                          onClick={handleOpen}
                          style={{
                            height: "25px",
                            width: "25px",
                            fontSize: "13px",
                            backgroundColor: hover.three ? "#039BA6" : "white",
                          }}
                          className={`rounded-circle p-1 ms-sm-2 d-flex align-items-center justify-content-center pointer shadow`}
                        >
                          <ArrowForwardIcon
                            sx={{
                              fontSize: 15,
                              color: hover.three ? "white" : "#039BA6",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        <TemporaryDrawer handleClose={handleClose} open={open}>
          <NotesDrawer
            note={profile}
            handleClose={handleClose}
            isEmpty={false}
          />
        </TemporaryDrawer>
      </div>
    </>
  );
};

export default ClientHistory;

import React from "react";
import ExploreCarousel from "./ExploreCarousel";
import { AppDispatch } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  aggregateFilter,
  exploreFilterSelector,
  fetchPaginatedMedia,
  resetFilters,
  searchByServiceName,
} from "../../../../reducers/mediaReducer";
import { localStorageObject } from "../../../../utils";
import { EXPLOREFILTERS } from "../../../../config/constant";
import { serviceLevelTwoSelector } from "../../../../reducers/serviceReducer";
import { BootstrapTooltip } from "../../../../components/common/BootstrapTooltip";
import { Slider } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FilterButton from "../../../../components/common/Button/FilterBtn";

const Filters = ({ coordinates, setFilters, filters }) => {
  const dispatch = useDispatch<AppDispatch>();

  const [search, setSearch] = React.useState<string>("");
  const levelTwoServices = useSelector(serviceLevelTwoSelector);

  const exploreFilterMediaData = useSelector(exploreFilterSelector);

  const [sliderValue, setSliderValue] = React.useState<any>(0);

  const searchFieldHandle = (e) => {
    setSearch((prev) => (prev = e.target.value));
    setFilters({
      ...filters,
      serviceName: e.target.value,
    });
    // if (e.target.value.length > 3) {
    if (e.target.value === "") {
      // console.log("cross");
      const { isFav, isStar, myData, ...rest } = filters;
      const finalData = {
        ...rest,
        perPage: 20,
        serviceName: "",
      };
      dispatch(fetchPaginatedMedia(finalData));
      setFilters({
        ...filters,
        serviceName: "",
      });
    }
    //   dispatch(searchByServiceName({ serviceName: e.target.value }));
    // }
  };

  React.useEffect(() => {
    const { isFav, isStar, myData, ...rest } = filters;
    const finalData = {
      ...rest,
      perPage: 20,
    };
    dispatch(fetchPaginatedMedia(finalData));
  }, [
    filters?.isMine,
    filters?.isVic,
    filters?.searchByFav,
    filters?.miles,
    filters?.serviceId,
  ]);

  const handleReset = (): void => {
    setFilters({
      ...filters,
      searchByFav: 0,
      isFav: false,
      isStar: false,
      serviceId: 0,
      miles: 0,
      latitude: 0,
      longitude: 0,
      isVic: 0,
      isMine: 1,
      myData: true,
      serviceName: "",
    });
    setSliderValue(0);
  };

  const clickSearchHandle = (e) => {
    e.preventDefault();
    if (search.length >= 3) {
      dispatch(searchByServiceName({ serviceName: search }));
      const { isFav, isStar, myData, ...rest } = filters;
      const finalData = {
        ...rest,
        perPage: 20,
      };
      dispatch(fetchPaginatedMedia(finalData));
    }
  };

  return (
    <>
      <div className="col-xl-9 col-12 d-flex flex-sm-row flex-column justify-content-end mt-lg-0 mt-3">
        {/* <form onSubmit={clickSearchHandle} className="d-flex">
          <input
            type="search"
            onChange={searchFieldHandle}
            id="form1"
            value={search}
            className="form-control explore-search border-end-0 d-lg-none d-block"
            placeholder="Search here..."
          />
          <button
            type="submit"
            className="btn btn-outline-secondary border border-search d-lg-none d-block"
          >
            Search
          </button>
        </form> */}
        <form className="d-flex" onSubmit={clickSearchHandle}>
          <input
            type="search"
            onChange={searchFieldHandle}
            id="form1"
            value={search}
            className="form-control explore-search border-end-0 d-block"
            placeholder="Search by name, caption or tag"
          />
          <button
            type="submit"
            className="btn btn-outline-secondary border border-search d-block"
          >
            Search
          </button>
        </form>
        <div
          className="btn-group ms-sm-3 mb-sm-0 mb-3"
          role="group"
          aria-label="Basic outlined example"
        >
          <FilterButton id="dropdownMenuButton2">
            <div className="dropdown">
              <div>
                <LocationOnIcon
                  className=""
                  sx={{ fontSize: 20, color: "#505050" }}
                />
              </div>
              <div
                style={{ borderRadius: "10px", width: "250px" }}
                className="dropdown-menu dropdown-menu-light py-3 px-3"
                aria-labelledby="dropdownMenuButton2"
              >
                <BootstrapTooltip
                  title="Once you set the range, the system will filter and display media that falls within the selected distance range. Media outside the range will be hidden"
                  placement="top"
                >
                  <img
                    className="pointer float-end"
                    height={18}
                    src="/images/schedule/infosvg.svg"
                    alt=""
                  />
                </BootstrapTooltip>
                <Slider
                  value={sliderValue}
                  aria-label="Default"
                  valueLabelFormat={(x) => {
                    return x === 300 ? `any` : `${x} miles`;
                  }}
                  max={300}
                  onChange={(e, value) => {
                    setSliderValue(value);
                  }}
                  onChangeCommitted={(e, value) => {
                    // console.log("value", value);

                    if (value === 300) {
                      // remove miles from filters
                      const { miles, ...rest } = filters;
                      setFilters({
                        ...rest,
                        latitude: coordinates?.lat,
                        longitude: coordinates?.lng,
                      });
                      localStorageObject.set(EXPLOREFILTERS, {
                        ...rest,
                        latitude: coordinates?.lat,
                        longitude: coordinates?.lng,
                      });
                    } else {
                      setFilters({
                        ...filters,
                        miles: value,
                        latitude: coordinates?.lat,
                        longitude: coordinates?.lng,
                      });
                      localStorageObject.set(EXPLOREFILTERS, {
                        ...filters,
                        miles: value,
                        latitude: coordinates?.lat,
                        longitude: coordinates?.lng,
                      });
                    }
                  }}
                  valueLabelDisplay="auto"
                />
              </div>
            </div>
          </FilterButton>
          <FilterButton
            active={filters.isFav}
            onClick={() => {
              // dispatch(resetFilters());
              setFilters({
                ...filters,
                searchByFav: !filters.searchByFav ? 1 : 0,
                isFav: !filters.isFav,
                isStar: false,
                isVic: 0,
              });
            }}
            tooltip="Favorite Media"
          >
            <img
              src="/images/explore/like.png"
              alt="icon"
              className="pointer my-1"
            />
          </FilterButton>
          {/* <FilterButton
            active={filters.isStar}
            onClick={() => {
              // dispatch(resetFilters());
              setFilters({
                ...filters,
                isStar: !filters.isStar,
                isVic: !filters.isVic ? 1 : 0,
                isFav: false,
                searchByFav: 0,
              });
              localStorageObject.set(EXPLOREFILTERS, {
                ...filters,
                isStar: !filters.isStar,
                isVic: !filters.isVic ? 1 : 0,
                isFav: false,
                searchByFav: 0,
              });
            }}
            tooltip="VIC Media"
          >
            <img
              src="/images/explore/star.png"
              alt="icon"
              className="pointer my-1"
            />
          </FilterButton> */}
        </div>
        <div
          className="ms-sm-3 d-flex align-items-center flex-sm-row flex-column"
          role="group"
          aria-label="Basic outlined example"
        >
          <button
            onClick={() => {
              setFilters({
                ...filters,
                isMine: !filters.isMine ? 1 : 0,
                myData: !filters.myData,
                isStar: false,
                isVic: 0,
              });
              dispatch(
                aggregateFilter({
                  ...filters,
                  key: "isMine",
                  value: !filters.isMine,
                })
              );
              // console.log("filters", filters);
            }}
            type="button"
            className={`btn btn-outline-light border border-b5 text-dark me-sm-3 text-sm-nowrap w-100 ${
              !filters.myData ? "bg-light-grey" : " "
            }`}
            style={{ whiteSpace: "nowrap" }}
          >
            {!filters.myData ? "Public Portfolio" : "Personal Portfolio"}
          </button>
          <button
            onClick={() => handleReset()}
            type="button"
            className={`btn btn-outline-light w-100 my-sm-0 my-3 ${
              exploreFilterMediaData === 0
                ? "text-danger"
                : "text-white bg-danger"
            }  border border-b5`}
          >
            Reset
          </button>
        </div>
      </div>
      {/* <div className="col-md-6 col-12 d-flex justify-content-end">
          <button
            onClick={handleOpen}
            style={{ whiteSpace: "nowrap" }}
            className="btn btn-primary d-flex flex-row align-items-center ps-1 border-b5"
          >
            <img
              height={15}
              src="/images/explore/plus.png"
              alt="plus"
              className="mx-2"
            />
            Add Media
          </button>
        </div> */}
      <div style={{ height: "230px" }} className="mt-3 px-0">
        <ExploreCarousel
          data={levelTwoServices}
          filters={filters}
          setFilters={setFilters}
        />
      </div>
    </>
  );
};

export default Filters;

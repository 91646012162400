import { InputAdornment, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BootstrapTooltip } from "../../../components/common/BootstrapTooltip";
import ConnectionUser from "../../../components/common/ConnectionUser";
import {
  clientListSelector,
  fetchMemberList,
} from "../../../reducers/clientReducer";
import { AppDispatch } from "../../../store";
import AddConnectionModal from "./AddConnectionModal";
import {
  getSearchUsersAction,
  resetList,
  roomSelector,
} from "../../../reducers/chatReducer";
import { ConnectionStatus } from "../../../components/common/types";

const ChatFilter = () => {
  const clientListData = useSelector(clientListSelector);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const roomData = useSelector(roomSelector);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchMemberList(ConnectionStatus.APPROVED));
    // return () => {
    //   setOpen(false);
    // };
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    if (event.target.value.length >= 3) {
      dispatch(getSearchUsersAction({ data: event.target.value, setLoading }));
    }
    if (event.target.value === "" || event.target.value === null) {
      dispatch(resetList());
      setLoading(false);
    }
  };

  const filteredClientListData = clientListData.filter((f) => {
    // show only those users who are not in chat list
    const isUserInChatList = roomData?.list?.find(
      (chat) => chat?.participant?.id == f?.id
    );
    return !isUserInChatList;
  });

  const UniqueClientList = filteredClientListData.filter(
    (f) => f.isMobileVerified == 1
  );

  return (
    <div className="d-flex m-4">
      {/* <input
        style={{ backgroundColor: "#F3F4F6" }}
        type="search"
        onChange={handleChange}
        id="providerSearch"
        className="form-control py-2 border border-0 rounded-5"
        placeholder="Search"
      /> */}
      <TextField
        placeholder="Search"
        sx={{
          backgroundColor: "#F3F6F9",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "0px solid #ced4da",
              borderRadius: "8px",
            },
            "&:hover fieldset": {
              borderColor: "#ced4da",
            },
            "&.Mui-focused fieldset": {
              border: "1px solid #ced4da",
              boxShadow: "0 0 0 0.25rem #cdf1f4",
            },
          },
        }}
        className="form-control"
        onChange={handleChange}
        InputProps={{
          endAdornment: loading ? (
            <InputAdornment position="end">
              <span
                className="spinner-border spinner-border-sm"
                role="status"
              ></span>
            </InputAdornment>
          ) : null,
        }}
        variant="outlined"
        size="small"
      />
      {/* <img
        style={{ filter: "grayscale(100%)" }}
        className="ms-2"
        height={40}
        src="/images/chat/filter-chat.png"
        alt=""
      /> */}

      <BootstrapTooltip title="Add Connection" placement="top">
        <img
          onClick={handleOpen}
          className="ms-2 pointer"
          height={40}
          src="/images/chat/add-chat.png"
          alt=""
        />
      </BootstrapTooltip>
      <AddConnectionModal handleClose={handleClose} open={open}>
        <Typography
          className="text-dark mb-4"
          id="modal-modal-title"
          sx={{
            fontSize: "18px",
            fontWeight: "550",
          }}
          component="h6"
        >
          Add Connection
        </Typography>
        <div style={{ height: "88%" }} className="overflow-auto">
          {UniqueClientList?.map((data, idx) => (
            <ConnectionUser key={idx} data={data} handleClose={handleClose} />
          ))}
        </div>
      </AddConnectionModal>
    </div>
  );
};

export default ChatFilter;

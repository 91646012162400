import React from "react";
import { Skeleton } from "@mui/material";

const GreetingLoading = () => {
  return (
    <div className="d-flex align-items-center">
      <div className="d-flex align-items-center me-2">
        <Skeleton className="me-3" variant="circular" height={25} width={25} />
        <Skeleton width={200} height={50} animation="pulse" />
      </div>
    </div>
  );
};

export default GreetingLoading;

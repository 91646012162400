import React, { useMemo } from "react";

const TextArea = ({
  errors,
  touched,
  value,
  toggle,
  handleChange,
  placeholder,
  name,
}) => {
  const input = useMemo(() => value?.length, [value]);
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <textarea
        name={name}
        onChange={handleChange}
        className={`form-control ${errors && touched ? "is-invalid" : ""}`}
        style={{ resize: "none" }}
        id="bio"
        readOnly={toggle}
        value={value}
        placeholder={placeholder}
        cols={8}
        rows={6}
      />

      <div
        style={{
          position: "absolute",
          right: 0,
        }}
      >
        <small style={{ fontSize: "12px" }} className="text-secondary">
          {input}/{"255"}
        </small>
      </div>
      {errors && touched ? (
        <div className="text-danger" style={{ fontSize: "15px" }}>
          {errors}
        </div>
      ) : null}
    </div>
  );
};

export default TextArea;

import Box from "@mui/material/Box";
import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { AppDispatch } from "../../store";
interface deletePrompt {
  handleClose: any;
  deleteFuntion: any;
  textToShow: any;
}

const DeletePrompt = ({
  handleClose,
  deleteFuntion,
  textToShow,
}: deletePrompt) => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const handleDelete = () => {
    dispatch(deleteFuntion);
    handleClose(false);
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-start">
        <WarningAmberRoundedIcon
          className="me-2"
          sx={{ fontSize: 45, color: "#dc3545" }}
          fontSize="small"
        />
        <div>
          <Typography
            className="text-start mt-1 mb-0"
            id="modal-modal-title"
            variant="h6"
            component="h6"
          >
            {textToShow}
          </Typography>
          <p className="mb-2 text-start">Are you sure?</p>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div>
          <button
            onClick={() => {
              handleClose();
            }}
            type="button"
            className="btn bg-light-grey mt-4 px-5 mx-2"
            aria-label="submit data"
          >
            No
          </button>
        </div>
        <div>
          <button
            onClick={handleDelete}
            type="button"
            className="btn btn-primary mt-4 px-5 ms-2 me-0"
            aria-label="submit data"
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default DeletePrompt;

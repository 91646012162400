import Echo from "laravel-echo";
import Pusher from "pusher-js";
import { getAuth } from "../utils/AuthHelpers";
import { baseUrl } from "../config/config";

// create a new instance of Pusher
window.Pusher = Pusher as any;
const BASE_URL = baseUrl.api;

const auth = getAuth();
// create a hook to use websockets and takes 0 arguments
// get the auth token from the local storage
// create a new instance of Echo
const echo = new Echo({
  broadcaster: process.env.REACT_APP_BROADCASTER,
  key: process.env.REACT_APP_PUSHER_APP_KEY,
  cluster: "",
  wsHost: process.env.REACT_APP_WS_HOST,
  wsPort: 6002,
  wssPort: 6002,
  disableStats: true,
  encrypted: true, // Set to true if using secure WebSocket connection (wss://)
  // the auth token
  auth: {
    headers: {
      Authorization: `Bearer ${auth?.access_token}`,
    },
  },
  authEndpoint: `${BASE_URL}/api/broadcasting/auth?guard=api-provider`,
  enabledTransports: ["ws", "wss"],
});
// return the echo instance
export default echo;

import { useSelector } from "react-redux";
import Bar from "../Bar";
import Empty from "../Empty";
import { allNotificationSelector } from "../../../../../reducers/notificationReducer";

const AllNotification = () => {
  const data = useSelector(allNotificationSelector);

  const isQueEmpty = data.length === 0;

  if (isQueEmpty) {
    return <Empty />;
  } else {
    return (
      <>
        {data.map((data, idx) => (
          <Bar key={idx} data={data} />
        ))}
      </>
    );
  }
};

export default AllNotification;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPaymentRecord,
  getProviderBoardingAction,
} from "../../../../reducers/paymentReducer";
import { AppDispatch } from "../../../../store";
import { getAuth } from "../../../../utils/AuthHelpers";
import { PAYMENT_URL } from "../../../../config/config";

const BeginPayment = ({
  setChangeStep,
}: {
  setChangeStep(string): void;
}): JSX.Element => {
  // const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const { currentUser } = useSelector((state: any) => state.auth);

  useEffect(() => {
    dispatch(getProviderBoardingAction());
  }, [dispatch]);

  const auth = getAuth();
  // console.log(auth.access_token);

  return (
    <div style={{ maxWidth: "700px" }} className="my-4 m-auto">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 p-5"
      >
        <div className="d-flex flex-column justify-content-center align-items-center">
          <img className="" src="/images/payment/payment.svg" alt="no icon" />
          <h4
            style={{ marginTop: "2.2rem", color: "#3F3F46" }}
            className="mb-0 mb-1"
          >
            Setup Payments in the KAZZAH Platform!
          </h4>
          <p className="mb-0 text-secondary" style={{ fontSize: "16px" }}>
            Receiving in-platform payments allows you to:
          </p>
          <ul
            style={{ fontSize: "14px", marginTop: "2.2rem", color: "#3F3F46" }}
          >
            <li className="mb-2">Build valuable operating history</li>
            <li className="mb-2">
              Keep current on your top customers and performance trends
            </li>
            <li className="mb-2">
              Make it easy for customers to become loyal clients with easy,
              1-stop booking and payment managment.
            </li>
          </ul>
          <button
            onClick={() => setChangeStep("begin")}
            style={{ fontSize: "13px" }}
            className="btn btn-primary mt-1"
          >
            Get Setup to Received Payments
          </button>
          <button
            style={{ fontSize: "13px" }}
            className="btn btn-outline-secondary mt-2 border"
            onClick={() =>
              window.open(
                `${PAYMENT_URL}${currentUser?.id}/${auth?.access_token}`,
                "Popup",
                "toolbar=no,status=no,menubar=no,scrollbars=no,resizable=no,left=700,top=200,width=500,height=730,visible=none"
              )
            }
          >
            Get Setup For Sending Payments
          </button>
        </div>
      </div>
    </div>
  );
};

export default BeginPayment;

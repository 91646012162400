import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../store";
import {
  appointmentPendingListSelector,
  getPendingAppointmentList,
  statusSelector,
} from "../../reducers/appointmentReducer";
import { useLocation } from "react-router-dom";
import TableLoading from "../../components/common/TableLoading";
import PendingPaymentTable from "../provider/payment/components/PendingPaymentTable";

const PaymentHistory = () => {
  const dispatch = useDispatch<AppDispatch>();

  const pendingAppointmentListData = useSelector(
    appointmentPendingListSelector
  );

  const location = useLocation();

  const [params, setParams] = useState(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const singleValue = queryParams.get("memberId");
    if (!singleValue) {
      return;
    }
    setParams(singleValue);
    return () => {};
  }, [location.search]);

  // console.log(params);

  useEffect(() => {
    dispatch(getPendingAppointmentList("all"));
    return () => {};
  }, []);
  const [isDialog, setdialog] = useState(false);

  const appointmentStatus = useSelector(statusSelector);

  return (
    <div className="mx-lg-1 my-4">
      <div
        style={{ minHeight: "70vh" }}
        className="container-fluid bg-white rounded-5 px-0"
      >
        <div className="d-flex justify-content-start px-3 py-3">
          <h5 className="mb-0 main-head">Payments</h5>
          {/* <button onClick={() => setdialog(!isDialog)}>open</button> */}
          {/* <dialog open={isDialog}>This is an open dialog window</dialog> */}
          <div className="d-flex align-items-center">
            <div
              style={{
                height:
                  pendingAppointmentListData?.length <= 9 ? "18px" : "24px",
                width:
                  pendingAppointmentListData?.length <= 9 ? "18px" : "24px",
                fontSize: "12px",
              }}
              className={`bg-primary rounded-circle ms-2 d-flex align-items-center justify-content-center w-days`}
            >
              {params === null ? (
                <p className={`m-0 p-0 text-white`}>
                  {pendingAppointmentListData?.length}
                </p>
              ) : (
                <p className={`m-0 p-0 text-white`}>
                  {
                    pendingAppointmentListData?.filter(
                      (f) => f?.client?.id == params
                    )?.length
                  }
                </p>
              )}
            </div>
          </div>
        </div>
        {false ? (
          <div className="row">
            <div className="col-4 m-auto px-4 py-5 text-center">
              <label>
                <img
                  src="/images/Media/UploadImg.svg"
                  alt="Upload"
                  className="pointer"
                />

                <h6 className="mb-0 fw-bold mt-3 mb-1">No Pending Requests</h6>
                <p
                  style={{ fontSize: "14px", color: "#667085" }}
                  className="mb-0 text-grey"
                >
                  All the requests put by your clients will be listed here You
                  can easily Appove or Delline the requests
                </p>
              </label>
            </div>
          </div>
        ) : (
          <div>
            {appointmentStatus === "loading" ? (
              <TableLoading />
            ) : (
              <PendingPaymentTable param={params} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PaymentHistory;

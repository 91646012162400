import { Route, Routes, Navigate } from "react-router-dom";
// import TopBarProgress from "react-topbar-progress-indicator";
// import OTP from "../components/common/auth/OTP";

// local import
import Layout from "../components/layout";

// pages
import Dashboard from "../pages/dashboard/Dashboard";
import Services from "../pages/provider/services/Services";
import Schedule from "../pages/provider/schedule/Schedule";
import Media from "../pages/provider/media/Media";
import Credential from "../pages/provider/credential/credential";
import Payment from "../pages/provider/payment/Payment";
import Explore from "../pages/provider/explore/Explore";
import ClientList from "../pages/provider/clientList/ClientList";
import Appointment from "../pages/provider/appointment/Appointment";
import ClientDetail from "../pages/provider/clientList/ClientDetail";
import UserProfile from "../pages/profile/UserProfile";
import PublicProfile from "../pages/profile/PublicProfile";
import Settings from "../pages/provider/settings/Settings";
import MediaView from "../pages/provider/clientList/components/MediaView";
import Chat from "../pages/chat/Chat";
import PaymentHistory from "../pages/PaymentHistory";
import AcceptCash from "../pages/provider/payment/AcceptCash";
import ClientHistory from "../pages/provider/clientList/ClientHistory";
// import ProviderProfile from "../pages/profile";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path="auth/*" element={<Navigate to="/" />} />
        {/* Pages */}
        <Route path="/" element={<Dashboard />} />
        <Route path="/services" element={<Services />} />
        <Route path="/schedule" element={<Schedule />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/media" element={<Media />} />
        <Route path="/credential" element={<Credential />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/payment-status" element={<PaymentHistory />} />
        <Route path="/cash" element={<AcceptCash />} />
        <Route path="/appointment" element={<Appointment />} />
        <Route path="/explore" element={<Explore />} />
        <Route path="/client" element={<ClientList />} />
        <Route path="/Client/history" element={<ClientHistory />} />
        <Route path="/client/profile/:Id" element={<ClientDetail />} />
        <Route path="/profile" element={<UserProfile />} />
        <Route path="/public-profile/:Id" element={<PublicProfile />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/client/media/:Id" element={<MediaView />} />

        {/* Lazy Modules */}
      </Route>
      {/* <Route path="/logout" element={<Navigate to="/error/404" />} /> */}
      {/* Page Not Found */}
      <Route path="*" element={<Navigate to="/error/404" />} />

      {/* </Route> */}
    </Routes>
  );
};

// type WithChildren = {
//   children?: ReactNode;
// };
// const SuspensedView: FC<WithChildren> = ({ children }) => {
//   TopBarProgress.config({
//     barColors: {
//       "0": "#20ef42",
//     },
//     barThickness: 1,
//     shadowBlur: 5,
//   });
//   return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
// };

export { PrivateRoutes };

// {/* <Route
//           path='crafted/pages/profile/*'
//           element={
//             <SuspensedView>
//               <ProfilePage />
//             </SuspensedView>
//           }
//         />
//         <Route
//           path='/business/*'
//           element={
//             <SuspensedView>
//               <Business />
//             </SuspensedView>
//           }
//         />
//         <Route
//           path='/business/create'
//           element={
//             <SuspensedView>
//               <CreateBusiness />
//             </SuspensedView>
//           }
//         />
//         */}

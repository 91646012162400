import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { changeAppointmentStatus } from "../../../../reducers/appointmentReducer";
import { AppDispatch } from "../../../../store";

const ActionPrompt = ({ handleClose, data, textToShow }) => {
  const dispatch = useDispatch<AppDispatch>();

  const handleClick = () => {
    // console.log(data);
    dispatch(changeAppointmentStatus(data));
  };

  return (
    <>
      <div className="d-flex justify-content-start align-items-start">
        <WarningAmberRoundedIcon
          className="me-2"
          sx={{ fontSize: 45, color: "#dc3545" }}
          fontSize="small"
        />
        <div>
          <Typography
            className="text-start mt-1 mb-0"
            id="modal-modal-title"
            variant="h6"
            component="h6"
          >
            {textToShow}
          </Typography>
          <p className="mb-2 text-start">Are you sure?</p>
        </div>
      </div>
      <div className="d-flex justify-content-end">
        <div>
          <button
            onClick={() => {
              handleClose();
            }}
            type="button"
            className="btn bg-light-grey mt-4 px-5 mx-2"
            aria-label="submit data"
          >
            No
          </button>
        </div>
        <div>
          <button
            onClick={() => {
              handleClick();
              handleClose();
            }}
            type="button"
            className="btn btn-primary mt-4 px-5 ms-2 me-0"
            aria-label="submit data"
          >
            Yes
          </button>
        </div>
      </div>
    </>
  );
};

export default ActionPrompt;

export const serverInitialState = {
  id: "",
  providerServiceId: "",
  parentId: "",
  name: "",
  duration: "",
  price: "",
  description: "",
  status: "0",
};

import Typography from "@mui/material/Typography";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CURRENCY, MINUTE } from "../../../../config/constant";
import { updateThirdLevelChildServices } from "../../../../reducers/serviceReducer";
import { AppDispatch, RootState } from "../../../../store";
import * as yup from "yup";
import OverlayLoading from "../../../../components/common/OverlayLoading";
import TextArea from "../../../../components/common/Textarea";
import { handleKeyDown } from "../../../../utils";

export const EditModal = ({ handleClose, thirdLevelService }) => {
  const [newService, setNewService] = useState<any>(
    thirdLevelService?.providerService
  );
  const [service, setService] = useState<any>(thirdLevelService);
  const dispatch = useDispatch<AppDispatch>();

  const { createServiceStatus } = useSelector(
    (state: RootState) => state.service
  );
  //   const levelThreeServices = useSelector(serviceLevelThreeSelector);
  // console.log("data", newService);

  const handleServiceChange = (e: any) => {
    // console.log("before => ", newProviderService);
    // newProviderService = {
    //   ...newProviderService,
    //   [e.target.name]: e.target.value,
    // };
    // console.log("after=> ", newService);

    setNewService({
      ...newService,
      [e.target.name]: e.target.value,
      // providerServiceId: newService?.id,
    });
  };

  const handleDefaultServiceChange = (e: any) => {
    setService({
      ...service,
      [e.target.name]: e.target.value,
    });
  };
  // console.log("data", data);

  const validationSchema =
    thirdLevelService?.providerService !== null
      ? yup.object().shape({
          service_name: yup
            .string()
            .nullable()
            .required("Service Name is required"),
          duration: yup
            .number()
            .nullable()
            .min(1, "Value must be greater than 0")
            .max(999, "Value must be less than 1000")
            .required("Duration is required"),
          price: yup
            .number()
            .nullable()
            .positive("Value must be greater than 0")
            .max(9999, "Value must be less than 10,000")
            .required("Price is required"),
          service_description: yup
            .string()
            .nullable()
            .max(255, "Max 255 characters allowed")
            .required("Description is required"),
        })
      : yup.object().shape({
          name: yup.string().nullable().required("Service Name is required"),
          duration: yup
            .number()
            .nullable()
            .min(1, "Value must be greater than 0")
            .max(999, "Value must be less than 1000")
            .required("Duration is required"),
          price: yup
            .number()
            .nullable()
            .positive("Value must be greater than 0")
            .max(9999, "Value must be less than 10,000")
            .required("Price is required"),
          description: yup
            .string()
            .nullable()
            .max(255, "Max 255 characters allowed")
            .required("Description is required"),
        });

  const handleSubmit = (e: any) => {
    // e.preventDefault();
    if (thirdLevelService?.providerService !== null) {
      dispatch(
        updateThirdLevelChildServices({
          services: [
            {
              ...newService,
              id: newService?.service_id
                ? newService?.service_id
                : newService?.id,
              providerServiceId: newService?.id,
              name: newService?.service_name,
              description: newService?.service_description,
              parentId: thirdLevelService?.parentId,
              level: 3,
            },
          ],
          id: thirdLevelService?.parentId,
          handleClose,
        })
      );
    } else {
      dispatch(
        updateThirdLevelChildServices({
          services: [{ ...service, providerServiceId: null }],
          handleClose,
        })
      );
    }

    // handleClose();
  };
  return (
    <>
      <Typography
        id="modal-modal-title"
        sx={{
          fontSize: "18px",
          fontWeight: "530",
        }}
        component="h6"
      >
        Edit Service
      </Typography>
      <div className="form">
        <Formik
          initialValues={
            thirdLevelService?.providerService === null
              ? {
                  duration: service?.duration,
                  price: service?.price,
                  name: service?.name,
                  description: service?.description,
                }
              : {
                  service_name: newService?.service_name,
                  duration: newService?.duration,
                  price: newService?.price,
                  service_description: newService?.service_description,
                }
          }
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            handleSubmit(values);
            // console.log("values", values);
          }}
        >
          {({ errors, touched, values, handleBlur, handleChange }) => (
            <Form>
              {thirdLevelService?.providerService !== null ? (
                <>
                  <div className="form-group mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="servicename"
                      className="form-label"
                    >
                      Service Name
                    </label>
                    <Field
                      className={`form-control ${
                        errors.service_name && touched.service_name
                          ? "is-invalid"
                          : ""
                      }`}
                      id="servicename"
                      placeholder="Service name"
                      value={values?.service_name}
                      name="service_name"
                      onChange={(e) => {
                        handleChange(e);
                        handleServiceChange(e);
                      }}
                    />
                    <ErrorMessage
                      name="service_name"
                      component="span"
                      className="invalid-feedback"
                    />
                    {/* <div className="border rounded p-2 bg-disable">
                  {data?.name}
                </div> */}
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6 form-group">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="duration"
                        className="form-label"
                      >
                        Duration
                      </label>
                      <div className="input-group">
                        <Field
                          name="duration"
                          onKeyDown={handleKeyDown}
                          onChange={(e) => {
                            if (e.target.value.length > 3) {
                              return;
                            }
                            handleChange(e);
                            handleServiceChange(e);
                          }}
                          type="number"
                          className={`form-control border-end-0 ${
                            errors.duration && touched.duration
                              ? "is-invalid border-end-dangerC"
                              : ""
                          }`}
                          id="duration"
                          placeholder="Duration"
                          value={newService?.duration}
                        />
                        <ErrorMessage
                          name="duration"
                          component="span"
                          className="invalid-feedback"
                        />
                        {errors.duration && touched.duration ? null : (
                          <span
                            className="input-group-text px-md-2 px-1"
                            id="basic-addon1"
                          >
                            {MINUTE}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="price"
                        className="form-label"
                      >
                        Price
                      </label>
                      <div className="input-group">
                        <span
                          className="input-group-text px-md-2 px-1"
                          id="basic-addon1"
                        >
                          {CURRENCY}
                        </span>
                        <Field
                          name="price"
                          onKeyDown={handleKeyDown}
                          onChange={(e: any) => {
                            if (e.target.value > 9999) {
                              return;
                            }
                            handleChange(e);
                            handleServiceChange(e);
                          }}
                          value={newService?.price}
                          type="number"
                          className={`form-control border-start-0 ${
                            errors.price && touched.price ? "is-invalid" : ""
                          }`}
                          id="price"
                          placeholder="Price"
                        />
                        <ErrorMessage
                          name="price"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="description"
                      className="form-label"
                    >
                      Description
                    </label>
                    <TextArea
                      handleChange={(e) => {
                        handleServiceChange(e);
                        handleChange(e);
                      }}
                      errors={errors?.service_description}
                      touched={touched?.service_description}
                      name={"service_description"}
                      value={values?.service_description}
                      toggle={null}
                      placeholder={"Description"}
                    />
                    {/* <div className="border rounded ps-2 pt-2 pb-4 bg-disable">
                  {data?.description}
                </div> */}
                  </div>
                </>
              ) : (
                <>
                  <div className="form-group mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="servicename"
                      className="form-label"
                    >
                      Service Name
                    </label>
                    <Field
                      className={`form-control ${
                        errors.name && touched.name ? "is-invalid" : ""
                      }`}
                      id="servicename"
                      placeholder="Service name"
                      value={values?.name}
                      name="name"
                      onChange={(e) => {
                        handleChange(e);
                        handleDefaultServiceChange(e);
                        console.log("values", values);
                      }}
                    />
                    <ErrorMessage
                      name="name"
                      component="span"
                      className="invalid-feedback"
                    />
                    {/* <div className="border rounded p-2 bg-disable">
                  {data?.name}
                </div> */}
                  </div>

                  <div className="row mt-2">
                    <div className="col-md-6 form-group">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="duration"
                        className="form-label"
                      >
                        Duration
                      </label>
                      <div className="input-group">
                        <Field
                          name="duration"
                          onKeyDown={handleKeyDown}
                          onChange={(e) => {
                            if (e.target.value.length > 3) {
                              return;
                            }
                            handleChange(e);
                            handleDefaultServiceChange(e);
                          }}
                          type="number"
                          className={`form-control border-end-0 ${
                            errors.duration && touched.duration
                              ? "is-invalid border-end-dangerC"
                              : ""
                          }`}
                          id="duration"
                          placeholder="Duration"
                          value={values?.duration}
                        />
                        <ErrorMessage
                          name="duration"
                          component="span"
                          className="invalid-feedback"
                        />
                        {errors.duration && touched.duration ? null : (
                          <span
                            className="input-group-text px-md-2 px-1"
                            id="basic-addon1"
                          >
                            {MINUTE}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 form-group mt-3 mt-md-0">
                      <label
                        style={{ fontSize: "14px" }}
                        htmlFor="price"
                        className="form-label"
                      >
                        Price
                      </label>
                      <div className="input-group">
                        <span
                          className="input-group-text px-md-2 px-1"
                          id="basic-addon1"
                        >
                          {CURRENCY}
                        </span>
                        <Field
                          name="price"
                          onKeyDown={handleKeyDown}
                          onChange={(e: any) => {
                            if (e.target.value > 9999) {
                              return;
                            }
                            handleChange(e);
                            handleDefaultServiceChange(e);
                          }}
                          value={values?.price}
                          type="number"
                          className={`form-control border-start-0 ${
                            errors.price && touched.price ? "is-invalid" : ""
                          }`}
                          id="price"
                          placeholder="Price"
                        />
                        <ErrorMessage
                          name="price"
                          component="span"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      htmlFor="description"
                      className="form-label"
                    >
                      Description
                    </label>
                    {/* <Field
                      as="textarea"
                      className={`form-control ${
                        errors.description && touched.description
                          ? "is-invalid"
                          : ""
                      }`}
                      value={values?.description}
                      style={{ resize: "none" }}
                      name="description"
                      onChange={(e) => {
                        handleChange(e);
                        handleDefaultServiceChange(e);
                      }}
                      id="description"
                      placeholder="Description"
                      rows={2}
                    /> */}
                    <TextArea
                      handleChange={(e) => {
                        handleDefaultServiceChange(e);
                        handleChange(e);
                      }}
                      errors={errors?.description}
                      touched={touched?.description}
                      name={"description"}
                      value={values?.description}
                      toggle={null}
                      placeholder={"Description"}
                    />
                    {/* <div className="border rounded ps-2 pt-2 pb-4 bg-disable">
                  {data?.description}
                </div> */}
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-6">
                  <button
                    onClick={handleClose}
                    className="bg-white btn text-secondary border rounded rounded-3 w-100 mt-4 fw-bold"
                    type="reset"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  <button
                    style={{ backgroundColor: "#04BAC7" }}
                    className="btn text-white rounded rounded-3 w-100 mt-4 fw-bold"
                    type="submit"
                  >
                    Save
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <OverlayLoading
        active={createServiceStatus == "loading" ? true : false}
      />
    </>
  );
};

import React, { useLayoutEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import { PERCENT } from "../../../../../config/constant";
import { AppDispatch } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import {
  scheduleOnToOneReadOnlySelector,
  updateOneToOne,
} from "../../../../../reducers/scheduleReducer";
import { SchedulePeakPricingTypes } from "../../type";
import "react-tooltip/dist/react-tooltip.css";
import { BootstrapTooltip } from "../../../../../components/common/BootstrapTooltip";

const PeakPriceAccordian = ({
  pricing,
  monthly,
  increase,
  vic,
  change,
  setChange,
}) => {
  const readOnlyData = useSelector(scheduleOnToOneReadOnlySelector);

  const dispatch = useDispatch<AppDispatch>();

  const handleChange = (e: any) => {
    // value entered by user must not be greater than 100
    if (e.target.value.length <= 3) {
      if (e.target.value <= 100) {
        dispatch(updateOneToOne({ key: e.target.name, value: e.target.value }));
      }
    }
  };

  const handleToggleChange = (e: any) => {
    dispatch(
      updateOneToOne({ key: e.target.name, value: e.target.checked ? 1 : 0 })
    );
  };

  return (
    <div className="accordion mb-3" id="accordionExample6">
      <div
        className={`accordion-item ${pricing === 1
          ? ["", "0", "00", "000", null].includes(monthly) ||
            ["", "0", "00", "000", null].includes(increase)
            ? "border-danger"
            : change.peak
              ? "border-start-primary"
              : ""
          : change.peak
            ? "border-start-primary"
            : ""
          }`}
      >
        <h2 className="accordion-header" id="headingOne">
          <button
            style={{ backgroundColor: "#f9fafb", minHeight: "4.5rem" }}
            className="accordion-button px-md-4 px-2 collapsed"
            type="button"
          >
            <div className="row w-100 align-items-center">
              <div className="col-md-8 col-9">
                <div className="d-flex align-items-center">
                  <img
                    className="me-3"
                    height={22}
                    src="/images/schedule/peekPricing.svg"
                    alt=""
                  />
                  <div>
                    <p className="fw-light mb-0 text-dark">Peak Pricing</p>
                    {readOnlyData?.isPeakPricing === 1 ? (
                      <p
                        style={{ fontSize: "12px" }}
                        className="fw-light my-0 text-secondary align-items-center"
                      >
                        Monthly Sch. : {readOnlyData.monthlySchedulePercentage}%{" "}
                        <FiberManualRecordIcon
                          className="ms-2 me-1"
                          sx={{ fontSize: 9, color: "#94A3B8" }}
                        />{" "}
                        Increase Rate: {readOnlyData.increaseRateBy}%{" "}
                        <FiberManualRecordIcon
                          className="ms-2 me-1"
                          sx={{ fontSize: 9, color: "#94A3B8" }}
                        />{" "}
                        VIC :{" "}
                        {readOnlyData.isVicExempt ? (
                          <DoneIcon color="success" sx={{ fontSize: 18 }} />
                        ) : (
                          <ClearIcon color="warning" sx={{ fontSize: 18 }} />
                        )}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-3">
                <div className="d-flex align-items-center justify-content-end actions">
                  <BootstrapTooltip placement="top" title="This feature allows you to set premium increases on services during peak demand for your services.  You can exempt this upcharge on your VIC 'very important repeat customers'.VIC designation can be applied to your client profile in your Client list hub.">
                    <img
                      id="peakPriceOne"
                      className="me-3 pointer"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="isPeakPricing"
                      onChange={(e) => {
                        setChange({ ...change, peak: true });
                        handleToggleChange(e);
                      }}
                      checked={pricing}
                      type="checkbox"
                      id="flexSwitchCheckChecked"
                    />
                  </div>
                  <img
                    className="down-arrow pointer"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapse6"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    src="/images/schedule/arrow-down.svg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </button>
        </h2>
        <div
          id="collapse6"
          className={`accordion-collapse collapse ${monthly === "" ||
            monthly === null ||
            monthly === "0" ||
            increase === "" ||
            increase === null ||
            increase === "0"
            ? pricing === 1
              ? "show"
              : "collapse"
            : "show"
            }`}
          aria-labelledby="headingOne"
          data-bs-parent="#accordionExample6"
        >
          <div className="accordion-body px-2 py-4">
            <div className="row w-100 m-auto px-0 rounded-5 align-items-sm-stretch">
              <div className="col-md-4">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  When <span className="fw-bold">daily</span> schedule:
                </p>

                <div className="input-group">
                  <input
                    style={{ fontSize: "15px" }}
                    type="number"
                    min={1}
                    max={100}
                    pattern="\d*"
                    className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control border-end-0 ${monthly === "0" || monthly === "00" || monthly === "000"
                      ? "is-invalid"
                      : ""
                      }`}
                    placeholder="Enter Value"
                    name="monthlySchedulePercentage"
                    onKeyPress={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e" ||
                        e.key === "E"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      setChange({ ...change, peak: true });
                      handleChange(e);
                    }}
                    value={`${monthly !== null ? monthly : " "}`}
                    id="Text1"
                  />
                  <span
                    className="input-group-text px-md-2 px-1"
                    id="basic-addon1"
                  >
                    {PERCENT}
                  </span>
                </div>
                {monthly === "0" || monthly === "00" || monthly === "000" ? (
                  <p style={{ fontSize: "12px" }} className="mb-0 text-danger">
                    When Monthly Schedule can not be 0
                  </p>
                ) : null}
                <p
                  style={{ fontSize: "15px" }}
                  className="text-secondary text-end mt-2 mb-0"
                >
                  Full
                </p>
              </div>
              <div className="col-md-4">
                <p
                  style={{ fontSize: "14px" }}
                  className="mb-0 me-4 text-secondary"
                >
                  Increase rate by:
                </p>

                <div className="input-group">
                  <input
                    style={{ fontSize: "15px" }}
                    type="number"
                    min={1}
                    max={100}
                    className={`border-1 rounded-4 align-items-center justify-content-center px-2 form-control border-end-0 ${increase === "0" ||
                      increase === "00" ||
                      increase === "000"
                      ? "is-invalid"
                      : ""
                      }`}
                    placeholder="Enter Value"
                    name="increaseRateBy"
                    onChange={(e) => {
                      setChange({ ...change, peak: true });
                      handleChange(e);
                    }}
                    onKeyPress={(e) => {
                      if (
                        e.key === "-" ||
                        e.key === "." ||
                        e.key === "e" ||
                        e.key === "E"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    value={`${increase !== null ? increase : " "}`}
                    id="Text1"
                  />

                  <span
                    className="input-group-text px-md-2 px-1"
                    id="basic-addon1"
                  >
                    {PERCENT}
                  </span>
                </div>
                {increase === "0" || increase === "00" || increase === "000" ? (
                  <p style={{ fontSize: "12px" }} className="mb-0 text-danger">
                    Increase Rate By can not be 0
                  </p>
                ) : null}
              </div>
              <div className="col-md-4">
                <div className="d-flex justify-content-md-start justify-content-between mt-md-0 mt-2 align-items-center">
                  <p
                    style={{ fontSize: "14px" }}
                    className="mb-0 me-1 text-secondary"
                  >
                    VIC Exempt
                  </p>
                  <BootstrapTooltip placement="top" title="This feature allows you to set premium increases on services during peak demand for your services.  You can exempt this upcharge on your VIC 'very important repeat customers'.VIC designation can be applied to your client profile in your Client list hub.">
                    <img
                      id="peakPriceOne2"
                      className="pointer d-md-block d-none"
                      height={18}
                      src="/images/schedule/infosvg.svg"
                      alt=""
                    />
                  </BootstrapTooltip>
                  <div className="form-check d-md-none d-block form-switch mt-2">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isVicExempt"
                      onChange={(e) => {
                        setChange({ ...change, peak: true });
                        handleToggleChange(e);
                      }}
                      checked={vic}
                      id="flexSwitchCheckChecked"
                    />
                    <span>Yes</span>
                  </div>
                </div>
                <div className="form-check d-md-block d-none form-switch mt-2">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="isVicExempt"
                    onChange={(e) => {
                      setChange({ ...change, peak: true });
                      handleToggleChange(e);
                    }}
                    checked={vic}
                    id="flexSwitchCheckChecked"
                  />
                  <span>Yes</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PeakPriceAccordian;

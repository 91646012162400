import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateOneToOneDays } from "../../../../../reducers/scheduleReducer";
import { AppDispatch } from "../../../../../store";
import { ScheduleDaysPropTypes, scheduleDayType } from "../../type";
import DaysSkeletonLoading from "../DaysSkeletonLoading";

const ScheduleDays = ({
  days,
  SelectedDays,
  change,
  setChange,
}: ScheduleDaysPropTypes) => {
  const dispatch = useDispatch<AppDispatch>();
  const { status } = useSelector((state: any) => state.schedule);

  // const Loading = useSelector(scheduleStatus);
  const handleDayActive = (id: number) => {
    const index = change.days.indexOf(id);
    if (index > -1) {
      change.days.splice(index, 1);
    } else {
      change.days.push(id);
    }
    dispatch(updateOneToOneDays(id));
    // console.log("ids =>", ids);
  };

  return (
    <>
      {status === "loading" ? (
        <DaysSkeletonLoading />
      ) : (
        <>
          <div className="d-flex align-items-center mb-4">
            {SelectedDays?.map(({ id, day, isActive }: scheduleDayType) => (
              <div
                key={id}
                onClick={() => handleDayActive(id)}
                style={{ height: "45px", width: "45px", fontSize: "17px" }}
                className={`${
                  isActive
                    ? change.days?.includes(id)
                      ? "bg-primary border-dotted-secondary"
                      : "bg-primary"
                    : "bg-light-grey"
                } rounded-circle p-1 me-md-3 me-2 d-flex align-items-center justify-content-center pointer w-days`}
              >
                <p
                  className={`m-0 p-0 ${
                    isActive ? "text-white" : "text-secondary"
                  }`}
                >
                  {day}
                </p>
              </div>
            ))}
          </div>
          {days?.length < 1 ? (
            <div className="text-danger">Atleast one day required</div>
          ) : null}
        </>
      )}
    </>
  );
};

export default ScheduleDays;
